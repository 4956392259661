import { __assign } from "tslib";
import { jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
import { userFriendlyNameMappings } from 'common/utils/userFriendlyNameMappings';
export var LastEditedInfo = function (_a) {
    var _b;
    var lastEditedBy = _a.lastEditedBy, lastEditedAt = _a.lastEditedAt;
    return (_jsxs(Text, __assign({ size: "small", prominence: "subtle" }, { children: ["Last edit: ", (_b = userFriendlyNameMappings.get(lastEditedBy)) !== null && _b !== void 0 ? _b : lastEditedBy, ",", ' ', dayjs(lastEditedAt).format(DATE_FORMAT_DD_MMM_YYYY)] })));
};
