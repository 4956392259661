import { __assign } from "tslib";
import { jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Badge = function (props) {
    return (_jsxs("div", __assign({ className: "item-1 selected-item mr-3 pull-left config-badge", 
        // style={
        //   props.selectedBadge === props.id ? { backgroundColor: "#303030" } : null
        // }
        onClick: props.onBadgeClick, id: props.id }, { children: [props.name, " (", props.count, ")"] }), props.list));
};
export default Badge;
