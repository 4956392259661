import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import MeasureUnit from './measureUnit';
import MeasureUnitCrossReference from './measureUnitCrossReference';
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var measureUnitsReferenceNavLink = /** @class */ (function (_super) {
    __extends(measureUnitsReferenceNavLink, _super);
    function measureUnitsReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    measureUnitsReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Measure Units ", _jsx("span", { children: "(Reference Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/measureUnitsReference/measureUnit", className: "nav-link", activeClassName: "active" }, { children: "Measure Unit" })), _jsx(NavLink, __assign({ to: "/dashboard/measureUnitsReference/measureUnitCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Measure Unit Cross Reference" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/measureunitsreference", to: "/dashboard/measureUnitsReference/measureUnit", exact: true }), _jsx(Route, { path: "/dashboard/measureUnitsReference/measureUnit", component: MeasureUnit, exact: true }), _jsx(Route, { path: "/dashboard/measureUnitsReference/measureUnitCrossReference", component: MeasureUnitCrossReference, exact: true })] }) }))] }))] })) }));
    };
    return measureUnitsReferenceNavLink;
}(Component));
export default measureUnitsReferenceNavLink;
