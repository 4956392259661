import { AvatarSize } from './Avatar.types';
import { Sizes } from '../../types/types';

type AvatarConstsType = {
  [size in AvatarSize]: {
    avatar: number;
    icon: number;
  };
};

export const avatarConsts: AvatarConstsType = {
  [Sizes.Small]: {
    avatar: 28,
    icon: 20,
  },
  [Sizes.Medium]: {
    avatar: 36,
    icon: 24,
  },
  [Sizes.Large]: {
    avatar: 44,
    icon: 28,
  },
};

export const altDefaultText = 'Avatar';
