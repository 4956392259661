import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
import { Flexbox, ButtonGroup } from '@sede-x/shell-ds-react-framework';
export var StyledHeaderContainer = styled(Flexbox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 24px;\n  gap: 24px;\n  background-color: ", ";\n  width: 100%;\n"], ["\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 24px;\n  gap: 24px;\n  background-color: ", ";\n  width: 100%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
export var StyledHeader = styled(Flexbox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n"], ["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n"])));
export var StyledButtonGroup = styled(ButtonGroup)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  label {\n    padding: 6px;\n  }\n  label:first-child {\n    border-radius: 6px 0 0 6px;\n  }\n  label:last-child {\n    border-radius: 0 6px 6px 0;\n  }\n"], ["\n  label {\n    padding: 6px;\n  }\n  label:first-child {\n    border-radius: 6px 0 0 6px;\n  }\n  label:last-child {\n    border-radius: 0 6px 6px 0;\n  }\n"])));
export var StyledInfoContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  ", ";\n"], ["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  ", ";\n"])), function (_a) {
    var open = _a.open;
    return open
        ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          top: 35%;\n        "], ["\n          top: 35%;\n        "]))) : css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          top: 40%;\n        "], ["\n          top: 40%;\n        "])));
});
export var StyledTrendsDataContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 16px;\n  padding: 24px;\n  background-color: ", ";\n  th {\n    position: sticky;\n    top: 0px;\n    margin: 0 0 0 0;\n    background-color: ", ";\n  }\n  tr {\n    background-color: ", ";\n  }\n"], ["\n  margin-top: 16px;\n  padding: 24px;\n  background-color: ", ";\n  th {\n    position: sticky;\n    top: 0px;\n    margin: 0 0 0 0;\n    background-color: ", ";\n  }\n  tr {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
