import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
var CollapseExpand = function (props) {
    var _a = useState(true), open = _a[0], setOpen = _a[1];
    var togglePanel = function () {
        setOpen(!open);
    };
    var iconclass = 'fa fa-lg bg-solid fa-caret-square-o-';
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "separator mx-2 mb-4", onClick: togglePanel }, { children: _jsxs("div", __assign({ className: "icon-title p-2" }, { children: [_jsx("span", __assign({ className: "p-3" }, { children: _jsx("i", { className: !open ? iconclass + 'down' : iconclass + 'up' }) })), _jsx("span", __assign({ className: "p-3 title" }, { children: _jsx("strong", { children: props.title }) }))] })) })), open ? _jsx("div", __assign({ className: "row" }, { children: props.children })) : null] }));
};
export default CollapseExpand;
