import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../commons/crossReferenceGridTemplate';
var measureUnitCrossReference = /** @class */ (function (_super) {
    __extends(measureUnitCrossReference, _super);
    function measureUnitCrossReference(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            dataProviderAbbr: {
                required: true,
                error: 'Please enter Data Provider.',
            },
            measureUnitCd: {
                required: true,
                error: 'Please enter Measure Unit Key',
            },
        };
        var foreginKeyParams = [
            {
                path: 'measureUnit',
                key: 'measureUnitKey',
                cdName: 'measureUnitCd',
            },
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
            },
        ];
        var columns = [
            {
                field: 'providerMeasureUnitCd',
                title: 'Provider Measure Unit Cd',
            },
            {
                field: 'measureUnitCd',
                title: 'Measure Unit Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'measureUnitCd',
                referenceTitle: 'Measure Unit',
                urlEndPoint: 'measureUnit',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider',
                urlEndPoint: 'dataProvider',
            },
            {
                field: 'versionEffectiveDt',
                title: 'Effective Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'versionTerminationDt',
                title: 'Termination Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    measureUnitCrossReference.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "measureUnitCrossRef", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "providerMeasureUnitCd", foreginKeyParams: this.state.foreginKeyParams }));
    };
    return measureUnitCrossReference;
}(Component));
export default measureUnitCrossReference;
