import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from '@sede-x/shell-ds-react-framework';
import { StyledActionsContainer } from './EventsSummaryActionsModal.styles';
import { Versions } from 'components/versions';
import { OriginalEventsModal } from 'components/original-events-modal';
import { LinkEventsModal } from 'components/link-events-modal';
import { useUpdateEventLinksMutation, useGetVersionsQuery, useGetEventOriginalsQuery, } from 'common/ducks/maintenanceApis';
import { useAppDispatch, useAppSelector } from 'common/hooks/store';
import { Loader } from 'components/loader';
import { selectActionModals, setModalClose } from 'pages/maintenance-event-summary/ducks/slice';
import { ModalExportButton } from 'components/modal-export-button/ModalExportButton';
var getShouldSkipRequest = function (isOpen, selectedModal, modalName) {
    return !isOpen || selectedModal !== modalName;
};
export var EventsSummaryActionsModal = function (_a) {
    var refetchTable = _a.refetchTable;
    var dispatch = useAppDispatch();
    var _b = useUpdateEventLinksMutation(), updateLinks = _b[0], isLinksUpdating = _b[1].isLoading;
    var _c = useAppSelector(selectActionModals), isOpen = _c.isOpen, current = _c.current, title = _c.title, assetUnitEventKey = _c.assetUnitEventKey, selectedItemKey = _c.selectedItemKey;
    var handleClose = function () { return dispatch(setModalClose()); };
    var linksPostRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateLinks({ assetUnitEventKey: assetUnitEventKey, selectedItemKey: selectedItemKey })];
                case 1:
                    _a.sent();
                    handleClose();
                    refetchTable === null || refetchTable === void 0 ? void 0 : refetchTable();
                    return [2 /*return*/];
            }
        });
    }); };
    var isButtonDisabled = selectedItemKey < 1;
    var _d = useGetEventOriginalsQuery(assetUnitEventKey, {
        skip: getShouldSkipRequest(isOpen, current, 'originals'),
    }), originals = _d.data, isFetchingOriginals = _d.isFetching;
    var _e = useGetVersionsQuery(assetUnitEventKey, {
        skip: getShouldSkipRequest(isOpen, current, 'versions'),
    }), versions = _e.data, isFetchingVersions = _e.isFetching;
    var getModalContent = {
        none: _jsx("div", { children: "none" }),
        originals: _jsx(OriginalEventsModal, { data: originals !== null && originals !== void 0 ? originals : [], isFetching: isFetchingOriginals }),
        links: _jsx(LinkEventsModal, { skip: getShouldSkipRequest(isOpen, current, 'links') }),
        versions: _jsx(Versions, { data: versions !== null && versions !== void 0 ? versions : [], isFetching: isFetchingVersions }),
    };
    var getButtons = {
        none: (_jsx(Button, __assign({ variant: "outlined", size: "small", onClick: handleClose }, { children: "Close" }))),
        originals: (_jsx(_Fragment, { children: _jsx(Button, __assign({ variant: "outlined", size: "small", onClick: handleClose }, { children: "Close" })) })),
        links: (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "outlined", size: "small", onClick: handleClose, style: { marginRight: '8px' } }, { children: "Close" })), _jsx(Button, __assign({ disabled: isButtonDisabled, variant: "filled", size: "small", onClick: linksPostRequest }, { children: "Link selected event" }))] })),
        versions: (_jsx(Button, __assign({ variant: "outlined", size: "small", onClick: handleClose }, { children: "Close" }))),
    };
    var dataToExport = current === 'originals' ? originals : versions;
    var type = current === 'originals' ? 'maintenance-original-events' : 'maintenance-baseline-history-events';
    return (_jsxs(Modal, __assign({ title: title, width: "70vw", open: isOpen, onClose: handleClose, destroyOnClose: true, footer: _jsx("div", __assign({ style: { margin: '15px 27px 15px 0px' } }, { children: getButtons[current] })) }, { children: [current !== 'links' && (_jsx(ModalExportButton, { top: 25, right: 60, dataToExport: dataToExport !== null && dataToExport !== void 0 ? dataToExport : [], type: type, propertyName: "maintenanceOriginals" })), isLinksUpdating ? (_jsx(StyledActionsContainer, { children: _jsx(Loader, { message: "Updating data..." }) })) : (getModalContent[current])] })));
};
