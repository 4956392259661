import { __assign, __extends, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { getSourceFilter } from '../../../../../services/urlService';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Pagination from '../../../../../commons/tools/pagination';
import SvgLogo from '../../../../../commons/tools/svgLogo';
import { VersionsPopup } from './VersionsPopup.tsx';
var UnitCell = /** @class */ (function (_super) {
    __extends(UnitCell, _super);
    function UnitCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UnitCell.prototype.render = function () {
        return _jsx("td", __assign({ title: this.props.dataItem.unit }, { children: this.props.dataItem.unit }));
    };
    return UnitCell;
}(React.Component));
var totalCapacityCell = /** @class */ (function (_super) {
    __extends(totalCapacityCell, _super);
    function totalCapacityCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    totalCapacityCell.prototype.render = function () {
        return (_jsxs("td", __assign({ title: this.props.dataItem.totalCapacity }, { children: [parseFloat(this.props.dataItem.totalCapacity).toFixed(1), " ", this.props.dataItem.measureUnitCd] })));
    };
    return totalCapacityCell;
}(React.Component));
var CountryCell = /** @class */ (function (_super) {
    __extends(CountryCell, _super);
    function CountryCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CountryCell.prototype.render = function () {
        return _jsx("td", __assign({ title: this.props.dataItem.countryNm }, { children: this.props.dataItem.countryNm }));
    };
    return CountryCell;
}(React.Component));
var AssetCell = /** @class */ (function (_super) {
    __extends(AssetCell, _super);
    function AssetCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AssetCell.prototype.render = function () {
        return _jsx("td", __assign({ title: this.props.dataItem.asset }, { children: this.props.dataItem.asset }));
    };
    return AssetCell;
}(React.Component));
var DailyMaintenance = /** @class */ (function (_super) {
    __extends(DailyMaintenance, _super);
    function DailyMaintenance(props) {
        var _this = _super.call(this, props) || this;
        //pageSize = 20;
        _this.baseUrl = 'Maintenance/MaintenanceEventSummary' + _this.props.path;
        _this.init = { method: 'GET', accept: 'application/json', headers: {} };
        _this.state = {
            orders: [],
            defaultOrders: [],
            total: 0,
            selectedID: null,
            sort: [],
            show: false,
            skip: 0,
            take: 15,
            pageLimit: 20,
            pageNumber: 1,
            totalPaginationRows: 0,
            subloading: false,
            refreshRand: false,
        };
        _this.rowRender = function (trElement, dataItem) {
            var trProps = __assign(__assign({}, trElement.props), { onDoubleClick: function () {
                    var selectedData = {
                        Countries: {
                            text: dataItem.dataItem.countryNm,
                            id: dataItem.dataItem.countryKey,
                        },
                        Cities: {
                            text: dataItem.dataItem.cityNm,
                            id: dataItem.dataItem.cityKey,
                        },
                        Companies: {
                            text: dataItem.dataItem.ownerNm,
                            id: dataItem.dataItem.ownerKey,
                        },
                        AssetType: {
                            text: dataItem.dataItem.assetClassDesc,
                            id: dataItem.dataItem.assetClassKey,
                        },
                        Asset: {
                            text: dataItem.dataItem.asset,
                            id: dataItem.dataItem.assetKey,
                        },
                        UnitType: {
                            text: dataItem.dataItem.unitType,
                            id: dataItem.dataItem.unitTypeKey,
                        },
                        UnitSubType: {
                            text: dataItem.dataItem.unitSubType,
                            id: dataItem.dataItem.unitSubTypeKey,
                        },
                        Unit: {
                            text: dataItem.dataItem.unit,
                            id: dataItem.dataItem.unitKey,
                        },
                        eventKey: dataItem.dataItem.assetUnitEventKey,
                    };
                    _this.props.onBaselineEdit(selectedData, true);
                } });
            return React.cloneElement(trElement, __assign({}, trProps), trElement.props.children);
        };
        _this.pageChange = function (event) {
            _this.setState({
                skip: event.page.skip,
                take: event.page.take,
            });
        };
        /*
         * a utility function to append pageNumber and pagelimit to the
         * maintenance URL
         */
        _this.appendPaginationParameters = function (pageNumber, pageLimit) {
            _this.baseUrl = _this.baseUrl.split('&pageNumber=')[0];
            if (pageNumber !== undefined && pageLimit !== undefined) {
                _this.baseUrl = _this.baseUrl + '&pageNumber=' + pageNumber + '&pageSize=' + pageLimit;
            }
            if (_this.state.sort.length > 0) {
                _this.baseUrl = _this.baseUrl + '&orderBy=' + _this.state.sort[0].field + ':' + _this.state.sort[0].dir;
            }
            return _this.baseUrl;
        };
        _this.onAddEditRefresh = function () {
            _this.onPageChange(1, _this.state.pageLimit);
            _this.setState({
                refreshRand: Math.random().toString(36).substring(7),
            });
        };
        /*
         *function that makes the API call for new page number/page Limit
         */
        _this.onPageChange = function (pageNumber, pageLimit) {
            _this.setState({
                pageLimit: pageLimit,
                pageNumber: pageNumber,
            });
            _this.appendPaginationParameters(pageNumber, pageLimit);
            _this.requestData(0);
        };
        _this.addSortingParameters = function (fieldName, sortOrder) {
            _this.baseUrl = _this.baseUrl.split('&orderBy=')[0];
            _this.baseUrl = _this.baseUrl + '&orderBy=' + fieldName + ':' + sortOrder;
            _this.requestData(0);
        };
        _this.sortByTotalCapacity = function (dir) {
            if (dir === void 0) { dir = 'default'; }
            if (dir === 'default') {
                _this.setState({
                    orders: _this.state.defaultOrders,
                });
                return;
            }
            var sortedData = _this.state.orders.sort(function (data1, data2) { return parseFloat(data1.totalCapacity) - parseFloat(data2.totalCapacity); });
            _this.setState({
                orders: dir === 'asc' ? sortedData : sortedData.reverse(),
            });
        };
        _this.dateFormatter = function (items) {
            items.map(function (item, key) {
                if (item.updateDate !== null && item.updateDate) {
                    var timeSplit = item.updateDate.split('.');
                    item.updateDate = new Date(timeSplit[0]);
                }
                if (item.startDate !== null && item.startDate) {
                    var timeSplit = item.startDate.split('.');
                    item.startDate = new Date(timeSplit[0]);
                }
                if (item.endDate !== null && item.endDate) {
                    var timeSplit = item.endDate.split('.');
                    item.endDate = new Date(timeSplit[0]);
                }
            });
            return items;
        };
        _this.loadingCell = function (tdElement, props) {
            if (props.dataItem[props.field] === undefined) {
                // shows loading icon if no data
                return (_jsx("td", { children: _jsx("span", { className: "k-icon k-i-loading" }) }));
            }
            return tdElement;
        };
        return _this;
    }
    DailyMaintenance.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: this.state.show && (_jsx("div", __assign({ className: "col-12" }, { children: this.state.orders.length > 0 ? (_jsxs("div", __assign({ id: "dailyeventListing" }, { children: [this.state.subloading ? (_jsxs("div", __assign({ className: "k-loading-mask" }, { children: [_jsx("span", __assign({ className: "k-loading-text" }, { children: "Loading" })), _jsx("div", { className: "k-loading-image" }), _jsx("div", { className: "k-loading-color" })] }))) : (_jsx(_Fragment, {})), _jsxs(Tooltip, __assign({ openDelay: 200, position: "top", anchorElement: "pointer" }, { children: [_jsxs(Grid, __assign({ style: { height: window.innerHeight - 335 }, rowHeight: 20, data: this.state.orders.map(function (item) { return (__assign(__assign({}, item), { selected: item.assetUnitEventKey === _this.state.selectedID })); }), total: this.state.total, rowRender: this.rowRender, selectedField: "selected", onRowClick: function (e) {
                                        _this.setState({
                                            selectedID: e.dataItem.assetUnitEventKey,
                                        });
                                    }, sortable: true, sort: this.state.sort, onSortChange: function (e) {
                                        _this.setState({
                                            sort: e.sort,
                                        });
                                        if (e.sort.length === 0) {
                                            _this.sortByTotalCapacity();
                                            return;
                                        }
                                        if (e.sort[0].field === 'totalCapacity') {
                                            _this.sortByTotalCapacity(e.sort[0].dir);
                                            return;
                                        }
                                        _this.addSortingParameters(e.sort[0].field, e.sort[0].dir);
                                    } }, { children: [_jsx(Column, { field: "updateDate", title: "Update Date", format: "{0:dd MMM yyyy}" }), _jsx(Column, { field: "countryNm", title: "Country", cell: CountryCell }), _jsx(Column, { field: "asset", title: "Asset", cell: AssetCell }), _jsx(Column, { field: "unitType", title: "Unit Type" }), _jsx(Column, { field: "unitSubType", title: "Unit SubType" }), _jsx(Column, { field: "unit", title: "Unit", cell: UnitCell }), _jsx(Column, { field: "offlinePCT", title: "Offline %" }), _jsx(Column, { field: "totalCapacity", title: "Total Capacity", cell: totalCapacityCell }), _jsx(Column, { field: "startDate", title: "Start Date", format: "{0:dd MMM yyyy}" }), _jsx(Column, { field: "endDate", title: "End Date", format: "{0:dd MMM yyyy}" }), _jsx(Column, { field: "eventType", title: "Event Type" }), _jsx(Column, { field: "eventCauseType", title: "Event Cause Type" }), _jsx(Column, { field: "dataProviderName", title: "Provider" }), _jsx(Column, { field: "", cell: function (props) { return (_jsx("td", { children: _jsx(VersionsPopup, { assetUnitEventKey: props.dataItem.assetUnitEventKey }) })); } })] })), _jsx(Pagination, { totalRecords: this.state.totalPaginationRows, pageLimit: this.state.pageLimit, onPageChange: this.onPageChange }, this.state.refreshRand)] }))] }))) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No data available, Please reselect filters." }))] })) }))) }))) }));
    };
    DailyMaintenance.prototype.componentDidMount = function () {
        if (this.appendPaginationParameters(this.state.pageNumber, this.state.pageLimit).split('?').length > 1) {
            this.requestData(0);
        }
    };
    DailyMaintenance.prototype.requestData = function (skipParameter) {
        var _this = this;
        this.setState({ subloading: true });
        getSourceFilter(this.baseUrl).then(function (response) {
            var total = 0;
            var data = [];
            if (response.data.maintenanceEventDataList !== null) {
                total = response.data.maintenanceEventDataList.length;
                data = response.data.maintenanceEventDataList;
            }
            var totalPaginationRows = response.data.totalRows;
            var orders = _this.state.orders.length === total ? _this.state.orders : new Array(total).fill().map(function (e, i) { return ({ Index: i }); });
            data.forEach(function (order, i) {
                orders[parseInt(i)] = __assign({ Index: parseInt(i) }, order);
            });
            if (total > 0) {
                _this.setState({
                    orders: _this.dateFormatter(orders),
                    total: total,
                    show: true,
                    totalPaginationRows: totalPaginationRows,
                    subloading: false,
                }, function () {
                    _this.setState({
                        defaultOrders: __spreadArray([], orders, true),
                    });
                    _this.props.applyRemove();
                });
            }
            else {
                _this.setState({
                    orders: [],
                    defaultOrders: [],
                    show: true,
                }, function () {
                    _this.props.applyRemove();
                });
            }
        });
        //this.setState({ subloading: false });
    };
    return DailyMaintenance;
}(React.Component));
export default DailyMaintenance;
