import { forwardRef, ReactElement } from 'react';

import { TABS_CLASS } from '../../utils/constants';
import * as Styled from './Tabs.styles';
import { ITabsProps } from './Tabs.types';
import { Sizes, Positions } from '../../types/types';

const Tabs = forwardRef<HTMLDivElement, ITabsProps>(
  (
    { className = '', size = Sizes.Large, emphasis = true, tabPosition = Positions.Top, indicatorPosition, ...rest },
    ref,
  ): ReactElement => (
    <Styled.Tabs
      className={className}
      prefixCls={TABS_CLASS}
      size={size}
      $emphasis={emphasis}
      tabPosition={tabPosition}
      $indicatorPosition={indicatorPosition}
      ref={ref}
      {...rest}
    />
  ),
);

export default Tabs;
