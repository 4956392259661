import RCPagination from 'rc-pagination';
import styled, { css } from 'styled-components';

import { paginationConsts } from './Pagination.consts';
import { PaginationPosition, PaginationSize } from './Pagination.types';
import { Sizes } from '../../types/types';

interface PaginationProps {
  prefixCls: string;
  position: PaginationPosition;
  size: PaginationSize;
}

const Pagination = styled(RCPagination)<PaginationProps>`
  ${({ prefixCls, position, size, theme }) => css`
    display: flex;
    justify-content: ${position};
    align-items: stretch;
    padding: 0;
    color: ${theme.text.onSurface.strong};
    list-style-type: none;
    user-select: none;

    ${size === Sizes.Small &&
    css`
      ${theme.label.small.normal};
      height: 32px;
    `};
    ${size === Sizes.Medium &&
    css`
      ${theme.label.medium.normal};
      height: 40px;
    `};
    ${size === Sizes.Large &&
    css`
      ${theme.label.large.normal};
      height: 48px;
    `};

    .${prefixCls} {
      &-item,
      &-prev,
      &-next,
      &-jump-prev,
      &-jump-next {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border: 1px solid ${theme.border.medium};
        cursor: pointer;

        ${size === Sizes.Small &&
        css`
          ${theme.label.medium.bold};
          width: 32px;
        `};

        ${size === Sizes.Medium &&
        css`
          ${theme.label.medium.bold};
          width: 40px;
        `};

        ${size === Sizes.Large &&
        css`
          ${theme.label.large.bold};
          width: 48px;
        `};

        &:not(.${prefixCls}-disabled):not(.${prefixCls}-item-active):hover {
          background-color: ${theme.background.inactive};
        }

        &:not(.${prefixCls}-disabled):not(.${prefixCls}-item-active):active {
          background-color: ${theme.focus.medium};
          color: ${theme.text.onFocus.strong};

          svg {
            fill: ${theme.text.onFocus.strong};
          }
        }

        &:focus-visible {
          box-shadow: 0 0 0 3px ${theme.focus.medium};
          border-color: ${theme.focus.strong};
          border-radius: 2px;
          outline: 0;
        }
      }

      &-prev,
      &-next,
      &-jump-prev,
      &-jump-next {
        > svg {
          width: 24px;
          height: 24px;

          ${size !== Sizes.Large &&
          css`
            width: 20px;
            height: 20px;
          `}
        }

        &.${prefixCls}-disabled {
          border-color: ${theme.border.subtle};
          color: ${theme.text.onSurface.inactive};
          cursor: not-allowed;

          > svg {
            fill: ${theme.text.onSurface.inactive};
          }
        }
      }

      &-next,
      &-next:last-child,
      &-item:not(:last-child),
      &-jump-next:last-child {
        border-left: none;
      }

      &-prev {
        border-radius: ${paginationConsts.radius} 0 0 ${paginationConsts.radius};
      }

      &-next {
        border-radius: 0 ${paginationConsts.radius} ${paginationConsts.radius} 0;
      }

      &-item:last-child {
        border-right: none;
      }

      &-jump-prev,
      &-jump-next {
        border-left: none;
        border-top: 1px solid ${theme.border.medium};
        border-bottom: 1px solid ${theme.border.medium};
        outline: none;
        display: flex;
      }

      &-item-active {
        background-color: ${theme.focus.strong};
        color: ${theme.text.onFocus.strong};
        border: none;
      }

      &-item-link {
        background: transparent;
        border: none;
        cursor: pointer;
        color: ${theme.text.onSurface.strong};

        &:after {
          display: block;
          content: '•••';
        }
      }

      &-options-quick-jumper,
      &-simple-pager {
        input {
          height: 100%;
          padding: ${paginationConsts.inputPadding[size]};
          border: 1px solid ${theme.border.medium};
          border-radius: ${paginationConsts.radius};
          background-color: ${theme.background.surface};
          color: ${theme.text.onSurface.strong};
          outline: none;
          opacity: 1;
        }
      }

      &-options-size-changer .rc-select-selector {
        min-height: initial;
        height: 100%;
      }

      &-options {
        display: flex;
        justify-content: center;
        align-items: stretch;
        margin-left: ${paginationConsts.margin};
        font-weight: normal;

        &-quick-jumper {
          display: flex;
          align-items: center;

          input {
            max-width: 112px;
            margin: 0 ${paginationConsts.margin};
          }
        }
      }

      &-total-text {
        display: flex;
        align-items: center;
        margin-right: ${paginationConsts.margin};
      }
    }

    &.${prefixCls}-simple {
      li {
        border-radius: ${paginationConsts.radius};
      }

      .${prefixCls}-prev {
        margin-right: ${paginationConsts.margin};
      }

      .${prefixCls}-next {
        margin-left: ${paginationConsts.margin};
        border-left: 1px solid ${theme.border.medium};
      }

      .${prefixCls}-simple-pager {
        display: flex;
        align-items: center;

        .${prefixCls}-slash {
          margin: 0 4px 0 ${paginationConsts.margin};
        }
      }
    }
  `}
`;

export { Pagination };
