import React, { forwardRef, ForwardRefExoticComponent, ComponentType, cloneElement } from 'react';

import classNames from 'classnames';

import { IPopconfirmProps } from './Popconfirm.types';
import * as Styled from './Popconfirm.styles';
import { POPCONFIRM_CLASS } from '../../utils/constants';
import { QuestionMarkCircleSolid } from '../Icon/components';
import PopconfirmTitle from './components/PopconfirmTitle/PopconfirmTitle';
import PopconfirmDescription from './components/PopconfirmDescription/PopconfirmDescription';
import { IPopconfirmTitleProps } from './components/PopconfirmTitle/PopconfirmTitle.types';
import { IPopconfirmDescriptionProps } from './components/PopconfirmDescription/PopconfirmDescription.types';
import PopconfirmActions from './components/PopconfirmActions/PopconfirmActions';
import Popover from '../Popover/Popover';
import { Sizes, Positions } from '../../types/types';

const Popconfirm = forwardRef<HTMLDivElement, IPopconfirmProps>(
  (
    { className, size = Sizes.Medium, title, description, actions, sentiment, icon, children, dropdownProps, ...rest },
    ref,
  ) => {
    const popup = (
      <Styled.Popconfirm size={size} {...rest}>
        <Styled.PopconfirmIcon sentiment={sentiment} size={size}>
          {icon || <QuestionMarkCircleSolid />}
        </Styled.PopconfirmIcon>
        <Styled.PopconfirmWrapper>
          {title && cloneElement(title, { size })}
          {description && cloneElement(description, { size })}
          <PopconfirmActions actions={actions} size={size} sentiment={sentiment} />
        </Styled.PopconfirmWrapper>
      </Styled.Popconfirm>
    );

    return (
      <Popover
        className={classNames(className, POPCONFIRM_CLASS)}
        popup={popup}
        popupPlacement={Positions.Bottom}
        arrow
        getPopupContainer={() => document.body}
        ref={ref}
        {...dropdownProps}
      >
        {children}
      </Popover>
    );
  },
) as ForwardRefExoticComponent<IPopconfirmProps> & {
  Title: ComponentType<IPopconfirmTitleProps>;
  Description: ComponentType<IPopconfirmDescriptionProps>;
};

Popconfirm.Title = PopconfirmTitle;
Popconfirm.Description = PopconfirmDescription;

export default Popconfirm;
