export const textConsts = {
  gutter: {
    section: '56px',
    h1: '56px',
    h2: '24px',
    h3: '24px',
    h4: '24px',
    h5: '16px',
    display: '24px',
    p: '24px',
    label: '24px',
    a: '24px',
    ul: '24px',
    ol: '24px',
    li: '16px',
  },
};
