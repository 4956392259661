import { ReactElement, forwardRef, useImperativeHandle, useRef } from 'react';

import { MenuItem as RCMenuItem } from 'rc-menu';
import { MenuItemProps as RCMenuItemProps } from 'rc-menu/lib/MenuItem';

import { getString } from '../../../utils/textUtils';
import MenuLabel from './MenuLabel';

export interface IMenuItemProps extends RCMenuItemProps {
  level?: number;
  path?: string;
}

const MenuItem = forwardRef<HTMLElement, IMenuItemProps>(
  ({ children, itemIcon, level, path, ...rest }, ref): ReactElement => {
    const menuItemRef = useRef<HTMLElement>(null);
    useImperativeHandle<HTMLElement | null, HTMLElement | null>(ref, () => menuItemRef.current);

    return (
      <RCMenuItem ref={menuItemRef} aria-label={getString(children)} {...rest}>
        <MenuLabel parentRef={menuItemRef} level={level} path={path} itemIcon={itemIcon}>
          {children}
        </MenuLabel>
      </RCMenuItem>
    );
  },
);

export default MenuItem;
