import { __assign } from "tslib";
var parse = function (e) { return parseInt(e, 10); };
var mapSelectData = function (label, searchBy) {
    if (searchBy === void 0) { searchBy = undefined; }
    return function (item) {
        return typeof searchBy !== 'undefined'
            ? { value: item.id, key: item.id, children: item[label], optionFilterProp: item[searchBy] }
            : { value: item.id, key: item.id, children: item[label] };
    };
};
var newMapSelectData = function (label, idKey, searchBy) {
    return function (item) {
        if (label === 'hierarchyElementNm' || label === 'HierarchyElementNm') {
            return __assign({ value: item[idKey], key: item[idKey], children: item[label] }, item);
        }
        return typeof searchBy !== 'undefined'
            ? { value: item[idKey], key: item[idKey], children: item[label], optionFilterProp: item[searchBy] }
            : { value: item[idKey], key: item[idKey], children: item[label] };
    };
};
var sortSelectData = function (a, b) { var _a, _b; return (((_a = a.children) !== null && _a !== void 0 ? _a : '') > ((_b = b.children) !== null && _b !== void 0 ? _b : '') ? 1 : -1); };
export { mapSelectData, parse, newMapSelectData, sortSelectData };
