import { __assign } from "tslib";
var PROVIDER = 'dp';
var COUNTRY = 'co';
var CITY = 'ci';
var COMPANY = 'o';
var ASSET_CLASS = 'at';
var ASSET = 'a';
var ASSET_UNIT_TYPE = 'ut';
var ASSET_UNIT_SUBTYPE = 'ust';
var UNIT = 'unit';
var YEAR_RANGE = 'yearRange';
var GROUP_BY = 'groupBy';
var VERSION_DATE = 'versionDate';
var DATA_TYPE = 'dataType';
var IS_ABSOLUTE = 'isAbsolute';
var IS_DELTA_PROVIDER = 'isDeltaByProvider';
var BASE_PROVIDER_KEY = 'BaseProviderKey';
var VERSION_DATE_1 = 'versionDate1';
var VERSION_DATE_2 = 'versionDate2';
var SINCE_LAST_UPDATED = 'sinceLastUpdate';
export var URL_PARAMS = {
    PROVIDER: PROVIDER,
    COUNTRY: COUNTRY,
    CITY: CITY,
    COMPANY: COMPANY,
    ASSET_CLASS: ASSET_CLASS,
    ASSET: ASSET,
    ASSET_UNIT_TYPE: ASSET_UNIT_TYPE,
    ASSET_UNIT_SUBTYPE: ASSET_UNIT_SUBTYPE,
    UNIT: UNIT,
    YEAR_RANGE: YEAR_RANGE,
    GROUP_BY: GROUP_BY,
    VERSION_DATE: VERSION_DATE,
    DATA_TYPE: DATA_TYPE,
    IS_ABSOLUTE: IS_ABSOLUTE,
    IS_DELTA_PROVIDER: IS_DELTA_PROVIDER,
    BASE_PROVIDER_KEY: BASE_PROVIDER_KEY,
    VERSION_DATE_1: VERSION_DATE_1,
    VERSION_DATE_2: VERSION_DATE_2,
    SINCE_LAST_UPDATED: SINCE_LAST_UPDATED,
};
var MaintenanceSearchParamsBuilder = /** @class */ (function () {
    function MaintenanceSearchParamsBuilder(params) {
        this.params = params || {};
    }
    MaintenanceSearchParamsBuilder.prototype.isValidParams = function (params) {
        if (params === null)
            return false;
        if (Array.isArray(params))
            return params.length !== 0;
        return true;
    };
    MaintenanceSearchParamsBuilder.prototype.addParams = function (paramType, params) {
        var _a;
        this.params = __assign(__assign({}, this.params), (_a = {}, _a[paramType] = Array.isArray(params) ? params : [params], _a));
        return this;
    };
    MaintenanceSearchParamsBuilder.prototype.validateAndAddParam = function (paramType, params) {
        if (this.isValidParams(params)) {
            return this.addParams(paramType, params);
        }
        return this;
    };
    MaintenanceSearchParamsBuilder.prototype.providers = function (providers) {
        return this.validateAndAddParam(PROVIDER, providers);
    };
    MaintenanceSearchParamsBuilder.prototype.countries = function (countries) {
        return this.validateAndAddParam(COUNTRY, countries);
    };
    MaintenanceSearchParamsBuilder.prototype.cities = function (cities) {
        return this.validateAndAddParam(CITY, cities);
    };
    MaintenanceSearchParamsBuilder.prototype.companies = function (companies) {
        return this.validateAndAddParam(COMPANY, companies);
    };
    MaintenanceSearchParamsBuilder.prototype.assetClasses = function (assetClasses) {
        return this.validateAndAddParam(ASSET_CLASS, assetClasses);
    };
    MaintenanceSearchParamsBuilder.prototype.assets = function (assets) {
        return this.validateAndAddParam(ASSET, assets);
    };
    MaintenanceSearchParamsBuilder.prototype.assetUnitTypes = function (assetUnitTypes) {
        return this.validateAndAddParam(ASSET_UNIT_TYPE, assetUnitTypes);
    };
    MaintenanceSearchParamsBuilder.prototype.assetUnitSubtypes = function (assetUnitSubtypes) {
        return this.validateAndAddParam(ASSET_UNIT_SUBTYPE, assetUnitSubtypes);
    };
    MaintenanceSearchParamsBuilder.prototype.units = function (units) {
        return this.validateAndAddParam(UNIT, units);
    };
    MaintenanceSearchParamsBuilder.prototype.yearRange = function (yearRange) {
        return this.validateAndAddParam(YEAR_RANGE, yearRange);
    };
    MaintenanceSearchParamsBuilder.prototype.groupBy = function (groupBy) {
        return this.validateAndAddParam(GROUP_BY, groupBy);
    };
    MaintenanceSearchParamsBuilder.prototype.versionDate = function (versionDate) {
        return this.validateAndAddParam(VERSION_DATE, versionDate);
    };
    MaintenanceSearchParamsBuilder.prototype.dataType = function (dataType) {
        return this.validateAndAddParam(DATA_TYPE, dataType);
    };
    MaintenanceSearchParamsBuilder.prototype.isAbsolute = function (isAbsolute) {
        return this.validateAndAddParam(IS_ABSOLUTE, isAbsolute);
    };
    MaintenanceSearchParamsBuilder.prototype.isDeltaByProvider = function (isDeltaByProvider) {
        return this.validateAndAddParam(IS_DELTA_PROVIDER, isDeltaByProvider);
    };
    MaintenanceSearchParamsBuilder.prototype.baseProviderKey = function (baseProviderKey) {
        return this.validateAndAddParam(BASE_PROVIDER_KEY, baseProviderKey);
    };
    MaintenanceSearchParamsBuilder.prototype.versionDate1 = function (versionDate1) {
        return this.validateAndAddParam(VERSION_DATE_1, versionDate1);
    };
    MaintenanceSearchParamsBuilder.prototype.versionDate2 = function (versionDate2) {
        return this.validateAndAddParam(VERSION_DATE_2, versionDate2);
    };
    MaintenanceSearchParamsBuilder.prototype.sinceLastUpdate = function (sinceLastUpdate) {
        return this.validateAndAddParam(SINCE_LAST_UPDATED, sinceLastUpdate);
    };
    MaintenanceSearchParamsBuilder.prototype.copy = function () {
        return new MaintenanceSearchParamsBuilder(this.params);
    };
    MaintenanceSearchParamsBuilder.prototype.getParams = function () {
        return Object.assign({}, this.params);
    };
    MaintenanceSearchParamsBuilder.prototype.toString = function () {
        var _this = this;
        var paramsArray = [];
        Object.keys(this.params).forEach(function (key) {
            if (Array.isArray(_this.params[key])) {
                _this.params[key].forEach(function (paramValue) {
                    paramsArray.push([key, "".concat(paramValue)]);
                });
            }
        });
        return new URLSearchParams(paramsArray).toString();
    };
    return MaintenanceSearchParamsBuilder;
}());
export { MaintenanceSearchParamsBuilder };
