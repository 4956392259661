import React from 'react';

import { IButtonProps } from '../../../Button/Button.types';
import Button from '../../../Button/Button';
import { popconfirmConsts } from '../../Popconfirm.consts';
import * as Styled from '../../Popconfirm.styles';
import { IPopconfirmActionsProps } from './PopconfirmActions.types';
import { Sentiments, Variants } from '../../../../types/types';

const PopconfirmActions = ({ actions, size, sentiment }: IPopconfirmActionsProps) => (
  <Styled.PopconfirmActions>
    {actions?.map((actionButton) => {
      const defaultProps: Pick<IButtonProps, 'sentiment'> = {
        ...((!actionButton.props?.variant || actionButton.props?.variant === Variants.Filled) &&
          sentiment && { sentiment: sentiment === Sentiments.Warning ? 'default' : sentiment }),
      };

      const handleOnClick = () => {
        if (actionButton?.action) {
          actionButton.action();
        }
      };

      return (
        <Button
          key={actionButton.label}
          {...defaultProps}
          size={popconfirmConsts[size].actionSize}
          onClick={handleOnClick}
          {...actionButton.props}
        >
          {actionButton.label}
        </Button>
      );
    })}
  </Styled.PopconfirmActions>
);

export default PopconfirmActions;
