/* Focus and key events are disabled since menu handles them internally.
   onClick added to circumvent event propagation being blocked by tooltip. */

import {
  cloneElement,
  forwardRef,
  isValidElement,
  MouseEventHandler,
  ReactElement,
  RefObject,
  useContext,
  HTMLAttributes,
} from 'react';

import classNames from 'classnames';

import { MENU_CLASS } from '../../../utils/constants';
import { IMenuProps } from '../Menu.types';
import Tooltip from '../../Tooltip/Tooltip';
import MenuContext from '../MenuContext';
import { Positions } from '../../../types/types';

export type MenuLabelRequiredTypes = 'itemIcon' | 'className' | 'children' | 'size';

export interface IMenuLabelProps
  extends Pick<IMenuProps, MenuLabelRequiredTypes>,
    Omit<HTMLAttributes<HTMLElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'onSelect'> {
  parentRef?: RefObject<HTMLElement>;
  level?: number;
  path?: string;
}

const MenuLabel = forwardRef<HTMLElement, IMenuLabelProps>(
  ({ children, itemIcon, parentRef, level, path, className, ...rest }, ref): ReactElement => {
    const { inlineCollapsed, itemRender } = useContext(MenuContext);

    const handleOnClick: MouseEventHandler = () => {
      if (parentRef?.current && inlineCollapsed) {
        parentRef.current.click();
      }
    };

    const elementProps = {
      ref,
      className: classNames(`${MENU_CLASS}-label`, className),
      ...rest,
    };

    const content = (
      <>
        {isValidElement(itemIcon) ? <span className={`${MENU_CLASS}-label-icon`}>{itemIcon}</span> : null}
        {children ? <span className={`${MENU_CLASS}-label-text`}>{children}</span> : null}
      </>
    );

    const element =
      path && children && itemRender ? (
        cloneElement(itemRender({ path, children: content }), elementProps)
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={handleOnClick} {...elementProps}>
          {content}
        </span>
      );

    if (inlineCollapsed && level === 0) {
      return <Tooltip offset={20} placement={Positions.Right} trigger={element} overlay={children} />;
    }

    return element;
  },
);

export default MenuLabel;
