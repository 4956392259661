import { HeadingLevel } from '../Text/components/Heading/Heading.types';
import { LabelSize } from '../Text/components/Label/Label.types';
import { ModalSize, ModalPadding } from './Modal.types';
import { Sizes, Spacings } from '../../types/types';

type ModalConstsProps = {
  [size in ModalSize]: {
    titleLevel: HeadingLevel;
    descriptionSize: LabelSize;
    titleAndDescriptionGap: string;
    titleBarPadding: {
      [padding in ModalPadding]: string;
    };
    actionBarPadding: {
      [padding in ModalPadding]: string;
    };
    bodyPadding: {
      [padding in ModalPadding]: string;
    };
    closeButton: {
      width?: string;
      height?: string;
      position: {
        [padding in ModalPadding]: {
          top: string;
          right: string;
        };
      };
    };
  };
};

export const modalConsts: ModalConstsProps = {
  [Sizes.Small]: {
    titleLevel: 4,
    descriptionSize: Sizes.ExtraSmall,
    titleAndDescriptionGap: '2px',
    titleBarPadding: { [Spacings.Tight]: '22px 55px 22px 16px', [Spacings.Generous]: '30px 55px 30px 24px' },
    actionBarPadding: { [Spacings.Tight]: '16px', [Spacings.Generous]: '24px' },
    bodyPadding: { [Spacings.Tight]: '16px', [Spacings.Generous]: '24px' },
    closeButton: {
      width: '28px',
      height: '32px',
      position: {
        [Spacings.Tight]: {
          top: '16px',
          right: '12px',
        },
        [Spacings.Generous]: {
          top: '24px',
          right: '20px',
        },
      },
    },
  },
  [Sizes.Medium]: {
    titleLevel: 3,
    descriptionSize: Sizes.Small,
    titleAndDescriptionGap: '4px',
    titleBarPadding: { [Spacings.Tight]: '28px 60px 28px 20px', [Spacings.Generous]: '40px 60px 40px 32px' },
    actionBarPadding: { [Spacings.Tight]: '20px', [Spacings.Generous]: '32px' },
    bodyPadding: { [Spacings.Tight]: '20px', [Spacings.Generous]: '32px' },
    closeButton: {
      width: '28px',
      height: '40px',
      position: {
        [Spacings.Tight]: {
          top: '20px',
          right: '16px',
        },
        [Spacings.Generous]: {
          top: '32px',
          right: '28px',
        },
      },
    },
  },
  [Sizes.Large]: {
    titleLevel: 2,
    descriptionSize: Sizes.Medium,
    titleAndDescriptionGap: '8px',
    titleBarPadding: { [Spacings.Tight]: '34px 70px 34px 24px', [Spacings.Generous]: '50px 60px 50px 40px' },
    actionBarPadding: { [Spacings.Tight]: '24px', [Spacings.Generous]: '40px' },
    bodyPadding: { [Spacings.Tight]: '24px', [Spacings.Generous]: '40px' },
    closeButton: {
      width: '36px',
      height: '48px',
      position: {
        [Spacings.Tight]: {
          top: '24px',
          right: '18px',
        },
        [Spacings.Generous]: {
          top: '40px',
          right: '34px',
        },
      },
    },
  },
};
