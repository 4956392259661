import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
//Here we have foriegnKeyReplica flag which indicate that this screen has two foriegn key column
//with same data
var assetUnitSubtype = /** @class */ (function (_super) {
    __extends(assetUnitSubtype, _super);
    function assetUnitSubtype(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            assetUnitSubtypeCd: {
                required: true,
                error: 'Enter Asset Unit Subtype Cd.',
            },
            assetUnitTypeCd: {
                required: true,
                error: 'Select Asset unit Type.',
            },
        };
        var foreginKeyParams = [
            {
                path: 'assetUnitType',
                key: 'assetUnitTypeKey',
                cdName: 'assetUnitTypeCd',
                replicaPath: 'assetUnitTypeReplica',
            },
            {
                path: 'measureUnit',
                key: 'universalMeasureUnitKey',
                cdName: 'measureUnitCd',
                foreignReplica: true,
                foreignReplicaCol: 'universalMeasureUnit',
                foreignReplicaKey: 'universalMeasureUnitKey',
                foreignReplicaColPath: 'universalMeasureUt',
                replicaPath: 'measureUnitReplica',
            },
            {
                path: 'measureUnit',
                key: 'universalMeasureUnitKey',
                cdName: 'measureUnitCd',
                foreignReplica: true,
                foreignReplicaCol: 'platformMeasureUnit',
                foreignReplicaKey: 'platformMeasureUnitKey',
                foreignReplicaColPath: 'platformMeasureUt',
                replicaPath: 'platformMeasureUnitReplica',
            },
        ];
        var columns = [
            {
                field: 'assetUnitTypeCd',
                title: 'Asset Unit Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetUnitTypeCd',
                referenceTitle: 'Asset Unit Type',
                urlEndPoint: 'assetUnitType',
                replicaPath: 'assetUnitTypeReplica',
            },
            {
                field: 'assetUnitSubtypeCd',
                title: 'Asset Unit Subtype Cd',
            },
            {
                field: 'assetUnitSubtypeDesc',
                title: 'Asset Unit Subtype Desc Title',
            },
            {
                field: 'universalMeasureUnit',
                title: 'Universal Measure Unit Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'measureUnitCd',
                referenceTitle: 'Measure Unit',
                urlEndPoint: 'measureUnit',
                replicaPath: 'measureUnitReplica',
                filterOnCondition: true,
                filterIndicator: 'universalUnitInd',
            },
            {
                field: 'platformMeasureUnit',
                title: ' Platform Measure Unit Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'measureUnitCd',
                referenceTitle: 'Measure Unit',
                urlEndPoint: 'measureUnit',
                foreignReplica: true,
                replicaPath: 'platformMeasureUnitReplica',
                filterOnCondition: true,
                filterIndicator: 'platformUnitInd',
            },
            {
                field: 'solomonComplexityFctr',
                title: 'Solomon Complexity Factor',
                filter: 'numeric',
            },
            {
                field: 'nelsonComplexityFctr',
                title: 'Nelson Complexity Factor',
                filter: 'numeric',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    assetUnitSubtype.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "assetUnitSubtype", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "assetUnitSubtypeCd", foreginKeyParams: this.state.foreginKeyParams, foreignReplicaScreen: "replica" }));
    };
    return assetUnitSubtype;
}(Component));
export default assetUnitSubtype;
