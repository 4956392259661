import Trigger from 'rc-trigger';
import classNames from 'classnames';

import * as Styled from './Popover.styles';
import { IPopoverProps } from './Popover.types';
import { POPOVER_CLASS, POPOVER_OVERLAY_CLASS } from '../../utils/constants';
import placements from './Popover.consts';

const PopoverComponent = ({
  className,
  popupClassName,
  popup,
  arrow,
  children,
  action = 'click',
  ...rest
}: IPopoverProps) => {
  const childIsString = typeof popup === 'string';

  const Popup = () => (
    <>
      {arrow && <Styled.PopoverOverlayArrow className={`${POPOVER_CLASS}-arrow`} />}
      <Styled.PopoverOverlay padded={childIsString}>{popup}</Styled.PopoverOverlay>
    </>
  );

  return (
    <Trigger
      popupClassName={classNames(POPOVER_OVERLAY_CLASS, popupClassName, className, {
        [`${POPOVER_CLASS}-show-arrow`]: arrow,
      })}
      action={action}
      builtinPlacements={placements}
      popupPlacement="bottomLeft"
      {...rest}
      prefixCls={POPOVER_CLASS}
      popup={<Popup />}
    >
      {children}
    </Trigger>
  );
};

const Popover = Styled.Popover(PopoverComponent, POPOVER_CLASS);

export default Popover;
