import { AlertSize } from './Alert.types';

type AlertConstsType = {
  icon: {
    [size in AlertSize]: number;
  };
  minHeight: {
    [size in AlertSize]: number;
  };
  padding: {
    [size in AlertSize]: string;
  };
  labelMarginTop: {
    [size in AlertSize]: number;
  };
  childrenMarginTop: {
    [size in AlertSize]: number;
  };
};

export const alertConsts: AlertConstsType = {
  icon: {
    small: 24,
    medium: 26,
    large: 28,
  },
  minHeight: {
    small: 44,
    medium: 52,
    large: 60,
  },
  padding: {
    small: '8px 16px',
    medium: '12px 20px',
    large: '16px 20px',
  },
  labelMarginTop: {
    small: 4,
    medium: 3,
    large: 3,
  },
  childrenMarginTop: {
    small: 2,
    medium: 2,
    large: 2,
  },
};
