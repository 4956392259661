import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
import { dayWidth, unitHeight } from './../../MaintenancePanel.styles';
export var StyledPanelRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: ", "px;\n"], ["\n  display: flex;\n  height: ", "px;\n"])), unitHeight);
export var StyledPanelRowLineWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-right: ", "px;\n"], ["\n  position: relative;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-right: ", "px;\n"])), dayWidth / 2);
export var StyledPanelRowLineTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  visibility: ", ";\n  position: absolute;\n  height: 16px;\n  top: -12px;\n  left: 10px;\n\n  label {\n    margin-bottom: 0;\n  }\n"], ["\n  visibility: ", ";\n  position: absolute;\n  height: 16px;\n  top: -12px;\n  left: 10px;\n\n  label {\n    margin-bottom: 0;\n  }\n"])), function (_a) {
    var unexpected = _a.unexpected, hide = _a.hide;
    return (!unexpected && hide ? 'hidden' : 'visible');
});
var rowColors = {
    derate: 'warning',
    off: 'negative',
    on: 'positive',
};
export var StyledPanelRowLine = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  border-radius: 5px;\n  height: 4px;\n  position: relative;\n  width: ", "px;\n\n  &:before {\n    box-sizing: border-box;\n    position: absolute;\n    content: '';\n    background-color: ", ";\n    border: 2px solid ", ";\n    width: 12px;\n    height: 12px;\n    border-radius: 8px;\n    left: -2px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n"], ["\n  ", ";\n  border-radius: 5px;\n  height: 4px;\n  position: relative;\n  width: ", "px;\n\n  &:before {\n    box-sizing: border-box;\n    position: absolute;\n    content: '';\n    background-color: ", ";\n    border: 2px solid ", ";\n    width: 12px;\n    height: 12px;\n    border-radius: 8px;\n    left: -2px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n"])), function (_a) {
    var theme = _a.theme, type = _a.type, unexpected = _a.unexpected, hide = _a.hide;
    return unexpected
        ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          background: repeating-linear-gradient(\n            to right,\n            ", ",\n            ", " 5px,\n            transparent 5px,\n            transparent 10px\n          );\n        "], ["\n          background: repeating-linear-gradient(\n            to right,\n            ", ",\n            ", " 5px,\n            transparent 5px,\n            transparent 10px\n          );\n        "])), theme.system.strong[rowColors[type]], theme.system.strong[rowColors[type]]) : css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          background-color: ", ";\n          visibility: ", ";\n        "], ["\n          background-color: ", ";\n          visibility: ", ";\n        "])), theme.system.strong[rowColors[type]], hide ? 'hidden' : 'visible');
}, function (_a) {
    var days = _a.days;
    return dayWidth * days - dayWidth / 2;
}, function (_a) {
    var theme = _a.theme, type = _a.type;
    return theme.system.strong[rowColors[type]];
}, function (_a) {
    var theme = _a.theme;
    return theme.background.base;
});
export var StyledUnexpectedLabel = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.subtle;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
