import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
//asset rel *** warning****
var assetRelationship = /** @class */ (function (_super) {
    __extends(assetRelationship, _super);
    function assetRelationship(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            assetRelationshipTypeCd: {
                required: true,
                error: 'Please enter Asset Relationship Type Key',
            },
            assetNm: {
                required: true,
                error: 'Please enter To Asset key',
            },
            fromAssetCol: {
                required: true,
                error: 'Please enter From Asset key',
            },
        };
        var foreginKeyParams = [
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
                replicaPath: 'dataProviderReplica',
            },
            {
                path: 'assetRelationshipType',
                key: 'assetRelationshipTypeKey',
                cdName: 'assetRelationshipTypeCd',
                replicaPath: 'assetRelationshipTypeReplica',
            },
            {
                path: 'dataSet',
                key: 'datasetKey',
                cdName: 'datasetNm',
                replicaPath: 'dataSetReplica',
            },
            {
                path: 'person',
                key: 'userKey',
                cdName: 'shellUserId',
                replicaPath: 'personReplica',
            },
            {
                path: 'assets',
                key: 'toAssetKey',
                cdName: 'assetNm',
                replicaPath: 'toAssetReplica',
            },
            {
                path: 'assets',
                key: 'toAssetKey',
                cdName: 'assetNm',
                foreignReplica: true,
                foreignReplicaCol: 'fromAssetCol',
                foreignReplicaKey: 'fromAssetKey',
                foreignReplicaColPath: 'fromAssetpt',
                replicaPath: 'fromAssetReplica',
            },
            {
                path: 'intelligentSourceType',
                key: 'intelligenceSourceTypeKey',
                cdName: 'intelligenceSourceTypeCd',
                replicaPath: 'intelligentSourceTypeReplica',
            },
        ];
        var columns = [
            {
                field: 'assetRelationshipTypeCd',
                title: 'AssetRelationship Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetRelationshipTypeCd',
                referenceTitle: 'Asset Relationship Type',
                urlEndPoint: 'assetRelationshipType',
                replicaPath: 'assetRelationshipTypeReplica',
            },
            {
                field: 'assetNm',
                title: 'To Asset Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetNm',
                referenceTitle: 'Asset Nm',
                urlEndPoint: 'assets',
                replicaPath: 'toAssetReplica',
            },
            {
                field: 'fromAssetCol',
                title: 'From Asset Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetNm',
                referenceTitle: 'Asset Nm',
                urlEndPoint: 'assets',
                foreignReplica: true,
                replicaPath: 'fromAssetReplica',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider',
                urlEndPoint: 'dataProvider',
                replicaPath: 'dataProviderReplica',
                loadDependent: 'loadSource',
            },
            {
                field: 'datasetNm',
                title: 'Data set Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'datasetNm',
                referenceTitle: 'Dataset Nm',
                urlEndPoint: 'dataSet',
                replicaPath: 'dataSetReplica',
                loadDependecy: 'dataProviderKey',
                loadField: 'dataProviderAbbr',
            },
            {
                field: 'reportDt',
                title: 'Report Dt',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'intelligenceSourceImg',
                title: 'Intelligence Source Img',
            },
            {
                field: 'intelligenceSourceUrl',
                title: 'Intelligence Source Url',
            },
            {
                field: 'intelligenceSourceTxt',
                title: 'Intelligence Source Txt',
            },
            {
                field: 'intelligenceSourceTypeCd',
                title: 'IntelligenceSource Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'intelligenceSourceTypeCd',
                referenceTitle: 'Intelligence Source Type',
                urlEndPoint: 'intelligentSourceType',
                replicaPath: 'intelligentSourceTypeReplica',
            },
            {
                field: 'shellUserId',
                title: 'User Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
                replicaPath: 'personReplica',
            },
            {
                field: 'versionTerminationDt',
                title: 'Termination Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'versionEffectiveDt',
                title: 'Effective Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    assetRelationship.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "assetRelationship", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "intelligenceSourceTxt", foreginKeyParams: this.state.foreginKeyParams, foreignReplicaScreen: "replica" }));
    };
    return assetRelationship;
}(Component));
export default assetRelationship;
