import { __spreadArray } from "tslib";
export var addRegionToTree = function (treeData, data) {
    var _a;
    var index = 0;
    while (index < treeData.length && ((_a = treeData[index].title) !== null && _a !== void 0 ? _a : '') < data.acmRegion) {
        index++;
    }
    treeData.splice(index, 0, {
        title: data.acmRegion,
        key: data.regionHierarchyKey,
        children: [
            {
                title: data.acmCountry,
                key: data.countryHierarchyKey,
                children: [
                    {
                        title: data.assetNm,
                        key: data.assetHierarchyKey,
                    },
                ],
            },
        ],
    });
};
export var addCountryToTree = function (treeData, data) {
    var _a;
    if (treeData === void 0) { treeData = []; }
    var index = 0;
    while (index < treeData.length && ((_a = treeData[index].title) !== null && _a !== void 0 ? _a : '') < data.acmCountry) {
        index++;
    }
    treeData.splice(index, 0, {
        title: data.acmCountry,
        key: data.countryHierarchyKey,
        children: [
            {
                title: data.assetNm,
                key: data.assetHierarchyKey,
            },
        ],
    });
};
export var addAssetToTree = function (treeData, data) {
    var _a;
    if (treeData === void 0) { treeData = []; }
    var index = 0;
    while (index < treeData.length && ((_a = treeData[index].title) !== null && _a !== void 0 ? _a : '') < data.assetNm) {
        index++;
    }
    treeData.splice(index, 0, {
        title: data.assetNm,
        key: data.assetHierarchyKey,
    });
};
export var transformIntoTreeData = function (data) {
    var _a, _b, _c, _d;
    var treeData = [];
    var _loop_1 = function (i) {
        var regionIndex = treeData.findIndex(function (region) { return region.key === data[i].regionHierarchyKey; });
        if (regionIndex === -1) {
            addRegionToTree(treeData, data[i]);
        }
        else {
            var countryIndex = (_a = treeData[regionIndex].children) === null || _a === void 0 ? void 0 : _a.findIndex(function (country) { return country.key === data[i].countryHierarchyKey; });
            if (countryIndex === -1) {
                addCountryToTree(treeData[regionIndex].children, data[i]);
            }
            else if (countryIndex !== undefined) {
                var assetIndex = (_c = (_b = treeData[regionIndex].children) === null || _b === void 0 ? void 0 : _b[countryIndex].children) === null || _c === void 0 ? void 0 : _c.findIndex(function (asset) { return asset.key === data[i].assetHierarchyKey; });
                if (assetIndex === -1) {
                    addAssetToTree((_d = treeData[regionIndex].children) === null || _d === void 0 ? void 0 : _d[countryIndex].children, data[i]);
                }
            }
        }
    };
    for (var i = 0; i < data.length; i++) {
        _loop_1(i);
    }
    return treeData;
};
export var getKeysOnCheck = function (info, checkedKeys, treeData) {
    var _a, _b, _c, _d, _e;
    var updateCheckedKeys = __spreadArray(__spreadArray([], checkedKeys, true), [info.node.key], false);
    // Remove all children if node is a country or a region
    if (info.node.pos.length === 3) {
        //node is a region
        var pos = info.node.pos.split('-');
        var rIndex = parseInt(pos[1], 10);
        (_a = treeData[rIndex].children) === null || _a === void 0 ? void 0 : _a.forEach(function (country) {
            var _a;
            updateCheckedKeys = updateCheckedKeys.filter(function (key) { return key !== country.key; });
            (_a = country.children) === null || _a === void 0 ? void 0 : _a.forEach(function (asset) {
                updateCheckedKeys = updateCheckedKeys.filter(function (key) { return key !== asset.key; });
            });
        });
    }
    else if (info.node.pos.length === 5) {
        //node is a country
        var pos = info.node.pos.split('-');
        var rIndex = parseInt(pos[1], 10);
        var cIndex = parseInt(pos[2], 10);
        (_c = (_b = treeData[rIndex].children) === null || _b === void 0 ? void 0 : _b[cIndex].children) === null || _c === void 0 ? void 0 : _c.forEach(function (asset) {
            updateCheckedKeys = updateCheckedKeys.filter(function (key) { return key !== asset.key; });
        });
        var allSelected_1 = true;
        (_d = treeData[rIndex].children) === null || _d === void 0 ? void 0 : _d.forEach(function (country) {
            if (!updateCheckedKeys.includes(country.key)) {
                allSelected_1 = false;
            }
        });
        if (allSelected_1) {
            (_e = treeData[rIndex].children) === null || _e === void 0 ? void 0 : _e.forEach(function (country) {
                var _a;
                updateCheckedKeys = updateCheckedKeys.filter(function (key) { return key !== country.key; });
                (_a = country.children) === null || _a === void 0 ? void 0 : _a.forEach(function (asset) {
                    updateCheckedKeys = updateCheckedKeys.filter(function (key) { return key !== asset.key; });
                });
            });
            updateCheckedKeys.push(treeData[rIndex].key);
        }
    }
    return updateCheckedKeys;
};
export var getKeysOnUncheck = function (info, checkedKeys, treeData) {
    var _a, _b, _c, _d;
    if (checkedKeys.includes(info.node.key)) {
        return checkedKeys.filter(function (key) { return key !== info.node.key; });
    }
    else {
        var updateCheckedKeys_1 = __spreadArray([], checkedKeys, true);
        if (info.node.pos.length === 7) {
            //node is an asset
            var pos = info.node.pos.split('-');
            var rIndex_1 = parseInt(pos[1], 10);
            var cIndex_1 = parseInt(pos[2], 10);
            if (checkedKeys.includes(treeData[rIndex_1].key)) {
                updateCheckedKeys_1 = updateCheckedKeys_1.filter(function (key) { return key !== treeData[rIndex_1].key; });
                (_a = treeData[rIndex_1].children) === null || _a === void 0 ? void 0 : _a.forEach(function (country) {
                    var _a, _b;
                    if (country.key !== ((_a = treeData[rIndex_1].children) === null || _a === void 0 ? void 0 : _a[cIndex_1].key)) {
                        updateCheckedKeys_1.push(country.key);
                    }
                    else {
                        (_b = country.children) === null || _b === void 0 ? void 0 : _b.forEach(function (asset) {
                            if (asset.key !== info.node.key) {
                                updateCheckedKeys_1.push(asset.key);
                            }
                        });
                    }
                });
            }
            else {
                updateCheckedKeys_1 = updateCheckedKeys_1.filter(function (key) { var _a; return key !== ((_a = treeData[rIndex_1].children) === null || _a === void 0 ? void 0 : _a[cIndex_1].key); });
                (_c = (_b = treeData[rIndex_1].children) === null || _b === void 0 ? void 0 : _b[cIndex_1].children) === null || _c === void 0 ? void 0 : _c.forEach(function (asset) {
                    if (asset.key !== info.node.key) {
                        updateCheckedKeys_1.push(asset.key);
                    }
                });
            }
        }
        else {
            // node is a country
            var pos = info.node.pos.split('-');
            var rIndex_2 = parseInt(pos[1], 10);
            if (updateCheckedKeys_1.includes(treeData[rIndex_2].key)) {
                updateCheckedKeys_1 = updateCheckedKeys_1.filter(function (key) { return key !== treeData[rIndex_2].key; });
                (_d = treeData[rIndex_2].children) === null || _d === void 0 ? void 0 : _d.forEach(function (country) {
                    if (country.key !== info.node.key) {
                        updateCheckedKeys_1.push(country.key);
                    }
                });
            }
        }
        return updateCheckedKeys_1;
    }
};
