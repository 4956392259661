import styled, { css } from 'styled-components';

import { TABLE_CLASS } from '../../../../utils/constants';

export const StyledResizer = styled.div(
  ({ theme }) => css`
    width: 2px;
    border-radius: 1px;
    margin-left: auto;
    cursor: ew-resize;
    height: 18px;
    user-select: none;
    background-color: ${theme.border.medium};
  `,
);

export const Resizer = ({ ...rest }) => (
  <StyledResizer className={`${TABLE_CLASS}-resizer`} aria-label="Resizer" {...rest} />
);
