import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import { IOverlayProps } from './Overlay.types';

const Overlay = styled.span<Pick<IOverlayProps, 'arrow'> & { padded?: boolean }>(({ padded, arrow, theme }) => {
  const width = '20px';
  const height = '12px';

  return css`
    z-index: ${Z_INDEX.TOOLTIP};
    border-radius: 4px;
    background: ${theme.background.raised};
    color: ${theme.text.onSurface.strong};
    box-shadow: ${theme.elevation.level0};

    ${padded &&
    css`
      padding: 4px 8px;
    `}

    ${!arrow &&
    css`
      &.top {
        margin-bottom: -10px;
      }

      &.bottom {
        margin-top: -10px;
      }

      &.left {
        margin-right: -10px;
      }

      &.right {
        margin-left: -10px;
      }
    `}
      
      ${arrow &&
    css`
      .tip {
        position: absolute;
        width: ${width};
        height: ${height};
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;

        &:after {
          content: '';
          width: 10px;
          height: 10px;
          top: 0;
          left: 0;
          transform-origin: 0 0;
          position: absolute;
          background: ${theme.background.raised};
          box-shadow: ${theme.elevation.level0};
          transform: rotate(45deg) translate(-50%, -50%);
        }
      }

      &.top .tip {
        top: 100%;

        &:after {
          left: 50%;
        }
      }

      &.bottom .tip {
        top: auto;
        bottom: 100%;

        &:after {
          left: 50%;
          top: 100%;
        }
      }

      &.right .tip {
        top: auto;
        left: auto;
        width: ${height};
        height: ${width};
        right: 100%;
        transform: translateY(-50%);

        &:after {
          left: 100%;
          top: 50%;
        }
      }

      &.left .tip {
        top: auto;
        width: ${height};
        height: ${width};
        left: 100%;
        transform: translateY(-50%);

        &:after {
          top: 50%;
        }
      }
    `}
  `;
});

export { Overlay };
