import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import GridTemplate from '../../commons/gridTemplate';
var dataProviderType = /** @class */ (function (_super) {
    __extends(dataProviderType, _super);
    function dataProviderType(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            dataProviderTypeCd: {
                required: true,
                error: 'Enter Data Provider Type Cd unique key.',
            },
        };
        var yesNoData = [{ ynValue: 'externalProviderInd' }];
        var columns = [
            {
                field: 'dataProviderTypeCd',
                title: 'Data Provider Type Cd',
            },
            {
                field: 'externalProviderInd',
                title: 'External Provider Ind',
                type: 'yesNo',
                yn: 'yn',
                ynRefer: 'externalProviderInd',
            },
            {
                field: 'dataProviderTypeDesc',
                title: 'Data Provider Type Desc Title',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            yesNoData: yesNoData,
        };
        return _this;
    }
    dataProviderType.prototype.render = function () {
        return (_jsx(GridTemplate, { path: "dataProviderType", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "dataProviderTypeCd", yesNoData: this.state.yesNoData }));
    };
    return dataProviderType;
}(Component));
export default dataProviderType;
