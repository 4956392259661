import { __assign, __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
export var OutsideAlerter = function (_a) {
    var onClickOutside = _a.onClickOutside, children = _a.children, rest = __rest(_a, ["onClickOutside", "children"]);
    var wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, onClickOutside);
    return (_jsx("div", __assign({}, rest, { ref: wrapperRef }, { children: children })));
};
