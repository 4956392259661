import { emptySplitApi } from './emptySplitApi';
import { resolveParamsObject } from 'common/utils/url/search-params';
export var filtersApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getHierarchyElement: builder.query({
            query: function () { return '/HierarchyElement?he=6&het=1&hegt=1'; },
        }),
        getCountries: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.regionIds, regionIds = _c === void 0 ? [] : _c;
                return ({
                    url: '/Countries',
                    params: resolveParamsObject({ re: regionIds }),
                });
            },
        }),
        getCities: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.regionIds, regionIds = _c === void 0 ? [] : _c, _d = _b.countryIds, countryIds = _d === void 0 ? [] : _d;
                return ({
                    url: '/Cities',
                    params: resolveParamsObject({ re: regionIds, co: countryIds }),
                });
            },
        }),
        getCompanies: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.cityIds, cityIds = _c === void 0 ? [] : _c, _d = _b.countryIds, countryIds = _d === void 0 ? [] : _d, _e = _b.regionIds, regionIds = _e === void 0 ? [] : _e;
                return ({
                    url: '/Companies',
                    params: resolveParamsObject({ re: regionIds, co: countryIds, ci: cityIds }),
                });
            },
        }),
        getAllCompanies: builder.query({
            query: function () { return ({
                url: '/Companies/allcompany',
            }); },
        }),
        getAssets: builder.query({
            query: function (_a) {
                var _b = _a.cityIds, cityIds = _b === void 0 ? [] : _b, _c = _a.companyIds, companyIds = _c === void 0 ? [] : _c, _d = _a.countryIds, countryIds = _d === void 0 ? [] : _d, _e = _a.regionIds, regionIds = _e === void 0 ? [] : _e;
                return ({
                    url: '/Assets/AssetFilter',
                    params: resolveParamsObject({ re: regionIds, co: countryIds, ci: cityIds, o: companyIds }),
                });
            },
        }),
        getAssetUnitTypes: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.assetClassIds, assetClassIds = _c === void 0 ? [] : _c, _d = _b.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _b.cityIds, cityIds = _e === void 0 ? [] : _e, _f = _b.companyIds, companyIds = _f === void 0 ? [] : _f, _g = _b.countryIds, countryIds = _g === void 0 ? [] : _g, _h = _b.regionIds, regionIds = _h === void 0 ? [] : _h;
                return ({
                    url: '/AssetUnitTypes?htype=ACM',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        ci: cityIds,
                        o: companyIds,
                        a: assetIds,
                        at: assetClassIds,
                    }),
                });
            },
        }),
        getAssetUnitSubTypes: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.unitTypeIds, unitTypeIds = _c === void 0 ? [] : _c, _d = _b.assetClassIds, assetClassIds = _d === void 0 ? [] : _d, _e = _b.assetIds, assetIds = _e === void 0 ? [] : _e, _f = _b.cityIds, cityIds = _f === void 0 ? [] : _f, _g = _b.regionIds, regionIds = _g === void 0 ? [] : _g, _h = _b.companyIds, companyIds = _h === void 0 ? [] : _h, _j = _b.countryIds, countryIds = _j === void 0 ? [] : _j;
                return ({
                    url: '/AssetUnitSubtypes?htype=ACM',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        ci: cityIds,
                        o: companyIds,
                        a: assetIds,
                        at: assetClassIds,
                        ut: unitTypeIds,
                    }),
                });
            },
        }),
        getAssetUnits: builder.query({
            query: function (_a) {
                var _b = _a.unitTypeIds, unitTypeIds = _b === void 0 ? [] : _b, _c = _a.unitSubTypeIds, unitSubTypeIds = _c === void 0 ? [] : _c, _d = _a.placeholder, placeholder = _d === void 0 ? false : _d, _e = _a.assetIds, assetIds = _e === void 0 ? [] : _e, _f = _a.cityIds, cityIds = _f === void 0 ? [] : _f, _g = _a.companyIds, companyIds = _g === void 0 ? [] : _g, _h = _a.countryIds, countryIds = _h === void 0 ? [] : _h;
                return ({
                    url: '/AssetUnits/AssetUnitFilter',
                    params: resolveParamsObject({
                        co: countryIds,
                        ci: cityIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        placeholder: [placeholder],
                    }),
                });
            },
        }),
        getDataProviders: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.type, type = _c === void 0 ? 2 : _c;
                return ({ url: '/DataProviders', params: { type: type } });
            },
        }),
        getUserSpecificDataProvider: builder.query({
            query: function (_a) {
                var _b = _a === void 0 ? {} : _a, _c = _b.type, type = _c === void 0 ? 2 : _c;
                return ({ url: '/DataProviders/UserSpecificDataProvider', params: { typeKey: type } });
            },
        }),
        getPlanningProbabilityTypes: builder.query({
            query: function () { return '/PlanningProbabilityTypes'; },
        }),
        getCapacityBasisTypes: builder.query({
            query: function () { return '/CapacityBasisTypes'; },
        }),
        getPlanningStatusTypes: builder.query({
            query: function () { return '/PlanningStatusTypes'; },
        }),
        getOperationalStatusTypes: builder.query({
            query: function () { return '/OperationalStatusTypes'; },
        }),
        getAcmHierarchyElement: builder.query({
            providesTags: ['ACMHierarchyElement'],
            query: function () { return '/AcmHierarchy/acmhierarchyelement'; },
        }),
        getHierarchyElementByName: builder.query({
            query: function (name) { return "/HierarchyElement?name=".concat(name); },
        }),
        getHierarchyCountries: builder.query({
            query: function () { return '/Countries?hegt=1&het=1&he=1'; },
        }),
        getACMCountryHierarchy: builder.query({
            query: function () { return ({
                url: process.env.REACT_APP_FAST_API_BASE_URL + '/acmcountryhierarchy',
            }); },
        }),
        getGeopoliticalEntities: builder.query({
            query: function () { return '/GeopoliticalEntities'; },
        }),
        getModuleLastUpdate: builder.query({
            query: function (moduleName) { return process.env.REACT_APP_FAST_API_BASE_URL + "/modulelastupdate/".concat(moduleName); },
        }),
    }); },
});
export var useGetAssetUnitSubTypesQuery = filtersApis.useGetAssetUnitSubTypesQuery, useGetAssetUnitTypesQuery = filtersApis.useGetAssetUnitTypesQuery, useGetDataProvidersQuery = filtersApis.useGetDataProvidersQuery, useGetAssetUnitsQuery = filtersApis.useGetAssetUnitsQuery, useGetUserSpecificDataProviderQuery = filtersApis.useGetUserSpecificDataProviderQuery, useGetHierarchyElementQuery = filtersApis.useGetHierarchyElementQuery, useGetCountriesQuery = filtersApis.useGetCountriesQuery, useGetCitiesQuery = filtersApis.useGetCitiesQuery, useGetCompaniesQuery = filtersApis.useGetCompaniesQuery, useGetAssetsQuery = filtersApis.useGetAssetsQuery, useLazyGetAssetsQuery = filtersApis.useLazyGetAssetsQuery, useGetPlanningProbabilityTypesQuery = filtersApis.useGetPlanningProbabilityTypesQuery, useGetCapacityBasisTypesQuery = filtersApis.useGetCapacityBasisTypesQuery, useGetPlanningStatusTypesQuery = filtersApis.useGetPlanningStatusTypesQuery, useGetAcmHierarchyElementQuery = filtersApis.useGetAcmHierarchyElementQuery, useGetHierarchyElementByNameQuery = filtersApis.useGetHierarchyElementByNameQuery, useGetHierarchyCountriesQuery = filtersApis.useGetHierarchyCountriesQuery, useGetACMCountryHierarchyQuery = filtersApis.useGetACMCountryHierarchyQuery, useGetGeopoliticalEntitiesQuery = filtersApis.useGetGeopoliticalEntitiesQuery, useGetOperationalStatusTypesQuery = filtersApis.useGetOperationalStatusTypesQuery, useGetModuleLastUpdateQuery = filtersApis.useGetModuleLastUpdateQuery, useGetAllCompaniesQuery = filtersApis.useGetAllCompaniesQuery;
