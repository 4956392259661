import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useHistory } from 'react-router';
import { Tag } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
import { useAppDispatch } from 'common/hooks/store';
import { setCapacityLoaded } from 'pages/capacity/ducks/slice';
var StyledTag = styled(Tag)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 12px;\n  padding: 5px;\n"], ["\n  margin-right: 12px;\n  padding: 5px;\n"])));
export var FilterGroup = function (_a) {
    var groupName = _a.groupName, selectedData = _a.selectedData, param = _a.param;
    var history = useHistory();
    var filterFields = [
        'regions',
        'location',
        'owners',
        'assets',
        'unitTypes',
        'unitSubTypes',
        'units',
        'probabilityTypes',
    ];
    var location = useLocation();
    var dispatch = useAppDispatch();
    var onRemoveTag = function (id) {
        if (groupName === 'Probability' && id === 1) {
            dispatch(setCapacityLoaded(true));
        }
        var search = location.search, pathname = location.pathname;
        var updatedParams = search.replace("".concat(param, "=").concat(id), '');
        var index = filterFields.indexOf(param);
        for (var i = index + 1; i < filterFields.length; i++) {
            updatedParams = updatedParams.replaceAll(new RegExp("".concat(filterFields[i], "=[0-9]*"), 'g'), '');
        }
        history.push({
            pathname: pathname,
            search: updatedParams,
        });
    };
    if (selectedData.length) {
        return (_jsxs(_Fragment, { children: [_jsx(StyledLabel, __assign({ size: "medium" }, { children: groupName })), selectedData.map(function (_a) {
                    var id = _a.id, name = _a.name;
                    if (id) {
                        return (_jsx(StyledTag, __assign({ onDismissClick: function () { return onRemoveTag(id); }, id: id.toString(), dismissible: true }, { children: name }), name));
                    }
                    return _jsx(_Fragment, {});
                })] }));
    }
    return _jsx(_Fragment, {});
};
var templateObject_1;
