import { __assign } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { getSourceFilter } from '../../../../../services/urlService';
import Moment from 'moment';
import { ApiContext } from './apiContext';
import Spinner from '../../../../../commons/spinner';
import SvgLogo from '../../../../../commons/tools/svgLogo';
var AssetUnitListGrid = function (props) {
    useEffect(function () {
        //removing data stored in addEditAssetUnitConfigData in Context when Add/Edit screen is closed.
        if (!props.isAddEditPopupVisible) {
            var resetConfigParams = __assign({}, apiParams);
            resetConfigParams.addEditAssetUnitConfigData = {
                assetDetails: {
                    assetClassKey: null,
                    assetClassName: '',
                    assetConfigurationCd: '',
                    assetConfigurationType: null,
                    assetCreatedDate: null,
                    assetKey: null,
                    assetName: '',
                    assetStartDate: null,
                    industryTypeCd: '',
                    industryTypeKey: null,
                },
                assetUnitDetails: {
                    assetKey: null,
                    assetUnitKey: 0,
                    assetUnitName: '',
                    assetUnitSubTypeCd: '',
                    assetUnitSubTypeKey: null,
                    dataProviderCd: '',
                    dataProviderKey: null,
                    dataSetCd: '',
                    dataSetKey: null,
                    endDate: null,
                    intellegenceSourceText: '',
                    intellegenceSourceUrl: '',
                    latitude: null,
                    longtiude: null,
                    offlineLockedInd: false,
                    operationalStatusCd: '',
                    operationalStatusKey: null,
                    placeHolderInd: false,
                    sourceTypeCd: '',
                    sourceTypeKey: null,
                    startDate: null,
                },
                assetUnitCapacityDetails: {
                    capacityStartDate: null,
                    id: null,
                    measureUnitCd: '',
                    measureUnitKey: null,
                    probabilityTypeCd: '',
                    probabilityTypeKey: null,
                    quantity: null,
                },
                assetUnitCrossRefDetails: [],
                assetUnitPetchemFeedstockDetails: [],
                assetUnitHistory: null,
            };
            resetConfigParams.originalExtIdLength = null;
            resetConfigParams.originalCrossRefLength = null;
            resetConfigParams.originalFeedstockLength = null;
            resetConfigParams.originalAssetUnitConfigData = {
                assetUnitCrossRefDetails: [],
                assetUnitPetchemFeedstockDetails: [],
            };
            setApiParams(resetConfigParams);
        }
    }, [props.isAddEditPopupVisible]);
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState([]), sort = _b[0], setSort = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var DateFormatCell = function (dataItemProps) {
        return dataItemProps.dataItem.startDate === null ? (_jsx("td", {})) : (_jsx("td", { children: Moment(dataItemProps.dataItem.startDate).format('DD MMM YYYY') }));
    };
    var EditButtonsCell = function (dataItemProps) {
        return (_jsx("td", __assign({ style: !(dataItemProps.dataItem.versionTypeKey === 3)
                ? { borderLeft: '4px solid #038000' }
                : { borderLeft: '4px solid #dd1d21' } }, { children: !(dataItemProps.dataItem.versionTypeKey === 3) ? null : (_jsxs(_Fragment, { children: [_jsx("button", __assign({ onClick: function () {
                            onEditClick(dataItemProps.dataItem.id);
                        } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ onClick: function () {
                            props.onDelete(dataItemProps.dataItem.id);
                        } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] })) })));
    };
    var onEditClick = function (id) {
        setLoader(true);
        var path = 'AssetUnits/' + id;
        getSourceFilter(path).then(function (response) {
            if (response && response.data) {
                var newState = __assign({}, apiParams);
                newState.addEditAssetUnitConfigData = response.data;
                newState.originalAssetUnitConfigData = JSON.parse(JSON.stringify(response.data)); //deep copy so changes don't reflect here on editing
                newState.originalCrossRefLength = response.data.assetUnitCrossRefDetails.length;
                newState.originalFeedstockLength = response.data.assetUnitPetchemFeedstockDetails.length;
                setApiParams(newState);
                props.showAddEditPopup(true);
            }
            setLoader(false);
        });
    };
    /**************Filtering data based on whether original records are to be displayed or not**********************/
    if (apiParams.assetUnitListingData.length) {
        var filteredData = void 0;
        if (!props.showOriginal) {
            filteredData = apiParams.assetUnitListingData.filter(function (data) { return data.versionTypeKey === 3; });
        }
        else
            filteredData = apiParams.assetUnitListingData;
        return !filteredData.length ? (_jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0" }, { children: _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No Baseline records available to display." }))] })) })) })) })) : (_jsxs("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: [loader && _jsx(Spinner, {}), _jsxs(Grid, __assign({ data: orderBy(filteredData, sort), style: { height: '75vh' }, sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    } }, { children: [_jsx(Column, { width: "130px", cell: EditButtonsCell, filterable: false, resizable: false, reorderable: false, locked: true }), _jsx(Column, { field: "assetUnitName", title: "Unit Name" }), _jsx(Column, { field: "assetName", title: "Asset Name" }), _jsx(Column, { field: "startDate", title: "Start Date", format: "{0:dd MMM yyyy}", cell: DateFormatCell }), _jsx(Column, { field: "unitType", title: "Unit Type" }), _jsx(Column, { field: "unitSubType", title: "Unit Subtype" }), _jsx(Column, { field: "statusType", title: "Operational Status Type" }), _jsx(Column, { field: "dataProviderName", title: "Data Provider" })] }))] })));
    }
    /**************************************************************************************************/
    /**********************************default screen when no filters selected*************************/
    return (_jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0", id: "capacityscreen" }, { children: _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "Click on Apply to view" }))] })) })) })) }));
    /******************************************************************************************************/
};
export default AssetUnitListGrid;
