import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label } from '@sede-x/shell-ds-react-framework';
import { StyledList, StyledListItem, StyledListItemAsset, StyledListItemUnit, StyledListItemUnitName, StyledListItemUnitNameWrapper, } from './AssetsList.styles';
import { StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
export var AssetsList = function (_a) {
    var assets = _a.assets;
    return (_jsx(StyledList, { children: assets.map(function (_a) {
            var name = _a.name, owner = _a.owner, units = _a.units;
            return (_jsxs(StyledListItem, { children: [_jsxs(StyledListItemAsset, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: name })), _jsx(StyledLabel, __assign({ size: "small" }, { children: owner }))] }), units.map(function (_a) {
                        var unitName = _a.name, capacity = _a.capacity;
                        return (_jsx(StyledListItemUnit, { children: _jsxs(StyledListItemUnitNameWrapper, { children: [_jsx(StyledListItemUnitName, __assign({ size: "small" }, { children: unitName })), _jsx(Label, __assign({ size: "small" }, { children: capacity }))] }) }, unitName));
                    })] }, "".concat(name, "-").concat(owner)));
        }) }));
};
