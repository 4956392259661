import React, { ReactElement } from 'react';

import { DarkMode, LightMode, RechargeDark, RechargeLight, Typography } from '@sede-x/shell-ds-fabrics';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { GlobalStyles } from '../../styles/GlobalStyles';
import { DARK_THEME, LIGHT_THEME, RECHARGE_DARK_THEME, RECHARGE_LIGHT_THEME } from '../../utils/constants';
import { Theme, ThemeMode } from './theme';

export interface IThemeProviderProps {
  theme: ThemeMode;
  children?: React.ReactNode;
}

export const ShellThemeProvider = ({ theme, children }: IThemeProviderProps): ReactElement => {
  const themes: { [K in ThemeMode]: Theme } = {
    [LIGHT_THEME]: { name: LIGHT_THEME, ...Typography, ...LightMode },
    [DARK_THEME]: { name: DARK_THEME, ...Typography, ...DarkMode },
    [RECHARGE_LIGHT_THEME]: { name: RECHARGE_LIGHT_THEME, ...Typography, ...RechargeLight },
    [RECHARGE_DARK_THEME]: { name: RECHARGE_DARK_THEME, ...Typography, ...RechargeDark },
  };

  return (
    <StyledThemeProvider theme={themes[theme]}>
      <>
        <GlobalStyles />
        {children}
      </>
    </StyledThemeProvider>
  );
};
