import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Icons, Popconfirm } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { convertToCsvAndDownload } from 'common/utils/convertToCsv.util';
import { displayNameMapping } from 'components/export-data/export-data.mapping';
import { StyledPopconfirm } from 'components/export-data/ExportData.styles';
var Download = Icons.Download;
var StyledExportButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: ", "px;\n  top: ", "px;\n  z-index: 100;\n"], ["\n  position: absolute;\n  right: ", "px;\n  top: ", "px;\n  z-index: 100;\n"])), function (_a) {
    var right = _a.right;
    return right;
}, function (_a) {
    var top = _a.top;
    return top;
});
export var ModalExportButton = function (_a) {
    var top = _a.top, right = _a.right, dataToExport = _a.dataToExport, type = _a.type, propertyName = _a.propertyName;
    var fileName = "acm-".concat(type, "-").concat(dayjs().format('DD-MMM-YYYY'), ".csv");
    var _b = useState(false), downloaded = _b[0], setDownloaded = _b[1];
    var handleClick = function () {
        convertToCsvAndDownload(dataToExport, propertyName, fileName, displayNameMapping);
        setDownloaded(true);
    };
    useEffect(function () {
        setDownloaded(false);
    }, [dataToExport]);
    return (_jsx(StyledPopconfirm, __assign({ title: _jsx(Popconfirm.Title, { text: "Data exported" }), description: _jsx(Popconfirm.Description, { text: _jsxs(_Fragment, { children: ["Please check your Downloads folder for ", _jsx("br", {}), " ", _jsx("i", { children: fileName })] }) }), sentiment: "positive", actions: [], hidden: !downloaded }, { children: _jsx(StyledExportButton, __assign({ top: top, right: right, icon: _jsx(Download, {}), variant: "outlined", size: "small", disabled: !(dataToExport === null || dataToExport === void 0 ? void 0 : dataToExport.length) || downloaded, onClick: handleClick }, { children: downloaded ? 'Data exported' : 'Export data' })) })));
};
var templateObject_1;
