import { FormFieldSize } from './FormField.types';
import { Sizes } from '../../types/types';

type FormFieldConstsType = {
  [size in FormFieldSize as string]: {
    margin: string;
    heading: 'h11' | 'h14' | 'h16';
    description: 'h11';
    icon: string;
  };
};

export const formFieldConsts: FormFieldConstsType = {
  [Sizes.Large]: {
    margin: '8px',
    heading: 'h16',
    description: 'h11',
    icon: '24px',
  },
  [Sizes.Medium]: {
    margin: '8px',
    heading: 'h14',
    description: 'h11',
    icon: '20px',
  },
  [Sizes.Small]: {
    margin: '6px',
    heading: 'h11',
    description: 'h11',
    icon: '16px',
  },
};
