import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { BUTTON_CLASS } from '../../utils/constants';
import * as Styled from './ButtonLink.styles';
import ButtonContent from '../Button/components/ButtonContent/ButtonContent';
import { Sizes, Sentiments, Variants, Positions } from '../../types/types';
import { IButtonLinkProps } from './ButtonLink.types';

const ButtonLink = forwardRef<HTMLAnchorElement, IButtonLinkProps>(
  (
    {
      className = '',
      children,
      size = Sizes.Medium,
      variant = Variants.Transparent,
      disabled = false,
      icon,
      iconOnly = false,
      iconPosition = Positions.Right,
      loading = false,
      alignment,
      ...rest
    }: IButtonLinkProps,
    ref,
  ) => {
    const innerAlignment = variant === Variants.Transparent ? 'text' : 'edge';

    return (
      <Styled.ButtonLink
        className={classNames(BUTTON_CLASS, className)}
        size={size}
        iconOnly={iconOnly}
        iconPosition={iconPosition}
        sentiment={Sentiments.Default}
        variant={variant}
        alignment={innerAlignment}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        ref={ref}
        {...rest}
      >
        <ButtonContent loading={loading} icon={icon} iconOnly={iconOnly} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </Styled.ButtonLink>
    );
  },
);

export default ButtonLink;
