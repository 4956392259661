import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import GridTemplate from '../../../../commons/gridTemplate';
var assignedRole = /** @class */ (function (_super) {
    __extends(assignedRole, _super);
    function assignedRole(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            intelligenceSourceTypeCd: {
                required: true,
                error: 'Enter Intelligence Source Type Cd unique key.',
            },
        };
        var columns = [
            {
                field: 'intelligenceSourceTypeCd',
                title: 'Intelligence Source Type Cd',
            },
            {
                field: 'intelligenceSourceTypeDesc',
                title: 'Intelligence Source Type Desc Title',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
        };
        return _this;
    }
    assignedRole.prototype.render = function () {
        return (_jsx(GridTemplate, { path: "intelligentSourceType", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "intelligenceSourceTypeCd" }));
    };
    return assignedRole;
}(Component));
export default assignedRole;
