import styled, { css } from 'styled-components';

import { buttonConsts } from './Button.consts';
import { StyledButtonProps } from './Button.types';
import * as Styled from './components/ButtonContent/ButtonContent.styles';
import {
  getAlignmentMargin,
  getBackgroundColor,
  getBackgroundColorOnHover,
  getBackgroundColorWhenDisabled,
  getBorderColor,
  getBorderColorOnHover,
  getBorderColorWhenDisabled,
  getColor,
  getColorOnHover,
  getPadding,
} from './utils/styleUtils';
import { Sizes, Variants, Positions } from '../../types/types';

export const CommonStyles = css<StyledButtonProps>`
  ${({ size, iconOnly, iconPosition, variant, alignment, fullWidth, sentiment, disabled, theme }) => css`
    ${(size === Sizes.Small || size === Sizes.ExtraSmall) && theme.label.medium.bold};
    ${(size === Sizes.Medium || size === Sizes.Large) && theme.label.large.bold};

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${getPadding(iconOnly, size, variant)};
    margin: 0;
    border: 1px solid ${getBorderColor(theme, variant, sentiment)};
    border-radius: 2px;
    background-color: ${getBackgroundColor(theme, variant, sentiment)};
    color: ${getColor(theme, variant, sentiment)};
    cursor: pointer;

    transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s, border 0.3s;

    &:focus-visible,
    &.test-simulate-focus {
      box-shadow: 0 0 0 3px ${theme.focus.medium};
      outline: 0;

      ${!disabled &&
      variant !== Variants.Transparent &&
      css`
        border-color: ${theme.focus.strong};
      `}
    }

    ${alignment === 'text' &&
    css`
      margin-left: ${getAlignmentMargin(size, variant)};
    `}

    ${fullWidth &&
    css`
      width: 100%;
    `}

    min-height: ${buttonConsts.sizes[size]};

    ${iconOnly &&
    css`
      justify-content: center;
      width: ${buttonConsts.sizes[size]};
      height: ${buttonConsts.sizes[size]};
      padding: 0;
    `}

    ${Styled.ButtonContent} > svg {
      width: ${buttonConsts.icons[size]};
      height: ${buttonConsts.icons[size]};
      margin: ${!iconOnly && (iconPosition === Positions.Right ? '0 0 0 8px' : '0 8px 0 0')};
      fill: ${getColor(theme, variant, sentiment)};
    }

    &:disabled {
      background-color: ${getBackgroundColorWhenDisabled(theme, variant)};
      border-color: ${getBorderColorWhenDisabled(theme, variant)};
      color: ${theme.text.onSurface.inactive};
      cursor: not-allowed;
      pointer-events: none;

      * {
        color: ${theme.text.onSurface.inactive};
        border-color: ${theme.text.onSurface.inactive};
      }

      svg path {
        fill: ${theme.text.onSurface.inactive};
      }
    }

    &:hover:not(:disabled) {
      background-color: ${getBackgroundColorOnHover(theme, variant, sentiment)};
      border-color: ${getBorderColorOnHover(theme, variant, sentiment)};
      color: ${getColorOnHover(theme, variant, sentiment)};

      ${Styled.ButtonContent} > svg {
        fill: ${getColorOnHover(theme, variant, sentiment)};
      }
    }
  `}
`;

const Button = styled.button<StyledButtonProps>`
  ${CommonStyles};
`;

export { Button };
