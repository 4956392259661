import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useEffect, useContext } from 'react';
import { filterBy } from '@progress/kendo-data-query';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { defaultConst } from '../ApiContextInitialState';
import { ApiContext } from '../ApiContext';
import _ from 'lodash';
function Filter(props) {
    var _a, _b, _c;
    //current functional variables
    var filterRandomKey = (_a = {
            Countries: 'co',
            Cities: 'ci',
            Companies: 'o',
            AssetClasses: 'at'
        },
        _a['Assets/AssetFilter'] = 'a',
        _a.AssetUnitTypes = 'ut',
        _a.AssetUnitSubtypes = 'ust',
        _a['AssetUnits/AssetUnitFilter'] = 'unit',
        _a);
    var defaultItem = (_b = {},
        _b[props.code] = 'Select',
        _b.id = 0,
        _b);
    var multiSelectFirstItem = [
        (_c = {},
            _c[props.code] = 'Select All',
            _c.id = 0,
            _c),
    ];
    //function state set
    var multiSelect = useRef(null);
    var multiSelectCapacity = useRef(null);
    var _d = useState(typeof props.data !== 'undefined' ? props.data : []), data = _d[0], setData = _d[1];
    var _e = useState(true), loader = _e[0], setLoader = _e[1];
    //context
    var _f = useContext(ApiContext), apiParams = _f[0], setApiParams = _f[1];
    //function state set
    var _g = useState(apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]] > 0
        ? apiParams[props.dropDown].filter(function (obj) {
            return (obj.id ===
                apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]]);
        })[0]
        : defaultItem), value = _g[0], setValue = _g[1];
    /*
     *item render for select dropdowns
     */
    var itemRender = function (li, itemProps) {
        var index = itemProps.index;
        var itemChildren = _jsx("span", __assign({ style: { textAlign: 'left' } }, { children: li.props.children }));
        return React.cloneElement(li, li.props, itemChildren);
    };
    /*
     *function to filter the dropdown
     */
    function filterByOnData(e) {
        var currentDropDownData = props.data;
        return filterBy(currentDropDownData, e);
    }
    function filterChange(e) {
        setData(filterByOnData(e.filter));
    }
    /*
     * loader change on data and empty data
     */
    useEffect(function () {
        setLoader(true);
        if (typeof props.data !== 'undefined' && props.data.length > 0) {
            setLoader(false);
        }
        else {
            setTimeout(function () {
                setLoader(false);
            }, 6000);
        }
    }, [props.data]);
    /*
     *function to check two object have any difference
     */
    var objectsEqual = function (o1, o2) {
        return typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(function (p) { return objectsEqual(o1[p], o2[p]); })
            : o1 === o2;
    };
    /*
     *function handle change in dropdown
     */
    function handleChange(e) {
        if (Array.isArray(e.target.value)) {
            var apiParamUnitComparisonValue = apiParams.unit.length > 0
                ? _.difference(apiParams.unit, e.target.value).length === 1
                    ? _.difference(apiParams.unit, e.target.value)[0].id
                    : null
                : null;
            var lastItem = e.target.value.pop();
            if (e.target.value.length === 0) {
                var indexElement = __spreadArray(__spreadArray([], multiSelectFirstItem, true), data, true).findIndex(function (obj) {
                    return props.unitOnly ? obj.id == apiParams.multipleUnit[0].id : obj.id == apiParams.unit[0].id;
                });
                if (props.unitOnly) {
                    if (indexElement !== 0) {
                        multiSelect.current.setState({ focusedIndex: indexElement });
                    }
                }
                else {
                    if (indexElement !== 0) {
                        multiSelectCapacity.current.setState({
                            focusedIndex: indexElement,
                        });
                    }
                }
                props.changeDropDownOptions([], props.dropDown, true);
            }
            else if ((e.target.value.length === 1 && e.target.value[0].id === 0) ||
                (lastItem.id === 0 && e.target.value.length !== data.length + 2)) {
                if (props.unitOnly) {
                    multiSelect.current.setState({ focusedIndex: 0 });
                }
                else {
                    multiSelectCapacity.current.setState({
                        focusedIndex: 0,
                    });
                }
                props.changeDropDownOptions(__spreadArray(__spreadArray([], multiSelectFirstItem, true), data, true), props.dropDown, true);
            }
            else if (e.target.value.length === data.length + 2 || apiParamUnitComparisonValue === 0) {
                if (props.unitOnly) {
                    multiSelect.current.setState({ focusedIndex: 0 });
                }
                else {
                    multiSelectCapacity.current.setState({
                        focusedIndex: 0,
                    });
                }
                props.changeDropDownOptions([], props.dropDown, true);
            }
            else {
                var selectedItems = e.target.value.filter(function (obj) {
                    return obj.id > 0;
                });
                if (_.differenceWith(data, selectedItems, _.isEqual).length === 0) {
                    var indexElement = __spreadArray(__spreadArray([], multiSelectFirstItem, true), data, true).findIndex(function (obj) { return obj.id == lastItem.id; });
                    if (props.unitOnly) {
                        if (indexElement !== 0) {
                            multiSelect.current.setState({ focusedIndex: indexElement });
                        }
                    }
                    else {
                        if (indexElement !== 0) {
                            multiSelectCapacity.current.setState({
                                focusedIndex: indexElement,
                            });
                        }
                    }
                    props.changeDropDownOptions(__spreadArray(__spreadArray([], multiSelectFirstItem, true), data, true), props.dropDown, true);
                }
                else {
                    var diffObj_1;
                    if (props.unitOnly) {
                        diffObj_1 =
                            _.difference(selectedItems, apiParams.multipleUnit.filter(function (obj) {
                                return obj.id > 0;
                            })).length > 0
                                ? _.difference(selectedItems, apiParams.multipleUnit.filter(function (obj) {
                                    return obj.id > 0;
                                }))
                                : _.difference(apiParams.multipleUnit.filter(function (obj) {
                                    return obj.id > 0;
                                }), selectedItems);
                    }
                    else {
                        diffObj_1 =
                            _.difference(selectedItems, apiParams.unit.filter(function (obj) {
                                return obj.id > 0;
                            })).length > 0
                                ? _.difference(selectedItems, apiParams.unit.filter(function (obj) {
                                    return obj.id > 0;
                                }))
                                : _.difference(apiParams.unit.filter(function (obj) {
                                    return obj.id > 0;
                                }), selectedItems);
                    }
                    var indexElement = __spreadArray(__spreadArray([], multiSelectFirstItem, true), data, true).findIndex(function (obj) { return obj.id == diffObj_1[0].id; });
                    if (props.unitOnly) {
                        if (indexElement !== 0) {
                            multiSelect.current.setState({ focusedIndex: indexElement });
                        }
                    }
                    else {
                        if (indexElement !== 0) {
                            multiSelectCapacity.current.setState({
                                focusedIndex: indexElement,
                            });
                        }
                    }
                    props.changeDropDownOptions(__spreadArray([], selectedItems, true), props.dropDown, true);
                }
            }
        }
        else {
            setValue(e.target.value);
            if (props.dropDown === 'Countries' && e.target.value.id === 0) {
                var object = __assign(__assign(__assign({}, defaultConst), props.allFilters), props.dropDownDataArea);
                setApiParams(__assign({}, object));
                props.selectedDataReset({});
                props.setUnit([]);
            }
            else {
                if (typeof props.changeDropDownOptionKey !== 'undefined') {
                    props.changeDropDownOptions(e.target, props.changeDropDownOptionKey);
                }
                else {
                    props.changeDropDownOptions(e.target, props.dropDown);
                }
            }
        }
        if (!apiParams.dataAreaPath.includes(props.dropDown) &&
            props.dropDown !== 'DataProviders/Maintenance' &&
            props.dropDown !== 'DataProviders/Capacity') {
            if (props.dropDown !== 'AssetUnits/AssetUnitFilter/Maintenance' &&
                props.dropDown !== 'AssetUnits/AssetUnitFilter/Capacity') {
                if (apiParams.multipleUnit.length > 0 || apiParams.unit.length > 0) {
                    if (!props.unitOnly) {
                        setApiParams(function (prevParams) {
                            var _a;
                            return __assign(__assign({}, prevParams), (_a = {}, _a['AssetUnits/AssetUnitFilter/Maintenance'] = [], _a));
                        });
                    }
                    else {
                        setApiParams(function (prevParams) {
                            var _a;
                            return __assign(__assign({}, prevParams), (_a = {}, _a['AssetUnits/AssetUnitFilter/Capacity'] = [], _a));
                        });
                    }
                }
            }
            else {
                if (props.dropDown === 'AssetUnits/AssetUnitFilter/Maintenance' &&
                    props.dropDown === 'AssetUnits/AssetUnitFilter/Capacity') {
                    if (apiParams.multipleUnit.length === 0 || apiParams.unit.length === 0) {
                        setValue([]);
                    }
                }
            }
        }
    }
    useEffect(function () {
        if (props.dropDown === 'AssetUnits/AssetUnitFilter/Maintenance' ||
            props.dropDown === 'AssetUnits/AssetUnitFilter/Capacity') {
            if (props.unitOnly) {
                if (apiParams.multipleUnit.length > 0) {
                    props.setShowEventsEnableDisable(false);
                }
                else if (apiParams.multipleUnit.length < 1 || apiParams.multipleUnit[0].id === 0) {
                    props.setShowEventsEnableDisable(true);
                }
            }
            else {
                if (apiParams.unit.length > 0) {
                    if (!props.editArea) {
                        props.setShowEventsEnableDisable(false);
                    }
                }
                else {
                    if (!props.editArea) {
                        props.setShowEventsEnableDisable(true);
                    }
                }
            }
        }
    }, [apiParams.unit, apiParams.multipleUnit]);
    /*
     *item render for option in multi select
     */
    function itemRenderMultiSelection(li, itemProps) {
        var itemChildren = {};
        itemChildren = (_jsxs("span", __assign({ className: "p-1" }, { children: [_jsx("input", { type: "checkbox", checked: apiParams.multipleUnit.length > 0 && apiParams.multipleUnit[0][props.code] === 'Select All'
                        ? true
                        : apiParams.multipleUnit.length > 0 && apiParams.multipleUnit.some(function (e) { return e.id === itemProps.dataItem.id; })
                            ? true
                            : itemProps.selected, className: "k-checkbox", readOnly: true }), _jsxs("label", __assign({ className: "col-12 k-checkbox-label pl-4 text-left" }, { children: [_jsx("span", { children: li.props.children }), li.props.children !== 'Select All' && (_jsxs("span", __assign({ className: "caption-oum" }, { children: [_jsxs("span", { children: ["(", itemProps.dataItem.totalCapacity, " "] }), _jsxs("span", { children: [" ", itemProps.dataItem.measureUnit.measureUnitCd, ")"] })] })))] }))] })));
        return React.cloneElement(li, __assign(__assign({}, li.props), {
            'data-checked': apiParams.multipleUnit.length > 0 && apiParams.multipleUnit[0][props.code] === 'Select All'
                ? true
                : itemProps.selected,
            className: apiParams.multipleUnit.length > 0 && apiParams.multipleUnit[0][props.code] === 'Select All'
                ? 'k-item k-state-selected'
                : apiParams.multipleUnit.length > 0 && apiParams.multipleUnit.some(function (e) { return e.id === itemProps.dataItem.id; })
                    ? 'k-item k-state-selected'
                    : 'k-item',
        }), itemChildren);
    }
    /*
     *item render for option in multi select capacity
     */
    function itemRenderMultiSelectionCapacity(li, itemProps) {
        var itemChildren = {};
        itemChildren = (_jsxs("span", __assign({ className: "p-1" }, { children: [_jsx("input", { type: "checkbox", checked: apiParams.unit.length > 0 && apiParams.unit[0][props.code] === 'Select All'
                        ? true
                        : apiParams.unit.length > 0 && apiParams.unit.some(function (e) { return e.id === itemProps.dataItem.id; })
                            ? true
                            : itemProps.selected, className: "k-checkbox", readOnly: true }), _jsxs("label", __assign({ className: "col-12 k-checkbox-label pl-4 text-left" }, { children: [_jsx("span", { children: li.props.children }), li.props.children !== 'Select All' && (_jsxs("span", __assign({ className: "caption-oum" }, { children: [_jsxs("span", { children: ["(", itemProps.dataItem.totalCapacity, " "] }), _jsxs("span", { children: [" ", itemProps.dataItem.measureUnit.measureUnitCd, ")"] })] })))] }))] })));
        return React.cloneElement(li, __assign(__assign({}, li.props), {
            'data-checked': apiParams.unit.length > 0 && apiParams.unit[0][props.code] === 'Select All' ? true : itemProps.selected,
            className: apiParams.unit.length > 0 && apiParams.unit[0][props.code] === 'Select All'
                ? 'k-item k-state-selected'
                : apiParams.unit.length > 0 && apiParams.unit.some(function (e) { return e.id === itemProps.dataItem.id; })
                    ? 'k-item k-state-selected'
                    : 'k-item',
        }), itemChildren);
    }
    /*
     *function to change to unit only prop from parent
     */
    useEffect(function () {
        if (props.unitOnly) {
            setValue(apiParams.multipleUnit);
        }
        else {
            setValue(apiParams.unit);
        }
    }, [props.unitOnly]);
    function checkProperties(obj) {
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != '')
                return false;
        }
        return true;
    }
    /*
     *function to detect change in the apiparams and process it accordingly
     */
    useEffect(function () {
        setData(props.data);
        if (typeof props.defaultData !== 'undefined' &&
            props.defaultData &&
            !checkProperties(props.defaultData) &&
            typeof props.data !== 'undefined' &&
            props.data.length > 1) {
            setValue(props.data.filter(function (obj) {
                return obj.id === props.defaultData.id;
            })[0]);
        }
        else {
            if (typeof value === 'undefined' ||
                value.id === 0 ||
                typeof value.id === 'object' ||
                apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]] === 0) {
                setValue(defaultItem);
            }
            else if (apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]] === undefined) {
                setValue(defaultItem);
            }
        }
    }, [
        apiParams.maintenanceAssetEventTypeKey,
        apiParams.maintenanceEventCauseTypeKey,
        apiParams.maintenanceDataProviderKey,
        apiParams.capacityCapacityBasisTypeKey,
        apiParams.capacityPlanningProbabilityTypeKey,
        apiParams.capacityDataProviderKey,
        apiParams.capacityPlanningStatusTypeKey,
    ]);
    useEffect(function () {
        if (apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]] > 0) {
            setValue(apiParams[props.dropDown].filter(function (obj) {
                return (obj.id ===
                    apiParams[filterRandomKey[typeof props.changeDropDownOptionKey !== 'undefined' ? props.changeDropDownOptionKey : props.dropDown]]);
            })[0]);
        }
    }, [apiParams[props.dropDown]]);
    useEffect(function () {
        if (props.dropDown === 'AssetUnits/AssetUnitFilter/Capacity' || props.dropDown === 'EditAreaUnitFilter') {
            if (apiParams['AssetUnits/AssetUnitFilter/Capacity'].length > 0 && apiParams['EditAreaUnitFilter'].length > 0) {
                var updatedCapacity_1 = __assign(__assign({}, apiParams.capacityData), { capacityUniversalUomKey: data.length > 0 ? data[0].measureUnit.measureUnitKey : '' });
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { capacityData: updatedCapacity_1, defaultCapacityUOMkey: data.length > 0 ? data[0].measureUnit.measureUnitCd : '' });
                });
            }
        }
    }, [apiParams[props.dropDown]]);
    // /*
    //  *useeffect for storing UOM
    //  */
    // useEffect(() => {
    //   if (
    //     props.dropDown === "AssetUnits/AssetUnitFilter/Capacity" ||
    //     props.dropDown === "EditAreaUnitFilter"
    //   ) {
    //     let updatedCapacity = {
    //       ...apiParams.capacityData,
    //       capacityUniversalUomKey:
    //         data.length > 0 ? data[0].measureUnit.measureUnitKey : "",
    //     };
    //     setApiParams((prevParams) => {
    //       return {
    //         ...prevParams,
    //         defaultUomOnUnitSelection:
    //           data.length > 0 ? data[0].measureUnit.measureUnitCd : "",
    //         capacityData: updatedCapacity,
    //       };
    //     });
    //   }
    // }, [apiParams.unit.length]);
    /*
     *return of jsx
     */
    if (!props.unitOnly) {
        if (props.dropDown === 'AssetUnits/AssetUnitFilter/Capacity' || props.dropDown === 'EditAreaUnitFilter') {
            return (
            // <DropDownList
            //   className="text-left rounded w-100"
            //   data={data}
            //   textField={props.code}
            //   loading={props.disabled ? false : loader}
            //   filterable={true}
            //   onChange={handleChange}
            //   onFilterChange={filterChange}
            //   defaultItem={defaultItem}
            //   itemRender={itemRender}
            //   disabled={props.disabled}
            //   value={apiParams.unit.length > 0 ? apiParams.unit[0] : defaultItem}
            // />
            _jsx("div", __assign({ id: "multiSelect" }, { children: _jsx(MultiSelect, { ref: function (el) { return (multiSelectCapacity.current = el); }, tags: apiParams.unit.length > 0
                        ? apiParams.unit.find(function (x) { return x.id === 0; })
                            ? [
                                {
                                    text: "Selected All",
                                    data: __spreadArray([], apiParams.unit, true),
                                },
                            ]
                            : [
                                {
                                    text: "Selected (".concat(apiParams.unit.length, " Units)"),
                                    data: __spreadArray([], apiParams.unit, true),
                                },
                            ]
                        : [], data: props.data.length > 0 ? __spreadArray(__spreadArray([], multiSelectFirstItem, true), props.data, true) : [], itemRender: itemRenderMultiSelectionCapacity, textField: props.code, dataItemKey: "id", className: "text-left rounded w-100", value: apiParams.unit.length > 0 ? apiParams.unit : [], onChange: handleChange, loading: props.disabled ? false : loader, placeholder: 'Select', autoClose: false, disabled: props.disabled }) })));
        }
        else {
            return (_jsx(DropDownList, { className: "text-left rounded w-100", data: data, textField: props.code, loading: loader, filterable: true, onChange: handleChange, onFilterChange: filterChange, defaultItem: defaultItem, itemRender: itemRender, value: !checkProperties(value) ? value : defaultItem, disabled: typeof props.disabled !== 'undefined' ? props.disabled : false }));
        }
    }
    else {
        return (_jsx("div", __assign({ id: "multiSelect" }, { children: _jsx(MultiSelect, { ref: function (el) { return (multiSelect.current = el); }, tags: apiParams.multipleUnit.length > 0
                    ? apiParams.multipleUnit.find(function (x) { return x.id === 0; })
                        ? [
                            {
                                text: "Selected All",
                                data: __spreadArray([], apiParams.multipleUnit, true),
                            },
                        ]
                        : [
                            {
                                text: "Selected (".concat(apiParams.multipleUnit.length, " Units)"),
                                data: __spreadArray([], apiParams.multipleUnit, true),
                            },
                        ]
                    : [], data: apiParams['AssetUnits/AssetUnitFilter/Maintenance'].length > 0
                    ? __spreadArray(__spreadArray([], multiSelectFirstItem, true), apiParams['AssetUnits/AssetUnitFilter/Maintenance'], true) : [], itemRender: itemRenderMultiSelection, textField: props.code, className: "text-left rounded w-100", value: apiParams.multipleUnit.length > 0 ? apiParams.multipleUnit : [], onChange: handleChange, loading: props.disabled ? false : loader, placeholder: 'Select', autoClose: false, disabled: props.disabled }) })));
    }
}
export default Filter;
