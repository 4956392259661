import { __assign, __awaiter, __extends, __generator, __rest, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import SlimSelect from 'slim-select';
import _ from 'lodash';
import 'slim-select/dist/slimselect.css';
import { getSourceFilter } from '../../../../../services/urlService';
import makeAnimated from 'react-select/animated';
import MultiSelectPicker from './tools/multiSelectPicker';
import { components } from 'react-select';
import Select from 'react-select';
var Option = function (props) {
    var uniq = Math.floor(100000 + Math.random() * 900000);
    return (_jsx("div", { children: _jsxs(components.Option, __assign({}, props, { children: [_jsx("input", { type: "checkbox", className: "k-checkbox", checked: props.isSelected, onChange: function () { return null; } }), _jsx("label", __assign({ className: "k-checkbox-label" }, { children: props.label }))] })) }));
};
var customStyles = {
    dropdownIndicator: function () { return ({
        display: 'none',
    }); },
};
var Menu = function (props) {
    return _jsx(components.Menu, __assign({ className: "select-option-menu" }, props));
};
var animatedComponents = makeAnimated();
var allOption = {
    label: 'Select all',
    value: '*',
};
var ValueContainer = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var currentValues = props.getValue();
    var length = props.getValue().length;
    if (typeof currentValues !== 'undefined' &&
        typeof currentValues[0] !== 'undefined' &&
        currentValues[0].value !== '*') {
        return (_jsxs(components.ValueContainer, __assign({}, props, { className: "count" }, { children: ["Selected(".concat(length, ")"), React.cloneElement(children[1])] })));
    }
    else {
        var toBeRendered = children;
        if (currentValues.some(function (val) { return val.value === allOption.value; })) {
            toBeRendered = [[children[0][0]], children[1]];
        }
        return _jsx(components.ValueContainer, __assign({}, props, { children: toBeRendered }));
    }
};
var MultiValue = function (props) {
    var labelToBeDisplayed = "".concat(props.data.label, ", ");
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = 'Selected All';
    }
    return (_jsx("div", __assign({ id: "all-options", className: "all-options" }, { children: _jsx(components.MultiValue, __assign({}, props, { children: labelToBeDisplayed })) })));
};
var IndicatorsContainer = function (_a) {
    var innerRef = _a.innerRef, innerProps = _a.innerProps;
    return (_jsx("div", __assign({ className: "IndicatorsContainer", ref: innerRef }, innerProps)));
};
var Control = function (props) {
    return _jsx(components.Control, __assign({}, props, { className: 'control-dd' }));
};
var MenuPortal = function (props) {
    var currentValues = props.getValue();
    return (_jsx(components.MenuPortal, __assign({}, props, { children: _jsx("div", { children: "Sample" }) })));
};
var componentsList = {
    Control: Control,
    Option: Option,
    MultiValue: MultiValue,
    ValueContainer: ValueContainer,
    animatedComponents: animatedComponents,
    Menu: Menu,
    IndicatorsContainer: IndicatorsContainer,
};
var dropdownMultiSelect = /** @class */ (function (_super) {
    __extends(dropdownMultiSelect, _super);
    function dropdownMultiSelect(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.handleChange = function (selected) {
            _this.props.selectHandler(selected);
            _this.setState({
                optionSelected: selected,
            });
        };
        _this.componentDidMount = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var path;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = this.props.path;
                        return [4 /*yield*/, getSourceFilter(path)
                                .then(function (filterData) { return __awaiter(_this, void 0, void 0, function () {
                                var dataInLoop, i, currObject;
                                return __generator(this, function (_a) {
                                    if (filterData.data.length > 0) {
                                        dataInLoop = __spreadArray([], this.state.data, true);
                                        for (i = 0; i < filterData.data.length; i++) {
                                            currObject = {
                                                value: filterData.data[i].id,
                                                label: filterData.data[i][this.props.name],
                                            };
                                            dataInLoop.push(currObject);
                                        }
                                        return [2 /*return*/, this.setState({
                                                data: dataInLoop,
                                            })];
                                    }
                                    return [2 /*return*/];
                                });
                            }); })
                                .catch(function (error) { })];
                    case 1:
                        _a.sent();
                        if (!this.props.onSwitchPreviousSelectedItems) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.selectHandler(this.props.onSwitchPreviousSelectedItems)];
                    case 2:
                        _a.sent();
                        this.setState({
                            options: this.state.data,
                            optionSelected: this.props.onSwitchPreviousSelectedItems,
                        }, function () {
                            _this.props.applyButtonEnable(path);
                        });
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.setState({
                            options: this.state.data,
                            optionSelected: __spreadArray([
                                {
                                    label: 'Select all',
                                    value: '*',
                                }
                            ], this.state.data, true),
                        }, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.props.selectHandler(this.state.optionSelected)];
                                    case 1:
                                        _a.sent();
                                        this.props.applyButtonEnable(path);
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.props.fillDeltaDropdown(this.state.data);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.resetHandler = function () {
            _this.setState({
                optionSelected: __spreadArray([
                    {
                        label: 'Select all',
                        value: '*',
                    }
                ], _this.state.data, true),
            });
        };
        _this.state = {
            selectedItems: [],
            data: [],
            options: [],
        };
        _this.set = '';
        _this.data = [];
        return _this;
    }
    dropdownMultiSelect.prototype.render = function () {
        return (_jsx("div", __assign({ className: "border rounded bg-transparent pt-2 pb-2 pl-3 pr-3", style: { height: '37px' } }, { children: _jsxs("div", __assign({ className: "row justify-content-between no-gutters" }, { children: [_jsx("div", __assign({ className: "select-text" }, { children: _jsxs("span", __assign({ className: "dim-text" }, { children: [this.props.title, ":"] })) })), _jsx("div", __assign({ className: 'dropdown-multiselect dropdown-multiselect-' + this.props.path + ' multiselect' }, { children: _jsx(MultiSelectPicker, { className: "multiselect-picker", options: this.state.options, isMulti: true, isSearchable: false, hideSelectedOptions: false, closeMenuOnSelect: false, components: componentsList, styles: customStyles, onChange: this.handleChange, allowSelectAll: true, value: this.state.optionSelected }) }))] })) })));
    };
    return dropdownMultiSelect;
}(Component));
export default dropdownMultiSelect;
