import { __makeTemplateObject } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import LogoImg from '../../../../assets/logo.svg';
var StyledName = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 16px;\n  font-size: 14px;\n  line-height: 18px;\n  font-weight: 600;\n"], ["\n  margin-left: 16px;\n  font-size: 14px;\n  line-height: 18px;\n  font-weight: 600;\n"])));
var StyledVersion = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.subtle;
});
export var Logo = function () { return (_jsxs(_Fragment, { children: [_jsx("img", { src: LogoImg, height: "36", width: "36" }), " ", _jsx(StyledName, { children: "ACM" }), ' ', _jsx(StyledVersion, { children: "2.0" })] })); };
var templateObject_1, templateObject_2;
