import { ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { IPopconfirmProps } from './Popconfirm.types';
import Heading from '../Text/components/Heading/Heading';
import { getColorBySentiment } from '../../utils/colorUtils';
import { popconfirmConsts } from './Popconfirm.consts';
import Text from '../Text/components/Text/Text';

const PopconfirmIcon = styled.div<Required<Pick<IPopconfirmProps, 'size' | 'sentiment'>>>`
  ${({ size, sentiment, theme }) => css`
    svg {
      width: ${popconfirmConsts[size].icon}px;
      height: ${popconfirmConsts[size].icon}px;

      path {
        fill: ${getColorBySentiment(sentiment, theme)};
      }
    }
  `}
`;

const PopconfirmWrapper = styled.div<{ children: ReactNode[] }>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: start;
`;

const PopconfirmTitle = styled(Heading)`
  margin-bottom: unset;
`;

const PopconfirmDescription = styled(Text)`
  margin-bottom: unset;
`;

const PopconfirmActions = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 10px;
`;

const Popconfirm = styled.div<Required<Pick<IPopconfirmProps, 'size'>>>`
  ${({ size }) => css`
    display: flex;
    gap: ${popconfirmConsts[size].gap}px;
    padding: ${popconfirmConsts[size].padding}px;

    ${PopconfirmWrapper} {
      gap: ${popconfirmConsts[size].gap}px;
    }

    ${PopconfirmTitle} {
      margin-top: 2px;
    }
  `}
`;

export { Popconfirm, PopconfirmIcon, PopconfirmWrapper, PopconfirmTitle, PopconfirmDescription, PopconfirmActions };
