import { MouseEvent, ReactElement, useRef } from 'react';

import Checkbox from '../../../Checkbox/Checkbox';
import { ICheckboxProps } from '../../../Checkbox/Checkbox.types';
import {
  TREE_CHECKBOX_CHECKED_CLASS,
  TREE_CHECKBOX_UNCHECKED_CLASS,
  TREE_CHECKBOX_INDETERMINATE_CLASS,
} from '../../../../utils/constants';

const TreeCheckboxes = ({ size, disabled }: { size: ICheckboxProps['size']; disabled: boolean }): ReactElement => {
  const checkedRef = useRef<HTMLInputElement | null>(null);
  const uncheckedRef = useRef<HTMLInputElement | null>(null);
  const indeterminateRef = useRef<HTMLInputElement | null>(null);

  const onClick = (event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => {
    const refToFocus = event.target === checkedRef.current ? uncheckedRef : checkedRef;
    setTimeout(() => {
      refToFocus.current?.focus();
    }, 0);
  };

  return (
    <>
      <Checkbox
        aria-label="Checked"
        checked
        className={TREE_CHECKBOX_CHECKED_CLASS}
        size={size}
        disabled={disabled}
        onClick={onClick}
        ref={checkedRef}
      />
      <Checkbox
        aria-label="Unchecked"
        className={TREE_CHECKBOX_UNCHECKED_CLASS}
        size={size}
        disabled={disabled}
        onClick={onClick}
        ref={uncheckedRef}
      />
      <Checkbox
        aria-label="Indeterminate"
        indeterminate
        checked
        className={TREE_CHECKBOX_INDETERMINATE_CLASS}
        size={size}
        disabled={disabled}
        onClick={onClick}
        ref={indeterminateRef}
      />
    </>
  );
};

export default TreeCheckboxes;
