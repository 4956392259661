import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useContext } from 'react';
export var useApiContext = function () { return useContext(ApiContext); };
export var ApiContext = createContext();
export var ApiProvider = function (props) {
    var _a = useState({
        countries: [],
        owners: [],
        assets: [],
        unitTypes: [],
        unitSubTypes: [],
        assetUnitListingData: [],
        assetsFilterData: [],
        unitSubTypesFilterData: [],
        operationalStatusTypes: [],
        probabilityTypes: [],
        dataProviders: [],
        intelligenceSourceTypes: [],
        dataSets: [],
        feedstocks: [],
        addEditAssetUnitConfigData: null,
        originalAssetUnitConfigData: null,
        fieldsMissing: false,
    }), apiParams = _a[0], setApiParams = _a[1];
    return _jsx(ApiContext.Provider, __assign({ value: [apiParams, setApiParams] }, { children: props.children }));
};
