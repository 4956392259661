import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import moment from 'moment';
import { noop } from 'lodash';
var columns = [
    {
        title: 'Updated Date',
        dataIndex: 'versionEffectiveDate',
        render: function (date) { return moment(date).format('DD MMM YYYY'); },
    },
    {
        title: 'Unit',
        dataIndex: 'assetUnitNm',
        ellipsis: true,
    },
    {
        title: 'Capacity',
        dataIndex: 'totalCapacity',
        render: function (cap, rec) { return "".concat(parseFloat(cap).toFixed(1), " ").concat(rec.measureUnitCd); },
    },
    {
        title: 'Offline ( % )',
        dataIndex: 'offlineCapacityPct',
    },
    {
        title: 'Start Date',
        dataIndex: 'eventStartDt',
        render: function (date) { return moment(date).format('DD MMM YYYY'); },
    },
    {
        title: 'End Date',
        dataIndex: 'eventEndDt',
        render: function (date) { return moment(date).format('DD MMM YYYY'); },
    },
    {
        title: 'Event Type',
        dataIndex: 'eventType',
    },
    {
        title: 'Event Cause',
        dataIndex: 'eventCauseType',
    },
    {
        title: 'Source',
        dataIndex: 'dataProvider',
    },
];
export var EventLinkingModal = function (_a) {
    var events = _a.events, _b = _a.visible, visible = _b === void 0 ? false : _b, _c = _a.onCancel, onCancel = _c === void 0 ? noop : _c, _d = _a.onLinkEvent, onLinkEvent = _d === void 0 ? noop : _d, _e = _a.loading, loading = _e === void 0 ? false : _e;
    //from the Ant Design documentation for row selection in a table
    var _f = useState([]), selectedRowKeys = _f[0], setRowKeys = _f[1];
    var rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: function (selectedRow) {
            var numberSelectedRow = selectedRow.map(function (key) { return parseInt(key); });
            setRowKeys(numberSelectedRow);
        },
    };
    //Unset all selected items whenever the modal is closed or is opened again
    useEffect(function () {
        setRowKeys([]);
    }, [visible]);
    return (_jsx(Modal, __assign({ bodyStyle: { maxHeight: '70vh', overflow: 'auto' }, width: "60vw", visible: visible, centered: true, title: "Link Events - Select an event from the below list to link it and remove it from baseline :", onCancel: onCancel, footer: [
            _jsx(Button, __assign({ onClick: onCancel }, { children: "Cancel" }), "btn1"),
            _jsx(Button, __assign({ disabled: !selectedRowKeys.length, onClick: function () {
                    onLinkEvent(selectedRowKeys[0]);
                }, type: "primary" }, { children: "Link Selected Event" }), "btn2"),
        ] }, { children: _jsx(Table, { loading: loading, rowKey: "assetUnitEventKey", dataSource: events, rowSelection: __assign({ type: 'radio' }, rowSelection), onRow: function (record) { return ({
                onClick: function () {
                    setRowKeys([record.assetUnitEventKey]);
                },
            }); }, columns: columns, pagination: false }) })));
};
export default EventLinkingModal;
