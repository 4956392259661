import { ReactNode } from 'react';

import { ButtonGroupButtonIconPosition } from '../ButtonGroup.types';
import { Positions } from '../../../types/types';

export const useGetProps = (
  label: string,
  icon: ReactNode,
  iconPosition: ButtonGroupButtonIconPosition,
  iconOnly: boolean,
  disabled: boolean,
) => {
  const children = (
    <>
      {iconPosition === Positions.Left && icon}
      {!iconOnly && label}
      {iconPosition === Positions.Right && icon}
    </>
  );

  const tabIndex = disabled ? -1 : 0;

  return { children, tabIndex };
};
