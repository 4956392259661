import styled, { css } from 'styled-components';

import { getBackgroundColorByVariant, getBorderColorByVariant, getColorByVariant } from '../../utils/colorUtils';
import { badgeConsts } from './Badge.consts';
import { IBadgeProps } from './Badge.types';
import { Sizes, Variants } from '../../types/types';

type RequiredProps = 'sentiment' | 'variant' | 'size';

const Badge = styled.div<Required<Pick<IBadgeProps, RequiredProps>> & { $round?: boolean }>`
  ${({ sentiment, variant, size, $round, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${badgeConsts[size].gap}px;
    width: fit-content;
    min-width: ${badgeConsts[size].size}px;
    min-height: ${badgeConsts[size].size}px;
    padding: ${$round ? 'unset' : badgeConsts[size].padding};
    border: ${variant === Variants.Outlined && `1px solid ${getBorderColorByVariant(theme, sentiment, variant)}`};
    border-radius: ${$round ? '50%' : '16px'};
    background-color: ${getBackgroundColorByVariant(theme, sentiment, variant)};
    color: ${getColorByVariant(theme, sentiment, variant)};

    > svg {
      width: ${badgeConsts[size].icon}px;
      height: ${badgeConsts[size].icon}px;
      fill: ${getColorByVariant(theme, sentiment, variant)};
    }
  `}
`;

const BadgeText = styled.span<Required<Pick<IBadgeProps, 'size'>>>`
  ${({ size, theme }) => css`
    ${size === Sizes.Large && theme.label.large.bold}
    ${size === Sizes.Medium && theme.label.medium.bold}
    ${(size === Sizes.Small || size === Sizes.ExtraSmall) && theme.label.small.bold}
    ${size === Sizes.ExtraSmall &&
    css`
      line-height: 14px;
    `};
  `};
`;

export { Badge, BadgeText };
