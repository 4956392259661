import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Avatar, Dropdown, Icons } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { OutsideAlerter } from '../../../commons/OutsideAlerter/OutsideAlerter';
import { AvatarMenu } from './components/avatar-menu';
var StyledAvatar = styled(Avatar)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 8px;\n"], ["\n  margin-right: 8px;\n"])));
var DropdownContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var ChevronDown = Icons.ChevronDown;
export var AppAvatar = function (_a) {
    var onAvatarMenuClick = _a.onAvatarMenuClick;
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var handleVisibilityChange = useCallback(function (visible) {
        if (visible) {
            setIsVisible(true);
        }
    }, []);
    var handleMenuClick = useCallback(function () {
        setIsVisible(false);
        onAvatarMenuClick();
    }, [onAvatarMenuClick]);
    return (_jsx(Dropdown, __assign({ overlay: 
        //TODO: Move hook for OutsideAlerter and useOnClickOutside to the new app when old app is obsolete
        _jsx(OutsideAlerter, __assign({ onClickOutside: function () {
                setIsVisible(false);
            } }, { children: _jsx(AvatarMenu, { onMenuClick: handleMenuClick }) })), trigger: ['click'], placement: "bottomRight", 
        // @ts-ignore: prop is from rc-dropdown but DS doesnt export it yet
        align: {
            offset: [0, 16],
        }, visible: isVisible, onVisibleChange: handleVisibilityChange, 
        // @ts-ignore: prop is from rc-trigger but rc-dropdown doesnt export it
        autoDestroy: true }, { children: _jsxs(DropdownContent, __assign({ role: "button" }, { children: [_jsx(StyledAvatar, { style: { cursor: 'pointer' }, size: "medium" }), _jsx(ChevronDown, { style: { cursor: 'pointer' }, width: 20, height: 20 })] })) })));
};
var templateObject_1, templateObject_2;
