import { ReactNode } from 'react';

export const getString = (child: string | ReactNode): string | undefined =>
  typeof child === 'string' ? child : undefined;

export const convertCamelCaseToWords = (text: string): string | undefined => {
  const result = text.replace(/([A-Z])/g, ' $1').trimStart();

  return result.charAt(0).toUpperCase() + result.slice(1);
};
