import React, { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { NOOP, TAG_CLASS } from '../../utils/constants';
import { Cross } from '../Icon/components';
import * as Styled from './Tag.styles';
import { ITagProps } from './Tag.types';
import { Sizes, Sentiments, Variants, Positions } from '../../types/types';
import { getInnerShape } from './utils/tagUtils';
import { iconSizes } from './Tag.consts';

const Tag = forwardRef<HTMLDivElement, ITagProps>(
  (
    {
      children,
      className,
      size = Sizes.Medium,
      shape,
      variant = Variants.Filled,
      sentiment = Sentiments.Neutral,
      dismissible = false,
      dismissButtonTitle = 'Dismiss',
      onDismissClick = NOOP,
      icon,
      iconPosition = Positions.Left,
      iconOnly = false,
      ...rest
    },
    ref,
  ): ReactElement => {
    const innerShape = getInnerShape(shape);

    return (
      <Styled.Tag
        className={classNames(TAG_CLASS, className)}
        shape={innerShape}
        variant={variant}
        size={size}
        sentiment={sentiment}
        icon={!!icon}
        iconOnly={iconOnly}
        iconPosition={iconPosition}
        ref={ref}
        {...rest}
      >
        {iconPosition === Positions.Left && icon}
        {!iconOnly && (
          <Styled.Label size={size} $haveIcon={!!icon} iconPosition={iconPosition}>
            {children}
          </Styled.Label>
        )}
        {iconPosition === Positions.Right && icon}
        {dismissible && (
          <Styled.TagButton
            title={dismissButtonTitle}
            size={size}
            iconOnly
            icon={<Cross className={`${TAG_CLASS}-dismiss-icon`} width={iconSizes[size]} height={iconSizes[size]} />}
            onClick={onDismissClick}
            variant={Variants.Transparent}
          />
        )}
      </Styled.Tag>
    );
  },
);

export default Tag;
