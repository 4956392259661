import { getSourceData } from '../../services/urlService';
import * as types from '../actions/masterScreenActionsType';
import _ from 'lodash';
export var loadMasterScreenData = function (params) { return function (dispatch) {
    if (params) {
        params.map(function (value, i) {
            getSourceData(value.path)
                .then(function (filterData) {
                if (filterData.data.length > 0) {
                    var masterDataWithoutNull = removeNullData(filterData.data, value.cdName);
                    if (masterDataWithoutNull.length > 0) {
                        dispatch({
                            type: types.LOAD_MASTER_SCREEN_DATA,
                            masterData: masterDataWithoutNull,
                            masterDataType: value.path,
                        });
                    }
                }
            })
                .catch(function (error) {
                return false;
            });
            return true;
        });
    }
    // getSourceData(path)
    //   .then(filterData => {
    //     if (filterData.data.length > 0) {
    //       dispatch({
    //         type: types.LOAD_MASTER_SCREEN_DATA,
    //         masterData: filterData.data,
    //         masterDataType: screenType
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     return;
    //   });
}; };
//Method to skip null value data before feeding to store
var removeNullData = function (dataWithNull, col) {
    var data = _.filter(dataWithNull, function (item) {
        return item[col] !== null && item[col] !== undefined;
    });
    return data;
};
export function loadMasterScreenDataOnAction(path, screenType) {
    return function (dispatch, getState) {
        var currentGlobalState = getState();
        if (typeof currentGlobalState.masterScreenReducer.masterData !== 'undefined') {
            if (currentGlobalState.masterScreenReducer.masterData.has(screenType)) {
                return;
            }
            else {
                dispatch(loadMasterScreenData(path, screenType));
            }
        }
        else {
            dispatch(loadMasterScreenData(path, screenType));
        }
    };
}
export var loadMasterScreenForeignData = function (path, screenType) { return function (dispatch, getState) {
    getSourceData(path)
        .then(function (filterData) {
        if (filterData.data.length > 0) {
            dispatch({
                type: types.LOAD_MASTER_SCREEN_FOREIGN_DATA,
                masterData: filterData.data,
                masterDataType: screenType,
            });
        }
    })
        .catch(function (error) {
        return;
    });
}; };
