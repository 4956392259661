import { PopconfirmSize } from './Popconfirm.types';
import { ButtonSize } from '../Button/Button.types';
import { HeadingLevel } from '../Text/components/Heading/Heading.types';
import { Sizes } from '../../types/types';

type PopconfirmConstsType = {
  [sizes in PopconfirmSize]: {
    padding: number;
    icon: number;
    gap: number;
    titleLevel: HeadingLevel;
    actionSize: ButtonSize;
  };
};

export const popconfirmConsts: PopconfirmConstsType = {
  [Sizes.Small]: {
    padding: 12,
    icon: 20,
    gap: 8,
    titleLevel: 5,
    actionSize: Sizes.ExtraSmall,
  },
  [Sizes.Medium]: {
    padding: 16,
    icon: 24,
    gap: 12,
    titleLevel: 4,
    actionSize: Sizes.Small,
  },
  [Sizes.Large]: {
    padding: 20,
    icon: 28,
    gap: 16,
    titleLevel: 3,
    actionSize: Sizes.Medium,
  },
};
