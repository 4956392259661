import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import DailyView from './dailyView';
import './maintenance.css';
import _ from 'lodash';
import PopUpAdd from './popUpAdd';
import Header from './tools/header';
import Topnav from './tools/topnav';
import FilterContainer from './filterContainer';
import { If } from 'rc-if-else';
import { getSourceFilter } from '../../../../../services/urlService';
import Spinner from '../../../../../commons/spinner';
import DailyMaintenance from './dailyMaintenance';
import { connect } from 'react-redux';
import * as mainFilterActions from '../../../../../redux/actions/mainFilterActions';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import SvgLogo from '../../../../../commons/tools/svgLogo';
import { ApiProvider } from '../../dataStore/apiDataStore';
import MaintenanceAddEdit from './MaintenanceAddEdit.tsx';
var prevMaintenanceData = null;
var maintenance = /** @class */ (function (_super) {
    __extends(maintenance, _super);
    function maintenance(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.year = ['1 Year', '2 Year', '3 Year'];
        _this.changeTitle = function (title) {
            if (!title) {
                _this.setState({
                    title: 'Maintenance Daily',
                });
            }
            else {
                _this.setState({
                    title: 'Maintenance Event Summary',
                });
            }
        };
        _this.resetEnableWatermark = function () {
            _this.setState({
                isApply: true,
            });
        };
        _this.applyRemove = function () {
            _this.setState({
                isApply: false,
                loader: false,
            });
        };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.setAllFilters = function (data) {
            _this.setState({
                allFilters: __assign(__assign({}, _this.state.allFilters), data),
            });
        };
        _this.componentDidMount = function (e) {
            var currentPath = _this.props.location.pathname.split('/').pop();
            if (currentPath === 'maintenanceDaily') {
                _this.setState({
                    maintenanceOn: false,
                    loader: false,
                }, function () {
                    _this.changeTitle(_this.state.maintenanceOn);
                });
            }
            else {
                _this.setState({
                    maintenanceOn: true,
                    loader: true,
                }, function () {
                    _this.changeTitle(_this.state.maintenanceOn);
                });
            }
        };
        _this.showDeltaBetweenProviders = function (baseProvider, limitForHighlight) {
            _this.setState({
                baseProvider: baseProvider,
                limitForHighlight: limitForHighlight,
                maintenanceData: prevMaintenanceData,
                deltaView: true,
            });
        };
        /*
         * common includes
         * this function is called when clicked on 'Apply'
         */
        _this.OnApply = function (url, isDelta, limitForHighlight) {
            var _a;
            if (isDelta === void 0) { isDelta = false; }
            if (limitForHighlight === void 0) { limitForHighlight = null; }
            if (!isDelta) {
                prevMaintenanceData = null;
            }
            _this.setState({
                loader: true,
                url: url,
                deltaView: isDelta,
                limitForHighlight: limitForHighlight,
                baseProvider: null,
            });
            var assetUnitIdWithEq = url.split('&').filter(function (e) { return e.indexOf('unit=') === 0; })[0];
            var assetUnitId = (_a = assetUnitIdWithEq === null || assetUnitIdWithEq === void 0 ? void 0 : assetUnitIdWithEq.split('=')) === null || _a === void 0 ? void 0 : _a[1];
            var path;
            if (_this.state.maintenanceOn) {
                _this.setState({
                    setKeyOnDailyMaintenance: _this.getRandom(),
                });
            }
            else {
                path = 'Maintenance/' + assetUnitId + url;
            }
            if (path) {
                getSourceFilter(path)
                    .then(function (returnData) {
                    if (typeof returnData !== 'undefined') {
                        if (!_this.state.maintenanceOn) {
                            if (typeof returnData.data.maintenanceCapacityAssetUnitList !== 'undefined')
                                _this.setState({
                                    maintenanceData: returnData.data.maintenanceCapacityAssetUnitList.length > 0 ? returnData.data : [],
                                    randKey: _this.getRandom(),
                                }, function () {
                                    if (prevMaintenanceData === null) {
                                        prevMaintenanceData = _this.state.maintenanceData;
                                    }
                                    _this.setState({
                                        loader: false,
                                        isApply: false,
                                    });
                                });
                        }
                    }
                    else {
                        _this.setState({
                            loader: false,
                            isApply: true,
                        });
                    }
                })
                    .catch(function (error) {
                    _this.setState({
                        loader: false,
                        isApply: true,
                    });
                });
            }
        };
        _this.maintanenceViewEvent = function () {
            _this.setState({ cviewisOn: !_this.state.cviewisOn });
        };
        _this.collapseExpandEvent = function () {
            _this.setState({ ceviewisOn: !_this.state.ceviewisOn });
        };
        _this.filterShowEvent = function () {
            _this.setState({
                fsviewisOn: !_this.state.fsviewisOn,
                filterShow: !_this.state.filterShow,
            });
        };
        _this.dailyMaintenanceEvent = function () {
            if (_this.state.maintenanceOn) {
                _this.setState({
                    maintenanceOn: !_this.state.maintenanceOn,
                    title: 'Maintenance Daily',
                });
            }
            else {
                _this.setState({
                    maintenanceOn: _this.state.maintenanceOn,
                    title: 'Maintenance Event Summary',
                });
            }
        };
        _this.expandStandardEvent = function () {
            _this.setState({ esviewisOn: !_this.state.esviewisOn });
        };
        _this.filterBtnToggle = function () {
            _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
        };
        _this.customRender = function (data) {
            var value = data.dataItem[data.field];
            return (_jsx("td", { children: _jsxs("div", __assign({ className: "td-data" }, { children: [value === null ? '' : data.dataItem[data.field].toString(), " KB/D"] })) }));
        };
        _this.visualizationEvent = function () {
            window.open('https://app.powerbi.com/groups/me/apps/99d6e8b2-e816-4907-9de0-6efde3d3055b/reports/600296f7-1f8b-439d-a152-40527da409d2/ReportSectionda033444bb0a3361dd17?ctid=db1e96a8-a3da-442a-930b-235cac24cd5c', '_blank');
        };
        _this.handleDownloadToggle = function () {
            _this.setState({ download: !_this.state.download });
        };
        /**common includes */
        _this.optionDropdownTab = function (e) {
            _this.setState({ selectionPallete: !_this.state.selectionPallete });
        };
        _this.dailyViewHandler = function (e) {
            _this.providerSelect(_this.selector.state.apiQuery.get('providers'), null, false);
        };
        _this.weeklyViewHandler = function (e) {
            _this.providerSelect(_this.selector.state.apiQuery.get('providers'), null, false);
        };
        _this.mqyViewHandler = function (e) {
            _this.providerSelect(_this.selector.state.apiQuery.get('providers'), null, false);
        };
        _this.windowFormator = function (e) {
            var currentWindowHeightSet = parseInt(window.innerHeight - 100);
            _this.setState({ windowHeight: currentWindowHeightSet });
        };
        _this.onChangeYearsInclude = function (e) {
            var years = [];
            years.push(e.target.value);
            _this.selector.optionSelectHandler(years, false, 'years');
        };
        _this.absoluteHandler = function (e) {
            var currentPage = _.last(_this.props.location.pathname.split('/'));
            _this.selector.optionSelectHandler([], false, 'delta_between_views');
            switch (currentPage) {
                case 'daily':
                    _this.DailyView.absoluteDataOnState();
                    break;
                case 'weekly':
                    _this.WeeklyView.absoluteDataOnState();
                    break;
                case 'mqy':
                    _this.MqyView.absoluteDataOnState();
                    break;
                default:
                    break;
            }
            document.getElementById('delta').classList.remove('k-state-active');
            document.getElementById('absolute').classList.add('k-state-active');
        };
        _this.popOverShowHandler = function (e) {
            _this.setState({ popOverShow: !_this.state.popOverShow });
        };
        _this.handleAddEdit = function (e, bool) {
            if (bool === void 0) { bool = false; }
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (Object.keys(this.state.allFilters).length < 7) {
                        toast.warning('Please wait until screen finishes loading.', {
                            autoClose: 6000,
                            toastId: 'ADD_EDIT_BUTTON',
                        });
                        return [2 /*return*/];
                    }
                    if (typeof e !== 'undefined' && !bool) {
                        if (typeof e.tagName !== 'undefined' && (e.tagName == 'DIV' || e.tagName == 'BUTTON' || e.tagName == 'SPAN')) {
                            this.setState({
                                eventsWindow: !this.state.eventsWindow,
                                selectedData: {
                                    Countries: {
                                        text: e.getAttribute('data-country'),
                                        id: parseInt(e.getAttribute('data-country-id')),
                                    },
                                    Cities: {
                                        text: e.getAttribute('data-city'),
                                        id: parseInt(e.getAttribute('data-city-id')),
                                    },
                                    Companies: {
                                        text: e.getAttribute('data-owner'),
                                        id: parseInt(e.getAttribute('data-owner-id')),
                                    },
                                    AssetType: {
                                        text: e.getAttribute('data-assettype'),
                                        id: parseInt(e.getAttribute('data-assettype-id')),
                                    },
                                    Asset: {
                                        text: e.getAttribute('data-assetname'),
                                        id: parseInt(e.getAttribute('data-asset-id')),
                                    },
                                    UnitType: {
                                        text: e.getAttribute('data-unittype'),
                                        id: parseInt(e.getAttribute('data-unittype-id')),
                                    },
                                    UnitSubType: {
                                        text: e.getAttribute('data-unitsubtype'),
                                        id: parseInt(e.getAttribute('data-unitsubtype-id')),
                                    },
                                    Unit: {
                                        text: e.getAttribute('data-unit'),
                                        id: parseInt(e.getAttribute('data-unit-id')),
                                    },
                                    eventKey: parseInt(e.getAttribute('data-event-key')),
                                },
                                editOnUnitCapacities: e.getAttribute('edit-event'),
                                newAddEditBt: false,
                            });
                        }
                        else {
                            this.setState({
                                eventsWindow: !this.state.eventsWindow,
                                newAddEditBt: true,
                                selectedData: {},
                            });
                        }
                    }
                    else if (bool && typeof e !== 'undefined' && typeof e === 'object') {
                        this.setState({
                            eventsWindow: !this.state.eventsWindow,
                            newAddEditBt: false,
                            // editEventKey: ,
                            selectedData: e,
                        });
                    }
                    else {
                        this.setState({
                            eventsWindow: !this.state.eventsWindow,
                            newAddEditBt: true,
                            selectedData: {},
                        });
                    }
                    return [2 /*return*/];
                });
            });
        };
        _this.currentYearData = function (data) {
            _this.setState({
                currentYearData: data,
            });
        };
        _this.state = {
            value: new Date(),
            selectionPallete: false,
            dailyView: true,
            weeklyView: false,
            mqyView: false,
            windowHeight: '',
            popOverShow: false,
            selectorState: _this.selector,
            filterbtnisOn: false,
            cviewisOn: false,
            ceviewisOn: false,
            fsviewisOn: true,
            maintenanceOn: true,
            esviewisOn: false,
            eventsWindow: false,
            download: false,
            isApply: true,
            filterShow: true,
            currentYearData: 3,
            year: 0,
            maintenanceData: [],
            newAddEditBt: false,
            title: 'Maintenance Event Summary',
            maintenanceEventData: [],
            url: null,
            setKeyOnDailyMaintenance: _this.getRandom(),
            applyButton: false,
            applyButtonEnable: [],
            deltaView: false,
            limitForHighlight: null,
            baseProvider: null,
            allFilters: {},
            selectedData: {},
            pageLimit: 50,
        };
        _this.dailyViewHandler = _this.dailyViewHandler.bind(_this);
        _this.weeklyViewHandler = _this.weeklyViewHandler.bind(_this);
        _this.mqyViewHandler = _this.mqyViewHandler.bind(_this);
        // this.deltaBetweensViewHandler = this.deltaBetweensViewHandler.bind(this);
        _this.popOverShowHandler = _this.popOverShowHandler.bind(_this);
        return _this;
    }
    maintenance.prototype.render = function () {
        var _this = this;
        var _a = this.state, cviewisOn = _a.cviewisOn, ceviewisOn = _a.ceviewisOn, fsviewisOn = _a.fsviewisOn, esviewisOn = _a.esviewisOn, eventsWindow = _a.eventsWindow, maintenanceOn = _a.maintenanceOn;
        return (_jsx(ApiProvider, { children: _jsxs(_Fragment, { children: [this.state.loader && _jsx(Spinner, {}), _jsx(Header, { title: this.state.title, onAddEditClick: this.handleAddEdit, onAddEditClickEnable: Object.keys(this.state.allFilters).length == 9 ? false : true, onDownloadToggle: this.handleDownloadToggle, download: this.state.download }), _jsxs("div", __assign({ className: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100" }, { children: [_jsx("div", { children: _jsx(Topnav, { cvisOn: cviewisOn, 
                                    // capacityViewEvent={this.capacityViewEvent}
                                    pathDaily: "/dashboard/globalAssetDatabase/maintenance/maintenanceDaily", pathEventSummary: "/dashboard/globalAssetDatabase/maintenance/maintenanceEventSummary", collapseExpandEvent: this.collapseExpandEvent, ceisOn: ceviewisOn, filterShowEvent: this.filterShowEvent, fsisOn: fsviewisOn, maintenanceOn: maintenanceOn, dailyMaintenanceEvent: this.dailyMaintenanceEvent, expandStandardEvent: this.expandStandardEvent, esisOn: esviewisOn, visualizationEvent: this.visualizationEvent, chartEvent: this.chartEvent }) }), _jsxs("div", __assign({ className: "filter", style: { display: this.state.filterShow ? 'block' : 'none' } }, { children: [_jsx(FilterContainer, { ref: function (ref) { return (_this.applyTrigger = ref); }, OnApply: this.OnApply, switchPath: this.props.match.path, maintenanceOn: this.state.maintenanceOn, resetWMEnable: this.resetEnableWatermark, currentYearData: this.currentYearData, globalFilterStore: this.props.actions.addSelectedElementToGlobalArray, deleteFilterStore: this.props.actions.removeSelectedElementToGlobalArray, mainFilterSelectedItems: this.props.mainFilterSelectedItems, showDeltaBetweenProviders: this.showDeltaBetweenProviders, setAllFilters: this.setAllFilters, startWithLastUpdated: this.state.maintenanceOn }), _jsx("div", { className: "border-top mt-0 ml-0 mr-0 mb-3 p-0 shadow" })] })), _jsxs("div", __assign({ style: {
                                    height: fsviewisOn ? '670px' : '812px',
                                    border: '0px',
                                } }, { children: [_jsx(If, __assign({ condition: this.state.maintenanceOn }, { children: _jsx(DailyMaintenance, { height: fsviewisOn ? '670px' : '812px', ref: function (ref) { return (_this.dailyMaintenance = ref); }, 
                                            // data={this.state.maintenanceEventData}
                                            path: this.state.url, 
                                            // handleAddEdit={this.handleAddEdit}
                                            // show={
                                            //   typeof this.state.maintenanceEventData
                                            //     .maintenanceEventDataList !== "undefined" &&
                                            //   this.state.maintenanceEventData.maintenanceEventDataList
                                            //     .length > 0
                                            //     ? true
                                            //     : false
                                            // }
                                            applyRemove: this.applyRemove, onBaselineEdit: this.handleAddEdit }, this.state.setKeyOnDailyMaintenance) })), _jsx(If, __assign({ condition: !this.state.maintenanceOn }, { children: _jsx("div", __assign({ className: "maintenance-datasheet pr-2", style: { display: !this.state.isApply ? 'block' : 'none' } }, { children: _jsx(DailyView, { height: fsviewisOn ? '670px' : '812px', data: this.state.maintenanceData, ref: function (ref) { return (_this.dailyView = ref); }, onBaselineEdit: this.handleAddEdit, deltaView: this.state.deltaView, limitForHighlight: this.state.limitForHighlight, baseProvider: this.state.baseProvider }, this.state.randKey) })) })), _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100" }, { children: _jsx("div", __assign({ className: "container", style: { marginTop: '5%' } }, { children: _jsxs("div", __assign({ className: "text-center", style: { display: this.state.isApply ? 'block' : 'none' } }, { children: [_jsx(SvgLogo, {}), _jsxs("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: ["Click on Apply to view ", this.state.title] }))] })) })) }))] }))] })), _jsx(MaintenanceAddEdit, { onClose: this.handleAddEdit, selectedData: this.state.selectedData, capacity: false, maintenance: eventsWindow, onApply: function () {
                            //this.OnApply(this.state.url);
                            if (_this.state.maintenanceOn) {
                                _this.dailyMaintenance.onAddEditRefresh();
                            }
                            else {
                                // this.dailyView.onAddEditRefresh();
                                _this.OnApply(_this.state.url);
                            }
                        }, currentYearData: this.state.currentYearData, newAddEditBt: this.state.newAddEditBt, allFilters: this.state.allFilters }), this.state.popOverShow && _jsx(PopUpAdd, { onClick: this.popOverShowHandler }), this.state.eventsWindow && _jsx("div", { className: "k-overlay" })] }) }));
    };
    return maintenance;
}(Component));
function mapStateToProps(state) {
    return {
        mainFilterSelectedItems: state.mainFilterActionReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainFilterActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(maintenance);
