import { __spreadArray } from "tslib";
import dayjs from 'dayjs';
import { renderDateColumn } from './renderDateColumn.util';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
export var convertToCsvAndDownload = function (jsonObject, propertyName, fileName, displayNameMapping) {
    var csv = propertyName !== ''
        ? convertToCsv(jsonObject, propertyName, displayNameMapping)
        : convertToCsvForOfflineCap(jsonObject);
    downloadCsv(csv, fileName);
};
var replacer = function (_key, value) { return (value === null ? '' : value); }; // specify how you want to handle null values here
export var convertToCsv = function (jsonObject, propertyName, displayNameMapping) {
    var mappedProperties = displayNameMapping[propertyName];
    var header = Object.values(mappedProperties);
    var keys = Object.keys(mappedProperties);
    var jsonArray = propertyName === 'maintenanceEventDataList' || propertyName === 'capacityEventDataList'
        ? jsonObject[propertyName]
        : jsonObject;
    return __spreadArray([
        header.join(',')
    ], jsonArray.map(function (row) {
        return keys
            .map(function (fieldName) {
            return fieldName.includes('Date') ? renderDateColumn(row[fieldName]) : JSON.stringify(row[fieldName], replacer);
        })
            .join(',');
    }), true).join('\r\n');
};
export var convertToCsvForOfflineCap = function (data) {
    var headers = Object.keys(data[0]);
    return __spreadArray([
        headers
            .map(function (header) {
            if (isNaN(parseInt(header, 10))) {
                return header;
            }
            return dayjs(parseInt(header, 10)).format(DATE_FORMAT_DD_MMM_YYYY);
        })
            .join(',')
    ], data.map(function (row) { return headers.map(function (header) { return row[header].replace(/,/g, ' '); }); }), true).join('\r\n');
};
export var downloadCsv = function (csv, fileName) {
    var blob = new Blob([csv], { type: 'text/csv' });
    var url = URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
};
