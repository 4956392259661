import styled, { css } from 'styled-components';

import { Theme } from '../../providers/theme/theme';
import { formFieldConsts } from './FormField.consts';
import { FormFieldSize, FormFieldSentiment } from './FormField.types';
import { Sizes, Sentiments } from '../../types/types';

type MessageStyledProps = {
  size: FormFieldSize;
  sentiment?: FormFieldSentiment;
};

const getColor = (theme: Theme, sentiment?: FormFieldSentiment) => {
  switch (sentiment) {
    case Sentiments.Secondary:
      return theme.text.onSurface.subtle;
    case Sentiments.Negative:
      return theme.text.onSystem.subtle.negative;
    case Sentiments.Positive:
      return theme.text.onSystem.subtle.positive;
    default:
      return theme.text.onSurface.strong;
  }
};

const FormField = styled.div`
  width: 100%;
  margin-bottom: 12px;

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
`;

const Message = styled.div<MessageStyledProps>`
  ${({ sentiment, size, theme }) => css`
    ${size === Sizes.Small && theme.label.small.normal};
    ${size === Sizes.Medium && theme.label.medium.normal};
    ${size === Sizes.Large && theme.label.large.normal};
    color: ${getColor(theme, sentiment)};
    margin-top: ${formFieldConsts[size].margin};
  `};

  display: flex;
`;

export { FormField, Message };
