import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
import { StyledHighlight } from 'components/capacity-actions-modal/CapacityActionsModal.styles';
import { DivWithEllipsis, renderCellWithEllipsis } from 'pages/capacity/Capacity.hooks';
var conditionalHighlightRender = function (name, record, field) {
    //check for type safety
    if (record[field] && name) {
        return _jsx(StyledHighlight, { children: renderCellWithEllipsis(name) });
    }
    return renderCellWithEllipsis(name);
};
export var columns = function (_a) {
    var shortKeyType = _a.shortKeyType;
    return [
        {
            header: 'Update date',
            accessorKey: 'versionEffectiveDate',
            cell: function (info) { return _jsx(DivWithEllipsis, { children: dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY) }); },
        },
        {
            header: 'Offline %',
            accessorKey: 'offlineCapacityPct',
            cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasOfflinePctChanged'); },
        },
        {
            header: 'Start date',
            accessorKey: shortKeyType ? 'eventStartDt' : 'eventStartDate',
            cell: function (info) {
                var startDate = dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY);
                if ('hasStartDateChanged' in info.row.original && info.row.original.hasStartDateChanged) {
                    return _jsx(StyledHighlight, { children: startDate });
                }
                return _jsx(DivWithEllipsis, { children: startDate });
            },
        },
        {
            header: 'End date',
            accessorKey: shortKeyType ? 'eventEndDt' : 'eventEndDate',
            cell: function (info) {
                var endDate = dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY);
                if ('hasStartDateChanged' in info.row.original && info.row.original.hasStartDateChanged) {
                    return _jsx(StyledHighlight, { children: endDate });
                }
                return _jsx(DivWithEllipsis, { children: endDate });
            },
        },
        {
            header: 'Event type',
            accessorKey: 'eventType',
            cell: function (info) { return (_jsx(DivWithEllipsis, { children: conditionalHighlightRender(info.getValue(), info.row.original, 'hasEventTypeChanged') })); },
        },
        {
            header: 'Event cause',
            accessorKey: 'eventCauseType',
            cell: function (info) { return (_jsx(DivWithEllipsis, { children: conditionalHighlightRender(info.getValue(), info.row.original, 'hasEventCauseChanged') })); },
        },
        {
            header: 'Source',
            accessorKey: 'dataProvider',
            cell: function (info) { return (_jsx(DivWithEllipsis, { children: conditionalHighlightRender(info.getValue(), info.row.original, 'hasDataProviderChanged') })); },
        },
    ];
};
export var addHighlightFieldsToVersions = function (data) {
    var newData = data.map(function (item) { return (__assign(__assign({}, item), { hasOfflinePctChanged: false, hasStartDateChanged: false, hasEndDateChanged: false, hasEventTypeChanged: false, hasEventCauseChanged: false, hasDataProviderChanged: false })); });
    data.forEach(function (column, index) {
        if (index + 1 < data.length) {
            if (column.offlineCapacityPct !== data[index + 1].offlineCapacityPct) {
                newData[index].hasOfflinePctChanged = true;
            }
            if (column.eventStartDate !== data[index + 1].eventStartDate) {
                newData[index].hasStartDateChanged = true;
            }
            if (column.eventEndDate !== data[index + 1].eventEndDate) {
                newData[index].hasEndDateChanged = true;
            }
            if (column.eventType !== data[index + 1].eventType) {
                newData[index].hasEventTypeChanged = true;
            }
            if (column.eventCauseType !== data[index + 1].eventCauseType) {
                newData[index].hasEventCauseChanged = true;
            }
            if (column.dataProvider !== data[index + 1].dataProvider) {
                newData[index].hasDataProviderChanged = true;
            }
        }
    });
    return newData;
};
