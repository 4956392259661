import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { BUTTON_CLASS } from '../../utils/constants';
import * as Styled from './Button.styles';
import { IButtonProps } from './Button.types';
import ButtonContent from './components/ButtonContent/ButtonContent';
import { Sizes, Variants, Positions, Sentiments } from '../../types/types';

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className = '',
      children,
      variant = Variants.Filled,
      size = Sizes.Medium,
      icon,
      iconOnly = false,
      iconPosition = Positions.Left,
      sentiment = Sentiments.Default,
      loading = false,
      alignment = 'edge',
      type = 'button',
      ...rest
    }: IButtonProps,
    ref,
  ) => (
    <Styled.Button
      className={classNames(BUTTON_CLASS, className)}
      size={size}
      variant={variant}
      iconOnly={iconOnly}
      iconPosition={iconPosition}
      sentiment={sentiment}
      alignment={alignment}
      type={type}
      ref={ref}
      {...rest}
    >
      <ButtonContent
        variant={variant}
        sentiment={sentiment}
        loading={loading}
        icon={icon}
        iconOnly={iconOnly}
        iconPosition={iconPosition}
        size={size}
      >
        {children}
      </ButtonContent>
    </Styled.Button>
  ),
);

export default Button;
