import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function asset(props) {
    var validationParams = {
        assetNm: 'Select Asset Name.',
        assetClassKey: 'Select Asset Class type.',
        dataProviderKey: 'Select Data Provider Name.',
        datasetKey: 'Select Dataset Name.',
        // assetConfigurationTypeKey: "Select Asset Configuration Type",
        operationalStatusTypeKey: 'Select Operational Status Type.',
        // industryTypeKey: "Select Industry Type Name.",
        geopoliticalEntityKey: 'Select Location.',
    };
    var columnParams = [
        {
            field: 'assetNm',
            title: 'Name',
        },
        {
            field: 'assetShortNm',
            title: 'Short Name',
        },
        {
            field: 'assetClassCd',
            title: 'Asset Class',
            key: 'assetClassKey',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'datasetNm',
            title: 'Data set Key',
            key: 'datasetKey',
        },
        {
            field: 'probabilityLockedInd',
            title: 'Probablity Locked ?',
            key: 'booleanList',
        },
        {
            field: 'geopoliticalEntityNm',
            title: 'Location',
            key: 'geopoliticalEntityKey',
        },
        {
            field: 'operationalStatusTypeCd',
            title: 'Operational Status',
            key: 'operationalStatusTypeKey',
        },
        {
            field: 'industryTypeCd',
            title: 'Industry Type',
            key: 'industryTypeKey',
        },
        {
            field: 'assetConfigurationTypeCd',
            title: 'Configuration Type',
            key: 'assetConfigurationTypeKey',
        },
        {
            field: 'assetStartDt',
            title: 'Start Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            editor: 'date',
        },
        {
            field: 'assetCloseDt',
            title: 'Close Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            editor: 'date',
        },
        {
            field: 'petchemIntegrationInd',
            title: 'Petchem Integration ?',
            key: 'booleanList',
        },
        {
            field: 'coastalLocationInd',
            title: 'Coastal Location ?',
            key: 'booleanList',
        },
        {
            field: 'internationalTradingInd',
            title: 'International Trading ?',
            key: 'booleanList',
        },
        {
            field: 'apiGravityAverageNum',
            title: 'API Gravity Average',
        },
        {
            field: 'sulphurAverageNum',
            title: 'Sulphur Average',
        },
        {
            field: 'totalAcidNum',
            title: 'Total ACID',
        },
        {
            field: 'latitudeNum',
            title: 'Latitude',
        },
        {
            field: 'longitudeNum',
            title: 'Longitude',
        },
        {
            field: 'fccEquivalentQty',
            title: 'FCC Equivalent Quantity',
        },
        {
            field: 'shellUserId',
            title: 'User',
            key: 'userKey',
        },
        {
            field: 'intelligenceSourceTypeCd',
            title: 'Intelligence Source Type Key',
            key: 'intelligenceSourceTypeKey',
        },
        {
            field: 'intelligenceSourceTxt',
            title: 'Intelligence Source Text',
        },
        {
            field: 'intelligenceSourceUrl',
            title: 'Intelligence Source URL',
        },
        {
            field: 'reportDt',
            title: 'Report Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            editor: 'date',
        },
        {
            field: 'versionEffectiveDt',
            title: 'Effective Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionTerminationDt',
            title: 'Termination Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'assets' });
}
export default asset;
