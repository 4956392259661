import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { PECTEN_CLASS } from '../../utils/constants';
import * as Styled from './Pecten.styles';
import { IPectenProps } from './Pecten.types';
import { Sizes } from '../../types/types';
import { getEmphasis, getPadding } from './utils/pectenUtils';

const Pecten = ({
  className,
  size = Sizes.Medium,
  emphasis,
  colorStyle,
  padding,
  ...rest
}: IPectenProps): ReactElement => {
  const innerPadding = getPadding(padding);
  const innerEmphasis = getEmphasis(emphasis, colorStyle);

  return (
    <Styled.Pecten
      className={classNames(PECTEN_CLASS, className)}
      prefixCls={PECTEN_CLASS}
      size={size}
      $emphasis={innerEmphasis}
      $padding={innerPadding}
      {...rest}
    />
  );
};
export default Pecten;
