import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function baselineProviders(props) {
    var validationParams = {
        baselineTypeCd: 'Select Baseline type Key.',
    };
    var columnParams = [
        {
            field: 'baselineTypeCd',
            title: 'Baseline type Key',
            key: 'baselineTypeKey',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'ownershipClassCd',
            title: 'Ownership Class Key',
            key: 'ownershipClassKey',
        },
        {
            field: 'assetClassCd',
            title: 'Asset Class Key',
            key: 'assetClassKey',
        },
        {
            field: 'automaticBaseliningInd',
            title: 'Automatic Baseline?',
            key: 'booleanList',
        },
        {
            field: 'baseliningPriorityNum',
            title: 'Baselining Priority Number',
            editingBasedOn: 'automaticBaseliningInd',
        },
        {
            field: 'versionEffectiveDt',
            title: 'Effective Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionTerminationDt',
            title: 'Termination Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Code',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Code',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Name',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Name',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'baselineDataProvider' });
}
export default baselineProviders;
