import { emptySplitApi } from './emptySplitApi';
import { resolveParamsObject } from 'common/utils/url/search-params';
var chartApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getOfflineCapacityByTimeFrameChart: builder.query({
            query: function (_a) {
                var _b = _a.unitTypeIds, unitTypeIds = _b === void 0 ? [] : _b, _c = _a.unitSubTypeIds, unitSubTypeIds = _c === void 0 ? [] : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _a.companyIds, companyIds = _e === void 0 ? [] : _e, _f = _a.countryIds, countryIds = _f === void 0 ? [] : _f, _g = _a.regionIds, regionIds = _g === void 0 ? [] : _g, _h = _a.assetUnitIds, assetUnitIds = _h === void 0 ? [] : _h, startDate = _a.startDate, endDate = _a.endDate, version = _a.version, chartMode = _a.chartMode, splitBySubType = _a.splitBy, _j = _a.assetOutageGroupKey, assetOutageGroupKey = _j === void 0 ? [] : _j, _k = _a.derateCapacityInd, derateCapacityInd = _k === void 0 ? [] : _k, _l = _a.eventCauseTypeKey, eventCauseTypeKey = _l === void 0 ? [] : _l;
                return ({
                    url: '/OfflineCapacityByTimeFrameChart',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        assetUnitKeys: assetUnitIds,
                        startDate: [startDate],
                        endDate: [endDate],
                        version: [version],
                        chartMode: [chartMode],
                        splitBy: [splitBySubType],
                        assetOutageGroupKey: assetOutageGroupKey,
                        derateCapacityInd: derateCapacityInd,
                        eventCauseTypeKey: eventCauseTypeKey,
                    }),
                });
            },
        }),
    }); },
});
export var useGetOfflineCapacityByTimeFrameChartQuery = chartApis.useGetOfflineCapacityByTimeFrameChartQuery;
