import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, PureComponent } from 'react';
import { getSourceFilter } from '../../services/urlService';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, AreaChart, Area, } from 'recharts';
import NavPanel from './navPannel.jsx';
import Spinner from '../../commons/spinner';
var _ = require('lodash');
var ChartList = function (props) {
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState({}), data = _b[0], setData = _b[1];
    var color = [
        '#FFFFFF',
        '#E70E0D',
        '#2196F3',
        '#03A9F5',
        '#00BBD4',
        '#009788',
        '#4CB050',
        '#8BC24A',
        '#CDDC39',
        '#FFEB3C',
        '#FEC107',
        '#FF9801',
        '#221815',
        '#9E9E9E',
        '#607D8B',
        '#0000FA',
        '#FFFF01',
        '#008300',
        '#FF00FA',
        '#FD0000',
        '#F7F404',
    ];
    useEffect(function () {
        if (props.path) {
            setLoader(true);
            var chartPath = 'CapacitiesOverTime' + props.path;
            getSourceFilter(chartPath).then(function (returnData) {
                //api data
                setData(returnData.data);
                setLoader(false);
            });
        }
    }, []);
    var switchChart = function (e) {
        for (var i = 0; i < 9; i++) {
            document.getElementById('chart' + (i + 1)).classList.remove('show');
            document.getElementById('chart' + (i + 1)).classList.add('hide');
            if (e == i + 1) {
                document.getElementById('chart' + e).classList.remove('hide');
                document.getElementById('chart' + e).classList.add('show');
            }
        }
    };
    var monthTickFormatter = function (tick) {
        var date = new Date(tick);
        return date.getMonth() + 1;
    };
    var renderQuarterTick = function (tickProps) {
        var x = tickProps.x, y = tickProps.y, payload = tickProps.payload;
        var value = payload.value, offset = payload.offset;
        var date = new Date(value);
        var month = date.getMonth();
        var quarterNo = Math.floor(month / 3) + 1;
        var isMidMonth = month % 3 === 1;
        if (month % 3 === 1) {
            return _jsx("text", __assign({ x: x + offset, y: y - 4, textAnchor: "middle" }, { children: "Q".concat(quarterNo) }));
        }
        var isLast = month === 11;
        if (month % 3 === 0 || isLast) {
            var pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;
            return _jsx("path", { d: "M".concat(pathX, ",").concat(y - 4, "v").concat(-35), stroke: "red" });
        }
        return null;
    };
    var CustomizedLabel = /** @class */ (function (_super) {
        __extends(CustomizedLabel, _super);
        function CustomizedLabel() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedLabel.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, value = _a.value;
            return (_jsx("text", __assign({ x: x, y: y, dy: -4, fill: stroke, fontSize: 10, textAnchor: "middle" }, { children: value })));
        };
        return CustomizedLabel;
    }(PureComponent));
    var CustomizedAxisTick = /** @class */ (function (_super) {
        __extends(CustomizedAxisTick, _super);
        function CustomizedAxisTick() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedAxisTick.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, payload = _a.payload;
            return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: _jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "end", fill: "#666", transform: "rotate(-35)" }, { children: payload.value })) })));
        };
        return CustomizedAxisTick;
    }(PureComponent));
    var chartData = [];
    _.forEach(data.labels, function (value) {
        var chartObj = {};
        chartObj['name'] = value;
        _.forEach(data.data, function (provider) {
            _.forEach(provider.capacityOverTimeGroup, function (qty) {
                if (qty.capacityMonth === value) {
                    chartObj[provider.dataProviderName] = qty.quantity;
                }
            });
        });
        chartData.push(chartObj);
    });
    return (_jsxs("div", __assign({ style: { width: '100%', height: 500, position: 'absolute' } }, { children: [loader && _jsx(Spinner, {}), _jsxs("div", __assign({ className: "row chart-continer" }, { children: [_jsx(NavPanel, { switchChart: switchChart }), _jsx("div", __assign({ className: "col-12 p-0" }, { children: _jsx("button", __assign({ className: "k-button-icon k-button k-bare k-button-icon k-button k-bare m-1 pull-right", onClick: props.chartEvent, title: "Close" }, { children: _jsx("span", { className: "k-icon k-i-close" }) })) })), _jsx("div", __assign({ id: "chart0", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(LineChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, { verticalAlign: "top", height: 36 }), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Line, { type: "monotone", dataKey: pname.dataProviderName, stroke: color[index] }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart1", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(LineChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Line, { type: "monotone", strokeDasharray: "5 5", dataKey: pname.dataProviderName, stroke: color[index] }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart2", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(LineChart, __assign({ data: chartData, height: 300, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", tick: _jsx(CustomizedAxisTick, {}) }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Line, { type: "monotone", strokeDasharray: "5 5", dataKey: pname.dataProviderName, label: _jsx(CustomizedLabel, {}), stroke: color[index] }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart3", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(AreaChart, __assign({ data: chartData, height: 300, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Area, { strokeDasharray: "5 5", dataKey: pname.dataProviderName, stackId: "1", stroke: color[index], fill: color[index] }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart4", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return _jsx(Bar, { dataKey: pname.dataProviderName, fill: color[index] }, 'chart' + index);
                                        })] })) }) })), _jsx("div", __assign({ id: "chart5", className: "col-12 p-0 show" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return _jsx(Bar, { dataKey: pname.dataProviderName, stackId: "a", fill: color[index] }, 'chart' + index);
                                        })] })) }) })), _jsx("div", __assign({ id: "chart6", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return _jsx(Bar, { dataKey: pname.dataProviderName, stackId: "a", fill: color[index] }, 'chart' + index);
                                        })] })) }) })), _jsx("div", __assign({ id: "chart7", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { cursor: { fill: 'transparent' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, background: { fill: '#595959' }, fill: color[index] }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart8", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", tickFormatter: monthTickFormatter }), _jsx(XAxis, { dataKey: "name", axisLine: false, tickLine: false, interval: 0, tick: renderQuarterTick, height: 1, scale: "band", xAxisId: "quarter" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, background: { fill: '#595959' }, fill: color[index] }, 'chart' + index));
                                        })] })) }) }))] }))] })));
};
export default ChartList;
