import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { PowerBITokenHelper } from './PowerBITokenHelper';
import { SESSIONSTORAGE_POWER_BI_ACCESS_TOKEN } from 'common/constants';
import './EmbeddedReport.css';
export var EmbeddedReport = function (_a) {
    var _b;
    var reportId = _a.reportId, pageName = _a.pageName, _c = _a.format, format = _c === void 0 ? 'Full' : _c;
    var powerBiToken = (_b = window.localStorage.getItem(SESSIONSTORAGE_POWER_BI_ACCESS_TOKEN)) !== null && _b !== void 0 ? _b : 'undefined';
    var _d = useState(false), isErrorLoading = _d[0], setErrorLoading = _d[1];
    var _e = useState({
        type: 'report',
        tokenType: models.TokenType.Aad,
        id: reportId,
        pageName: pageName,
        embedUrl: 'https://app.powerbi.com/reportEmbed?appId=99d6e8b2-e816-4907-9de0-6efde3d3055b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1ILVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19',
        accessToken: powerBiToken,
        settings: {
            persistentFiltersEnabled: true,
            personalBookmarksEnabled: true,
            navContentPaneEnabled: false,
            bars: {
                actionBar: {
                    visible: true,
                },
            },
            panes: {
                // bookmarks: {
                //     visible: true
                // },
                fields: {
                    expanded: false,
                },
                filters: {
                    expanded: false,
                    visible: true,
                },
                pageNavigation: {
                    visible: !pageName,
                },
                selection: {
                    visible: true,
                },
                syncSlicers: {
                    visible: true,
                },
                visualizations: {
                    expanded: true,
                },
            },
        },
    }), sampleReportConfig = _e[0], setReportConfig = _e[1];
    var eventHandlersMap = new Map([
        // ['loaded', () => console.log('Report has loaded')],
        // ['rendered', () => console.log('Report has rendered')],
        // ['visualClicked', () => console.log('visual clicked')],
        // ['pageChanged', (event) => console.log(event)],
        [
            'error',
            function (event) {
                if (event) {
                    setErrorLoading(true);
                }
            },
        ],
    ]);
    var handleAccessTokenChange = function (event) {
        setErrorLoading(false);
        setReportConfig(__assign(__assign({}, sampleReportConfig), { accessToken: event.target.value }));
        window.localStorage.setItem(SESSIONSTORAGE_POWER_BI_ACCESS_TOKEN, event.target.value);
    };
    return (_jsx("div", { children: isErrorLoading ? (_jsx("div", { children: _jsx(PowerBITokenHelper, { handleAccessTokenChange: handleAccessTokenChange }) })) : (_jsx(PowerBIEmbed, { eventHandlers: eventHandlersMap, embedConfig: sampleReportConfig, cssClassName: format === 'Full' ? 'pbi-report-container-large' : 'pbi-report-container' })) }));
};
