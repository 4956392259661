import React, { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { ALERT_CLASS, ICON_CLASS, NOOP } from '../../utils/constants';
import Button from '../Button/Button';
import { Cross } from '../Icon/components';
import * as Styled from './Alert.styles';
import { IAlertProps } from './Alert.types';
import { Sizes, Sentiments, Variants, Alignments } from '../../types/types';
import { getAlertStateIcon, getAlertLabelType } from './utils/alertUtils';
import Text from '../Text/components/Text/Text';

const Alert = forwardRef<HTMLDivElement, IAlertProps>(
  (
    {
      className,
      size = Sizes.Medium,
      label,
      children,
      sentiment = Sentiments.Neutral,
      icon,
      iconVisibility = true,
      elevation = false,
      dismissible = false,
      onDismissClick = NOOP,
      actionsAlignment = Alignments.Bottom,
      actions,
      dismissButtonTitle = 'Dismiss',
      solidBgColor = false,
      ...rest
    },
    ref,
  ): ReactElement => {
    const iconComponent = icon || getAlertStateIcon(sentiment, size);

    return (
      <Styled.Alert
        size={size}
        className={classNames(ALERT_CLASS, className)}
        sentiment={sentiment}
        $elevation={elevation}
        solidBgColor={solidBgColor}
        ref={ref}
        {...rest}
      >
        {iconVisibility && (
          <Styled.IconContainer sentiment={sentiment} solidBgColor={solidBgColor}>
            {iconComponent}
          </Styled.IconContainer>
        )}
        <Styled.AlertContentContainer size={size} actionsAlignment={actionsAlignment} $hasLabel={!!label}>
          <Styled.AlertContent>
            {label && (
              <Styled.AlertLabel type={getAlertLabelType(size)} bold>
                {label}
              </Styled.AlertLabel>
            )}
            <Text size={size === Sizes.Large ? Sizes.Medium : Sizes.Small}>{children}</Text>
          </Styled.AlertContent>
          {actions?.length && (
            <Styled.Actions actionsAlignment={actionsAlignment} dismissible={dismissible}>
              {actions.map(({ label: actionLabel, action, props }) => (
                <Button
                  key={actionLabel}
                  onClick={action}
                  {...props}
                  size={size === Sizes.Large ? Sizes.Medium : Sizes.Small}
                  variant={Variants.Outlined}
                >
                  {actionLabel}
                </Button>
              ))}
            </Styled.Actions>
          )}
        </Styled.AlertContentContainer>
        {dismissible && (
          <Styled.DismissContainer>
            <Styled.DismissButton
              title={dismissButtonTitle}
              onClick={onDismissClick}
              variant={Variants.Transparent}
              iconOnly
              size={Sizes.Small}
              icon={<Cross className={ICON_CLASS} />}
            />
          </Styled.DismissContainer>
        )}
      </Styled.Alert>
    );
  },
);

export default Alert;
