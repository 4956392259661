// This hook is tweaked from Ant Design's menu component
// https://github.com/ant-design/ant-design/blob/master/components/menu/hooks/useItems.tsx
// It converts the items array to React nodes using our components

import { useMemo } from 'react';

import { ItemType } from 'rc-menu/lib/interface';

import MenuDivider from '../components/MenuDivider';
import MenuItem from '../components/MenuItem';
import MenuItemGroup from '../components/MenuItemGroup';
import SubMenu from '../components/SubMenu';
import { MenuItemGroupType, MenuItemType, SubMenuType } from '../Menu.types';

const convertItemsToNodes = (items: ItemType[], level: number) =>
  (items || [])
    .map((opt, index) => {
      if (opt && typeof opt === 'object') {
        const { label, children, key, type, ...restProps } = opt as any;
        const mergedKey = key ?? `tmp-${index}`;

        if (children || type === 'group') {
          if (type === 'group') {
            return (
              <MenuItemGroup key={mergedKey} {...restProps} title={label}>
                {convertItemsToNodes(children, level + 1)}
              </MenuItemGroup>
            );
          }

          return (
            <SubMenu key={mergedKey} level={level} {...restProps} title={label}>
              {convertItemsToNodes(children, level + 1)}
            </SubMenu>
          );
        }

        if (type === 'divider') {
          return <MenuDivider key={mergedKey} {...restProps} />;
        }

        return (
          <MenuItem key={mergedKey} level={level} {...restProps}>
            {label}
          </MenuItem>
        );
      }

      return null;
    })
    .filter((opt) => opt);

export const useItems = (items?: ItemType[]) =>
  useMemo(() => {
    if (!items) {
      return items;
    }

    return convertItemsToNodes(items, 0);
  }, [items]);

const isParent = (item: ItemType): item is SubMenuType | MenuItemGroupType => !!item && 'children' in item;
const isMenuItem = (item: ItemType): item is MenuItemType => !!item && 'key' in item && !('children' in item);

export const getItemsByPath: (path: string, items: ItemType[] | undefined) => string[] = (path, items) => {
  if (items === undefined) return [];
  const findItem: (menuItems: ItemType[]) => string[] = (menuItems) =>
    menuItems.reduce<string[]>((acc, item) => {
      if (!item) return acc;
      if (isParent(item) && item.children) return [...acc, ...findItem(item.children)];
      if (isMenuItem(item) && item.path === path) return [...acc, item.key as string];

      return acc;
    }, []);

  return findItem(items);
};
