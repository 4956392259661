import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { BADGE_CLASS } from '../../utils/constants';
import * as Styled from './Badge.styles';
import { IBadgeProps } from './Badge.types';
import { Sizes, Sentiments, Variants } from '../../types/types';

const Badge = ({
  className = '',
  size = Sizes.Medium,
  sentiment = Sentiments.Neutral,
  variant = Variants.Filled,
  icon,
  round,
  children,
  ...rest
}: IBadgeProps): ReactElement => (
  <Styled.Badge
    className={classNames(BADGE_CLASS, className)}
    size={size}
    variant={variant}
    sentiment={sentiment}
    $round={round || (!!icon && !children)}
    {...rest}
  >
    {icon}
    {children && size !== Sizes.Micro && <Styled.BadgeText size={size}>{children}</Styled.BadgeText>}
  </Styled.Badge>
);

export default Badge;
