import styled, { css } from 'styled-components';

import { isTouchDevice } from '../../utils/devices';
import { ICheckboxProps } from './Checkbox.types';
import { Transient } from '../../types/types';
import { checkboxConsts } from './Checkbox.consts';
import Label from '../Text/components/Label/Label';

const FakeCheckbox = styled.div<
  Required<Pick<ICheckboxProps, 'size'>> &
    Transient<ICheckboxProps, 'checked' | 'disabled' | 'indeterminate' | 'invalid' | 'emphasis'>
>`
  ${({ size, $checked, $disabled, $indeterminate, $invalid, $emphasis, theme }) => css`
    box-sizing: border-box;
    position: relative;
    padding: 0;
    border: ${$checked && $disabled ? 'none' : `2px solid ${theme.border.medium}`};
    border-radius: 2px;
    transition: all 150ms;
    pointer-events: ${$disabled ? 'none' : ''};
    background: ${theme.background.surface};

    ${$invalid &&
    css`
      border-color: ${theme.system.strong.negative};
    `}

    ${!$emphasis &&
    css`
      border-color: ${theme.text.onPrimary.strong};
    `}

    ${size &&
    css`
      width: ${checkboxConsts[size].width}px;
      height: ${checkboxConsts[size].height}px;
    `}

    svg {
      ${size &&
      css`
        width: ${checkboxConsts[size].width}px;
        height: ${checkboxConsts[size].height}px;
      `}

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${$checked &&
      css`
        fill: ${!$indeterminate ? theme.text.onFocus.strong : theme.text.onSurface.strong};
      `}

      ${!$checked &&
      css`
        fill: ${!$indeterminate ? theme.background.surface : theme.text.onSurface.strong};
      `}
    }
  `}
`;

const Checkbox = styled.input<Transient<ICheckboxProps, 'indeterminate' | 'invalid' | 'emphasis'>>`
  ${({ $indeterminate, $invalid, $emphasis, theme }) => css`
    position: absolute;
    opacity: 0;
    width: 1px;

    &:focus ~ ${FakeCheckbox} {
      box-shadow: 0 0 0 1px ${theme.background.surface}, 0 0 0 3px ${theme.focus.medium};
    }

    &:checked ~ ${FakeCheckbox} {
      background-color: ${!$indeterminate ? theme.focus.strong : theme.background.surface};
      border-color: ${theme.focus.strong};

      ${!$emphasis &&
      css`
        background-color: ${theme.text.onSurface.strong};
        border-color: ${theme.text.onSurface.strong};
      `}

      ${$invalid &&
      css`
        background-color: ${theme.system.strong.negative};
        border-color: ${theme.system.strong.negative};
      `}
    }

    &:disabled ~ ${FakeCheckbox} {
      background-color: ${theme.background.inactive};
      border-color: ${theme.border.subtle};

      svg {
        fill: transparent;
      }
    }

    &:active:not(:disabled) ~ ${FakeCheckbox} {
      border-color: ${$emphasis ? theme.focus.strong : theme.text.onSurface.strong};

      svg {
        margin: auto;
        fill: ${theme.border.medium};
      }
    }

    &:disabled:checked ~ ${FakeCheckbox} {
      background-color: ${theme.focus.medium};
      border-color: ${theme.focus.medium};

      svg {
        fill: ${theme.background.surface};
      }
    }
  `}
`;

const CheckboxLabel = styled(Label)``;

const CheckboxWrapper = styled.label<
  Required<Pick<ICheckboxProps, 'size'>> &
    Transient<
      Pick<ICheckboxProps, 'checked' | 'disabled' | 'emphasis' | 'mirrored'>,
      'checked' | 'disabled' | 'emphasis' | 'mirrored'
    >
>`
  ${({ size, $checked, $disabled, $emphasis, $mirrored, theme }) => css`
    width: fit-content;
    display: flex;
    flex-direction: ${$mirrored ? 'row-reverse' : 'row'};
    align-items: center;
    cursor: ${$disabled ? 'default' : 'pointer'};

    ${CheckboxLabel} {
      color: ${$disabled ? theme.text.onSurface.inactive : theme.text.onSurface.strong};

      ${$mirrored &&
      css`
        margin-left: unset;
        margin-right: ${checkboxConsts[size].labelSpacing}px;
      `};

      ${!$mirrored &&
      css`
        margin-left: ${checkboxConsts[size].labelSpacing}px;
        margin-right: unset;
      `};
    }

    &:hover {
      svg {
        ${!isTouchDevice() &&
        css`
          fill: ${$checked && !$disabled ? theme.text.onFocus.strong : theme.text.onSurface.subtle};

          ${!$emphasis &&
          css`
            fill: ${theme.text.onSurface.subtle};
          `}
        `}

        ${$checked &&
        !$disabled &&
        css`
          opacity: 50%;
        `};
      }
    }
  `}
`;

export { CheckboxWrapper, Checkbox, FakeCheckbox, CheckboxLabel };
