import { emptySplitApi } from './emptySplitApi';
import { resolveParamsObject } from 'common/utils/url/search-params';
export var capacityApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getIncrementalCapacities: builder.query({
            providesTags: ['IncrementalCapacities'],
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 20 : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _a.assetUnitIds, assetUnitIds = _e === void 0 ? [] : _e, _f = _a.companyIds, companyIds = _f === void 0 ? [] : _f, _g = _a.countryIds, countryIds = _g === void 0 ? [] : _g, _h = _a.regionIds, regionIds = _h === void 0 ? [] : _h, _j = _a.unitSubTypeIds, unitSubTypeIds = _j === void 0 ? [] : _j, _k = _a.unitTypeIds, unitTypeIds = _k === void 0 ? [] : _k, startDate = _a.startDate, endDate = _a.endDate, _l = _a.orderBy, orderBy = _l === void 0 ? '' : _l, _m = _a.probabilityType, probabilityType = _m === void 0 ? [] : _m;
                return ({
                    url: '/v2/capacity/IncrementalCapacities',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        unit: assetUnitIds,
                        pt: probabilityType,
                        pageNumber: [page],
                        pageSize: [pageSize],
                        startDate: [startDate],
                        endDate: [endDate],
                        orderBy: [orderBy],
                    }),
                });
            },
        }),
        updateAssetUnitCapacities: builder.mutation({
            invalidatesTags: function (_, error) { return (error ? [] : ['IncrementalCapacities', 'CapacityVersions']); },
            query: function (body) { return ({
                url: Array.isArray(body.id) ? "/AssetUnitCapacities" : "/AssetUnitCapacities/".concat(body.id),
                method: 'put',
                body: body,
            }); },
        }),
        addAssetUnitCapacities: builder.mutation({
            invalidatesTags: function (_, error) { return (error ? [] : ['IncrementalCapacities', 'CapacityVersions']); },
            query: function (body) { return ({
                url: "/AssetUnitCapacities",
                method: 'post',
                body: body,
            }); },
        }),
        bulkClosure: builder.mutation({
            invalidatesTags: function (_, error) { return (error ? [] : ['IncrementalCapacities', 'CapacityVersions']); },
            query: function (body) { return ({
                url: "/AssetUnitCapacities/BulkClosure",
                method: 'post',
                body: body,
            }); },
        }),
        getCapacityVersions: builder.query({
            providesTags: ['CapacityVersions'],
            query: function (eventId) { return ({
                url: "/AssetUnitCapacities/".concat(eventId, "/versions"),
            }); },
        }),
        getCapacityOriginals: builder.query({
            query: function (assetUnitCapacityId) { return ({
                url: "/v2/capacity/originals",
                params: resolveParamsObject({ assetUnitCapacityId: [assetUnitCapacityId] }),
            }); },
        }),
        getRelatedEvents: builder.query({
            query: function (_a) {
                var assetKey = _a.assetKey, unitSubtypeKey = _a.unitSubtypeKey;
                return ({
                    url: "/v2/capacity/relatedevents",
                    params: resolveParamsObject({ assetKey: [assetKey], unitSubtypeKey: [unitSubtypeKey] }),
                });
            },
        }),
        getLastCapacityUpdates: builder.query({
            query: function () { return '/ApplicationInfo/LastCapacityUpdates'; },
        }),
    }); },
});
export var useGetIncrementalCapacitiesQuery = capacityApis.useGetIncrementalCapacitiesQuery, useLazyGetIncrementalCapacitiesQuery = capacityApis.useLazyGetIncrementalCapacitiesQuery, useUpdateAssetUnitCapacitiesMutation = capacityApis.useUpdateAssetUnitCapacitiesMutation, useAddAssetUnitCapacitiesMutation = capacityApis.useAddAssetUnitCapacitiesMutation, useBulkClosureMutation = capacityApis.useBulkClosureMutation, useGetCapacityVersionsQuery = capacityApis.useGetCapacityVersionsQuery, useGetCapacityOriginalsQuery = capacityApis.useGetCapacityOriginalsQuery, useGetRelatedEventsQuery = capacityApis.useGetRelatedEventsQuery, useGetLastCapacityUpdatesQuery = capacityApis.useGetLastCapacityUpdatesQuery;
