import { css } from 'styled-components';

import { MENU_CLASS } from '../../../utils/constants';
import { menuConsts } from '../Menu.consts';
import { StyledMenuProps } from '../Menu.types';

export const menuLabelStyle = css<StyledMenuProps>`
  ${({ theme, size, inlineCollapsed }) => {
    const iconSize = `${menuConsts[size].iconSize}px`;
    const gap = `${menuConsts[size].gap}px`;
    const iconContainerWidth = `${menuConsts[size].iconContainer.width}px`;
    const iconContainerHeight = `${menuConsts[size].iconContainer.height}px`;
    const padding = `${menuConsts[size].padding - 4}px`;

    return css`
      .${MENU_CLASS}-label {
        display: flex;
        align-items: flex-start;
        gap: ${gap};
        border-radius: 2px;
        padding: ${padding};
        margin: -${padding};
        transition: background-color 0.3s ease-out;
        flex-grow: 1;
        min-width: 0;
        color: ${theme.text.onSurface.strong};
        text-decoration: none;

        .${MENU_CLASS}-label-text {
          transition: opacity 0.3s ease-out;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .${MENU_CLASS}-label-icon {
          min-width: ${iconContainerWidth};
          max-height: ${iconContainerHeight};
          display: flex;
          align-items: center;
          justify-content: center;

          > svg {
            display: block;
            width: ${iconSize};
            height: ${iconSize};
          }
        }
      }

      /* Horizontal */
      &.${MENU_CLASS}-horizontal .${MENU_CLASS}-label {
        align-items: center;

        .${MENU_CLASS}-label-icon {
          margin-left: -2px;
          min-width: ${iconSize};
        }
      }

      /* Top level */
      &.${MENU_CLASS} {
        > .${MENU_CLASS}-submenu
          > .${MENU_CLASS}-submenu-title
          > .${MENU_CLASS}-submenu-title-content,
          > .${MENU_CLASS}-item {
          > .${MENU_CLASS}-label > .${MENU_CLASS}-label-text {
            white-space: nowrap;
          }
        }
      }

      ${inlineCollapsed &&
      css`
        &.${MENU_CLASS}-inline-collapsed {
          > .${MENU_CLASS}-submenu
            > .${MENU_CLASS}-submenu-title
            > .${MENU_CLASS}-submenu-title-content,
            > .${MENU_CLASS}-item {
            > .${MENU_CLASS}-label > .${MENU_CLASS}-label-text {
              opacity: 0;
              text-overflow: clip;
              white-space: nowrap;
            }
          }
        }
      `}
    `;
  }}
`;
