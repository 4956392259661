import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Tag, Flexbox } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
var MAX_PROVIDERS_ALLOWED = 3;
var StyledTag = styled(Tag)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  border-color: ", ";\n"], ["\n  color: ", ";\n  border-color: ", ";\n"])), function (_a) {
    var theme = _a.theme, isLink = _a.isLink;
    return (isLink ? theme.series[1] : theme.text.onSurface.subtle);
}, function (_a) {
    var theme = _a.theme, isLink = _a.isLink;
    return (isLink ? theme.series[1] : theme.text.onSurface.subtle);
});
export var ProvidersTags = function (_a) {
    var providers = _a.providers;
    var _b = useState(false), showAll = _b[0], setShowAll = _b[1];
    if (providers.length < 4 || showAll) {
        return (_jsx(Flexbox, __assign({ gap: "4px", onClick: function (e) {
                e.stopPropagation();
                setShowAll(false);
            }, style: { cursor: 'pointer' } }, { children: providers.map(function (provider) { return (_jsx(StyledTag, __assign({ isLink: false, size: "small", variant: "outlined" }, { children: provider }), provider)); }) })));
    }
    var firstThreeProviders = [];
    for (var i = 0; i < MAX_PROVIDERS_ALLOWED; i++) {
        firstThreeProviders.push(_jsx(StyledTag, __assign({ isLink: false, size: "small", variant: "outlined" }, { children: providers[i] }), providers[i]));
    }
    var remainingProvidersCount = providers.length - MAX_PROVIDERS_ALLOWED;
    firstThreeProviders.push(_jsxs(StyledTag, __assign({ isLink: true, variant: "outlined", size: "small" }, { children: ["+", remainingProvidersCount] }), "count"));
    return (_jsx(Flexbox, __assign({ gap: "4px", onClick: function (e) {
            e.stopPropagation();
            setShowAll(true);
        }, style: { cursor: 'pointer' } }, { children: firstThreeProviders })));
};
var templateObject_1;
