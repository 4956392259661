import { ButtonSize } from '../Button/Button.types';
import { DatePickerSize } from './DatePicker.types';
import { Sizes } from '../../types/types';

type DatePickerConstsType = {
  [size in DatePickerSize]: {
    height: number;
    boxSize: number;
    cellWidth: number;
    panelGap: number;
    panelPadding: number;
    clearSize: ButtonSize;
  };
};

export const datePickerConsts: DatePickerConstsType = {
  [Sizes.Small]: {
    height: 32,
    boxSize: 28,
    cellWidth: 48,
    panelGap: 12,
    panelPadding: 16,
    clearSize: Sizes.Small,
  },
  [Sizes.Medium]: {
    height: 40,
    boxSize: 36,
    cellWidth: 64,
    panelGap: 16,
    panelPadding: 20,
    clearSize: Sizes.Medium,
  },
  [Sizes.Large]: {
    height: 48,
    boxSize: 44,
    cellWidth: 80,
    panelGap: 24,
    panelPadding: 24,
    clearSize: Sizes.Medium,
  },
};

export const arrowWidth = 14;
export const arrowHeight = 8;
export const arrowSize = '10px';
