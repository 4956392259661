import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import { Flexbox, FormField, Heading, Icons, TextInput } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { StyledSingleDatePicker } from './AssetDetailsSection';
import { FormFieldInfoLink } from './FormFieldInfo';
import { Select } from 'components/select';
import { useGetAllCompaniesQuery } from 'common/ducks/filtersApis';
import { newMapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
import { resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
import { Loader } from 'components/loader';
var Search = Icons.Search;
export var StyledTextInput = styled(TextInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n"], ["\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n"])));
export var OwnershipDetailsSection = function (_a) {
    var form = _a.form, open = _a.open, isLoading = _a.isLoading;
    var _b = useState(false), fakeLoader = _b[0], setFakeLoader = _b[1];
    var watch = form.watch, setValue = form.setValue, register = form.register, control = form.control, errors = form.formState.errors;
    var _c = useFieldArray({
        control: control,
        name: 'ownershipDetails',
        rules: {
            validate: function (_, formValues) {
                var _a;
                var sum = 0;
                (_a = formValues.ownershipDetails) === null || _a === void 0 ? void 0 : _a.forEach(function (value) { var _a; return (sum += Number((_a = value.equityShare) !== null && _a !== void 0 ? _a : 0)); });
                if (sum > 100) {
                    return 'Sum of all equity share % should not exceed 100';
                }
                return true;
            },
        },
    }), fields = _c.fields, append = _c.append, replace = _c.replace;
    var _d = useGetAllCompaniesQuery(undefined, { skip: !open }), _e = _d.data, companies = _e === void 0 ? [] : _e, isFetchingCompanies = _d.isFetching;
    var companiesOptions = companies.map(newMapSelectData('companyNm', 'id')).sort(sortSelectData);
    var appendOwnership = useCallback(function () {
        setFakeLoader(true);
        append({});
        setTimeout(function () {
            setFakeLoader(false);
            var activeElement = document.activeElement;
            if (activeElement) {
                activeElement.blur();
            }
        }, 500);
    }, [append]);
    var handleSelect = function (index) {
        if (index === fields.length - 1 &&
            watch("ownershipDetails.".concat(fields.length - 1, ".company")) &&
            Number(watch("ownershipDetails.".concat(fields.length - 1, ".equityShare"))) !== 100) {
            appendOwnership();
        }
    };
    var handleEquityChange = function (e, index) {
        if (isNaN(Number(e.target.value))) {
            setValue("ownershipDetails.".concat(index, ".equityShare"), undefined);
            return;
        }
        var value = Number(e.target.value);
        if (value === 100 && index === 0) {
            setValue("ownershipDetails.".concat(index, ".equityShare"), e.target.value);
            if (fields.length === 1) {
                return;
            }
            var newFields = [fields[0]];
            replace(newFields);
            setFakeLoader(true);
            setTimeout(function () {
                setFakeLoader(false);
            }, 500);
        }
        else if (value < 100 && watch("ownershipDetails.".concat(index, ".company")) && fields.length === 1) {
            appendOwnership();
            setTimeout(function () { return e.target.focus(); }, 500);
        }
        setValue("ownershipDetails.".concat(index, ".equityShare"), e.target.value);
    };
    return (_jsxs("section", { children: [_jsx(Heading, __assign({ bold: true, color: "light", level: 5 }, { children: "OWNERSHIP DETAILS" })), isLoading ? (_jsx(Loader, { message: "" })) : (fields.map(function (field, index) {
                var _a, _b, _c, _d, _e;
                return (_jsxs("div", { children: [_jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: index === 0 ? 'Company' : "Company ".concat(index + 1), size: "small", topHelper: index === fields.length - 1 && (_jsx(FormFieldInfoLink, { href: process.env.REACT_APP_TED_URL, text: "+ Add a company to Ted" })) }, { children: _jsx(Select, __assign({}, register("ownershipDetails.".concat(index, ".company")), { data: companiesOptions, loading: isFetchingCompanies, size: "small", value: watch("ownershipDetails.".concat(index, ".company")), onChange: resolveOnSelectChange('ownershipDetails', form, [], 'company', index), onSelect: function () { return handleSelect(index); }, placeholder: "Search", suffixIcon: !isFetchingCompanies && _jsx(Search, {}) }), field.id) })), _jsx(FormField, __assign({ label: "Equity share %", size: "small", bottomLeftHelper: {
                                        content: dayjs(field.equityEndDate).isSameOrAfter(dayjs()) &&
                                            (((_c = (_b = (_a = errors.ownershipDetails) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.equityShare) === null || _c === void 0 ? void 0 : _c.message) ||
                                                ((_e = (_d = errors.ownershipDetails) === null || _d === void 0 ? void 0 : _d.root) === null || _e === void 0 ? void 0 : _e.message)),
                                        sentiment: 'negative',
                                    } }, { children: _jsx(StyledTextInput, __assign({}, register("ownershipDetails.".concat(index, ".equityShare"), {
                                        validate: function (value) {
                                            if (value && +value <= 0) {
                                                return 'Equity share % should be greater than 0';
                                            }
                                            if (value && +value > 100) {
                                                return 'Equity share % should not exceed 100';
                                            }
                                        },
                                    }), { value: watch("ownershipDetails.".concat(index, ".equityShare")), size: "small", onChange: function (e) { return handleEquityChange(e, index); }, type: "number" })) }))] })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "Start date", size: "small" }, { children: _jsx(StyledSingleDatePicker, __assign({}, register("ownershipDetails.".concat(index, ".equityStartDate")), { value: watch("ownershipDetails.".concat(index, ".equityStartDate"))
                                            ? dayjs(watch("ownershipDetails.".concat(index, ".equityStartDate")))
                                            : null, placeholder: "Select", size: "small", elevation: true, format: "DD MMM YYYY", onChange: function (date) {
                                            return date
                                                ? setValue("ownershipDetails.".concat(index, ".equityStartDate"), date.format())
                                                : setValue("ownershipDetails.".concat(index, ".equityStartDate"), undefined);
                                        } })) })), _jsx(FormField, __assign({ label: "End date", size: "small" }, { children: _jsx(StyledSingleDatePicker, __assign({}, register("ownershipDetails.".concat(index, ".equityEndDate")), { value: watch("ownershipDetails.".concat(index, ".equityEndDate"))
                                            ? dayjs(watch("ownershipDetails.".concat(index, ".equityEndDate")))
                                            : null, showToday: false, placeholder: "Select", size: "small", elevation: true, format: "DD MMM YYYY", onChange: function (date) {
                                            return date
                                                ? setValue("ownershipDetails.".concat(index, ".equityEndDate"), date.format())
                                                : setValue("ownershipDetails.".concat(index, ".equityEndDate"), undefined);
                                        }, disabledDate: function (value) {
                                            if (watch("ownershipDetails.".concat(index, ".equityStartDate"))) {
                                                return value.isSameOrBefore(watch("ownershipDetails.".concat(index, ".equityStartDate")));
                                            }
                                            return false;
                                        } })) }))] }))] }, index));
            })), fakeLoader && _jsx(Loader, { message: "" })] }));
};
var templateObject_1;
