import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flexbox, FormField, TextInput, Text, SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { StyledFormField } from '../../pages/maintenance-event-summary/add-edit-maintenance-event-drawer/AddEditMaintenanceEventDrawer.styles';
import { Select } from 'components/select/Select';
import { resolveOnSelectChange, SELECT_ITEM_PLACEHOLDER } from 'common/utils/url/addEditEvent.utils';
var UNWANTED_BASIS_TYPES_IDS = [3, 5];
export var AddEditCapacityConditionalFields = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var lengthOfEvents = _a.lengthOfEvents, form = _a.form, units = _a.units, assets = _a.assets, basisTypes = _a.basisTypes, isFetchingBasisTypes = _a.isFetchingBasisTypes, isFetchingAssets = _a.isFetchingAssets, isFetchingUnits = _a.isFetchingUnits, uomName = _a.uomName;
    var watch = form.watch, register = form.register, errors = form.formState.errors, setValue = form.setValue;
    var filteredBasisTypes;
    if (lengthOfEvents === 1 && UNWANTED_BASIS_TYPES_IDS.includes(watch('basisType'))) {
        filteredBasisTypes = basisTypes.map(function (basisType) { return (__assign(__assign({}, basisType), { disabled: UNWANTED_BASIS_TYPES_IDS.includes(basisType.value) })); });
    }
    else {
        filteredBasisTypes = basisTypes.filter(function (basisType) { return !UNWANTED_BASIS_TYPES_IDS.includes(basisType.value); });
    }
    return (_jsxs(_Fragment, { children: [lengthOfEvents > 1 && (_jsx(FormField, __assign({ size: "small", label: "Offset start date by (+/-)", bottomLeftHelper: { content: (_b = errors.offsetStartDateBy) === null || _b === void 0 ? void 0 : _b.message, sentiment: 'negative' } }, { children: _jsxs(Flexbox, __assign({ alignItems: "center", gap: "3%" }, { children: [_jsx(TextInput, __assign({ size: "small" }, register('offsetStartDateBy', {
                            required: 'Please enter the Offset Value',
                            validate: function (value) { return Number.isInteger(Number(value)) || 'Offset Value must be an integer'; },
                        }), { value: watch('offsetStartDateBy') })), _jsx(Text, __assign({ size: "small" }, { children: "Months" }))] })) }))), !lengthOfEvents && (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({ size: "small", label: "Asset", bottomLeftHelper: { content: (_c = errors.asset) === null || _c === void 0 ? void 0 : _c.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('asset', {
                            required: 'Please select an Asset',
                        }), { onChange: resolveOnSelectChange('asset', form, units), value: watch('asset'), size: "small", allowClear: false, data: assets, loading: isFetchingAssets, placeholder: SELECT_ITEM_PLACEHOLDER })) })), _jsx(StyledFormField, __assign({ size: "small", label: "Unit", bottomLeftHelper: { content: (_d = errors.units) === null || _d === void 0 ? void 0 : _d.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('units', {
                            required: 'Please select at least one Unit',
                        }), { onChange: resolveOnSelectChange('units', form, units), value: watch('units'), size: "small", allowClear: false, data: units, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingUnits, mode: "multiple" })) }))] })), lengthOfEvents < 2 && (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({ size: "small", label: "Basis type", bottomLeftHelper: { content: (_e = errors.basisType) === null || _e === void 0 ? void 0 : _e.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('basisType', {
                            required: 'Please select a Basis Type',
                        }), { onChange: resolveOnSelectChange('basisType', form, []), value: watch('basisType'), disabled: ((_f = watch('units')) === null || _f === void 0 ? void 0 : _f.length) > 1, size: "small", allowClear: false, data: filteredBasisTypes, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingBasisTypes })) })), _jsxs(Flexbox, __assign({ flexDirection: "row", gap: "3%" }, { children: [_jsx(FormField, __assign({ size: "small", label: "Start Date" }, { children: _jsx(SingleDatePicker, { elevation: true, size: "small", value: dayjs(watch('startDate')), format: "YYYY-MM-DD", allowClear: false, onChange: function (date) { var _a; return setValue('startDate', (_a = date === null || date === void 0 ? void 0 : date.toISOString()) !== null && _a !== void 0 ? _a : dayjs().toISOString()); } }) })), (watch('basisType') !== 4 || !!lengthOfEvents) && (_jsx(FormField, __assign({ size: "small", label: "Incremental Capacity", bottomLeftHelper: { content: (_g = errors.incrementalCapacity) === null || _g === void 0 ? void 0 : _g.message, sentiment: 'negative' } }, { children: _jsx(TextInput, __assign({}, register('incrementalCapacity', {
                                    required: 'Please enter the Incremental Capacity value',
                                    validate: function (value) { return !isNaN(Number(value)) || 'Capacity value must be a number'; },
                                }), { value: watch('incrementalCapacity'), size: "small", placeholder: "Incremental Capacity", disabled: ((_h = watch('units')) === null || _h === void 0 ? void 0 : _h.length) > 1, suffix: { node: uomName, transparent: !uomName } })) })))] }))] }))] }));
};
