import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Alert, Button, Flexbox, Icons, Sentiments } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { selectIsSidebarOpen, setSidebarOpenState } from './ducks/slice';
import { MaintenanceEventTable } from './MaintenanceEventTable';
import { ExportData } from 'components/export-data';
import { InfoPanel } from 'components/info-panel';
import { SidebarTemplate } from 'components/sidebar-template';
import { useSidebar } from 'components/sidebar-template/hooks';
import { AddEditMaintenanceEventDrawer, } from 'pages/maintenance-event-summary/add-edit-maintenance-event-drawer';
import { MaintenanceFilter } from 'components/maintenance-filter/MaintenanceFilter';
import { useGetNewMaintenanceEventSummaryQuery } from 'common/ducks/maintenanceApis';
import { ROUTE_PATHS } from 'app/routes';
import { resolveParamsObject } from 'common/utils/url/search-params';
import { SelectedFiltersTags } from 'components/selected-filters-tags';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { parse } from 'common/utils/newScreenUtils';
import { PagesHeader } from 'components/pages-header/PagesHeader';
import { Loader } from 'components/loader';
var Add = Icons.Add, Gear = Icons.Gear;
export var isValidTimeframe = function (timeframeParam) {
    return timeframeParam[0] && timeframeParam[1] && dayjs(timeframeParam[0]).isValid() && dayjs(timeframeParam[1]).isValid();
};
export var MaintenanceEventSummary = function (_a) {
    var _b, _c;
    var _d = _a.title, title = _d === void 0 ? '' : _d;
    document.title = title;
    var _e = useSidebar(selectIsSidebarOpen, setSidebarOpenState), handleSidebarClose = _e.handleSidebarClose, handleSidebarOpen = _e.handleSidebarOpen, isSidebarOpen = _e.isSidebarOpen;
    var _f = useState(false), isAddEditDrawerOpen = _f[0], setIsAddEditDrawerOpen = _f[1];
    var _g = useState(), editingEvent = _g[0], setEditingEvent = _g[1];
    var _h = useState(false), isConfigureModalOpen = _h[0], setIsConfigureModalOpen = _h[1];
    var _j = useState([]), eventsForBulkEdit = _j[0], setEventsForBulkEdit = _j[1];
    var history = useHistory();
    var location = useLocation();
    var initialValues = useMemo(function () {
        var search = location.search;
        var params = new URLSearchParams(search);
        var today = dayjs().format(DATE_FORMAT_YYYY_MM_DD);
        var versionParam = params.get('version');
        var timeframeParams = params.getAll('timeframe');
        return {
            assets: params.getAll('assets').map(parse),
            regions: params.getAll('regions').map(parse),
            location: params.getAll('location').map(parse),
            owners: params.getAll('owners').map(parse),
            units: params.getAll('units').map(parse),
            unitSubTypes: params.getAll('unitSubTypes').map(parse),
            unitTypes: params.getAll('unitTypes').map(parse),
            version: dayjs(versionParam).isValid() ? dayjs(versionParam).format(DATE_FORMAT_YYYY_MM_DD) : today,
            timeframe: isValidTimeframe(timeframeParams)
                ? [timeframeParams[0], timeframeParams[1]]
                : [
                    dayjs().startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
                    dayjs().add(3, 'month').endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
                ],
            currentPage: params.getAll('currentPage').length === 0 ? [1] : params.getAll('currentPage').map(parse),
        };
    }, [location]);
    var queryParams = useMemo(function () {
        var _a;
        return ({
            asset_unit_keys: initialValues.units,
            asset_unit_subtype_keys: initialValues.unitSubTypes,
            asset_unit_type_keys: initialValues.unitTypes,
            region_keys: initialValues.regions,
            country_keys: initialValues.location,
            owner_keys: initialValues.owners,
            asset_keys: initialValues.assets,
            event_start_dt: initialValues.timeframe[0],
            event_end_dt: initialValues.timeframe[1],
            page_number: (_a = initialValues.currentPage) === null || _a === void 0 ? void 0 : _a[0],
        });
    }, [initialValues]);
    var _k = useGetNewMaintenanceEventSummaryQuery(queryParams), data = _k.data, isFetching = _k.isFetching, isError = _k.isError;
    var handlePaginationChange = function (page) {
        var search = location.search;
        var updatedParams = new URLSearchParams(search);
        updatedParams.set('currentPage', page.toString());
        history.push({
            pathname: ROUTE_PATHS.MAINTENANCE_EVENT_SUMMARY,
            search: updatedParams.toString(),
        });
    };
    var handleFilterSubmit = function (values) {
        var convertedValues = __assign(__assign({}, values), { version: [values.version] });
        history.push({
            pathname: ROUTE_PATHS.MAINTENANCE_EVENT_SUMMARY,
            search: new URLSearchParams(resolveParamsObject(convertedValues)).toString(),
        });
    };
    var handleAddEditDrawerSave = useCallback(function (params) {
        console.log('saved', params);
        setEditingEvent(undefined);
        setIsAddEditDrawerOpen(false);
    }, []);
    var handleAddEditDrawerClose = function () {
        setEditingEvent(undefined);
        setIsAddEditDrawerOpen(false);
    };
    var handleRowClick = useCallback(function (event, bulkEvents) {
        if (bulkEvents === void 0) { bulkEvents = []; }
        if (!editingEvent || editingEvent.asset_unit_event_key !== event.asset_unit_event_key) {
            setEditingEvent(event);
            setIsAddEditDrawerOpen(true);
        }
        else {
            setEditingEvent(undefined);
        }
        setEventsForBulkEdit(bulkEvents);
    }, [editingEvent]);
    return (_jsxs(SidebarTemplate, __assign({ sidebarOpen: isSidebarOpen, onSidebarClose: handleSidebarClose, onSidebarOpen: handleSidebarOpen, sidebar: _jsx(MaintenanceFilter, __assign({}, initialValues, { onSubmit: handleFilterSubmit, isEventSummary: true, sidebarOpen: isSidebarOpen })), sidebarTitle: "Filters" }, { children: [_jsxs(PagesHeader, __assign({ title: "Maintenance events", dockStartItemsLength: 1 }, { children: [_jsx(InfoPanel, { type: "maintenance" }), _jsx(ExportData, { requestParams: {
                            assetIds: initialValues.assets,
                            assetUnitIds: initialValues.units,
                            unitSubTypeIds: initialValues.unitSubTypes,
                            unitTypeIds: initialValues.unitTypes,
                            regionIds: initialValues.regions,
                            countryIds: initialValues.location,
                            companyIds: initialValues.owners,
                            startDate: initialValues.timeframe[0],
                            endDate: initialValues.timeframe[1],
                        }, type: "maintenance", propertyName: "maintenanceEventDataList", refetchingData: isFetching, totalRows: data === null || data === void 0 ? void 0 : data.total_rows }), _jsx(Button, __assign({ size: "small", variant: "outlined", icon: _jsx(Gear, {}), disabled: isFetching || isError, onClick: function () { return setIsConfigureModalOpen(true); } }, { children: "Configure columns" })), _jsx(Button, __assign({ size: "small", onClick: function () { return setIsAddEditDrawerOpen(true); }, icon: _jsx(Add, { height: 100 }) }, { children: "Maintenance event" }))] })), _jsx(SelectedFiltersTags, { regionIds: initialValues.regions, companyIds: initialValues.owners, countryIds: initialValues.location, assetIds: initialValues.assets, assetUnitIds: initialValues.units, unitSubTypeIds: initialValues.unitSubTypes, unitTypeIds: initialValues.unitTypes }), _jsx(AddEditMaintenanceEventDrawer, { event: editingEvent, bulkEvents: eventsForBulkEdit, open: isAddEditDrawerOpen, onClose: handleAddEditDrawerClose, onSave: handleAddEditDrawerSave }), isFetching && _jsx(Loader, { message: "Fetching maintenance events..." }), isError && (_jsx(Flexbox, __assign({ justifyContent: "center", style: { height: '75%' }, alignItems: "center" }, { children: _jsx(Alert, __assign({ style: { maxWidth: '350px' }, sentiment: Sentiments.Negative }, { children: "An error occurred. Please try again later." })) }))), !isFetching && !isError && !!data && (_jsx(MaintenanceEventTable, { events: data.data, isConfigureModalOpen: isConfigureModalOpen, closeConfigureModal: function () { return setIsConfigureModalOpen(false); }, onRowClick: handleRowClick, onPaginationChange: handlePaginationChange, currentPage: (_c = (_b = initialValues.currentPage) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : 1, totalRows: data.total_rows }))] })));
};
