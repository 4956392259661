import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from 'react';
import FilterBtn from '../capacityscreen/tools/filterBtn';
import Badge from './tools/badge';
import Filter from './tools/filter';
import onClickOutside from 'react-onclickoutside';
import ErrorBoundary from '../../../../../services/errorBoundary';
import { getSourceFilter } from '../../../../../services/urlService';
import { ApiContext } from './apiContext';
var FilterContainer = function (props) {
    //Background API calls for required data, for smoother experience when editing
    useEffect(function () {
        getSourceFilter('/OperationalStatusTypes').then(function (response) {
            if (response && response.data) {
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { operationalStatusTypes: response.data });
                });
            }
        });
        getSourceFilter('/PlanningProbabilityTypes').then(function (response) {
            if (response && response.data) {
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { probabilityTypes: response.data });
                });
            }
        });
        getSourceFilter('/DataProviders').then(function (response) {
            if (response && response.data) {
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { dataProviders: response.data });
                });
            }
        });
        getSourceFilter('/IntelligenceSourceTypes').then(function (response) {
            if (response && response.data) {
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { intelligenceSourceTypes: response.data });
                });
            }
        });
        getSourceFilter('/PetchemFeedstock').then(function (response) {
            if (response && response.data) {
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { feedstocks: response.data });
                });
            }
        });
    }, []);
    useEffect(function () {
        if (props.shouldGridRefresh && apiParams.assetUnitListingData.length > 0)
            //Refresh data after edit or delete but only when there is data to refresh
            applyOnClick();
    }, [props.shouldGridRefresh]);
    var _a = useState(false), filterBtnIsOn = _a[0], setFilterBtnIsOn = _a[1];
    var _b = useContext(ApiContext), apiParams = _b[0], setApiParams = _b[1];
    var _c = useState(-1), selectedBadge = _c[0], setSelectedBadge = _c[1];
    var filterBtnToggle = function () {
        setFilterBtnIsOn(!filterBtnIsOn);
        var filterDiv = document.getElementById('filterPanel');
        if (filterDiv.style.zIndex === '-1') {
            filterDiv.style.zIndex = '9999';
            document.getElementById('Countries-search-box').focus();
        }
        else {
            filterDiv.style.zIndex = '-1';
        }
    };
    var badgeNameArray = [
        { text: 'Countries', code: 'countries' },
        { text: 'Owners', code: 'owners' },
        { text: 'Assets', code: 'assets' },
        { text: 'Unit Type', code: 'unitTypes' },
        { text: 'Unit Subtype', code: 'unitSubTypes' },
    ];
    var applyOnClick = function (e) {
        setApiParams(function (prev) {
            return __assign(__assign({}, prev), { assetUnitListingData: [] });
        });
        document.activeElement.blur(); //remove focus from apply button after clicking
        props.setLoader(true);
        FilterContainer.handleClickOutside();
        var path = '/AssetUnits?' +
            apiParams.countries
                .map(function (country) {
                return 'co=' + country.id + '&';
            })
                .join('') +
            apiParams.owners
                .map(function (owner) {
                return 'o=' + owner.id + '&';
            })
                .join('') +
            apiParams.assets
                .map(function (asset) {
                return 'a=' + asset.id + '&';
            })
                .join('') +
            apiParams.unitTypes
                .map(function (unitType) {
                return 'ut=' + unitType.id + '&';
            })
                .join('') +
            apiParams.unitSubTypes
                .map(function (unitSubTypes) {
                return 'ust=' + unitSubTypes.id + '&';
            })
                .join('') +
            'displayOriginalRecords=true';
        getSourceFilter(path)
            .then(function (response) {
            if (response && response.data) {
                var newApiParams = __assign({}, apiParams);
                newApiParams.assetUnitListingData = response.data;
                setApiParams(newApiParams);
            }
            props.setLoader(false);
        })
            .catch(function (err) {
            props.setLoader(false);
        });
    };
    var resetHandler = function (e) {
        document.activeElement.blur();
        setSelectedBadge(-1);
        var defaultApiParams = __assign({}, apiParams);
        defaultApiParams.countries = [];
        defaultApiParams.owners = [];
        defaultApiParams.assets = [];
        defaultApiParams.unitTypes = [];
        defaultApiParams.unitSubTypes = [];
        defaultApiParams.assetUnitListingData = [];
        setApiParams(defaultApiParams);
    };
    FilterContainer.handleClickOutside = function () {
        var filterDiv = document.getElementById('filterPanel');
        if (filterDiv.style.zIndex === '9999') {
            filterDiv.style.zIndex = '-1';
        }
        setFilterBtnIsOn(false);
        setSelectedBadge(-1);
    };
    var onBadgeClick = function (e) {
        var filterDiv = document.getElementById('filterPanel');
        filterDiv.style.zIndex = '9999';
        setSelectedBadge(e.target.id);
        switch (e.target.id) {
            case '0':
                document.getElementById('Countries-search-box').focus();
                break;
            case '1':
                document.getElementById('Companies-search-box').focus();
                break;
            case '2':
                document.getElementById('Assets/AssetFilter-search-box').focus();
                break;
            case '3':
                document.getElementById('AssetUnitTypes-search-box').focus();
                break;
            case '4':
                document.getElementById('AssetUnitSubTypes-search-box').focus();
                break;
        }
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "container-fluid background-filter-fill pl-0 pr-0" }, { children: _jsxs("div", __assign({ className: "m-0 position-relative" }, { children: [_jsxs("div", __assign({ className: "row no-gutters justify-content-between pt-3 pb-3 pr-3 position-relative" }, { children: [_jsxs("div", __assign({ className: "col-sm-10 p-0 float-left" }, { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsx(FilterBtn, { isOn: filterBtnIsOn, handleToggle: filterBtnToggle }) })), _jsx("div", __assign({ className: "region" }, { children: badgeNameArray.map(function (badge, index) {
                                            return (_jsx(Badge, { list: badge.text + '-' + index, name: badge.text, id: index, code: badge.code, onBadgeClick: onBadgeClick, selectedBadge: selectedBadge }, index));
                                        }) }))] })), _jsx("div", __assign({ className: "col-sm-2 pr-3" }, { children: _jsxs("div", __assign({ className: "row justify-content-end" }, { children: [_jsx("div", __assign({ className: "mr-3" }, { children: _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear tab-focus apply", onClick: applyOnClick, tabIndex: 6 }, { children: ["Apply ", _jsx("i", { className: "fa fa-paint-brush" })] })) })) })), _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear tab-focus", onClick: resetHandler, tabIndex: 7 }, { children: ["Reset ", _jsx("i", { className: "fa fa-undo" })] })) }))] })) }))] })), _jsxs("div", __assign({ id: "filterPanel", className: "row capacity-select rounded border px-3 ml-0 mr-0", style: { zIndex: '-1' } }, { children: [_jsxs("div", __assign({ className: "country f-panel mr-4", style: { width: '16%' } }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-2" }, { children: "Country" })), _jsx("div", { children: _jsx(ErrorBoundary, { children: _jsx(Filter, { path: "Countries", displayField: "geopoliticalEntityNm", tabIndex: 1 }) }) })] })), _jsxs("div", __assign({ className: "owners f-panel mr-4", style: { width: '16%' } }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-2" }, { children: "Owner" })), _jsx("div", { children: _jsx(ErrorBoundary, { children: _jsx(Filter, { path: 'Companies?' +
                                                    apiParams.countries
                                                        .map(function (country) {
                                                        return 'co=' + country.id + '&';
                                                    })
                                                        .join(''), displayField: "companyCd", tabIndex: 2 }) }) })] })), _jsxs("div", __assign({ className: "f-panel mr-4", style: { width: '16%' } }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-2" }, { children: "Asset" })), _jsx("div", { children: _jsx(ErrorBoundary, { children: _jsx(Filter, { path: 'Assets/AssetFilter?' +
                                                    apiParams.countries
                                                        .map(function (country) {
                                                        return 'co=' + country.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.owners
                                                        .map(function (owner) {
                                                        return 'o=' + owner.id + '&';
                                                    })
                                                        .join(''), displayField: "assetNm", tabIndex: 3 }) }) })] })), _jsxs("div", __assign({ className: "f-panel mr-4", style: { width: '16%' } }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-2" }, { children: "Unit Type" })), _jsx("div", { children: _jsx(ErrorBoundary, { children: _jsx(Filter, { path: 'AssetUnitTypes?' +
                                                    apiParams.countries
                                                        .map(function (country) {
                                                        return 'co=' + country.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.owners
                                                        .map(function (owner) {
                                                        return 'o=' + owner.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.assets
                                                        .map(function (asset) {
                                                        return 'a=' + asset.id + '&';
                                                    })
                                                        .join(''), displayField: "assetUnitTypeCd", tabIndex: 4 }) }) })] })), _jsxs("div", __assign({ className: "country f-panel", style: { width: '16%' } }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-2" }, { children: "Unit Subtype" })), _jsx("div", { children: _jsx(ErrorBoundary, { children: _jsx(Filter, { path: 'AssetUnitSubTypes?' +
                                                    apiParams.countries
                                                        .map(function (country) {
                                                        return 'co=' + country.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.owners
                                                        .map(function (owner) {
                                                        return 'o=' + owner.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.assets
                                                        .map(function (asset) {
                                                        return 'a=' + asset.id + '&';
                                                    })
                                                        .join('') +
                                                    apiParams.unitTypes
                                                        .map(function (unitType) {
                                                        return 'ut=' + unitType.id + '&';
                                                    })
                                                        .join(''), displayField: "assetUnitSubtypeCd", tabIndex: 5 }) }) })] }))] }))] })) })) }));
};
FilterContainer.prototype = {};
var clickOutsideConfig = {
    handleClickOutside: function () { return FilterContainer.handleClickOutside; },
};
export default onClickOutside(FilterContainer, clickOutsideConfig);
