import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from '@sede-x/shell-ds-react-framework';
var CheckCircle = Icons.CheckCircle, CrossCircle = Icons.CrossCircle, AlertCircle = Icons.AlertCircle;
export var getExportButtonValues = function (downloadSuccessful, downloadInterrupted, fileName) {
    if (downloadSuccessful) {
        return {
            popConfirmTitle: 'Data exported',
            popConfirmDescription: (_jsxs(_Fragment, { children: ["Please check your Downloads folder for ", _jsx("i", { children: fileName })] })),
            sentiment: 'positive',
            icon: _jsx(CheckCircle, {}),
        };
    }
    if (downloadInterrupted) {
        return {
            popConfirmTitle: 'Export failed',
            popConfirmDescription: _jsx(_Fragment, { children: "File download was interrupted, please check your connection and try again" }),
            sentiment: 'negative',
            icon: _jsx(CrossCircle, {}),
        };
    }
    return {
        popConfirmTitle: 'Large data set',
        popConfirmDescription: (_jsxs(_Fragment, { children: ["It may take up to several minutes to prepare and download this data set.", _jsx("br", {}), _jsx("br", {}), "For performance consideration file export will be trimmed to first 50,000 records"] })),
        sentiment: 'warning',
        icon: _jsx(AlertCircle, {}),
    };
};
export var getPopconfirmActions = function (downloadWarning, downloadSuccessful, downloadAction, retryAction) {
    if (downloadWarning) {
        return [
            {
                label: 'Download',
                action: downloadAction,
                props: {
                    sentiment: 'default',
                },
            },
        ];
    }
    if (downloadSuccessful) {
        return [];
    }
    return [
        {
            label: 'Retry',
            action: retryAction,
            props: {
                sentiment: 'default',
            },
        },
    ];
};
