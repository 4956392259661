import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
export default function ConfirmDeleteActionBox(props) {
    var gridContent = document.body;
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    function onConfirmAction() {
        //setIsVisible(!isVisible);
        //document
        //.querySelector('.k-state-selected')
        //.classList.remove('k-state-selected');
        props.confirmDelete();
    }
    return (
    //isVisible &&
    ReactDOM.createPortal(_jsxs(Dialog, __assign({ title: 'Please confirm', onClose: props.cancelDelete }, { children: [_jsx("p", __assign({ style: { margin: '25px', textAlign: 'center' } }, { children: "Are you sure want to delete ?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: props.cancelDelete }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onConfirmAction }, { children: "Yes" }))] })] })), gridContent));
}
