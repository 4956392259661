import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import AssetUnitCrossReference from './assetUnitCrossReference';
import AssetUnitSubTypeCrossReference from './assetUnitSubTypeCrossReference';
import AssetEventTypeCrossReference from './assetEventTypeCrossReference';
import '../../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var assetUnitCrossReferenceBaseNavLink = /** @class */ (function (_super) {
    __extends(assetUnitCrossReferenceBaseNavLink, _super);
    function assetUnitCrossReferenceBaseNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    assetUnitCrossReferenceBaseNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Asset Unit Cross Reference ", _jsx("span", { children: "(Reference Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/assetUnitCrossReference/assetUnitCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Asset Unit Cross Reference" })), _jsx(NavLink, __assign({ to: "/dashboard/assetUnitCrossReference/assetUnitSubTypeCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Asset Unit Subtype Cross Reference" })), _jsx(NavLink, __assign({ to: "/dashboard/assetUnitCrossReference/assetEventTypeCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Asset Event Type Cross Reference" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/assetunitcrossreference", to: "/dashboard/assetUnitCrossReference/assetUnitCrossReference", exact: true }), _jsx(Route, { path: "/dashboard/assetUnitCrossReference/assetUnitCrossReference", component: AssetUnitCrossReference, exact: true }), _jsx(Route, { path: "/dashboard/assetUnitCrossReference/assetUnitSubTypeCrossReference", component: AssetUnitSubTypeCrossReference, exact: true }), _jsx(Route, { path: "/dashboard/assetUnitCrossReference/assetEventTypeCrossReference", component: AssetEventTypeCrossReference, exact: true })] }) }))] }))] })) }));
    };
    return assetUnitCrossReferenceBaseNavLink;
}(Component));
export default assetUnitCrossReferenceBaseNavLink;
