import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { ApiContext } from './apiContext';
var AssetUnitCrossReferencesTab = function () {
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(apiParams.dataProviders), dataProvidersDropdownData = _b[0], setDataProvDD = _b[1];
    var _c = useState(null), indexForEdit = _c[0], setIndexForEdit = _c[1];
    var _d = useState(''), addEditPopupTitle = _d[0], setAddEditPopupTitle = _d[1];
    var _e = useState(false), VisibleAddEdit = _e[0], AddEditPopup = _e[1];
    var _f = useState(false), deletePopup = _f[0], setDeletePopup = _f[1];
    var _g = useState([]), sort = _g[0], setSort = _g[1];
    useEffect(function () {
        setDataProvDD(apiParams.dataProviders);
    }, [apiParams.dataProviders]);
    var addEditUnitCrossReferences = function (dataItem) {
        var index;
        if (!dataItem) {
            //new cross reference;
            setAddEditPopupTitle('Add Cross-Reference');
            index = apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.length;
            var newParams = __assign({}, apiParams);
            newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[index] = {};
            setIndexForEdit(index);
            setApiParams(newParams);
        }
        else {
            //edit cross reference
            setAddEditPopupTitle('Edit Cross-Reference');
            index = apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.findIndex(function (item) { return item.assetUnitCrossRefKey === dataItem.assetUnitCrossRefKey; });
            setIndexForEdit(index);
        }
        AddEditPopup(true);
    };
    var deleteCrossRef = function (dataItem) {
        var index = apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.findIndex(function (item) { return item.assetUnitCrossRefKey === dataItem.assetUnitCrossRefKey; });
        setIndexForEdit(index);
        setDeletePopup(true);
    };
    var EditButtonsCell = function (dataItemProps) {
        return (_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () { return addEditUnitCrossReferences(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ onClick: function () { return deleteCrossRef(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] }));
    };
    //////////////////Functions for handling edits/////////////////////////////////////////
    var isEdited = function () { return (indexForEdit < apiParams.originalCrossRefLength ? true : false); };
    var onDataProviderChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].dataProviderKey = e.target.value.id;
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].dataProviderCd =
            e.target.value.dataProviderAbbr;
        setApiParams(newParams);
    };
    var onAssetIdChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetId = e.target.value;
        setApiParams(newParams);
    };
    var onAssetUnitIdChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetUnitId = e.target.value;
        setApiParams(newParams);
    };
    var onAssetNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetNm = e.target.value;
        setApiParams(newParams);
    };
    var onCityNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerCityNm = e.target.value;
        setApiParams(newParams);
    };
    var onOwnerNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerOwnerNm = e.target.value;
        setApiParams(newParams);
    };
    var onProviderAssetUnitNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetUnitNm = e.target.value;
        setApiParams(newParams);
    };
    var onUnitTypeCodeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetUnitTypeCd =
            e.target.value;
        setApiParams(newParams);
    };
    var onProviderCapacityChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetCapacityQty =
            e.target.value;
        setApiParams(newParams);
    };
    var onDelete = function () {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.splice(indexForEdit, 1);
        newParams.originalAssetUnitConfigData.assetUnitCrossRefDetails.splice(indexForEdit, 1);
        newParams.originalCrossRefLength = newParams.originalCrossRefLength - 1; //important for setting isEdited field
        setApiParams(newParams);
        setDeletePopup(false);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        if (!apiParams.originalAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]) {
            newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.splice(indexForEdit, 1);
        }
        else {
            newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]));
        }
        setApiParams(newParams);
        AddEditPopup(false);
    };
    var onUpdate = function () {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].isEdited = isEdited();
        newParams.originalAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]));
        setApiParams(newParams);
        AddEditPopup(false);
    };
    return (_jsxs("div", { children: [VisibleAddEdit && (_jsx("div", __assign({ id: "unit-cross-reference" }, { children: _jsx(Dialog, __assign({ width: '40%', title: addEditPopupTitle, onClose: onCancel }, { children: _jsxs("div", __assign({ className: "defalut-height" }, { children: [_jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Data Provider" })), _jsx(DropDownList, { data: dataProvidersDropdownData, textField: "dataProviderAbbr", name: "dataProviderAbbr", value: {
                                                        dataProviderAbbr: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                                .dataProviderCd || 'Select'
                                                            : 'Select',
                                                    }, onChange: onDataProviderChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset ID" })), _jsx(Input, { name: "providerAssetId", style: { width: '100%' }, placeholder: "Enter a value", value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit].providerAssetId
                                                        : '', onChange: onAssetIdChange })] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset Name" })), _jsx(Input, { name: "assetName", style: { width: '100%' }, value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerAssetNm || ''
                                                        : 'Enter a name', placeholder: "Enter a name", onChange: onAssetNameChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "City Name" })), _jsx(Input, { name: "firstName", style: { width: '100%' }, value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerCityNm || ''
                                                        : 'Enter a name', placeholder: "Enter a name", onChange: onCityNameChange })] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "col m-0 p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Owner Name" })), _jsx(Input, { name: "firstName", style: { width: '100%' }, value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerOwnerNm || ''
                                                        : 'Enter a name', placeholder: "Enter a name", onChange: onOwnerNameChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset Unit ID" })), _jsx(Input, { name: "providerAssetUnitId", style: { width: '100%' }, placeholder: "Enter a value", value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerAssetUnitId
                                                        : '', onChange: onAssetUnitIdChange })] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "col p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset Unit Name" })), _jsx(Input, { name: "firstName", style: { width: '100%' }, value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerAssetUnitNm || ''
                                                        : '', placeholder: "Enter a name", onChange: onProviderAssetUnitNameChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Unit Type Code" })), _jsx(Input, { name: "firstName", style: { width: '100%' }, value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                            .providerAssetUnitTypeCd || ''
                                                        : 'Enter a value', placeholder: "Enter a value", onChange: onUnitTypeCodeChange })] })) }))] })), _jsx("div", __assign({ className: "row m-0 px-2 py-4" }, { children: _jsx("div", __assign({ className: "col-6 m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "col p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset Capacity" })), _jsx(Input, { name: "cpctyQty", style: { width: '100%' }, type: "number", value: apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                    ? apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails[indexForEdit]
                                                        .providerAssetCapacityQty || ''
                                                    : '', placeholder: "Enter a value", onChange: onProviderCapacityChange })] })) })) })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border", onClick: onCancel }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary", onClick: onUpdate }, { children: "Update" }))] }))] })) })) }))), _jsxs("button", __assign({ className: "p-3", onClick: function () { return addEditUnitCrossReferences(); } }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Cross Reference" }))] })), apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails &&
                apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails.length > 0 ? (_jsx("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: _jsxs(Grid, __assign({ data: orderBy(apiParams.addEditAssetUnitConfigData.assetUnitCrossRefDetails, sort), sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    } }, { children: [_jsx(Column, { width: "90px", cell: EditButtonsCell, filterable: false, resizable: false, reorderable: false, locked: true }), _jsx(Column, { field: "dataProviderCd", title: "Data Provider" }), _jsx(Column, { field: "providerAssetId", title: "Asset ID" }), _jsx(Column, { field: "providerAssetNm", title: "Asset Name" }), _jsx(Column, { field: "providerCityNm", title: "City" }), _jsx(Column, { field: "providerOwnerNm", title: "Owner" }), _jsx(Column, { field: "providerAssetCapacityQty", title: "Asset Capacity" }), _jsx(Column, { field: "providerAssetUnitId", title: "Unit ID" }), _jsx(Column, { field: "providerAssetUnitNm", title: "Unit Name" }), _jsx(Column, { field: "providerAssetUnitTypeCd", title: "Unit Type Code" })] })) }))) : (_jsx("div", __assign({ style: {
                    border: '1px solid #595959',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    color: '#595959',
                    fontFamily: 'ShellLight',
                    left: '36%',
                    top: '46%',
                } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No records available to display." })) }))), deletePopup && (_jsx("div", { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setDeletePopup(false); } }, { children: [_jsx("div", __assign({ className: "p-4" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setDeletePopup(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onDelete }, { children: "Yes" }))] })] })) }))] }));
};
export default AssetUnitCrossReferencesTab;
