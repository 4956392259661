import { emptySplitApi } from './emptySplitApi';
import { resolveParamsObject } from 'common/utils/url/search-params';
var assetCatalogApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getV2Assets: builder.query({
            providesTags: ['AssetCatalog'],
            query: function (_a) {
                var _b = _a.countryIds, countryIds = _b === void 0 ? [] : _b, _c = _a.regionIds, regionIds = _c === void 0 ? [] : _c, _d = _a.assetClassIds, assetClassIds = _d === void 0 ? [] : _d, _e = _a.assetIds, assetIds = _e === void 0 ? [] : _e, _f = _a.page, page = _f === void 0 ? 1 : _f, _g = _a.pageSize, pageSize = _g === void 0 ? 20 : _g, _h = _a.orderBy, orderBy = _h === void 0 ? '' : _h;
                return ({
                    url: '/v2/assets',
                    params: resolveParamsObject({
                        acmRegionKeys: regionIds,
                        acmCountryKeys: countryIds,
                        assetKeys: assetIds,
                        assetClassKeys: assetClassIds,
                        pageNumber: [page],
                        pageSize: [pageSize],
                        orderBy: [orderBy],
                    }),
                });
            },
        }),
        addAsset: builder.mutation({
            query: function (params) { return ({
                url: process.env.REACT_APP_FAST_API_BASE_URL + '/asset',
                method: 'POST',
                body: params,
            }); },
            invalidatesTags: function (_, error) { return (error ? [] : ['ACMHierarchyElement', 'AssetCatalog']); },
        }),
        editAsset: builder.mutation({
            query: function (params) { return ({
                url: process.env.REACT_APP_FAST_API_BASE_URL + '/asset',
                method: 'PUT',
                body: params,
            }); },
            invalidatesTags: function (_, error) { return (error ? [] : ['ACMHierarchyElement', 'AssetCatalog', 'OwnershipDetails']); },
        }),
        getAssetCompany: builder.query({
            query: function (assetKey) { return ({
                url: process.env.REACT_APP_FAST_API_BASE_URL + "/getassetcompany/".concat(assetKey),
            }); },
            providesTags: ['OwnershipDetails'],
        }),
    }); },
});
export var useGetV2AssetsQuery = assetCatalogApis.useGetV2AssetsQuery, useAddAssetMutation = assetCatalogApis.useAddAssetMutation, useGetAssetCompanyQuery = assetCatalogApis.useGetAssetCompanyQuery, useEditAssetMutation = assetCatalogApis.useEditAssetMutation;
