import { LoadingSize, LoadingType } from './Loading.types';
import { Theme } from '../../providers/theme/theme';
import { Sizes } from '../../types/types';

type ProgressBarConstsColorsType = {
  color: {
    [state in LoadingType]: string;
  };
};

type LoadingConstsType = {
  [size in LoadingSize]: {
    viewBoxSize: number;
    strokeWidth: number;
  };
};

export const LoadingConstsColors = (theme: Theme): ProgressBarConstsColorsType => ({
  color: {
    default: theme.accent.primary.strong,
    primary: theme.text.onPrimary.strong,
    tertiary: theme.text.onTertiary.strong,
  },
});

export const loadingConsts: LoadingConstsType = {
  [Sizes.ExtraSmall]: {
    viewBoxSize: 16,
    strokeWidth: 2,
  },
  [Sizes.Small]: {
    viewBoxSize: 20,
    strokeWidth: 2,
  },
  [Sizes.Medium]: {
    viewBoxSize: 30,
    strokeWidth: 3,
  },
  [Sizes.Large]: {
    viewBoxSize: 40,
    strokeWidth: 4,
  },
};
