import { useMemo } from 'react';
var resolveValueToSelect = function (key, text) {
    return function (value) { return ({
        key: value[key],
        value: value[key],
        children: value[text],
    }); };
};
export var useResolveSelectData = function (data, idKey, titleKey) {
    return useMemo(function () { return (data !== null && data !== void 0 ? data : []).map(resolveValueToSelect(idKey, titleKey)); }, [idKey, titleKey, data]);
};
