import { __awaiter, __generator } from "tslib";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useUpdateAssetUnitCapacitiesMutation, useAddAssetUnitCapacitiesMutation, useBulkClosureMutation, } from 'common/ducks/capacityApis';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
export var useHandleSave = function (_a) {
    var events = _a.events, setIsSaving = _a.setIsSaving, onClose = _a.onClose;
    var updateAssetUnitCapacities = useUpdateAssetUnitCapacitiesMutation()[0];
    var addAssetUnitCapacities = useAddAssetUnitCapacitiesMutation()[0];
    var bulkClosure = useBulkClosureMutation()[0];
    var _b = useState(null), error = _b[0], setError = _b[1];
    useEffect(function () {
        setError(null);
    }, [events]);
    var handleSave = (function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var capacityEventToSave, e_1, data_1, errors;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsSaving(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 9, , 10]);
                    if (!!events.length) return [3 /*break*/, 6];
                    if (!(data.units.length === 1)) return [3 /*break*/, 3];
                    capacityEventToSave = {
                        assetUnitKey: data.units[0],
                        capacityStartDt: dayjs(data.startDate).format(DATE_FORMAT_YYYY_MM_DD),
                        capacityUniversalQty: data.incrementalCapacity,
                        planningStatusTypeKey: data.statusType,
                        planningProbabilityTypeKey: data.probabilityType,
                        dataProviderKey: data.sourceOfIntelligence,
                        comment: data.intelligenceSourceTxt,
                        capacityBasisTypeKey: data.basisType,
                        capacityUniversalUomKey: data.capacityUoM,
                    };
                    return [4 /*yield*/, addAssetUnitCapacities(capacityEventToSave).unwrap()];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 5];
                case 3:
                    capacityEventToSave = {
                        assetUnitKey: data.units,
                        capacityStartDt: dayjs(data.startDate).format(DATE_FORMAT_YYYY_MM_DD),
                        planningStatusTypeKey: data.statusType,
                        planningProbabilityTypeKey: data.probabilityType,
                        dataProviderKey: data.sourceOfIntelligence,
                        comment: data.intelligenceSourceTxt,
                        capacityBasisTypeKey: data.basisType,
                    };
                    return [4 /*yield*/, bulkClosure(capacityEventToSave).unwrap()];
                case 4:
                    _c.sent();
                    _c.label = 5;
                case 5: return [3 /*break*/, 8];
                case 6:
                    //edit single event
                    if (events.length === 1) {
                        capacityEventToSave = {
                            capacityBasisTypeKey: data.basisType,
                            capacityStartDt: dayjs(data.startDate).format(DATE_FORMAT_YYYY_MM_DD),
                            comment: data.intelligenceSourceTxt,
                            id: events[0].assetUnitCapacityKey,
                            dataProviderKey: data.sourceOfIntelligence,
                            planningProbabilityTypeKey: data.probabilityType,
                            planningStatusTypeKey: data.statusType,
                            capacityUniversalQty: data.incrementalCapacity,
                            capacityUniversalUomKey: data.capacityUoM,
                        };
                    }
                    else {
                        capacityEventToSave = {
                            planningProbabilityTypeKey: data.probabilityType,
                            dataProviderKey: data.sourceOfIntelligence,
                            planningStatusTypeKey: data.statusType,
                            comment: data.intelligenceSourceTxt,
                            id: events.map(function (e) { return e.assetUnitCapacityKey; }),
                            delayByMonths: data.offsetStartDateBy,
                        };
                    }
                    return [4 /*yield*/, updateAssetUnitCapacities(capacityEventToSave).unwrap()];
                case 7:
                    _c.sent();
                    _c.label = 8;
                case 8:
                    onClose();
                    return [3 /*break*/, 10];
                case 9:
                    e_1 = _c.sent();
                    if (e_1.data) {
                        data_1 = e_1.data;
                        errors = Object.keys(data_1 === null || data_1 === void 0 ? void 0 : data_1.errors);
                        setError((_b = (_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.errors) === null || _a === void 0 ? void 0 : _a[errors === null || errors === void 0 ? void 0 : errors[0]]) === null || _b === void 0 ? void 0 : _b[0]);
                    }
                    return [3 /*break*/, 10];
                case 10:
                    setIsSaving(false);
                    return [2 /*return*/];
            }
        });
    }); });
    return { handleSave: handleSave, error: error };
};
