import { StyledAvatar, Area } from './LastArea.styles';
import { INavBarLastAreaProps } from '../../NavBar.types';

const LastArea = ({ avatarArea, avatarProps }: INavBarLastAreaProps) => (
  <>
    {avatarArea && !avatarProps && <Area>{avatarArea}</Area>}
    {avatarProps && (
      <Area>
        <StyledAvatar {...avatarProps} />
      </Area>
    )}
  </>
);

export default LastArea;
