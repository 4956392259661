var MAX_SERIES_LENGTH = 7;
var BREAKDOWN_BY_MAP = new Map([
    ['R', 'Region'],
    ['C', 'Country'],
    ['UT', 'Unit Type'],
    ['UST', 'Unit Subtype'],
    ['A', 'Asset'],
]);
var CHART_MODES_FOR_API = new Map([
    ['D', 'Daily'],
    ['A', 'EiA'],
    ['W', 'Weekly'],
    ['M', 'Monthly'],
    ['Y', 'Yearly'],
]);
var DATE_FORMAT_BY_MODE = new Map([
    ['day', 'DD MMM YYYY'],
    ['month', 'MMM YYYY'],
    ['year', 'YYYY'],
    ['week', 'DD MMM YYYY'],
]);
var SPLIT_BY_TYPE_OPTIONS = [
    { value: 'Location', children: 'Location', key: 'L' },
    { value: 'Asset', children: 'Asset', key: 'A' },
];
var LOCATION_SUBTYPE_OPTIONS = [
    { value: 'R', children: 'Region', key: 'R' },
    { value: 'C', children: 'Country', key: 'C' },
];
var ASSET_SUBTYPE_OPTIONS = [
    { value: 'UT', children: 'Unit Type', key: 'UT' },
    { value: 'UST', children: 'Unit Subtype', key: 'UST' },
    { value: 'A', children: 'Asset Name', key: 'A' },
];
export { MAX_SERIES_LENGTH, CHART_MODES_FOR_API, SPLIT_BY_TYPE_OPTIONS, LOCATION_SUBTYPE_OPTIONS, ASSET_SUBTYPE_OPTIONS, BREAKDOWN_BY_MAP, DATE_FORMAT_BY_MODE, };
