import { ReactElement } from 'react';

import { Divider } from 'rc-menu';
import { DividerProps } from 'rc-menu/lib/Divider';

export interface IMenuDividerProps extends DividerProps {}

const MenuDivider = ({ ...props }: IMenuDividerProps): ReactElement => <Divider {...props} />;

export default MenuDivider;
