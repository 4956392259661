import { PaginationSize } from './Pagination.types';
import { Sizes } from '../../types/types';

type PaginationConstsType = {
  margin: string;
  radius: string;
  inputPadding: {
    [size in PaginationSize]: string;
  };
};

export const paginationConsts: PaginationConstsType = {
  margin: '12px',
  radius: '2px',
  inputPadding: {
    [Sizes.Small]: '4px 10px',
    [Sizes.Medium]: '8px 12px',
    [Sizes.Large]: '11px 16px',
  },
};
