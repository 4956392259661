import styled, { css } from 'styled-components';

import { avatarConsts } from './Avatar.consts';
import { IAvatarProps } from './Avatar.types';
import { Sizes } from '../../types/types';

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Avatar = styled.div<Required<Pick<IAvatarProps, 'size'>>>(
  ({ size }) => css`
    display: flex;
    align-items: stretch;
    flex: 0;
    ${size &&
    css`
      min-width: ${avatarConsts[size].avatar}px;
      min-height: ${avatarConsts[size].avatar}px;
      width: ${avatarConsts[size].avatar}px;
      height: ${avatarConsts[size].avatar}px;
    `}
  `,
);

const NoImageCircleWrapper = styled.div<Required<Pick<IAvatarProps, 'size'>>>`
  ${({ size, theme }) => css`
    ${(size === Sizes.Small || size === Sizes.Medium) && theme.heading[5].bold}
    ${size === Sizes.Large && theme.heading[3].demi}

    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    line-height: initial;
    text-align: center;
    white-space: nowrap;
    color: ${theme.text.onFocus.strong};
    background-color: ${theme.focus.strong};
  `}
`;

const NoImageCircle = styled.div`
  display: flex;
  font-size: 100%;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

const TextBox = styled.div<Pick<IAvatarProps, 'collapsed'>>(
  ({ collapsed }) => css`
    display: flex;
    flex: 1 1 0;
    min-width: 0;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transition: opacity 0.2s linear;
    opacity: ${collapsed ? '0' : '1'};
  `,
);

const Label = styled.p<IAvatarProps>`
  ${({ theme, size, secondaryLabel }) => css`
    margin: 0;

    ${secondaryLabel &&
    secondaryLabel.length > 0 &&
    css`
      ${size === Sizes.Large && theme.label.medium.bold}
      ${(size === Sizes.Small || size === Sizes.Medium) && theme.label.small.bold}
    `}

    ${(!secondaryLabel || (secondaryLabel && secondaryLabel.length === 0)) &&
    css`
      ${(size === Sizes.Large || size === Sizes.Medium) && theme.label.medium.bold}
      ${size === Sizes.Small && theme.label.small.bold}
    `}
    
    color: ${theme.text.onSurface.strong};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `}
`;

const AdditionalText = styled.p<Required<Pick<IAvatarProps, 'size'>>>`
  ${({ size, theme }) => css`
    margin: ${size === Sizes.Large ? '4px' : '0'} 0 0;

    ${size === Sizes.Large && theme.label.medium.normal}
    ${(size === Sizes.Small || size === Sizes.Medium) && theme.label.small.normal}

    color: ${theme.text.onSurface.subtle};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `}
`;

const AvatarDropdown = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

export {
  AvatarWrapper,
  Avatar,
  NoImageCircleWrapper,
  NoImageCircle,
  Image,
  TextBox,
  Label,
  AdditionalText,
  AvatarDropdown,
};
