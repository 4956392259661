import styled, { css } from 'styled-components';

import { NAV_BAR_CLASS, Z_INDEX } from '../../utils/constants';
import { INavBarProps, INavBarPropsWithReqSize } from './NavBar.types';
import Menu from '../Menu/Menu';
import { Directions, Transient } from '../../types/types';
import { navBarSizes } from './NavBar.consts';

const StyledMenu = styled(Menu)`
  flex: 1 1 0;
`;

const ExpandNotch = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 32px;
    overflow: hidden;

    &:before {
      content: '';
      right: 2px;
      top: 2px;
      position: absolute;
      border-radius: 0 14px 14px 0;
      background-color: ${theme.background.surface};
      box-shadow: ${theme.elevation.level1};
      display: block;
      width: 28px;
      height: 28px;
      transform: translateX(-12px);
      transition: transform 0.2s ease-out;
    }
  `,
);

const ExpandIcon = styled.div`
  opacity: 0;
  position: absolute;
  left: 4px;
  top: 4px;
  width: 24px;
  height: 24px;
  padding: 2px;
  transform: translateX(-6px);
  transition: opacity 0.1s linear, transform 0.2s ease-out;
`;

const Expand = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    position: absolute;
    z-index: 2;
    right: -12px;
    top: 38px;
    width: 32px;
    height: 32px;
    border-radius: 16px;

    &:focus-visible {
      outline: 3px solid ${theme.focus.medium};

      ${ExpandIcon}, ${ExpandNotch}:before {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `,
);

const Wrapper = styled.div<Transient<INavBarPropsWithReqSize, 'direction'>>(
  ({ $direction, size }) => css`
    position: relative;
    height: ${$direction === Directions.Vertical ? '100%' : `${navBarSizes[size].height}px`};
    width: ${$direction === Directions.Vertical ? `${navBarSizes[size].height}px` : '100%'};
  `,
);

const Nav = styled.header<Transient<INavBarPropsWithReqSize, 'direction'>>(
  ({ $direction, elevation, collapsed, theme, expandedWidth = 300, size }) => css`
    z-index: ${Z_INDEX.NAVBAR};
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${$direction === Directions.Horizontal ? 'row' : 'column'};
    gap: 16px;
    align-items: stretch;
    height: 100%;
    width: 100%;
    box-shadow: ${elevation ? theme.elevation.level1 : null};
    color: ${theme.text.onSurface.strong};
    background-color: ${theme.background.surface};
    transition: width 0.3s ease-out;

    ${$direction === Directions.Vertical &&
    css`
      width: ${collapsed ? `${navBarSizes[size].height}px` : `${expandedWidth}px`};

      .${NAV_BAR_CLASS}-icon {
        transform: rotate(${collapsed ? 0 : 180}deg);
      }

      &:hover ${Expand} {
        ${ExpandIcon} {
          opacity: 1;
        }

        ${ExpandIcon}, ${ExpandNotch}:before {
          transform: translateX(-2px);
        }

        &:hover {
          ${ExpandIcon}, ${ExpandNotch}:before {
            transform: translateX(0);
          }
        }
      }
    `}
  `,
);

const MiddleArea = styled.div<Transient<INavBarProps, 'direction'>>(
  ({ $direction, collapsed }) => css`
    position: relative;
    display: flex;
    flex-shrink: ${collapsed ? 0 : 1};
    flex-grow: 1;
    flex-direction: ${$direction === Directions.Horizontal ? 'row' : 'column'};
    align-items: stretch;
    min-width: 0;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
  `,
);

export { Wrapper, Nav, MiddleArea, Expand, ExpandNotch, ExpandIcon, StyledMenu };
