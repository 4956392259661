import styled, { css } from 'styled-components';

import Flexbox from '../../../Flexbox/Flexbox';
import { modalConsts } from '../../Modal.consts';
import { ModalActionBarAlignment, ModalPadding, ModalSize } from '../../Modal.types';

type ContainerProps = {
  actionBarAlignment: ModalActionBarAlignment;
  size: ModalSize;
  padding: ModalPadding;
};

const Container = styled(Flexbox)<ContainerProps>`
  ${({ actionBarAlignment, size, padding }) => css`
    padding: ${modalConsts[size].actionBarPadding[padding]};
    & button {
      flex-grow: ${actionBarAlignment === 'fill' && 1};
    }
  `}
`;

export default { Container };
