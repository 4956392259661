import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flexbox, Icons } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { StyledButtonWithIcon } from 'pages/maintenance-event-summary/maintenance-utils/MaintenanceEventTable.styles';
import { useGetBaselineEventsHavingOriginalsQuery } from 'common/ducks/maintenanceApis';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
var DatabaseSolid = Icons.DatabaseSolid, Time = Icons.Time;
var StyledDataBaseIcon = styled(DatabaseSolid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  g {\n    fill: ", ";\n  }\n"], ["\n  g {\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
});
export var EventSummaryActions = function (eventKey, item, handleShowModal) {
    var _a, _b;
    var has_links = item.has_links, has_previous_versions = item.has_previous_versions;
    var location = useLocation();
    var search = location.search;
    var params = new URLSearchParams(search);
    var timeframe = params.getAll('timeframe');
    var event_start_dt = (_a = timeframe[0]) !== null && _a !== void 0 ? _a : dayjs().startOf('month').format(DATE_FORMAT_YYYY_MM_DD);
    var event_end_dt = (_b = timeframe[1]) !== null && _b !== void 0 ? _b : dayjs().add(3, 'month').endOf('month').format(DATE_FORMAT_YYYY_MM_DD);
    var data = useGetBaselineEventsHavingOriginalsQuery({ event_start_dt: event_start_dt, event_end_dt: event_end_dt }).data;
    var has_originals = data === null || data === void 0 ? void 0 : data.asset_unit_event_keys.includes(eventKey);
    var title = 'Link Events';
    return (_jsxs(Flexbox, __assign({ alignContent: "center" }, { children: [has_originals ? (_jsx(StyledButtonWithIcon, { isVisible: true, iconOnly: true, icon: _jsx(DatabaseSolid, {}), variant: "transparent", size: "small", title: "Originals", onClick: function (e) {
                    return handleShowModal({ e: e, eventKey: eventKey, modalName: 'originals', title: 'Originals', unitKey: item.asset_unit_key });
                } })) : (_jsx(StyledButtonWithIcon, { isVisible: has_links === 'y', iconOnly: true, icon: has_links === 'y' && _jsx(StyledDataBaseIcon, {}), variant: "transparent", size: "small", title: "Link Events", onClick: function (e) {
                    return handleShowModal({
                        e: e,
                        eventKey: eventKey,
                        modalName: 'links',
                        title: title,
                        unitKey: item.asset_unit_key,
                    });
                } })), _jsx(StyledButtonWithIcon, { isVisible: has_previous_versions === 'y', iconOnly: true, icon: has_previous_versions === 'y' && _jsx(Time, {}), variant: "transparent", size: "small", title: "Previous Versions", onClick: function (e) {
                    return handleShowModal({
                        e: e,
                        eventKey: eventKey,
                        modalName: 'versions',
                        title: 'Baseline history',
                        unitKey: item.asset_unit_key,
                    });
                } })] })));
};
var templateObject_1;
