import React, { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';
import RCSelect, { BaseSelectRef, SelectProps } from 'rc-select';

import { SELECT_CLASS, SELECT_ERROR_CLASS } from '../../utils/constants';
import { ArrowIcon, ClearIcon, RemoveIcon, SelectedIcon } from './components/Icons/Icons';
import * as Styled from './Select.styles';
import { ISelectProps } from './Select.types';
import { Sizes, Positions } from '../../types/types';

const RCSelectRef = forwardRef<BaseSelectRef, SelectProps>(({ className, ...rest }: SelectProps, ref) => (
  <RCSelect
    ref={ref}
    dropdownClassName={className}
    className={className}
    getPopupContainer={(element) => element}
    {...rest}
  />
));

const Select = forwardRef<BaseSelectRef, ISelectProps>(
  (
    {
      className,
      size = Sizes.Medium,
      mode = 'single',
      placeholder,
      disabled = false,
      invalid = false,
      showSearch = true,
      placement,
      optionFilterProp = 'children',
      optionLabelProp = 'children',
      ...rest
    }: ISelectProps,
    ref,
  ): ReactElement => (
    <Styled.Select<any>
      as={RCSelectRef}
      ref={ref}
      className={classNames(invalid && SELECT_ERROR_CLASS, SELECT_CLASS, className)}
      prefixCls={SELECT_CLASS}
      aria-label="Select"
      size={size}
      mode={mode !== 'single' ? mode : undefined}
      placeholder={placeholder}
      disabled={disabled}
      showSearch={showSearch}
      suffixIcon={ArrowIcon}
      allowClear={{ clearIcon: ClearIcon }}
      optionFilterProp={optionFilterProp}
      optionLabelProp={optionLabelProp}
      {...rest}
      removeIcon={RemoveIcon}
      menuItemSelectedIcon={SelectedIcon}
      placement={placement === Positions.Top ? 'topLeft' : 'bottomLeft'}
    />
  ),
);

export default Select;
