import { __assign, __spreadArray } from "tslib";
import * as types from '../actions/capacityScreenActionsType';
export default function capacityScreenReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case types.LOAD_FILTER_SUCCESS:
            // let filters = new Map();
            // let currentFilterState = state.filter;
            // if (typeof currentFilterState !== "undefined") {
            //   filters.set(action.filterDataType, action.filterData);
            //   let concatedArray = new Map([...currentFilterState, ...filters]);
            //   return { filter: concatedArray };
            // } else {
            //   filters.set(action.filterDataType, action.filterData);
            //   return { filter: filters };
            // }
            break;
        case types.ADD_TO_FILTER:
            // let addToMap = new Map();
            // if (typeof state.selections === "undefined") {
            //   addToMap.set(action.filterType, [action.payload]);
            // } else {
            //   let currentSelection = state.selections.get(action.filterType);
            //   if (typeof currentSelection !== "undefined") {
            //     let currentSelectionState = state.selections;
            //     if (typeof currentSelectionState !== "undefined") {
            //       currentSelection = [...currentSelection, action.payload];
            //       addToMap.set(action.filterType, currentSelection);
            //       let concatedArray = new Map([
            //         ...currentSelectionState,
            //         ...addToMap
            //       ]);
            //       return { ...state, selections: concatedArray };
            //     } else {
            //       currentSelection = [...currentSelection, action.payload];
            //       addToMap.set(action.filterType, [action.payload]);
            //     }
            //   } else {
            //     let currentSelectionState = state.selections;
            //     if (typeof currentSelectionState !== "undefined") {
            //       addToMap.set(action.filterType, [action.payload]);
            //       let concatedArray = new Map([
            //         ...currentSelectionState,
            //         ...addToMap
            //       ]);
            //       return { ...state, selections: concatedArray };
            //     } else {
            //       addToMap.set(action.filterType, [action.payload]);
            //     }
            //   }
            // }
            // return { ...state, selections: addToMap };
            break;
        case types.DELETE_FROM_FILTER:
            var deleteMap = new Map();
            var currentSelectionState = state.selections;
            var currentSelection = state.selections.get(action.filterType);
            if (typeof currentSelection !== 'undefined') {
                var index = currentSelection.findIndex(function (x) { return x.id === action.payload.id; });
                if (index > -1) {
                    currentSelection.splice(index, 1);
                }
                deleteMap.set(action.filterType, currentSelection);
                var concatedArray = new Map(__spreadArray(__spreadArray([], currentSelectionState, true), deleteMap, true));
                return __assign(__assign({}, state), { selections: concatedArray });
            }
            break;
        case types.SEARCH_IN_FILTER:
            var tasks = void 0;
            var filter = new Map();
            var search = new Map();
            var currentSearchState = state.search;
            var currentIsFilteredState = state.isFiltered;
            if (action.length > 0) {
                tasks = state.filter.get(action.filterName).filter(function (val) {
                    return val[action.Cd].toLowerCase().search(action.payload.toLowerCase()) !== -1;
                });
                filter.set(action.filterName, true);
            }
            else {
                tasks = [];
                filter.set(action.filterName, false);
            }
            if (typeof currentSearchState !== 'undefined') {
                search.set(action.filterName, tasks);
                var concatedArray = new Map(__spreadArray(__spreadArray([], currentSearchState, true), search, true));
                var concatedIsFilteredArray = new Map(__spreadArray(__spreadArray([], currentIsFilteredState, true), filter, true));
                return __assign(__assign({}, state), { search: concatedArray, isFiltered: concatedIsFilteredArray });
            }
            else {
                search.set(action.filterName, tasks);
                return __assign(__assign({}, state), { search: search, isFiltered: filter });
            }
            break;
        case types.RESET_ALL_FILTER:
            var resetAllFilter = new Map();
            tasks = [];
            return __assign(__assign({}, state), { selections: resetAllFilter, search: tasks, isFiltered: false });
        default:
            return state;
            break;
    }
}
