import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
var assetUnitCrossReference = /** @class */ (function (_super) {
    __extends(assetUnitCrossReference, _super);
    function assetUnitCrossReference(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            dataProviderAbbr: {
                required: true,
                error: 'Please enter Data Provider name.',
            },
            assetUnitNm: {
                required: true,
                error: 'Please enter Asset Unit Name.',
            },
            assetNm: {
                required: true,
                error: 'Please enter Asset Name.',
            },
            allAssetUnitsInd: {
                required: true,
                error: 'Please enter Asset Unit Indicator.',
            },
            providerAssetUnitNm: {
                required: true,
                error: 'Please enter Provider Asset Unit name.',
            },
            providerAssetUnitId: {
                required: true,
                error: 'Please enter Provider Asset Unit Id.',
            },
        };
        var yesNoData = [{ ynValue: 'allAssetUnitsInd' }];
        var foreginKeyParams = [
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
            },
            {
                path: 'assets',
                key: 'assetKey',
                cdName: 'assetNm',
            },
            {
                path: 'assetUnit',
                key: 'assetUnitKey',
                cdName: 'assetUnitNm',
            },
        ];
        var columns = [
            {
                field: 'providerAssetCapacityQty',
                title: 'Provider Asset Capacity Qty',
            },
            {
                field: 'providerAssetUnitTypeCd',
                title: 'Provider Asset Unit Type Cd',
            },
            {
                field: 'providerAssetUnitNm',
                title: 'Provider Asset Unit Nm',
            },
            {
                field: 'providerAssetUnitId',
                title: 'Provider Asset Unit Id',
            },
            {
                field: 'providerOwnerNm',
                title: 'Provider Owner Nm',
            },
            {
                field: 'providerCityNm',
                title: 'Provider City Nm',
            },
            {
                field: 'providerAssetNm',
                title: 'Provider Asset Nm',
            },
            {
                field: 'providerAssetId',
                title: 'Provider Asset Id',
            },
            {
                field: 'allAssetUnitsInd',
                title: 'All Asset Units Ind',
                type: 'yesNo',
                ynRefer: 'allAssetUnitsInd',
                yn: 'yn',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider Name',
                urlEndPoint: 'dataProvider',
            },
            {
                field: 'assetUnitNm',
                title: 'Asset Unit Key',
                type: 'foreignKeyConstraint',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetUnitNm',
                useId: true,
                referenceTitle: 'Asset Unit Key',
                urlEndPoint: 'assetUnit',
            },
            {
                field: 'assetNm',
                title: 'Asset Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetNm',
                referenceTitle: 'Asset Nm',
                urlEndPoint: 'assets',
            },
            {
                field: 'versionTerminationDt',
                title: 'Termination Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'versionEffectiveDt',
                title: 'Effective Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
            yesNoData: yesNoData,
        };
        return _this;
    }
    assetUnitCrossReference.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "assetUnitCrossRef", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "providerAssetUnitTypeCd", foreginKeyParams: this.state.foreginKeyParams, yesNoData: this.state.yesNoData }));
    };
    return assetUnitCrossReference;
}(Component));
export default assetUnitCrossReference;
