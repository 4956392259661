import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import SvgLogo from '../../../../../../commons/tools/svgLogo';
import _ from 'lodash';
import { getSourceFilter, editSourceData } from '../../../../../../services/urlService';
import { Modal, Button, Select, Checkbox } from 'antd';
import CollapseExpand from './collapseExpand';
import { toast } from 'react-toastify';
import { ProviderRow } from './ProviderRow';
var Option = Select.Option;
var moment = require('moment');
function dateRange(startDate, endDate) {
    if (startDate && endDate) {
        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];
        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? '0' + month : month;
                dates.push([i, displayMonth, '01'].join('-'));
            }
        }
        return dates.sort(function (a, b) { return new Date(a) - new Date(b); });
    }
    else {
        return null;
    }
}
var DataLayout = function (_a) {
    var dataLayoutRefresh = _a.dataLayoutRefresh, setLoader = _a.setLoader, matType = _a.matType, title = _a.title, data = _a.data, sDate = _a.sDate, eDate = _a.eDate, nmForProvider = _a.nmForProvider;
    var _b = useState(false), isModalVisible = _b[0], setIsModalVisible = _b[1];
    var _c = useState(), countryId = _c[0], setCountryId = _c[1];
    var _d = useState(), dataProvider = _d[0], setDataProvider = _d[1];
    var _e = useState({}), changeData = _e[0], setChangeData = _e[1];
    var _f = useState([]), dataLst = _f[0], setDataLst = _f[1];
    var _g = useState(''), comments = _g[0], setComments = _g[1];
    var _h = useState([]), provider = _h[0], setProvider = _h[1];
    var _j = useState(null), selectedProvider = _j[0], setSelectedProvider = _j[1];
    var _k = useState(), clickedCountry = _k[0], setClickedCountry = _k[1];
    var dateColumn = dateRange(sDate, eDate);
    var _l = useState(data), modalData = _l[0], setModalData = _l[1];
    var _m = useState(true), locked = _m[0], setLocked = _m[1];
    var showModal = function () {
        setIsModalVisible(true);
    };
    var editCurrentRow = function (cId, dPro, clickedCountry) {
        showModal();
        setCountryId(cId);
        setDataProvider(dPro);
        setClickedCountry(clickedCountry);
    };
    var handleOk = function () {
        if (!selectedProvider) {
            toast.error('Select source of intelligence', {
                autoClose: 3000,
                toastId: 'DATE_ISSUE',
            });
            return;
        }
        var invalidDateValue = dataLst.find(function (data) { return isNaN(data.quantity); });
        if (invalidDateValue) {
            toast.error("Enter a valid intake quantity for ".concat(moment(invalidDateValue.yearAndMonth, 'YYYYMM').format('MMM YYYY')), {
                autoClose: 3000,
                toastId: 'QTY',
            });
            return;
        }
        var arrWrapper = [];
        var data = {
            dataProviderId: selectedProvider,
            materialTypeKey: matType,
            geopoliticalEntityKey: countryId,
            comment: comments ? comments : '',
            intakeDataLst: dataLst,
        };
        arrWrapper.push(data);
        setLoader(true);
        editSourceData(arrWrapper, null, 'refineryIntakeBulk').then(function (response) {
            if (response.status === 200 || response.status === 201 || response.status === 206) {
                handleCancel();
                setDataLst([]);
                dataLayoutRefresh(getRandom());
            }
            setLoader(false);
        });
    };
    var handleCancel = function () {
        setIsModalVisible(false);
        setSelectedProvider(null);
        setDataLst([]);
        setCountryId(null);
        setClickedCountry(null);
        setComments(null);
        setLocked(true);
    };
    var handleMenuClick = function (e) {
        setSelectedProvider(e);
    };
    useEffect(function () {
        function dataLoader() {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(!provider.length > 0)) return [3 /*break*/, 2];
                            return [4 /*yield*/, getSourceFilter('DataProviders/UserSpecificDataProvider?TypeKey=1')
                                    .then(function (filterData) { return __awaiter(_this, void 0, void 0, function () {
                                    var dataInLoop, i, currObject;
                                    return __generator(this, function (_a) {
                                        if (filterData.data.length > 0) {
                                            dataInLoop = provider;
                                            for (i = 0; i < filterData.data.length; i++) {
                                                currObject = {
                                                    value: filterData.data[i].id,
                                                    label: filterData.data[i][nmForProvider],
                                                };
                                                dataInLoop.push(currObject);
                                            }
                                            setProvider(dataInLoop);
                                        }
                                        return [2 /*return*/];
                                    });
                                }); })
                                    .catch(function (error) { })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        dataLoader();
    }, []);
    var addToUpdatedList = function (value, refineryIntakeKey, monthYear) {
        var editedData = dataLst;
        var found = editedData.some(function (el) { return el.yearAndMonth === monthYear.toString(); });
        if (!found) {
            editedData.push({
                quantity: parseFloat(value),
                refineryIntakeKey: refineryIntakeKey,
                yearAndMonth: monthYear.toString(),
                isEdited: !!refineryIntakeKey,
                isLockedInd: locked,
            });
        }
        else {
            var indexOfFoundItem = editedData.findIndex(function (el) { return el.yearAndMonth === monthYear.toString(); });
            var objectOfData = editedData[indexOfFoundItem];
            objectOfData.quantity = parseFloat(value);
            objectOfData.refineryIntakeKey = refineryIntakeKey;
            objectOfData.yearAndMonth = monthYear.toString();
            objectOfData.isEdited = !!refineryIntakeKey;
            objectOfData.isLockedInd = locked;
            editedData[indexOfFoundItem] = objectOfData;
        }
        setDataLst(editedData);
    };
    var getQuantity = function (date, values, dates) {
        var rawQuantity = values[dates.indexOf(date)];
        return rawQuantity ? parseFloat(rawQuantity) : 0;
    };
    var handleInputChange = function (values, countryId, providerName, dates) {
        var updated = modalData.map(function (country) {
            if (country.countryId === countryId) {
                return __assign(__assign({}, country), { dataProviderLsts: country.dataProviderLsts.map(function (provider) {
                        if (provider.dataProviderName === providerName) {
                            var addedDates_1 = [];
                            var dataLst_1 = provider.dataLst.map(function (data) {
                                var foundDate = dates.find(function (date) { return date === data.monthAndYear; });
                                if (data.monthAndYear === foundDate) {
                                    var quantity = getQuantity(foundDate, values, dates);
                                    addedDates_1.push(foundDate);
                                    addToUpdatedList(quantity, data.refineryIntakeKey, moment(foundDate, 'MMYYYY').format('YYYYMM'), data.quantity);
                                    return __assign(__assign({}, data), { quantity: quantity });
                                }
                                else
                                    return data;
                            });
                            if (addedDates_1.length !== dates.length) {
                                var missingDates = dates.filter(function (date) { return addedDates_1.indexOf(date) === -1; });
                                missingDates.forEach(function (date) {
                                    var quantity = getQuantity(date, values, dates);
                                    dataLst_1.push({
                                        quantity: quantity,
                                        monthAndYear: date,
                                    });
                                    addToUpdatedList(quantity, null, moment(date, 'MMYYYY').format('YYYYMM'), null);
                                });
                            }
                            return __assign(__assign({}, provider), { dataLst: dataLst_1 });
                        }
                        else
                            return provider;
                    }) });
            }
            else
                return country;
        });
        setModalData(updated);
    };
    var handlePaste = function (e, dateIndex, countryId, providerName) {
        e.preventDefault();
        var text = e.clipboardData.getData('text/plain');
        text = text.replaceAll('\n', '_').replaceAll('\t', '_').replaceAll(',', '');
        var values = text.split('_');
        if (text.endsWith('_')) {
            values.pop();
        }
        var len = values.length;
        if (len > 1) {
            var dates = dateColumn
                .map(function (date) { return moment(new Date(date)).format('MMYYYY'); })
                .slice(dateIndex, dateIndex + values.length);
            handleInputChange(values, countryId, providerName, dates);
        }
    };
    var textCommentForEdit = function (e) {
        setComments(e.target.value);
    };
    var getRandom = function () {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
    var toggleLock = function () {
        var updatedLock = !locked;
        var editedData = dataLst.slice();
        editedData.forEach(function (data) {
            data.isLockedInd = updatedLock;
        });
        setDataLst(editedData);
        setLocked(updatedLock);
    };
    useEffect(function () {
        setModalData(data);
    }, [data]);
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ title: "Insert/Edit Records", visible: isModalVisible, onOk: handleOk, onCancel: handleCancel, width: '80%', footer: [
                    _jsx(Button, __assign({ onClick: handleCancel }, { children: "Cancel" }), "back"),
                    _jsx(Button, __assign({ type: "primary", onClick: handleOk }, { children: "Save" }), "submit"),
                ] }, { children: [_jsxs("div", __assign({ className: "mr-5", style: {
                            display: 'inline',
                            cursor: 'pointer',
                            backgroundColor: '#202020',
                            border: '1px solid #595959',
                            padding: '7px 0 7px 10px',
                            borderRadius: '2px',
                            width: '275px',
                        }, id: "provider" }, { children: ["Source of Intelligence:", _jsx(Select, __assign({ style: { width: '125px' }, showSearch: true, bordered: false, allowClear: true, showArrow: true, placeholder: "Select", filterOption: function (input, option) { return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0; }, onChange: function (value) {
                                    handleMenuClick(value);
                                }, value: selectedProvider }, { children: provider.map(function (data) {
                                    return (_jsx(Option, __assign({ value: data.value, label: data.label }, { children: data.label }), data.label));
                                }) }))] })), _jsx(Checkbox, __assign({ onClick: toggleLock, checked: locked }, { children: "Locked to Baseline" })), dateColumn &&
                        modalData.length > 0 &&
                        modalData.map(function (countrydata) {
                            if (countrydata.countryId === countryId) {
                                return (_jsx("div", __assign({ className: "edittable p-0 pt-3 pb-2", id: "wrapmodel" }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: dateColumn.map(function (date) {
                                                        return (_jsx("th", __assign({ className: "text-centre" }, { children: _jsx("div", __assign({ className: "header" }, { children: moment(new Date(date)).format('MMM YYYY') })) }), date));
                                                    }) }) }), _jsx("tbody", { children: countrydata.dataProviderLsts.map(function (row, index) {
                                                    if (row.dataProviderName === dataProvider) {
                                                        return (_jsx("tr", __assign({ className: "tbodycontent" }, { children: dateColumn.map(function (dt, dateIndex) {
                                                                var coData = row.dataLst.filter(function (dl, i) {
                                                                    return dl.monthAndYear === moment(new Date(dt)).format('MMYYYY');
                                                                });
                                                                var qty;
                                                                if (coData.length > 0) {
                                                                    qty = coData[0].quantity;
                                                                }
                                                                else {
                                                                    qty = null;
                                                                }
                                                                return (_jsx("td", { children: _jsxs("div", __assign({ className: "td-data" }, { children: [_jsx("input", { className: "edit-input", type: "text", value: qty === null ? 0 : qty, placeholder: qty === null ? 0 : qty, onChange: function (e) {
                                                                                    var _a;
                                                                                    handleInputChange([e.target.value], countrydata.countryId, row === null || row === void 0 ? void 0 : row.dataProviderName, [((_a = coData === null || coData === void 0 ? void 0 : coData[0]) === null || _a === void 0 ? void 0 : _a.monthAndYear) || moment(new Date(dt)).format('MMYYYY')]);
                                                                                }, onPaste: function (e) {
                                                                                    return handlePaste(e, dateIndex, countrydata.countryId, row === null || row === void 0 ? void 0 : row.dataProviderName);
                                                                                } }), !!coData.length && !!coData[0].isLockedInd && (_jsx("i", { className: "fa fa-lock baseline-lock", style: { color: '#fbce07' } }))] })) }, dt));
                                                            }) }), row + index));
                                                    }
                                                }) })] }) }), countrydata.countryId)
                                //)
                                );
                            }
                        }), _jsx("textarea", { className: "edit-textarea", placeholder: "Comments", defaultValue: comments, onBlur: textCommentForEdit })] }), clickedCountry), _jsx("div", __assign({ style: { overflowY: 'scroll', height: '660px', paddingBottom: '150px' } }, { children: dateColumn && data.length > 0 ? (data.map(function (countrydata) {
                    var countryInLoop = countrydata.countryId;
                    return (_jsx("div", __assign({ className: "mx-3" }, { children: _jsx("div", __assign({ className: "py-2" }, { children: _jsx(CollapseExpand, __assign({ title: countrydata.countryName }, { children: _jsx(ProviderRow, { countrydata: countrydata, editCurrentRow: editCurrentRow, dateColumn: dateColumn, countryInLoop: countryInLoop }) })) })) }), countrydata.countryId));
                })) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100" }, { children: _jsx("div", __assign({ className: "container", style: { marginTop: '5%' } }, { children: _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx(SvgLogo, {}), _jsx("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "Click on Apply to view refinery intake" }))] })) })) }))) }))] }));
};
export default DataLayout;
