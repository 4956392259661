import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Grid, GridColumn, GridToolbar, GridFilterCell } from '@progress/kendo-react-grid';
import { getSourceData, editSourceData, saveSourceData, deleteSourceData } from '../services/urlService';
// import { ExcelExport } from "@progress/kendo-react-excel-export";
import Spinner from './spinner';
// import ScrollbarWithSimpleBar from "simplebar";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { process } from '@progress/kendo-data-query';
import _ from 'lodash';
import CommandCell from './commandCell';
import { withWindowSizeListener } from 'react-window-size-listener';
import { toast } from 'react-toastify';
import GridDropDownCell from './gridDropDownCell';
import { connect } from 'react-redux';
import * as masterScreenActions from '../redux/actions/masterScreenActions';
import { bindActionCreators } from 'redux';
import './gridTemplate.css';
var moment = require('moment');
var options = { autoClose: 2000 };
var Msg = function (props) { return (_jsx(React.Fragment, { children: _jsx("ul", { children: props.list.map(function (value, i) {
            return _jsx("li", { children: props.error[value].error }, i);
        }) }) })); };
var CrossReferenceGridTemplate = /** @class */ (function (_super) {
    __extends(CrossReferenceGridTemplate, _super);
    function CrossReferenceGridTemplate(props) {
        var _this = _super.call(this, props) || this;
        _this.pageSize = 20;
        _this.getRandomInt = function (max) {
            return Math.floor(Math.random() * Math.floor(max));
        };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.CustomFilterCell = function (props) {
            return (_jsx(GridFilterCell, __assign({ filterType: "date" }, props, { operators: [
                    { operator: 'gt', text: 'From Date' },
                    { operator: 'lt', text: 'To Date' },
                ] })));
        };
        _this.dateFilterCell = function (props) {
            var onChange = function (event) {
                var hasValue = event.target.value !== '' && event.target.value !== null;
                props.onChange({
                    value: hasValue ? event.target.value : '',
                    operator: hasValue ? 'eq' : '',
                    syntheticEvent: event.syntheticEvent,
                });
            };
            var onClearButtonClick = function (event) {
                event.preventDefault();
                props.onChange({
                    value: '',
                    operator: '',
                    syntheticEvent: event,
                });
            };
            return (
            // <div className="k-filtercell">
            //   <DatePicker
            //     onChange={onChange}
            //     format={"dd/MMM/yyyy"}
            //     value={props.value || null}
            //     children={this.CustomFilterCell}
            //   />
            //   <button
            //     className="k-button k-button-icon k-clear-button-visible"
            //     title="Clear"
            //     onClick={onClearButtonClick}
            //   >
            //     <span className="k-icon k-i-filter-clear" />
            //   </button>
            // </div>
            _jsx(GridFilterCell, __assign({ filterType: "date" }, props, { operators: [
                    { operator: 'gte', text: 'From Date' },
                    { operator: 'lte', text: 'To Date' },
                ] })));
        };
        _this.loadForeginKeyData = function (value, key) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    this.props.actions.loadMasterScreenDataOnAction(value, value);
                    return [2 /*return*/, true];
                }
                catch (error) {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        }); };
        _this.pageChange = function (event) {
            _this.requestIfNeeded(event.page.skip);
            _this.setState({
                skip: event.page.skip,
            });
        };
        _this.loadingCell = function (tdElement, props) {
            if (props.dataItem[props.field] === undefined) {
                return (_jsx("td", { children: _jsx("span", { className: "k-icon k-i-loading" }) }));
            }
            return tdElement;
        };
        _this.dateFormatter = function (item) {
            if (item.recordEntryDttm !== null && item.recordEntryDttm) {
                var timeSplit = item.recordEntryDttm.split('.');
                item.recordEntryDttm = new Date(timeSplit[0]);
            }
            if (item.metaChangedDttm !== null && item.metaChangedDttm) {
                var timeSplit = item.metaChangedDttm.split('.');
                item.metaChangedDttm = new Date(timeSplit[0]);
            }
            if (item.metaCreatedDttm !== null && item.metaCreatedDttm) {
                var timeSplit = item.metaCreatedDttm.split('.');
                item.metaCreatedDttm = new Date(timeSplit[0]);
            }
            if (item.startDt !== null && item.startDt) {
                var timeSplit = item.startDt.split('.');
                item.startDt = new Date(timeSplit[0]);
            }
            if (item.endDt !== null && item.endDt) {
                var timeSplit = item.endDt.split('.');
                item.endDt = new Date(timeSplit[0]);
            }
            if (item.versionTerminationDt !== null && item.versionTerminationDt) {
                var timeSplit = item.versionTerminationDt.split('.');
                item.versionTerminationDt = new Date(timeSplit[0]);
            }
            if (item.versionEffectiveDt !== null && item.versionEffectiveDt) {
                var timeSplit = item.versionEffectiveDt.split('.');
                item.versionEffectiveDt = new Date(timeSplit[0]);
            }
            if (item.assetStartDt !== null && item.assetStartDt) {
                var timeSplit = item.assetStartDt.split('.');
                item.assetStartDt = new Date(timeSplit[0]);
            }
            if (item.assetCloseDt !== null && item.assetCloseDt) {
                var timeSplit = item.assetCloseDt.split('.');
                item.assetCloseDt = new Date(timeSplit[0]);
            }
            if (item.reportDt !== null && item.reportDt) {
                var timeSplit = item.reportDt.split('.');
                item.reportDt = new Date(timeSplit[0]);
            }
            if (item.assetUnitStartDt !== null && item.assetUnitStartDt) {
                var timeSplit = item.assetUnitStartDt.split('.');
                item.assetUnitStartDt = new Date(timeSplit[0]);
            }
            if (item.assetUnitCloseDt !== null && item.assetUnitCloseDt) {
                var timeSplit = item.assetUnitCloseDt.split('.');
                item.assetUnitCloseDt = new Date(timeSplit[0]);
            }
            if (item.subscriptionStartDt !== null && item.subscriptionStartDt) {
                var timeSplit = item.subscriptionStartDt.split('.');
                item.subscriptionStartDt = new Date(timeSplit[0]);
            }
            if (item.subscriptionEndDt !== null && item.subscriptionEndDt) {
                var timeSplit = item.subscriptionEndDt.split('.');
                item.subscriptionEndDt = new Date(timeSplit[0]);
            }
            return item;
        };
        _this.dataFormatter = function (items, dateFormat) {
            var formatedData = items.map(function (item) {
                if (_this.props.foreginKeyParams) {
                    _this.props.foreginKeyParams.forEach(function (value, i) {
                        if (typeof _this.props.masterData !== 'undefined') {
                            if (typeof _this.props.masterData.get(value.path) !== 'undefined') {
                                var index = void 0;
                                if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                    index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.foreignReplicaKey]; });
                                }
                                else {
                                    index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.key]; });
                                }
                                if (index !== -1) {
                                    if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                        item[value.foreignReplicaCol] = _this.props.masterData.get(value.path)[index][value.cdName];
                                        item[value.foreignReplicaColPath] = _this.props.masterData.get(value.path)[index];
                                    }
                                    else {
                                        item[value.cdName] = _this.props.masterData.get(value.path)[index][value.cdName];
                                        item[value.path] = _this.props.masterData.get(value.path)[index];
                                    }
                                }
                                else {
                                    item[value.path] = {};
                                }
                            }
                        }
                    });
                }
                if (dateFormat) {
                    _this.dateFormatter(item);
                }
                return item;
            });
            return formatedData;
        };
        _this.dataFormatterSingleItems = function (item, dateFormat) {
            // let formatedData = items.map(item => {
            if (_this.props.foreginKeyParams) {
                _this.props.foreginKeyParams.forEach(function (value, i) {
                    if (typeof _this.props.masterData !== 'undefined') {
                        if (typeof _this.props.masterData.get(value.path) !== 'undefined') {
                            var index = void 0;
                            if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.foreignReplicaKey]; });
                            }
                            else {
                                index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.key]; });
                            }
                            if (index !== -1) {
                                if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                    item[value.foreignReplicaCol] = _this.props.masterData.get(value.path)[index][value.cdName];
                                    item[value.foreignReplicaColPath] = _this.props.masterData.get(value.path)[index];
                                }
                                else {
                                    item[value.cdName] = _this.props.masterData.get(value.path)[index][value.cdName];
                                    item[value.path] = _this.props.masterData.get(value.path)[index];
                                }
                            }
                            else {
                                item[value.path] = {};
                            }
                        }
                    }
                });
            }
            if (dateFormat) {
                _this.dateFormatter(item);
            }
            return item;
            // });
            //return formatedData;
        };
        _this.prelimForeignKeyDataLoad = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.props.foreginKeyParams) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.actions.loadMasterScreenData(this.props.foreginKeyParams)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.dataload = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.requestData(0, this.props.loadPath);
                return [2 /*return*/];
            });
        }); };
        _this.componentDidMount = function (e) {
            try {
                _this.setState({
                    loader: !_this.state.loader,
                    randNum: _this.getRandom(),
                });
                _this.dataload();
            }
            catch (error) {
                _this.setState({ loader: !_this.state.loader });
            }
        };
        _this.commandCellWithProps = function (props) {
            return (_jsx(CommandCell, __assign({}, props, { result: _this.state.result, onSave: _this.save, onCancel: _this.cancel, onRemove: _this.remove, onUpdate: _this.update, onEnterEdit: _this.enterEdit, uniqueid: _this.state.uniqueid, uniquecode: _this.props.uniquecode })));
        };
        _this.enterInsert = function () {
            var currentStateData = _this.state.virtualScrollData;
            _.map(currentStateData, function (o) {
                if (typeof o.inEdit !== 'undefined' && o.inEdit) {
                    return (o.inEdit = false);
                }
            });
            var dataItem = {
                inEdit: true,
                Discontinued: false,
                events: 'add',
                randomEditInd: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            };
            var newproducts = currentStateData.slice();
            newproducts.unshift(dataItem);
            _this.setState({
                virtualScrollData: newproducts,
                addButton: !_this.state.addButton,
                editID: dataItem.id,
            });
            var currentTable = document.querySelector('.k-grid-table');
            currentTable.style.transform = 'translate(0px)';
            document.querySelector('.k-grid-table').scrollIntoView({ block: 'start' });
        };
        _this.enterEdit = function (dataItem) {
            var currentStateData = _this.state.virtualScrollData;
            _.map(currentStateData, function (o, i) {
                if (o && o.inEdit) {
                    return (o.inEdit = false);
                }
            });
            dataItem.randomEditInd = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            currentStateData = currentStateData.filter(function (obj) {
                return obj.events !== 'add';
            });
            _this.update(currentStateData, dataItem).inEdit = true;
            _this.setState({
                virtualScrollData: currentStateData,
                data: currentStateData.slice(),
                dataItemCustom: '',
                editID: dataItem.id,
            });
            if (!_this.state.addButton) {
                _this.setState({
                    addButton: !_this.state.addButton,
                });
            }
        };
        _this.save = function (dataItem) { return __awaiter(_this, void 0, void 0, function () {
            var errorToSHow, edit, errorToSHow, add;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(typeof dataItem.events === 'undefined')) return [3 /*break*/, 4];
                        if (!dataItem) return [3 /*break*/, 3];
                        errorToSHow = Object.keys(this.props.validation).filter(function (value, i) {
                            if (dataItem.hasOwnProperty(value) && _this.props.validation[value].required) {
                                if (!dataItem[value] || dataItem[value] === -1) {
                                    return _this.props.validation[value].error;
                                }
                            }
                            return false;
                        });
                        if (!(errorToSHow.length > 0)) return [3 /*break*/, 1];
                        toast.error(_jsx(Msg, { list: errorToSHow, error: this.props.validation }), options);
                        return [2 /*return*/, false];
                    case 1: return [4 /*yield*/, this.getEdit(dataItem, dataItem[this.props.uniqueid], this.state.path)];
                    case 2:
                        edit = _a.sent();
                        if (edit) {
                            this.setState({
                                virtualScrollData: this.state.virtualScrollData.slice(),
                                dataBackup: this.state.virtualScrollData.slice(),
                            });
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 7];
                    case 4:
                        if (!dataItem) return [3 /*break*/, 7];
                        errorToSHow = Object.keys(this.props.validation).filter(function (value, i) {
                            if (!dataItem.hasOwnProperty(value) && _this.props.validation[value].required) {
                                return _this.props.validation[value].error;
                            }
                            return false;
                        });
                        if (!(errorToSHow.length > 0)) return [3 /*break*/, 5];
                        toast.error(_jsx(Msg, { list: errorToSHow, error: this.props.validation }), options);
                        return [2 /*return*/, false];
                    case 5: return [4 /*yield*/, this.add(dataItem, this.state.path)];
                    case 6:
                        add = _a.sent();
                        if (add) {
                            delete dataItem.inEdit;
                            delete dataItem.Discontinued;
                            delete dataItem.events;
                            this.setState({
                                virtualScrollData: this.state.virtualScrollData.slice(),
                                dataBackup: this.state.virtualScrollData.slice(),
                            });
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.cancel = function (dataItem) {
            dataItem.inEdit = undefined;
            if (dataItem[_this.props.uniqueid]) {
                var originalItem = _this.state.dataBackup.find(function (p) { return p[_this.props.uniqueid] === dataItem[_this.props.uniqueid]; });
                delete originalItem.inEdit;
                delete originalItem.randomEditInd;
                _this.update(_this.state.virtualScrollData, originalItem);
            }
            else {
                _this.update(_this.state.virtualScrollData, dataItem, !dataItem[_this.props.uniqueid]);
            }
            if (!_this.state.addButton) {
                _this.setState({
                    addButton: !_this.state.addButton,
                });
            }
            _this.setState({
                data: _this.state.virtualScrollData.slice(),
            });
        };
        _this.remove = function (dataItem) {
            dataItem.inEdit = undefined;
            _this.update(_this.state.virtualScrollData, dataItem, true);
            _this.removeRow(dataItem[_this.props.uniqueid], _this.state.path);
            _this.setState({
                data: _this.state.virtualScrollData.slice(),
            });
        };
        _this.itemChange = function (event) {
            var value = event.value;
            var name = event.field;
            if (!name) {
                return;
            }
            var updatedData = _this.state.virtualScrollData.slice();
            var item = _this.update(updatedData, event.dataItem);
            item[name] = value;
            _this.setState({
                virtualScrollData: updatedData,
            });
        };
        _this.update = function (data, item, remove) {
            var _a;
            var updated;
            var index = data.findIndex(function (p) { return p === item || (item[_this.props.uniqueid] && p[_this.props.uniqueid] === item[_this.props.uniqueid]); });
            if (index >= 0) {
                updated = Object.assign({}, item);
                data[index] = updated;
            }
            else {
                var id_1 = 1;
                data.forEach(function (p) {
                    id_1 = Math.max(p[_this.props.uniqueid] + 1, id_1);
                });
                updated = Object.assign({}, item, (_a = {}, _a[_this.props.uniqueid] = id_1, _a));
                data.unshift(updated);
                index = 0;
            }
            if (remove) {
                return data.splice(index, 1)[0];
            }
            return data[index];
        };
        _this.removeRow = function (uniqueID, path) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, deleteSourceData(uniqueID, this.state.path)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.getEdit = function (dt, uniqueID, path) { return __awaiter(_this, void 0, void 0, function () {
            var returnData_1, stateData, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 9]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        Object.keys(dt).map(function (value, keys) {
                            if (dt[value] && Object.prototype.toString.call(dt[value]) === '[object Date]' && !isNaN(dt[value])) {
                                dt[value] = moment(dt[value]).format('YYYY-MM-DDT00:00:00');
                            }
                        });
                        if (!this.props.foreginKeyParams) return [3 /*break*/, 3];
                        this.props.foreginKeyParams.map(function (value, i) {
                            var foreignKey = value.key;
                            var foreignCd = value.cdName;
                            var foreignPath = value.path;
                            if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                dt[value.foreignReplicaKey] = dt[value.foreignReplicaCol];
                                if (typeof dt[value.foreignReplicaCol] === 'string') {
                                    dt[value.foreignReplicaKey] = dt[value.foreignReplicaColPath].id;
                                }
                                delete dt[value.foreignReplicaCol];
                                delete dt[value.foreignReplicaColPath];
                            }
                            else {
                                var currentChangedId = dt[value.cdName];
                                dt[value.key] = currentChangedId !== -1 ? currentChangedId : null;
                                if (typeof currentChangedId === 'string') {
                                    dt[value.key] = dt[foreignPath].id;
                                }
                                else {
                                    if (currentChangedId === -1) {
                                        dt[value.key] = null;
                                    }
                                }
                                delete dt[foreignCd];
                                delete dt[foreignPath];
                            }
                            return value;
                        });
                        return [4 /*yield*/, editSourceData(dt, uniqueID, path)];
                    case 2:
                        returnData_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, editSourceData(dt, uniqueID, path)];
                    case 4:
                        returnData_1 = _a.sent();
                        _a.label = 5;
                    case 5:
                        stateData = this.state.virtualScrollData;
                        stateData.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                            var mergedData, formatedData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(item.inEdit === true)) return [3 /*break*/, 2];
                                        mergedData = _.merge(item, returnData_1.data);
                                        delete mergedData.inEdit;
                                        return [4 /*yield*/, this.dataFormatterSingleItems(item, true)];
                                    case 1:
                                        formatedData = _a.sent();
                                        return [2 /*return*/, formatedData];
                                    case 2: return [2 /*return*/, item];
                                }
                            });
                        }); });
                        this.setState({
                            virtualScrollData: stateData,
                            dataBackup: stateData,
                        });
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 7:
                        error_2 = _a.sent();
                        if (this.props.foreginKeyParams) {
                            this.props.foreginKeyParams.map(function (value, i) {
                                var foreignKey = value.key;
                                var foreignCd = value.cdName;
                                var currentChangedId = dt[foreignKey];
                                delete dt[foreignKey];
                                dt[foreignCd] = currentChangedId;
                                return value;
                            });
                        }
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 8:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 9: return [2 /*return*/];
                }
            });
        }); };
        _this.add = function (dt, path) { return __awaiter(_this, void 0, void 0, function () {
            var returnData_2, stateData, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 9]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        Object.keys(dt).map(function (value, keys) {
                            if (dt[value] && Object.prototype.toString.call(dt[value]) === '[object Date]' && !isNaN(dt[value])) {
                                dt[value] = moment(dt[value]).format('YYYY-MM-DDT00:00:00');
                            }
                        });
                        if (!this.props.foreginKeyParams) return [3 /*break*/, 3];
                        this.props.foreginKeyParams.map(function (value, i) {
                            var foreignKey = value.key;
                            var foreignCd = value.cdName;
                            var foreignPath = value.path;
                            var currentChangedId = dt[value.cdName];
                            //If block for replica (two foriegnkey with same data) screen and
                            //Else block for non-replica that is normal screen
                            if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                var changedID = dt[value.foreignReplicaCol];
                                delete dt[value.foreignReplicaCol];
                                delete dt[value.foreignReplicaColPath];
                                dt[value.foreignReplicaKey] = changedID;
                            }
                            else {
                                delete dt[foreignCd];
                                delete dt[foreignPath];
                                dt[foreignKey] = currentChangedId !== -1 ? currentChangedId : null;
                            }
                            return value;
                        });
                        return [4 /*yield*/, saveSourceData(dt, path)];
                    case 2:
                        returnData_2 = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, saveSourceData(dt, path)];
                    case 4:
                        returnData_2 = _a.sent();
                        _a.label = 5;
                    case 5:
                        stateData = this.state.virtualScrollData;
                        stateData.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                            var mergedData, formatedData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(item.events === 'add')) return [3 /*break*/, 2];
                                        mergedData = _.merge(item, returnData_2.data);
                                        return [4 /*yield*/, this.dataFormatterSingleItems(item, true)];
                                    case 1:
                                        formatedData = _a.sent();
                                        return [2 /*return*/, formatedData];
                                    case 2: return [2 /*return*/, item];
                                }
                            });
                        }); });
                        this.setState({
                            virtualScrollData: stateData,
                            dataBackup: stateData,
                            addButton: !this.state.addButton,
                        });
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 7:
                        error_3 = _a.sent();
                        if (this.props.foreginKeyParams) {
                            this.props.foreginKeyParams.map(function (value, i) {
                                var foreignKey = value.key;
                                var foreignCd = value.cdName;
                                var currentChangedId = dt[foreignKey];
                                delete dt[foreignKey];
                                dt[foreignCd] = currentChangedId;
                                return value;
                            });
                        }
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 8:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 9: return [2 /*return*/];
                }
            });
        }); };
        //metabox enable and disable
        _this.checkboxChange = function (_a) {
            var checked = _a.target.checked;
            if (_this.state.showMetaColumn) {
                _this.setState({
                    showMetaColumn: !_this.state.showMetaColumn,
                    singleMode: !_this.state.singleMode,
                    minWidth: '',
                    // scroll: 'none'
                });
            }
            else {
                _this.setState({
                    showMetaColumn: !_this.state.showMetaColumn,
                    singleMode: !_this.state.singleMode,
                    minWidth: '200px',
                    // scroll: 'virtual'
                });
            }
            return;
        };
        _this.cellWithDropDowns = function (props) {
            return (_jsx(GridDropDownCell, __assign({ newColumnWidth: _this.state.newColumnWidth, resizeParam: _this.state.resizeParam }, props, { foreginKeyParams: _this.props.foreginKeyParams, yesNoData: _this.props.yesNoData, foreignReplicaScreen: _this.props.foreignReplicaScreen })));
        };
        _this.cancelCurrentChanges = function (e) {
            var cancelChangesData = _this.state.virtualScrollData;
            cancelChangesData = cancelChangesData.filter(function (item, key) {
                if (item.inEdit) {
                    item.inEdit = undefined;
                    if (typeof item.events !== 'undefined') {
                        return;
                    }
                    else {
                        return item;
                    }
                }
                else {
                    return item;
                }
                return item;
            });
            _this.setState({ virtualScrollData: cancelChangesData });
        };
        _this.dataStateChange = function (event) {
            _this.setState(_this.createAppState(event));
        };
        _this.export = function () {
            _this._export.save();
        };
        //Callback on Grid column resize
        _this.onColumnResize = function (event) {
            if (event !== undefined) {
                if (event.columns[event.index].field !== undefined && event.end) {
                    _this.setState({
                        resizeParam: event.columns[event.index].field,
                        newColumnWidth: event.newWidth,
                    });
                }
            }
        };
        _this.isEditable = function (editableColumn, editableValue, field) {
            var isEditable = false;
            var index;
            var editableItem;
            var data = _this.state.virtualScrollData;
            data.map(function (item, i) {
                if (item.id === _this.state.editID && item[editableColumn] == editableValue) {
                    item[field] = item[field] !== null && item[field] > -1 ? item[field] : _this.state.dataBackup[i][field];
                    isEditable = true;
                }
                else {
                    if (item.inEdit === true) {
                        index = i;
                        item[field] = null;
                        editableItem = item;
                    }
                }
            });
            if (index > -1) {
                data[index] = editableItem;
            }
            return isEditable;
        };
        _this.state = {
            data: [],
            dataBackup: [],
            loader: false,
            events: undefined,
            showMetaColumn: true,
            singleMode: true,
            dataState: {
                skip: 0,
                take: 20,
                filter: {
                    logic: 'and',
                    filters: [],
                },
            },
            minWidth: '200px',
            width: '',
            height: '',
            path: _this.props.path,
            uniqueid: _this.props.uniqueid,
            column: _this.props.column,
            foreginKeyData: new Map(),
            dataItemCustom: '',
            virtualScrollData: [],
            total: 0,
            skip: 0,
            addButton: true,
            resizeParam: undefined,
            newColumnWidth: undefined,
            randNum: '',
            scroll: 'virtual',
            editID: '',
        };
        //initializing virtual scroll
        _this.prelimForeignKeyDataLoad();
        _this.createAppState({
            data: {},
        });
        //React.renderComponent(GridDropDownCell);
        _this.checkboxChange = _this.checkboxChange.bind(_this);
        _this.cancelCurrentChanges = _this.cancelCurrentChanges.bind(_this);
        _this.enterInsert = _this.enterInsert.bind(_this);
        _this.itemChange = _this.itemChange.bind(_this);
        _this.enterEdit = _this.enterEdit.bind(_this);
        //this.save = this.save.bind(this);
        _this.cancel = _this.cancel.bind(_this);
        _this.remove = _this.remove.bind(_this);
        return _this;
    }
    CrossReferenceGridTemplate.prototype.requestIfNeeded = function (skip) {
        for (var i = skip; i < skip + this.pageSize && i < this.state.virtualScrollData.length; i++) {
            if (this.state.virtualScrollData[i].id === undefined) {
                // request data only if not already fetched
                this.requestData(skip);
                return;
            }
        }
    };
    CrossReferenceGridTemplate.prototype.requestData = function (skipParameter, path) {
        var _this = this;
        if (this.requestInProgress) {
            return;
        }
        var skip = Math.max(skipParameter - this.pageSize, 0); //request the prev page as well
        getSourceData(path)
            .then(function (response) { return response; })
            .then(function (response) {
            try {
                if (typeof response !== 'undefined') {
                    var total = response['data'].length;
                    var data = response['data'];
                    if (data !== 'empty') {
                        var virtualScrollData_1 = _this.state.virtualScrollData.length === total
                            ? _this.state.virtualScrollData
                            : new Array(total).fill().map(function (e, i) { return ({ Index: i }); });
                        data.forEach(function (order, i) {
                            virtualScrollData_1[i + skip] = __assign({ Index: i + skip }, order);
                        });
                        var mapData = _this.dataFormatter(virtualScrollData_1, true);
                        _this.requestIfNeeded(_this.state.skip);
                        _this.setState({
                            virtualScrollData: mapData,
                            dataBackup: mapData,
                            total: total,
                        });
                        setTimeout(function () {
                            if (_this.state.loader) {
                                _this.setState({ loader: !_this.state.loader });
                            }
                        }, 2000);
                    }
                    else {
                        _this.setState({
                            virtualScrollData: [],
                            dataBackup: [],
                            total: 0,
                        });
                    }
                }
                else {
                    _this.setState({
                        loader: !_this.state.loader,
                    });
                    _this.setState({
                        virtualScrollData: [],
                        dataBackup: [],
                        total: 0,
                    });
                }
            }
            catch (error) {
                _this.setState({
                    loader: !_this.state.loader,
                });
                return false;
            }
        });
    };
    CrossReferenceGridTemplate.prototype.getSnapshotBeforeUpdate = function (prevProps) {
        if (typeof this.props.masterData !== 'undefined') {
            return { notifyRequired: prevProps.masterData !== this.props.masterData };
        }
        return null;
    };
    CrossReferenceGridTemplate.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (snapshot !== null) {
            if (snapshot.notifyRequired) {
                if (this.props.masterData.size <= this.props.foreginKeyParams.length) {
                    var formatedDataOnUpdate = this.dataFormatter(this.state.virtualScrollData, false);
                    this.setState({
                        randNum: this.getRandom(),
                        virtualScrollData: formatedDataOnUpdate,
                    });
                }
            }
        }
    };
    CrossReferenceGridTemplate.prototype.convertDateToUTC = function (date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 0, 0);
    };
    CrossReferenceGridTemplate.prototype.createAppState = function (event) {
        return {
            result: process(this.state.virtualScrollData, event.data),
            dataState: event.data,
        };
    };
    CrossReferenceGridTemplate.prototype.render = function () {
        var _this = this;
        return (_jsxs(_Fragment, { children: [this.state.loader && _jsx(Spinner, {}), _jsx("div", __assign({ id: "gridTemplateListings" }, { children: _jsxs(Grid, __assign({ style: { height: this.props.windowSize.windowHeight - 140 }, rowHeight: 40, onItemChange: this.itemChange, editField: "inEdit", reorderable: true, resizable: true, sortable: true, filterable: true, scrollable: this.state.scroll, data: process(this.state.virtualScrollData, this.state.dataState) }, this.state.dataState, { onDataStateChange: function (event) { return _this.setState({ dataState: event.data }); }, onColumnResize: this.onColumnResize }, { children: [_jsxs(GridToolbar, { children: [_jsx("div", __assign({ className: "pull-left ml-2" }, { children: _jsx(Tooltip, __assign({ openDelay: 10, position: "bottom", anchorElement: "target" }, { children: this.state.addButton && (_jsx("button", __assign({ look: "bare", onClick: this.enterInsert, className: "k-button bg-transparent border border-0 p-1" }, { children: _jsx("span", { className: "k-icon k-i-plus-outline", style: { fontSize: 25, color: '#7f7f7f' }, title: "Add new item to the grid" }) }))) })) })), _jsx("div", __assign({ className: "pull-right" }, { children: _jsx(Tooltip, { openDelay: 10, position: "bottom", anchorElement: "target" }) })), _jsxs("div", __assign({ className: "pull-right p-2" }, { children: ["Total Data: ", this.state.virtualScrollData.length] })), _jsxs("div", __assign({ className: "pull-left pl-3" }, { children: [_jsx("input", { id: "metaData", type: "checkbox", checked: this.state.singleMode, onChange: this.checkboxChange, className: "k-checkbox" }), _jsx("label", __assign({ htmlFor: "metaData", className: "k-checkbox-label m-2" }, { children: "Enable Metadata" }))] }))] }), _jsx(GridColumn, { width: "130px", cell: this.commandCellWithProps, filterable: false, resizable: false, reorderable: false, locked: true }), this.props.column.map(function (column, index) {
                                if (column.type !== 'meta' || (column.type === 'meta' && _this.state.showMetaColumn)) {
                                    var columnKey = Object.keys(column);
                                    var filterCell = column.filter === 'date' ? _this.dateFilterCell : '';
                                    var path = columnKey.indexOf('urlEndPoint') !== -1 ? column.urlEndPoint : '';
                                    var conditionalEditing = columnKey.indexOf('conditionalEditing') !== -1
                                        ? _this.isEditable(column.conditionalEditingColumn, column.conditionalEditingValue, column.field)
                                        : column.type !== 'meta'
                                            ? true
                                            : false;
                                    var Cd = columnKey.indexOf('referenceCd') !== -1 ? column.referenceCd : '';
                                    var useId = columnKey.indexOf('useId') !== -1 ? 'useId' : 'noUseId';
                                    var replica = columnKey.indexOf('replicaPath') !== -1 ? column.replicaPath : '';
                                    var cell = column.type === 'foreignKeyConstraint' || column.type === 'yesNo' ? _this.cellWithDropDowns : '';
                                    var Cdyn = columnKey.indexOf('yn') !== -1 ? column.yn : '';
                                    var ynCol = columnKey.indexOf('ynRefer') !== -1 ? column.ynRefer : '';
                                    var filterInd = columnKey.indexOf('filterIndicator') !== -1 ? column.filterIndicator : 'noFilterInd';
                                    var loadDpendency = columnKey.indexOf('loadDependecy') != -1 ? column.loadDependecy : 'noDependency';
                                    var loadDpendent = columnKey.indexOf('loadDependent') != -1 ? column.loadDependent : 'noDependent';
                                    var loadField = columnKey.indexOf('loadField') != -1 ? column.loadField : 'noLoadField';
                                    return (_jsx(GridColumn, __assign({ className: path +
                                            '-' +
                                            Cd +
                                            '-' +
                                            Cdyn +
                                            '-' +
                                            ynCol +
                                            '-' +
                                            replica +
                                            '-' +
                                            filterInd +
                                            '-' +
                                            loadDpendency +
                                            '-' +
                                            loadDpendent +
                                            '-' +
                                            loadField +
                                            '-' +
                                            useId }, column, { cell: cell, path: path, filterCell: filterCell, width: _this.state.minWidth, editable: conditionalEditing }), index));
                                }
                                return true;
                            })] }), this.state.randNum) }))] }));
    };
    return CrossReferenceGridTemplate;
}(Component));
function mapStateToProps(state) {
    return {
        masterData: state.masterScreenReducer.masterData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(masterScreenActions, dispatch),
    };
}
export default withWindowSizeListener(connect(mapStateToProps, mapDispatchToProps)(CrossReferenceGridTemplate));
