import { __assign } from "tslib";
export var SELECT_ITEM_PLACEHOLDER = 'Select';
export var LOADING_PLACEHOLDER = 'Loading';
export var getFormFieldState = function (errorMessage) {
    return !!errorMessage ? 'error' : 'default';
};
export var resolveOnSelectChange = function (key, form, units, arrayField, index) { return function (value) {
    if (arrayField && index !== undefined) {
        form.setValue("".concat(key, ".").concat(index, ".").concat(arrayField), value);
        return;
    }
    if (key === 'asset') {
        form.reset(__assign(__assign({}, form.getValues()), { units: [], asset: undefined }));
    }
    if (Array.isArray(value) && value.includes(0)) {
        if (form.watch('units') && form.watch('units').length === units.length - 1) {
            form.setValue(key, []);
            return;
        }
        var all = units.map(function (unit) { return unit.value; }).filter(function (value) { return value !== 0; });
        form.setValue(key, all);
        form.clearErrors(key);
        return;
    }
    form.setValue(key, value);
    form.clearErrors(key);
}; };
