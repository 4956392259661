import { ReactElement, useRef } from 'react';

import classNames from 'classnames';
import RcDrawer from 'rc-drawer';

import { DRAWER_CLASS } from '../../utils/constants';
import Button from '../Button/Button';
import { ChevronLeft } from '../Icon/components';
import CrossIcon from '../Icon/components/Cross';
import * as Styled from './Drawer.styles';
import { IDrawerProps } from './Drawer.types';
import { Sizes, Variants, Spacings, BgColors, Alignments } from '../../types/types';
import { getElevation } from './utils/drawerUtils';

const DrawerHoc = ({ className, rootClassName, ...props }: IDrawerProps): ReactElement => (
  <RcDrawer rootClassName={classNames(rootClassName, className)} {...props} />
);

const Drawer = ({
  children,
  className,
  size = Sizes.Medium,
  header,
  actionsAlignment,
  sticky = false,
  elevation,
  onClose,
  closeButton = true,
  backButton = false,
  padding = Spacings.Tight,
  bodyPadding = true,
  bodyBgColor = BgColors.Surface,
  borders = true,
  actions,
  getContainer,
  maskMotion = {
    motionName: 'mask-motion',
    motionAppear: true,
    motionEnter: true,
    motionLeave: true,
    motionDeadline: 300,
  },
  motion = (placement) => ({
    motionName: `panel-motion-${placement}`,
    motionAppear: true,
    motionEnter: true,
    motionLeave: true,
    motionDeadline: 300,
  }),
  open = false,
  footerAlignment,
  float,
  ...rest
}: IDrawerProps): ReactElement => {
  const cachedActiveElement = useRef<HTMLElement | null>(null);

  const innerElevation = getElevation(elevation, float);
  const innerActionsAlignment = actionsAlignment || footerAlignment || Alignments.Right;

  if (open && document.activeElement && !cachedActiveElement.current) {
    cachedActiveElement.current = document.activeElement as HTMLElement;
  }

  if (!open && cachedActiveElement.current) {
    cachedActiveElement.current.focus();
    cachedActiveElement.current = null;
  }

  return (
    <Styled.Drawer
      as={DrawerHoc}
      className={`${className} this-new-class-name`}
      prefixCls={DRAWER_CLASS}
      onClose={onClose}
      getContainer={getContainer}
      motion={motion}
      maskMotion={maskMotion}
      sticky={sticky}
      $elevation={innerElevation}
      autoFocus
      open={open}
      {...rest}
    >
      {header && (
        <Styled.DrawerHeader
          backButton={backButton}
          closeButton={closeButton}
          size={size}
          padding={padding}
          borders={borders}
        >
          {backButton ? (
            <Button
              icon={<ChevronLeft />}
              iconOnly
              variant={Variants.Transparent}
              size={Sizes.Large}
              onClick={onClose}
              aria-label="Back"
            />
          ) : null}
          <Styled.DrawerHeaderContent>{header}</Styled.DrawerHeaderContent>
          {closeButton ? (
            <Button
              icon={<CrossIcon />}
              iconOnly
              variant={Variants.Transparent}
              size={Sizes.Large}
              onClick={onClose}
              aria-label="Close"
            />
          ) : null}
        </Styled.DrawerHeader>
      )}

      <Styled.DrawerContent
        sticky={sticky}
        size={size}
        padding={padding}
        bodyPadding={bodyPadding}
        bodyBgColor={bodyBgColor}
      >
        {children}
      </Styled.DrawerContent>

      {actions && (
        <Styled.DrawerFooter size={size} actionsAlignment={innerActionsAlignment} padding={padding} borders={borders}>
          {actions?.length &&
            actions.map(({ label, action, props }) => (
              <Button size={size} onClick={action} {...props} key={label}>
                {label}
              </Button>
            ))}
        </Styled.DrawerFooter>
      )}
    </Styled.Drawer>
  );
};

export default Drawer;
