import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { Flexbox, FormField, Heading, SingleDatePicker, TextInput } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
import { Select } from 'components/select';
import { useGetAssetClassesQuery } from 'common/ducks/maintenanceApis';
import { newMapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
import { useGetOperationalStatusTypesQuery } from 'common/ducks/filtersApis';
export var StyledSingleDatePicker = styled(SingleDatePicker)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  a {\n    background-color: transparent !important;\n    border-color: ", " !important;\n  }\n"], ["\n  a {\n    background-color: transparent !important;\n    border-color: ", " !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
});
export var AssetDetailsSection = function (_a) {
    var _b, _c;
    var form = _a.form, open = _a.open;
    var watch = form.watch, setValue = form.setValue, register = form.register, errors = form.formState.errors;
    var _d = useGetAssetClassesQuery(undefined, {
        skip: !open,
    }), _e = _d.data, assetClasses = _e === void 0 ? [] : _e, isFetchingAssetClasses = _d.isFetching;
    var assetClassesOptions = assetClasses.map(newMapSelectData('assetClassDesc', 'id'));
    var _f = useGetOperationalStatusTypesQuery(undefined, {
        skip: !open,
    }), _g = _f.data, statusTypes = _g === void 0 ? [] : _g, isFetchingStatusTypes = _f.isFetching;
    var statusTypesOptions = statusTypes
        .map(newMapSelectData('operationalStatusTypeCd', 'id'))
        .sort(sortSelectData);
    return (_jsxs("section", { children: [_jsx(Heading, __assign({ bold: true, color: "light", level: 5 }, { children: "ASSET DETAILS" })), _jsx(FormField, __assign({ label: "Asset name", size: "small", mandatory: true, bottomLeftHelper: {
                    content: (_b = errors.assetName) === null || _b === void 0 ? void 0 : _b.message,
                    sentiment: 'negative',
                } }, { children: _jsx(TextInput, __assign({}, register('assetName', { required: 'Enter the asset name' }), { size: "small", value: watch('assetName') })) })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "Asset class", size: "small", mandatory: true, bottomLeftHelper: {
                            content: (_c = errors.assetClass) === null || _c === void 0 ? void 0 : _c.message,
                            sentiment: 'negative',
                        } }, { children: _jsx(Select, __assign({}, register('assetClass', {
                            required: 'Select an asset class',
                        }), { loading: isFetchingAssetClasses, placeholder: "Select", data: assetClassesOptions, size: "small", value: watch('assetClass'), onChange: resolveOnSelectChange('assetClass', form, []), allowClear: false })) })), _jsx(FormField, __assign({ label: "Status", size: "small" }, { children: _jsx(Select, __assign({}, register('status'), { loading: isFetchingStatusTypes, value: watch('status'), placeholder: "Select", data: statusTypesOptions, size: "small", onChange: resolveOnSelectChange('status', form, []) })) }))] })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "Start date", size: "small" }, { children: _jsx(StyledSingleDatePicker, __assign({}, register('startDate'), { value: watch('startDate') ? dayjs(watch('startDate')) : null, placeholder: "Select", size: "small", elevation: true, format: "DD MMM YYYY", onChange: function (date) {
                                return date ? setValue('startDate', date.format()) : setValue('startDate', undefined);
                            } })) })), _jsx(FormField, __assign({ label: "Close date", size: "small" }, { children: _jsx(StyledSingleDatePicker, __assign({}, register('closeDate'), { value: watch('closeDate') ? dayjs(watch('closeDate')) : null, placeholder: "Select", showToday: false, size: "small", elevation: true, format: "YYYY-MM-DD", onChange: function (date) {
                                return date ? setValue('closeDate', date.format()) : setValue('closeDate', undefined);
                            }, disabledDate: function (value) {
                                if (watch('startDate')) {
                                    return value.isSameOrBefore(watch('startDate'));
                                }
                                return false;
                            } })) }))] }))] }));
};
var templateObject_1;
