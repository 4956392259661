import styled, { css } from 'styled-components';

import Button from '../Button/Button';
import { textInputConsts, textInputWrapperConsts } from './TextInput.consts';
import { ITextInputProps, TextInputSize } from './TextInput.types';
import { Sizes } from '../../types/types';

type TextInputProps = Pick<ITextInputProps, 'disabled'> & {
  inputSize: TextInputSize;
};

type TextInputAffixProps = Pick<ITextInputProps, 'size' | 'disabled'> & {
  transparent?: boolean;
};

const TextInput = styled.input<TextInputProps & { transparentPrefix?: boolean; transparentSuffix?: boolean }>`
  ${({ disabled, inputSize, transparentPrefix, transparentSuffix, theme }) => css`
    border: none;
    width: 100%;
    color: ${theme.text.onSurface.strong};
    background-color: ${theme.background.surface};
    transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: ${textInputConsts.padding[inputSize]};

    ${transparentPrefix &&
    css`
      padding-left: ${textInputConsts.paddingWithTransparentAffix[inputSize]};
    `}

    ${transparentSuffix &&
    css`
      padding-right: ${textInputConsts.paddingWithTransparentAffix[inputSize]};
    `}

    ${(inputSize === Sizes.Small || inputSize === Sizes.Medium) && theme.paragraph.small.normal};
    ${inputSize === Sizes.Large && theme.paragraph.medium.normal};

    &::placeholder {
      color: ${disabled ? theme.text.onSurface.inactive : theme.text.onSurface.subtle};
      font-style: italic;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
      border-color: ${theme.border.subtle};
      cursor: not-allowed;
    }
  `}
`;

const Affix = styled.div<TextInputAffixProps & { type: 'prefix' | 'suffix' }>`
  ${({ size = Sizes.Medium, transparent = false, disabled, type, theme }) => css`
    ${theme.label[size === Sizes.Small ? Sizes.Medium : size].bold};
    display: flex;
    align-items: center;
    padding: 0 ${textInputWrapperConsts.affixPadding[size]};
    background: ${transparent ? 'transparent' : theme.background.section};
    color: ${theme.text.onSurface.subtle};

    ${type === 'prefix' &&
    transparent &&
    css`
      padding-right: unset;
    `}

    ${type === 'suffix' &&
    transparent &&
    css`
      padding-left: unset;
    `}

    ${disabled &&
    css`
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
    `}

    svg {
      width: ${textInputWrapperConsts.iconSize[size]};
      height: ${textInputWrapperConsts.iconSize[size]};
    }
  `}
`;

export const ClearButton = styled(Button)`
  margin-top: -1px;
  margin-bottom: -1px;
`;

export { TextInput, Affix };
