import { composeSortDescriptors } from '@progress/kendo-data-query';
import { func } from 'prop-types';
export var errorMessage = [
    {
        commonError: 'Error in connections, Please contact server Admin.',
        corsIssue: 'Error in connections , It could be wrong network or server may be down.',
        badRequest: 'Not a Valid Request , Validation or duplication error ',
        resourceUriChange: 'Resource changes please contact admin',
        unAuthorized: 'Not Authorized to make request',
        notFound: 'Requested resource not found',
        timeOut: 'Request Timeout',
        put: {
            success: 'Current record edited successfully. ',
            warning: 'Error occurred, Please contact Admin.',
            uniqueIssue: 'Please check duplicate entry.',
            notFound: 'Not found issue, Please contact Admin.',
            serverError: 'Failed to update due to some server error',
        },
        post: {
            success: 'New record added successfully.',
            warning: 'Error occurred, Please contact Admin.',
            uniqueIssue: 'Please check duplicate entry.',
            notFound: 'Not found issue, Please contact Admin.',
            serverError: 'Failed to save due to some server error',
        },
        get: {
            success: 'Screen data loaded successfully.',
            warning: 'Error occurred, Please contact Admin.',
            notFound: 'Not found issue, Please contact Admin.',
            serverError: 'Failed to fetch due to some server error',
        },
        delete: {
            success: 'Selected record deleted successfully.',
            warning: 'Error occurred, Please contact Admin.',
            notFound: 'Not found issue, Please contact Admin.',
            serverError: 'Failed to remove due to some server error',
        },
    },
];
//Function to read Error format coming from Back end side
//It will return field and respective error as a pair
export function getDetailedError(errorData) {
    var errorList = new Map();
    var validjson = JSON.stringify(errorData);
    var jsonData = JSON.parse(validjson);
    var keys = Object.keys(jsonData.data.errors);
    if (keys.length > 0) {
        keys.map(function (key, index) {
            var combinedError = getCombinedError(jsonData, key);
            errorList.set(key.replace(/["']/g, '').replace(/_/g, ' '), combinedError);
        });
    }
    return errorList;
}
//Combining error for each field - it may happen
//there could be many error under one field/column
function getCombinedError(jsonData, key) {
    var errorMessage = '';
    if (jsonData.data.errors[key].length > 1) {
        jsonData.data.errors[key].map(function (item, index) {
            errorMessage = errorMessage + ' ' + item;
        });
    }
    else {
        errorMessage = jsonData.data.errors[key][0];
    }
    return errorMessage;
}
