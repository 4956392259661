import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { maintenanceDailySummarySlice } from 'pages/maintenance-daily-summary/ducks/slice';
import { emptySplitApi } from 'common/ducks/emptySplitApi';
import { maintenanceEventSummarySlice } from 'pages/maintenance-event-summary/ducks/slice';
import { capacitySlice } from 'pages/capacity/ducks/slice';
export var createStore = function () {
    var _a;
    return configureStore({
        reducer: (_a = {},
            _a[emptySplitApi.reducerPath] = emptySplitApi.reducer,
            _a.maintenanceDailySummary = maintenanceDailySummarySlice.reducer,
            _a.maintenanceEventSummary = maintenanceEventSummarySlice.reducer,
            _a.capacity = capacitySlice.reducer,
            _a),
        middleware: function (getDefaultMiddle) { return getDefaultMiddle().concat(emptySplitApi.middleware); },
        devTools: true,
    });
};
export var store = createStore();
setupListeners(store.dispatch);
