import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import TotalCapacityAccordion from './tools/totalCapacityAccordion';
import { getSourceFilter } from '../../../../../services/urlService';
import Spinner from '../../../../../../src/commons/spinner.jsx';
import SvgLogo from '../../../../../commons/tools/svgLogo';
import { If } from 'rc-if-else';
var UnitDataGrid = /** @class */ (function (_super) {
    __extends(UnitDataGrid, _super);
    function UnitDataGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.componentDidUpdate = function () {
            if (_this.props.data === null) {
                _this.accordionRefs = [];
            }
        };
        _this.setButtonGroupState = function (assetKey, id) {
            var bgs = __assign({}, _this.state.buttonGroupState);
            bgs[assetKey] = id;
            _this.setState({
                buttonGroupState: bgs,
            });
        };
        _this.setRef = function (ref, id) {
            if (ref) {
                var index = _this.accordionRefs.findIndex(function (x) {
                    return x[id];
                });
                if (ref && index === -1) {
                    var assetRefObj = {};
                    assetRefObj[id] = ref;
                    _this.accordionRefs.push(assetRefObj);
                }
                else {
                    var assetRefObj = {};
                    assetRefObj[id] = ref;
                    _this.accordionRefs[index] = assetRefObj;
                }
            }
        };
        _this.refreshGridFromAddEdit = function (assetId) {
            var index = _this.props.data.findIndex(function (x) { return x.groupId === assetId; });
            if (index > -1) {
                _this.accordionRefs[index][assetId].setStateRefreshOnUpdate();
            }
        };
        _this.refreshBlockOfIndex = function (index, assetId) {
            _this.accordionRefs[index][assetId].setStateRefreshOnUpdate();
        };
        _this.state = {
            unitTpeOn: false,
            subUnitOn: false,
            unitOn: true,
            currentRandKey: '',
            totalCapacityAssetUnit: [],
            loading: false,
            buttonGroupState: {},
        };
        _this.currentView = 3;
        _this.accordionRefs = [];
        return _this;
    }
    UnitDataGrid.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (nextProps.assetFilterParams !== this.props.assetFilterParams || nextProps.baseProvider !== null) {
            return true;
        }
        else {
            return false;
        }
    };
    UnitDataGrid.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: this.props.data.length > 0 ? (this.props.data.map(function (parent, i) {
                var randNum = _this.getRandom();
                return (_jsx(TotalCapacityAccordion, { ref: function (ref) { return _this.setRef(ref, parent.groupId); }, index: i, identifier: randNum, title: parent.groupName, accordionKey: parent.groupId, data: parent, onCapacitySelect: _this.props.onCapacitySelect, assetFilterParams: _this.props.assetFilterParams, assetKey: parent.groupId, limitForHighlight: _this.props.limitForHighlight, baseProvider: _this.props.baseProvider, setButtonGroupState: _this.setButtonGroupState, buttonGroupState: _this.state.buttonGroupState[parent.groupId] }, randNum));
            })) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No data available, Please reselect filters." }))] })) }))) }, this.state.currentRandKey ? this.getRandom : this.state.currentRandKey));
    };
    return UnitDataGrid;
}(Component));
export default UnitDataGrid;
