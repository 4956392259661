import { __assign, __makeTemplateObject, __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-top: 16px;\n\n  label {\n    margin-bottom: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-top: 16px;\n\n  label {\n    margin-bottom: 0;\n  }\n"])));
export var StyledFormContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: calc(100% - 49px);\n  overflow-y: auto;\n  padding: 0 1px;\n  overflow: hidden;\n\n  section {\n    padding: 16px 0 16px 0;\n    border-bottom: 1px solid ", ";\n  }\n\n  section:first-child {\n    padding: 2px 0 16px 0;\n    border-bottom: 1px solid ", ";\n  }\n\n  section:last-child {\n    border-bottom: 0;\n  }\n\n  h1 {\n    margin-bottom: 16px;\n  }\n"], ["\n  height: calc(100% - 49px);\n  overflow-y: auto;\n  padding: 0 1px;\n  overflow: hidden;\n\n  section {\n    padding: 16px 0 16px 0;\n    border-bottom: 1px solid ", ";\n  }\n\n  section:first-child {\n    padding: 2px 0 16px 0;\n    border-bottom: 1px solid ", ";\n  }\n\n  section:last-child {\n    border-bottom: 0;\n  }\n\n  h1 {\n    margin-bottom: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
});
export var StyledFooter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  padding-bottom: 8px;\n  button {\n    margin: 16px 0 0 16px;\n  }\n"], ["\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  padding-bottom: 8px;\n  button {\n    margin: 16px 0 0 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.subtle;
});
export var BaseDrawerForm = function (_a) {
    var children = _a.children, footer = _a.footer, rest = __rest(_a, ["children", "footer"]);
    return (_jsxs(StyledForm, __assign({}, rest, { children: [_jsx(StyledFormContent, { children: children }), !!footer && _jsx(StyledFooter, { children: footer })] })));
};
BaseDrawerForm.Footer = StyledFooter;
var templateObject_1, templateObject_2, templateObject_3;
