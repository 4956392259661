import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function assetRelationshipType(props) {
    var validationParams = {
        assetRelationshipTypeCd: 'Enter a unique Asset Relationship Type Cd',
    };
    var columnParams = [
        {
            field: 'assetRelationshipTypeCd',
            title: 'Asset Relationship Type Cd',
        },
        {
            field: 'assetRelationshipTypeDesc',
            title: 'Asset Relationship Type Desc Title',
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'assetRelationshipType' });
}
export default assetRelationshipType;
