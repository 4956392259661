import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import IntelligenceSourceType from './intelligentSourceType';
import VersionType from './versionType';
import './generalConfiguration.css';
var generalConfigurationNavLink = /** @class */ (function (_super) {
    __extends(generalConfigurationNavLink, _super);
    function generalConfigurationNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    generalConfigurationNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["General Configuration ", _jsx("span", { children: "(Configuration Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/generalConfiguration/intelligenceSourceType", className: "nav-link", activeClassName: "active" }, { children: "Intelligence Source Type" })), _jsx(NavLink, __assign({ to: "/dashboard/generalConfiguration/versionType", className: "nav-link", activeClassName: "active" }, { children: "Version Type" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/generalconfiguration", to: "/dashboard/generalConfiguration/intelligenceSourceType", exact: true }), _jsx(Route, { path: "/dashboard/generalConfiguration/intelligenceSourceType", component: IntelligenceSourceType, exact: true }), _jsx(Route, { path: "/dashboard/generalConfiguration/versionType", component: VersionType, exact: true })] }) }))] }))] })) }));
    };
    return generalConfigurationNavLink;
}(Component));
export default generalConfigurationNavLink;
