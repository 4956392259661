import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormField, TextArea, Alert, Sentiments, OptionGroup, Option } from '@sede-x/shell-ds-react-framework';
import { getDefaultValues } from './AddEditCapacityDrawer.utils';
import { useHandleSave } from './AddEditCapacity.hook';
import { capacityRequestsHook } from './capacityRequests.hook';
import { AddEditCapacityConditionalFields } from './AddEditCapacityConditionalFields';
import { BaseDrawer } from 'components/base-drawer';
import { BaseDrawerForm } from 'components/base-drawer-form';
import { Select } from 'components/select';
import { BaseDrawerFormFooter } from 'components/base-drawer-form-footer';
import { resolveOnSelectChange, SELECT_ITEM_PLACEHOLDER } from 'common/utils/url/addEditEvent.utils';
import { StyledGroupedSelect } from 'components/select/Select';
import { LastEditedInfo } from 'components/last-edited-info/LastEditedInfo';
export var AddEditCapacityEventDrawer = function (_a) {
    var _b, _c, _d;
    var open = _a.open, onClose = _a.onClose, eventsToEdit = _a.eventsToEdit;
    var form = useForm({
        defaultValues: getDefaultValues(eventsToEdit),
    });
    var watch = form.watch, setValue = form.setValue, register = form.register, errors = form.formState.errors, handleSubmit = form.handleSubmit, reset = form.reset;
    var _e = useState(false), isSaving = _e[0], setIsSaving = _e[1];
    var _f = useState(''), uomName = _f[0], setUomName = _f[1];
    var _g = capacityRequestsHook.useCapacityRequests(form, !open), assets = _g.assets, basistypes = _g.basistypes, probabilityTypes = _g.probabilityTypes, statusTypes = _g.statusTypes, units = _g.units, unitsData = _g.unitsData, _h = _g.isFetching, isFetchingAcmHierarchy = _h.isFetchingAcmHierarchy, isFetchingBasisTypes = _h.isFetchingBasisTypes, isFetchingProbabilityTypes = _h.isFetchingProbabilityTypes, isFetchingStatusTypes = _h.isFetchingStatusTypes, isFetchingUnits = _h.isFetchingUnits;
    var _j = useHandleSave({ events: eventsToEdit, setIsSaving: setIsSaving, onClose: onClose }), handleSave = _j.handleSave, error = _j.error;
    useEffect(function () {
        reset(__assign({}, getDefaultValues(eventsToEdit)));
        if (eventsToEdit.length === 1) {
            setUomName(eventsToEdit[0].measureUnitCd);
            setValue('capacityUoM', eventsToEdit[0].measureUnitKey);
        }
        else {
            setUomName('');
        }
    }, [eventsToEdit, reset, open, setValue]);
    var title = !eventsToEdit.length ? '' : 'Edit capacity event';
    var subtitle = !eventsToEdit.length
        ? 'New Event'
        : eventsToEdit.length === 1
            ? "".concat(eventsToEdit[0].asset, "/").concat(eventsToEdit[0].unit)
            : 'Multiple';
    var selectedUnits = watch('units');
    useEffect(function () {
        var _a, _b;
        if ((selectedUnits === null || selectedUnits === void 0 ? void 0 : selectedUnits.length) === 1) {
            /** Event for a single unit needs a unit of measurement to be sent to the API **/
            var uomData = unitsData === null || unitsData === void 0 ? void 0 : unitsData.filter(function (unit) { return unit.id === selectedUnits[0]; })[0].measureUnit;
            setValue('capacityUoM', (_a = uomData === null || uomData === void 0 ? void 0 : uomData.measureUnitKey) !== null && _a !== void 0 ? _a : 0);
            setUomName((_b = uomData === null || uomData === void 0 ? void 0 : uomData.measureUnitCd) !== null && _b !== void 0 ? _b : '');
        }
        else if ((selectedUnits === null || selectedUnits === void 0 ? void 0 : selectedUnits.length) > 1) {
            /** Selecting multiple units implies a closure **/
            setValue('basisType', 4);
        }
        else {
            setUomName('');
        }
    }, [selectedUnits, setValue, unitsData, watch]);
    return (_jsx(BaseDrawer, __assign({ title: title, subtitle: subtitle, open: open, showContent: true, onClose: onClose }, { children: _jsxs(BaseDrawerForm, __assign({ onSubmit: handleSubmit(handleSave), footer: _jsx(BaseDrawerFormFooter, { saveDisabled: isSaving, cancelDisabled: isSaving, onCancelClick: onClose, showSavingLoader: isSaving }) }, { children: [_jsx(AddEditCapacityConditionalFields, { form: form, units: units, assets: assets, basisTypes: basistypes, lengthOfEvents: eventsToEdit.length, isFetchingAssets: isFetchingAcmHierarchy, isFetchingUnits: isFetchingUnits, isFetchingBasisTypes: isFetchingBasisTypes, uomName: uomName }), _jsx(FormField, __assign({ size: "small", label: "Probability Type", bottomLeftHelper: { content: (_b = errors.probabilityType) === null || _b === void 0 ? void 0 : _b.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('probabilityType', {
                        required: 'Please select a Probability Type',
                    }), { onChange: resolveOnSelectChange('probabilityType', form, []), value: watch('probabilityType'), size: "small", allowClear: false, data: probabilityTypes, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingProbabilityTypes })) })), _jsx(FormField, __assign({ size: "small", label: "Source", bottomLeftHelper: { content: (_c = errors.sourceOfIntelligence) === null || _c === void 0 ? void 0 : _c.message, sentiment: 'negative' } }, { children: _jsxs(StyledGroupedSelect, __assign({}, register('sourceOfIntelligence', {
                        required: 'Please select the source',
                    }), { onChange: resolveOnSelectChange('sourceOfIntelligence', form, []), value: watch('sourceOfIntelligence'), size: "small", allowClear: false, placeholder: SELECT_ITEM_PLACEHOLDER }, { children: [_jsxs(OptionGroup, __assign({ label: "Internal source" }, { children: [_jsx(Option, __assign({ value: 1, disabled: true }, { children: "DAD" })), _jsx(Option, __assign({ disabled: true, value: 19 }, { children: "GENEVA" })), _jsx(Option, __assign({ value: 20 }, { children: "Internal" })), _jsx(Option, __assign({ value: 18 }, { children: "STMA" }))] })), _jsxs(OptionGroup, __assign({ label: "Data provider" }, { children: [_jsx(Option, __assign({ value: 9 }, { children: "IIR" })), _jsx(Option, __assign({ value: 13 }, { children: "PIRA" }))] })), _jsxs(OptionGroup, __assign({ label: "Intel source" }, { children: [_jsx(Option, __assign({ value: 15 }, { children: "EIA" })), _jsx(Option, __assign({ value: 24 }, { children: "ENT" })), _jsx(Option, __assign({ value: 5 }, { children: "FGE" })), _jsx(Option, __assign({ value: 7 }, { children: "ICIS" })), _jsx(Option, __assign({ value: 10 }, { children: "JLC" })), _jsx(Option, __assign({ value: 12 }, { children: "PM" })), _jsx(Option, __assign({ value: 25 }, { children: "REUTERS" })), _jsx(Option, __assign({ value: 16 }, { children: "SCI" })), _jsx(Option, __assign({ value: 14 }, { children: "WM" }))] }))] })) })), _jsx(FormField, __assign({ size: "small", label: "Status Type", bottomLeftHelper: { content: (_d = errors.statusType) === null || _d === void 0 ? void 0 : _d.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('statusType', {
                        required: 'Please select a Status Type',
                    }), { onChange: resolveOnSelectChange('statusType', form, []), value: watch('statusType'), size: "small", allowClear: false, data: statusTypes, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingStatusTypes })) })), _jsx(FormField, __assign({ size: "small", label: "Comments", bottomLeftHelper: {
                        content: eventsToEdit.length === 1 && (_jsx(LastEditedInfo, { lastEditedBy: eventsToEdit[0].editedBy, lastEditedAt: eventsToEdit[0].updateDate })),
                    } }, { children: _jsx(TextArea, __assign({}, register('intelligenceSourceTxt'), { value: watch('intelligenceSourceTxt'), resize: "vertical", rows: 3 })) })), error && _jsx(Alert, __assign({ sentiment: Sentiments.Negative }, { children: error }))] })) })));
};
