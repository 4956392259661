import { __assign } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormField } from '@sede-x/shell-ds-react-framework';
import { StyledFormField } from '../AddEditMaintenanceEventDrawer.styles';
import { Select } from 'components/select/Select';
import { SELECT_ITEM_PLACEHOLDER, resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
export var AddEditConditionalFields = function (_a) {
    var _b, _c, _d, _e;
    var form = _a.form, units = _a.units, assets = _a.assets, isFetchingUnit = _a.isFetchingUnit, isFetchingAssets = _a.isFetchingAssets;
    var watch = form.watch, register = form.register, errors = form.formState.errors;
    return (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({ size: "small", label: "Asset name", bottomLeftHelper: { content: (_b = errors.asset) === null || _b === void 0 ? void 0 : _b.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('asset', {
                    required: 'Please select an Asset',
                }), { onChange: resolveOnSelectChange('asset', form, units), value: watch('asset'), data: assets, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingAssets, size: "small", invalid: !!((_c = errors.asset) === null || _c === void 0 ? void 0 : _c.message), "data-testid": "select-name" })) })), _jsx(StyledFormField, __assign({ size: "small", label: "Unit name", bottomLeftHelper: { content: (_d = errors.units) === null || _d === void 0 ? void 0 : _d.message, sentiment: 'negative' } }, { children: _jsx(Select, __assign({}, register('units', {
                    required: 'Please select a Unit',
                }), { mode: "multiple", onChange: resolveOnSelectChange('units', form, units), value: watch('units'), data: units, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingUnit, size: "small", invalid: !!((_e = errors === null || errors === void 0 ? void 0 : errors.units) === null || _e === void 0 ? void 0 : _e.message), "data-testid": "select-unit" })) }))] }));
};
