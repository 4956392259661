import { __assign, __extends } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';
import './quaterPicker.css';
var quaterPicker = /** @class */ (function (_super) {
    __extends(quaterPicker, _super);
    function quaterPicker(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.componentDidMount = function () {
            var min = _this.props.minYear ? _this.props.minYear : 1900;
            var max = new Date().getFullYear();
            max = _this.props.minYear ? parseInt(max) + parseInt(101) : parseInt(max) + parseInt(100);
            var select = document.querySelector('#year-' + _this.props.align);
            for (var i = min; i <= max; i++) {
                var opt = document.createElement('option');
                opt.value = i;
                opt.innerHTML = i;
                opt.className = _this.props.minYear ? 'option-values' : '';
                select.appendChild(opt);
            }
            _this.Qq = new SlimSelect({
                placeholder: 'QQ',
                select: '#quater-' + _this.props.align,
                selected: false,
            });
            _this.yearSelect = new SlimSelect({
                placeholder: 'YYYY',
                select: '#year-' + _this.props.align,
                selected: false,
            });
            if (_this.state.quater !== 'QQ' && _this.state.year !== 'YYYY') {
                _this.Qq.set(_this.state.quater);
                _this.yearSelect.set(_this.state.year);
            }
            window.addEventListener('click', function (e) {
                if (document.getElementById('quater-selection-' + _this.props.align) !== null) {
                    if (document.getElementById('quater-selection-' + _this.props.align).contains(e.target)) {
                        return;
                    }
                    else {
                        if (_this.state.calender) {
                            _this.setState({
                                calender: !_this.state.calender,
                            });
                        }
                        else {
                            return;
                        }
                    }
                }
            });
        };
        _this.resetQqYear = function () {
            _this.Qq.set(_this.state.defaultQuarter);
            _this.yearSelect.set(_this.state.defaultYear);
            _this.setState({
                quater: _this.state.defaultQuarter,
                year: _this.state.defaultYear,
            });
        };
        _this.handleSelect = function (e) {
            _this.setState({
                calender: !_this.state.calender,
            });
        };
        _this.quaterSelection = function (e) {
            _this.setState({
                quater: e.target.value,
            });
            _this.props.selectHandler('quater', e.target.value);
        };
        _this.yearSelection = function (e) {
            _this.setState({
                year: e.target.value,
            });
            _this.props.selectHandler('year', e.target.value);
        };
        _this.state = {
            calender: false,
            year: _this.props.defaultYear ? _this.props.defaultYear : 'YYYY',
            quater: _this.props.defaultQq ? _this.props.defaultQq : 'QQ',
            defaultYear: _this.props.defaultYear ? _this.props.defaultYear : 'YYYY',
            defaultQuarter: _this.props.defaultQq ? _this.props.defaultQq : 'QQ',
        };
        _this.Qq = '';
        _this.yearSelect = '';
        return _this;
    }
    quaterPicker.prototype.render = function () {
        return (_jsxs("div", __assign({ id: 'quater-selection-' + this.props.align }, { children: [_jsxs("span", __assign({ className: "dim-text" }, { children: [this.props.text, ": "] })), _jsxs("span", __assign({ className: "normal-text ml-2 mr-4" }, { children: [this.state.year < this.props.minYear ? '< ' + this.props.minYear : this.state.year, " | ", this.state.quater] })), _jsx("span", __assign({ className: "calander-icon ol", onClick: this.handleSelect }, { children: _jsx("i", { className: "k-icon k-i-calendar olicon", "aria-hidden": "true" }) })), _jsx("div", __assign({ className: 'calender bg-solid border rounded p-3 ' + this.props.align, style: { display: this.state.calender ? 'block' : 'none' } }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col" }, { children: _jsxs("select", __assign({ id: 'quater-' + this.props.align, className: 'quater-' + this.props.align, onChange: this.quaterSelection }, { children: [_jsx("option", { "data-placeholder": "true" }), _jsx("option", __assign({ value: "Q1" }, { children: "Q1" })), _jsx("option", __assign({ value: "Q2" }, { children: "Q2" })), _jsx("option", __assign({ value: "Q3" }, { children: "Q3" })), _jsx("option", __assign({ value: "Q4" }, { children: "Q4" }))] })) })), _jsx("div", __assign({ className: "col" }, { children: _jsxs("select", __assign({ id: 'year-' + this.props.align, className: 'quater-' + this.props.align, onChange: this.yearSelection }, { children: [_jsx("option", { "data-placeholder": "true" }), this.props.minYear && (_jsx("option", __assign({ value: "1800", style: {
                                                backgroundColor: '#202020',
                                            } }, { children: '< 2007' })))] })) }))] })) }))] })));
    };
    return quaterPicker;
}(Component));
export default quaterPicker;
