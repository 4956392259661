import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
var geoPoliticalEntity = /** @class */ (function (_super) {
    __extends(geoPoliticalEntity, _super);
    function geoPoliticalEntity(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            geopoliticalEntityCd: {
                required: true,
                error: 'Enter Geopolitical Entity Cd.',
            },
            geopoliticalEntityTypeCd: {
                required: true,
                error: 'Enter Geopolitical Entity Type Key.',
            },
            geopoliticalEntityTypeParent: {
                required: true,
                error: 'Enter Parent Geopolitical Entity Key.',
            },
        };
        var foreginKeyParams = [
            {
                path: 'geopoliticalEntityType',
                key: 'geopoliticalEntityTypeKey',
                cdName: 'geopoliticalEntityTypeCd',
                replicaPath: 'geopoliticalEntityTypeReplica',
            },
            {
                path: 'geopoliticalEntity',
                cdName: 'geopoliticalEntityId',
                foreignReplica: true,
                foreignReplicaCol: 'geopoliticalEntityTypeParent',
                foreignReplicaKey: 'parentGeopoliticalEntityKey',
                foreignReplicaColPath: 'geopoliticalEntityTyp',
                replicaPath: 'geopoliticalEntityTypeParentReplica',
            },
        ];
        var columns = [
            {
                field: 'geopoliticalEntityId',
                title: 'Geopolitical Entity ID',
                editable: false,
            },
            {
                field: 'geopoliticalEntityCd',
                title: 'Geopolitical Entity Cd',
            },
            {
                field: 'geopoliticalEntityAbbr',
                title: 'Geopolitical Entity Abbrevation',
            },
            {
                field: 'geopoliticalEntityNm',
                title: 'Geopolitical Entity Name',
            },
            {
                field: 'geopoliticalEntityLevelNum',
                title: 'Geopolitical Entity Level Number',
                filter: 'numeric',
                editor: 'numeric',
            },
            {
                field: 'geopoliticalEntityTypeCd',
                title: 'Geopolitical Entity Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'geopoliticalEntityTypeCd',
                referenceTitle: 'Geopolitical Entity Type',
                urlEndPoint: 'geopoliticalEntityType',
                replicaPath: 'geopoliticalEntityTypeReplica',
            },
            {
                field: 'geopoliticalEntityTypeParent',
                title: 'Parent Geopolitical Entity Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'geopoliticalEntityId',
                referenceTitle: 'Geopolitical Entity Type',
                urlEndPoint: 'geopoliticalEntity',
                foreignReplica: true,
                replicaPath: 'geopoliticalEntityTypeParentReplica',
            },
            // {
            //   field: "opecMemberInd",
            //   title: "OPEC Member Ind"
            // },
            // {
            //   field: "oecdMemberInd",
            //   title: "OECD Member Ind"
            // },
            // {
            //   field: "bricMemberInd",
            //   title: "BRIC Member Ind"
            // },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    geoPoliticalEntity.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "geopoliticalEntity", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "geopoliticalEntityId", foreginKeyParams: this.state.foreginKeyParams, foreignReplicaScreen: "replica" }));
    };
    return geoPoliticalEntity;
}(Component));
export default geoPoliticalEntity;
