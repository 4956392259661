import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
var LockSolid = Icons.LockSolid, UnlockSolid = Icons.UnlockSolid;
export var StyledLock = styled(LockSolid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  path {\n    fill: ", ";\n  }\n"], ["\n  path {\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.accent.primary.strong;
});
export var StyledUnlock = styled(UnlockSolid)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  path {\n    fill: ", ";\n  }\n"], ["\n  path {\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.inactive;
});
export var StyledButtonWithIcon = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  visibility: ", ";\n"], ["\n  visibility: ", ";\n"])), function (_a) {
    var isVisible = _a.isVisible;
    return (isVisible ? 'visible' : 'hidden');
});
var loadingStyles = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  pointer-events: none;\n\n  & > div:nth-child(1) {\n    position: absolute;\n  }\n\n  tbody {\n    opacity: 0.1;\n  }\n\n  & > div > div + div {\n    opacity: 0.1;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  pointer-events: none;\n\n  & > div:nth-child(1) {\n    position: absolute;\n  }\n\n  tbody {\n    opacity: 0.1;\n  }\n\n  & > div > div + div {\n    opacity: 0.1;\n  }\n"])));
export var StyledTableLoader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var loading = _a.loading;
    return loading && loadingStyles;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
