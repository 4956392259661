import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function assetEventTypeCrossReference(props) {
    var validationParams = {
        assetEventTypeKey: 'Select Asset event type',
        dataProviderKey: 'Select data provider',
        providerAssetEventTypeCd: 'Enter Provider Asset Event Type Code',
    };
    var columnParams = [
        {
            field: 'assetEventTypeDesc',
            title: 'Asset Event Type Name',
            key: 'assetEventTypeKey',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider Name',
            key: 'dataProviderKey',
        },
        {
            field: 'providerAssetEventTypeCd',
            title: 'Provider Asset Event Type Code',
        },
        {
            field: 'versionEffectiveDt',
            title: 'Effective Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionTerminationDt',
            title: 'Termination Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'assetEventTypeCrossRefs' });
}
export default assetEventTypeCrossReference;
