import styled, { css } from 'styled-components';

import { textConsts } from '../../Text.consts';
import { ILabelProps } from './Label.types';
import { Sizes } from '../../../../types/types';

const Label = styled.span<Required<Pick<ILabelProps, 'size' | 'gutter' | 'bold' | 'prominence'>>>`
  ${({ size, prominence, gutter, bold, theme }) => css`
    ${bold
      ? css`
          ${size === Sizes.ExtraSmall && theme.label.extraSmall.bold};
          ${size === Sizes.Small && theme.label.small.bold};
          ${size === Sizes.Medium && theme.label.medium.bold};
          ${size === Sizes.Large && theme.label.large.bold};
          ${size === Sizes.ExtraLarge && theme.label.extraLarge.demi};
        `
      : css`
          ${size === Sizes.ExtraSmall && theme.label.extraSmall.normal};
          ${size === Sizes.Small && theme.label.small.normal};
          ${size === Sizes.Medium && theme.label.medium.normal};
          ${size === Sizes.Large && theme.label.large.normal};
          ${size === Sizes.ExtraLarge && theme.label.extraLarge.normal};
        `}

    margin-top: 0;
    margin-bottom: 0;
    color: ${theme.text.onSurface[prominence]};

    ${gutter &&
    css`
      margin-bottom: ${textConsts.gutter.label};
    `}
  `}
`;

export { Label };
