import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table';
import { Table, THead, TH, TR, TD, TBody } from '@sede-x/shell-ds-react-framework';
import { Loader } from 'components/loader';
import { columns, addHighlightFieldsToVersions, } from 'components/event-summary-actions-modal/EventsSummaryActionsModal.config';
import { userFriendlyNameMappings } from 'common/utils/userFriendlyNameMappings';
import { StyledText } from 'components/capacity-actions-modal/CapacityActionsModal.styles';
import { DivWithEllipsis } from 'pages/capacity/Capacity.hooks';
var stickyWidth = [0, 114];
var getStickyStyles = function (index, stickyBackground, type) {
    if (index < 2) {
        return {
            position: 'sticky',
            zIndex: 1,
            left: stickyWidth[index],
            background: stickyBackground,
        };
    }
    if (type === 'header') {
        return {
            background: stickyBackground,
        };
    }
};
export var Versions = function (_a) {
    var data = _a.data, isFetching = _a.isFetching;
    var theme = useTheme();
    var stickyBackground = theme.background.surface;
    var getColumns = columns({ shortKeyType: false });
    var versionColumns = __spreadArray([], getColumns, true);
    versionColumns.splice(1, 0, {
        header: 'Edited By',
        accessorKey: 'updatedBy',
        cell: function (info) {
            var _a;
            return (_jsx(DivWithEllipsis, { children: (_a = userFriendlyNameMappings.get(info.getValue())) !== null && _a !== void 0 ? _a : info.getValue() }));
        },
    });
    var dataWithHighlights = useMemo(function () { return addHighlightFieldsToVersions(data || []); }, [data]);
    var table = useReactTable({
        data: dataWithHighlights,
        columns: versionColumns,
        getCoreRowModel: getCoreRowModel(),
    });
    return (_jsxs(_Fragment, { children: [isFetching && _jsx(Loader, { message: "Fetching previous versions..." }), !isFetching && !!data && (_jsx("div", __assign({ style: {
                    position: 'relative',
                    maxHeight: '60vh',
                    maxWidth: '80vw',
                    overflowY: 'auto',
                    overflowX: 'auto',
                } }, { children: _jsxs(Table, { children: [_jsx(THead, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header, index) { return (_jsx(TH, __assign({ style: getStickyStyles(index, stickyBackground, 'header') }, { children: header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext()) }), header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: table.getRowModel().rows.map(function (row, index) { return (_jsxs(_Fragment, { children: [index === 0 && _jsx(StyledText, { children: "CURRENT BASELINE" }), index === 1 && _jsx(StyledText, { children: "BASELINE HISTORY" }), _jsx(TR, { children: row.getVisibleCells().map(function (cell, index) { return (_jsx(TD, __assign({ style: getStickyStyles(index, stickyBackground) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id)); }) }, row.id)] })); }) })] }) })))] }));
};
