import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { toast } from 'react-toastify';
import { filterSearch } from './tools/filterSearch';
import { useApiContext } from './apiContext';
var AssetRampUpProfiles = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(apiParams.unitSubTypesFilterData), unitSubTypesFilterData = _b[0], setUnitSubTypesFilterData = _b[1];
    var _c = useState(null), indexForEdit = _c[0], setIndexForEdit = _c[1];
    var _d = useState(''), addEditPopupTitle = _d[0], setAddEditPopupTitle = _d[1];
    var _e = useState([]), sort = _e[0], setSort = _e[1];
    var _f = useState(false), visibleAddEdit = _f[0], setVisibleAddEdit = _f[1];
    var _g = useState(false), deletePopup = _g[0], setDeletePopup = _g[1];
    useEffect(function () {
        setUnitSubTypesFilterData(apiParams.unitSubTypesFilterData);
    }, [apiParams.unitSubTypesFilterData, visibleAddEdit]);
    var addEditRampUpProfile = function (dataItem) {
        var index;
        if (!dataItem) {
            setAddEditPopupTitle('Add Ramp-Up Profile');
            index = apiParams.addEditAssetConfigData.assetRampUp.length;
            var newParams = __assign({}, apiParams);
            newParams.addEditAssetConfigData.assetRampUp[index] = {};
            newParams.fieldsMissing = false;
            setIndexForEdit(index);
            setApiParams(newParams);
        }
        else {
            setAddEditPopupTitle('Edit Ramp-Up Profile');
            index = apiParams.addEditAssetConfigData.assetRampUp.findIndex(function (item) { return item.assetRampUpProfileKey === dataItem.assetRampUpProfileKey; });
            setIndexForEdit(index);
        }
        setVisibleAddEdit(true);
    };
    var deleteRampUpProfile = function (dataItem) {
        var index = apiParams.addEditAssetConfigData.assetRampUp.findIndex(function (item) { return item.assetRampUpProfileKey === dataItem.assetRampUpProfileKey; });
        setIndexForEdit(index);
        setDeletePopup(true);
    };
    var EditButtonsCell = function (dataItemProps) {
        return (_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () { return addEditRampUpProfile(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ onClick: function () { return deleteRampUpProfile(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] }));
    };
    var SubtypeCell = function (dataItemProps) {
        return (_jsx("td", { children: dataItemProps.dataItem.assetUnitSubtypeNm == null ? 'All Subtypes' : dataItemProps.dataItem.assetUnitSubtypeNm }));
    };
    /////////////Functions for handling edits/////////////////////////
    var isEdited = function () { return indexForEdit < apiParams.originalRampUpLength; };
    var onSubtypeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetRampUp[indexForEdit].assetUnitSubtypeKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetRampUp[indexForEdit].assetUnitSubtypeNm = e.target.value.assetUnitSubtypeCd;
        setApiParams(newParams);
    };
    var onRampUpMonthsChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetRampUp[indexForEdit].rampUpMonthCnt = e.target.value;
        setApiParams(newParams);
    };
    var onDelete = function () {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetRampUp.splice(indexForEdit, 1);
        newParams.originalAssetConfigData.assetRampUp.splice(indexForEdit, 1);
        newParams.originalRampUpLength = newParams.originalRampUpLength - 1; //important for setting isEdited field
        setApiParams(newParams);
        setDeletePopup(false);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        if (!apiParams.originalAssetConfigData.assetRampUp[indexForEdit]) {
            newParams.addEditAssetConfigData.assetRampUp.splice(indexForEdit, 1);
        }
        else {
            newParams.addEditAssetConfigData.assetRampUp[indexForEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetConfigData.assetRampUp[indexForEdit]));
        }
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    var onUpdate = function () {
        var details = apiParams.addEditAssetConfigData.assetRampUp[indexForEdit];
        if (!details.rampUpMonthCnt) {
            toast.error('Fields marked in red are required', { autoClose: 5000 });
            setApiParams(__assign(__assign({}, apiParams), { fieldsMissing: true }));
            return;
        }
        var newParams = __assign({}, apiParams);
        if (!details.assetUnitSubtypeKey) {
            newParams.addEditAssetConfigData.assetRampUp[indexForEdit].assetUnitSubtypeNm = 'All Subtypes';
            newParams.addEditAssetConfigData.assetRampUp[indexForEdit].assetUnitSubtypeKey = null;
        }
        newParams.addEditAssetConfigData.assetRampUp[indexForEdit].isEdited = isEdited();
        newParams.originalAssetConfigData.assetRampUp[indexForEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetConfigData.assetRampUp[indexForEdit]));
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    return (_jsxs("div", { children: [visibleAddEdit && (_jsx("div", __assign({ id: "unit-cross-reference" }, { children: _jsxs(Dialog, __assign({ width: '30%', title: addEditPopupTitle, onClose: onCancel }, { children: [_jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Unit Subtype" })), _jsx(DropDownList, { data: __spreadArray([{ assetUnitSubtypeCd: 'All Subtypes', id: null }], unitSubTypesFilterData, true), textField: "assetUnitSubtypeCd", name: "assetUnitSubtypeCd", filterable: true, onFilterChange: function (e) {
                                                    setUnitSubTypesFilterData(filterSearch(apiParams.unitSubTypesFilterData, e.filter));
                                                }, value: {
                                                    assetUnitSubtypeCd: apiParams.addEditAssetConfigData.assetRampUp[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetRampUp[indexForEdit].assetUnitSubtypeNm ||
                                                            'All Subtypes'
                                                        : 'All Subtypes',
                                                }, onChange: onSubtypeChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Ramp-Up Months" })), _jsx("input", { name: "rampup-months", style: { width: '100%' }, className: !apiParams.addEditAssetConfigData.assetRampUp[indexForEdit].rampUpMonthCnt &&
                                                    apiParams.fieldsMissing
                                                    ? 'k-textbox k-state-invalid'
                                                    : 'k-textbox', value: apiParams.addEditAssetConfigData.assetRampUp[indexForEdit]
                                                    ? apiParams.addEditAssetConfigData.assetRampUp[indexForEdit].rampUpMonthCnt || ''
                                                    : '', onChange: onRampUpMonthsChange, placeholder: "Enter a value", autoComplete: "off" })] })) }))] })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border btn-cancel", onClick: onCancel }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary", onClick: onUpdate }, { children: "Update" }))] }))] })) }))), _jsxs("button", __assign({ className: "p-3 new-asset-rampup-profile", onClick: function () { return addEditRampUpProfile(); } }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Asset Ramp-Up Profile" }))] })), apiParams.addEditAssetConfigData && apiParams.addEditAssetConfigData.assetRampUp.length > 0 ? (_jsx("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: _jsxs(Grid, __assign({ data: orderBy(apiParams.addEditAssetConfigData.assetRampUp, sort), sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    }, style: { height: '575px' } }, { children: [_jsx(Column, { width: "90px", cell: EditButtonsCell, locked: true }), _jsx(Column, { width: "200px", field: "rampUpMonthCnt", title: "Ramp-up months" }), _jsx(Column, { field: "assetUnitSubtypeNm", title: "Unit Subtype", cell: SubtypeCell })] })) }))) : (_jsx("div", __assign({ style: {
                    border: '1px solid #595959',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    color: '#595959',
                    fontFamily: 'ShellLight',
                    left: '36%',
                    top: '46%',
                } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No records available to display." })) }))), deletePopup && (_jsx("div", { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setDeletePopup(false); } }, { children: [_jsx("div", __assign({ className: "p-4" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setDeletePopup(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onDelete }, { children: "Yes" }))] })] })) }))] }));
};
export default AssetRampUpProfiles;
