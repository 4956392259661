import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { StyledQuantity, StyledHighlight } from './CapacityActionsModal.styles';
import { DivWithEllipsis, renderCellWithEllipsis } from 'pages/capacity/Capacity.hooks';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
import { userFriendlyNameMappings } from 'common/utils/userFriendlyNameMappings';
var ChevronDown = Icons.ChevronDown, ChevronRight = Icons.ChevronRight;
var conditionalHighlightRender = function (name, record, field) {
    if (record[field] && name) {
        return _jsx(StyledHighlight, { children: renderCellWithEllipsis(name) });
    }
    return renderCellWithEllipsis(name);
};
export var renderCapacity = function (qty, record) {
    if (!isNaN(qty)) {
        var sign = qty > 0 ? '+' : qty === 0 ? '' : '-';
        if ('hasIncCapChanged' in record && record.hasIncCapChanged) {
            return (_jsxs(StyledHighlight, __assign({ title: "".concat(qty, " ").concat(record.measureUnitCd), style: {
                    marginLeft: 'auto',
                    marginRight: '0',
                    maxWidth: '100px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                } }, { children: [_jsx(StyledQuantity, { children: sign }), Math.abs(qty).toFixed(1), " ", _jsx(StyledQuantity, { children: record.measureUnitCd })] })));
        }
        return (_jsxs("div", __assign({ title: "".concat(qty, " ").concat(record.measureUnitCd), style: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } }, { children: [_jsx(StyledQuantity, { children: sign }), Math.abs(qty).toFixed(1), " ", _jsx(StyledQuantity, { children: record.measureUnitCd })] })));
    }
    return _jsx(_Fragment, {});
};
var baselineHistoryColumns = [
    {
        header: 'Version date',
        accessorKey: 'versionEffectiveDate',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY) }); },
    },
    {
        header: 'Edited by',
        accessorKey: 'createdBy',
        cell: function (info) { return (_jsx(DivWithEllipsis, { children: userFriendlyNameMappings.get(info.getValue()) || info.getValue() })); },
    },
    {
        header: 'Start date',
        accessorKey: 'capacityStartDate',
        cell: function (info) {
            var startDate = dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY);
            var record = info.row.original;
            if (record.hasStartDateChanged) {
                return _jsx(StyledHighlight, { children: startDate });
            }
            return _jsx(DivWithEllipsis, { children: startDate });
        },
    },
    {
        header: 'Inc. Capacity',
        accessorKey: 'capacityUniversalQty',
        cell: function (info) { return renderCapacity(+info.getValue(), info.row.original); },
    },
    {
        header: 'Probability',
        accessorKey: 'planningProbabilityTypeDesc',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasProbChanged'); },
    },
    {
        header: 'Basis',
        accessorKey: 'capacityBasisTypeDesc',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasBasisChanged'); },
    },
    {
        header: 'Status',
        accessorKey: 'planningStatusTypeDesc',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasStatusChanged'); },
    },
    {
        header: 'Source',
        accessorKey: 'dataProviderAbbr',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasDataProviderChanged'); },
    },
];
var originalsColumns = [
    {
        header: function () { return _jsx("div", __assign({ style: { paddingLeft: 25 } }, { children: "Source" })); },
        accessorKey: 'source',
        cell: function (_a) {
            var row = _a.row, getValue = _a.getValue;
            return (_jsxs(DivWithEllipsis, { children: [row.getCanExpand() ? (_jsx("button", __assign({}, {
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                    }, { children: row.getIsExpanded() ? _jsx(ChevronDown, {}) : _jsx(ChevronRight, {}) }))) : null, ' ', getValue()] }));
        },
    },
    {
        header: 'Start date',
        accessorKey: 'startDate',
        cell: function (info) {
            var date = info.getValue();
            var record = info.row.original;
            var startDate = dayjs(date).format(DATE_FORMAT_DD_MMM_YYYY);
            if (record.hasStartDateChanged) {
                return _jsx(StyledHighlight, { children: startDate });
            }
            return _jsx(DivWithEllipsis, { children: startDate });
        },
    },
    {
        header: 'Inc. capacity',
        accessorKey: 'capacityOrigQty',
        cell: function (info) { return renderCapacity(+info.getValue(), info.row.original); },
    },
    {
        header: 'Probability',
        accessorKey: 'probability',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasProbChanged'); },
    },
    {
        header: 'Basis',
        accessorKey: 'capacityBasisType',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasBasisChanged'); },
    },
    {
        header: 'Status',
        accessorKey: 'status',
        cell: function (info) { return conditionalHighlightRender(info.getValue(), info.row.original, 'hasStatusChanged'); },
    },
    {
        header: 'Last update',
        accessorKey: 'updateDate',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY) }); },
    },
];
export { baselineHistoryColumns, originalsColumns };
