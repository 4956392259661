import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormField, Heading, TextArea, TextInput } from '@sede-x/shell-ds-react-framework';
import { resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
import { useGetDataProvidersQuery } from 'common/ducks/filtersApis';
import { newMapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
import { Select } from 'components/select';
import { LastEditedInfo } from 'components/last-edited-info/LastEditedInfo';
export var IntelligenceSection = function (_a) {
    var form = _a.form, open = _a.open, assetToEdit = _a.assetToEdit;
    var register = form.register, watch = form.watch;
    var _b = useGetDataProvidersQuery({ type: 1 }, {
        skip: !open,
    }), _c = _b.data, dataProviders = _c === void 0 ? [] : _c, isFetchingDataProviders = _b.isFetching;
    var dataProvidersOptions = dataProviders
        .map(newMapSelectData('dataProviderAbbr', 'id'))
        .sort(sortSelectData);
    return (_jsxs("section", { children: [_jsx(Heading, __assign({ bold: true, color: "light", level: 5 }, { children: "INTELLIGENCE" })), _jsx(FormField, __assign({ label: "Intelligence source", size: "small", style: { width: '48%' } }, { children: _jsx(Select, __assign({}, register('intelligenceSource'), { placeholder: "Select", data: dataProvidersOptions, loading: isFetchingDataProviders, size: "small", value: watch('intelligenceSource'), onChange: resolveOnSelectChange('intelligenceSource', form, []) })) })), _jsx(FormField, __assign({ label: "Intelligence source URL", size: "small" }, { children: _jsx(TextInput, __assign({}, register('intelligenceSourceUrl'), { placeholder: "Enter URL", size: "small" })) })), _jsx(FormField, __assign({ label: "Comments", size: "small", bottomLeftHelper: {
                    content: assetToEdit && (_jsx(LastEditedInfo, { lastEditedBy: assetToEdit.metaChangedByNm, lastEditedAt: assetToEdit.metaChangedDttm })),
                } }, { children: _jsx(TextArea, __assign({}, register('comments'), { value: watch('comments'), placeholder: "Enter comments", rows: 5 }), open.toString()) }))] }));
};
