import { __assign, __makeTemplateObject, __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Select as ShellSelect, Option } from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import styled from 'styled-components';
// FIXME: remove this component when master-dark.css file is deleted!
var StyledShellSelect = styled(ShellSelect)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    vertical-align: baseline;\n  }\n"], ["\n  svg {\n    vertical-align: baseline;\n  }\n"])));
export var StyledGroupedSelect = styled(ShellSelect)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .shell-select-item-group {\n    margin-left: 8px;\n  }\n  .shell-select-item-option-grouped {\n    padding-left: 16px;\n  }\n"], ["\n  .shell-select-item-group {\n    margin-left: 8px;\n  }\n  .shell-select-item-option-grouped {\n    padding-left: 16px;\n  }\n"])));
export var Select = function (_a) {
    var data = _a.data, props = __rest(_a, ["data"]);
    var _b = useState(), searchValue = _b[0], setSearchValue = _b[1];
    return (_jsx(StyledShellSelect, __assign({}, props, { searchValue: searchValue, onSearch: function (value) { return setSearchValue(value); }, onDropdownVisibleChange: function () { return setSearchValue(undefined); } }, { children: data.map(function (_a) {
            var key = _a.key, props = __rest(_a, ["key"]);
            return (_jsx(Option, __assign({}, props), key));
        }) })));
};
var templateObject_1, templateObject_2;
