import React from 'react';

import RCDialog, { DialogProps } from 'rc-dialog';

interface IDialogProps extends DialogProps {
  className?: string;
  prefixCls: string;
}

const getContainer = (prefixCls: string, className: string): HTMLElement => {
  const portal = document.getElementById(`${prefixCls}-portal`);

  if (portal) {
    portal.className = className;

    return portal;
  }

  const newPortal = document.createElement('div');
  newPortal.className = className;
  newPortal.id = `${prefixCls}-portal`;
  document.body.append(newPortal);

  return newPortal;
};

const Dialog = ({ className = '', prefixCls, children, visible, ...props }: IDialogProps) => (
  <RCDialog
    prefixCls={`${className} ${prefixCls}`}
    className={className}
    visible={visible}
    getContainer={() => getContainer(prefixCls, className)}
    animation="zoom"
    maskAnimation="fade"
    {...props}
  >
    {children}
  </RCDialog>
);

export default Dialog;
