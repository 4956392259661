import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash';
import React, { useState, createContext, useContext, useMemo, } from 'react';
import { defaultConst } from './ApiContextInitialState';
export var ApiContext = createContext([defaultConst, noop]);
export var useApiContext = function () { return useContext(ApiContext); };
export var ApiProvider = function (_a) {
    var children = _a.children;
    //function state set
    var _b = useState(__assign({}, defaultConst)), apiParams = _b[0], setApiParams = _b[1];
    var value = useMemo(function () { return [apiParams, setApiParams]; }, [apiParams, setApiParams]);
    return _jsx(ApiContext.Provider, __assign({ value: value }, { children: children }));
};
