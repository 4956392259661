import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import DetailsTab from './detailsTab';
import AssetCrossReferencesTab from './assetCrossReferencesTab';
import AssetCompanies from './assetCompanies';
import AssetRampUpProfiles from './assetRampUpProfiles';
import AssetUnitDependencies from './assetUnitDependencies';
import AssetHistoryTab from './assetHistoryTab';
import RegionalHierarchiesTab from './RegionalHierarchiesTab';
import { editSourceData, saveSourceData } from '../../../../../services/urlService';
import { useApiContext } from './apiContext';
import Spinner from '../../../../../commons/spinner';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
var openCity = function (evt, cityname) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = 'none';
    }
    document.getElementById('div-' + cityname).style.display = 'block';
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    evt.target.className += ' active';
    var buttonsDiv = document.getElementById('tab-buttons');
    if (cityname === 'assetHistory')
        buttonsDiv.style.visibility = 'hidden';
    else
        buttonsDiv.style.visibility = 'visible';
};
var AssetConfigTabContainer = function (props) {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), submitClicked = _c[0], setSubmitClicked = _c[1];
    //useEffect for removing validation styling while adding a unit
    useEffect(function () {
        if (apiParams.fieldsMissing) {
            var newApiParams = __assign(__assign({}, apiParams), { fieldsMissing: false });
            setApiParams(newApiParams);
        }
    }, [props.isNewUnit]);
    //useEffect for going to the Details tab to show missing fields
    useEffect(function () {
        if (apiParams.fieldsMissing) {
            var detailsTab = document.getElementById('details');
            if (detailsTab)
                detailsTab.click();
            var missingEl = document.getElementsByClassName('k-state-invalid')[0];
            if (missingEl)
                missingEl.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [submitClicked]);
    var onSubmit = function () {
        var assetDetails = apiParams.addEditAssetConfigData.assetDetails;
        ////////////VALIDATION CHECK/////////////////////////////////////
        if (assetDetails.assetNm == '' || assetDetails.assetClassNm == '') {
            var newApiParams = __assign(__assign({}, apiParams), { fieldsMissing: true });
            setApiParams(newApiParams);
            toast.error('Fields marked in red are required', { autoClose: 5000 });
            setSubmitClicked(!submitClicked); //calls useEffect
            return;
        }
        /////////////////////////////////////////////////////////////////
        /////////////////////if validation succeeds//////////////////////
        setIsLoading(true);
        if (apiParams.fieldsMissing) {
            var newApiParams = __assign(__assign({}, apiParams), { fieldsMissing: false });
            setApiParams(newApiParams);
        }
        var data = apiParams.addEditAssetConfigData;
        var id = apiParams.addEditAssetConfigData.assetDetails.assetKey;
        //post request
        if (props.isNewUnit) {
            saveSourceData(data, 'assets')
                .then(function (res) {
                if (res !== undefined) {
                    props.onClose(); //close add/edit popup
                    props.setDeleteAsset(function (id) { return (id < 0 ? id - 1 : -1); }); //refresh Filter data for assets
                    props.refreshGrid(true); //change value so useEffect of FilterContainer is called once
                    props.refreshGrid(false); //change value to default
                }
                setIsLoading(false);
            })
                .catch(function (err) {
                setIsLoading(false);
            });
        }
        //put request
        else {
            editSourceData(data, id, 'assets')
                .then(function (res) {
                if (res !== undefined) {
                    props.onClose(); //close add/edit popup
                    props.refreshGrid(true); //change value so useEffect of FilterContainer is called once
                    props.refreshGrid(false); //change value to default
                }
                setIsLoading(false);
            })
                .catch(function (err) {
                setIsLoading(false);
            });
        }
    };
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Spinner, {}), _jsxs("div", { children: [_jsxs("ul", __assign({ className: "tab" }, { children: [_jsx("li", __assign({ className: "tablinks active", id: "details", onClick: function (e) { return openCity(e, 'details'); } }, { children: "Details" })), _jsx("li", __assign({ className: "tablinks", id: "assetCompanies", onClick: function (e) { return openCity(e, 'assetCompanies'); } }, { children: "Asset Companies" })), _jsx("li", __assign({ className: "tablinks", id: "assetCrossRefs", onClick: function (e) { return openCity(e, 'assetCrossReferences'); } }, { children: "Asset Cross Refs" })), _jsx("li", __assign({ className: "tablinks", id: "assetRampUpProfiles", onClick: function (e) { return openCity(e, 'assetRampUpProfiles'); } }, { children: "Asset Ramp Up Profiles" })), _jsx("li", __assign({ className: "tablinks", id: "regionalHierarchies", onClick: function (e) { return openCity(e, 'regionalHierarchies'); } }, { children: "Regional Hierarchies" })), !props.isNewUnit ? (_jsx("li", __assign({ className: "tablinks", id: "assetUnitDependencies", onClick: function (e) { return openCity(e, 'assetUnitDependencies'); } }, { children: "Asset Unit Dependencies" }))) : null, _jsx("li", __assign({ className: "tablinks", id: "assetHistory", onClick: function (e) { return openCity(e, 'assetHistory'); } }, { children: "Asset History" }))] })), _jsx("div", __assign({ id: "div-details", className: "tabcontent", style: {
                            display: 'block',
                        } }, { children: _jsx(DetailsTab, { isNewUnit: props.isNewUnit }) })), _jsx("div", __assign({ id: "div-assetCompanies", className: "tabcontent" }, { children: _jsx(AssetCompanies, {}) })), _jsx("div", __assign({ id: "div-assetCrossReferences", className: "tabcontent" }, { children: _jsx(AssetCrossReferencesTab, {}) })), _jsx("div", __assign({ id: "div-assetRampUpProfiles", className: "tabcontent" }, { children: _jsx(AssetRampUpProfiles, {}) })), _jsx("div", __assign({ id: "div-regionalHierarchies", className: "tabcontent" }, { children: _jsx(RegionalHierarchiesTab, {}) })), _jsx("div", __assign({ id: "div-assetUnitDependencies", className: "tabcontent" }, { children: _jsx(AssetUnitDependencies, {}) })), _jsx("div", __assign({ id: "div-assetHistory", className: "tabcontent" }, { children: _jsx(AssetHistoryTab, {}) })), _jsxs("div", __assign({ className: "text-right", id: "tab-buttons" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border btn-cancel", onClick: props.onClose }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary btn-submit", onClick: onSubmit }, { children: "Submit" }))] }))] })] }));
};
export default AssetConfigTabContainer;
