import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { Button, Icons, Popconfirm } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { StyledPopconfirm } from './ExportData.styles';
import { getExportButtonValues, getPopconfirmActions } from './export-data.utils';
import { displayNameMapping } from './export-data.mapping';
import { useLazyGetIncrementalCapacitiesQuery } from 'common/ducks/capacityApis';
import { useLazyGetAllMaintenanceEventsQuery } from 'common/ducks/maintenanceApis';
import { convertToCsvAndDownload } from 'common/utils/convertToCsv.util';
var Download = Icons.Download, Check = Icons.Check;
export var ExportData = function (_a) {
    var requestParams = _a.requestParams, type = _a.type, propertyName = _a.propertyName, orderBy = _a.orderBy, refetchingData = _a.refetchingData, totalRows = _a.totalRows;
    var fileName = "acm-".concat(type, "-events-").concat(dayjs().format('DD-MMM-YYYY'), ".csv");
    var buttonRef = useRef(null);
    var fetchCapacity = useLazyGetIncrementalCapacitiesQuery()[0];
    var fetchMaintenance = useLazyGetAllMaintenanceEventsQuery()[0];
    var _b = useState(false), isFetching = _b[0], setIsFetching = _b[1];
    var _c = useState(false), downloadInterrupted = _c[0], setDownloadInterrupted = _c[1];
    var _d = useState(false), downloadSuccessful = _d[0], setDownloadSuccessful = _d[1];
    var _e = useState(false), downloadWarning = _e[0], setDownloadWarning = _e[1];
    var fetchData = type === 'capacity' ? fetchCapacity : fetchMaintenance;
    var retry = function () {
        handleClick();
        setTimeout(function () {
            var _a;
            (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }, 100);
    };
    var handleClick = function (resume) {
        if (resume === void 0) { resume = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var _a, data, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (totalRows && totalRows >= 10000 && !resume) {
                            setDownloadWarning(true);
                            return [2 /*return*/];
                        }
                        setDownloadSuccessful(false);
                        setDownloadInterrupted(false);
                        setDownloadWarning(false);
                        setIsFetching(true);
                        return [4 /*yield*/, fetchData(__assign(__assign({}, requestParams), { page: 1, pageSize: 50000, orderBy: orderBy }), true)];
                    case 1:
                        _a = _b.sent(), data = _a.data, error = _a.error;
                        if (error) {
                            setDownloadInterrupted(true);
                        }
                        else {
                            convertToCsvAndDownload(data, propertyName, fileName, displayNameMapping);
                            setDownloadSuccessful(true);
                        }
                        setIsFetching(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    useEffect(function () {
        setDownloadInterrupted(false);
        setDownloadSuccessful(false);
        setDownloadWarning(false);
    }, [requestParams, orderBy, refetchingData]);
    var actions = getPopconfirmActions(downloadWarning, downloadSuccessful, function () { return handleClick(true); }, retry);
    var _f = getExportButtonValues(downloadSuccessful, downloadInterrupted, fileName), popConfirmTitle = _f.popConfirmTitle, popConfirmDescription = _f.popConfirmDescription, sentiment = _f.sentiment, icon = _f.icon;
    return (_jsx(StyledPopconfirm, __assign({ title: _jsx(Popconfirm.Title, { text: popConfirmTitle }), description: _jsx(Popconfirm.Description, { size: "small", text: popConfirmDescription }), sentiment: sentiment, actions: actions, icon: icon, hidden: !downloadSuccessful && !downloadInterrupted && !downloadWarning, style: {
            maxWidth: '386px',
            zIndex: -1,
        } }, { children: _jsx(Button, __assign({ loading: isFetching, disabled: !totalRows || downloadSuccessful || isFetching || refetchingData, size: "small", variant: "outlined", icon: downloadSuccessful ? _jsx(Check, { height: 100 }) : _jsx(Download, { height: 100 }), onClick: function () { return handleClick(); }, ref: buttonRef }, { children: downloadSuccessful ? 'Data Exported' : 'Export data' })) })));
};
