import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProgramsMenu from '../../components/programs/programsMenu';
import GlobalReferenceMenu from '../../components/globalReferenceData/globalReferenceMenu';
import './sideBarMenu.css';
import logo from '../../assets/images/shell-logo.png';
//main at the far left hand side
var sideBarMenu = /** @class */ (function (_super) {
    __extends(sideBarMenu, _super);
    function sideBarMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    sideBarMenu.prototype.render = function () {
        return (_jsxs("aside", __assign({ className: "sidebar-menu position-fixed h-100" }, { children: [_jsx("div", __assign({ className: "app-shell-logo py-3" }, { children: _jsx(Link, __assign({ to: "/", className: "home app-shell-logo" }, { children: _jsx("img", { alt: "shell-logo", src: logo }) })) })), _jsxs("ul", __assign({ className: "sidebar-menu-group" }, { children: [_jsx("li", __assign({ className: "sidebar-menu-item item-1" }, { children: _jsx(ProgramsMenu, {}) })), _jsx("li", __assign({ className: "sidebar-menu-item item-2" }, { children: _jsx(GlobalReferenceMenu, {}) }))] }))] })));
    };
    return sideBarMenu;
}(Component));
export default sideBarMenu;
