import { FunctionComponent } from 'react';

import RCTree, { TreeProps as RcTreeProps } from 'rc-tree';
import styled, { css } from 'styled-components';

import { ITreeProps } from './Tree.types';

const Checkbox = css<Required<Pick<RcTreeProps, 'prefixCls'>>>`
  ${({ prefixCls }) => css`
    &.${prefixCls}-checkbox {
      .shell-checkbox-label {
        display: none;
      }

      .${prefixCls}-checkbox-wrapper-checked, .${prefixCls}-checkbox-wrapper-indeterminate {
        display: none;
      }

      &-checked {
        .${prefixCls}-checkbox-wrapper-checked {
          display: initial;
        }

        .${prefixCls}-checkbox-wrapper-unchecked, .${prefixCls}-checkbox-wrapper-indeterminate {
          display: none;
        }
      }

      &-indeterminate {
        .${prefixCls}-checkbox-wrapper-indeterminate {
          display: initial;
        }

        .${prefixCls}-checkbox-wrapper-unchecked, .${prefixCls}-checkbox-wrapper-checked {
          display: none;
        }
      }
    }
  `}
`;

const Tree = styled(RCTree as unknown as FunctionComponent<RcTreeProps & Pick<ITreeProps, 'size'>>)`
  ${({ prefixCls, theme, size }) => css`
    ${theme.heading[5].bold}
    margin: 0;
    border: 1px solid transparent;
    color: ${theme.text.onSurface.strong};

    .${prefixCls} {
      /* Caret toggle */
      &-switcher {
        display: flex;
        margin-right: 8px;
      }

      /* Indent placeholder */
      &-indent-unit {
        display: inline-flex;
        width: 32px;
      }

      /* Node text */
      &-node-content-wrapper {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      /* SVG icon */
      &-iconEle {
        display: flex;
      }

      &-checkbox {
        margin-right: 8px;
      }

      &-treenode {
        display: flex;
        align-items: center;
        margin: 4px 0;
        padding: 0;
        white-space: nowrap;
        list-style: none;
        outline: 0;

        ${size ? theme.label[size].bold : theme.label.medium.bold}

        &-disabled {
          color: ${theme.text.onSurface.inactive};
          cursor: not-allowed;

          .${prefixCls}-checkbox {
            pointer-events: none;
          }
        }

        span {
          ${Checkbox}
        }

        &:not(&-show-line) {
          .${prefixCls}-treenode {
            .${prefixCls}-switcher-noop {
              background: none;
            }
          }
        }
      }
    }
  `}
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
`;

export { Tree, ExpandButton };
