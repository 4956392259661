import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, Modal, InputNumber, Select } from 'antd';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useApiContext } from './apiContext';
var Option = Select.Option;
var FeedstocksTab = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(''), title = _c[0], setTitle = _c[1];
    var _d = useState(-1), indexToEdit = _d[0], setIndexToEdit = _d[1];
    var feedstocks = apiParams.feedstocks;
    var visibleFeedstocks = feedstocks.filter(function (feed) {
        return !apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails.some(function (details) { return details.feedstockTypeKey === feed.id; });
    });
    var addDetails = function () {
        var index = apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails.length;
        setIndexToEdit(index);
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[index] = {};
        setApiParams(newParams);
        setTitle('Add Feedstock Details');
        setShowModal(true);
    };
    var editDetails = function (record) {
        var index = apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails.findIndex(function (item) { return item.feedstockTypeKey === record.feedstockTypeKey; });
        setTitle('Edit Feedstock Details');
        setIndexToEdit(index);
        setShowModal(true);
    };
    var isEdited = function () { return indexToEdit < apiParams.originalFeedstockLength; };
    var onOk = function () {
        var selection = apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit];
        if (typeof selection.feedstockTypeKey !== 'number' ||
            typeof selection.feedstockMaximumPct !== 'number' ||
            selection.feedstockMaximumPct < 0 ||
            selection.feedstockMaximumPct > 100) {
            toast.error('Feedstock and Max Percentage (between 0 and 100) are required', {
                autoClose: 5000,
                toastId: 'feestock-err',
            });
            return;
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit].isEdited = isEdited();
        newParams.originalAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit]));
        setApiParams(newParams);
        setShowModal(false);
    };
    var columns = [
        {
            width: '5%',
            render: function (_, record) { return (_jsx("div", __assign({ style: {
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: '16px',
                }, onClick: function () { return editDetails(record); } }, { children: _jsx("i", { className: "fa fa-pencil" }) }))); },
        },
        {
            title: 'Feedstock',
            dataIndex: 'feedstockTypeName',
            width: '20%',
        },
        {
            title: 'Max Flexibility (%)',
            dataIndex: 'feedstockMaximumPct',
        },
    ];
    var updatePct = function (details) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit].feedstockMaximumPct = details;
        setApiParams(newParams);
    };
    var addFeedstock = function (id) {
        var _a;
        var name = (_a = feedstocks.find(function (feedstock) { return feedstock.id === id; })) === null || _a === void 0 ? void 0 : _a.materialTypeNm;
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit].feedstockTypeKey = id;
        newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit].feedstockTypeName = name;
        setApiParams(newParams);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        if (!apiParams.originalAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit]) {
            newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails.splice(indexToEdit, 1);
        }
        else {
            newParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit]));
        }
        setApiParams(newParams);
        setShowModal(false);
        setIndexToEdit(-1);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("button", __assign({ className: "p-3", onClick: addDetails }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Feedstock" }))] })), _jsx(Table, { className: "p-3", dataSource: __spreadArray([], apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails, true), columns: columns, pagination: false }), _jsx(Modal, __assign({ centered: true, onCancel: onCancel, onOk: onOk, destroyOnClose: true, visible: showModal, maskClosable: false, title: title }, { children: _jsxs(_Fragment, { children: [_jsx(Select, __assign({ size: "large", disabled: title !== 'Add Feedstock Details', showSearch: true, optionFilterProp: "children", style: { width: '49%' }, placeholder: "Select Feedstock", onChange: function (e) { return addFeedstock(e); }, value: indexToEdit > -1
                                ? apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit].feedstockTypeName
                                : '' }, { children: visibleFeedstocks.map(function (feedstock) { return (_jsx(Option, __assign({ value: feedstock.id }, { children: feedstock.materialTypeNm }), feedstock.id)); }) })), _jsx(InputNumber, { autoFocus: title != 'Add Feedstock Details', placeholder: "Enter Max Percentage", size: "large", className: "pull-right", style: { width: '49%' }, onChange: function (e) { return updatePct(e); }, value: indexToEdit > -1
                                ? apiParams.addEditAssetUnitConfigData.assetUnitPetchemFeedstockDetails[indexToEdit]
                                    .feedstockMaximumPct
                                : null, onPressEnter: onOk, maxLength: 3 })] }) }))] }));
};
export default FeedstocksTab;
