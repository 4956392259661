import styled, { css } from 'styled-components';

import { loadingConsts, LoadingConstsColors } from './Loading.consts';
import { LoadingSize, LoadingType } from './Loading.types';
import { rotate } from '../../utils/styleUtils';
import { getStrokeDashArray, getStrokeDashoffset } from '../../utils/circleSvgUtils';

const Loading = styled.svg<{ size: LoadingSize }>`
  ${({ size }) => css`
    width: ${`${loadingConsts[size].viewBoxSize}px`};
    height: ${`${loadingConsts[size].viewBoxSize}px`};
    overflow: inherit;
    animation: ${rotate} 0.5s linear infinite;

    & > * {
      stroke-width: ${loadingConsts[size].strokeWidth}px;
    }
  `}
`;

const Circle = styled.circle`
  fill: transparent;
`;

const Background = styled(Circle)<{ size: LoadingSize }>`
  ${({ theme }) => css`
    stroke: ${theme.border.subtle};
  `}
`;

const Highlighted = styled(Circle)<{ size: LoadingSize; type: LoadingType; r: number }>`
  ${({ type, r, theme }) => css`
    stroke: ${LoadingConstsColors(theme).color[type]};
    stroke-linecap: round;
    stroke-dasharray: ${`${getStrokeDashArray(r)}`};
    stroke-dashoffset: ${getStrokeDashoffset(r, 25)};
    transition: stroke-dashoffset 1s;
  `}
`;

export { Loading, Background, Highlighted };
