import TabsRC from 'rc-tabs';
import styled, { css } from 'styled-components';

import { ITabsProps } from './Tabs.types';
import { Sizes, Transient, Positions } from '../../types/types';
import { ICON_CLASS } from '../../utils/constants';

const Tabs = styled(TabsRC)<Transient<ITabsProps, 'emphasis' | 'indicatorPosition'>>(
  ({ prefixCls, size, $emphasis, tabPosition, $indicatorPosition, theme }) => css`
    &.${prefixCls} {
      ${theme.paragraph.medium.normal};

      position: relative;
      display: flex;
      color: ${theme.text.onSurface.strong};
      overflow: hidden;

      & .${prefixCls}-nav {
        display: flex;
        flex: none;
        position: relative;

        &-measure,
        &-wrap {
          transform: translate(0);
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
        }

        &-list {
          position: relative;
          display: flex;
          transition: transform 0.3s;
        }

        &-operations {
          display: flex;

          &-hidden {
            position: absolute;
            visibility: hidden;
            pointer-events: none;
          }
        }
      }

      & .${prefixCls}-tab {
        position: relative;
        display: flex;
        align-items: center;
        padding: 4px 0;
        border: 0;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
        color: ${theme.text.onSurface.subtle};
        transition: 0.3s;

        &-btn {
          ${size === Sizes.Large ? theme.label.medium.normal : theme.label.small.normal};
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 7px 6px;
          border: 3px solid transparent;
          border-radius: 2px;

          &:hover {
            background-color: ${$emphasis ? theme.focus.subtle : theme.background.section};
          }

          &:focus {
            outline: none;
            border: 3px solid ${theme.focus.medium};
          }

          .${ICON_CLASS} path {
            fill: ${theme.text.onSurface.subtle};
          }
        }

        &-active {
          color: ${theme.text.onSurface.strong};

          .${ICON_CLASS} path {
            fill: ${theme.text.onSurface.strong};
          }
        }

        &-disabled {
          background: transparent;
          color: ${theme.text.onSurface.inactive};
          cursor: default;

          &:hover {
            background: none;
          }
        }
      }

      & .${prefixCls}-ink-bar {
        position: absolute;
        background: ${$emphasis ? theme.accent.primary.strong : theme.text.onSurface.strong};
        pointer-events: none;

        &-animated {
          transition: all 0.3s;
        }

        ${$indicatorPosition === Positions.Left &&
        tabPosition === Positions.Left &&
        css`
          left: 0;
          right: unset;
        `}

        ${$indicatorPosition === Positions.Right &&
        tabPosition === Positions.Right &&
        css`
          left: unset;
          right: 0;
        `}
      }

      & .${prefixCls}-extra-content {
        flex: none;
      }

      .${prefixCls}-content {
        display: flex;
        width: 100%;

        &-holder {
          flex: auto;
        }

        &-animated {
          transition: margin 0.3s;
        }
      }

      .${prefixCls}-tabpane {
        width: 100%;
        flex: none;

        &-hidden {
          display: none;
        }
      }

      &-top,
      &-bottom {
        flex-direction: column;
      }

      &-top .${prefixCls}-ink-bar, &-bottom .${prefixCls}-ink-bar {
        height: 3px;
      }

      &-top .${prefixCls}-ink-bar {
        bottom: 0;
      }

      &-bottom .${prefixCls}-nav {
        order: 1;
      }

      &-bottom .${prefixCls}-content {
        order: 0;
      }

      &-bottom .${prefixCls}-ink-bar {
        top: 0;
      }

      &-left .${prefixCls}-nav-wrap, &-right .${prefixCls}-nav-wrap {
        flex-direction: column;
        width: 100%;
      }

      &-left .${prefixCls}-ink-bar, &-right .${prefixCls}-ink-bar {
        width: 3px;
      }

      &-left .${prefixCls}-nav, &-right .${prefixCls}-nav {
        flex-direction: column;
        min-width: 50px;
      }

      /* stylelint-disable */
      &-left
        .${prefixCls}-nav-list,
        &-right
        .${prefixCls}-nav-list,
        &-left
        .${prefixCls}-nav-operations,
        &-right
        .${prefixCls}-nav-operations {
        flex: 1 0 auto;
        flex-direction: column;
      }
      /* stylelint-enable */

      &-left .${prefixCls}-ink-bar {
        right: 0;
      }

      &-right .${prefixCls}-nav {
        order: 1;
      }

      &-right .${prefixCls}-content {
        order: 0;
      }

      &-right .${prefixCls}-ink-bar {
        left: 0;
      }
    }
  `,
);

export { Tabs };
