import { __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pagination as SDSPagination } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
var StyledPaginationWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  ul {\n    margin-bottom: 0;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n\n  ul {\n    margin-bottom: 0;\n  }\n"])));
export var Pagination = function (_a) {
    var total = _a.total, pageSize = _a.pageSize, current = _a.current, onChange = _a.onChange;
    return (_jsxs(StyledPaginationWrapper, { children: ["Total ", total, " items", _jsx(SDSPagination, { size: "small", total: total, pageSize: pageSize, current: current, onChange: onChange })] }));
};
var templateObject_1;
