import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
var assetCompany = /** @class */ (function (_super) {
    __extends(assetCompany, _super);
    function assetCompany(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {};
        var foreginKeyParams = [
            {
                path: 'company',
                key: 'companyKey',
                cdName: 'companyCd',
            },
            {
                path: 'intelligentSourceType',
                key: 'intelligenceSourceTypeKey',
                cdName: 'intelligenceSourceTypeCd',
            },
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
            },
            {
                path: 'companyRoleType',
                key: 'compayRoleTypeKey',
                cdName: 'companyRoleTypeCd',
            },
            {
                path: 'assets',
                key: 'assetKey',
                cdName: 'assetNm',
            },
            {
                path: 'person',
                key: 'userKey',
                cdName: 'shellUserId',
            },
            {
                path: 'dataSet',
                key: 'datasetKey',
                cdName: 'datasetNm',
            },
        ];
        var columns = [
            {
                field: 'assetNm',
                title: 'Asset Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetNm',
                referenceTitle: 'Name',
                urlEndPoint: 'assets',
            },
            {
                field: 'companyCd',
                title: 'Company Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'companyCd',
                referenceTitle: 'Company Cd',
                urlEndPoint: 'company',
            },
            {
                field: 'companyRoleTypeCd',
                title: 'Company Role Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'companyRoleTypeCd',
                referenceTitle: 'Company Role Type Cd',
                urlEndPoint: 'companyRoleType',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider Name',
                urlEndPoint: 'dataProvider',
                loadDependent: 'loadSource',
            },
            {
                field: 'datasetNm',
                title: 'Data set Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'datasetNm',
                referenceTitle: 'Dataset Nm',
                urlEndPoint: 'dataSet',
                loadDependecy: 'dataProviderKey',
                loadField: 'dataProviderAbbr',
            },
            {
                field: 'startDt',
                title: 'Start Dt',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'endDt',
                title: 'End Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'equitySharePct',
                title: 'Equity Share PCT',
                editor: 'numeric',
            },
            {
                field: 'shellUserId',
                title: 'User Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
            },
            {
                field: 'intelligenceSourceTypeCd',
                title: 'Intelligence Source Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'intelligenceSourceTypeCd',
                referenceTitle: 'Intelligence Source Type Cd',
                urlEndPoint: 'intelligentSourceType',
            },
            {
                field: 'intelligenceSourceText',
                title: 'Intelligence Source Text',
            },
            {
                field: 'intelligenceSourceUrl',
                title: 'Intelligence Source URL',
            },
            {
                field: 'reportDt',
                title: 'Report Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'versionEffectiveDt',
                title: 'Version Effective Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'versionTerminationDt',
                title: 'Version Termination Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    assetCompany.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "assetCompanies", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "id", foreginKeyParams: this.state.foreginKeyParams }));
    };
    return assetCompany;
}(Component));
export default assetCompany;
