import React from 'react';

import * as Styled from '../../Popconfirm.styles';
import { IPopconfirmDescriptionProps } from './PopconfirmDescription.types';
import { Sizes } from '../../../../types/types';

const PopconfirmDescription = ({ size = Sizes.Medium, text, ...rest }: IPopconfirmDescriptionProps) => (
  <Styled.PopconfirmDescription {...rest} size={size}>
    {text}
  </Styled.PopconfirmDescription>
);

export default PopconfirmDescription;
