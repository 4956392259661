import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'common/utils/access-token/get-access-token';
//empty API on to which we can inject APIs.
//This is done so that we don't get one huge file with all APIs.
export var emptySplitApi = createApi({
    reducerPath: 'emptySplitApi',
    tagTypes: [
        'MaintenanceEventSummary',
        'Versions',
        'GroupedEvents',
        'IncrementalCapacities',
        'CapacityVersions',
        'MaintenanceAllEvents',
        'ACMHierarchyElement',
        'AssetCatalog',
        'OwnershipDetails',
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        credentials: 'include',
        prepareHeaders: function (headers) {
            headers.set('Content-Type', 'application/json;charset=UTF-8');
            var accessToken = getAccessToken();
            if (accessToken) {
                headers.set('Authorization', "Bearer ".concat(accessToken));
            }
            return headers;
        },
    }),
    endpoints: function () { return ({}); },
});
