import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getSourceData } from '../services/urlService';
import { filterBy } from '@progress/kendo-data-query';
import PreviousStateFunctionalComponent from './previousStateFunctionalComponent';
var _ = require('lodash');
function GridDropDownList(props) {
    var _a;
    var dataItem = props.dataItem, field = props.field;
    var dataValue = dataItem[field] === null ? '' : dataItem[field];
    var dropdownDataRef = useRef(0);
    var defaultItem = (_a = {},
        _a[field] = 'Select',
        _a.id = '',
        _a);
    /*
     * Hook constant to set state to the current functional component
     */
    var currentDataItem = dataItem[field] === null ? [] : [dataItem];
    var _b = useState([]), dropdownData = _b[0], setdropdownData = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var _d = useState(null), value = _d[0], setValue = _d[1];
    /*
     * Function to load dropdown list on opening the list
     */
    function onOpenDropdown() {
        if (dropdownData.length === 0 || (dataItem[field] === null && currentDataItem.length === 0)) {
            setLoader(true);
            var path = props.field !== 'companyCd' ? props.field : 'allCompanies';
            getSourceData(path).then(function (response) {
                if (typeof response !== 'undefined') {
                    var data = response['data'];
                    if (data !== 'empty' && response['data'].length > 0) {
                        dropdownDataRef.current = data;
                        setdropdownData(dropdownDataRef.current);
                        setLoader(false);
                    }
                }
            });
        }
    }
    /*
     * This will hold the previous state of the grid data in this functional component
     */
    var previousStateDropdownData = PreviousStateFunctionalComponent(dropdownDataRef.current);
    /*
     * Function to apply filterBy kendo function on the state data
     */
    function filterData(filter) {
        var data = previousStateDropdownData.slice();
        return filterBy(data, filter);
    }
    /*
     * Function to be called on search filter
     */
    function filterChange(event) {
        if (event.filter.value.length > 0) {
            setdropdownData(filterData(event.filter));
        }
        else {
            setdropdownData(dropdownDataRef.current);
        }
    }
    /*
     * Function to be called on change
     */
    function onChange(event) {
        var foreignKey = props.className.split(' ')[0];
        var data = props.dataItem;
        delete data[foreignKey];
        var keyId = event.target.value.id;
        _.set(data, foreignKey, keyId);
        var foreignKeyName = event.target.value[props.field];
        props.onChange({
            dataItem: data,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: foreignKeyName,
        });
        setValue(dropdownData.find(function (c) { return c[props.field] === event.target.value[props.field]; }));
    }
    /*
     * Function to reset value when cancelled or switched
     */
    useEffect(function () {
        if (!dataItem.inEdit) {
            setValue(null);
        }
    }, [dataItem.inEdit]);
    /*
     *Function to align list items to the left
     */
    function itemRender(li, itemProps) {
        var itemChildren = _jsx("span", __assign({ style: { textAlign: 'left' } }, { children: li.props.children }));
        return React.cloneElement(li, li.props, itemChildren);
    }
    /*
     *component jsx return
     */
    return (_jsx("td", { children: dataItem.inEdit ? (_jsx(DropDownList, { id: props.field, className: props.field, style: { width: '100%' }, data: dropdownData, textField: props.field, loading: loader, defaultItem: defaultItem, itemRender: itemRender, value: value
                ? value
                : dropdownData.length > 0
                    ? dropdownData.find(function (c) { return c[props.field] === dataItem[props.field]; })
                    : dataItem.event === 'edit'
                        ? dataItem
                        : defaultItem, onOpen: function () {
                onOpenDropdown();
            }, filterable: true, onChange: onChange, onFilterChange: filterChange })) : typeof dataValue !== 'undefined' ? (dataValue.toString()) : ('') }));
}
export default GridDropDownList;
