import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
var ChevronDown = Icons.ChevronDown, ChevronUp = Icons.ChevronUp, ChevronUpDown = Icons.ChevronUpDown;
export var StyledTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: nowrap;\n  white-space: nowrap;\n  cursor: pointer;\n  user-select: none;\n\n  span {\n    margin: 0;\n    padding: 0 0 0 4px;\n  }\n\n  svg {\n    opacity: 0.5;\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: nowrap;\n  white-space: nowrap;\n  cursor: pointer;\n  user-select: none;\n\n  span {\n    margin: 0;\n    padding: 0 0 0 4px;\n  }\n\n  svg {\n    opacity: 0.5;\n  }\n"])));
export var renderSortingTitle = function (handleSorting, title, dataIndex, current) {
    if (current.columnName === dataIndex && current.order === 'asc') {
        return (_jsxs(StyledTitle, __assign({ "aria-label": "sort-btn-asc-".concat(dataIndex), onClick: function () { return handleSorting({ columnName: dataIndex, order: 'desc' }); } }, { children: [title, _jsx("span", { children: _jsx(ChevronUp, { height: 16, width: 16 }) })] })));
    }
    if (current.columnName === dataIndex && current.order === 'desc') {
        return (_jsxs(StyledTitle, __assign({ "aria-label": "sort-btn-des-".concat(dataIndex), onClick: function () { return handleSorting({ columnName: dataIndex, order: 'none' }); } }, { children: [title, _jsx("span", { children: _jsx(ChevronDown, { height: 16, width: 16 }) })] })));
    }
    return (_jsxs(StyledTitle, __assign({ "aria-label": "sort-btn-none-".concat(dataIndex), onClick: function () { return handleSorting({ columnName: dataIndex, order: 'asc' }); } }, { children: [title, _jsx("span", { children: _jsx(ChevronUpDown, { height: 16, width: 16 }) })] })));
};
var templateObject_1;
