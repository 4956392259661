import { useEffect, useState } from 'react';
export var useDetectSystemTheme = function () {
    var getCurrentTheme = function () { return window.matchMedia('(prefers-color-scheme: dark)').matches; };
    var _a = useState(getCurrentTheme()), isDarkTheme = _a[0], setIsDarkTheme = _a[1];
    var mqListener = function (e) {
        setIsDarkTheme(e.matches);
    };
    useEffect(function () {
        var darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
        darkThemeMq.addEventListener('change', mqListener);
        return function () { return darkThemeMq.removeEventListener('change', mqListener); };
    }, []);
    return isDarkTheme;
};
