import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route, Redirect, useLocation } from 'react-router';
import { ROUTE_PATHS } from '.';
import { MaintenanceEventSummary } from 'pages/maintenance-event-summary';
import { Capacity } from 'pages/capacity';
import { Trends } from 'pages/trends';
import { AllReports } from 'pages/all-reports';
import { AssetCatalog } from 'pages/asset-catalog/AssetCatalog';
export var Router = function () {
    var search = useLocation().search;
    return (_jsxs(Switch, { children: [_jsx(Route, { path: ROUTE_PATHS.MAINTENANCE_EVENT_SUMMARY, render: function () { return _jsx(MaintenanceEventSummary, { title: "Maintenance Baseline \u2022 Asset Capacity & Maintenance" }); } }), _jsx(Route, { path: ROUTE_PATHS.CAPACITY, render: function () { return _jsx(Capacity, { title: "Capacity Baseline \u2022 Asset Capacity & Maintenance" }); } }), _jsx(Route, { path: ROUTE_PATHS.ASSET_CATALOG, component: AssetCatalog }), _jsx(Route, { path: ROUTE_PATHS.TRENDS, component: Trends }), _jsx(Route, { path: ROUTE_PATHS.ALL_REPORTS, component: AllReports }), _jsx(Redirect, { from: "/", to: ROUTE_PATHS.MAINTENANCE_EVENT_SUMMARY + search })] }));
};
