import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { Table, THead, TBody, TR, Flexbox, Resizer, SorterButton, Text, Tooltip, Button, Icons, } from '@sede-x/shell-ds-react-framework';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { StyledTD, StyledTH } from './AssetCatalog.styles';
import { StyledFooter } from 'components/table/table.styles';
import { Pagination } from 'components/pagination';
import { ProvidersTags } from 'components/providers-tags/ProvidersTags';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
import ConfigureColumns from 'components/configure-columns/ConfigureColumns';
import { LastEditedInfo } from 'components/last-edited-info/LastEditedInfo';
var Edit = Icons.Edit, Comment = Icons.Comment;
var ASSET_START_DEFAULT = '0001-01-01T00:00:00';
var ASSET_END_DEFAULT = '9999-12-31T00:00:00';
var CAPACITY_COLUMN = 'CDU capacity today';
var FIXED_COLUMN_WIDTHS = [0, 150];
var DEFAULT_VISIBLE_COLUMNS = [
    'Asset key',
    'Asset name',
    'Company',
    'Asset class',
    'Mapped source',
    'Start date',
    'Close date',
    CAPACITY_COLUMN,
    'Units',
    'Status',
    'Edited by',
    'Edited date',
    'ACM region',
    'ACM country',
    'Actions',
];
var INDEXFORGROUPINGS = [1, 11, 16];
var getColumns = function (handleDrawerVisibility) { return [
    {
        id: 'Asset key',
        header: 'Asset key',
        accessorKey: 'assetKey',
        size: 150,
        enableHiding: false,
    },
    {
        id: 'Asset name',
        header: 'Asset name',
        accessorKey: 'assetNm',
        size: 200,
        enableHiding: false,
    },
    {
        id: 'Company',
        header: 'Company',
        accessorKey: 'companyNm',
        cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
    },
    {
        id: 'Asset class',
        header: 'Asset class',
        accessorKey: 'assetClassDesc',
        size: 150,
    },
    {
        id: 'Mapped source',
        header: 'Mapped source',
        accessorKey: 'dataProviderAbbr',
        size: 250,
        cell: function (info) {
            if (info.getValue() === null) {
                return '—';
            }
            var providersArray = info.getValue().split('|');
            return _jsx(ProvidersTags, { providers: providersArray });
        },
    },
    {
        id: 'Start date',
        header: 'Start date',
        accessorKey: 'assetStartDate',
        size: 140,
        cell: function (info) {
            return info.getValue() === ASSET_START_DEFAULT ? '—' : dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY);
        },
    },
    {
        id: 'Close date',
        header: 'Close date',
        accessorKey: 'assetCloseDate',
        size: 140,
        cell: function (info) {
            return info.getValue() === ASSET_END_DEFAULT ? '—' : dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY);
        },
    },
    {
        id: CAPACITY_COLUMN,
        header: CAPACITY_COLUMN,
        accessorKey: 'assetSize',
        cell: function (info) {
            var component;
            if (info.getValue() === null) {
                component = '—';
            }
            else {
                var value = parseFloat(info.getValue()).toFixed(2);
                var valueArray = value.split('.');
                component = (_jsxs(_Fragment, { children: [_jsxs(Text, __assign({ size: "small" }, { children: [valueArray[0], ". "] })), _jsx(Text, __assign({ size: "small", prominence: "subtle" }, { children: valueArray[1] }))] }));
            }
            return (_jsx(Flexbox, __assign({ justifyContent: "flex-end", style: { marginRight: '12px' }, alignItems: "baseline" }, { children: component })));
        },
    },
    {
        id: 'Units',
        accessorKey: 'units',
        size: 120,
        header: function () { return (_jsxs(Flexbox, __assign({ alignItems: "flex-end", gap: "4px" }, { children: ["Units", _jsx(Tooltip, { delay: 0, closeDelay: 0, overlay: "Total number of units in an asset", placement: "bottom", trigger: _jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77818 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77818 12.8333 6.99984 12.8333ZM6.70817 5.24996C6.54709 5.24996 6.4165 5.11938 6.4165 4.95829V4.37496C6.4165 4.21388 6.54709 4.08329 6.70817 4.08329H7.2915C7.45259 4.08329 7.58317 4.21388 7.58317 4.37496V4.95829C7.58317 5.11938 7.45259 5.24996 7.2915 5.24996H6.70817ZM6.70817 9.91663C6.54709 9.91663 6.4165 9.78604 6.4165 9.62496V6.70829C6.4165 6.54721 6.54709 6.41663 6.70817 6.41663H7.2915C7.45259 6.41663 7.58317 6.54721 7.58317 6.70829V9.62496C7.58317 9.78604 7.45259 9.91663 7.2915 9.91663H6.70817Z", fill: "#4B78AA" }) })) })] }))); },
    },
    {
        id: 'Status',
        header: 'Status',
        accessorKey: 'assetOperationalStatus',
        size: 120,
        cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
    },
    {
        id: 'Edited by',
        header: 'Edited by',
        accessorKey: 'metaChangedByNm',
        size: 120,
        cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
    },
    {
        id: 'Edited date',
        header: 'Edited date',
        accessorKey: 'metaChangedDttm',
        size: 150,
        cell: function (info) { return dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY); },
    },
    {
        id: 'ACM region',
        header: 'ACM region',
        accessorKey: 'acmRegion',
        size: 150,
        cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
    },
    {
        id: 'ACM country',
        header: 'ACM country',
        accessorKey: 'acmCountry',
        size: 150,
        cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
    },
    {
        id: 'Latitude',
        header: 'Latitude',
        accessorKey: 'latitudeNum',
        size: 120,
        cell: function (info) { return (info.getValue() ? parseFloat(info.getValue()).toFixed(4) : '—'); },
    },
    {
        id: 'Longitude',
        header: 'Longitude',
        accessorKey: 'longitudeNum',
        size: 120,
        cell: function (info) { return (info.getValue() ? parseFloat(info.getValue()).toFixed(4) : '—'); },
    },
    {
        id: 'Actions',
        header: 'Actions',
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        size: 100,
        cell: function (_a) {
            var _b;
            var row = _a.row;
            var record = row.original;
            return (_jsxs(Flexbox, { children: [_jsx(Button, { onClick: function () { return handleDrawerVisibility(true, row.original); }, size: "small", variant: "transparent", iconOnly: true, icon: _jsx(Edit, {}), title: "Edit asset" }), _jsx(Tooltip, { delay: 0, closeDelay: 0, overlay: _jsxs(Flexbox, __assign({ flexDirection: "column", style: { maxWidth: '400px', padding: '8px' } }, { children: [_jsx(Text, __assign({ size: "medium" }, { children: (_b = record.comments) !== null && _b !== void 0 ? _b : 'No comments provided' })), _jsx(LastEditedInfo, { lastEditedBy: record.metaChangedByNm, lastEditedAt: record.metaChangedDttm })] })), placement: "bottom end", trigger: _jsx(Button, { size: "small", variant: "transparent", iconOnly: true, icon: _jsx(Comment, {}) }) })] }));
        },
    },
]; };
export var AssetCatalogTable = function (_a) {
    var _b;
    var assetsData = _a.assetsData, sorting = _a.sorting, setSorting = _a.setSorting, handlePaginationChange = _a.handlePaginationChange, currentPage = _a.currentPage, closeConfigureModal = _a.closeConfigureModal, isConfigureModalOpen = _a.isConfigureModalOpen, handleEditAsset = _a.handleEditAsset, selectedAssetKey = _a.selectedAssetKey;
    var table = useReactTable({
        data: assetsData,
        columns: getColumns(handleEditAsset),
        getCoreRowModel: getCoreRowModel(),
        columnResizeMode: 'onChange',
        defaultColumn: {
            size: 200,
            minSize: 100,
            maxSize: 1000,
        },
        state: { sorting: sorting },
        manualSorting: true,
        onSortingChange: setSorting,
        sortDescFirst: true,
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: {
                    position: 'relative',
                    maxHeight: "calc(100vh - 230px )",
                    overflowY: 'auto',
                } }, { children: _jsxs(Table, __assign({ size: "small" }, { children: [_jsx(THead, __assign({ style: { borderRadius: '4px' } }, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header, index) {
                                    var _a;
                                    return (_jsx(StyledTH, __assign({ width: header.getSize(), fixedIndex: [0, 1].includes(index), fixedWidth: FIXED_COLUMN_WIDTHS[index], isActions: headerGroup.headers.length - 1 === index }, { children: _jsxs(Flexbox, __assign({ alignItems: "center" }, { children: [header.isPlaceholder ? null : (_jsxs(Flexbox, __assign({ onClick: function () { return header.column.toggleSorting(); }, alignItems: "center" }, { children: [flexRender(header.column.columnDef.header, header.getContext()), (_a = (header.column.getCanSort() &&
                                                            {
                                                                asc: _jsx(SorterButton, { sortDirection: "asc" }),
                                                                desc: _jsx(SorterButton, { sortDirection: "desc" }),
                                                            }[header.column.getIsSorted()])) !== null && _a !== void 0 ? _a : _jsx(SorterButton, {})] }))), _jsx(Resizer, { onMouseDown: header.getResizeHandler(), onTouchStart: header.getResizeHandler() })] })) }), header.id));
                                }) }), headerGroup.id)); }) })), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) { return (_jsx(TR, { children: row.getVisibleCells().map(function (cell, index) { return (_jsx(StyledTD, __assign({ isSelected: selectedAssetKey === row.original.assetKey, width: cell.column.getSize(), fixedIndex: [0, 1].includes(index), fixedWidth: FIXED_COLUMN_WIDTHS[index], isActions: row.getVisibleCells().length - 1 === index, onClick: function () { return handleEditAsset(true, row.original); } }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id)); }) }, row.id)); }) })] })) })), _jsx(StyledFooter, { children: _jsx(Pagination, { pageSize: 20, current: currentPage, total: (_b = assetsData[0]) === null || _b === void 0 ? void 0 : _b.totalRows, size: "small", onChange: handlePaginationChange }) }), _jsx(ConfigureColumns, { onClose: closeConfigureModal, open: isConfigureModalOpen, table: table, defaultSelectedColumns: DEFAULT_VISIBLE_COLUMNS, groupIndex: INDEXFORGROUPINGS, description: "Asset catalog", localStorageId: "assetCatalogTableColumn" })] }));
};
