import { __assign, __extends, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as capacityScreenActions from '../../../redux/actions/capacityScreenActions';
import { bindActionCreators } from 'redux';
import { getSourceFilter } from '../../../services/urlService';
import ErrorBoundary from '../../../services/errorBoundary';
import Spin from '../../../commons/spin';
import SearchBox from './search-box';
import { If } from 'rc-if-else';
import Checkbox from './checkbox';
import _ from 'lodash';
var countriesSt = [];
var citiesSt = [];
var companySt = [];
var assetClassSt = [];
var assetsSt = [];
var assetUnitTypeSt = [];
var assetUnitSubtypesSt = [];
var assetUnit = [];
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSearch = function (query) {
            _this.setState({ searchQuery: query });
            return;
        };
        _this.getFilterData = function () {
            var _a = _this.state, searchQuery = _a.searchQuery, allData = _a.filterData;
            var filtered = allData;
            if (searchQuery)
                filtered = allData.filter(function (item) {
                    if (item[_this.props.code]) {
                        //special Characters need includes
                        return item[_this.props.code].toLowerCase().includes(_this.state.searchQuery.toLowerCase());
                    }
                    return true;
                });
            return filtered;
        };
        _this.onInputSelect = function (e) {
            var currentSelected = __spreadArray([], _this.state.currentSelectedValue, true);
            if (e.target.checked) {
                currentSelected.push(parseInt(e.target.value));
            }
            else {
                var index = currentSelected.indexOf(parseInt(e.target.value));
                currentSelected.splice(index, 1);
            }
            _this.setState(function (prevState, props) {
                return { currentSelectedValue: currentSelected };
            }, function () {
                var path = _this.props.path.split('?');
                _this.handleChange(path[0], _this.state.currentSelectedValue);
            });
        };
        _this.handleChange = function (path, currentSelected) {
            switch (path) {
                case 'Countries':
                    countriesSt = [];
                    currentSelected.forEach(function (value) {
                        countriesSt.push('co=' + value);
                    });
                    var eventManagementObjectCo = {
                        cities: 'Cities?' + countriesSt.join('&'),
                        company: 'Companies?' + countriesSt.join('&'),
                        assetClass: 'AssetClasses?' + countriesSt.join('&'),
                        assets: 'Assets/AssetFilter?' + countriesSt.join('&'),
                        assetUnitType: 'AssetUnitTypes?' + countriesSt.join('&'),
                        assetUnitSubtype: 'AssetUnitSubtypes?' + countriesSt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' + countriesSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectCo, path, currentSelected, currentSelected.length);
                    break;
                case 'Cities':
                    citiesSt = [];
                    currentSelected.forEach(function (value) {
                        citiesSt.push('ci=' + value);
                    });
                    var eventManagementObjectCt = {
                        company: 'Companies?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                        assetClass: 'AssetClasses?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                        assets: 'Assets/AssetFilter?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                        assetUnitType: 'AssetUnitTypes?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                        assetUnitSubtype: 'AssetUnitSubtypes?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' + countriesSt.join('&') + '&' + citiesSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectCt, path, currentSelected, currentSelected.length);
                    break;
                case 'Companies':
                    companySt = [];
                    currentSelected.forEach(function (value) {
                        companySt.push('o=' + value);
                    });
                    var eventManagementObjectCom = {
                        assetClass: 'AssetClasses?' + countriesSt.join('&') + '&' + citiesSt.join('&') + '&' + companySt.join('&'),
                        assets: 'Assets/AssetFilter?' + countriesSt.join('&') + '&' + citiesSt.join('&') + '&' + companySt.join('&'),
                        assetUnitType: 'AssetUnitTypes?' + countriesSt.join('&') + '&' + citiesSt.join('&') + '&' + companySt.join('&'),
                        assetUnitSubtype: 'AssetUnitSubtypes?' + countriesSt.join('&') + '&' + citiesSt.join('&') + '&' + companySt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectCom, path, currentSelected, currentSelected.length);
                    break;
                case 'AssetClasses':
                    assetClassSt = [];
                    currentSelected.forEach(function (value) {
                        assetClassSt.push('at=' + value);
                    });
                    var eventManagementObjectAssetClasses = {
                        assets: 'Assets/AssetFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&'),
                        assetUnitType: 'AssetUnitTypes?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&'),
                        assetUnitSubtype: 'AssetUnitSubtypes?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectAssetClasses, path, currentSelected, currentSelected.length);
                    break;
                case 'Assets/AssetFilter':
                    assetsSt = [];
                    currentSelected.forEach(function (value) {
                        assetsSt.push('a=' + value);
                    });
                    var eventManagementObjectAssets = {
                        assetUnitType: 'AssetUnitTypes?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&'),
                        assetUnitSubtype: 'AssetUnitSubtypes?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectAssets, path, currentSelected, currentSelected.length);
                    break;
                case 'AssetUnitTypes':
                    assetUnitTypeSt = [];
                    currentSelected.forEach(function (value) {
                        assetUnitTypeSt.push('ut=' + value);
                    });
                    var eventManagementObjectUnitType = {
                        assetUnitSubtype: 'AssetUnitSubtypes?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&') +
                            '&' +
                            assetUnitTypeSt.join('&'),
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&') +
                            '&' +
                            assetUnitTypeSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectUnitType, path, currentSelected, currentSelected.length);
                    break;
                case 'AssetUnitSubtypes':
                    assetUnitSubtypesSt = [];
                    currentSelected.forEach(function (value) {
                        assetUnitSubtypesSt.push('ust=' + value);
                    });
                    var eventManagementObjectSubType = {
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&') +
                            '&' +
                            assetUnitTypeSt.join('&') +
                            '&' +
                            assetUnitSubtypesSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectSubType, path, currentSelected, currentSelected.length);
                    break;
                case 'AssetUnits/AssetUnitFilter':
                    assetUnit = [];
                    var eventManagementObjectUnit = {
                        assetUnit: 'AssetUnits/AssetUnitFilter?' +
                            countriesSt.join('&') +
                            '&' +
                            citiesSt.join('&') +
                            '&' +
                            companySt.join('&') +
                            '&' +
                            assetClassSt.join('&') +
                            '&' +
                            assetsSt.join('&') +
                            '&' +
                            assetUnitTypeSt.join('&') +
                            '&' +
                            assetUnitSubtypesSt.join('&'),
                    };
                    _this.props.eventManagement(eventManagementObjectUnit, path, currentSelected, currentSelected.length, false);
                    break;
                default:
                    break;
            }
        };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.resetSelectionOnFilter = function () {
            countriesSt = [];
            citiesSt = [];
            companySt = [];
            assetClassSt = [];
            assetsSt = [];
            assetUnitTypeSt = [];
            assetUnitSubtypesSt = [];
        };
        //to test
        _this.renderList = function (item) {
            var dataActive;
            var currentSelectedInList = _this.state.currentSelectedValue;
            if (_.indexOf(currentSelectedInList, item.id) > -1) {
                dataActive = true;
            }
            else {
                dataActive = false;
            }
            return (_jsx(If, __assign({ condition: item[_this.props.code] }, { children: _jsx("div", __assign({ className: "py-2 ml-2 item-line-height" }, { children: _jsx(Checkbox, { name: item[_this.props.code], id: item.id, checkboxType: _this.props.path.split('?')[0], title: _this.props.path, onChange: _this.onInputSelect, value: item.id, isCheck: dataActive, capacity: _this.props.code == 'assetUnitNm' ? item.totalCapacity : null, uom: _this.props.code == 'assetUnitNm' ? item.measureUnit.measureUnitCd : null }, item.id + '-' + _this.getRandom() + '-' + _this.props.path.split('?')[0]) }), item.id + '-' + _this.getRandom() + '-' + _this.props.path.split('?')[0]) }), item.id + '-' + _this.getRandom() + '-' + _this.props.path.split('?')[0]));
        };
        _this.state = {
            searchQuery: '',
            selectSearchitem: null,
            loader: false,
            filterData: [],
            path: _this.props.path,
            reload: false,
            nodata: false,
            // currentSelectedValue: this.props.isReset
            //   ? this.props.previousSelection
            //   : [],
            currentSelectedValue: [],
            allItemsInList: [],
        };
        return _this;
    }
    Filter.prototype.componentDidMount = function () {
        var _this = this;
        var path = this.state.path;
        this.setState({
            loading: true,
            currentSelectedValue: this.props.onSwitchPreviousSelectedItems && this.props.onSwitchPreviousSelectedItems.length > 0
                ? this.props.onSwitchPreviousSelectedItems
                : [],
        }, function () {
            var path = _this.props.path.split('?');
            _this.handleChange(path[0], _this.state.currentSelectedValue);
        });
        getSourceFilter(path)
            .then(function (filterData) {
            if (typeof filterData !== 'undefined' && typeof filterData.data !== 'undefined') {
                if (filterData.data.length > 0) {
                    _this.setState({ filterData: filterData.data, loading: false });
                }
                else if (filterData.data.length === 0) {
                    _this.setState({ nodata: true, loading: false });
                }
            }
            else {
                _this.setState({ nodata: true, loading: false });
            }
        })
            .catch(function (error) {
            // console.error("Error encountered: Details - " + error);
        });
        if (typeof path.split('?')[1] === 'undefined' || !path.split('?')[1]) {
            this.resetSelectionOnFilter();
        }
        if (this.props.path.split('?').filter(function (entry) {
            return /\S/.test(entry);
        }).length <= 1) {
            this.setState({
                currentSelectedValue: this.props.onSwitchPreviousSelectedItems && this.props.onSwitchPreviousSelectedItems.length > 0
                    ? this.props.onSwitchPreviousSelectedItems
                    : [],
            }, function () {
                var path = _this.props.path.split('?');
                _this.handleChange(path[0], _this.state.currentSelectedValue);
            });
        }
    };
    Filter.prototype.renderElement = function () {
        if (this.state.loading) {
            return _jsx(Spin, {});
        }
        else if (this.state.nodata) {
            return _jsx("span", { children: "No Data..." });
        }
    };
    Filter.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: _jsx("div", __assign({ className: "col-12 p-0" }, { children: _jsxs(ErrorBoundary, { children: [_jsx(SearchBox, { id: this.props.id, onChange: this.handleSearch, value: this.state.searchQuery }), _jsxs("div", __assign({ style: {
                                height: '300px',
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                            } }, { children: [_jsx(If, __assign({ condition: this.getFilterData().length > 0 }, { children: this.getFilterData().map(function (value, key) { return _this.renderList(value); }) })), this.renderElement()] }))] }) })) }, this.state.path));
    };
    return Filter;
}(Component));
export { Filter };
Filter.propTypes = {
//actions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        filterData: state.filterData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(capacityScreenActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
