import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import FilterBtn from './filterBtn';
import Badge from './badge';
import onClickOutside from 'react-onclickoutside';
import Filter from './Filter';
import { useApiContext } from '../../../../../../globalFilterContext/apiContext';
import { getSourceFilter } from '../../../../../../services/urlService';
import _ from 'lodash';
import { Select, DatePicker } from 'antd';
var Option = Select.Option;
var moment = require('moment');
function FilterContainer(props) {
    var badgeName = ['Regions', 'Countries'];
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), filterbtnisOn = _b[0], setFilterbtnisOn = _b[1];
    var _c = useState(false), startMonth = _c[0], setStartMonth = _c[1];
    var _e = useState(false), endMonth = _e[0], setEndMonth = _e[1];
    var _f = useState(null), currentSelectInFilter = _f[0], setCurrentSelectInFilter = _f[1];
    var _g = useState(false), resetReload = _g[0], setResetReload = _g[1];
    var _h = useState({
        regions: false,
        countries: false,
    }), loadingStatus = _h[0], setLoadingStatus = _h[1];
    var _j = useState(null), selectedArray = _j[0], setSelectedArray = _j[1];
    var _k = useState(false), multipleLoader = _k[0], setMultipleLoader = _k[1];
    var _l = useState([]), multipleSelectData = _l[0], setMultipleSelectData = _l[1];
    var _m = useState(-1), selectedBadgeIndex = _m[0], setSelectedBadgeIndex = _m[1];
    var _o = useState({
        countries: typeof apiParams.filter !== 'undefined'
            ? typeof apiParams.filter.countries !== 'undefined'
                ? apiParams.filter.countries
                : []
            : [],
        regions: typeof apiParams.filter !== 'undefined'
            ? typeof apiParams.filter.hierarchyElement !== 'undefined'
                ? apiParams.filter.hierarchyElement
                : []
            : [],
    }), filterInProgressive = _o[0], setFilterInProgressive = _o[1];
    var _p = useState({
        countries: 0,
        regions: 0,
    }), badgeObject = _p[0], setBadgeObject = _p[1];
    var _q = useState(0), electedBadgeIndex = _q[0], setElectedBadgeIndex = _q[1];
    var filterToLoad = ['regions', 'countries'];
    var filterAndCode = {
        regions: 'hierarchyElementNm',
        countries: 'geopoliticalEntityNm',
    };
    function onBadgeClick(e) {
        var filterDiv = document.getElementById('filterPanel');
        filterDiv.style.zIndex = '9999';
        setSelectedBadgeIndex(e.target.id);
        switch (e.target.id) {
            case '0':
                document.getElementById('regions-search-box').focus();
                break;
            case '1':
                document.getElementById('countries-search-box').focus();
                break;
            default:
                return;
        }
    }
    function versionHandleChange(type, e) {
        if (e) {
            var dateFormat = moment(e._d).format('YYYY-MM-DD');
            if (type === 'sd') {
                var object_1 = __assign(__assign({}, apiParams.refineryFilterSelection), { startDate: dateFormat });
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { refineryFilterSelection: object_1 });
                });
            }
            else {
                var object_2 = __assign(__assign({}, apiParams.refineryFilterSelection), { endDate: dateFormat });
                setApiParams(function (prevParams) {
                    return __assign(__assign({}, prevParams), { refineryFilterSelection: object_2 });
                });
            }
        }
    }
    function filterBtnToggle() {
        setFilterbtnisOn(!filterbtnisOn);
        var filterDiv = document.getElementById('filterPanel');
        if (filterDiv.style.zIndex === '-1') {
            filterDiv.style.zIndex = '9999';
        }
        else {
            filterDiv.style.zIndex = '-1';
        }
    }
    FilterContainer.handleClickOutside = function (e) {
        setFilterbtnisOn(!filterbtnisOn);
        var filterDiv = document.getElementById('filterPanel');
        filterDiv.style.zIndex = '-1';
        setSelectedBadgeIndex(-1);
        if (e === undefined)
            return;
        for (var i = 0; i < e.path.length; i++) {
            if (e.path[i].className) {
                return;
            }
        }
        setStartMonth(false);
        setEndMonth(false);
    };
    function applyOnCLick() {
        var filterDiv = document.getElementById('filterPanel');
        filterDiv.style.zIndex = '-1';
        setSelectedBadgeIndex(-1);
        props.apply();
    }
    useEffect(function () {
        var filter = ['regions', 'countries'];
        var index = filter.indexOf(currentSelectInFilter);
        var filteredArray = [];
        if (index > -1) {
            filteredArray = filter.filter(function (value, i) {
                if (i > index) {
                    return value;
                }
            });
        }
        if (filteredArray.length > 0) {
            filteredArray.map(function (value, i) {
                var _a, _b;
                var object = __assign(__assign({}, filterInProgressive), (_a = {}, _a[value] = [], _a));
                setFilterInProgressive(object);
                var loadingObject = __assign(__assign({}, loadingStatus), (_b = {}, _b[value] = true, _b));
                setLoadingStatus(loadingObject);
                var urlConstruct = [];
                filter.forEach(function (uCvalue, i) {
                    if (uCvalue !== value) {
                        urlConstruct.push('re=' + apiParams.refineryFilterSelection[uCvalue].join('&re='));
                    }
                });
                getSourceFilter(value + '?' + urlConstruct.join('&'))
                    .then(function (filterData) {
                    var _a, _b;
                    if (typeof filterData !== 'undefined' && typeof filterData.data !== 'undefined') {
                        var object_3 = __assign(__assign({}, filterInProgressive), (_a = {}, _a[value] = filterData.data, _a));
                        setFilterInProgressive(object_3);
                        var loadingObject_1 = __assign(__assign({}, loadingStatus), (_b = {}, _b[value] = false, _b));
                        setLoadingStatus(loadingObject_1);
                    }
                })
                    .catch(function (error) { });
            });
        }
    }, [apiParams.refineryFilterSelection.countries.length, apiParams.refineryFilterSelection.regions.length]);
    useEffect(function () {
        var object = __assign(__assign({}, apiParams.refineryFilterSelection), { countries: [] });
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { refineryFilterSelection: object });
        });
    }, [apiParams.refineryFilterSelection.regions.length]);
    var resetHandler = function () {
        setFilterInProgressive({
            countries: typeof apiParams.filter !== 'undefined'
                ? typeof apiParams.filter.countries !== 'undefined'
                    ? apiParams.filter.countries
                    : []
                : [],
            regions: typeof apiParams.filter !== 'undefined'
                ? typeof apiParams.filter.hierarchyElement !== 'undefined'
                    ? apiParams.filter.hierarchyElement
                    : []
                : [],
        });
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { refineryFilterSelection: {
                    countries: [],
                    regions: [],
                    startDate: new Date('JAN').setFullYear(new Date().getFullYear() - 1),
                    endDate: moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD'),
                } });
        });
        setResetReload(Math.floor(100000 + Math.random() * 900000));
        props.resetData();
    };
    var tagRender = function (props) {
        return 'Selected Items(0)';
    };
    var handleDropdownRender = function (origin) { };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (_jsx("div", __assign({ className: "container-fluid background-filter-fill pl-0 pr-0" }, { children: _jsxs("div", __assign({ className: "m-0  position-relative" }, { children: [_jsxs("div", __assign({ className: "row no-gutters justify-content-between pt-3 pb-3 pr-3 position-relative" }, { children: [_jsxs("div", __assign({ className: "col-sm-10 p-0 float-left" }, { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsx(FilterBtn, { isOn: filterbtnisOn, handleToggle: filterBtnToggle }) })), _jsx("div", __assign({ className: "region" }, { children: badgeName.map(function (name, index) {
                                        return (_jsx(Badge, { list: name + '-', name: name, count: typeof apiParams.refineryFilterSelection !== 'undefined' &&
                                                typeof apiParams.refineryFilterSelection[name.toLowerCase().replace(/ /g, '')] !== 'undefined' &&
                                                apiParams.refineryFilterSelection[name.toLowerCase().replace(/ /g, '')].length > 0
                                                ? apiParams.refineryFilterSelection[name.toLowerCase().replace(/ /g, '')].length
                                                : 0, onBadgeClick: onBadgeClick, id: index, selectedBadge: electedBadgeIndex }, name + '-' + index));
                                    }) }))] })), _jsx("div", __assign({ className: "col-sm-2 pr-3" }, { children: _jsxs("div", __assign({ className: "row justify-content-end" }, { children: [_jsx("div", __assign({ className: "mr-3" }, { children: _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear apply", onClick: applyOnCLick }, { children: ["Apply ", _jsx("i", { className: "fa fa-paint-brush" })] })) })) })), _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear", onClick: resetHandler }, { children: ["Reset ", _jsx("i", { className: "fa fa-undo" })] })) }))] })) }))] })), _jsx("div", __assign({ id: "filterPanel", className: "row capacity-select rounded border px-3 ml-0 mr-0", 
                    // style={{ display: "none" }}
                    style: { zIndex: '-1' } }, { children: filterToLoad.map(function (value, index) {
                        return (_jsxs("div", __assign({ className: "country f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: capitalizeFirstLetter(value) })), _jsx(Filter, { id: value, selectionCode: value, value: filterInProgressive[value], code: filterAndCode[value], loading: loadingStatus[value], setCurrentSelectInFilter: setCurrentSelectInFilter })] }), index + '-' + value));
                    }) })), _jsxs("div", __assign({ className: "row no-gutters border-top pr-3 pt-3 pb-3 select-buttons" }, { children: [_jsx("div", __assign({ className: 'ml-3' }, { children: _jsx(DatePicker, { defaultValue: moment(new Date('JAN').setFullYear(new Date().getFullYear() - 1)), value: moment(apiParams.refineryFilterSelection.startDate), size: "large", format: function (value) { return "Start Date: ".concat(value.format('MMM YYYY')); }, placeholder: "Start Date: Select", onChange: function (e) {
                                    versionHandleChange('sd', e);
                                }, picker: "month" }) })), _jsx("div", __assign({ className: 'ml-3' }, { children: _jsx(DatePicker, { defaultValue: moment(new Date(new Date().getFullYear(), 11, 31)), value: moment(apiParams.refineryFilterSelection.endDate), size: "large", format: function (value) { return "End Date: ".concat(value.format('MMM YYYY')); }, placeholder: "End Date: Select", onChange: function (e) {
                                    versionHandleChange('ed', e);
                                }, picker: "month" }) })), _jsxs("div", __assign({ className: "mr-3 ml-3 refinery-intake", style: {
                                border: '1px solid #595959',
                                padding: '3px 0 3px 10px',
                                borderRadius: '2px',
                            }, id: "provider" }, { children: ["Material Group Name:", _jsx(Select, { bordered: false, style: { width: '175px' }, disabled: true, defaultValue: 'Crude/Condensate' })] }))] })), _jsx("div", { className: "border-top mt-0 ml-0 mr-0 mb-3 p-0 shadow" })] })) })));
}
var clickOutsideConfig = {
    handleClickOutside: function () { return FilterContainer.handleClickOutside; },
};
export default onClickOutside(FilterContainer, clickOutsideConfig);
