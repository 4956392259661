import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Popover } from 'antd';
import { getSourceFilter } from '../../../../../../services/urlService';
import { VersionView } from './VersionView';
var VersionWindow = function (_a) {
    var unitCapacityId = _a.unitCapacityId, unitEventId = _a.unitEventId, capacity = _a.capacity, eventLockInd = _a.eventLockInd;
    var _b = useState([]), versionOrder = _b[0], setVersionOrder = _b[1];
    var _c = useState(false), show = _c[0], setShow = _c[1];
    var anchor = useRef(null);
    var handleToggle = function () {
        setShow(!show);
    };
    var versionDataLoad = function () {
        var capacityPath = "AssetUnitCapacities/".concat(unitCapacityId, "/versions");
        var maintanencePath = "AssetUnitEvents/".concat(unitEventId, "/versions");
        var path = capacity ? capacityPath : maintanencePath;
        getSourceFilter(path).then(function (returnData) {
            if (typeof returnData !== 'undefined' && returnData.data.length > 0) {
                setVersionOrder(returnData.data);
            }
        });
    };
    var handleButtonClick = function (e) {
        e.stopPropagation();
        handleToggle();
        versionDataLoad();
    };
    return (_jsxs("div", __assign({ style: { float: 'left', width: '100%', textAlign: 'center' } }, { children: [_jsxs(Tooltip, __assign({ openDelay: 10, position: "top", anchorElement: "target" }, { children: [eventLockInd ? (_jsx("button", __assign({ ref: anchor }, { children: _jsx("i", { className: "fa fa-lock", style: { color: '#fbce07', fontSize: '16px' }, title: "Locked" }) }))) : null, _jsx(Popover, __assign({ visible: show, placement: "bottomLeft", arrowPointAtCenter: true, content: _jsx("div", __assign({ className: "pt-2", style: { width: '60vw' } }, { children: _jsx(VersionView, { onToggle: handleToggle, versionOrder: versionOrder, capacity: capacity }) })) }, { children: _jsx("button", __assign({ onClick: handleButtonClick, style: { color: '#A6A6A6', textDecoration: 'underline' }, className: "pull-right" }, { children: _jsx("i", { className: "fa fa-history", style: { fontSize: '16px' }, title: "Version" }) })) }))] })), show && _jsx("div", { className: "overlay" })] })));
};
export default VersionWindow;
