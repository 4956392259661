import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import 'unorm';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux/configureStore';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './services/errorBoundary';
import { ApiProvider } from './globalFilterContext/apiContext';
import { AuthGuardProvider } from 'app/providers/auth/AuthGuardProvider';
var store = configureStore();
ReactDOM.render(_jsx(ReduxProvider, __assign({ store: store }, { children: _jsx(BrowserRouter, { children: _jsx(AuthGuardProvider, { children: _jsx(ErrorBoundary, { children: _jsx(ApiProvider, { children: _jsx(App, {}) }) }) }) }) })), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
