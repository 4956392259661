import styled, { css } from 'styled-components';

import { rgba2rgb } from '../../utils/colorUtils';
import { Z_INDEX } from '../../utils/constants';
import { tableConsts } from './Table.consts';
import { TableSize } from './Table.types';
import { Sizes } from '../../types/types';

type BaseTableProps = {
  prefixCls: string;
  size: TableSize;
};

const BaseTableStyles = styled.div<BaseTableProps>(
  ({ prefixCls, size, theme }) => css`
    width: 100%;

    .${prefixCls} {
      &-container table {
        border-collapse: collapse;
      }

      &-content table {
        width: 100%;
      }

      &-header table {
        width: 100%;
      }

      & tr:hover td {
        background-color: ${rgba2rgb(theme.focus.subtle, theme.background.surface)};
      }

      &-cell,
      &-no-data {
        ${(size === Sizes.ExtraSmall || size === Sizes.Small || size === Sizes.Medium) && theme.paragraph.small.normal};
        ${size === Sizes.Large && theme.paragraph.medium.normal};
        height: ${tableConsts[size].height};

        ${size === Sizes.ExtraSmall &&
        css`
          padding: 0 14px;
        `};

        ${size === Sizes.Small &&
        css`
          padding: 3px 14px;
        `};

        ${(size === Sizes.Medium || size === Sizes.Large) &&
        css`
          padding: 4px 14px;
        `};

        background-color: ${theme.background.surface};
        color: ${theme.text.onSurface.strong};
        box-sizing: border-box;
        overflow-wrap: break-word;
      }

      &-thead {
        .${prefixCls}-cell {
          background-color: ${theme.background.raised};
          ${(size === Sizes.ExtraSmall || size === Sizes.Small || size === Sizes.Medium) && theme.label.medium.bold};
          ${size === Sizes.Large && theme.label.large.bold};
          text-align: inherit;
        }

        th {
          position: relative;
          vertical-align: middle;
          border-bottom: 1px solid ${theme.border.medium};

          .react-resizable-handle-se {
            top: calc(50% - 9px);
            right: 14px;
            cursor: ew-resize;
          }

          .react-resizable-handle {
            position: absolute;
            width: 2px;
            height: 18px;
            border-radius: 1px;
            background-color: ${theme.border.medium};
          }
        }
      }

      &-tbody {
        .${prefixCls}-row:not(:last-of-type) {
          border-bottom: 1px solid ${theme.border.subtle};
        }

        div.${prefixCls}-cell {
          display: flex;
          align-items: center;
        }

        .row-checked {
          .${prefixCls}-cell, & + .${prefixCls}-expanded-row .${prefixCls}-cell {
            background-color: ${theme.focus.subtle};
          }
        }

        .${prefixCls}-expanded-row {
          border-bottom: 1px solid ${theme.border.subtle};
        }
      }

      &-column-sorter,
      &-filter-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 5px;
        padding: 2px;
        border-radius: 2px;

        path {
          fill: ${theme.text.onSurface.subtle};
        }
      }

      &-column-sorters,
      &-filter-column {
        display: flex;
        align-items: center;
      }

      &-column-sorted,
      &-filter-trigger.active {
        background-color: ${theme.focus.strong};

        path {
          fill: ${theme.text.onFocus.strong};
        }
      }

      &-expand-icon-col {
        width: 20px;
      }

      &-cell-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-cell-with-append {
        display: flex;
        align-items: center;

        > button {
          margin-right: 4px;
        }
      }

      &-cell-fix-left {
        z-index: ${Z_INDEX.ELEVATED};
      }
    }
  `,
);

export { BaseTableStyles };
