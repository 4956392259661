import { BadgeSize } from './Badge.types';
import { Sizes } from '../../types/types';

type BadgeConstsType = {
  [size in BadgeSize]: {
    size: number;
    icon: number;
    padding: string;
    gap: number;
  };
};

export const badgeConsts: BadgeConstsType = {
  [Sizes.Micro]: {
    size: 10,
    icon: 0,
    padding: '0',
    gap: 0,
  },
  [Sizes.ExtraSmall]: {
    size: 16,
    icon: 10,
    padding: '0 4px',
    gap: 4,
  },
  [Sizes.Small]: {
    size: 20,
    icon: 14,
    padding: '0 6px',
    gap: 4,
  },
  [Sizes.Medium]: {
    size: 28,
    icon: 16,
    padding: '0 8px',
    gap: 8,
  },
  [Sizes.Large]: {
    size: 32,
    icon: 18,
    padding: '0 12px',
    gap: 8,
  },
};
