import { __makeTemplateObject } from "tslib";
import styled from 'styled-components';
export var listWidth = 167;
export var panelLeftMargin = 44;
export var dayWidth = 36;
export var dayHeight = 40;
export var unitHeight = 24;
export var listItemMarginBottom = 24;
export var StyledHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  height: 40px;\n  width: 100%;\n  position: sticky;\n  top: 0;\n  background-color: ", ";\n  z-index: 10;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  height: 40px;\n  width: 100%;\n  position: sticky;\n  top: 0;\n  background-color: ", ";\n  z-index: 10;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
export var StyledDateSelector = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var StyledActions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  label {\n    margin-bottom: 0;\n    margin-right: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  label {\n    margin-bottom: 0;\n    margin-right: 5px;\n  }\n"])));
export var StyledPanelWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: ", "px;\n"], ["\n  margin-left: ", "px;\n"])), panelLeftMargin);
export var StyledDays = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  height: ", "px;\n  position: sticky;\n  top: 0;\n  background-color: ", ";\n  z-index: 10;\n"], ["\n  display: flex;\n  height: ", "px;\n  position: sticky;\n  top: 0;\n  background-color: ", ";\n  z-index: 10;\n"])), dayHeight, function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
export var StyledDay = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: ", "px;\n  text-align: left;\n  display: flex;\n  align-items: center;\n  label {\n    margin-bottom: 0;\n  }\n"], ["\n  width: ", "px;\n  text-align: left;\n  display: flex;\n  align-items: center;\n  label {\n    margin-bottom: 0;\n  }\n"])), dayWidth);
export var StyledPanel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);\n  background-size: ", "px ", "px;\n  background-position-x: 4px;\n  width: 100%;\n  height: 100%;\n"], ["\n  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);\n  background-size: ", "px ", "px;\n  background-position-x: 4px;\n  width: 100%;\n  height: 100%;\n"])), dayWidth, dayWidth);
export var StyledDivider = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 44px;\n\n  &:not(:first-child) {\n    margin-bottom: ", "px;\n  }\n"], ["\n  height: 44px;\n\n  &:not(:first-child) {\n    margin-bottom: ", "px;\n  }\n"])), listItemMarginBottom);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
