import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { PARAGRAPH_CLASS } from '../../../../utils/constants';
import * as Styled from './Text.styles';
import { ITextProps } from './Text.types';
import { getProminence, getTextType } from '../../utils/textUtils';
import { Sizes, TextTypes } from '../../../../types/types';

const Text = forwardRef<HTMLParagraphElement, ITextProps>(
  (
    { className = '', size = Sizes.Medium, type = TextTypes.Paragraph, gutter, bold, color, prominence, ...rest },
    ref,
  ): ReactElement => {
    const innerType = getTextType(type);
    const innerProminence = getProminence(color, prominence);

    return (
      <Styled.Text
        className={classNames(PARAGRAPH_CLASS, className)}
        size={size}
        as={innerType}
        gutter={gutter}
        bold={bold}
        prominence={innerProminence}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default Text;
