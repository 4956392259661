import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@sede-x/shell-ds-react-framework';
import { useGetAcmHierarchyElementQuery } from 'common/ducks/filtersApis';
import { newMapSelectData } from 'common/utils/newScreenUtils';
import { getAssetsAndOptions, getCompaniesAndOptions, getCountriesAndOptions, } from 'common/utils/get-filters-from-hierarchy';
import { loadingPlaceholder } from 'components/maintenance-filter/MaintenanceFilter.hooks';
import { FilterRow } from 'common/styles/FilterStyles';
import { FilterFieldWrapper, StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
import { Select } from 'components/select/Select';
import { useGetAssetClassesQuery } from 'common/ducks/maintenanceApis';
export var AssetsCatalogFilter = function (_a) {
    var _b = _a.regions, initialRegions = _b === void 0 ? [] : _b, _c = _a.location, initialLocation = _c === void 0 ? [] : _c, _d = _a.assets, initialAssets = _d === void 0 ? [] : _d, _e = _a.assetClasses, initialAssetClasses = _e === void 0 ? [] : _e, isSidebarOpen = _a.isSidebarOpen, onSubmit = _a.onSubmit;
    var form = useForm({
        defaultValues: {
            regions: initialRegions,
            location: initialLocation,
            assets: initialAssets,
            assetClasses: initialAssetClasses,
        },
    });
    var setValue = form.setValue, watch = form.watch, reset = form.reset, handleSubmit = form.handleSubmit, getValues = form.getValues;
    useEffect(function () {
        setValue('regions', initialRegions);
        setValue('location', initialLocation);
        setValue('assets', initialAssets);
        setValue('assetClasses', initialAssetClasses);
    }, [initialLocation, initialAssets, initialRegions, initialAssetClasses, setValue]);
    var watchRegions = watch('regions');
    var watchCountries = watch('location');
    var watchAssets = watch('assets');
    var watchAssetClasses = watch('assetClasses');
    var skip = !isSidebarOpen;
    var _f = useGetAcmHierarchyElementQuery(undefined, { skip: skip }), _g = _f.data, regions = _g === void 0 ? [] : _g, isFetching = _f.isFetching;
    var _h = useGetAssetClassesQuery(undefined, {
        skip: skip,
    }), _j = _h.data, assetClasses = _j === void 0 ? [] : _j, isFetchingAssetClasses = _h.isFetching;
    var assetClassesOptions = assetClasses.map(newMapSelectData('assetClassDesc', 'id'));
    var regionsOptions = useMemo(function () { return regions.map(newMapSelectData('regionName', 'regionKey')); }, [regions]);
    var _k = useMemo(function () { return getCountriesAndOptions(regions, watchRegions); }, [regions, watchRegions]), countries = _k.countries, countriesOptions = _k.countriesOptions;
    var companies = useMemo(function () { return getCompaniesAndOptions(regions, countries, watchRegions, watchCountries); }, [regions, countries, watchRegions, watchCountries]).companies;
    var assetsOptions = useMemo(function () {
        return getAssetsAndOptions({
            regions: regions,
            countries: countries,
            companies: companies,
            selectedRegions: watchRegions,
            selectedCountries: watchCountries,
            selectedCompanies: [],
        });
    }, [regions, countries, companies, watchRegions, watchCountries]).assetsOptions;
    var _l = useState(true), disableApply = _l[0], setDisableApply = _l[1];
    var resolveSelectOnChange = function (target) { return function (value) {
        switch (target) {
            case 'regions':
                reset(__assign(__assign({}, getValues()), { location: [], assets: [] }));
                break;
            case 'location':
                reset(__assign(__assign({}, getValues()), { assets: [] }));
                break;
        }
        setValue(target, value);
    }; };
    var dropdownsConfig = [
        {
            label: 'Regions',
            placeholder: loadingPlaceholder(isFetching),
            id: 'assets-filter-regions',
            data: regionsOptions,
            value: watchRegions,
            onChange: resolveSelectOnChange('regions'),
            disabled: isFetching,
        },
        {
            label: 'Countries',
            placeholder: loadingPlaceholder(isFetching),
            id: 'assets-filter-locations',
            data: countriesOptions,
            value: watchCountries,
            onChange: resolveSelectOnChange('location'),
            disabled: isFetching,
        },
        {
            label: 'Assets',
            placeholder: loadingPlaceholder(isFetching),
            id: 'assets-filter-assets',
            data: assetsOptions,
            value: watchAssets,
            onChange: resolveSelectOnChange('assets'),
            disabled: isFetching,
        },
        {
            label: 'Asset class',
            placeholder: loadingPlaceholder(isFetchingAssetClasses),
            id: 'assets-filter-classes',
            data: assetClassesOptions,
            value: watchAssetClasses,
            onChange: resolveSelectOnChange('assetClasses'),
            disabled: isFetchingAssetClasses,
        },
    ];
    var hasDirtyField = !!dropdownsConfig.map(function (el) { var _a; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.length; }).reduce(function (acc, curr) { return acc || curr; });
    return (_jsxs(_Fragment, { children: [dropdownsConfig.map(function (dropdown) {
                var optionFilterProp = dropdown.label === 'Assets' ? 'optionFilterProp' : 'children';
                return (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: dropdown.label })), _jsx(FilterFieldWrapper, { children: _jsx(Select, { size: "small", mode: "multiple", placeholder: dropdown.placeholder, id: dropdown.id, data: dropdown.data, value: dropdown.value, onChange: function (e) {
                                    setDisableApply(false);
                                    dropdown.onChange(e);
                                }, disabled: dropdown.disabled, optionFilterProp: optionFilterProp }) })] }, dropdown.id));
            }), _jsxs(FilterRow, { children: [_jsx(Button, __assign({ size: "small", variant: "outlined", onClick: function () { return reset({}); }, disabled: !hasDirtyField }, { children: "Reset" })), _jsx(Button, __assign({ size: "small", onClick: function (e) {
                            setDisableApply(true);
                            handleSubmit(onSubmit)(e);
                        }, disabled: disableApply }, { children: "Apply" }))] })] }));
};
