import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'common/hooks/store';
export var useSidebar = function (selectIsSidebarOpen, setSidebarOpenStateAction) {
    var isSidebarOpen = useAppSelector(selectIsSidebarOpen);
    var dispatch = useAppDispatch();
    var handleSidebarClose = useCallback(function () {
        dispatch(setSidebarOpenStateAction(false));
    }, [setSidebarOpenStateAction, dispatch]);
    var handleSidebarOpen = useCallback(function () {
        dispatch(setSidebarOpenStateAction(true));
    }, [setSidebarOpenStateAction, dispatch]);
    return { isSidebarOpen: isSidebarOpen, handleSidebarOpen: handleSidebarOpen, handleSidebarClose: handleSidebarClose };
};
