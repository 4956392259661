import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Flexbox, FormField, SingleDatePicker, TextInput, OptionGroup, Option, } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { StyledLockBaselineFormField } from '../AddEditMaintenanceEventDrawer.styles';
import { Select, StyledGroupedSelect } from 'components/select/Select';
import { SELECT_ITEM_PLACEHOLDER, resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
export var AddEditMandatoryFields = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var form = _a.form, units = _a.units, isEditingDisabled = _a.isEditingDisabled, assetEventTypes = _a.assetEventTypes, isFetchingAssetEventTypes = _a.isFetchingAssetEventTypes, eventCauseTypes = _a.eventCauseTypes, isFetchingEventCauseTypes = _a.isFetchingEventCauseTypes;
    var watch = form.watch, setValue = form.setValue, register = form.register, errors = form.formState.errors;
    var handleStartDateChange = function (startDate) {
        var _a;
        var date = (_a = startDate === null || startDate === void 0 ? void 0 : startDate.toISOString()) !== null && _a !== void 0 ? _a : dayjs().toISOString();
        setValue('startDate', date);
        if (startDate === null || startDate === void 0 ? void 0 : startDate.isAfter(form.getValues('endDate'))) {
            setValue('endDate', date);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Flexbox, __assign({ flexDirection: "row" }, { children: [_jsx(FormField, __assign({ size: "small", label: "Capacity offline", bottomLeftHelper: { content: (_b = errors.offlineCapacityOriginal) === null || _b === void 0 ? void 0 : _b.message, sentiment: 'negative' } }, { children: _jsx(TextInput, __assign({}, register('offlineCapacityOriginal', {
                            required: 'Please enter the Offline Capacity value',
                            validate: function (value) { return !isNaN(Number(value)) || 'Capacity must be a number'; },
                        }), { value: watch('offlineCapacityOriginal'), invalid: !!((_c = errors.offlineCapacityOriginal) === null || _c === void 0 ? void 0 : _c.message), placeholder: "Offline %", size: "small", inputMode: "numeric", suffix: { node: '%' }, disabled: isEditingDisabled })) })), _jsx(StyledLockBaselineFormField, __assign({ size: "small", label: "Lock baseline" }, { children: _jsx(Checkbox, __assign({}, register('lockBaseline'), { checked: !!watch('lockBaseline'), size: "xsmall", disabled: isEditingDisabled })) }))] })), _jsxs(Flexbox, __assign({ flexDirection: "row", gap: "3%" }, { children: [_jsx(FormField, __assign({ size: "small", label: "Start date", style: { width: '50%' } }, { children: _jsx(SingleDatePicker, __assign({}, register('startDate', {
                            required: 'Please select a Start date',
                        }), { elevation: true, disabled: isEditingDisabled, size: "small", value: dayjs(watch('startDate')), onChange: handleStartDateChange, format: "YYYY-MM-DD", allowClear: false })) })), _jsx(FormField, __assign({ size: "small", label: "End date", bottomLeftHelper: { content: (_d = errors.endDate) === null || _d === void 0 ? void 0 : _d.message, sentiment: 'negative' }, style: { width: '50%' } }, { children: _jsx(SingleDatePicker, __assign({}, register('endDate', {
                            required: 'Please select an End date',
                            validate: function (value) {
                                return dayjs(value).isSameOrAfter(watch('startDate'), 'day') || 'End date must be after start date';
                            },
                        }), { elevation: true, disabled: isEditingDisabled, size: "small", value: dayjs(watch('endDate')), onChange: function (date) { var _a; return setValue('endDate', (_a = date === null || date === void 0 ? void 0 : date.toISOString()) !== null && _a !== void 0 ? _a : dayjs().toISOString()); }, dropdownAlign: {
                                points: ['tr', 'br'],
                                offset: [0, 20],
                            }, format: "YYYY-MM-DD", allowClear: false })) }))] })), _jsxs(Flexbox, __assign({ flexDirection: "row", gap: "3%" }, { children: [_jsx(FormField, __assign({ size: "small", label: "Event type", bottomLeftHelper: { content: (_e = errors.eventType) === null || _e === void 0 ? void 0 : _e.message, sentiment: 'negative' }, style: { width: '50%' } }, { children: _jsx(Select, __assign({}, register('eventType', {
                            required: 'Please select an Event Type',
                        }), { disabled: isEditingDisabled, onChange: resolveOnSelectChange('eventType', form, units), value: watch('eventType'), data: assetEventTypes, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingAssetEventTypes, size: "small", invalid: !!((_f = errors.eventType) === null || _f === void 0 ? void 0 : _f.message), "data-testid": "select-event-type", allowClear: false })) })), _jsx(FormField, __assign({ size: "small", label: "Event cause", style: { width: '50%' } }, { children: _jsx(Select, { disabled: isEditingDisabled, onChange: resolveOnSelectChange('eventCause', form, units), value: watch('eventCause'), data: eventCauseTypes, placeholder: SELECT_ITEM_PLACEHOLDER, loading: isFetchingEventCauseTypes, size: "small", "data-testid": "select-event-cause", allowClear: false }) }))] })), _jsx(FormField, __assign({ size: "small", label: "Source", bottomLeftHelper: { content: (_g = errors.provider) === null || _g === void 0 ? void 0 : _g.message, sentiment: 'negative' } }, { children: _jsxs(StyledGroupedSelect, __assign({ disabled: isEditingDisabled }, register('provider', {
                    required: 'Please select the source',
                }), { onChange: resolveOnSelectChange('provider', form, units), value: watch('provider'), placeholder: SELECT_ITEM_PLACEHOLDER, size: "small", "data-testid": "select-intelligence-source", allowClear: false, invalid: !!((_h = errors.provider) === null || _h === void 0 ? void 0 : _h.message) }, { children: [_jsxs(OptionGroup, __assign({ label: "Internal source" }, { children: [_jsx(Option, __assign({ value: 19, disabled: true }, { children: "GENEVA" })), _jsx(Option, __assign({ value: 2, disabled: true }, { children: "GRAD" })), _jsx(Option, __assign({ value: 20 }, { children: "Internal" })), _jsx(Option, __assign({ value: 18 }, { children: "STMA" }))] })), _jsxs(OptionGroup, __assign({ label: "Data provider" }, { children: [_jsx(Option, __assign({ value: 6 }, { children: "GENSCAPE" })), _jsx(Option, __assign({ value: 9 }, { children: "IIR" }))] })), _jsxs(OptionGroup, __assign({ label: "Intel source" }, { children: [_jsx(Option, __assign({ value: 3 }, { children: "CITAC" })), _jsx(Option, __assign({ value: 24 }, { children: "ENT" })), _jsx(Option, __assign({ value: 7 }, { children: "ICIS" })), _jsx(Option, __assign({ value: 10 }, { children: "JLC" })), _jsx(Option, __assign({ value: 12 }, { children: "PM" })), _jsx(Option, __assign({ value: 25 }, { children: "REUTERS" })), _jsx(Option, __assign({ value: 16 }, { children: "SCI" }))] }))] })) }))] }));
};
