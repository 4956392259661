import { __assign, __spreadArray } from "tslib";
export var objToArray = function (obj) {
    var arr = [];
    for (var el in obj) {
        arr.push(obj[el]);
    }
    return arr;
};
export var combineRefineryIntake = function (arr1, arr2) {
    if (arr1 === void 0) { arr1 = []; }
    if (arr2 === void 0) { arr2 = []; }
    var obj = {};
    var combine = __spreadArray(__spreadArray([], arr1, true), arr2, true);
    combine.forEach(function (el) {
        if (el.countryId in obj) {
            var item = __assign({}, obj[el.countryId]);
            item.dataProviderLsts = __spreadArray(__spreadArray([], item.dataProviderLsts, true), [el.dataProviderLsts[0]], false);
            obj[el.countryId] = item;
        }
        else {
            obj[el.countryId] = el;
        }
    });
    return objToArray(obj);
};
