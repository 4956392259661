import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
var TopNav = function (_a) {
    var showingValueKbd = _a.showingValueKbd, 
    // capacityViewEvent,
    cvIsOn = _a.cvIsOn, collapseExpandEvent = _a.collapseExpandEvent, ceIsOn = _a.ceIsOn, filterShowEvent = _a.filterShowEvent, fsisOn = _a.fsisOn, expandStandardEvent = _a.expandStandardEvent, visualizationEvent = _a.visualizationEvent, chartEvent = _a.chartEvent, dailyMaintenanceEvent = _a.dailyMaintenanceEvent, maintenanceOn = _a.maintenanceOn, pathDaily = _a.pathDaily, pathEventSummary = _a.pathEventSummary, esIsOn = _a.esIsOn;
    return (_jsx("div", __assign({ className: "row justify-content-between no-gutters border-bottom shadow topbar background-filter-fill p-0" }, { children: _jsxs("div", __assign({ className: "col-sm-12 text-right mt-2" }, { children: [fsisOn ? (_jsx("span", __assign({ className: "mr-2 ml-2 topnav-span-text disabled-toggle" }, { children: "Filters Off" }))) : (_jsx("span", __assign({ className: "mr-2 ml-2 topnav-span-text" }, { children: "Filters Off" }))), _jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { type: "checkbox", checked: fsisOn, onChange: function (e) {
                                filterShowEvent(fsisOn ? false : true);
                            } }), _jsx("span", { className: "slider slider-toggle round" })] })), fsisOn ? (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text" }, { children: "Filters On" }))) : (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text disabled-toggle" }, { children: "Filters On" }))), _jsxs("span", __assign({ className: "topnav-span-text topnav-span-link mr-3", onClick: visualizationEvent }, { children: [_jsx("i", { className: "fa fa-bar-chart pr-2" }), "Data Visualization"] }))] })) })));
};
export default TopNav;
