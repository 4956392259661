export const toDecimal = (percent: number) => {
  if (percent === 0) {
    return 0;
  }

  return percent / 100;
};

export const getStrokeDashArray = (radius: number) => {
  const circumference = 2 * Math.PI * radius;

  return Math.round(circumference);
};

export const getStrokeDashoffset = (radius: number, value: number) => {
  const strokeOffset = getStrokeDashArray(radius) - getStrokeDashArray(radius) * toDecimal(value);

  return Math.round(strokeOffset);
};
