import React, { ReactElement, JSX } from 'react';

import { AlertSentiments, AlertSize } from '../Alert.types';
import { Sentiments, TextTypes, Sizes } from '../../../types/types';
import { QuestionMarkCircle, CheckCircle, CrossCircle, AlertCircle, InfoCircle } from '../../Icon/components';
import { HeadingType } from '../../Text/components/Heading/Heading.types';
import { alertConsts } from '../Alert.consts';
import { ALERT_CLASS } from '../../../utils/constants';

export const getAlertStateIcon = (sentiment: AlertSentiments, size: AlertSize): ReactElement => {
  const iconProps = {
    className: `${ALERT_CLASS}-icon`,
    width: alertConsts.icon[size],
    height: alertConsts.icon[size],
  };

  const icons: { [key: string]: JSX.Element } = {
    [Sentiments.Neutral]: <QuestionMarkCircle {...iconProps} />,
    [Sentiments.Positive]: <CheckCircle {...iconProps} />,
    [Sentiments.Negative]: <CrossCircle {...iconProps} />,
    [Sentiments.Warning]: <AlertCircle {...iconProps} />,
    [Sentiments.Information]: <InfoCircle {...iconProps} />,
  };

  return icons[sentiment];
};

export const getAlertLabelType = (size: AlertSize): HeadingType => {
  switch (size) {
    case Sizes.Small:
      return TextTypes.H5;
    case Sizes.Large:
      return TextTypes.H3;
    default:
      return TextTypes.H4;
  }
};
