import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { HEADING_CLASS } from '../../../../utils/constants';
import * as Styled from './Heading.styles';
import { IHeadingProps } from './Heading.types';
import { getProminence, getTextType } from '../../utils/textUtils';
import { TextTypes } from '../../../../types/types';

const Heading = forwardRef<HTMLHeadingElement, IHeadingProps>(
  ({ className = '', type = TextTypes.H1, level, gutter, bold, color, prominence, ...rest }, ref): ReactElement => {
    const innerType = getTextType(type);
    const innerProminence = getProminence(color, prominence);

    return (
      <Styled.Heading
        className={classNames(HEADING_CLASS, className)}
        as={innerType}
        level={level}
        gutter={gutter}
        bold={bold}
        prominence={innerProminence}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default Heading;
