import dayjs from 'dayjs';
import { DATE_FORMAT_YYYY_MM_DD, MAINTENANCE_NOTIFICATION_UNEXPECTED_OFF, MAINTENANCE_NOTIFICATION_UNEXPECTED_ON, } from 'common/constants';
export var valueOrEmptyString = function (bool, value) { return (bool ? value : ''); };
var getType = function (maintenanceQty) {
    if (maintenanceQty === 100) {
        return 'off';
    }
    if (maintenanceQty === 0) {
        return 'on';
    }
    return 'derate';
};
export var getEventStartDateForMonth = function (eventStartDate, monthDate) {
    var momentEventStart = dayjs(eventStartDate);
    var momentMonthDate = dayjs(monthDate);
    return momentEventStart.isBefore(momentMonthDate) ? monthDate : eventStartDate;
};
export var getEventEndDateForMonth = function (eventEndDate, monthDate) {
    var momentEventEvent = dayjs(eventEndDate);
    var momentMonthDate = dayjs(monthDate).endOf('month');
    return momentEventEvent.isAfter(momentMonthDate) ? momentMonthDate.format(DATE_FORMAT_YYYY_MM_DD) : eventEndDate;
};
export var getDaysCount = function (eventStartDate, eventEndDate, monthDate, addExtraDay) {
    if (addExtraDay === void 0) { addExtraDay = true; }
    var eventResolvedStartDate = getEventStartDateForMonth(eventStartDate, monthDate);
    var eventResolvedEndDate = getEventEndDateForMonth(eventEndDate, monthDate);
    return Math.min(dayjs(eventResolvedEndDate).diff(dayjs(eventResolvedStartDate), 'days') + (addExtraDay ? 1 : 0), dayjs(monthDate).daysInMonth());
};
export var updateSortedEvents = function (_a, monthDate, unitRow) {
    var unitEventStartDate = _a.startDate, unitEventEndDate = _a.endDate, maintenanceQty = _a.maintenanceQty, notificationClassCd = _a.notificationClassCd, notificationStDt = _a.notificationStDt, notificationEndDt = _a.notificationEndDt;
    var days = getDaysCount(unitEventStartDate, unitEventEndDate, monthDate);
    var startDate = getEventStartDateForMonth(unitEventStartDate, monthDate);
    /*
      Adding the lines of data that come from the the API
    */
    if (dayjs(startDate).isBefore(dayjs(monthDate).endOf('month')) && days > 0) {
        unitRow.lines.push({
            startDate: dayjs(startDate).format(DATE_FORMAT_YYYY_MM_DD),
            days: days,
            type: getType(maintenanceQty),
            unexpected: notificationClassCd === MAINTENANCE_NOTIFICATION_UNEXPECTED_OFF && getType(maintenanceQty) === 'off',
        });
    }
    /*
      Adding an event if we get an unexpected online event within the offline event
    */
    if (notificationClassCd === MAINTENANCE_NOTIFICATION_UNEXPECTED_ON &&
        (dayjs(notificationStDt).isBetween(monthDate, dayjs(monthDate).endOf('month'), 'day', '[]') ||
            dayjs(notificationEndDt).isBetween(monthDate, dayjs(monthDate).endOf('month'), 'day', '[]'))) {
        var unexpDays = getDaysCount(notificationStDt !== null && notificationStDt !== void 0 ? notificationStDt : '', notificationEndDt !== null && notificationEndDt !== void 0 ? notificationEndDt : '', monthDate);
        var unexpStartDate = getEventStartDateForMonth(notificationStDt !== null && notificationStDt !== void 0 ? notificationStDt : '', monthDate);
        unitRow.lines.push({
            startDate: dayjs(unexpStartDate).format(DATE_FORMAT_YYYY_MM_DD),
            days: unexpDays,
            type: 'on',
            unexpected: true,
        });
    }
};
export var updateUnitRow = function (line, i, arr, monthDate, finalLines) {
    var startDate = line.startDate;
    /*
      Checks if the first event is the day 1 of the month.
      If not, adds the line for the first day with correct amount of days.
    */
    if (!dayjs(startDate).isSame(dayjs(monthDate)) &&
        finalLines.every(function (_a) {
            var startDate = _a.startDate;
            return dayjs(startDate).date() !== 1;
        })) {
        var onEventStart = dayjs(monthDate).format(DATE_FORMAT_YYYY_MM_DD);
        finalLines.push({
            startDate: onEventStart,
            days: dayjs(startDate).diff(dayjs(monthDate), 'days'),
            type: 'on',
        });
    }
    /*
      Checks if we need to add a 'On' event between added events and current event
    */
    if (i !== 0 &&
        !finalLines.find(function (_a) {
            var everyStart = _a.startDate, days = _a.days;
            return dayjs(everyStart).add(days, 'days').isSame(dayjs(startDate));
        })) {
        var lastAddedLine = finalLines[finalLines.length - 1];
        // next start date is the previous event + its number of days
        var startDatePlusDays = dayjs(lastAddedLine.startDate)
            .add(lastAddedLine.days, 'days')
            .format(DATE_FORMAT_YYYY_MM_DD);
        finalLines.push({
            startDate: startDatePlusDays,
            // removing one day from the count as we already have the start added from previous event
            days: getDaysCount(startDatePlusDays, startDate, monthDate, false),
            type: 'on',
        });
    }
    finalLines.push(line);
    var momentEndOfMonth = dayjs(monthDate).endOf('month');
    /*
      Checks if we need to add a 'On' event for the end of the lines
    */
    if (i === arr.length - 1 &&
        !finalLines.find(function (_a) {
            var everyStart = _a.startDate, days = _a.days;
            return dayjs(everyStart)
                .add(days - 1, 'days')
                .isSame(momentEndOfMonth, 'day');
        })) {
        var lastAddedLine = finalLines[finalLines.length - 1];
        var startDatePlusDays = dayjs(lastAddedLine.startDate)
            .add(lastAddedLine.days, 'days')
            .format(DATE_FORMAT_YYYY_MM_DD);
        finalLines.push({
            startDate: startDatePlusDays,
            days: getDaysCount(startDatePlusDays, momentEndOfMonth.format(DATE_FORMAT_YYYY_MM_DD), monthDate),
            type: 'on',
        });
    }
};
