import React, { ReactElement } from 'react';

import {
  ArrowRight,
  CalendarEvent,
  CaretLeft,
  CaretRight,
  ChevronLeft,
  ChevronRight,
  Cross,
} from '../../../Icon/components';

export const NextIcon = (): ReactElement => <ChevronRight width={20} aria-label="Next" />;
export const SuperNextIcon = (): ReactElement => <CaretRight width={20} aria-label="Next" />;

export const PrevIcon = (): ReactElement => <ChevronLeft width={20} aria-label="Previous" />;
export const SuperPrevIcon = (): ReactElement => <CaretLeft width={20} aria-label="Previous" />;

export const SuffixIcon = (): ReactElement => <CalendarEvent width={20} aria-label="Month" />;

export const ClearIcon = (): ReactElement => <Cross width={20} aria-label="Clear" />;

export const Separator = (): ReactElement => <ArrowRight width={16} aria-label="Separator" />;
