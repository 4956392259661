import { LoadingType } from '../../Loading/Loading.types';
import { Sentiments, Variants } from '../../../types/types';
import { ButtonSentiment, ButtonVariant } from '../Button.types';

export const getLoaderType = (sentiment?: ButtonSentiment, variant?: ButtonVariant): LoadingType => {
  if (sentiment === Sentiments.Default) {
    return variant === Variants.Filled ? 'primary' : 'default';
  }

  return variant === Variants.Filled ? 'tertiary' : 'primary';
};
