import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { getSourceFilter } from '../../../../../services/urlService';
import { useApiContext } from './apiContext';
import Spinner from '../../../../../commons/spinner';
import SvgLogo from '../../../../../commons/tools/svgLogo';
var AssetListGrid = function (props) {
    useEffect(function () {
        //removing data stored in addEditAssetConfigData in Context when Add/Edit screen is closed.
        if (!props.isAddEditPopupVisible) {
            var resetConfigParams = __assign({}, apiParams);
            resetConfigParams.addEditAssetConfigData = {
                assetDetails: {
                    assetClassKey: null,
                    assetClassNm: '',
                    assetConfigurationTypeCd: '',
                    assetConfigurationTypeKey: null,
                    assetCreatedDate: null,
                    assetKey: 0,
                    assetNm: '',
                    assetStartDt: null,
                    industryTypeCd: '',
                    industryTypeKey: null,
                    cityId: 0,
                    cityNm: '',
                    countryId: null,
                    countryNm: '',
                    dataProviderAbbr: '',
                    dataProviderKey: null,
                    dataProviderNm: '',
                    geopoliticalEntityAbbr: '',
                    geopoliticalEntityCd: '',
                    geopoliticalEntityId: '',
                    geopoliticalEntityKey: 0,
                    geopoliticalEntityNm: '',
                    intelligenceSourceTxt: '',
                    intelligenceSourceTypeCd: '',
                    intelligenceSourceTypeDesc: '',
                    intelligenceSourceTypeKey: null,
                    intelligenceSourceUrl: '',
                    latitudeNum: '',
                    longitudeNum: '',
                    metaCreatedDttm: '',
                    petchemIntegrationInd: false,
                    subDivisionId: 0,
                    subDivisionNm: '',
                    userKey: null,
                },
                assetCompanies: [],
                assetCrossRefDetails: [],
                assetRampUp: [],
                assetUnitDependencyDetails: [],
                assetHistory: [],
                regionHierarchyDetails: [],
            };
            resetConfigParams.originalExtIdLength = null;
            resetConfigParams.originalCrossRefLength = null;
            resetConfigParams.originalRampUpLength = null;
            resetConfigParams.originalAssetCompaniesLength = null;
            resetConfigParams.originalAssetUnitDependencyDetailsLength = null;
            resetConfigParams.originalAssetConfigData = {
                assetCompanies: [],
                assetCrossRefDetails: [],
                assetRampUp: [],
                assetUnitDependencyDetails: [],
                regionHierarchyDetails: [],
            };
            setApiParams(resetConfigParams);
        }
    }, [props.isAddEditPopupVisible]);
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState([]), sort = _b[0], setSort = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    var EditButtonsCell = function (dataItemProps) {
        return (_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () {
                        onEditClick(dataItemProps.dataItem.id);
                    } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ className: "delete-asset", onClick: function () {
                        props.onDelete(dataItemProps.dataItem.id);
                    } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] }));
    };
    var onEditClick = function (id) {
        setLoader(true);
        var path = 'Assets/' + id;
        getSourceFilter(path).then(function (response) {
            if (response && response.data) {
                var newState = __assign({}, apiParams);
                newState.addEditAssetConfigData = response.data;
                newState.originalAssetConfigData = JSON.parse(JSON.stringify(response.data)); //deep copy so changes don't reflect here on editing
                newState.originalCrossRefLength = response.data.assetCrossRefDetails.length;
                newState.originalRampUpLength = response.data.assetRampUp.length;
                newState.originalAssetCompaniesLength = response.data.assetCompanies.length;
                newState.originalAssetUnitDependencyDetailsLength = response.data.assetUnitDependencyDetails.length;
                setApiParams(newState);
                props.showAddEditPopup(true);
            }
            setLoader(false);
        });
    };
    /**************Filtering data based on whether original records are to be displayed or not**********************/
    if (apiParams.assetListingData.length) {
        var filteredData = apiParams.assetListingData;
        return !filteredData.length ? (_jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0" }, { children: _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No Baseline records available to display." }))] })) })) })) })) : (_jsxs("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: [loader && _jsx(Spinner, {}), _jsxs(Grid, __assign({ data: orderBy(filteredData, sort), style: { height: '75vh' }, sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    } }, { children: [_jsx(Column, { width: "130px", cell: EditButtonsCell, filterable: false, resizable: false, reorderable: false, locked: true }), _jsx(Column, { field: "geopoliticalEntityNm", title: "Geographic Entity" }), _jsx(Column, { field: "ownerNm", title: "Owner" }), _jsx(Column, { field: "assetClassDesc", title: "Asset Class" }), _jsx(Column, { field: "assetNm", title: "Asset Name" })] }))] })));
    }
    /**************************************************************************************************/
    /**********************************default screen when no filters selected*************************/
    return (_jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0", id: "capacityscreen" }, { children: _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "Click on Apply to view" }))] })) })) })) }));
    /******************************************************************************************************/
};
export default AssetListGrid;
