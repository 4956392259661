import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useContext, useEffect } from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useApiContext } from './apiContext';
import { filterSearch } from './tools/filterSearch';
import { getSourceFilter } from '../../../../../services/urlService';
import Spinner from '../../../../../commons/spinner';
var DetailsTab = function (props) {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    // dropdown data states for Kendo Dropdowns
    var _c = useState(apiParams.dataProviders), dataProvidersDropdownData = _c[0], setDataProvidersDropdownData = _c[1];
    // const [assetDropdownData, setAssetDropdownData] = useState(apiParams.assetsFilterData);
    var _d = useState(apiParams.assetClassFilterData), assetClassDropdownData = _d[0], setAssetClassDropdownData = _d[1];
    var _e = useState(apiParams.intelligenceSourceTypes), sourceTypeDropdownData = _e[0], setSourceTypeDropdownData = _e[1];
    var _f = useState(apiParams.countriesFilterData), countriesDropdownData = _f[0], setCountriesDropdownData = _f[1];
    var _g = useState(apiParams.citiesFilterData), citiesDropdownData = _g[0], setCitiesDropdownData = _g[1];
    var _h = useState(apiParams.subdivisionsFilterData), subdivDropdownData = _h[0], setSubdivDropdownData = _h[1];
    var assetDetails = apiParams.addEditAssetConfigData.assetDetails;
    ////  useEffect for setting dropdown states when api call finishes
    useEffect(function () {
        setDataProvidersDropdownData(apiParams.dataProviders);
        // setAssetDropdownData(apiParams.assetsFilterData);
        setAssetClassDropdownData(apiParams.assetClassFilterData);
        setSourceTypeDropdownData(apiParams.intelligenceSourceTypes);
        setCountriesDropdownData(apiParams.countriesFilterData);
        setCitiesDropdownData(apiParams.citiesFilterData);
        setSubdivDropdownData(apiParams.subdivisionsFilterData);
    }, [
        apiParams.dataProviders,
        // apiParams.assetsFilterData,
        apiParams.assetClassFilterData,
        apiParams.intelligenceSourceTypes,
        apiParams.countriesFilterData,
        apiParams.citiesFilterData,
        apiParams.subdivisionsFilterData,
    ]);
    //functions for handling value changes
    var onPetchemChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.petchemIntegrationInd =
            !newParams.addEditAssetConfigData.assetDetails.petchemIntegrationInd;
        setApiParams(newParams);
    };
    var onAssetNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.assetNm = e.target.value;
        setApiParams(newParams);
    };
    var onAssetClassChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.assetClassKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.assetClassNm = e.target.value.assetClassCd;
        setApiParams(newParams);
    };
    var onProviderChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.dataProviderKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.dataProviderAbbr = e.target.value.dataProviderAbbr;
        setApiParams(newParams);
    };
    var onIntelligenceChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.intelligenceSourceTypeKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.intelligenceSourceTypeCd = e.target.value.intelligenceSourceTypeCd;
        setApiParams(newParams);
    };
    var onUrlChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.intelligenceSourceUrl = e.target.value;
        setApiParams(newParams);
    };
    var onIntelligenceSourceTextChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.intelligenceSourceTxt = e.target.value;
        setApiParams(newParams);
    };
    var onCountryChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.countryId = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.countryNm = e.target.value.geopoliticalEntityNm;
        newParams.addEditAssetConfigData.assetDetails.cityId = 0;
        newParams.addEditAssetConfigData.assetDetails.cityNm = '';
        newParams.addEditAssetConfigData.assetDetails.subDivisionId = 0;
        newParams.addEditAssetConfigData.assetDetails.subDivisionNm = '';
        setApiParams(newParams);
        setIsLoading(true);
        getSourceFilter('Cities?co=' + e.target.value.id)
            .then(function (res) {
            setIsLoading(false);
            if (res && res.data) {
                setApiParams(function (prev) {
                    return __assign(__assign({}, prev), { citiesFilterData: res.data });
                });
            }
            else {
                setApiParams(function (prev) {
                    return __assign(__assign({}, prev), { citiesFilterData: [] });
                });
            }
        })
            .catch(function (err) {
            setIsLoading(false);
        });
    };
    useEffect(function () {
        var path;
        if (assetDetails && assetDetails.countryId) {
            path = '/Cities?co=' + assetDetails.countryId;
        }
        else {
            path = '/Cities';
        }
        getSourceFilter(path)
            .then(function (res) {
            if (res && res.data) {
                setApiParams(function (prev) {
                    return __assign(__assign({}, prev), { citiesFilterData: res.data });
                });
            }
        })
            .catch(function (err) { });
    }, []);
    var onSubdivChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.subDivisionId = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.subDivisionNm = e.target.value.geopoliticalEntityNm;
        setApiParams(newParams);
    };
    var onCityChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.cityId = e.target.value.id;
        newParams.addEditAssetConfigData.assetDetails.cityNm = e.target.value.geopoliticalEntityNm;
        setApiParams(newParams);
    };
    var onLatitudeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.latitudeNum = e.target.value;
        setApiParams(newParams);
    };
    var onLongitudeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetDetails.longitudeNum = e.target.value;
        setApiParams(newParams);
    };
    var filterValueFromDropdown = function (id, obj) {
        var retVal = obj.filter(function (x) { return x.id === id; });
        return retVal[0];
    };
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Spinner, {}), _jsx("div", __assign({ id: "assetconfigwindow" }, { children: _jsxs("div", __assign({ style: { height: '625px', overflowX: 'hidden', overflowY: 'auto' } }, { children: [_jsxs("div", __assign({ className: "pannel mb-4" }, { children: [_jsx("div", __assign({ className: "row m-0 titlebar" }, { children: "Asset Details" })), _jsxs("div", __assign({ className: "row m-0" }, { children: [_jsx("div", __assign({ className: "col-12 p-0" }, { children: _jsxs("div", __assign({ className: "pull-right pl-3", style: { marginBottom: '10px', marginRight: '5rem' } }, { children: [_jsx("input", { type: "checkbox", id: "petchem-checkbox", name: "petchem-checkbox", className: "k-checkbox", value: assetDetails.petchemIntegrationInd, checked: assetDetails.petchemIntegrationInd, onChange: onPetchemChange }), _jsx("label", __assign({ htmlFor: "petchem-checkbox", className: "k-checkbox-label m-2" }, { children: "Petchem Integration" }))] })) })), _jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Asset Name"] })), _jsx("input", { autoComplete: "off", value: assetDetails.assetNm, onChange: onAssetNameChange, placeholder: "Enter a name", className: !assetDetails.assetNm && apiParams.fieldsMissing ? 'k-textbox k-state-invalid' : 'k-textbox' })] })), _jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Asset Class"] })), _jsx(DropDownList, { data: assetClassDropdownData, textField: "assetClassDesc", filterable: true, onFilterChange: function (e) {
                                                        setAssetClassDropdownData(filterSearch(apiParams.assetClassFilterData, e.filter));
                                                    }, className: assetDetails.assetClassKey == null && apiParams.fieldsMissing ? 'k-state-invalid' : null, value: assetDetails && assetDetails.assetClassKey
                                                        ? filterValueFromDropdown(assetDetails.assetClassKey, assetClassDropdownData)
                                                        : { assetClassDesc: 'Select' }, onChange: onAssetClassChange })] })), _jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Data Provider" })), _jsx(DropDownList, { data: dataProvidersDropdownData, textField: "dataProviderAbbr", name: "dataProviderAbbr", filterable: true, onFilterChange: function (e) {
                                                        setDataProvidersDropdownData(filterSearch(apiParams.dataProviders, e.filter));
                                                    }, 
                                                    // DIFFERENT "VALUE" LOGIC THAN OTHER DROPDOWNS BECAUSE DATA PROVIDERS MAY NOT BE COMMON FOR ALL ANALYSTS
                                                    value: assetDetails && assetDetails.dataProviderKey
                                                        ? { dataProviderAbbr: assetDetails.dataProviderAbbr }
                                                        : { dataProviderAbbr: 'Select' }, onChange: onProviderChange })] }))] })), _jsxs("div", __assign({ className: "row m-0 pt-30" }, { children: [_jsxs("div", __assign({ className: "col-4 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Rationale" })), _jsx(DropDownList, { data: sourceTypeDropdownData, textField: "intelligenceSourceTypeCd", name: "intelligenceSourceTypeCd", filterable: true, onFilterChange: function (e) {
                                                        setSourceTypeDropdownData(filterSearch(apiParams.intelligenceSourceTypes, e.filter));
                                                    }, value: assetDetails && assetDetails.intelligenceSourceTypeKey
                                                        ? filterValueFromDropdown(assetDetails.intelligenceSourceTypeKey, sourceTypeDropdownData)
                                                        : { intelligenceSourceTypeCd: 'Select' }, onChange: onIntelligenceChange })] })), _jsxs("div", __assign({ className: "col-8 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source URL" })), _jsx(Input, { name: "intelligentSourceURL", style: { width: '90%' }, placeholder: "Enter URL", value: assetDetails && assetDetails.intelligenceSourceUrl ? assetDetails.intelligenceSourceUrl : '', onChange: onUrlChange, autoComplete: "off" })] }))] })), _jsx("div", __assign({ className: "row m-0 pt-30" }, { children: _jsxs("div", __assign({ className: "col-12 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source Text" })), _jsx("textarea", { name: "intelligentSourceText", className: "textareaInput p-2", placeholder: "Enter text", value: assetDetails && assetDetails.intelligenceSourceTxt ? assetDetails.intelligenceSourceTxt : '', onChange: onIntelligenceSourceTextChange })] })) }))] })), _jsxs("div", __assign({ className: "pannel mb-4" }, { children: [_jsx("div", __assign({ className: "row m-0 titlebar" }, { children: "Asset Details" })), _jsxs("div", __assign({ className: "row m-0" }, { children: [_jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Country"] })), _jsx(DropDownList, { data: countriesDropdownData, textField: "geopoliticalEntityNm", name: "country", filterable: true, onFilterChange: function (e) {
                                                        setCountriesDropdownData(filterSearch(apiParams.countriesFilterData, e.filter));
                                                    }, className: assetDetails.countryNm == '' && apiParams.fieldsMissing ? 'k-state-invalid' : null, value: assetDetails && assetDetails.countryNm
                                                        ? filterValueFromDropdown(assetDetails.countryId, countriesDropdownData)
                                                        : { geopoliticalEntityNm: 'Select' }, onChange: onCountryChange })] })), _jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Subdivision" })), _jsx(DropDownList, { data: subdivDropdownData, textField: "geopoliticalEntityNm", name: "subdivision", filterable: true, onFilterChange: function (e) {
                                                        setSubdivDropdownData(filterSearch(apiParams.subdivisionsFilterData, e.filter));
                                                    }, value: assetDetails && assetDetails.subDivisionNm
                                                        ? filterValueFromDropdown(assetDetails.subDivisionId, subdivDropdownData)
                                                        : { geopoliticalEntityNm: 'Select' }, onChange: onSubdivChange })] })), _jsxs("div", __assign({ className: "col-4 p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "City" })), _jsx(DropDownList, { data: citiesDropdownData, textField: "geopoliticalEntityNm", name: "city", filterable: true, onFilterChange: function (e) {
                                                        setCitiesDropdownData(filterSearch(apiParams.citiesFilterData, e.filter));
                                                    }, value: assetDetails && assetDetails.cityNm
                                                        ? filterValueFromDropdown(assetDetails.cityId, citiesDropdownData)
                                                        : { geopoliticalEntityNm: 'Select' }, onChange: onCityChange })] }))] })), _jsxs("div", __assign({ className: "row m-0 pt-30" }, { children: [_jsxs("div", __assign({ className: "col-4 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Latitude" })), _jsx(Input, { name: "firstName", placeholder: "Enter a value", value: assetDetails && assetDetails.latitudeNum ? assetDetails.latitudeNum : '', onChange: onLatitudeChange, autoComplete: "off" })] })), _jsxs("div", __assign({ className: "col-4 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Longitude" })), _jsx(Input, { name: "firstName", placeholder: "Enter a value", value: assetDetails && assetDetails.longitudeNum ? assetDetails.longitudeNum : '', onChange: onLongitudeChange, autoComplete: "off" })] }))] }))] }))] })) }))] }));
};
export default DetailsTab;
