import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// randomchart = Math.random()
//     .toString(36)
//     .substring(2, 15) +
//     Math.random()
//       .toString(36)
//       .substring(2, 15)
var Checkbox = function (_a) {
    var name = _a.name, id = _a.id, onChange = _a.onChange, title = _a.title, _b = _a.value, value = _b === void 0 ? 'no' : _b, isCheck = _a.isCheck, typeOption = _a.typeOption, capacity = _a.capacity, uom = _a.uom;
    return (_jsxs("div", { children: [_jsx("input", { type: typeOption, className: 'k-' + typeOption, name: title, title: title, id: id + '-' + name, onChange: onChange, defaultChecked: isCheck, value: value }), _jsxs("label", __assign({ htmlFor: id + '-' + name, className: 'col-12 k-' + typeOption + '-label pl-4' }, { children: [name, ' ', capacity && uom ? (_jsxs("span", __assign({ className: "dim-text ml-1" }, { children: ["(", capacity, " ", uom, ")"] }))) : null] }))] }, id + '-' + name));
};
Checkbox.propTypes = {
//type: PropTypes.string,
// name: PropTypes.string.isRequired,
// checked: PropTypes.bool,
// onChange: PropTypes.func.isRequired
};
export default Checkbox;
