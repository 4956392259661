import Switch from 'rc-switch';
import styled, { css } from 'styled-components';

import { toggleConsts } from './Toggle.consts';
import { IToggleProps } from './Toggle.types';
import { Transient } from '../../types/types';
import Label from '../Text/components/Label/Label';

const Toggle: any = styled(Switch)<Required<Pick<IToggleProps, 'size'>>>`
  ${({ size, prefixCls, theme }) => {
    const { width, height, circle } = toggleConsts[size];

    return css`
      &.${prefixCls} {
        position: relative;
        display: block;
        width: ${width}px;
        height: ${height}px;
        padding: 0;
        border-radius: ${height / 2}px;
        border: 0;
        background-color: ${theme.border.medium};
        cursor: pointer;
        transition: background-color 0.3s ease-out;

        &:hover {
          background-color: ${theme.focus.medium};
        }

        &:focus {
          box-shadow: 0 0 0 3px ${theme.focus.medium};
          outline: none;
        }

        &-disabled {
          cursor: no-drop;
          background: ${theme.border.subtle};

          &-checked {
            background-color: ${theme.focus.subtle};
          }
        }

        &:after {
          position: absolute;
          width: ${circle.size}px;
          height: ${circle.size}px;
          left: ${circle.offset}px;
          top: ${circle.offset}px;
          border-radius: ${circle.size / 2}px;
          background-color: ${theme.text.onSecondary.strong};
          content: '';
          cursor: pointer;
          transition: width 0.3s ease-out, transform 0.3s ease-out;
        }

        &:active {
          &:after {
            width: ${circle.pressedSize}px;
          }
        }

        &-checked {
          background-color: ${theme.focus.strong};

          &:after {
            transform: translateX(${circle.onPosition}px);
          }
        }

        &-checked:active {
          &:after {
            transform: translateX(${circle.onPosition - circle.pressedSize + circle.size}px);
          }
        }
      }
    `;
  }}
`;

const ToggleLabel = styled(Label)``;

const ToggleWrapper = styled.div<
  Required<Pick<IToggleProps, 'size'>> & Transient<Pick<IToggleProps, 'disabled' | 'mirrored'>, 'disabled' | 'mirrored'>
>`
  ${({ size, $disabled, $mirrored, theme }) => css`
    display: flex;
    flex-direction: ${$mirrored ? 'row-reverse' : 'row'};
    align-items: center;

    ${ToggleLabel} {
      color: ${$disabled ? theme.text.onSurface.inactive : theme.text.onSurface.strong};

      ${$mirrored &&
      css`
        margin-left: unset;
        margin-right: ${toggleConsts[size].labelSpacing}px;
      `};

      ${!$mirrored &&
      css`
        margin-left: ${toggleConsts[size].labelSpacing}px;
        margin-right: unset;
      `};
    }
  `}
`;

export { Toggle, ToggleLabel, ToggleWrapper };
