var genevaReferenceMenu = {
    title: 'Global Reference Data',
    caption: 'General',
    menu: [
        {
            genevaMenu: {
                id: '0',
                uniqueKey: 1,
                title: 'Configuration Data',
                component_path: '',
            },
            items: [
                {
                    genevaMenu: {
                        id: '1',
                        uniqueKey: 2,
                        title: 'Companies',
                        component_path: '/dashboard/companiesconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 3,
                                title: 'Company Role Type',
                                component_path: '/dashboard/companiesConfiguration/companyRoleType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 4,
                                title: 'Ownership Class',
                                component_path: '/dashboard/companiesConfiguration/ownershipClass',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '2',
                        uniqueKey: 5,
                        title: 'Data Processing',
                        component_path: '/dashboard/dataprocessingconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 6,
                                title: 'Dataset Type',
                                component_path: '/dashboard/dataProcessingConfiguration/datasetType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 7,
                                title: 'Dataset Ingestion Method Type',
                                component_path: '/dashboard/dataProcessingConfiguration/datasetIngestionMethodType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 8,
                                title: 'Dataset Ingestion Technology Type',
                                component_path: '/dashboard/dataProcessingConfiguration/datasetIngestionTechnologyType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '4',
                                uniqueKey: 9,
                                title: 'Period type',
                                component_path: '/dashboard/dataProcessingConfiguration/periodType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '5',
                                uniqueKey: 10,
                                title: 'Time Zone',
                                component_path: '/dashboard/dataProcessingConfiguration/timeZone',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '3',
                        uniqueKey: 11,
                        title: 'Access & Security',
                        component_path: '/dashboard/accessandsecurityconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 12,
                                title: 'Employee Type',
                                component_path: '/dashboard/accessAndSecurityConfiguration/employeeType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 13,
                                title: 'User Role Type',
                                component_path: '/dashboard/accessandsecurityconfiguration/userroletype',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 14,
                                title: 'IT Application',
                                component_path: '/dashboard/accessAndSecurityConfiguration/itApplication',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '4',
                                uniqueKey: 15,
                                title: 'IT Application User',
                                component_path: '/dashboard/accessAndSecurityConfiguration/itApplicationUser',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '4',
                        uniqueKey: 16,
                        title: 'Licensing',
                        component_path: '/dashboard/licensingconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 17,
                                title: 'Data Provider Type',
                                component_path: '/dashboard/licensingConfiguration/dataProviderType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 18,
                                title: 'License Type',
                                component_path: '/dashboard/licensingConfiguration/licenseType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 19,
                                title: 'Split Cost Method Type',
                                component_path: '/dashboard/licensingConfiguration/splitCostMethodType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '4',
                                uniqueKey: 20,
                                title: 'Subscription Status Type',
                                component_path: '/dashboard/licensingConfiguration/subscriptionStatusType',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '5',
                        uniqueKey: 21,
                        title: 'Locations & Hierarchies',
                        component_path: '/dashboard/locationandhierarchyconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 22,
                                title: 'Geopolitical Entity Type',
                                component_path: '/dashboard/locationAndHierarchyConfiguration/geoPoliticalEntityType',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '6',
                        uniqueKey: 23,
                        title: 'Measure Units',
                        component_path: '/dashboard/measureunitsconfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 24,
                                title: 'Measure Unit Type',
                                component_path: '/dashboard/measureUnitsConfiguration/measureUnitType',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '7',
                        uniqueKey: 25,
                        title: 'Notification',
                        component_path: '/dashboard/notification',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 26,
                                title: 'Notification Class',
                                component_path: '/dashboard/notification/notificationClass',
                            },
                        },
                    ],
                },
            ],
            expanded: true,
        },
        {
            genevaMenu: {
                id: '1',
                uniqueKey: 25,
                title: 'Reference Data',
                component_path: '',
            },
            items: [
                {
                    genevaMenu: {
                        id: '1',
                        uniqueKey: 26,
                        title: 'Companies',
                        component_path: '/dashboard/companiesreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 27,
                                title: 'Company',
                                component_path: '/dashboard/companiesReference/company',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 29,
                                title: 'Company Cross Reference',
                                component_path: '/dashboard/companiesReference/companyCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '4',
                                uniqueKey: 30,
                                title: 'Company Role Type Cross Reference',
                                component_path: '/dashboard/companiesReference/companyRoleTypeCrossReference',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '2',
                        uniqueKey: 31,
                        title: 'Data Provider Service',
                        component_path: '/dashboard/dataproviderservicereference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 32,
                                title: 'Data Service Terminal Type',
                                component_path: '/dashboard/dataproviderServiceReference/dataServiceTerminalType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 33,
                                title: 'Direct Chargeback Type',
                                component_path: '/dashboard/dataProviderServiceReference/directChargebackType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 34,
                                title: 'Data Service Report Type',
                                component_path: '/dashboard/dataproviderservicereference/dataservicereporttype',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '5',
                        uniqueKey: 35,
                        title: 'Access & Security',
                        component_path: '/dashboard/accessandsecurityrefrence',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 36,
                                title: 'User Role',
                                component_path: '/dashboard/accessAndSecurityRefrence/userRole',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 37,
                                title: 'Assigned Role',
                                component_path: '/dashboard/accessAndSecurityRefrence/assignedRole',
                            },
                        },
                        // {
                        //   genevaMenu: {
                        //     id: "3",
                        //     uniqueKey: 38,
                        //     title: "Person",
                        //     component_path: "/dashboard/accessAndSecurityRefrence/person"
                        //   }
                        // }
                    ],
                },
                {
                    genevaMenu: {
                        id: '6',
                        uniqueKey: 39,
                        title: 'Locations & Hierarchies',
                        component_path: '/dashboard/locationandhierarchyreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 40,
                                title: 'Geopolitical Entity',
                                component_path: '/dashboard/locationAndHierarchyReference/geopoliticalEntity',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 41,
                                title: 'Geopolitical Entity Cross Reference',
                                component_path: '/dashboard/locationAndHierarchyReference/geopoliticalEntityCrossReference',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '7',
                        uniqueKey: 42,
                        title: 'Measure Units',
                        component_path: '/dashboard/measureunitsreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 43,
                                title: 'Measure Unit (UOM)',
                                component_path: '/dashboard/measureUnitsReference/measureUnit',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 44,
                                title: 'Measure Unit Cross Reference',
                                component_path: '/dashboard/measureUnitsReference/measureUnitCrossReference',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '8',
                        uniqueKey: 45,
                        title: 'Licensing',
                        component_path: '/dashboard/licensingreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 46,
                                title: 'Data Provider',
                                component_path: '/dashboard/licensingReference/dataProvider',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '2',
                                uniqueKey: 47,
                                title: 'Data Provider Service',
                                component_path: '/dashboard/licensingReference/dataProviderService',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '3',
                                uniqueKey: 48,
                                title: 'Data Service Subscriber',
                                component_path: '/dashboard/licensingReference/dataServiceSubscriber',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '9',
                        uniqueKey: 49,
                        title: 'Data Processing',
                        component_path: '/dashboard/dataprocessingreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '1',
                                uniqueKey: 50,
                                title: 'Dataset',
                                component_path: '/dashboard/dataprocessingreference/dataset',
                            },
                        },
                    ],
                },
            ],
            expanded: false,
        },
    ],
};
export function getGenevaReferenceMenu() {
    return genevaReferenceMenu;
}
