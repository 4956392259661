import { forwardRef, DetailedHTMLProps, HTMLAttributes } from 'react';

import classnames from 'classnames';

import { ARROW_KEYPAD_CLASS } from '../../../utils/constants';
import { ChevronUp, ChevronLeft, ChevronDown, ChevronRight } from '../../Icon/components';
import { Key, Keypad, Keys } from './ArrowKeys.styles';

const ArrowKeys = forwardRef<HTMLDivElement, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <Keypad className={classnames(ARROW_KEYPAD_CLASS, className)} {...props} ref={ref}>
      <Keys>
        <Key>
          <ChevronUp />
        </Key>
      </Keys>
      <Keys>
        <Key>
          <ChevronLeft />
        </Key>
        <Key>
          <ChevronDown />
        </Key>
        <Key>
          <ChevronRight />
        </Key>
      </Keys>
    </Keypad>
  ),
);

export default ArrowKeys;
