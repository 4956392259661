import { ReactElement } from 'react';

import classNames from 'classnames';

import { BUTTON_GROUP_CLASS } from '../../utils/constants';
import * as Styled from './ButtonGroup.styles';
import {
  IButtonGroupProps,
  CheckboxButtonValue,
  RadioButtonValue,
  RadioButtonOnChange,
  CheckboxButtonOnChange,
} from './ButtonGroup.types';
import RadioButton from './components/RadioButton/RadioButton';
import CheckboxButton from './components/CheckboxButton/CheckboxButton';
import { Sizes } from '../../types/types';

const ButtonGroup = ({
  className,
  type = 'radio',
  size = Sizes.Medium,
  name = 'buttonGroup',
  selectedKey,
  iconPosition,
  iconOnly,
  onChange,
  emphasis = true,
  items,
  ...rest
}: IButtonGroupProps): ReactElement => (
  <Styled.ButtonGroup className={classNames(BUTTON_GROUP_CLASS, className)} {...rest}>
    {items?.map(({ value, ...props }) => {
      const commonProps = {
        key: value || props.label,
        value: value || props.label,
        name,
        size,
        iconOnly,
        iconPosition,
        emphasis,
      };

      if (type === 'radio') {
        return (
          <RadioButton
            selectedKey={selectedKey as RadioButtonValue}
            onChange={onChange as RadioButtonOnChange}
            {...commonProps}
            {...props}
          />
        );
      }

      return (
        <CheckboxButton
          selectedKey={selectedKey as CheckboxButtonValue}
          onChange={onChange as CheckboxButtonOnChange}
          {...commonProps}
          {...props}
        />
      );
    })}
  </Styled.ButtonGroup>
);

export default ButtonGroup;
