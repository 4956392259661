import styled, { css } from 'styled-components';

import { getColorBySentiment, getSubtleColorBySentiment, getColorBySentimentOnSubtleBg } from '../../utils/colorUtils';
import Button from '../Button/Button';
import { IAlertProps } from './Alert.types';
import { Sizes, Alignments, RequiredProperties, Transient } from '../../types/types';
import Heading from '../Text/components/Heading/Heading';
import { alertConsts } from './Alert.consts';
import { ALERT_CLASS } from '../../utils/constants';

const Alert = styled.div<
  RequiredProperties<IAlertProps, 'sentiment' | 'size' | 'solidBgColor'> &
    Transient<Pick<IAlertProps, 'elevation'>, 'elevation'>
>`
  ${({ size, sentiment, $elevation, solidBgColor, theme }) => css`
    ${size === Sizes.Large ? theme.label.medium.normal : theme.label.small.normal};

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    min-height: ${alertConsts.minHeight[size]}px;
    padding: ${alertConsts.padding[size]};
    border: 1px solid ${getColorBySentiment(sentiment, theme)};
    border-radius: 2px;
    background-color: ${theme.background.raised};
    color: ${theme.text.onSurface.strong};

    ${$elevation &&
    css`
      box-shadow: ${theme.elevation.level1};
      border: 0;
      border-left: 4px solid ${getColorBySentiment(sentiment, theme)};
    `}

    ${solidBgColor &&
    css`
      background-color: ${getSubtleColorBySentiment(sentiment, theme)};
    `}
  `}
`;

const AlertLabel = styled(Heading)``;

const AlertContentContainer = styled.div<
  RequiredProperties<IAlertProps, 'size' | 'actionsAlignment'> & { $hasLabel?: boolean }
>`
  ${({ size, actionsAlignment, $hasLabel }) => css`
    display: flex;
    flex-grow: 1;
    margin-top: ${alertConsts[$hasLabel ? 'labelMarginTop' : 'childrenMarginTop'][size]}px;

    ${actionsAlignment === Alignments.Bottom &&
    css`
      flex-grow: 1;
      flex-direction: column;
    `}

    ${actionsAlignment === Alignments.Right &&
    css`
      justify-content: space-between;
    `}
  `}
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 20px;
  row-gap: 8px;
`;

const Actions = styled.div<RequiredProperties<IAlertProps, 'actionsAlignment' | 'dismissible'>>`
  ${({ actionsAlignment, dismissible }) => css`
    margin-top: 8px;
    display: flex;
    gap: 12px;
    height: fit-content;
    align-items: center;

    ${actionsAlignment === Alignments.Right &&
    dismissible &&
    css`
      margin-top: unset;
      margin-right: 12px;
    `};
  `};
`;

const IconContainer = styled.div<RequiredProperties<IAlertProps, 'sentiment' | 'solidBgColor'>>`
  ${({ sentiment, solidBgColor, theme }) => css`
    display: flex;
    height: 100%;
    margin-right: 16px;

    .${ALERT_CLASS}-icon {
      fill: ${solidBgColor ? getColorBySentimentOnSubtleBg(sentiment, theme) : getColorBySentiment(sentiment, theme)};
    }
  `};
`;

const DismissContainer = styled.div`
  margin-left: 4px;
  height: 20px;
  width: 20px;
  min-height: 20px;
  position: relative;
`;

const DismissButton = styled(Button)`
  margin: -25%;
`;

export {
  Alert,
  AlertContentContainer,
  AlertContent,
  AlertLabel,
  Actions,
  IconContainer,
  DismissContainer,
  DismissButton,
};
