import { Interpolation } from 'styled-components';

import { Theme } from '../providers/theme/theme';

export enum Sizes {
  Micro = 'micro',
  ExtraSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'xlarge',
}

export enum Sentiments {
  Default = 'default',
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
  Warning = 'warning',
  Information = 'information',
  Secondary = 'secondary',
}

export enum Variants {
  Filled = 'filled',
  Outlined = 'outlined',
  Transparent = 'transparent',
  Subtle = 'subtle',
}

export enum Positions {
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Side = 'side',
}

export enum Alignments {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  LeftAndRight = 'leftAndRight',
  Center = 'center',
  Fill = 'fill',
}

export enum Spacings {
  Tight = 'tight',
  Generous = 'generous',
}

export enum Directions {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum Sorts {
  Asc = 'ascend',
  Desc = 'descend',
}

export enum Prominences {
  Subtle = 'subtle',
  Strong = 'strong',
  Medium = 'medium',
}

export enum BgColors {
  Surface = 'surface',
  Base = 'base',
  Raised = 'raised',
}

export enum Shapes {
  Rounded = 'rounded',
  Square = 'square',
}

export enum TextTypes {
  Paragraph = 'p',
  Span = 'span',
  Label = 'label',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum Levels {
  'One' = 1,
  'Two' = 2,
  'Three' = 3,
  'Four' = 4,
  'Five' = 5,
  'Six' = 6,
}

type CommonActionProps<P> = {
  label: string;
  props?: P;
  showActionButton?: boolean;
};

export type Action<P> = CommonActionProps<P> & {
  action?: () => void;
};

export type ActionLink<P> = CommonActionProps<P> & {
  href?: string;
};

export type WithConditionalCssProp<P> = 'className' extends keyof P
  ? P extends { className?: string }
    ? P & { css?: Interpolation<Theme> }
    : P
  : P;

export type RequiredProperties<Type, Key extends keyof Type> = Omit<Type, Key> & Required<Pick<Type, Key>>;

/**
 * Construct a type with the properties Type where Key is prefixed with a dollar sign
 * making it transient with Styled Components.
 * https://styled-components.com/docs/api#transient-props.
 */
export type Transient<Type, Key extends keyof Type> = Omit<Type, Key> & {
  [K in Key as `$${Extract<K, string>}`]: Type[K];
};

export enum Resolutions {
  'XXL' = 'xxl',
  'XL' = 'xl',
  'L' = 'l',
  'M' = 'm',
  'S' = 's',
  'XS' = 'xs',
  'XXS' = 'xxs',
}

export type ResolutionsType = {
  [key in Resolutions]: number;
};

/**
 *
 *  ------------------- DEPRECATED TYPES -------------------
 *
 */

/**
 * @deprecated MicroSize type has been deprecated, please use Sizes.Micro enum instead
 */
export type MicroSize = Sizes.Micro | 'micro';

/**
 * @deprecated ExtraSmallSize type has been deprecated, please use Sizes.ExtraSmall enum instead
 */
export type ExtraSmallSize = Sizes.ExtraSmall | 'xsmall';

/**
 * @deprecated SmallSize type has been deprecated, please use Sizes.Small enum instead
 */
export type SmallSize = Sizes.Small | 'small';

/**
 * @deprecated MediumSize type has been deprecated, please use Sizes.Medium enum instead
 */
export type MediumSize = Sizes.Medium | 'medium';

/**
 * @deprecated LargeSize type has been deprecated, please use Sizes.Large enum instead
 */
export type LargeSize = Sizes.Large | 'large';

/**
 * @deprecated ExtraLargeSize type has been deprecated, please use Sizes.ExtraLarge enum instead
 */
export type ExtraLargeSize = Sizes.ExtraLarge | 'xlarge';

/**
 * @deprecated DefaultSentiment type has been deprecated, please use Sentiments.Default enum instead
 */
export type DefaultSentiment = Sentiments.Default | 'default';

/**
 * @deprecated PositiveSentiment type has been deprecated, please use Sentiments.Positive enum instead
 */
export type PositiveSentiment = Sentiments.Positive | 'positive';

/**
 * @deprecated NegativeSentiment type has been deprecated, please use Sentiments.Negative enum instead
 */
export type NegativeSentiment = Sentiments.Negative | 'negative';

/**
 * @deprecated NeutralSentiment type has been deprecated, please use Sentiments.Neutral enum instead
 */
export type NeutralSentiment = Sentiments.Neutral | 'neutral';

/**
 * @deprecated WarningSentiment type has been deprecated, please use Sentiments.Warning enum instead
 */
export type WarningSentiment = Sentiments.Warning | 'warning';

/**
 * @deprecated InformationSentiment type has been deprecated, please use Sentiments.Information enum instead
 */
export type InformationSentiment = Sentiments.Information | 'information';

/**
 * @deprecated DeprecatedStandardSentiments type has been deprecated, please use StandardSentiments enum instead
 */
export type DeprecatedStandardSentiments =
  | NeutralSentiment
  | WarningSentiment
  | InformationSentiment
  | PositiveSentiment
  | NegativeSentiment;

/**
 * @deprecated DeprecatedSentiments type has been deprecated, please use Sentiments enum instead
 */
export type DeprecatedSentiments = DeprecatedStandardSentiments | DefaultSentiment;

/**
 * @deprecated PositiveState prop has been deprecated, please use PositiveSentiment prop instead
 */
export type PositiveState = PositiveSentiment;

/**
 * @deprecated NegativeState prop has been deprecated, please use NegativeSentiment prop instead
 */
export type NegativeState = NegativeSentiment;

/**
 * @deprecated States prop has been deprecated, please use Sentiments prop instead
 */
export type States = DeprecatedStandardSentiments;

/**
 * @deprecated FilledVariant prop has been deprecated, please use Variants.Filled enum instead
 */
export type FilledVariant = Variants.Filled | 'filled';

/**
 * @deprecated OutlinedVariant prop has been deprecated, please use Variants.Outlined enum instead
 */
export type OutlinedVariant = Variants.Outlined | 'outlined';

/**
 * @deprecated TransparentVariant prop has been deprecated, please use Variants.Transparent enum instead
 */
export type TransparentVariant = Variants.Transparent | 'transparent';

/**
 * @deprecated SubtleVariant prop has been deprecated, please use Variants.Subtle enum instead
 */
export type SubtleVariant = Variants.Subtle | 'subtle';

/**
 * @deprecated HorizontalPositions prop has been deprecated, please use Position enum instead
 */
export type HorizontalPositions = 'right' | 'left';

/**
 * @deprecated DeprecatedHorizontalPositions prop has been deprecated, please use Position enum instead
 */
export type DeprecatedHorizontalPositions = HorizontalPositions;

/**
 * @deprecated VerticalPositions prop has been deprecated, please use Position enum instead
 */
export type VerticalPositions = 'top' | 'bottom';

/**
 * @deprecated DeprecatedVerticalPositions prop has been deprecated, please use Position enum instead
 */
export type DeprecatedVerticalPositions = VerticalPositions;

/**
 * @deprecated DeprecatedHorizontalCenteredPositions prop has been deprecated, please use Position enum instead
 */
export type DeprecatedHorizontalCenteredPositions = DeprecatedHorizontalPositions | 'center';

/**
 * @deprecated DeprecatedVerticalCenteredPositions prop has been deprecated, please use Position enum instead
 */
export type DeprecatedVerticalCenteredPositions = DeprecatedVerticalPositions | 'center';

/**
 * @deprecated Paddings prop has been deprecated, please use Paddings enum instead
 */
export type Paddings = 'tight' | 'generous';

/**
 * @deprecated Modes prop has been deprecated, please use Directions prop instead
 */
export type Modes = 'horizontal' | 'vertical';

/**
 * @deprecated DeprecatedBackgroundColors prop has been deprecated, please use BackgroundColors enum instead
 */
export type DeprecatedBgColors = 'base' | 'surface' | 'raised';

/**
 * @deprecated DeprecatedType type has been deprecated, please use TextTypes enum instead
 */
export type DeprecatedTypes = 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * @deprecated Spacing type has been deprecated, please use Spacings enum instead
 */
export type Spacing = Spacings;
