import { forwardRef, ReactElement, useMemo } from 'react';

import RCMenu, { MenuRef } from 'rc-menu';

import { MENU_CLASS } from '../../utils/constants';
import { Ellipsis } from '../Icon/components';
import { menuConsts } from './Menu.consts';
import * as Styled from './Menu.styles';
import { IMenuContext, IMenuProps } from './Menu.types';
import MenuContext from './MenuContext';
import { getDefaultMotions } from './utils/motionUtils';
import { getItemsByPath, useItems } from './utils/nodeUtils';
import { Sizes, Positions } from '../../types/types';

const RCMenuRef = forwardRef<MenuRef, IMenuProps>(
  ({ className, ...rest }, ref): ReactElement => <RCMenu ref={ref} rootClassName={className} {...rest} />,
);

const Menu = ({
  className,
  mode = 'inline',
  size = Sizes.Medium,
  inlineCollapsed = false,
  emphasis = true,
  indicatorPosition = Positions.Right,
  items,
  selectedPath,
  selectedKeys,
  itemRender,
  children,
  ...rest
}: IMenuProps): ReactElement => {
  const mergedItems = useItems(items) || children;

  const contextValue = useMemo<IMenuContext>(
    () => ({
      size,
      mode,
      inlineCollapsed,
      itemRender,
    }),
    [size, mode, inlineCollapsed, itemRender],
  );

  const selectedItem = selectedPath ? getItemsByPath(selectedPath || '', items) : selectedKeys;

  return (
    <MenuContext.Provider value={contextValue}>
      <Styled.Menu
        as={RCMenuRef}
        prefixCls={MENU_CLASS}
        id={MENU_CLASS}
        className={className}
        selectedKeys={selectedItem}
        size={size}
        mode={mode}
        defaultMotions={getDefaultMotions}
        inlineIndent={menuConsts[size].indent}
        inlineCollapsed={inlineCollapsed}
        overflowedIndicator={
          <div className={`${MENU_CLASS}-submenu-title-content`}>
            <Ellipsis className={`${MENU_CLASS}-submenu-rest-icon`} />
          </div>
        }
        $indicatorPosition={indicatorPosition}
        $emphasis={emphasis}
        {...rest}
      >
        {mergedItems}
      </Styled.Menu>
    </MenuContext.Provider>
  );
};

export default Menu;
