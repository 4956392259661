import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flexbox, FormField, Heading, Icons } from '@sede-x/shell-ds-react-framework';
import { FormFieldInfoLink } from './FormFieldInfo';
import { StyledTextInput } from './OwnershipDetailsSection';
import { Select } from 'components/select';
import { useGetACMCountryHierarchyQuery, useGetCitiesQuery, useGetGeopoliticalEntitiesQuery, useGetHierarchyCountriesQuery, useGetHierarchyElementByNameQuery, } from 'common/ducks/filtersApis';
import { newMapSelectData } from 'common/utils/newScreenUtils';
import { resolveOnSelectChange } from 'common/utils/url/addEditEvent.utils';
var TED_URL = process.env.REACT_APP_TED_URL;
var Search = Icons.Search;
export var AssetLocationSection = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var form = _a.form, open = _a.open;
    var watch = form.watch, register = form.register, errors = form.formState.errors;
    var _j = useGetHierarchyElementByNameQuery('RTL', {
        skip: !open,
    }), _k = _j.data, rtlRegions = _k === void 0 ? [] : _k, isFetchingRTLRegions = _j.isFetching;
    var rtlRegionsOptions = rtlRegions === null || rtlRegions === void 0 ? void 0 : rtlRegions.map(newMapSelectData('hierarchyElementNm', 'id'));
    var _l = useGetHierarchyElementByNameQuery('RTT Refinery', {
        skip: !open,
    }), _m = _l.data, rttRegions = _m === void 0 ? [] : _m, isFetchingRTTRegions = _l.isFetching;
    var rttRegionsOptions = rttRegions.map(newMapSelectData('hierarchyElementNm', 'id'));
    var _o = useGetHierarchyCountriesQuery(undefined, {
        skip: !open,
    }), _p = _o.data, countries = _p === void 0 ? [] : _p, isFetchingCountries = _o.isFetching;
    var countriesOptions = countries.map(newMapSelectData('geopoliticalEntityNm', 'id'));
    var _q = useGetACMCountryHierarchyQuery(undefined, {
        skip: !open,
    }), _r = _q.data, acmCountries = _r === void 0 ? [] : _r, isFetchingACMCountries = _q.isFetching;
    var acmCountriesOptions = acmCountries.map(newMapSelectData('HierarchyElementNm', 'HierarchyElementKey'));
    var _s = useGetCitiesQuery({ countryIds: watch('country') ? [watch('country')] : undefined }, { skip: !open }), _t = _s.data, cities = _t === void 0 ? [] : _t, isFetchingCities = _s.isFetching;
    var citiesOptions = cities.map(newMapSelectData('geopoliticalEntityNm', 'id'));
    var _u = useGetGeopoliticalEntitiesQuery(undefined, {
        skip: !open,
    }), _v = _u.data, subdivision = _v === void 0 ? [] : _v, isFetchingSubdivision = _u.isFetching;
    var subdivisionOptions = subdivision.map(newMapSelectData('geopoliticalEntityNm', 'id'));
    var setHierarchyValuesFromId = function (id, hierarchyName) {
        var _a, _b, _c;
        if (!id) {
            form.setValue(hierarchyName, undefined);
            return;
        }
        var hierarchy;
        if (hierarchyName === 'rtlRegion') {
            hierarchy = rtlRegionsOptions.find(function (hierarchy) { return hierarchy.key === id; });
        }
        else if (hierarchyName === 'rttRegion') {
            hierarchy = rttRegionsOptions.find(function (hierarchy) { return hierarchy.key === id; });
        }
        else {
            hierarchy = acmCountriesOptions.find(function (hierarchy) { return hierarchy.key === id; });
        }
        form.setValue(hierarchyName, {
            AssetKey: 0,
            HierarchyElementNm: (_a = hierarchy.hierarchyElementNm) !== null && _a !== void 0 ? _a : hierarchy.HierarchyElementNm,
            HierarchyElementKey: hierarchy.value,
            HierarchyElementLevelNum: (_b = hierarchy.hierarchyElementLevelNum) !== null && _b !== void 0 ? _b : hierarchy.HierarchyElementLevelNum,
            HierarchyKey: (_c = hierarchy.hierarchyKey) !== null && _c !== void 0 ? _c : hierarchy.HierarchyKey,
            ParentElementKey: hierarchy.key,
        });
    };
    return (_jsxs("section", { children: [_jsx(Heading, __assign({ bold: true, color: "light", level: 5 }, { children: "ASSET LOCATION" })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "RTL region", size: "small", mandatory: true, bottomLeftHelper: {
                            content: (_b = errors.rtlRegion) === null || _b === void 0 ? void 0 : _b.message,
                            sentiment: 'negative',
                        } }, { children: _jsx(Select, __assign({}, register('rtlRegion', {
                            validate: function (value) { return !!(value === null || value === void 0 ? void 0 : value.HierarchyElementKey) || 'Select an RTL region'; },
                        }), { value: (_c = watch('rtlRegion')) === null || _c === void 0 ? void 0 : _c.HierarchyElementKey, onChange: function (e) {
                                setHierarchyValuesFromId(e, 'rtlRegion');
                            }, placeholder: "Search", suffixIcon: !isFetchingRTLRegions && _jsx(Search, {}), data: rtlRegionsOptions, loading: isFetchingRTLRegions, size: "small" })) })), _jsx(FormField, __assign({ label: "RTT region", size: "small", mandatory: true, bottomLeftHelper: {
                            content: (_d = errors.rttRegion) === null || _d === void 0 ? void 0 : _d.message,
                            sentiment: 'negative',
                        } }, { children: _jsx(Select, __assign({}, register('rttRegion', {
                            validate: function (value) { return !!(value === null || value === void 0 ? void 0 : value.HierarchyElementKey) || 'Select an RTT region'; },
                        }), { value: (_e = watch('rttRegion')) === null || _e === void 0 ? void 0 : _e.HierarchyElementKey, onChange: function (e) {
                                setHierarchyValuesFromId(e, 'rttRegion');
                            }, placeholder: "Search", suffixIcon: !isFetchingRTTRegions && _jsx(Search, {}), data: rttRegionsOptions, loading: isFetchingRTTRegions, size: "small" })) }))] })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "ACM country", size: "small", mandatory: true, bottomLeftHelper: {
                            content: (_f = errors.acmCountry) === null || _f === void 0 ? void 0 : _f.message,
                            sentiment: 'negative',
                        } }, { children: _jsx(Select, __assign({}, register('acmCountry', {
                            validate: function (value) { return !!(value === null || value === void 0 ? void 0 : value.HierarchyElementKey) || 'Select an ACM country'; },
                        }), { placeholder: "Search", suffixIcon: !isFetchingACMCountries && _jsx(Search, {}), value: (_g = watch('acmCountry')) === null || _g === void 0 ? void 0 : _g.HierarchyElementKey, onChange: function (e) {
                                setHierarchyValuesFromId(e, 'acmCountry');
                            }, data: acmCountriesOptions, size: "small", loading: isFetchingACMCountries })) })), _jsx(FormField, __assign({ label: "Country", size: "small", mandatory: true, bottomLeftHelper: {
                            content: (_h = errors.country) === null || _h === void 0 ? void 0 : _h.message,
                            sentiment: 'negative',
                        }, topHelper: _jsx(FormFieldInfoLink, { href: TED_URL, text: "+ Add a country to Ted" }) }, { children: _jsx(Select, __assign({}, register('country', {
                            required: 'Select a country',
                        }), { placeholder: "Search", suffixIcon: !isFetchingCountries && _jsx(Search, {}), data: countriesOptions, loading: isFetchingCountries, size: "small", value: watch('country'), onChange: resolveOnSelectChange('country', form, []) })) }))] })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "Subdivision", size: "small", topHelper: _jsx(FormFieldInfoLink, { href: TED_URL, text: "+ Add a subdivision to Ted" }) }, { children: _jsx(Select, __assign({}, register('subdivision'), { value: watch('subdivision'), onChange: resolveOnSelectChange('subdivision', form, []), placeholder: "Search", suffixIcon: !isFetchingSubdivision && _jsx(Search, {}), data: subdivisionOptions, size: "small", loading: isFetchingSubdivision })) })), _jsx(FormField, __assign({ label: "City", size: "small", topHelper: _jsx(FormFieldInfoLink, { href: TED_URL, text: "+ Add a city to Ted" }) }, { children: _jsx(Select, __assign({}, register('city'), { value: watch('city'), onChange: resolveOnSelectChange('city', form, []), placeholder: "Search", suffixIcon: !isFetchingCities && _jsx(Search, {}), data: citiesOptions, loading: isFetchingCities, size: "small" })) }))] })), _jsxs(Flexbox, __assign({ gap: "3%" }, { children: [_jsx(FormField, __assign({ label: "Latitude", size: "small" }, { children: _jsx(StyledTextInput, __assign({}, register('latitude'), { size: "small", type: "number", step: "any" })) })), _jsx(FormField, __assign({ label: "Longitude", size: "small" }, { children: _jsx(StyledTextInput, __assign({}, register('longitude'), { size: "small", type: "number", step: "any" })) }))] }), open.toString())] }));
};
