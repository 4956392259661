import styled, { css } from 'styled-components';

type InputWrapperProps = { disabled: boolean; value?: any; invalid: boolean };

const InputWrapper = styled.div<InputWrapperProps>`
  ${({ disabled, value, invalid, theme }) => css`
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid ${invalid ? theme.system.strong.negative : theme.border.medium};
    border-radius: 2px;
    background-color: ${theme.background.surface};

    &:hover {
      ${!invalid &&
      !disabled &&
      css`
        border-color: ${theme.focus.strong};
      `}
    }

    &:focus-within {
      ${!invalid &&
      css`
        border: 1px solid ${theme.focus.strong};
      `}
      outline: 0;
      box-shadow: 0 0 0 3px ${theme.focus.medium};
    }

    ${disabled &&
    css`
      background-color: ${theme.background.inactive};
    `}

    svg {
      path {
        ${disabled &&
        `
          fill: ${theme.text.onSurface.inactive}
        `}

        ${!disabled &&
        value &&
        `
          fill: ${theme.text.onSurface.strong}
        `}
      
        ${!disabled &&
        !value &&
        `
          fill: ${theme.text.onSurface.subtle}
        `}
      }
    }
  `}
`;

export { InputWrapper };
