import { TableSize } from './Table.types';
import { Sizes } from '../../types/types';

type TableConstsProps = {
  [size in TableSize]: {
    height: string;
  };
};

export const tableConsts: TableConstsProps = {
  [Sizes.ExtraSmall]: {
    height: '32px',
  },
  [Sizes.Small]: {
    height: '40px',
  },
  [Sizes.Medium]: {
    height: '48px',
  },
  [Sizes.Large]: {
    height: '60px',
  },
};
