import { keyframes } from 'styled-components';

export const zoomIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

export const zoomOut = keyframes`
  0% {
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const commonAnimation = {
  animationDuration: '0.3s',
  animationFillMode: 'both',
  animationPlayState: 'paused',
};
