import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component, useState, useEffect } from 'react';
import Dropdown from '../../../../../commons/tools/dropDown/dropdown';
import Spinner from './../../../../../../src/commons/spinner';
import { getSourceFilter, fileUpload } from '../../../../../services/urlService';
var DataUpload = function () {
    var _a = useState(null), providerName = _a[0], setProviderName = _a[1];
    var _b = useState(null), dataSetName = _b[0], setDataSetName = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useState([]), dataSetOption = _d[0], setDataSetOption = _d[1];
    var _e = useState(null), selectedFile = _e[0], setSelectedFile = _e[1];
    var _f = useState(false), loading = _f[0], setIsLoading = _f[1];
    var uploadUrl = 'DataUploads';
    useEffect(function () {
        setIsLoading(true);
        getSourceFilter('DataProviders')
            .then(function (response) {
            if (response && response.data) {
                setOptions(response.data);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        })
            .catch(function (err) {
            setIsLoading(false);
        });
    }, []);
    var setProviderHandler = function (value) {
        if (value) {
            setProviderName(value);
            setIsLoading(true);
            var path = 'DataProviders/' + value + '/DataSets';
            getSourceFilter(path)
                .then(function (res) {
                if (res && res.data) {
                    setDataSetOption(res.data);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            })
                .catch(function (err) {
                setIsLoading(false);
            });
        }
    };
    var dataSetHandler = function (dataSetVal) {
        if (dataSetVal) {
            setDataSetName(dataSetVal);
        }
    };
    var onFileChange = function (event) {
        setSelectedFile(event.target.files[0]);
    };
    var onFileUpload = function () {
        if (providerName && dataSetName && selectedFile) {
            var postUrl = uploadUrl + '?DataSetProviderId=' + providerName + '&DataSetId=' + dataSetName;
            // Create an object of formData
            var formData = new FormData();
            // Update the formData object
            formData.append('files', selectedFile, selectedFile.name);
            fileUpload(postUrl, formData);
        }
    };
    var fileData = function () {
        if (selectedFile) {
            return (_jsxs("div", __assign({ className: "container" }, { children: [_jsx("h2", { children: "File Details:" }), _jsx("table", __assign({ className: "table" }, { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsxs("td", { children: ["File Name: ", selectedFile.name] }) }), _jsx("tr", { children: _jsxs("td", { children: ["File Type: ", selectedFile.type] }) }), _jsx("tr", { children: _jsxs("td", { children: ["Last Modified: ", selectedFile.lastModifiedDate.toDateString()] }) })] }) }))] })));
        }
        else {
            return (_jsx("div", { children: _jsx("p", { children: "Choose before Pressing the Upload button" }) }));
        }
    };
    return (_jsxs("div", { children: [loading && _jsx(Spinner, {}), _jsx("div", __assign({ className: "page-title m-3" }, { children: _jsx("div", __assign({ className: "row justify-content-between" }, { children: _jsx("div", __assign({ className: "col-2 mt-2" }, { children: _jsx("h3", __assign({ className: "px-2" }, { children: "Data Upload" })) })) })) })), _jsx("div", __assign({ style: { height: '100vh' } }, { children: _jsx("div", __assign({ className: "bg-secondary m-4 h-100" }, { children: _jsx("div", __assign({ className: "row mt-3 ml-1 mb-0" }, { children: _jsx("div", __assign({ className: "col-xs-12 col-sm-5 offset-sm-3 mt-4", style: { margin: 'auto' } }, { children: _jsx("div", __assign({ className: "card mt-4 p-4", style: { height: '100%' } }, { children: _jsxs("div", __assign({ className: "card-block p-4" }, { children: [_jsx("div", __assign({ className: "my-4" }, { children: _jsx(Dropdown, { placeholder: "Select", textName: 'dataProviderAbbr', returnValue: 'id', label: 'Data Provider', onChange: function (value) { return setProviderHandler(value); }, options: options }) })), _jsx("div", __assign({ className: "my-4" }, { children: _jsx(Dropdown, { placeholder: "Select", textName: 'datasetAbbr', returnValue: 'id', label: 'Data Set', onChange: function (value) { return dataSetHandler(value); }, options: dataSetOption }) })), _jsxs("div", __assign({ className: "row m-0" }, { children: [_jsx("input", { className: "col-10 btn my-4", type: "file", onChange: function (event) { return onFileChange(event); } }), _jsx("button", __assign({ className: "col-2 px-2 btn btn-primary my-4", onClick: onFileUpload }, { children: "Upload" }))] })), fileData()] })) })) })) })) })) }))] }));
};
export default DataUpload;
