import { forwardRef, ForwardedRef } from 'react';

import { ITableSorterProps } from '../../Table.types';
import { Sizes, Variants } from '../../../../types/types';
import { useTable } from '../../hooks/useTable';
import { ArrowUp, ArrowDown, ChevronUpDown } from '../../../Icon/components';
import { getSize } from '../../utils/tableUtils';
import Button from '../../../Button/Button';
import {
  TABLE_SORTER_BUTTON_CLASS,
  TABLE_ASC_ICON_CLASS,
  TABLE_DESC_ICON_CLASS,
  TABLE_NO_DIR_ICON_CLASS,
} from '../../../../utils/constants';

export const SorterButton = forwardRef(
  ({ sortDirection, ...rest }: ITableSorterProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size = Sizes.Medium } = useTable();

    return (
      <Button
        className={TABLE_SORTER_BUTTON_CLASS}
        aria-label="Sorter button"
        size={getSize(size)}
        variant={Variants.Transparent}
        iconOnly
        icon={
          <>
            {sortDirection === 'asc' && <ArrowUp className={TABLE_ASC_ICON_CLASS} width={16} height={16} />}
            {sortDirection === 'desc' && <ArrowDown className={TABLE_DESC_ICON_CLASS} width={16} height={16} />}
            {!sortDirection && <ChevronUpDown className={TABLE_NO_DIR_ICON_CLASS} width={16} height={16} />}
          </>
        }
        ref={ref}
        {...rest}
      />
    );
  },
);
