import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import { rotate } from '../../utils/styleUtils';
import { selectConsts } from './Select.consts';
import { SelectSize } from './Select.types';
import { rcSelectDropdownSlideUpIn, rcSelectDropdownSlideUpOut } from './utils/styleUtils';

type SelectProps = {
  size: SelectSize;
  prefixCls: string;
  disabled: boolean;
};

const singleSelectPadding = css<{ size: SelectSize }>`
  padding: ${({ size }) => `${selectConsts[size].topBottomOffset}px 38px 
  ${selectConsts[size].topBottomOffset}px ${selectConsts[size].leftOffset}px`};
`;

const baseSearchInput = css<SelectProps>`
  ${({ theme }) => css`
    ${theme.paragraph.small.normal}
    color: ${theme.text.onSurface.strong};
  `}

  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  margin: 0;
`;

const searchInputWithPadding = css<SelectProps>`
  .${({ prefixCls }) => prefixCls}-selection-search-input {
    ${baseSearchInput};
    cursor: pointer;

    ${({ size }) => css`
      padding: ${selectConsts[size].topBottomOffset}px 30px ${selectConsts[size].topBottomOffset}px
        ${selectConsts[size].leftOffset}px;
    `}

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const searchInputWithoutPadding = css<SelectProps>`
  .${({ prefixCls }) => prefixCls}-selection-search-input {
    ${baseSearchInput};
    cursor: pointer;
  }
`;

const Multiple = css<SelectProps>`
  ${({ prefixCls, theme, size, disabled }) => css`
    &-multiple .${prefixCls}-selector {
      display: flex;
      flex-wrap: wrap;
      padding: ${selectConsts[size].multiplePadding};

      &:hover {
        border-color: ${theme.focus.medium} !important;
      }

      .${prefixCls}-selection-item {
        ${theme.paragraph.small.normal};
        color: ${theme.text.onSurface.strong};
        background: ${theme.background.section};
        border-radius: 2px;
        margin: 2px 6px 2px;
        padding: ${selectConsts[size].tagTopBottomPadding}px ${selectConsts[size].tagRightPadding}px
          ${selectConsts[size].tagTopBottomPadding}px ${selectConsts[size].tagLeftPadding}px;
        display: flex;

        &:hover {
          background: ${!disabled && theme.focus.subtle};
        }

        &:first-child {
          margin-left: 0;
        }

        .${prefixCls}-selection-item-content {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &-remove {
          display: inline-block;
          height: 18px;
          padding-top: 2px;
          margin-left: 6px;
          cursor: pointer;

          svg {
            fill: ${theme.text.onSurface.subtle};
          }
        }

        &-disabled {
          cursor: not-allowed;
        }
      }

      .${prefixCls}-selection-placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: ${selectConsts[size].tagLeftPadding}px;
      }

      .${prefixCls}-selection-overflow {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &-item {
          flex: none;
          max-width: 100%;
          align-self: center;
        }
      }

      .${prefixCls}-selection-search {
        position: relative;
        max-width: 100%;

        &-input,
        &-mirror {
          padding: 0;
          font-family: system-ui;
        }

        &-mirror {
          z-index: ${Z_INDEX.SELECT};
          white-space: nowrap;
          position: absolute;
          left: 10px;
          top: 0;
          visibility: hidden;
        }
      }

      ${searchInputWithoutPadding}
    }
  `}
`;

const Single = css<SelectProps>`
  ${({ prefixCls, theme }) => css`
    &-single {
      .${prefixCls}-selector {
        display: flex;
        position: relative;

        ${searchInputWithPadding}

        &:hover {
          border-color: ${theme.focus.strong};
        }

        .${prefixCls}-selection-search {
          width: 100%;

          &-input {
            width: 100%;
          }
        }

        .${prefixCls}-selection-item {
          ${theme.paragraph.small.normal};
          display: inline-block;
          color: ${theme.text.onSurface.strong};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .${prefixCls}-selection-item, .${prefixCls}-selection-placeholder {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);

          ${singleSelectPadding};
        }
      }
    }
  `}
`;

const Option = css<SelectProps>`
  ${({ size, prefixCls, theme }) => css`
    &-item {
      ${theme.paragraph.small.normal};

      &-group {
        color: ${theme.text.onSurface.subtle};
      }

      &-option-content {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &-option {
        ${theme.paragraph.small.normal};
        cursor: pointer;
        position: relative;
        border-radius: 2px;
        color: ${theme.text.onSurface.strong};

        ${singleSelectPadding};

        &:hover:not(.${prefixCls}-item-option-selected):not(.${prefixCls}-item-option-disabled) {
          background: ${theme.background.section};
        }

        &-grouped {
          padding-left: 24px;
        }

        .${prefixCls}-item-option-state {
          display: none;
          position: absolute;
          right: 9px;
          top: ${selectConsts[size].iconTopOffset}px;
          pointer-events: none;
        }

        &-selected {
          background: ${theme.focus.subtle};

          .${prefixCls}-item-option-state {
            display: inline;
          }
        }

        &-disabled {
          color: ${theme.text.onSurface.inactive};
          cursor: not-allowed;
        }
      }

      &-option-active {
        background: ${theme.background.section};
      }

      &-empty {
        text-align: center;
        color: #999;
      }
    }
  `}
`;

const Select = styled.div<SelectProps>`
  ${({ prefixCls, size, theme }) => css`
    .${prefixCls} {
      ${Option}
    }

    &.${prefixCls} {
      display: inline-block;
      width: 100%;
      position: relative;
      cursor: pointer;

      .${prefixCls}-selector {
        min-height: ${selectConsts[size].height}px;
        border: 1px solid ${theme.border.medium};
        border-radius: 2px;
        background: ${theme.background.surface};
      }

      /* ============== Selector placeholder =============== */
      .${prefixCls}-selection-placeholder {
        ${theme.paragraph.small.italic};
        color: ${theme.text.onSurface.subtle};
        pointer-events: none;
      }

      /* ============== Search =============== */
      .${prefixCls}-selection-search-input {
        appearance: none;

        &::-webkit-search-cancel-button {
          display: none;
          appearance: none;
        }
      }

      /* =============== Focused =============== */
      &-focused {
        .${prefixCls}-selector {
          border-color: ${theme.focus.strong} !important;
          box-shadow: 0 0 0 3px ${theme.focus.medium};
        }
      }

      &-disabled {
        background-color: ${theme.background.inactive};
        cursor: not-allowed;

        .${prefixCls}-arrow svg {
          fill: ${theme.text.onSurface.inactive} !important;
        }
      }

      ${Multiple}
      ${Single}
      
      &-disabled .${prefixCls}-selector {
        border-color: ${theme.border.subtle};
        background-color: ${theme.background.inactive};

        &:hover {
          border-color: ${theme.border.subtle} !important;
        }

        .${prefixCls}-selection-search-input, .${prefixCls}-selection-item, .${prefixCls}-selection-placeholder {
          color: ${theme.text.onSurface.inactive};
          cursor: not-allowed;
        }

        .${prefixCls}-selection-item {
          background: transparent;
        }
      }

      /* ================ Icons ================ */
      &-allow-clear {
        &.${prefixCls}-multiple .${prefixCls}-selector {
          padding-right: 20px;
        }

        .${prefixCls}-clear {
          cursor: pointer;
          position: absolute;
          right: ${selectConsts[size].iconRightOffset}px;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${theme.background.surface};

          svg {
            display: block;
            fill: ${theme.text.onSurface.subtle};
          }
        }
      }

      &-show-arrow {
        &.${prefixCls}-multiple .${prefixCls}-selector {
          padding-right: 28px;
        }

        .${prefixCls}-arrow {
          pointer-events: none;
          position: absolute;
          right: ${selectConsts[size].iconRightOffset}px;
          top: 50%;
          transform: translateY(-50%);
          height: 24px;
          cursor: pointer;

          svg {
            fill: ${theme.text.onSurface.subtle};
          }

          &-loading {
            &:before {
              display: inline-block;
              box-sizing: border-box;
              width: 24px;
              height: 24px;
              border-radius: 100%;
              border: 2px solid ${theme.border.subtle};
              border-top-color: ${theme.accent.primary.strong};
              content: '';
              animation: ${rotate} 0.8s linear infinite;
            }
          }
        }
      }

      /* ============== Dropdown =============== */
      &-dropdown {
        box-sizing: border-box;
        border-radius: 2px;
        background-color: ${theme.background.surface};
        padding: 4px;
        box-shadow: ${theme.elevation.level2};
        position: absolute;
        z-index: ${Z_INDEX.POPOVER};

        &-hidden {
          display: none;
        }
      }

      &-error {
        .${prefixCls}-selector {
          border-color: ${theme.system.strong.negative};
        }
      }
    }

    .${prefixCls}-selection__choice-zoom {
      transition: all 0.3s;
    }

    .${prefixCls}-selection__choice-zoom-appear {
      opacity: 0;
      transform: scale(0.5);

      &&-active {
        opacity: 1;
        transform: scale(1);
      }
    }
    .${prefixCls}-selection__choice-zoom-leave {
      opacity: 1;
      transform: scale(1);

      &&-active {
        opacity: 0;
        transform: scale(0.5);
      }
    }
    .${prefixCls}-dropdown {
      &-slide-up-enter,
      &-slide-up-appear {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-play-state: paused;
      }

      &-slide-up-leave {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-play-state: paused;
      }

      &-slide-up-enter.${prefixCls}-slide-up-enter-active, &-slide-up-appear.${prefixCls}-slide-up-appear-active {
        &.${prefixCls}-placement-bottomLeft {
          animation-name: ${rcSelectDropdownSlideUpIn};
          animation-play-state: running;
        }
      }

      &-slide-up-leave.${prefixCls}-slide-up-leave-active {
        &.${prefixCls}-placement-bottomLeft {
          animation-name: ${rcSelectDropdownSlideUpOut};
          animation-play-state: running;
        }
      }

      &-slide-up-enter.${prefixCls}-slide-up-enter-active, &-slide-up-appear.${prefixCls}-slide-up-appear-active {
        &.${prefixCls}-placement-topLeft {
          animation-name: rcSelectDropdownSlideDownIn;
          animation-play-state: running;
        }
      }

      &-slide-up-leave.${prefixCls}-slide-up-leave-active {
        &.${prefixCls}-placement-topLeft {
          animation-name: rcSelectDropdownSlideDownOut;
          animation-play-state: running;
        }
      }
    }
  `}
`;

export { Select };
