import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CacheBuster from './CacheBuster';
import { ToastContainer } from 'react-toastify';
import { BaseContainer } from './baseContainer/baseContainer';
import { App as NewApp } from './app/App.tsx';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import 'font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.less';
var redirect = function (redirectUrl) {
    setTimeout(function () {
        window.location.replace(redirectUrl);
    }, 5000);
};
var App = function () {
    return (_jsx(CacheBuster, { children: function (_a) {
            var loading = _a.loading, isLatestVersion = _a.isLatestVersion, refreshCacheAndReload = _a.refreshCacheAndReload;
            if (loading)
                return null;
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            var location = window.location.href;
            //// redirect from old address to new one //////////////////////
            if (location.includes('sww-geneva')) {
                var routeArray = location.split('.com/');
                var routePath = routeArray.length > 1 ? routeArray[1] : '';
                var redirectUrl = void 0;
                if (location.includes('genevaacmdev')) {
                    redirectUrl = "https://acm-dev.shell.com/".concat(routePath);
                }
                else if (location.includes('genevaacmtst')) {
                    redirectUrl = "https://acm-test.shell.com/".concat(routePath);
                }
                else {
                    redirectUrl = "http://acm.shell.com/".concat(routePath);
                }
                redirect(redirectUrl);
                var seconds_1 = 1;
                setInterval(function () {
                    if (seconds_1 <= 0) {
                        clearInterval(downloadTimer);
                    }
                    document.getElementById('progressBar').value = seconds_1;
                    seconds_1 += 1;
                }, 1000);
                return (_jsxs("div", __assign({ style: {
                        textAlign: 'center',
                        width: '100vw',
                    } }, { children: [_jsx("h2", { children: "ACM has changed its address" }), _jsx("br", {}), "If you are not redirected automatically, follow this", ' ', _jsx("a", __assign({ style: { color: 'yellow', textDecoration: 'underline' }, href: redirectUrl }, { children: "LINK" })), ".", _jsx("br", {}), _jsx("br", {}), _jsx("progress", { value: "0", max: "5", id: "progressBar" })] })));
            }
            return (_jsxs(React.Fragment, { children: [_jsx(ToastContainer, {}), _jsxs(Switch, { children: [_jsx(Route, { path: "/dashboard", component: BaseContainer }), _jsx(Route, { path: "/", component: NewApp })] })] }));
        } }));
};
export default withAITracking(reactPlugin, App);
