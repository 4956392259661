import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { EmbeddedReport } from 'components/embedded-report';
import { SidebarTemplate } from 'components/sidebar-template';
export var AllReports = function () {
    document.title = 'All reports • Asset Capacity & Maintenance';
    //For full list of Geneva report ids go to this link:
    //https://learn.microsoft.com/en-us/rest/api/power-bi/reports/get-reports#code-try-0
    return (_jsx(SidebarTemplate, __assign({ sidebarOpen: false, sidebar: _jsx("div", {}) }, { children: _jsx(EmbeddedReport, { reportId: "6814b867-4d85-4186-a3b1-b3f4dd2fb33f", format: 'Full' }) })));
};
