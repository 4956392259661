import styled, { css } from 'styled-components';

import { Sentiments, Sizes } from '../../../../types/types';
import { rgba2rgb } from '../../../../utils/colorUtils';
import { ITableRowProps, ITableCellProps, StickyTypeCell } from './TableElements.types';
import { tableConsts } from '../../Table.consts';

const sharedCellStyles = css`
  color: ${({ theme }) => theme.text.onSurface.strong};
  box-sizing: border-box;
  overflow-wrap: break-word;
`;

const sharedStickyCellStyles = css<ITableCellProps>`
  ${({ sticky, stickyType, startingPoint, theme }) => css`
    ${sticky &&
    stickyType === StickyTypeCell.Column &&
    css`
      position: sticky;
      z-index: 1;
      left: ${startingPoint}px;
      background: ${theme.background.inactive};
    `};
  `}
`;

/** Table elements */

export const THead = styled.thead(
  ({ theme }) => css`
    tr {
      border-bottom: 1px solid ${theme.border.medium};
    }
  `,
);

export const TFoot = styled.tfoot(
  ({ theme }) => css`
    tr {
      border-top: 1px solid ${theme.border.medium};
    }
  `,
);

export const TBody = styled.tbody<{ $empty?: boolean }>(
  ({ $empty, theme }) => css`
    ${!$empty &&
    css`
      tr:hover {
        background-color: ${rgba2rgb(theme.focus.extraSubtle, theme.background.surface)};
      }
    `}
  `,
);

export const TR = styled.tr<ITableRowProps>(
  ({ size = Sizes.Medium, sticky, stickyType, depth, isSelected, sentiment = Sentiments.Default, theme }) => css`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.border.subtle};
    }

    ${sentiment !== Sentiments.Default &&
    css`
      background-color: ${theme.system.subtle[sentiment]};
    `}

    ${isSelected &&
    css`
      background-color: ${rgba2rgb(theme.focus.extraSubtle, theme.background.surface)};
    `}

    ${sticky &&
    css`
      position: sticky;
      z-index: 2;
      background-color: ${theme.background.inactive};
    `};

    ${sticky &&
    stickyType === StickyTypeCell.Header &&
    css`
      top: calc(${depth} * ${tableConsts[size].height});
    `};

    ${sticky &&
    stickyType === StickyTypeCell.Footer &&
    css`
      bottom: calc(${depth} * ${tableConsts[size].height});
    `};
  `,
);

export const TH = styled.th<ITableCellProps>(
  ({ width, colSpan }) => css`
    ${sharedCellStyles};

    width: ${width}px;
    max-width: ${width}px;
    min-width: ${width}px;
    text-align: ${(colSpan ?? 0) > 1 ? 'center' : 'left'};

    ${sharedStickyCellStyles};
  `,
);

export const TD = styled.td<ITableCellProps>`
  ${({ width }) => css`
    ${sharedCellStyles};

    width: ${width}px;
    max-width: ${width}px;
    min-width: ${width}px;

    ${sharedStickyCellStyles};
  `}
`;
