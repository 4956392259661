import { forwardRef } from 'react';

import { useTooltip } from '@react-aria/tooltip';
import classNames from 'classnames';

import * as Styled from './Overlay.styles';
import { IOverlayProps } from './Overlay.types';

const Overlay = forwardRef<HTMLElement, IOverlayProps>(
  ({ children, arrowRef, arrowProps, triggerState, className, arrow, ...props }, ref) => {
    const { tooltipProps } = useTooltip(props, triggerState);
    const { placement, overlayProps } = props;
    const childIsString = typeof children === 'string';

    return (
      <Styled.Overlay
        {...tooltipProps}
        padded={childIsString}
        {...overlayProps}
        className={classNames(className, placement)}
        arrow={arrow}
        ref={ref}
      >
        {children || null}
        <span className="tip" {...arrowProps} ref={arrowRef} />
      </Styled.Overlay>
    );
  },
);

export { Overlay };
