import { keyframes } from 'styled-components';

export const rcSelectDropdownSlideUpIn = keyframes`
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
`;

export const rcSelectDropdownSlideUpOut = keyframes`
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
`;
