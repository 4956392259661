/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components';

import { ARROW_KEYPAD_CLASS, ICON_CLASS, Z_INDEX } from '../../utils/constants';
import { DateInputSize } from './components/DateInput/DateInput.types';
import { DateInputFieldType } from './components/DateInputField/DateInputField.types';
import { INativeDatePickerProps } from './components/NativeDatePicker/NativeDatePicker.types';
import { arrowHeight, arrowSize, arrowWidth, datePickerConsts } from './DatePicker.consts';
import { IDatePickerProps } from './DatePicker.types';
import { textInputWrapperConsts } from '../TextInput/TextInput.consts';
import { Sizes, RequiredProperties, Transient } from '../../types/types';

const Dropdown = css<Pick<IDatePickerProps, 'prefixCls'> & Transient<Pick<IDatePickerProps, 'elevation'>, 'elevation'>>`
  ${({ prefixCls, $elevation, theme }) => css`
    div .${prefixCls}-dropdown {
      z-index: ${Z_INDEX.DATE_PICKER};
    }

    .${prefixCls}-dropdown {
      &:focus {
        outline: 2px solid ${theme.focus.medium};
      }

      ${$elevation &&
      css`
        position: absolute;
      `}

      ${!$elevation &&
      css`
        position: relative;

        &::after,
        &::before {
          display: none;
        }
      `};

      display: flex;
      justify-content: center;
      z-index: ${Z_INDEX.ELEVATED};
      border-radius: 2px;

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -${arrowHeight - 1}px;
        left: calc(50% - ${arrowWidth / 2}px);
        border: ${arrowWidth / 2}px solid transparent;
        border-top-color: ${theme.background.raised};
        border-left-color: ${theme.background.raised};
        transform-origin: center;
        transform: rotate(45deg);
        box-shadow: -1px -1px 2.5px 0 rgba(0, 0, 0, 0.08);
      }

      &::after {
        content: '';
        background-color: ${theme.background.raised};
        position: absolute;
        width: ${arrowWidth * 2}px;
        height: ${arrowHeight * 2}px;
        top: 0;
        left: calc(50% - ${arrowWidth}px);
      }

      &-hidden {
        display: none;
      }

      &-placement-topLeft,
      &-placement-topRight {
        .${prefixCls}-range-arrow {
          bottom: calc(${arrowSize} / 2 + 1px);
          transform: rotate(135deg);
        }
      }
      &-placement-bottomLeft,
      &-placement-bottomRight {
        .${prefixCls}-range-arrow {
          top: calc(${arrowSize} / 2 + 1px);
          transform: rotate(-45deg);
        }
      }

      .${prefixCls}-range-arrow {
        display: none;
      }

      .${prefixCls}-panel-container {
        background: ${$elevation ? theme.background.raised : 'transparent'};

        ${$elevation &&
        css`
          box-shadow: ${theme.elevation.level2};
        `}

        ${!$elevation &&
        css`
          box-shadow: none;
        `};
      }
    }
  `}
`;

const Input = css<Required<Pick<IDatePickerProps, 'prefixCls'>>>`
  ${({ prefixCls, theme }) => css`
    &-input {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      background-color: transparent;

      .${prefixCls}-rtl & {
        text-align: right;
      }

      > input {
        ${theme.paragraph.small.normal}
        color: ${theme.text.onSurface.strong};
        width: 100%;
        height: 23px;
        border: 0;
        outline: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
      }

      > input:placeholder-shown,
      > input::placeholder,
      &-placeholder > input {
        opacity: 1;
        color: ${theme.text.onSurface.subtle};
        font-style: italic;
        text-overflow: ellipsis;
      }
    }
  `}
`;

const Cell = css<Required<Pick<IDatePickerProps, 'prefixCls' | 'size'>>>`
  ${({ prefixCls, theme, size }) => css`
    &-cell {
      color: ${theme.text.onSurface.inactive};

      &-inner {
        ${size === Sizes.Small && theme.label.small.normal};
        ${size === Sizes.Medium && theme.label.medium.normal};
        ${size === Sizes.Large && theme.label.large.normal};
        border-left: 1px solid ${theme.border.subtle};
        border-top: 1px solid ${theme.border.subtle};
        color: ${theme.text.onSurface.inactive};
        min-width: ${datePickerConsts[size].boxSize}px;
        min-height: ${datePickerConsts[size].boxSize}px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background-color: ${theme.background.inactive};
        cursor: pointer;
        outline: none;
        transition: background-color 0.3s, border 0.3s;
      }

      &:last-child {
        .${prefixCls}-cell-inner {
          border-right: 1px solid ${theme.border.subtle};
        }
      }

      &-in-view .${prefixCls}-cell-inner {
        color: ${theme.text.onSurface.strong};
        background-color: ${theme.background.raised};

        &:hover {
          background: ${theme.focus.medium};
        }
      }

      &-in-view {
        &.${prefixCls}-cell-selected .${prefixCls}-cell-inner {
          background: ${theme.focus.strong};
          color: ${theme.text.onFocus.strong};
        }

        &.${prefixCls}-cell-in-range-selected .${prefixCls}-cell-inner {
          background: ${theme.focus.strong};
          color: ${theme.text.onFocus.strong};
        }

        &.${prefixCls}-cell-in-range .${prefixCls}-cell-inner {
          background: ${theme.focus.subtle};
        }

        &.${prefixCls}-cell-range-hover-start, &.${prefixCls}-cell-range-hover-end {
          .${prefixCls}-cell-inner {
            background: ${theme.focus.strong};
            color: ${theme.text.onFocus.strong};
          }
        }

        &.${prefixCls}-cell-range-hover {
          position: relative;

          .${prefixCls}-cell-inner {
            background: ${theme.focus.subtle};
          }
        }

        &.${prefixCls}-cell-range-start
          > .${prefixCls}-cell-inner,
          &.${prefixCls}-cell-range-end
          > .${prefixCls}-cell-inner {
          background: ${theme.focus.strong};
          color: ${theme.text.onFocus.strong};
        }
      }

      &-range-hover-start::after {
        border-left: 1px solid ${theme.focus.strong} !important;
      }

      &-range-hover-end::after {
        border-right: 1px solid ${theme.focus.strong} !important;
      }

      &-today .${prefixCls}-cell-inner {
        padding-top: 2px;
        border-bottom: 2px solid ${theme.text.onSurface.inactive};
      }

      &-today:not(.${prefixCls}-cell-disabled) .${prefixCls}-cell-inner {
        border-bottom: 2px solid ${theme.focus.strong};
      }

      &-disabled .${prefixCls}-cell-inner {
        cursor: not-allowed;
        color: ${theme.text.onSurface.inactive};
        background-color: ${theme.background.surface};

        &:hover {
          background: #f3f3f3;
        }
      }
    }
  `}
`;

const Header = css<Required<Pick<IDatePickerProps, 'size'>>>`
  ${({ theme, size }) => css`
    &-header {
      display: flex;

      &-view {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
          ${size === Sizes.Large && theme.heading[3].demi};
          ${size === Sizes.Medium && theme.heading[4].bold};
          ${size === Sizes.Small && theme.heading[5].bold};
          color: ${theme.text.onSurface.strong};
          border: 0;
          padding: 0;
          background: none;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &:hover {
            cursor: pointer;
            color: ${theme.focus.strong};
          }
        }
      }

      &-next-btn,
      &-prev-btn,
      &-super-next-btn,
      &-super-prev-btn {
        padding: 8px 4px;
        border: 0;
        margin: 0;
        background: none;
        cursor: pointer;

        > svg {
          display: block;

          &:hover {
            fill: ${theme.focus.strong};
          }
        }
      }

      &-super-next-btn,
      &-super-prev-btn {
        display: none;
      }
    }
  `}
`;

const Panel = css<
  Required<Pick<IDatePickerProps, 'prefixCls' | 'size'>> & Transient<Pick<IDatePickerProps, 'elevation'>, 'elevation'>
>`
  ${({ prefixCls, size, $elevation, theme }) => css`
    &-panel {
      background-color: ${$elevation ? theme.background.raised : 'transparent'};
      display: inline-block;
      vertical-align: top;
      margin: ${$elevation
        ? `${datePickerConsts[size].panelGap}px
        ${datePickerConsts[size].panelPadding}px
        ${datePickerConsts[size].panelPadding}px`
        : `${datePickerConsts[size].panelGap}px 0 0`};

      &:not(.${prefixCls}-panel-focused) .${ARROW_KEYPAD_CLASS} {
        display: none;
      }

      &-focused {
        .${ARROW_KEYPAD_CLASS} {
          display: flex;
        }

        .shell-date-picker-content tbody {
          outline: 2px solid ${theme.focus.medium};
        }
      }

      &-rtl {
        direction: rtl;
      }
    }

    &-panels {
      display: flex;
      gap: ${$elevation ? '0' : `${datePickerConsts[size].panelPadding}px`};
    }

    &-decade-panel,
    &-year-panel,
    &-month-panel,
    &-week-panel,
    &-date-panel,
    &-time-panel {
      display: flex;
      flex-direction: column;

      .${prefixCls}-content {
        background-color: ${theme.background.raised};
      }

      table {
        text-align: center;
        border-collapse: collapse;
        border-spacing: 0;

        td {
          padding: 0;
          margin: 0;
        }
      }

      tbody tr:last-child {
        .${prefixCls}-cell-inner {
          border-bottom: 1px solid ${theme.border.subtle};
        }
      }

      thead th {
        ${(size === Sizes.Large || size === Sizes.Medium) && theme.label.large.bold};
        ${size === Sizes.Small && theme.label.medium.bold};
        color: ${theme.text.onSurface.subtle};
        height: ${datePickerConsts[size].boxSize}px;
      }
    }

    &-year-panel,
    &-month-panel,
    &-decade-panel {
      .${prefixCls}-header-super-prev-btn, .${prefixCls}-header-super-next-btn {
        display: block;
      }

      .${prefixCls}-header {
        margin-bottom: ${datePickerConsts[size].panelGap}px;
      }

      .${prefixCls}-cell-inner {
        width: ${datePickerConsts[size].cellWidth}px;
      }
    }

    &-week-panel {
      &-row {
        &:hover {
          .${prefixCls}-cell {
            background: ${theme.focus.subtle};
          }
        }

        &-selected {
          .${prefixCls}-cell {
            background: ${theme.focus.strong};
          }
        }
      }

      .${prefixCls}-cell-week {
        width: 20px;
        font-size: 12px;
        color: #999;
        font-weight: bold;
      }

      .${prefixCls}-cell:hover > .${prefixCls}-cell-inner, .${prefixCls}-cell-selected > .${prefixCls}-cell-inner {
        background: transparent;
      }
    }

    &-time-panel {
      width: auto;

      .${prefixCls}-header {
        height: 32px;
        margin-bottom: 12px;

        &-view {
        }
      }

      .${prefixCls}-content {
        display: flex;
        max-height: 240px;
      }

      &-column {
        flex: none;
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 0 0 180px 0;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 50px;
        transition: background-color 0.3s;
        font-size: 12px;

        &-active {
          background: rgba(0, 0, 255, 0.1);
        }

        &:hover {
          overflow-y: auto;
        }

        > li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          padding: 0;
          margin: 0;
          cursor: pointer;
          color: ${theme.text.onSurface.subtle};

          .${prefixCls}-time-panel-cell-inner {
            width: 100%;
            height: 20px;
            margin: 0;
            color: ${theme.text.onSurface.subtle};
            line-height: 20px;
            text-align: center;

            .${prefixCls}-panel-rtl & {
              padding: 0 12px 0 0;
              text-align: right;
            }
          }

          &:hover {
            background: ${theme.focus.medium};
          }
        }
      }
    }

    &-datetime-panel {
      display: flex;

      .${prefixCls}-time-panel {
        margin-left: 20px;
      }

      .${prefixCls}-date-panel, .${prefixCls}-time-panel {
        transition: opacity 0.3s;
      }

      &-active {
        .${prefixCls}-date-panel, .${prefixCls}-time-panel {
          opacity: 0.3;

          &-active {
            opacity: 1;
          }
        }
      }
    }
  `}
`;

const DatePickerWrapper = styled.div<
  RequiredProperties<IDatePickerProps, 'size' | 'prefixCls'> &
    Transient<Pick<IDatePickerProps, 'elevation' | 'invalid'>, 'elevation' | 'invalid'>
>`
  ${({ prefixCls, size, $elevation, $invalid, theme }) => css`
    width: 100%;

    & > div:not(.${prefixCls}) {
      position: ${!$elevation && 'relative !important'};
    }

    .${prefixCls} {
      display: flex;
      height: ${datePickerConsts[size].height}px;
      box-sizing: border-box;
      border: 1px solid ${theme.border.medium};
      border-radius: 2px;
      padding: 0 8px 0 12px;
      cursor: pointer;
      background-color: ${theme.background.surface};
      ${$invalid && `border: 1px solid ${theme.system.strong.negative}`};

      &:hover {
        border-color: ${theme.focus.strong};
      }

      svg {
        fill: ${theme.text.onSurface.subtle};
      }

      &-rtl {
        direction: rtl;
      }

      ${Panel}
      ${Header}
      ${Cell}

      &-footer {
        display: flex;
        justify-content: space-between;

        &-extra {
          order: 2;
        }
      }

      &-today-btn,
      &-ok button,
      &-now a {
        border: 1px solid ${theme.accent.primary.strong};
        ${(size === Sizes.Large || size === Sizes.Medium) && theme.label.large.bold};
        ${size === Sizes.Small && theme.label.medium.bold};
        margin-top: ${datePickerConsts[size].panelGap}px;
        display: inline-block;
        text-align: center;
        padding: ${size === Sizes.Medium ? '7px 12px' : '10px 16px'};
        border-radius: 2px;
        background-color: ${theme.accent.primary.strong};
        color: ${theme.text.onPrimary.strong};
        cursor: pointer;
        transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);

        &:focus {
          outline: 0;
          border: 1px solid #fff;
          box-shadow: 0 0 0 3px ${theme.focus.medium};
        }

        &:hover {
          background-color: ${theme.accent.primary.medium};
          border-color: ${theme.accent.primary.medium};
        }
      }

      &-now a {
        border-color: ${theme.border.medium};
        background-color: transparent;
        color: ${theme.text.onSurface.strong};

        &:focus {
          outline: 0;
          border: 1px solid #fff;
          box-shadow: 0 0 0 3px ${theme.focus.medium};
        }

        &:hover {
          background-color: transparent;
          border-color: ${theme.focus.strong};
        }
      }

      &-ranges {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style: none;
        gap: 12px;

        > li {
          display: inline-block;
        }
      }

      ${Input}

      &-suffix {
        align-self: center;
      }

      &-clear {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        left: auto;
        bottom: 0;
        background: ${theme.background.surface};

        .${prefixCls}-rtl & {
          right: auto;
          left: 0;
        }
      }

      &-suffix {
        height: ${datePickerConsts[size].height}px;

        svg {
          height: ${datePickerConsts[size].height}px;
          fill: ${theme.text.onSurface.subtle};
        }
      }

      &-range {
        display: flex;
        position: relative;

        &-wrapper {
          display: flex;
          justify-content: center;

          .${prefixCls}-footer {
            margin: 0;
          }
        }

        .${prefixCls}-active-bar {
          background: ${theme.focus.strong};
          bottom: 0;
          height: 3px;
          pointer-events: none;
          transition: all 0.3s;
          opacity: 0;
          transform: translateX(12px);
        }

        &.${prefixCls}-focused {
          .${prefixCls}-active-bar {
            opacity: 1;
          }
        }

        &-separator {
          display: flex;
          align-items: center;
          padding: 0 10px;
        }

        .${prefixCls}-clear {
          right: 0;

          .${prefixCls}-rtl & {
            right: auto;
            left: 8px;
          }
        }
      }

      &-panel-container {
        display: inline-block;
        vertical-align: top;
        transition: margin 0.3s;
      }
    }

    ${Dropdown}

    .${prefixCls} {
      &-focused {
        outline: 2px solid ${theme.focus.medium};
      }

      &-disabled {
        cursor: not-allowed;
        background-color: ${theme.background.inactive};
        border: 1px solid ${theme.border.subtle};

        > .${prefixCls}-input {
          > input {
            cursor: not-allowed;
          }

          > input:placeholder-shown,
          > input::placeholder,
          &-placeholder > input,
          input {
            color: ${theme.text.onSurface.inactive};
          }
        }

        svg {
          fill: ${theme.text.onSurface.inactive};
        }
      }
    }
  `}
`;

const NativePicker = styled.div<Required<Pick<INativeDatePickerProps, 'size'>> & { $hidePicker: boolean }>`
  ${({ size, $hidePicker, theme }) => css`
    position: relative;
    width: 100%;

    .${ICON_CLASS} {
      position: absolute;
      right: 12px;
      top: calc(50% - calc(${textInputWrapperConsts.iconSize[size]} / 2));
      width: ${textInputWrapperConsts.iconSize[size]};
      height: ${textInputWrapperConsts.iconSize[size]};
      pointer-events: none;
      background-color: ${theme.background.surface};
    }

    ${$hidePicker &&
    css`
      .${ICON_CLASS}, input::-webkit-calendar-picker-indicator {
        display: none;
      }
    `}
  `};
`;

const DateInputWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const DateInputField = styled.div<{ dateType: DateInputFieldType; size: DateInputSize }>`
  ${({ dateType, size }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${size === Sizes.Small &&
    css`
      width: ${dateType === 'Year' ? '68px' : '44px'};
    `}

    ${size === Sizes.Medium &&
    css`
      width: ${dateType === 'Year' ? '72px' : '48px'};
    `}

    ${size === Sizes.Large &&
    css`
      width: ${dateType === 'Year' ? '84px' : '56px'};
    `}
  `}
`;

export { DatePickerWrapper, NativePicker, DateInputWrapper, DateInputField };
