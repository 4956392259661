import { ForwardedRef, forwardRef, useMemo } from 'react';

import { TableContext } from './hooks/useTable';
import * as Styled from './Table.styles';
import { ITableProps } from './Table.types';
import { Sizes, Alignments } from '../../types/types';

const Table = forwardRef(
  ({ size = Sizes.Medium, alignment, align, ...props }: ITableProps, ref: ForwardedRef<HTMLTableElement>) => {
    const value = useMemo(
      () => ({
        size,
        alignment: alignment || align || Alignments.Left,
      }),
      [size, alignment],
    );

    return (
      <TableContext.Provider value={value}>
        <Styled.Table ref={ref} size={size} alignment={alignment} {...props} cellPadding={1} />
      </TableContext.Provider>
    );
  },
);

export default Table;
