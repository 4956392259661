import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment';
export var MaintenanceOverlapping = function (_a) {
    var _b = _a.visible, visible = _b === void 0 ? false : _b, title = _a.title, text = _a.text, onHide = _a.onHide, onSubmit = _a.onSubmit, data = _a.data;
    return (_jsx(_Fragment, { children: visible && (_jsx("div", __assign({ className: "overlay" }, { children: _jsxs("div", __assign({ className: "popup" }, { children: [_jsx("div", __assign({ className: "popup-head" }, { children: title })), _jsx("div", __assign({ className: "popup-text" }, { children: text })), _jsxs("div", __assign({ className: "content" }, { children: [_jsx("div", __assign({ className: "table-content" }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Unit Name" }), _jsx("th", { children: "Start Date" }), _jsx("th", { children: "End Date" }), _jsx("th", { children: "% Offline" })] }) }), _jsx("tbody", { children: data.map(function (dt, i) {
                                                return (_jsxs("tr", { children: [_jsx("td", { children: dt.unitName }), _jsx("td", { children: moment(new Date(dt.startDate)).format('DD MMM YYYY') }), _jsx("td", { children: moment(new Date(dt.endDate)).format('DD MMM YYYY') }), _jsx("td", { children: dt.offlinePCT })] }, i));
                                            }) })] }) })), _jsxs("div", __assign({ className: "action-container" }, { children: [_jsx("button", __assign({ className: "k-button border-1 mr-3 action", onClick: onHide }, { children: "No" })), _jsx("button", __assign({ className: "k-button k-primary", onClick: onSubmit }, { children: "Yes" }))] }))] }))] })) }))) }));
};
export default MaintenanceOverlapping;
