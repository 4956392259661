import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormField, TextArea, Sentiments, Alert } from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useHandleSave } from './hooks';
import { AddEventMaintenanceEventDrawerHook } from './AddEventMaintenanceEventDrawer.hook';
import { getDefaultValues } from './AddEditMaintenanceEventDrawer.utils';
import { AddEditConditionalFields } from './components/AddEditConditionalFields';
import { AddEditMandatoryFields } from './components/AddEditMandatoryFields';
import { StyledAlert } from 'common/styles/ComponentStyles';
import { BaseDrawer } from 'components/base-drawer';
import { BaseDrawerForm } from 'components/base-drawer-form';
import { BaseDrawerFormFooter } from 'components/base-drawer-form-footer';
import { LastEditedInfo } from 'components/last-edited-info/LastEditedInfo';
var checkIsValid = function (watch) {
    var validConditionalFields = watch('asset') && watch('units') && watch('units').length > 0 && watch('startDate');
    var validDates = watch('endDate') && dayjs(watch('endDate')).isSameOrAfter(watch('startDate'), 'day');
    var validOtherFields = !isNaN(Number(watch('offlineCapacityOriginal'))) && watch('eventType') && watch('provider');
    return !!(validConditionalFields && validDates && validOtherFields);
};
var checkIsDirty = function (event, watch) {
    if (event) {
        var defaultValues = getDefaultValues(event);
        var dirtyFieldsTop = defaultValues.offlineCapacityOriginal !== watch('offlineCapacityOriginal') ||
            defaultValues.lockBaseline !== watch('lockBaseline') ||
            dayjs(defaultValues.startDate).toISOString() !== dayjs(watch('startDate')).toISOString() ||
            dayjs(defaultValues.endDate).toISOString() !== dayjs(watch('endDate')).toISOString();
        var dirtyFieldsBottom = defaultValues.eventType !== watch('eventType') ||
            defaultValues.eventCause !== watch('eventCause') ||
            defaultValues.provider !== watch('provider') ||
            defaultValues.comment !== watch('comment');
        return dirtyFieldsTop || dirtyFieldsBottom;
    }
    return false;
};
// eslint-disable-next-line complexity
export var AddEditMaintenanceEventDrawer = function (_a) {
    var _b;
    var open = _a.open, onClose = _a.onClose, onSave = _a.onSave, event = _a.event, bulkEvents = _a.bulkEvents;
    var isEditingDisabled = !!((_b = event === null || event === void 0 ? void 0 : event.event_cause_type_desc) === null || _b === void 0 ? void 0 : _b.includes('Forecast'));
    var form = useForm({
        defaultValues: getDefaultValues(event),
    });
    var watch = form.watch, setValue = form.setValue, register = form.register, handleSubmit = form.handleSubmit;
    var _c = useState(false), isSaving = _c[0], setIsSaving = _c[1];
    var _d = AddEventMaintenanceEventDrawerHook.useRequests(form, !open), assets = _d.assets, units = _d.units, _e = _d.isFetching, isFetchingAcmHierarchy = _e.isFetchingAcmHierarchy, isFetchingUnit = _e.isFetchingUnit, isFetchingEventCauseTypes = _e.isFetchingEventCauseTypes, isFetchingAssetEventTypes = _e.isFetchingAssetEventTypes, saveAssetUnitEvent = _d.saveAssetUnitEvent, updateAssetUnitEvent = _d.updateAssetUnitEvent, eventCauseTypes = _d.eventCauseTypes, assetEventTypes = _d.assetEventTypes;
    var _f = useHandleSave({
        saveAssetUnitEvent: saveAssetUnitEvent,
        updateAssetUnitEvent: updateAssetUnitEvent,
        onSave: onSave,
        event: event,
        bulkEvents: bulkEvents,
        setIsSaving: setIsSaving,
    }), handleSave = _f.handleSave, saveErrors = _f.saveErrors, setError = _f.setError;
    var onLockEvent = function (lock) {
        setValue('lockBaseline', lock);
        handleSubmit(handleSave)();
    };
    var isDirtyState = checkIsDirty(event, watch);
    var isValidState = checkIsValid(watch);
    AddEventMaintenanceEventDrawerHook.useSetDefaultEventDrawer({ event: event, setValue: setValue });
    AddEventMaintenanceEventDrawerHook.useReset(form, setError, open);
    return (_jsx(BaseDrawer, __assign({ title: event ? 'Edit maintenance event' : '', subtitle: event
            ? !bulkEvents.length
                ? "".concat(event.asset_nm, "/").concat(event.asset_unit_nm)
                : "".concat(event.asset_nm, "/Multiple")
            : 'New Event', onClose: onClose, open: open, showContent: open }, { children: _jsxs(BaseDrawerForm, __assign({ onSubmit: handleSubmit(handleSave), footer: _jsx(_Fragment, { children: _jsx(BaseDrawerFormFooter, { saveDisabled: (event && !isDirtyState) || isSaving, cancelDisabled: isSaving, onCancelClick: onClose, showSavingLoader: isSaving, confirmMaintenanceSave: isValidState && !watch('lockBaseline'), onLockEvent: onLockEvent }) }) }, { children: [isEditingDisabled && (_jsxs(Alert, __assign({ style: { marginBottom: '12px' }, sentiment: Sentiments.Information }, { children: ["This is a forecast event, and cannot be edited. ", _jsx("br", {}), " Please create a new event instead."] }))), !event && (_jsx(AddEditConditionalFields, { form: form, units: units, assets: assets, isFetchingAssets: isFetchingAcmHierarchy, isFetchingUnit: isFetchingUnit })), _jsx(AddEditMandatoryFields, { form: form, units: units, assetEventTypes: assetEventTypes, isFetchingAssetEventTypes: isFetchingAssetEventTypes, eventCauseTypes: eventCauseTypes, isFetchingEventCauseTypes: isFetchingEventCauseTypes, isEditingDisabled: isEditingDisabled }), _jsx(FormField, __assign({ size: "small", label: "Comments", bottomLeftHelper: {
                        content: event && (_jsx(LastEditedInfo, { lastEditedBy: event.meta_changed_by_nm, lastEditedAt: event.version_effective_dt })),
                    } }, { children: _jsx(TextArea, __assign({}, register('comment'), { value: watch('comment'), rows: 3, disabled: isEditingDisabled })) })), saveErrors === null || saveErrors === void 0 ? void 0 : saveErrors.map(function (err) { return (_jsx(StyledAlert, __assign({ sentiment: Sentiments.Negative }, { children: err }), err)); })] })) })));
};
