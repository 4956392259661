import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { LABEL_CLASS } from '../../../../utils/constants';
import * as Styled from './Label.styles';
import { ILabelProps } from './Label.types';
import { getProminence, getTextType } from '../../utils/textUtils';
import { Sizes, TextTypes } from '../../../../types/types';

const Label = forwardRef<HTMLSpanElement, ILabelProps>(
  (
    {
      className = '',
      size = Sizes.Medium,
      type = TextTypes.Span,
      color,
      prominence,
      gutter = false,
      bold = false,
      ...rest
    },
    ref,
  ): ReactElement => {
    const innerType = getTextType(type);
    const innerProminence = getProminence(color, prominence);

    return (
      <Styled.Label
        className={classNames(LABEL_CLASS, className)}
        as={innerType}
        size={size}
        prominence={innerProminence}
        gutter={gutter}
        bold={bold}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default Label;
