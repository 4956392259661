var _a;
import moment from 'moment';
//defaut data for api context
export var defaultConst = (_a = {
        Countries: [],
        Cities: [],
        Companies: [],
        AssetClasses: []
    },
    _a['Assets/AssetFilter'] = [],
    _a.AssetUnitTypesMaintenance = [],
    _a.AssetUnitTypesCapacity = [],
    _a.AssetUnitSubtypesMaintenance = [],
    _a.AssetUnitSubtypesCapacity = [],
    _a['AssetUnits/AssetUnitFilter/Maintenance'] = [],
    _a['AssetUnits/AssetUnitFilter/Capacity'] = [],
    _a.EditAreaUnitFilter = [],
    _a.AssetEventTypes = [],
    _a.EventCauseTypes = [],
    _a['DataProviders/Maintenance'] = [],
    _a['DataProviders/Capacity'] = [],
    _a.CapacityBasisTypes = [],
    _a.PlanningProbabilityTypes = [],
    _a.PlanningStatusTypes = [],
    _a.reclassificationData = [],
    _a.co = 0,
    _a.ci = 0,
    _a.o = 0,
    _a.at = 0,
    _a.a = 0,
    _a.ut = 0,
    _a.ust = 0,
    _a.unit = [],
    _a.multipleUnit = [],
    _a.path = [
        'Countries',
        'Cities',
        'Companies',
        'AssetClasses',
        'Assets/AssetFilter',
        'AssetUnitTypes',
        'AssetUnitSubtypes',
        'AssetUnits/AssetUnitFilter',
    ],
    _a.dataAreaPath = [
        'AssetEventTypes',
        'EventCauseTypes',
        // "DataProviders/UserSpecificDataProvider",
        'CapacityBasisTypes',
        'PlanningProbabilityTypes',
        'PlanningStatusTypes',
    ],
    _a.capacityData = {
        assetUnitKey: [],
        capacityBasisTypeKey: null,
        planningProbabilityTypeKey: null,
        dataProviderKey: null,
        //capacityOriginalUomKey: "",
        capacityUniversalUomKey: null,
        capacityOriginalUomNm: '',
        planningStatusTypeKey: null,
        capacityUniversalQty: 100,
        capacityStartDt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        comment: '',
        url: '',
        baselinedFromKey: null,
        eventId: null,
    },
    _a.maintenanceData = {
        eventStartDt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        eventEndDt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        assetEventTypeKey: null,
        dataProviderKey: null,
        offlineCapacityOriginal: 100,
        eventCauseTypeKey: null,
        offlineCapacityOriginalUomKey: 2,
        offlineDataType: 'PCT',
        assetUnitEventKey: [],
        eventLockInd: false,
        url: '',
        comment: '',
    },
    _a.maintenanceAssetUnitKey = {},
    _a.maintenanceAssetEventTypeKey = {},
    _a.maintenanceDataProviderKey = {},
    _a.maintenanceEventCauseTypeKey = {},
    _a.capacityAssetUnitKey = {},
    _a.capacityCapacityBasisTypeKey = {},
    _a.capacityPlanningProbabilityTypeKey = {},
    _a.capacityDataProviderKey = {},
    _a.capacityPlanningStatusTypeKey = {},
    _a.capacityShowEvent = false,
    _a.maintenanceShowEvents = false,
    _a.capacitySave = false,
    _a.reclassifiedData = {
        capacityID: 0,
        quantity: 0,
        units: [],
    },
    _a.maintanenceShowEvents = false,
    _a.capacityShowEvents = false,
    _a.ignoreCapacity = false,
    _a.showXBuckets = false,
    _a.defaultCapacityUOMkey = '',
    _a.defaultUomOnUnitSelection = '___',
    _a.unitRefreshOnUpdate = false,
    _a.capacityGridScrollPos = null,
    _a.currentMultipleItemsSelection = [],
    _a);
