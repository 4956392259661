import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import GridTemplate from '../../../../commons/gridTemplate';
var dataServiceTerminalType = /** @class */ (function (_super) {
    __extends(dataServiceTerminalType, _super);
    function dataServiceTerminalType(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            dataServiceTerminalTypeCd: {
                required: true,
                error: 'Enter Data Service Terminal Type Cd unique key.',
            },
        };
        var columns = [
            {
                field: 'dataServiceTerminalTypeCd',
                title: 'Data Service Terminal Type Cd',
            },
            {
                field: 'dataServiceTerminalTypeNm',
                title: 'Data Service Terminal Type Nm',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
        };
        return _this;
    }
    dataServiceTerminalType.prototype.render = function () {
        return (_jsx(GridTemplate, { path: "dataServiceTerminalType", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "dataServiceTerminalTypeCd" }));
    };
    return dataServiceTerminalType;
}(Component));
export default dataServiceTerminalType;
