import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { ApiProvider } from '../../components/programs/geneva/dataStore/apiDataStore';
import ChartList from './charts/chartList';
import SvgLogo from '../../commons/tools/svgLogo';
import ChartAddEdit from './ChartAddEdit';
import ChartHeader from './ChartHeader';
import ChartFilterContainer from './ChartFilterContainer';
var allFiltersGd = {};
var Charts = function () {
    var _a = useState(false), showChartWindow = _a[0], setShowChartWindow = _a[1];
    var _b = useState({}), allState = _b[0], setAllState = _b[1];
    var _c = useState({}), selectedData = _c[0], setSelectedData = _c[1];
    var _d = useState(false), windowEnable = _d[0], setWindowEnable = _d[1];
    var _e = useState(''), chartPath = _e[0], SetchartPath = _e[1];
    var getRandom = function () {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
    var OnApply = function (url) {
        SetchartPath(url);
        setShowChartWindow(getRandom());
        chartPath = url;
    };
    var resetChartlistFilter = function () {
        setShowChartWindow(false);
    };
    var handleAddEdit = function () {
        if (windowEnable) {
            setWindowEnable(false);
        }
        else {
            setWindowEnable(true);
        }
    };
    var setAllFilters = function (data) {
        allFiltersGd = __assign(__assign({}, allFiltersGd), data);
        setAllState(allFiltersGd);
    };
    useEffect(function () {
        setShowChartWindow(getRandom());
    }, []);
    return (_jsx(ApiProvider, { children: _jsxs("div", { children: [_jsx(ChartHeader, { handleAddEdit: handleAddEdit }), _jsxs("div", __assign({ id: "dashboard-chart", class: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100" }, { children: [_jsx(ChartFilterContainer, { OnApply: OnApply, resetChartlistFilter: resetChartlistFilter }), _jsxs("div", __assign({ style: {
                                height: '725px',
                                float: 'left',
                                background: '#202020',
                                width: '100%',
                                zIndex: '1',
                            } }, { children: [!showChartWindow && (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent" }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ className: "text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "Click on Apply to view" }))] })) })) }))), showChartWindow && _jsx(ChartList, { path: chartPath }, showChartWindow)] }))] })), _jsx(ChartAddEdit, { handleAddEdit: handleAddEdit, windowEnable: windowEnable, allState: allState })] }) }));
};
export default Charts;
