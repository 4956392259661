import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
import { StyledSingleDatePicker } from 'components/single-date-picker/singleDatePicker.styles';
var StyedMonthPicker = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  i {\n    color: white;\n    margin-right: 30px;\n    font-size: 2rem;\n    transition: all 0.2s ease-in-out;\n    &:hover {\n      cursor: pointer;\n      transform: scale(1.5);\n    }\n  }\n  label {\n    margin-bottom: 0px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  i {\n    color: white;\n    margin-right: 30px;\n    font-size: 2rem;\n    transition: all 0.2s ease-in-out;\n    &:hover {\n      cursor: pointer;\n      transform: scale(1.5);\n    }\n  }\n  label {\n    margin-bottom: 0px;\n  }\n"])));
export var MonthPicker = function (_a) {
    var monthlySkip = _a.monthlySkip, _b = _a.value, value = _b === void 0 ? dayjs().format('YYYY-MM-DD') : _b, _c = _a.onMonthChange, onMonthChange = _c === void 0 ? noop : _c;
    if (monthlySkip) {
        return (_jsxs(StyedMonthPicker, { children: [_jsx("i", { onClick: function () { return onMonthChange(dayjs(value).subtract(1, 'M')); }, className: "fa fa-angle-left" }), _jsx("i", { onClick: function () { return onMonthChange(dayjs(value).add(1, 'M')); }, className: "fa fa-angle-right" }), _jsx(StyledLabel, __assign({ size: "large" }, { children: dayjs(value).format('MMM YYYY') }))] }));
    }
    return (_jsx(StyledSingleDatePicker, { elevation: true, value: dayjs(value), size: "small", dropdownAlign: {
            overflow: {
                adjustY: true,
            },
        }, format: "MMM YYYY", onChange: onMonthChange, allowClear: false, 
        // @ts-ignore: passing type for rc-picker
        type: "single" }));
};
var templateObject_1;
