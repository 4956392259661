import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import UserRole from './userRole';
import AssignedRole from './assignedRole';
// import Person from "./person";
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var accessAndSecurityReferenceNavLink = /** @class */ (function (_super) {
    __extends(accessAndSecurityReferenceNavLink, _super);
    function accessAndSecurityReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    accessAndSecurityReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Access and Security ", _jsx("span", { children: "(Reference Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/accessAndSecurityRefrence/userRole", className: "nav-link", activeClassName: "active" }, { children: "User Role" })), _jsx(NavLink, __assign({ to: "/dashboard/accessAndSecurityRefrence/assignedRole", className: "nav-link", activeClassName: "active" }, { children: "Assigned Role" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/accessandsecurityrefrence", to: "/dashboard/accessAndSecurityRefrence/userRole", exact: true }), _jsx(Route, { path: "/dashboard/accessAndSecurityRefrence/userRole", component: UserRole, exact: true }), _jsx(Route, { path: "/dashboard/accessAndSecurityRefrence/assignedRole", component: AssignedRole, exact: true })] }) }))] }))] })) }));
    };
    return accessAndSecurityReferenceNavLink;
}(Component));
export default accessAndSecurityReferenceNavLink;
