import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';
import { Table, THead, TBody, TR, Flexbox, SorterButton, Resizer } from '@sede-x/shell-ds-react-framework';
import { GroupedEventsRow } from './components/GroupedEventsRow';
import { convertToTableData, defaultColumns, getStickyStyles, useExpandRowCheckboxChange, useHandleSelectAll, } from './maintenance-utils/MaintenanceEventTable.utils';
import { useNewTableColumns } from 'pages/maintenance-event-summary/maintenance-utils/MaintenanceEventTable.hooks';
import { StyledTD, StyledTH } from 'pages/asset-catalog/AssetCatalog.styles';
import { StyledFooter } from 'components/table/table.styles';
import ConfigureColumns from 'components/configure-columns/ConfigureColumns';
import { EventsSummaryActionsModal } from 'components/event-summary-actions-modal';
import { BulkEditFooter } from 'components/bulk-edit-footer/BulkEditFooter';
import { Pagination } from 'components/pagination';
var GROUP_INDEX = [2, 5, 9, 11];
export var MaintenanceEventTable = function (_a) {
    var events = _a.events, isConfigureModalOpen = _a.isConfigureModalOpen, closeConfigureModal = _a.closeConfigureModal, onRowClick = _a.onRowClick, onPaginationChange = _a.onPaginationChange, currentPage = _a.currentPage, totalRows = _a.totalRows;
    var eventsWithGroups = useMemo(function () { return convertToTableData(events); }, [events]);
    var _b = useState([]), sorting = _b[0], setSorting = _b[1];
    var _c = useState(false), checked = _c[0], setChecked = _c[1];
    var _d = useState([]), selectedEvents = _d[0], setSelectedEvents = _d[1];
    var expandRowCheckboxChange = useExpandRowCheckboxChange(selectedEvents, setSelectedEvents);
    var _e = useState([]), expandedData = _e[0], setExpandedData = _e[1];
    useEffect(function () {
        setExpandedData([]);
    }, [events]);
    useEffect(function () {
        if (!selectedEvents.length) {
            setChecked(false);
            return;
        }
        if (selectedEvents.length === expandedData.length) {
            setChecked(true);
            return;
        }
        setChecked('indeterminate');
    }, [selectedEvents, expandedData]);
    var handleSelectAll = useHandleSelectAll(setSelectedEvents, setChecked, expandedData, checked);
    var setGroupedEvents = function (subRows) {
        setExpandedData(subRows);
        setChecked(true);
        setSelectedEvents(subRows);
    };
    var table = useReactTable({
        data: eventsWithGroups,
        columns: useNewTableColumns(setGroupedEvents, handleSelectAll, checked),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        state: {
            sorting: sorting,
        },
        onSortingChange: setSorting,
        sortDescFirst: true,
        defaultColumn: {
            size: 200,
            minSize: 100,
            maxSize: 1000,
        },
        getRowCanExpand: function (row) { return !!row.original.subrows; },
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: {
                    position: 'relative',
                    maxHeight: "calc(100vh - 230px )",
                    overflowY: 'auto',
                } }, { children: _jsxs(Table, __assign({ size: "small" }, { children: [_jsx(THead, __assign({ style: { borderRadius: '4px' } }, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header, index) {
                                    var _a;
                                    return (_jsx(StyledTH, __assign({ width: header.getSize(), fixedIndex: [0, 1].includes(index), style: __assign(__assign({}, getStickyStyles(index, 'header')), { zIndex: 100 }), isActions: header.id.includes('Actions') }, { children: _jsxs(Flexbox, __assign({ alignItems: "center" }, { children: [header.isPlaceholder ? null : (_jsxs(Flexbox, __assign({ onClick: function () { return header.column.getCanSort() && header.column.toggleSorting(); }, alignItems: "center" }, { children: [flexRender(header.column.columnDef.header, header.getContext()), (_a = (header.column.getCanSort() &&
                                                            {
                                                                asc: _jsx(SorterButton, { sortDirection: "asc" }),
                                                                desc: _jsx(SorterButton, { sortDirection: "desc" }),
                                                            }[header.column.getIsSorted()])) !== null && _a !== void 0 ? _a : _jsx(SorterButton, {})] }))), index !== headerGroup.headers.length - 1 && _jsx(Resizer, { onMouseDown: header.getResizeHandler() })] })) }), header.id));
                                }) }), headerGroup.id)); }) })), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) {
                                var _a;
                                return (_jsxs(_Fragment, { children: [_jsx(TR, __assign({ style: { cursor: 'pointer' }, onClick: function () {
                                                if (!row.getCanExpand()) {
                                                    onRowClick(row.original);
                                                    return;
                                                }
                                                if (row.getCanExpand() && !row.getIsExpanded()) {
                                                    onRowClick(row.original, row.original.subrows);
                                                    return;
                                                }
                                            } }, { children: row.getVisibleCells().map(function (cell, index) { return (_jsx(StyledTD, __assign({ width: cell.column.getSize(), fixedIndex: [0, 1].includes(index), style: getStickyStyles(index, 'header'), isActions: cell.id.includes('Actions') }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id)); }) }), row.id), row.getIsExpanded() && (_jsx(GroupedEventsRow, { showLoading: false, groupedData: (_a = row.original.subrows) !== null && _a !== void 0 ? _a : [], selectedEvents: selectedEvents, handleChange: expandRowCheckboxChange }))] }));
                            }) })] })) })), _jsx(StyledFooter, { children: !expandedData.length ? (_jsx(Pagination, { pageSize: 20, current: currentPage, total: totalRows, size: "small", onChange: onPaginationChange })) : (_jsx(BulkEditFooter, { onEdit: function () {
                        onRowClick(selectedEvents[0], selectedEvents);
                    }, selectedCount: selectedEvents.length, clearSelection: function () { return setSelectedEvents([]); } })) }), _jsx(ConfigureColumns, { onClose: closeConfigureModal, open: isConfigureModalOpen, table: table, defaultSelectedColumns: defaultColumns, groupIndex: GROUP_INDEX, description: "Maintenance baseline", localStorageId: "maintenanceVisibleColumns" }), _jsx(EventsSummaryActionsModal, {})] }));
};
