import { forwardRef, ForwardedRef } from 'react';

import { Sizes, Variants } from '../../../../types/types';
import { useTable } from '../../hooks/useTable';
import { buttonConsts } from '../../../Button/Button.consts';
import { ITableExpander } from '../../Table.types';
import Button from '../../../Button/Button';
import { getSize } from '../../utils/tableUtils';
import { ChevronDown, ChevronRight } from '../../../Icon/components';
import { TABLE_EXPANDER_BUTTON_CLASS } from '../../../../utils/constants';

export const ExpanderButton = forwardRef(
  ({ expanded, ...props }: ITableExpander, ref: ForwardedRef<HTMLButtonElement>) => {
    const { size = Sizes.Medium } = useTable();

    const iconProps = {
      width: buttonConsts.icons[size],
      height: buttonConsts.icons[size],
    };

    return (
      <Button
        className={TABLE_EXPANDER_BUTTON_CLASS}
        aria-label="Expander button"
        size={getSize(size)}
        variant={Variants.Transparent}
        icon={
          expanded ? (
            <ChevronDown data-testid="expander-expanded" {...iconProps} />
          ) : (
            <ChevronRight data-testid="expander-collapsed" {...iconProps} />
          )
        }
        iconOnly
        ref={ref}
        {...props}
      />
    );
  },
);
