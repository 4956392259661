import * as types from '../actions/masterScreenActionsType';
export default function masterScreenReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case types.LOAD_MASTER_SCREEN_DATA:
            var masterData = new Map();
            var currentMasterState_1 = state.masterData;
            if (typeof currentMasterState_1 !== 'undefined') {
                masterData.set(action.masterDataType, action.masterData);
                masterData.forEach(function (value, key) { return currentMasterState_1.set(key, value); });
                return { masterData: currentMasterState_1 };
            }
            else {
                masterData.set(action.masterDataType, action.masterData);
                return { masterData: masterData };
            }
        default:
            return state;
    }
}
