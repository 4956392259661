var ACM_MAINTENANCE_BASELINE = 'ACM maintenance baseline';
export var userFriendlyNameMappings = new Map([
    ['CREATE_CAPACITY_ASSET_UNIT_BASELINES', 'ACM baseline'],
    ['DAD_SP', 'Downstream Asset Database'],
    ['PETCHEM_FIX', 'Downstream Asset Database'],
    ['SP_IIR_INSERT', 'IIR added an asset unit'],
    ['IIR_rollback', 'IIR updated capacity'],
    ['PY_CAPACITY_AUTOBASELINE', 'ACM capacity baseline'],
    ['SP_IIR_EXPANSION', 'IIR updated capacity'],
    ['SP_PIRA', 'PIRA updated capacity'],
    ['1731811_align_unchanged_17dec_with_16dec', ACM_MAINTENANCE_BASELINE],
    ['1731811_restore_incorrectly_deleted_GS', ACM_MAINTENANCE_BASELINE],
    ['AUTOBASELINE', ACM_MAINTENANCE_BASELINE],
    ['PY_CURATED_CONSUMPTION_BASELINE_CLNUP_TERM', ACM_MAINTENANCE_BASELINE],
    ['PY_CURATED_CONSUMPTION_BASELINE_NEW', ACM_MAINTENANCE_BASELINE],
    ['PY_CURATED_CONSUMPTION_BASELINE_UPD', ACM_MAINTENANCE_BASELINE],
    ['PY_CURATED_CONSUMPTION_ORIGINALS', 'Original provider data'],
    ['PY_CURATED_CONSUMPTION_ORIGINALS_POSTP', 'ACM processed provider originals'],
    ['PY_CURATED_CONSUMPTION_ORIGINALS_STITCHING', 'ACM processed provider originals'],
    ['PY_FORECASTING', 'ACM Forecast'],
    ['PY_FORECASTING_rectify_event_duration_days_by_adding_one', 'ACM Forecast'],
    ['PY_GENSCAPE_RETRO_CHANGES', 'Genscape update ACM baseline'],
    ['REACTIVATE_ETH_PDH_BASELINE', 'IIR updated maintenance'],
    ['SP_GRAD_OFFLINE_EVENT_INSERT', 'Global Refinery Asset Database'],
    ['SP_REFINERY_MAINTENANCE', 'Provider maintenance originals'],
]);
