import { LabelSize } from '../components/Text/components/Label/Label.types';
import { Sizes, ExtraSmallSize, SmallSize, MediumSize, LargeSize } from '../types/types';

/**
 * @deprecated DeprecatedSize contains deprecated sizes types
 */
type DeprecatedSize = ExtraSmallSize | SmallSize | MediumSize | LargeSize;

export const getLabelSize = (
  size: Sizes.ExtraSmall | Sizes.Small | Sizes.Medium | Sizes.Large | DeprecatedSize,
): LabelSize => {
  switch (size) {
    case Sizes.ExtraSmall:
      return Sizes.Small;
    case Sizes.Small:
      return Sizes.Medium;
    default:
      return size;
  }
};
