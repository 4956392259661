import { ReactElement } from 'react';

import classNames from 'classnames';

import { PAGINATION_CLASS } from '../../utils/constants';
import ChevronLeft from '../Icon/components/ChevronLeft';
import ChevronRight from '../Icon/components/ChevronRight';
import PaginationSelect from './components/PaginationSelect';
import enGB from './data/en_GB';
import * as Styled from './Pagination.styles';
import { IPaginationProps } from './Pagination.types';
import { Sizes, Positions } from '../../types/types';

const Pagination = ({
  className,
  size = Sizes.Medium,
  position = Positions.Center,
  showSizeChanger,
  ...rest
}: IPaginationProps): ReactElement => {
  const showSizeChangerProps = {
    ...(showSizeChanger && { showSizeChanger, selectComponentClass: PaginationSelect }),
  };

  return (
    <Styled.Pagination
      prefixCls={PAGINATION_CLASS}
      className={classNames(PAGINATION_CLASS, className)}
      prevIcon={<ChevronLeft />}
      nextIcon={<ChevronRight />}
      locale={enGB}
      position={position}
      size={size}
      {...showSizeChangerProps}
      {...rest}
    />
  );
};

export default Pagination;
