import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonLink, NavBar, Flexbox, Icons } from '@sede-x/shell-ds-react-framework';
import { useLocation, useHistory } from 'react-router-dom';
import { AppNavbar } from '../app-navbar';
import { AppAvatar } from '../app-avatar';
import { NotificationsModal } from '../notifications-modal';
import { Logo } from './components/logo';
var Link = Icons.Link;
var StyledAvatarAreaContainer = styled(Flexbox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 15px;\n  z-index: 1;\n"], ["\n  margin-right: 15px;\n  z-index: 1;\n"])));
var StyledButtonLink = styled(ButtonLink)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
export var AppHeader = function () {
    var _a = useState(false), showNotificationsModal = _a[0], setShowNotificationsModal = _a[1];
    var history = useHistory();
    var location = useLocation();
    var queryStrings = new URLSearchParams(location.search);
    var handleAvatarMenuClick = function () {
        setShowNotificationsModal(true);
        queryStrings.append('notifications', '');
        history.replace({
            search: queryStrings.toString(),
        });
    };
    var handleNotificationsModalClose = function () {
        setShowNotificationsModal(false);
        queryStrings.delete('notifications');
        history.replace({
            search: queryStrings.toString(),
        });
    };
    useEffect(function () {
        var queryStrings = new URLSearchParams(location.search);
        if (queryStrings.has('notifications')) {
            setShowNotificationsModal(true);
        }
    }, [location, history]);
    return (_jsxs(_Fragment, { children: [_jsx(NavBar, __assign({ pectenArea: _jsx(Logo, {}), avatarArea: _jsxs(StyledAvatarAreaContainer, __assign({ justifyContent: "space-between" }, { children: [_jsx(StyledButtonLink, __assign({ href: "/dashboard/globalAssetDatabase/maintenance/maintenanceEventSummary", target: "_blank", icon: _jsx(Link, {}), size: "xsmall", rel: "noreferrer" }, { children: "ACM 1.0" })), _jsx(AppAvatar, { onAvatarMenuClick: handleAvatarMenuClick })] })) }, { children: _jsx("div", __assign({ style: { width: '100%', display: 'flex', alignItems: 'flex-end' } }, { children: _jsx(AppNavbar, {}) })) })), _jsx(NotificationsModal, { open: showNotificationsModal, onClose: handleNotificationsModalClose })] }));
};
var templateObject_1, templateObject_2;
