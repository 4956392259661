import { TagShape, TagSize } from './Tag.types';
import { Sizes, Positions, Shapes } from '../../types/types';

type TagConstsProps = {
  radius: {
    [shape in TagShape]: {
      [size in TagSize]: string;
    };
  };
  minHeight: {
    [size in TagSize]: string;
  };
  padding: {
    label: {
      [size in TagSize]: string;
    };
    container: {
      [shape in TagShape]: {
        [size in TagSize]: string;
      };
    };
    containerWithIcon: {
      [position in Positions.Left | Positions.Right]: {
        [shape in TagShape]: {
          [size in TagSize]: string;
        };
      };
    };
  };
};

export const tagConsts: TagConstsProps = {
  radius: {
    [Shapes.Rounded]: {
      [Sizes.Small]: '8px',
      [Sizes.Medium]: '12px',
      [Sizes.Large]: '14px',
    },
    [Shapes.Square]: {
      [Sizes.Small]: '2px',
      [Sizes.Medium]: '2px',
      [Sizes.Large]: '4px',
    },
    round: {
      [Sizes.Small]: '8px',
      [Sizes.Medium]: '12px',
      [Sizes.Large]: '14px',
    },
  },
  minHeight: {
    [Sizes.Small]: '16px',
    [Sizes.Medium]: '24px',
    [Sizes.Large]: '28px',
  },
  padding: {
    label: {
      [Sizes.Small]: '0 2px',
      [Sizes.Medium]: '4px',
      [Sizes.Large]: '4px',
    },
    container: {
      [Shapes.Rounded]: {
        [Sizes.Small]: '0 4px',
        [Sizes.Medium]: '0 6px',
        [Sizes.Large]: '0 8px',
      },
      [Shapes.Square]: {
        [Sizes.Small]: '0 2px',
        [Sizes.Medium]: '0 4px',
        [Sizes.Large]: '0 4px',
      },
      round: {
        [Sizes.Small]: '0 4px',
        [Sizes.Medium]: '0 6px',
        [Sizes.Large]: '0 8px',
      },
    },
    containerWithIcon: {
      [Positions.Left]: {
        [Shapes.Square]: {
          [Sizes.Small]: '0 2px',
          [Sizes.Medium]: '0 4px 0 6px',
          [Sizes.Large]: '0 4px',
        },
        [Shapes.Rounded]: {
          [Sizes.Small]: '0 4px',
          [Sizes.Medium]: '0 6px 0 8px',
          [Sizes.Large]: '0 8px',
        },
        round: {
          [Sizes.Small]: '0 4px',
          [Sizes.Medium]: '0 6px 0 8px',
          [Sizes.Large]: '0 8px',
        },
      },
      [Positions.Right]: {
        [Shapes.Square]: {
          [Sizes.Small]: '0 2px',
          [Sizes.Medium]: '0 6px 0 4px',
          [Sizes.Large]: '0 4px',
        },
        [Shapes.Rounded]: {
          [Sizes.Small]: '0 4px',
          [Sizes.Medium]: '0 8px 0 6px',
          [Sizes.Large]: '0 8px',
        },
        round: {
          [Sizes.Small]: '0 4px',
          [Sizes.Medium]: '0 8px 0 6px',
          [Sizes.Large]: '0 8px',
        },
      },
    },
  },
};

export const iconSizes = {
  [Sizes.Small]: '14px',
  [Sizes.Medium]: '20px',
  [Sizes.Large]: '24px',
};
