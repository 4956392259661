import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { MODAL_CLASS, MODAL_CONTAINER_CLASS, NOOP } from '../../utils/constants';
import Flexbox from '../Flexbox/Flexbox';
import { Cross } from '../Icon/components';
import Loading from '../Loading/Loading';
import Label from '../Text/components/Label/Label';
import ActionBar from './components/ActionBar/ActionBar';
import Dialog from './components/Dialog/Dialog';
import TitleBar from './components/TitleBar/TitleBar';
import * as Styled from './Modal.styles';
import { IModalProps } from './Modal.types';
import { Sizes, Variants, Spacings, BgColors, Positions, Alignments } from '../../types/types';

const Modal = ({
  children,
  className,
  open,
  title,
  description,
  padding = Spacings.Tight,
  bodyBgColor = BgColors.Surface,
  bodyPadding = true,
  onClose = NOOP,
  showHeader = true,
  showFooter = true,
  actions,
  actionsAlignment,
  actionBarAlignment,
  headerAreaComponent = null,
  loading = false,
  loadingText = 'Loading...',
  size = Sizes.Medium,
  closable = true,
  footerDirection = 'row',
  descriptionPosition = Positions.Bottom,
  contentScrollable = false,
  centered = false,
  width = 520,
  ...props
}: IModalProps): ReactElement => {
  const innerActionsAlignment = actionsAlignment || actionBarAlignment || Alignments.Right;
  const isWithoutHeaderAndFooter =
    (!title && !description && !headerAreaComponent && actions && actions?.length > 0) || (!showHeader && !showFooter);

  return (
    <Styled.Modal
      prefixCls={MODAL_CONTAINER_CLASS}
      className={classNames(MODAL_CLASS, className)}
      as={Dialog}
      visible={open}
      onClose={onClose}
      padding={padding}
      bodyBgColor={bodyBgColor}
      bodyPadding={bodyPadding}
      size={size}
      contentScrollable={contentScrollable}
      closable={closable}
      centered={centered}
      bodyCentered={isWithoutHeaderAndFooter || loading}
      width={width}
      title={
        !loading &&
        showHeader && (
          <TitleBar
            title={title}
            description={description}
            headerAreaComponent={headerAreaComponent}
            size={size}
            padding={padding}
            descriptionPosition={descriptionPosition}
          />
        )
      }
      footer={
        !loading &&
        showFooter &&
        actions && (
          <ActionBar
            size={size}
            padding={padding}
            actions={actions}
            alignment={innerActionsAlignment}
            footerDirection={footerDirection}
          />
        )
      }
      onlyBody={(!showHeader && !showFooter) || loading}
      {...props}
    >
      {closable && (
        <Styled.CloseButton
          id={`${MODAL_CONTAINER_CLASS}-close-button`}
          size={size}
          padding={padding}
          variant={Variants.Transparent}
          icon={<Cross />}
          iconOnly
          onClick={onClose}
        />
      )}
      {loading ? (
        <Flexbox flexDirection="column" gap="12px" alignItems="center">
          <Loading size={Sizes.Large} />
          <Label size={Sizes.Large}>{loadingText}</Label>
        </Flexbox>
      ) : (
        children
      )}
    </Styled.Modal>
  );
};

export default Modal;
