import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function planningPrecisionTypeCrossReference(props) {
    var validationParams = {
        planningPrecisionTypeCd: 'Please select a Planning Precision Type Key',
        dataProviderAbbr: 'Please select a Data Provider',
    };
    var columnParams = [
        {
            field: 'providerPlanningPrecisionTypeCd',
            title: 'Provider Planning Precision Type Cd',
        },
        {
            field: 'planningPrecisionTypeCd',
            title: 'Planning Precision Type Key',
            key: 'planningPrecisionTypeKey',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'versionEffectiveDt',
            title: 'Effective Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionTerminationDt',
            title: 'Termination Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return (_jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'planningPrecisionTypeCrossRef' }));
}
export default planningPrecisionTypeCrossReference;
