import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import noop from 'lodash/noop';
import { OutsideAlerter } from '../../../../../../commons/OutsideAlerter';
var outerStyle = {
    position: 'absolute',
    top: '-21px',
    textAlign: 'left',
    left: '-18px',
    zIndex: 1030,
};
var btnStyle = {
    width: '100%',
    textAlign: 'left',
    height: '38px',
    background: '#202020',
    marginTop: '1px',
};
var titleStyle = {
    padding: '10px 10px 10px 15px',
    width: '149px',
};
export var SelectOption = function (_a) {
    var optionItems = _a.optionItems, selectedOption = _a.selectedOption, _b = _a.onOptionClicked, onOptionClicked = _b === void 0 ? noop : _b;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    return (_jsx(OutsideAlerter, __assign({ onClickOutside: function () { return setOpen(false); } }, { children: _jsxs("div", __assign({ style: outerStyle }, { children: [_jsx("div", __assign({ style: titleStyle, role: "button", onClick: function () { return setOpen(!open); } }, { children: selectedOption })), open && (_jsx("div", { children: optionItems.map(function (i) {
                        return (_jsx("div", __assign({ className: "border rounded pt-2 pb-2 pl-3 pr-3 vselection", style: btnStyle, onClick: function () {
                                onOptionClicked(i.item);
                                setOpen(!open);
                            } }, { children: i.item }), i.item));
                    }) }))] })) })));
};
