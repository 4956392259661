import { __awaiter, __generator } from "tslib";
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { generateCodeVerifier, createAuthorizeUrl, setLocalStore } from './utils';
import { SESSIONSTORAGE_REFRESH_TOKEN, SESSIONSTORAGE_ACCESS_TOKEN, SESSIONSTORAGE_CODE_VERIFIER, SESSIONSTORAGE_PREVIOUS_ROUTE, } from 'common/constants';
var PINGID_CODE = 'code';
var codeVerifierCheck = function (savedCodeVerifier) {
    return savedCodeVerifier ? JSON.parse(savedCodeVerifier) : null;
};
var successPath = function (previousPath, currentPath) {
    return previousPath && previousPath !== 'undefined' ? JSON.parse(previousPath) : currentPath;
};
var errorPath = function (previousPath, currentPath) {
    return previousPath ? JSON.parse(previousPath) : currentPath;
};
var handleCodeNullCase = function (code) {
    if (!code) {
        var codeVerifier = generateCodeVerifier();
        setLocalStore(SESSIONSTORAGE_CODE_VERIFIER, codeVerifier);
        var url = createAuthorizeUrl(codeVerifier);
        setLocalStore(SESSIONSTORAGE_PREVIOUS_ROUTE, location.href);
        if (url) {
            location.replace(url);
        }
    }
};
export var useRequestAccessToken = function () {
    var search = useLocation().search;
    var queryParams = new URLSearchParams(search);
    var code = queryParams.get(PINGID_CODE);
    return useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var savedCodeVerifier, codeVerifier, currentPath, resp, json, previousPath, path, previousPath, path;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!code) return [3 /*break*/, 4];
                    savedCodeVerifier = window.sessionStorage.getItem(SESSIONSTORAGE_CODE_VERIFIER);
                    codeVerifier = codeVerifierCheck(savedCodeVerifier);
                    currentPath = location.href;
                    if (!codeVerifier) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetch(process.env.REACT_APP_SSO_TOKEN_URI, {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            method: 'post',
                            body: new URLSearchParams({
                                grant_type: 'authorization_code',
                                client_id: process.env.REACT_APP_SSO_CLIENT_ID,
                                code_verifier: codeVerifier,
                                code: code,
                                redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
                            }),
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, resp.json()];
                case 2:
                    json = _a.sent();
                    setLocalStore(SESSIONSTORAGE_ACCESS_TOKEN, json.access_token);
                    setLocalStore(SESSIONSTORAGE_REFRESH_TOKEN, json.refresh_token);
                    previousPath = window.sessionStorage.getItem(SESSIONSTORAGE_PREVIOUS_ROUTE);
                    path = successPath(previousPath, currentPath);
                    window.sessionStorage.removeItem(SESSIONSTORAGE_PREVIOUS_ROUTE);
                    location.replace(path);
                    return [3 /*break*/, 4];
                case 3:
                    alert('SSO seems to be down. Try refreshing to authenticate again?');
                    previousPath = window.sessionStorage.getItem(SESSIONSTORAGE_PREVIOUS_ROUTE);
                    path = errorPath(previousPath, currentPath);
                    window.sessionStorage.clear();
                    location.replace(JSON.parse(path));
                    _a.label = 4;
                case 4:
                    handleCodeNullCase(code);
                    return [2 /*return*/];
            }
        });
    }); }, [code]);
};
