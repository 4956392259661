import { ReactElement, useMemo } from 'react';

import { TreeNodeProps as RCTreeNodeProps } from 'rc-tree';
import { DataNode } from 'rc-tree/lib/interface';

import { TREE_CLASS } from '../../utils/constants';
import { CaretSmallDown, CaretSmallRight } from '../Icon/components';
import Loading from '../Loading/Loading';
import * as Styled from './Tree.styles';
import { ITreeProps, TreeSize } from './Tree.types';
import { Sizes } from '../../types/types';
import TreeCheckboxes from './components/TreeCheckboxes/TreeCheckboxes';

const CARET_SIZES: Record<TreeSize, number> = {
  [Sizes.Small]: 16,
  [Sizes.Medium]: 24,
  [Sizes.Large]: 28,
};

const fixDisable = (childrenNode: DataNode[]) =>
  childrenNode.map((childNode): DataNode => {
    if (childNode.children) {
      const cn = fixDisable(childNode.children);

      return { ...childNode, disabled: true, children: cn };
    }

    return { ...childNode, disabled: true };
  });

const findInTree = (tree: DataNode[]): DataNode[] =>
  tree.map((node) => {
    if (node.children) {
      if (node.disabled) {
        return { ...node, disabled: true, children: fixDisable(node.children) };
      }

      return { ...node, children: findInTree(node.children), role: 'treeitem' };
    }

    return { ...node, role: 'treeitem' };
  });

const Tree = ({
  showIcon = false,
  loadingIcon,
  checkable = true,
  disabled = false,
  size = Sizes.Medium,
  treeData,
  ...rest
}: ITreeProps): ReactElement => {
  const dataTree = useMemo(() => (treeData ? findInTree(treeData) : undefined), [treeData]);

  return (
    <Styled.Tree
      size={size}
      checkable={checkable ? <TreeCheckboxes size={size} disabled={disabled} /> : undefined}
      prefixCls={TREE_CLASS}
      switcherIcon={({ isLeaf, loading, expanded }: RCTreeNodeProps) => {
        const caretSize = CARET_SIZES[size];

        if (isLeaf) return <span style={{ marginLeft: `${caretSize}px` }} />;
        if (loading) return loadingIcon ?? <Loading size={Sizes.Small} />;

        const Icon = expanded ? CaretSmallDown : CaretSmallRight;

        return (
          <Styled.ExpandButton>
            <Icon width={caretSize} height={caretSize} />
          </Styled.ExpandButton>
        );
      }}
      showIcon={showIcon}
      treeData={dataTree}
      {...rest}
    />
  );
};

export default Tree;
