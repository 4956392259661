export var initialState = {
    isSidebarOpen: false,
    actionModals: {
        isOpen: false,
        current: 'none',
        assetUnitEventKey: -1,
        unitKey: -1,
        selectedItemKey: -1,
        title: '',
    },
    sort: {
        columnName: '',
        order: 'none',
    },
};
