import { forwardRef } from 'react';

import classNames from 'classnames';

import { NOOP, RADIO_BUTTON_CLASS } from '../../utils/constants';
import * as Styled from './RadioButton.styles';
import { IRadioButtonProps } from './RadioButton.types';
import { Sizes } from '../../types/types';
import { getLabelSize } from '../../utils/sizesUtils';

const RadioButton = forwardRef<HTMLInputElement, IRadioButtonProps>(
  (
    {
      className = '',
      label = '',
      size = Sizes.Medium,
      checked,
      disabled = false,
      invalid = false,
      onChange = NOOP,
      emphasis = true,
      ...rest
    }: IRadioButtonProps,
    ref,
  ) => (
    <Styled.RadioButtonWrapper
      className={classNames(RADIO_BUTTON_CLASS, className)}
      disabled={disabled}
      checked={checked}
      $emphasis={emphasis}
      size={size}
    >
      <Styled.RadioButton
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        type="radio"
        tabIndex={0}
        $emphasis={emphasis}
        $invalid={invalid}
        ref={ref}
        {...rest}
      />
      <Styled.FakeRadio size={size} invalid={invalid} data-testid="radio" />
      {!!label && <Styled.RadioButtonLabel size={getLabelSize(size)}>{label}</Styled.RadioButtonLabel>}
    </Styled.RadioButtonWrapper>
  ),
);

export default RadioButton;
