import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from 'prop-types';
var Header = function (_a) {
    var title = _a.title, onAddEditClick = _a.onAddEditClick, onAddEditClickEnable = _a.onAddEditClickEnable;
    var newLink = title === 'Maintenance Daily' ? 'daily' : 'event-summary';
    return (_jsx("div", __assign({ className: "row ml-0 mr-0 mt-3 mb-3" }, { children: _jsxs("div", __assign({ className: "container-fluid" }, { children: [_jsx("div", __assign({ className: "pull-left page-title" }, { children: _jsx("h3", { children: title }) })), _jsxs("div", __assign({ className: "pull-right" }, { children: [_jsxs("a", __assign({ href: "/maintenance/".concat(newLink), target: "_blank" }, { children: [_jsx("i", { style: { color: '#FFFFFF', marginRight: '8px', fontSize: '15px' }, className: "fa fa-external-link" }), _jsx("span", __assign({ style: { fontFamily: 'ShellMedium', marginRight: '32px', fontSize: 'medium', color: 'white' } }, { children: "Try New Design" }))] })), _jsx("button", __assign({ type: "button", className: "add-edit-btn", title: "add or edit the current record", onClick: function () { return onAddEditClick(); }, disabled: onAddEditClickEnable }, { children: onAddEditClickEnable ? (_jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-spinner fa-spin mr-1" }), " Add / Edit an Event"] })) : (_jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-edit mr-1" }), " Add / Edit an Event"] })) }))] }))] })) })));
};
Header.propTypes = {
    onAddEditClick: PropTypes.func.isRequired,
};
export default Header;
