import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterSearch } from './tools/filterSearch';
import { toast } from 'react-toastify';
import { getSourceFilter } from '../../../../../services/urlService';
import { useApiContext } from './apiContext';
var AssetUnitDependencies = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(''), addEditPopupTitle = _b[0], setAddEditPopupTitle = _b[1];
    var _c = useState(null), indexForEdit = _c[0], setIndexForEdit = _c[1];
    var _d = useState(false), showAddEdit = _d[0], setShowAddEdit = _d[1];
    var _e = useState(false), showDeletePopup = _e[0], setShowDeletePopup = _e[1];
    var _f = useState([]), assetUnits = _f[0], setAssetUnits = _f[1];
    var _g = useState([]), fromUnits = _g[0], setFromUnits = _g[1];
    var _h = useState([]), toUnits = _h[0], setToUnits = _h[1];
    var _j = useState(apiParams.dataProviders), dataProviders = _j[0], setDataProviders = _j[1];
    var _k = useState([]), dependencyType = _k[0], setDependencyType = _k[1];
    var _l = useState([]), dependencyTypeDD = _l[0], setDependencyTypeDD = _l[1];
    var _m = useState([]), depDirectionType = _m[0], setDepDirectionType = _m[1];
    var _o = useState([]), depDirectionTypeDD = _o[0], setDepDirectionTypeDD = _o[1];
    var _p = useState(apiParams.intelligenceSourceTypes), intelligenceSourceType = _p[0], setIntelligenceSourceType = _p[1];
    useEffect(function () {
        setDataProviders(apiParams.dataProviders);
        setIntelligenceSourceType(apiParams.intelligenceSourceTypes);
        setFromUnits(__spreadArray([], assetUnits, true));
        setToUnits(__spreadArray([], assetUnits, true));
    }, [apiParams.dataProviders, apiParams.intelligenceSourceTypes, showAddEdit]);
    useEffect(function () {
        if (apiParams.addEditAssetConfigData.assetDetails.assetKey) {
            var path = 'AssetUnits/AssetUnitFilter?a=' + apiParams.addEditAssetConfigData.assetDetails.assetKey + '&placeholder=false';
            getSourceFilter(path).then(function (res) {
                setAssetUnits(res.data);
                setFromUnits(res.data);
                setToUnits(res.data);
            });
            getSourceFilter('AssetUnitDependencyTypes').then(function (res) {
                setDependencyType(res.data);
                setDependencyTypeDD(res.data);
            });
            getSourceFilter('DependencyDirectionTypes').then(function (res) {
                setDepDirectionType(res.data);
                setDepDirectionTypeDD(res.data);
            });
        }
    }, []);
    var addEditDependency = function (index) {
        if (typeof index == 'undefined') {
            setAddEditPopupTitle('Add Asset Unit Dependency');
            var lastIndex = apiParams.addEditAssetConfigData.assetUnitDependencyDetails.length;
            var newParams = __assign({}, apiParams);
            newParams.addEditAssetConfigData.assetUnitDependencyDetails[lastIndex] = { id: 0 };
            newParams.fieldsMissing = false;
            setIndexForEdit(lastIndex);
            setApiParams(newParams);
        }
        else {
            setAddEditPopupTitle('Edit Asset Unit Dependency');
            setIndexForEdit(index);
        }
        setShowAddEdit(true);
    };
    var isEdited = function () { return indexForEdit < apiParams.originalAssetUnitDependencyDetailsLength; };
    var onUpdate = function () {
        var details = apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit];
        if (!details.fromAssetUnitName ||
            !details.toAssetUnitName ||
            !details.assetUnitDependencyTypeDesc ||
            !details.dependencyDirectionTypeDesc ||
            !details.dataProviderAbbr ||
            !details.intelligenceSourceTypeCd) {
            toast.error('Fields marked in red are required', { autoClose: 5000 });
            setApiParams(__assign(__assign({}, apiParams), { fieldsMissing: true }));
            return;
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].isEdited = isEdited();
        newParams.originalAssetConfigData.assetUnitDependencyDetails[indexForEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]));
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setShowAddEdit(false);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        newParams.fieldsMissing = false;
        if (!apiParams.originalAssetConfigData.assetUnitDependencyDetails[indexForEdit]) {
            newParams.addEditAssetConfigData.assetUnitDependencyDetails.splice(indexForEdit, 1);
        }
        else {
            newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetConfigData.assetUnitDependencyDetails[indexForEdit]));
        }
        setApiParams(newParams);
        setShowAddEdit(false);
    };
    var deleteDependency = function (index) {
        setIndexForEdit(index);
        setShowDeletePopup(true);
    };
    var itemRender = function (li, itemProps) {
        var itemChildren = _jsx("span", __assign({ style: { textAlign: 'left' } }, { children: li.props.children }));
        return React.cloneElement(li, li.props, itemChildren);
    };
    var onDependencyFromChange = function (e) {
        var details = apiParams.addEditAssetConfigData.assetUnitDependencyDetails;
        if (details[indexForEdit].toAssetUnitKey) {
            if (e.target.value.id == details[indexForEdit].toAssetUnitKey) {
                toast.error('A unit cannot have a dependency on itself.', { autoClose: 5000 });
                return;
            }
            for (var i = 0; i < details.length; i++) {
                if (i !== indexForEdit &&
                    details[i].fromAssetUnitKey == e.target.value.id &&
                    details[i].toAssetUnitKey == details[indexForEdit].toAssetUnitKey) {
                    toast.error('Dependency already exists.', { autoClose: 5000 });
                    return;
                }
                else if (i !== indexForEdit &&
                    details[i].fromAssetUnitKey == details[indexForEdit].toAssetUnitKey &&
                    details[i].toAssetUnitKey == e.target.value.id) {
                    toast.error('Dependency already exists.', { autoClose: 5000 });
                    return;
                }
            }
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].fromAssetUnitKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].fromAssetUnitName =
            e.target.value.assetUnitNm;
        setApiParams(newParams);
    };
    var onDependencyToChange = function (e) {
        var details = apiParams.addEditAssetConfigData.assetUnitDependencyDetails;
        if (details[indexForEdit].fromAssetUnitKey) {
            if (e.target.value.id == details[indexForEdit].fromAssetUnitKey) {
                toast.error('A unit cannot have a dependency on itself.', { autoClose: 5000 });
                return;
            }
            for (var i = 0; i < details.length; i++) {
                if (i !== indexForEdit &&
                    details[i].toAssetUnitKey == e.target.value.id &&
                    details[i].fromAssetUnitKey == details[indexForEdit].fromAssetUnitKey) {
                    toast.error('Dependency already exists.', { autoClose: 5000 });
                    return;
                }
                else if (i !== indexForEdit &&
                    details[i].toAssetUnitKey == details[indexForEdit].fromAssetUnitKey &&
                    details[i].fromAssetUnitKey == e.target.value.id) {
                    toast.error('Dependency already exists.', { autoClose: 5000 });
                    return;
                }
            }
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].toAssetUnitKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].toAssetUnitName =
            e.target.value.assetUnitNm;
        setApiParams(newParams);
    };
    var onDataProviderChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].dataProviderKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].dataProviderAbbr =
            e.target.value.dataProviderAbbr;
        setApiParams(newParams);
    };
    var onDependencyTypeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].assetUnitDependencyTypeKey =
            e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].assetUnitDependencyTypeDesc =
            e.target.value.assetUnitDependencyTypeDesc;
        setApiParams(newParams);
    };
    var onDependencyDirectionChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].dependencyDirectionTypeKey =
            e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].dependencyDirectionTypeDesc =
            e.target.value.dependencyDirectionTypeDesc;
        setApiParams(newParams);
    };
    var onIntelligenceSourceTypeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].intelligenceSourceTypeKey =
            e.target.value.id;
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].intelligenceSourceTypeCd =
            e.target.value.intelligenceSourceTypeCd;
        setApiParams(newParams);
    };
    var onIntelligenceSourceURLChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].intelligenceSourceUrl = e.target.value;
        setApiParams(newParams);
    };
    var onIntelligenceSourceTextChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].intelligenceSourceTxt = e.target.value;
        setApiParams(newParams);
    };
    var onDelete = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetUnitDependencyDetails.splice(indexForEdit, 1);
        newParams.originalAssetConfigData.assetUnitDependencyDetails.splice(indexForEdit, 1);
        newParams.originalAssetUnitDependencyDetailsLength = newParams.originalAssetUnitDependencyDetailsLength - 1; //important for setting isEdited field
        setApiParams(newParams);
        setShowDeletePopup(false);
    };
    return (_jsxs("div", { children: [showAddEdit && (_jsx("div", __assign({ id: "unit-cross-reference" }, { children: _jsxs(Dialog, __assign({ width: '50%', title: addEditPopupTitle, onClose: onCancel }, { children: [_jsxs("div", __assign({ className: "row m-0 px-2 pt-5 pb-4" }, { children: [_jsx("div", __assign({ className: "col-4 m-0 pl-0 pr-5" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Dependency From"] })), _jsx(DropDownList, { data: fromUnits, textField: "assetUnitNm", itemRender: itemRender, filterable: true, onFilterChange: function (e) {
                                                    setFromUnits(filterSearch(assetUnits, e.filter));
                                                }, className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].fromAssetUnitName &&
                                                    apiParams.fieldsMissing
                                                    ? 'k-state-invalid'
                                                    : null, value: {
                                                    assetUnitNm: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].fromAssetUnitName ||
                                                            'Select'
                                                        : 'Select',
                                                }, onChange: onDependencyFromChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 pl-0 pr-5" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Dependency To"] })), _jsx(DropDownList, { data: toUnits, textField: "assetUnitNm", itemRender: itemRender, filterable: true, onFilterChange: function (e) {
                                                    setToUnits(filterSearch(assetUnits, e.filter));
                                                }, className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].toAssetUnitName &&
                                                    apiParams.fieldsMissing
                                                    ? 'k-state-invalid'
                                                    : null, value: {
                                                    assetUnitNm: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].toAssetUnitName ||
                                                            'Select'
                                                        : 'Select',
                                                }, onChange: onDependencyToChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 px-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Data Provider"] })), _jsx("div", __assign({ className: "input-group mr-sm-2" }, { children: _jsx(DropDownList, { data: dataProviders, textField: "dataProviderAbbr", 
                                                    // itemRender={itemRender}
                                                    className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit].dataProviderAbbr &&
                                                        apiParams.fieldsMissing
                                                        ? 'k-state-invalid'
                                                        : null, value: {
                                                        dataProviderAbbr: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                            ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                                .dataProviderAbbr || 'Select'
                                                            : 'Select',
                                                    }, filterable: true, onFilterChange: function (e) {
                                                        setDataProviders(filterSearch(apiParams.dataProviders, e.filter));
                                                    }, onChange: onDataProviderChange }) }))] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col-4 m-0 pl-0 pr-5" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Dependency Type"] })), _jsx(DropDownList, { data: dependencyTypeDD, textField: "assetUnitDependencyTypeDesc", className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                    .assetUnitDependencyTypeDesc && apiParams.fieldsMissing
                                                    ? 'k-state-invalid'
                                                    : null, value: {
                                                    assetUnitDependencyTypeDesc: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                            .assetUnitDependencyTypeDesc || 'Select'
                                                        : 'Select',
                                                }, onChange: onDependencyTypeChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 pl-0 pr-5" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Dependency Direction"] })), _jsx(DropDownList, { data: depDirectionTypeDD, textField: "dependencyDirectionTypeDesc", className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                    .dependencyDirectionTypeDesc && apiParams.fieldsMissing
                                                    ? 'k-state-invalid'
                                                    : null, value: {
                                                    dependencyDirectionTypeDesc: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                            .dependencyDirectionTypeDesc || 'Select'
                                                        : 'Select',
                                                }, onChange: onDependencyDirectionChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 px-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Intelligence Source Type"] })), _jsx("div", __assign({ className: "input-group mr-sm-2" }, { children: _jsx(DropDownList, { data: intelligenceSourceType, textField: "intelligenceSourceTypeCd", itemRender: itemRender, filterable: true, onFilterChange: function (e) {
                                                        setIntelligenceSourceType(filterSearch(apiParams.intelligenceSourceTypes, e.filter));
                                                    }, className: !apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                        .intelligenceSourceTypeCd && apiParams.fieldsMissing
                                                        ? 'k-state-invalid'
                                                        : null, value: {
                                                        intelligenceSourceTypeCd: apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                            ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                                .intelligenceSourceTypeCd || 'Select'
                                                            : 'Select',
                                                    }, onChange: onIntelligenceSourceTypeChange }) }))] })) }))] })), _jsx("div", __assign({ className: "row m-0 px-2 py-4" }, { children: _jsx("div", __assign({ className: "col-12 px-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source URL" })), _jsx(Input, { name: "intelligenceSourceURL", style: { width: '100%' }, placeholder: "Enter URL", value: apiParams.addEditAssetConfigData.assetUnitDependencyDetails &&
                                                apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                    .intelligenceSourceUrl
                                                : '', onChange: onIntelligenceSourceURLChange, autoComplete: "off" })] })) })) })), _jsx("div", __assign({ className: "row m-0 px-2 py-4" }, { children: _jsx("div", __assign({ className: "col-12 px-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source Text" })), _jsx("textarea", { name: "intelligentSourceText", className: "p-2", style: {
                                                height: '100px',
                                                backgroundColor: '#1d1d1d',
                                                borderColor: '#595959',
                                                color: '#d9d9d9',
                                                width: '100%',
                                            }, placeholder: "Enter text", width: '100%', value: apiParams.addEditAssetConfigData.assetUnitDependencyDetails &&
                                                apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                ? apiParams.addEditAssetConfigData.assetUnitDependencyDetails[indexForEdit]
                                                    .intelligenceSourceTxt
                                                : '', onChange: onIntelligenceSourceTextChange })] })) })) })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border btn-cancel", onClick: onCancel }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary", onClick: onUpdate }, { children: "Update" }))] }))] })) }))), _jsxs("button", __assign({ className: "p-3", onClick: function () { return addEditDependency(); } }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Asset Unit Dependency" }))] })), apiParams.addEditAssetConfigData && apiParams.addEditAssetConfigData.assetUnitDependencyDetails.length > 0 ? (_jsx("div", __assign({ id: "dailyeventListing" }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "py-2" }, { children: "\u00A0" })), _jsx("th", __assign({ className: "py-2" }, { children: "Dependency From" })), _jsx("th", __assign({ className: "py-2" }, { children: "Dependency To" })), _jsx("th", __assign({ className: "py-2" }, { children: "Data Provider" })), _jsx("th", __assign({ className: "py-2" }, { children: "Dependency Type" })), _jsx("th", __assign({ className: "py-2" }, { children: "Dependency Direction" })), _jsx("th", __assign({ className: "py-2" }, { children: "Intelligence Source Type" })), _jsx("th", __assign({ className: "py-2" }, { children: "Intelligence Source URL" })), _jsx("th", __assign({ className: "py-2" }, { children: "Intelligence Source Text" }))] }) }), _jsx("tbody", { children: apiParams.addEditAssetConfigData.assetUnitDependencyDetails.map(function (details, index) {
                                return (_jsxs("tr", { children: [_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () { return addEditDependency(index); }, className: "mr-2" }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ onClick: function () { return deleteDependency(index); }, className: "ml-2" }, { children: _jsx("i", { className: "fa fa-trash" }) }))] }), _jsx("td", __assign({ className: "py-2" }, { children: details.fromAssetUnitName })), _jsx("td", __assign({ className: "py-2" }, { children: details.toAssetUnitName })), _jsx("td", __assign({ className: "py-2" }, { children: details.dataProviderAbbr })), _jsx("td", __assign({ className: "py-2" }, { children: details.assetUnitDependencyTypeDesc })), _jsx("td", __assign({ className: "py-2" }, { children: details.dependencyDirectionTypeDesc })), _jsx("td", __assign({ className: "py-2" }, { children: details.intelligenceSourceTypeCd })), _jsx("td", __assign({ className: "py-2" }, { children: details.intelligenceSourceUrl })), _jsx("td", __assign({ className: "py-2" }, { children: details.intelligenceSourceTxt }))] }, index));
                            }) })] }) }))) : (_jsx("div", __assign({ style: {
                    border: '1px solid #595959',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    color: '#595959',
                    fontFamily: 'ShellLight',
                    left: '36%',
                    top: '46%',
                } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No records available to display." })) }))), showDeletePopup && (_jsx("div", { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setShowDeletePopup(false); } }, { children: [_jsx("div", __assign({ className: "my-4 mx-2" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setShowDeletePopup(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onDelete }, { children: "Yes" }))] })] })) }))] }));
};
export default AssetUnitDependencies;
