import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../commons/crossReferenceGridTemplate';
var notificationClass = /** @class */ (function (_super) {
    __extends(notificationClass, _super);
    function notificationClass(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            notificationClassCd: {
                required: true,
                error: 'Enter code.',
            },
            notificationClassNm: {
                required: true,
                error: 'Enter name',
            },
            notificationClassDesc: {
                required: true,
                error: 'Enter description',
            },
            datasetAbbr: {
                required: true,
                error: 'Enter dataset.',
            },
            baselineTypeCd: {
                required: true,
                error: 'Enter baseline type',
            },
            notificationPriorityTypeCd: {
                required: true,
                error: 'Enter priority type',
            },
            // notificationTypeCd: {
            //   required: true,
            //   error: "Enter notification type."
            // },
            // notificationActionTypeCd: {
            //   required: true,
            //   error: "Enter notification action type."
            // },
            // accessibilityTypeCd: {
            //   required: true,
            //   error: "Enter accessibility type."
            // }
        };
        var foreginKeyParams = [
            {
                path: 'dataSet',
                key: 'datasetKey',
                cdName: 'datasetAbbr',
            },
            {
                path: 'baselineType',
                key: 'baselineTypeKey',
                cdName: 'baselineTypeCd',
            },
            {
                path: 'notificationTypes',
                key: 'notificationTypeKey',
                cdName: 'notificationTypeCd',
            },
            {
                path: 'notificationActionTypes',
                key: 'notificationActionTypeKey',
                cdName: 'notificationActionTypeCd',
            },
            {
                path: 'accessibilityTypes',
                key: 'accessibilityTypeKey',
                cdName: 'accessibilityTypeCd',
            },
            {
                path: 'notificationPriorityTypes',
                key: 'notificationPriorityTypeKey',
                cdName: 'notificationPriorityTypeCd',
            },
        ];
        var columns = [
            {
                field: 'notificationClassCd',
                title: 'Code',
            },
            {
                field: 'notificationClassNm',
                title: 'Name',
            },
            {
                field: 'notificationClassDesc',
                title: 'Description',
            },
            {
                field: 'datasetAbbr',
                title: 'Dataset',
                type: 'foreignKeyConstraint',
                referenceCd: 'datasetAbbr',
                urlEndPoint: 'dataSet',
                referenceTitle: 'Dataset',
            },
            {
                field: 'baselineTypeCd',
                title: 'Baseline Type',
                type: 'foreignKeyConstraint',
                referenceCd: 'baselineTypeCd',
                urlEndPoint: 'baselineType',
                referenceTitle: 'Baseline Type',
            },
            {
                field: 'notificationTypeCd',
                title: 'Notification Type',
                type: 'foreignKeyConstraint',
                urlEndPoint: 'notificationTypes',
                referenceCd: 'notificationTypeCd',
                referenceTitle: 'Notification Type',
            },
            {
                field: 'notificationActionTypeCd',
                title: 'Action Type',
                type: 'foreignKeyConstraint',
                urlEndPoint: 'notificationActionTypes',
                referenceCd: 'notificationActionTypeCd',
                referenceTitle: 'Action Type',
            },
            {
                field: 'notificationPriorityTypeCd',
                title: 'Priority Type',
                type: 'foreignKeyConstraint',
                urlEndPoint: 'notificationPriorityTypes',
                referenceCd: 'notificationPriorityTypeCd',
                referenceTitle: 'Priority Type',
            },
            {
                field: 'accessibilityTypeCd',
                title: 'Acessibility Type',
                type: 'foreignKeyConstraint',
                urlEndPoint: 'accessibilityTypes',
                referenceCd: 'accessibilityTypeCd',
                referenceTitle: 'Acessibility Type',
            },
            {
                field: 'recordEntryDttm',
                title: 'Entry Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Quality Code',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Action Code',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Created Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Created By',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Changed Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Changed By',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    notificationClass.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "notificationClass", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, 
            // uniquecode="notificationClassCd"
            foreginKeyParams: this.state.foreginKeyParams }));
    };
    return notificationClass;
}(Component));
export default notificationClass;
