import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterSearch } from './tools/filterSearch';
import { toast } from 'react-toastify';
import { useApiContext } from './apiContext';
var AssetCrossReferencesTab = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(apiParams.dataProviders), dataProvidersDropdownData = _b[0], setDataProvidersDropdownData = _b[1];
    var _c = useState(null), indexForEdit = _c[0], setIndexForEdit = _c[1];
    var _d = useState(''), addEditPopupTitle = _d[0], setAddEditPopupTitle = _d[1];
    var _e = useState(false), visibleAddEdit = _e[0], setVisibleAddEdit = _e[1];
    var _f = useState(false), deletePopup = _f[0], setDeletePopup = _f[1];
    var _g = useState([]), sort = _g[0], setSort = _g[1];
    useEffect(function () {
        setDataProvidersDropdownData(apiParams.dataProviders);
    }, [apiParams.dataProviders, visibleAddEdit]);
    var addEditCrossRef = function (dataItem) {
        var index;
        if (!dataItem) {
            //new cross reference;
            setAddEditPopupTitle('Add Cross-Reference');
            index = apiParams.addEditAssetConfigData.assetCrossRefDetails.length;
            var newParams = __assign({}, apiParams);
            newParams.addEditAssetConfigData.assetCrossRefDetails[index] = {};
            newParams.fieldsMissing = false;
            setIndexForEdit(index);
            setApiParams(newParams);
        }
        else {
            //edit cross reference
            setAddEditPopupTitle('Edit Cross-Reference');
            index = apiParams.addEditAssetConfigData.assetCrossRefDetails.findIndex(function (item) { return item.assetCrossRefKey === dataItem.assetCrossRefKey; });
            setIndexForEdit(index);
        }
        setVisibleAddEdit(true);
    };
    var deleteCrossRef = function (dataItem) {
        var index = apiParams.addEditAssetConfigData.assetCrossRefDetails.findIndex(function (item) { return item.assetCrossRefKey === dataItem.assetCrossRefKey; });
        setIndexForEdit(index);
        setDeletePopup(true);
    };
    var EditButtonsCell = function (dataItemProps) {
        return (_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () { return addEditCrossRef(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ onClick: function () { return deleteCrossRef(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] }));
    };
    //////////////////Functions for handling edits/////////////////////////////////////////
    var isEdited = function () { return indexForEdit < apiParams.originalCrossRefLength; };
    var onDataProviderChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].dataProviderKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].dataProviderAbbr =
            e.target.value.dataProviderAbbr;
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].dataProviderNm = e.target.value.dataProviderNm;
        setApiParams(newParams);
    };
    var onProviderAssetIdChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerAssetId = e.target.value;
        setApiParams(newParams);
    };
    var onAssetNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerAssetNm = e.target.value;
        setApiParams(newParams);
    };
    var onCityNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerCityNm = e.target.value;
        setApiParams(newParams);
    };
    var onOwnerNameChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerOwnerNm = e.target.value;
        setApiParams(newParams);
    };
    var onDelete = function () {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails.splice(indexForEdit, 1);
        newParams.originalAssetConfigData.assetCrossRefDetails.splice(indexForEdit, 1);
        newParams.originalCrossRefLength = newParams.originalCrossRefLength - 1; //important for setting isEdited field
        setApiParams(newParams);
        setDeletePopup(false);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        newParams.fieldsMissing = false;
        if (!apiParams.originalAssetConfigData.assetCrossRefDetails[indexForEdit]) {
            newParams.addEditAssetConfigData.assetCrossRefDetails.splice(indexForEdit, 1);
        }
        else {
            newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetConfigData.assetCrossRefDetails[indexForEdit]));
        }
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    var onUpdate = function () {
        var details = apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit];
        if (!details.dataProviderAbbr || !details.providerAssetNm) {
            toast.error('Fields marked in red are required', { autoClose: 5000 });
            setApiParams(__assign(__assign({}, apiParams), { fieldsMissing: true }));
            return;
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].isEdited = isEdited();
        newParams.originalAssetConfigData.assetCrossRefDetails[indexForEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]));
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    return (_jsxs("div", { children: [visibleAddEdit && (_jsx("div", __assign({ id: "unit-cross-reference" }, { children: _jsx(Dialog, __assign({ width: '40%', title: addEditPopupTitle, onClose: onCancel }, { children: _jsxs("div", __assign({ className: "defalut-height" }, { children: [_jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Data Provider"] })), _jsx(DropDownList, { data: dataProvidersDropdownData, textField: "dataProviderAbbr", name: "dataProviderAbbr", filterable: true, onFilterChange: function (e) {
                                                        setDataProvidersDropdownData(filterSearch(apiParams.dataProviders, e.filter));
                                                    }, className: !apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].dataProviderAbbr &&
                                                        apiParams.fieldsMissing
                                                        ? 'k-state-invalid'
                                                        : null, value: {
                                                        dataProviderAbbr: apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]
                                                            ? apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].dataProviderAbbr ||
                                                                'Select'
                                                            : 'Select',
                                                    }, onChange: onDataProviderChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Asset Id" })), _jsx("input", { name: "providerAssetId", style: { width: '100%' }, placeholder: "Enter a value", autoComplete: "off", className: "k-textbox", value: apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerAssetId || ''
                                                        : '', onChange: onProviderAssetIdChange })] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "col p-0 position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Asset Name"] })), _jsx("input", { name: "assetName", autoComplete: "off", style: { width: '100%' }, className: !apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerAssetNm &&
                                                        apiParams.fieldsMissing
                                                        ? 'k-textbox k-state-invalid'
                                                        : 'k-textbox', value: apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerAssetNm || ''
                                                        : 'Enter Value', placeholder: "Enter a name", onChange: onAssetNameChange })] })) })), _jsx("div", __assign({ className: "col m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "col p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "City Name" })), _jsx("input", { name: "firstName", autoComplete: "off", style: { width: '100%' }, className: "k-textbox", value: apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerCityNm || ''
                                                        : 'Enter value', placeholder: "Enter a name", onChange: onCityNameChange })] })) }))] })), _jsx("div", __assign({ className: "row m-0 px-2 py-4" }, { children: _jsx("div", __assign({ className: "col-6 m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "col p-0 position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Owner Name" })), _jsx("input", { name: "firstName", style: { width: '100%' }, className: "k-textbox", value: apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit]
                                                    ? apiParams.addEditAssetConfigData.assetCrossRefDetails[indexForEdit].providerOwnerNm || ''
                                                    : 'Enter a name', autoComplete: "off", placeholder: "Enter a name", onChange: onOwnerNameChange })] })) })) })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border btn-cancel", onClick: onCancel }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary", onClick: onUpdate }, { children: "Update" }))] }))] })) })) }))), _jsxs("button", __assign({ className: "p-3 new-cross-reference", onClick: function () { return addEditCrossRef(); } }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Cross Reference" }))] })), apiParams.addEditAssetConfigData && apiParams.addEditAssetConfigData.assetCrossRefDetails.length > 0 ? (_jsx("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: _jsxs(Grid, __assign({ data: orderBy(apiParams.addEditAssetConfigData.assetCrossRefDetails, sort), sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    }, style: { height: '575px' } }, { children: [_jsx(Column, { width: "90px", cell: EditButtonsCell, locked: true }), _jsx(Column, { field: "dataProviderAbbr", title: "Data Provider" }), _jsx(Column, { field: "providerAssetId", title: "Asset ID" }), _jsx(Column, { field: "providerAssetNm", title: "Asset Name" }), _jsx(Column, { field: "providerCityNm", title: "City Name" }), _jsx(Column, { field: "providerOwnerNm", title: "Owner Name", width: 250 })] })) }))) : (_jsx("div", __assign({ style: {
                    border: '1px solid #595959',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    color: '#595959',
                    fontFamily: 'ShellLight',
                    left: '36%',
                    top: '46%',
                } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No records available to display." })) }))), deletePopup && (_jsx("div", { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setDeletePopup(false); } }, { children: [_jsx("div", __assign({ className: "p-4" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setDeletePopup(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onDelete }, { children: "Yes" }))] })] })) }))] }));
};
export default AssetCrossReferencesTab;
