import styled, { css } from 'styled-components';

import { textConsts } from '../../Text.consts';
import { HeadingLevel, IHeadingProps } from './Heading.types';
import { TextTypes } from '../../../../types/types';
import { allHeadings, headingFontBold, headingFontNormal } from '../../utils/headingUtils';

const Heading = styled.h1<IHeadingProps & { as: string }>`
  ${({ level, gutter, bold, prominence, theme, type, as }) => {
    const l: HeadingLevel = level || ((allHeadings.indexOf(type || as || TextTypes.H1) + 1) as HeadingLevel) || 1;

    return css`
      ${bold ? headingFontBold(l) : headingFontNormal(l)}
      margin: unset;

      ${prominence &&
      css`
        color: ${theme.text.onSurface[prominence]};
      `}

      ${gutter &&
      l === 1 &&
      css`
        margin-bottom: ${textConsts.gutter.h1};
      `}

      ${gutter &&
      (l === 2 || l === 3 || l === 4) &&
      css`
        margin-bottom: ${textConsts.gutter.h2};
      `}

      ${gutter &&
      (l === 5 || l === 6) &&
      css`
        margin-bottom: ${textConsts.gutter.h5};
      `}

      strong {
        ${headingFontBold(l)}
      }
    `;
  }}
`;

export { Heading };
