import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import DataServiceTerminalType from './dataServiceTerminalType';
import DirectChargebackType from './directChargebackType';
import DataServiceReportType from './dataServiceReportType';
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var dataProviderServiceReferenceNavLink = /** @class */ (function (_super) {
    __extends(dataProviderServiceReferenceNavLink, _super);
    function dataProviderServiceReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    dataProviderServiceReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Data Provider Service ", _jsx("span", { children: "(Reference Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/dataProviderServiceReference/dataServiceTerminalType", className: "nav-link", activeClassName: "active" }, { children: "Data Service Terminal Type" })), _jsx(NavLink, __assign({ to: "/dashboard/dataProviderServiceReference/directChargebackType", className: "nav-link", activeClassName: "active" }, { children: "Direct Chargeback Type" })), _jsx(NavLink, __assign({ to: "/dashboard/dataProviderServiceReference/dataServiceReportType", className: "nav-link", activeClassName: "active" }, { children: "Data Service Report Type" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/dataproviderservicereference", to: "/dashboard/dataProviderServiceReference/dataServiceTerminalType", exact: true }), _jsx(Route, { path: "/dashboard/dataProviderServiceReference/dataServiceTerminalType", component: DataServiceTerminalType, exact: true }), _jsx(Route, { path: "/dashboard/dataProviderServiceReference/directChargebackType", component: DirectChargebackType, exact: true }), _jsx(Route, { path: "/dashboard/dataProviderServiceReference/dataServiceReportType", component: DataServiceReportType, exact: true })] }) }))] }))] })) }));
    };
    return dataProviderServiceReferenceNavLink;
}(Component));
export default dataProviderServiceReferenceNavLink;
