import RCMenu from 'rc-menu';
import styled, { css } from 'styled-components';

import { MENU_CLASS } from '../../utils/constants';
import { StyledMenuProps } from './Menu.types';
import { groupMenuItemStyle } from './styles/groupMenuItem.styles';
import { menuItemStyle } from './styles/menuItem.styles';
import { menuLabelStyle } from './styles/menuLabel.styles';
import { motionStyle } from './styles/motion.styles';
import { subMenuStyle } from './styles/subMenu.styles';
import { Sizes } from '../../types/types';

export const Menu = styled(RCMenu)<StyledMenuProps>`
  ${({ theme, size, mode }) => css`
    ${(size === Sizes.Small || size === Sizes.Medium) && theme.label.medium.normal};
    ${size === Sizes.Large && theme.label.large.normal};
    position: relative;

    &:focus-visible {
      outline: none;

      :before {
        content: '';
        pointer-events: none;
        display: block;
        position: absolute;
        inset: 3px;
        border-radius: 2px;
        outline: 3px solid ${theme.focus.medium};
        z-index: 1;
      }
    }

    &.${MENU_CLASS} {
      list-style: none;
      margin: 0;
      padding: 0;
      transition: width 0.3s ease-out;
    }

    .${MENU_CLASS} {
      &-hidden,
      &-submenu-hidden {
        display: none;
      }

      &,
      &-item-group-list {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }

    /* Horizontal */
    ${mode === 'horizontal' &&
    css`
      display: flex;
      flex-direction: row;
      align-items: stretch;
      overflow: hidden;
    `}

    ${subMenuStyle}
    ${menuLabelStyle}
    ${menuItemStyle}
    ${groupMenuItemStyle}
    ${motionStyle}
  `}
`;
