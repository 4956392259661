import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useApiContext } from '../../../../../globalFilterContext/apiContext';
import Header from './tools/Header';
import Topnav from './tools/topnav';
import Spinner from '../../../../../commons/spinner';
import FilterContainer from './tools/FilterContainer';
import { getSourceFilter } from '../../../../../services/urlService';
import { Spin } from 'antd';
import './Refineryintake.css';
import DataLayout from './tools/dataLayout';
import { combineRefineryIntake } from '../../../../../app/common/utils/combineRefineryIntake';
var moment = require('moment');
function RefineryIntake(props) {
    //context
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    var _c = useState(false), showFilterDropdown = _c[0], setShowFilterDropdown = _c[1];
    var _d = useState(true), filterShow = _d[0], setFilterShow = _d[1];
    var _e = useState(0), allFilters = _e[0], setAllFilters = _e[1];
    var _f = useState(true), filterShowEvent = _f[0], setFilterShowEvent = _f[1];
    var _g = useState('#'), visualizationEvent = _g[0], setVisualizationEvent = _g[1];
    var _h = useState(false), refreshRandomKey = _h[0], setRefreshRandomKey = _h[1];
    var _j = useState([]), gridData = _j[0], setGridData = _j[1];
    function dataLayoutRefresh(e) {
        setRefreshRandomKey(e);
    }
    function handleAddEdit() { }
    useEffect(function () {
        if (Object.keys(apiParams.filter).length === 2) {
            setShowFilterDropdown(true);
        }
        else {
            setShowFilterDropdown(false);
        }
    }, [Object.keys(apiParams.filter).length]);
    useEffect(function () {
        if (refreshRandomKey) {
            apply();
        }
    }, [refreshRandomKey]);
    function resetData() {
        setGridData([]);
    }
    function apply() {
        var region = typeof apiParams.refineryFilterSelection.regions !== 'undefined'
            ? apiParams.refineryFilterSelection.regions.length > 0 &&
                '&re=' + apiParams.refineryFilterSelection.regions.join('&re=')
            : '&re=1';
        var country = typeof apiParams.refineryFilterSelection.countries !== 'undefined'
            ? apiParams.refineryFilterSelection.countries.length > 0 &&
                '&co=' + apiParams.refineryFilterSelection.countries.join('&co=')
            : '&co=1';
        var endDateFormat = moment(apiParams.refineryFilterSelection.endDate, 'YYYY-MM-DD', true).isValid()
            ? apiParams.refineryFilterSelection.endDate
            : moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD');
        var startDateFormat = moment(apiParams.refineryFilterSelection.startDate, 'YYYY-MM-DD', true).isValid()
            ? apiParams.refineryFilterSelection.startDate
            : moment(new Date('JAN').setFullYear(new Date().getFullYear() - 1)).format('YYYY-MM-DD');
        var matTypeFilter = apiParams.refineryFilterSelection.matType
            ? '&matType=' + apiParams.refineryFilterSelection.matType
            : '';
        var regionFormat = !region ? '' : region;
        var countryFormat = !country ? '' : country;
        var objToArray = function (obj) {
            var arr = [];
            for (var el in obj) {
                arr.push(obj[el]);
            }
            return arr;
        };
        var combineArrays = function (arr1, arr2) {
            var obj = {};
            var combine = __spreadArray(__spreadArray([], arr1, true), arr2, true);
            combine.forEach(function (el) {
                if (el.countryId in obj) {
                    obj[el.countryId].dataProviderLsts.push(el.dataProviderLsts[0]);
                }
                else {
                    obj[el.countryId] = el;
                }
            });
            return objToArray(obj);
        };
        var plainPath = 'RefineryIntakeByCountry';
        var crudePath = 'RefineryIntakeByCountryForCrudeDesk';
        var pathConstruct = function (path) {
            return "RefineryIntake/".concat(path, "?").concat(countryFormat).concat(regionFormat, "&stDate=").concat(startDateFormat, "&endDate=").concat(endDateFormat).concat(matTypeFilter);
        };
        setLoader(true);
        Promise.all([
            getSourceFilter(pathConstruct(plainPath).replace('?&', '?')),
            getSourceFilter(pathConstruct(crudePath).replace('?&', '?')),
        ])
            .then(function (values) {
            var _a, _b;
            setGridData(combineRefineryIntake((_a = values[0]) === null || _a === void 0 ? void 0 : _a.data, (_b = values[1]) === null || _b === void 0 ? void 0 : _b.data));
            setLoader(false);
        })
            .catch(function () {
            setLoader(false);
        });
    }
    return (_jsxs(_Fragment, { children: [loader && _jsx(Spinner, {}), _jsx(Header, { title: "Refinery Intake", onAddEditClick: handleAddEdit, onAddEditClickEnable: Object.keys(allFilters).length === 5 ? false : true }), _jsxs("div", __assign({ id: "refinery-intake", className: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100" }, { children: [_jsx("div", { children: _jsx(Topnav, { filterShowEvent: setFilterShowEvent, fsisOn: filterShowEvent, visualizationEvent: setVisualizationEvent }) }), _jsx("div", __assign({ className: "filter", style: {
                            display: filterShow ? 'block' : 'none',
                            height: '100%',
                            position: 'relative',
                        } }, { children: showFilterDropdown ? (_jsxs(_Fragment, { children: [filterShowEvent && _jsx(FilterContainer, { filters: apiParams.filter, resetData: resetData, apply: apply }), gridData && (_jsx(DataLayout, { dataLayoutRefresh: dataLayoutRefresh, setLoader: setLoader, title: "country", data: gridData, matType: apiParams.refineryFilterSelection.matType, sDate: typeof apiParams.refineryFilterSelection !== 'undefined' &&
                                        typeof apiParams.refineryFilterSelection.startDate !== 'undefined'
                                        ? moment(apiParams.refineryFilterSelection.startDate).format('YYYY-MM')
                                        : null, eDate: typeof apiParams.refineryFilterSelection !== 'undefined' &&
                                        typeof apiParams.refineryFilterSelection.endDate !== 'undefined'
                                        ? moment(apiParams.refineryFilterSelection.endDate).format('YYYY-MM')
                                        : null, nmForProvider: "dataProviderAbbr" }))] })) : (_jsx("div", __assign({ className: "container-fluid background-filter-fill  pl-0 pr-0", style: { textAlign: 'center', height: '100%' } }, { children: _jsx("div", __assign({ className: "m-0  page-loading-spinner" }, { children: _jsx(Spin, { size: "large" }) })) }))) }))] }))] }));
}
export default RefineryIntake;
