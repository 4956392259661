import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { getSourceFilter } from '../../../../../../services/urlService';
import Spin from '../../../../../../commons/spin';
import SearchBox from './searchBox';
import ErrorBoundary from '../../../../../../services/errorBoundary';
import { ApiContext } from '../apiContext';
var Filter = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), filterData = _b[0], setFilterData = _b[1];
    var _c = useState(''), filterByValue = _c[0], setFilterByValue = _c[1];
    var _d = useState(false), noData = _d[0], setNoData = _d[1];
    var _e = useContext(ApiContext), apiParams = _e[0], setApiParams = _e[1];
    useEffect(function () {
        setNoData(false);
        setFilterData([]);
        setFilterByValue('');
        setLoading(true);
        getSourceFilter(props.path).then(function (response) {
            setLoading(false);
            if (response && response.data) {
                setFilterData(response.data);
                if (!apiParams.assetsFilterData.length && props.path.includes('Assets/AssetFilter')) {
                    setApiParams(function (prevParams) {
                        return __assign(__assign({}, prevParams), { assetsFilterData: response.data });
                    });
                }
                if (!apiParams.unitSubTypesFilterData.length && props.path.includes('AssetUnitSubTypes')) {
                    setApiParams(function (prevParams) {
                        return __assign(__assign({}, prevParams), { unitSubTypesFilterData: response.data });
                    });
                }
            }
            else {
                setNoData(true);
            }
        });
    }, [props.path]);
    var handleSearch = function (value) {
        setFilterByValue(value.toLowerCase());
    };
    var onChange = function (event, data) {
        var newApiParams = __assign({}, apiParams);
        if (event.target.checked) {
            if (props.path.includes('Countries')) {
                document.getElementById('Countries-search-box').focus();
                newApiParams.countries.push(data);
                newApiParams.owners = [];
                newApiParams.assets = [];
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('Companies')) {
                document.getElementById('Companies-search-box').focus();
                newApiParams.owners.push(data);
                newApiParams.assets = [];
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('Assets/AssetFilter')) {
                document.getElementById('Assets/AssetFilter-search-box').focus();
                newApiParams.assets.push(data);
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('AssetUnitTypes')) {
                document.getElementById('AssetUnitTypes-search-box').focus();
                newApiParams.unitTypes.push(data);
                newApiParams.unitSubTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('AssetUnitSubTypes')) {
                document.getElementById('AssetUnitSubTypes-search-box').focus();
                newApiParams.unitSubTypes.push(data);
                setApiParams(newApiParams);
            }
            else {
                return;
            }
        }
        else {
            var index = void 0;
            if (props.path.includes('Countries')) {
                document.getElementById('Countries-search-box').focus();
                index = newApiParams.countries.findIndex(function (country) { return country.id === data.id; });
                newApiParams.countries.splice(index, 1);
                newApiParams.owners = [];
                newApiParams.assets = [];
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('Companies')) {
                index = newApiParams.owners.findIndex(function (owner) { return owner.id === data.id; });
                document.getElementById('Companies-search-box').focus();
                newApiParams.owners.splice(index, 1);
                newApiParams.assets = [];
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('Assets/AssetFilter')) {
                index = newApiParams.assets.findIndex(function (asset) { return asset.id === data.id; });
                document.getElementById('Assets/AssetFilter-search-box').focus();
                newApiParams.assets.splice(index, 1);
                newApiParams.unitSubTypes = [];
                newApiParams.unitTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('AssetUnitTypes')) {
                index = newApiParams.unitTypes.findIndex(function (unitType) { return unitType.id === data.id; });
                document.getElementById('AssetUnitTypes-search-box').focus();
                newApiParams.unitTypes.splice(index, 1);
                newApiParams.unitSubTypes = [];
                setApiParams(newApiParams);
            }
            else if (props.path.includes('AssetUnitSubTypes')) {
                index = newApiParams.unitSubTypes.findIndex(function (unitSubType) { return unitSubType.id === data.id; });
                document.getElementById('AssetUnitSubTypes-search-box').focus();
                newApiParams.unitSubTypes.splice(index, 1);
                setApiParams(newApiParams);
            }
            else {
                return;
            }
        }
    };
    var isChecked = function (data) {
        if (props.path.includes('Countries')) {
            return apiParams.countries.some(function (c) { return c.id === data.id; });
        }
        else if (props.path.includes('Companies')) {
            return apiParams.owners.some(function (o) { return o.id === data.id; });
        }
        else if (props.path.includes('Assets/AssetFilter')) {
            return apiParams.assets.some(function (a) { return a.id === data.id; });
        }
        else if (props.path.includes('AssetUnitTypes')) {
            return apiParams.unitTypes.some(function (ut) { return ut.id === data.id; });
        }
        else if (props.path.includes('AssetUnitSubTypes')) {
            return apiParams.unitSubTypes.some(function (ust) { return ust.id === data.id; });
        }
        else {
            return;
        }
    };
    return (_jsx("div", { children: _jsx("div", __assign({ className: "col-12 p-0" }, { children: _jsxs(ErrorBoundary, { children: [_jsx(SearchBox, { handleSearch: handleSearch, id: props.path.split('?')[0], tabIndex: props.tabIndex }), _jsxs("div", __assign({ style: {
                            height: '300px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        } }, { children: [noData ? (_jsx("p", { children: "No Data..." })) : (filterData.map(function (data, index) {
                                if (data[props.displayField].toLowerCase().includes(filterByValue)) {
                                    return (_jsxs("div", __assign({ className: "mb-3 ml-1" }, { children: [_jsx("input", { type: "checkbox", className: "k-checkbox", id: data.id + '-' + props.path, value: data[props.displayField], onChange: function (event) { return onChange(event, data); }, checked: isChecked(data) }), _jsx("label", __assign({ htmlFor: data.id + '-' + props.path, className: "col-12 k-checkbox-label pl-4" }, { children: data[props.displayField] }))] }), index));
                                }
                            })), loading && _jsx(Spin, {})] }))] }) })) }, props.path));
};
export default Filter;
