import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState, useEffect } from 'react';
import { Text, Toggle, Flexbox, Label } from '@sede-x/shell-ds-react-framework';
import { useTheme } from 'styled-components';
import { StyledTree, StyledModal, StyledPlaceholder, StyledTabs } from './NotificationsModal.styles';
import { transformIntoTreeData, getKeysOnCheck, getKeysOnUncheck } from './utils';
import { mapSelectData } from 'common/utils/newScreenUtils';
import { Select } from 'components/select';
import { useGetAssetUnitTypesQuery } from 'common/ducks/filtersApis';
import { useGetAcmHierarchyQuery, useGetEmailSubscriptionQuery, useSaveEmailSubscriptionMutation, } from 'common/ducks/emailSubscriptionApis';
import { Loader } from 'components/loader';
var TITLE = 'Subscriptions';
var DESCRIPTION = 'Subscribe to regions, countries, or assets to get email updates.';
export var NotificationsModal = function (_a) {
    var _b, _c;
    var open = _a.open, onClose = _a.onClose;
    var _d = useGetAssetUnitTypesQuery(undefined, {
        skip: !open,
    }), _e = _d.data, unitTypes = _e === void 0 ? [] : _e, isFetchingUnitTypes = _d.isFetching;
    var _f = useGetAcmHierarchyQuery(undefined, {
        skip: !open,
    }), _g = _f.data, acmHierarchies = _g === void 0 ? [] : _g, isFetchingHierarchies = _f.isFetching;
    var _h = useGetEmailSubscriptionQuery(undefined, { skip: !open }), _j = _h.data, emailSubscription = _j === void 0 ? {
        subscribeHierarchyKeys: [],
        enableMaintenanceNotification: false,
        enableCapacityNotification: false,
        unitTypesForMaintenance: [],
        unitTypesForCapacity: [],
    } : _j, refetch = _h.refetch, isSuccess = _h.isSuccess;
    var unitTypesOptions = useMemo(function () { return unitTypes.map(mapSelectData('assetUnitTypeCd')); }, [unitTypes]);
    var treeData = useMemo(function () { return transformIntoTreeData(acmHierarchies); }, [acmHierarchies]);
    var border = useTheme().border;
    var _k = useState([]), checkedKeys = _k[0], setCheckedKeys = _k[1];
    var _l = useState({ maintenance: false, capacity: false }), enableToggle = _l[0], setEnableToggle = _l[1];
    var _m = useState({
        maintenance: [],
        capacity: [],
    }), subscribedUnitTypes = _m[0], setSubscribedUnitTypes = _m[1];
    var _o = useSaveEmailSubscriptionMutation(), triggerSaveEmailSubscription = _o[0], isLoading = _o[1].isLoading;
    useEffect(function () {
        var _a, _b;
        if (isSuccess) {
            setCheckedKeys(__spreadArray([], emailSubscription.subscribeHierarchyKeys, true));
            setEnableToggle({
                maintenance: emailSubscription.enableMaintenanceNotification,
                capacity: emailSubscription.enableCapacityNotification,
            });
            setSubscribedUnitTypes({
                maintenance: (_a = emailSubscription.unitTypesForMaintenance) !== null && _a !== void 0 ? _a : [],
                capacity: (_b = emailSubscription.unitTypesForCapacity) !== null && _b !== void 0 ? _b : [],
            });
        }
    }, [emailSubscription, open, isSuccess]);
    var handleCheck = function (_, info) {
        if (info.checked) {
            setCheckedKeys(getKeysOnCheck(info, checkedKeys, treeData));
        }
        else {
            setCheckedKeys(getKeysOnUncheck(info, checkedKeys, treeData));
        }
    };
    var handleToggle = function (type) {
        var currentToggle = __assign({}, enableToggle);
        currentToggle[type] = !currentToggle[type];
        setEnableToggle(currentToggle);
    };
    var handleUnitTypesChange = function (value, type) {
        var currentUnitTypes = __assign({}, subscribedUnitTypes);
        currentUnitTypes[type] = value;
        setSubscribedUnitTypes(currentUnitTypes);
    };
    var updateEmailSubscriptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var subscriptionObject;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    subscriptionObject = {
                        enableCapacityNotification: enableToggle.capacity,
                        enableMaintenanceNotification: enableToggle.maintenance,
                        subscribeHierarchyKeys: checkedKeys,
                        unitTypesForCapacity: subscribedUnitTypes.capacity,
                        unitTypesForMaintenance: subscribedUnitTypes.maintenance,
                    };
                    return [4 /*yield*/, triggerSaveEmailSubscription(subscriptionObject)];
                case 1:
                    _a.sent();
                    refetch();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    //disable update button if no changes
    var isUpdateDisabled = enableToggle.capacity === emailSubscription.enableCapacityNotification &&
        enableToggle.maintenance === emailSubscription.enableMaintenanceNotification &&
        checkedKeys.every(function (key) { var _a; return (_a = emailSubscription.subscribeHierarchyKeys) === null || _a === void 0 ? void 0 : _a.includes(key); }) &&
        checkedKeys.length === emailSubscription.subscribeHierarchyKeys.length;
    return (_jsxs(StyledModal, __assign({ destroyOnClose: true, open: open, onClose: onClose, title: TITLE, description: DESCRIPTION, actions: [
            {
                label: 'Cancel',
                action: onClose,
                props: {
                    variant: 'outlined',
                    disabled: isLoading,
                    size: 'small',
                },
            },
            {
                label: 'Update',
                action: updateEmailSubscriptions,
                props: {
                    loading: isLoading,
                    disabled: isUpdateDisabled,
                    size: 'small',
                },
            },
        ] }, { children: [isFetchingHierarchies ? (_jsx(Loader, { message: "", delayMessage: "" })) : (_jsx(Text, __assign({ bold: true, size: "small" }, { children: "Locations" }))), _jsx("div", __assign({ style: { overflow: 'auto', maxHeight: '30vh' } }, { children: _jsx(StyledTree, { treeData: treeData, checkedKeys: checkedKeys, onCheck: handleCheck, disabled: isLoading }) })), _jsx(StyledTabs, { style: {
                    overflow: 'inherit',
                }, tabBarStyle: {
                    justifyContent: 'flex-start',
                    borderBottom: "1px solid ".concat(border.medium),
                }, items: [
                    {
                        label: (_jsx(Text, __assign({ size: "small", bold: true }, { children: "Maintenance" }))),
                        key: 'maintenance',
                        children: (_jsxs(Flexbox, __assign({ flexDirection: "column", gap: "15px", style: { padding: '20px 0 10px 0' } }, { children: [_jsx(Toggle, { size: "small", label: "Subscribed", disabled: isLoading, checked: enableToggle.maintenance, onChange: function () { return handleToggle('maintenance'); } }), _jsxs(Flexbox, __assign({ gap: "15px", alignItems: "center" }, { children: [_jsx(Label, __assign({ size: "small" }, { children: "Unit types" })), _jsx(Select, { placeholder: isFetchingUnitTypes ? 'Loading unit types' : _jsx(StyledPlaceholder, { children: "All units" }), disabled: isFetchingUnitTypes || isLoading, style: { width: '50%' }, mode: "multiple", size: "small", data: unitTypesOptions, value: (_b = subscribedUnitTypes.maintenance) !== null && _b !== void 0 ? _b : [], onChange: function (e) { return handleUnitTypesChange(e, 'maintenance'); } })] }))] }))),
                    },
                    {
                        label: (_jsx(Text, __assign({ size: "small", bold: true }, { children: "Capacity" }))),
                        key: 'capacity',
                        children: (_jsxs(Flexbox, __assign({ flexDirection: "column", gap: "15px", style: { padding: '20px 0 10px 0' } }, { children: [_jsx(Toggle, { size: "small", label: "Subscribed", disabled: isLoading, checked: enableToggle.capacity, onChange: function () { return handleToggle('capacity'); } }), _jsxs(Flexbox, __assign({ gap: "15px", alignItems: "center" }, { children: [_jsx(Label, __assign({ size: "small" }, { children: "Unit types" })), _jsx(Select, { placeholder: isFetchingUnitTypes ? 'Loading unit types' : _jsx(StyledPlaceholder, { children: "All units" }), disabled: isFetchingUnitTypes || isLoading, style: { width: '50%' }, mode: "multiple", size: "small", data: unitTypesOptions, value: (_c = subscribedUnitTypes.capacity) !== null && _c !== void 0 ? _c : [], onChange: function (e) { return handleUnitTypesChange(e, 'capacity'); } })] }))] }))),
                    },
                ] })] })));
};
