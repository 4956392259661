import styled, { css } from 'styled-components';

import Label from '../Text/components/Label/Label';
import { ILabelProps } from '../Text/components/Label/Label.types';
import { IButtonGroupButtonProps } from './ButtonGroup.types';
import { buttonGroupConsts } from './ButtonGroup.consts';
import { Sizes, Positions } from '../../types/types';

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.background.surface};
  color: ${({ theme }) => theme.text.onSurface.strong};
`;

const ButtonGroupLabel = styled(Label)<
  ILabelProps &
    Pick<IButtonGroupButtonProps, 'checked' | 'disabled' | 'iconOnly' | 'iconPosition' | 'emphasis'> &
    Required<Pick<IButtonGroupButtonProps, 'size'>>
>`
  ${({ size, iconOnly, iconPosition, checked, disabled, emphasis, theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.border.medium};
  height: ${buttonGroupConsts[size].height}px;
  padding: 0 ${buttonGroupConsts[size].paddings}px;
  transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s;
  cursor: pointer;

  ${size === Sizes.ExtraSmall && theme.label.small.bold};
  ${size === Sizes.Small && theme.label.medium.bold};
  ${(size === Sizes.Large || size === Sizes.Medium) && theme.label.large.bold};


  &:first-child {
    border-radius: ${buttonGroupConsts[size].borderRadius}px 0 0 ${buttonGroupConsts[size].borderRadius}px;
    border-right-color: transparent
  }
  
  &:last-child {
    border-radius: 0 ${buttonGroupConsts[size].borderRadius}px ${buttonGroupConsts[size].borderRadius}px 0;
    border-left-color: transparent;
  }
  
  &:only-child {
    border-radius: ${buttonGroupConsts[size].borderRadius}px;
  }

  ${
    !checked &&
    !disabled &&
    css`
      &:hover {
        background-color: ${theme.background.inactive};
      }
    `
  }

  ${
    !disabled &&
    css`
      &:active {
        background-color: ${emphasis ? theme.focus.medium : theme.text.onSurface.subtle};
        border-color: transparent;
        color: ${theme.text.onFocus.strong};

        svg path {
          fill: ${theme.text.onFocus.strong};
        }
      }

      &:focus-visible {
        box-shadow: 0 0 0 3px ${emphasis ? theme.focus.medium : theme.text.onSurface.subtle};
        border-color: ${emphasis ? theme.focus.strong : theme.text.onSurface.strong};
        border-radius: 2px;
        outline: 0;
      }
    `
  }

  ${
    checked &&
    css`
      background-color: ${emphasis ? theme.focus.strong : theme.text.onSurface.strong};
      color: ${theme.text.onFocus.strong};
      border-color: transparent;

      svg path {
        fill: ${theme.text.onFocus.strong};
      }
    `
  }

  ${
    disabled &&
    css`
      background-color: ${theme.background.inactive};
      color: ${theme.text.onSurface.inactive};
      user-select: none;
      outline: none;
      cursor: not-allowed;

      ${!checked &&
      css`
        svg path {
          fill: ${theme.text.onFocus.inactive};
        }
      `}

      ${checked &&
      css`
        background-color: ${theme.background.mask};
        color: ${theme.text.onInverted.strong};
        border-color: transparent;
      `}
    `
  }

  svg {
    width: ${buttonGroupConsts[size].icon};
    height: ${buttonGroupConsts[size].icon};
    margin: ${!iconOnly && (iconPosition === Positions.Right ? '0 0 0 8px' : '0 8px 0 0')};
`}
`;

const ButtonGroupRadio = styled.input`
  display: none;
`;

const ButtonGroupCheckbox = styled.input`
  display: none;
`;

export { ButtonGroup, ButtonGroupLabel, ButtonGroupRadio, ButtonGroupCheckbox };
