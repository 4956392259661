import { PectenColorStyle, PectenPadding } from '../Pecten.types';
import { Spacings } from '../../../types/types';

export const getEmphasis = (emphasis?: boolean, colorStyle?: PectenColorStyle): boolean =>
  !(emphasis === false || (colorStyle === 'mono' && !emphasis));

export const getPadding = (padding?: PectenPadding): PectenPadding | undefined => {
  switch (padding) {
    case 'none':
      return undefined;
    case 'minimum':
      return Spacings.Tight;
    case 'optimal':
      return Spacings.Generous;
    default:
      return padding;
  }
};
