var _a;
import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Menu, MenuItem, Tag, Flexbox } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { ROUTE_PATHS } from '../../routes';
var BetaNavItem = function (_a) {
    var title = _a.title;
    return (_jsxs(Flexbox, __assign({ gap: "8px" }, { children: [title, _jsx(Tag, __assign({ sentiment: "warning", variant: "outlined", size: "small" }, { children: "Beta" }))] })));
};
var StyledMenu = styled(Menu)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .shell-menu-label {\n    height: 52px;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    cursor: pointer;\n  }\n  .shell-menu-label-text {\n    overflow: visible !important;\n  }\n"], ["\n  .shell-menu-label {\n    height: 52px;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    cursor: pointer;\n  }\n  .shell-menu-label-text {\n    overflow: visible !important;\n  }\n"])));
var MENU_KEY = {
    EVENT_SUMMARY: 'maintenance',
    EVENT_SUMMARY_NEW: 'maintenance-new',
    CAPACITY: 'capacity',
    TRENDS: 'trends',
    ALL_REPORTS: 'all-reports',
    ASSET_CATALOG: 'asset-catalog',
};
var ROUTES = (_a = {},
    _a[MENU_KEY.EVENT_SUMMARY] = ROUTE_PATHS.MAINTENANCE_EVENT_SUMMARY,
    _a[MENU_KEY.CAPACITY] = ROUTE_PATHS.CAPACITY,
    _a[MENU_KEY.TRENDS] = ROUTE_PATHS.TRENDS,
    _a[MENU_KEY.ALL_REPORTS] = ROUTE_PATHS.ALL_REPORTS,
    _a[MENU_KEY.ASSET_CATALOG] = ROUTE_PATHS.ASSET_CATALOG,
    _a);
export var AppNavbar = function () {
    var history = useHistory();
    var location = useLocation();
    var pathName = location.pathname.split('/').filter(Boolean);
    var handleNavigation = useCallback(function (e) {
        //save filters between event-summary and capacity screens
        var saveFilters = (pathName[0] === MENU_KEY.EVENT_SUMMARY && e.key === MENU_KEY.CAPACITY) ||
            (pathName[0] === MENU_KEY.CAPACITY && e.key === MENU_KEY.EVENT_SUMMARY);
        var params = new URLSearchParams(location.search);
        params.delete('timeframe');
        if (ROUTES[e.key]) {
            history.push({
                pathname: ROUTES[e.key],
                search: saveFilters ? params.toString() : '',
            });
        }
    }, [history, location, pathName]);
    return (_jsxs(StyledMenu, __assign({ mode: "horizontal", onClick: handleNavigation, selectedKeys: [pathName[0]], disabledOverflow: true }, { children: [_jsx(MenuItem, { children: "Maintenance" }, MENU_KEY.EVENT_SUMMARY), _jsx(MenuItem, { children: "Capacity" }, MENU_KEY.CAPACITY), _jsx(MenuItem, { children: _jsx(BetaNavItem, { title: "Asset catalog" }) }, MENU_KEY.ASSET_CATALOG), _jsx(MenuItem, { children: _jsx(BetaNavItem, { title: "Trends" }) }, MENU_KEY.TRENDS), _jsx(MenuItem, { children: _jsx(BetaNavItem, { title: "All reports" }) }, MENU_KEY.ALL_REPORTS)] })));
};
var templateObject_1;
