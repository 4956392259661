import Loading from '../../../Loading/Loading';
import { ICommonButtonProps } from '../../Button.types';
import * as Styled from './ButtonContent.styles';
import { Sizes, Positions } from '../../../../types/types';
import { getLoaderType } from '../../utils/buttonContentUtils';

const ButtonContent = ({
  size = Sizes.Medium,
  variant,
  sentiment,
  children,
  loading,
  icon,
  iconOnly,
  iconPosition,
}: ICommonButtonProps) => {
  const loaderSize = size === Sizes.Large ? Sizes.Small : Sizes.ExtraSmall;

  return (
    <>
      <Styled.ButtonContentLoader>
        {loading && <Loading size={loaderSize} type={getLoaderType(sentiment, variant)} />}
      </Styled.ButtonContentLoader>
      <Styled.ButtonContent visibility={loading ? 'hidden' : 'visible'}>
        {iconPosition === Positions.Left && icon}
        {iconOnly ? null : children}
        {iconPosition === Positions.Right && icon}
      </Styled.ButtonContent>
    </>
  );
};

export default ButtonContent;
