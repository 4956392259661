import dayjs from 'dayjs';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { mapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
export var filterResetObject = {
    assets: [],
    location: [],
    owners: [],
    units: [],
    unitSubTypes: [],
    unitTypes: [],
    timeframe: [
        dayjs().startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        dayjs().add(3, 'month').endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
    ],
    version: dayjs().format(DATE_FORMAT_YYYY_MM_DD),
    assetOutageGroupKey: [],
    derateCapacityInd: [],
    eventCauseTypeKey: [],
};
export var sortUnitTypes = function (unitTypes) {
    if (!unitTypes.length)
        return [];
    var temp = unitTypes.map(mapSelectData('assetUnitTypeCd')).sort(sortSelectData);
    var cduCsuIndex = temp.findIndex(function (unitType) { return unitType.children === 'CDU+CSU'; });
    var vduIndex = temp.findIndex(function (unitType) { return unitType.children === 'VDU'; });
    var cduCsu = temp.splice(cduCsuIndex, 1);
    temp.unshift(cduCsu[0]);
    var vdu = temp.splice(vduIndex, 1);
    temp.splice(1, 0, vdu[0]);
    return temp;
};
export var sortUnitSubtypes = function (unitSubTypes) {
    if (!unitSubTypes.length)
        return [];
    var temp = unitSubTypes.map(mapSelectData('assetUnitSubtypeCd')).sort(sortSelectData);
    var cduIndex = temp.findIndex(function (unitSubType) { return unitSubType.children === 'CDU'; });
    var csuIndex = temp.findIndex(function (unitSubType) { return unitSubType.children === 'CSU'; });
    var vduIndex = temp.findIndex(function (unitSubType) { return unitSubType.children === 'VDU'; });
    var cdu = temp.splice(cduIndex, 1);
    temp.unshift(cdu[0]);
    var csu = temp.splice(csuIndex, 1);
    temp.splice(1, 0, csu[0]);
    var vdu = temp.splice(vduIndex, 1);
    temp.splice(2, 0, vdu[0]);
    return temp;
};
