import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function dataProviderService(props) {
    var validationParams = {
        serviceNm: 'Enter Service Name.',
        dataProviderAbbr: 'Enter Data Provider Abbreviation .',
    };
    var columnParams = [
        //Need to consider this for upcoming release
        // {
        //   field: "splitCostMethodTypeCd",
        //   title: "Split Cost Method Type Key",
        //   type: "foreignKeyConstraint",
        //   referenceCd: "splitCostMethodTypeCd",
        //   referenceTitle: "Split Cost Method Type",
        //   urlEndPoint: "splitCostMethodType"
        // },
        {
            field: 'accessAdGroupNm',
            title: 'Access Ad Group Nm',
        },
        //Need to consider this for upcoming release
        // {
        //   field: "licenseTypeCd",
        //   title: "License Type Key",
        //   type: "foreignKeyConstraint",
        //   referenceCd: "licenseTypeCd",
        //   referenceTitle: "License Type",
        //   urlEndPoint: "licenseType"
        // },
        {
            field: 'serviceDesc',
            title: 'Service Desc',
        },
        {
            field: 'serviceNm',
            title: 'Service Nm',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'subscriptionRequiredInd',
            title: 'Is Subscription Required ?',
            key: 'booleanList',
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'dataProviderService' });
}
export default dataProviderService;
