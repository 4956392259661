import { css } from 'styled-components';

import { StyledMenuProps } from '../Menu.types';

export const indicatorStyle = css<StyledMenuProps>`
  ${({ $emphasis, theme }) => css`
    content: '';
    position: absolute;
    display: block;
    background-color: ${$emphasis ? theme.accent.primary.strong : theme.text.onBase.subtle};
  `}
`;

export const indicatorRightStyle = css<StyledMenuProps>`
  ${indicatorStyle};
  width: 3px;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const indicatorLeftStyle = css<StyledMenuProps>`
  ${indicatorStyle};
  width: 3px;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const indicatorBottomStyle = css<StyledMenuProps>`
  ${indicatorStyle};
  height: 3px;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const indicatorPositionStyle = css<StyledMenuProps>`
  ${indicatorStyle};

  ${({ $indicatorPosition }) =>
    ({
      right: indicatorRightStyle,
      left: indicatorLeftStyle,
    }[$indicatorPosition])}
`;
