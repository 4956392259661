import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from './tools/header';
import Topnav from './tools/topnav';
import { If } from 'rc-if-else';
import '../globalAssetDatabase.css';
import './capacity.css';
import FilterContainer from './filterContainer';
import '../../../../../../node_modules/simplebar/dist/simplebar.min.css';
import TotalCapacityDataGrid from './totalCapacityDataGrid';
import IncrementalCapacityScreen from './incrementalCapacityScreen';
import { getSourceFilter } from '../../../../../services/urlService';
import Spinner from '../../../../../commons/spinner';
import * as mainFilterActions from '../../../../../redux/actions/mainFilterActions';
import { bindActionCreators } from 'redux';
import CapacityAddEdit from './CapacityAddEdit.tsx';
import { toast } from 'react-toastify';
import { ApiProvider } from '../../dataStore/apiDataStore';
import SvgLogo from '../../../../../commons/tools/svgLogo';
var Capacityscreen = /** @class */ (function (_super) {
    __extends(Capacityscreen, _super);
    function Capacityscreen(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            filterbtnisOn: false,
            cviewisOn: false,
            ceviewisOn: false,
            fsviewisOn: true,
            esviewisOn: false,
            eventsWindow: false,
            download: false,
            isApply: true,
            filterShow: true,
            selectedData: {},
            randKey: '76786896786786',
            totalCapacityAssetUnit: [],
            incrementalCapacityData: [],
            loader: false,
            newAddEditBt: false,
            applyButton: false,
            applyButtonEnable: [],
            baseProvider: null,
            limitForHighlight: 10,
            showLegend: false,
            lastUpdateDate: null,
            allFilters: {},
            url: null,
            shouldRefresh: false,
            assetParamsUrl: false,
            dateFilter: null,
        };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.resetEnableWatermark = function () {
            _this.setState({
                isApply: true,
            });
        };
        _this.OnApply = function (url) { return __awaiter(_this, void 0, void 0, function () {
            var pathTotalCapacity, pathIncrementalCapacity, path;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            loader: true,
                            url: url,
                        });
                        pathTotalCapacity = 'TotalCapacities/TotalCapacityByAsset' + url;
                        pathIncrementalCapacity = 'IncrementalCapacities' + url;
                        path = this.state.cviewisOn ? pathIncrementalCapacity : pathTotalCapacity;
                        return [4 /*yield*/, getSourceFilter(path)
                                .then(function (returnData) {
                                if (_this.state.cviewisOn) {
                                    if (typeof returnData !== 'undefined' &&
                                        typeof returnData.data !== 'undefined' &&
                                        returnData.data.length > 0) {
                                        _this.setState({
                                            incrementalCapacityData: returnData.data,
                                            loader: false,
                                            isApply: false,
                                            randKey: _this.getRandom(),
                                        });
                                    }
                                    else {
                                        _this.setState({
                                            loader: false,
                                            isApply: true,
                                        });
                                    }
                                }
                                else {
                                    _this.setState({
                                        totalCapacityAssetUnit: typeof returnData !== 'undefined' ? returnData.data : [],
                                        randKey: _this.getRandom(),
                                    }, function () {
                                        _this.setState({
                                            loader: false,
                                            isApply: false,
                                        });
                                    });
                                }
                            })
                                .catch(function (error) {
                                _this.setState({
                                    loader: false,
                                    isApply: true,
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setAllFilters = function (data) {
            _this.setState({
                allFilters: __assign(__assign({}, _this.state.allFilters), data),
            });
        };
        _this.handleAddEdit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (Object.keys(this.state.allFilters).length < 7) {
                    toast.warning('Please wait until screen finishes loading.', {
                        autoClose: 6000,
                        toastId: 'ADD_EDIT_BUTTON',
                    });
                    return [2 /*return*/];
                }
                if (typeof e !== 'undefined') {
                    if (typeof e.tagName !== 'undefined' && (e.tagName == 'DIV' || e.tagName == 'BUTTON')) {
                        this.setState({
                            eventsWindow: !this.state.eventsWindow,
                            selectedData: {
                                Countries: {
                                    text: e.getAttribute('data-country'),
                                    id: parseInt(e.getAttribute('data-country-id')),
                                },
                                Cities: {
                                    text: e.getAttribute('data-city'),
                                    id: parseInt(e.getAttribute('data-city-id')),
                                },
                                Companies: {
                                    text: e.getAttribute('data-owner'),
                                    id: parseInt(e.getAttribute('data-owner-id')),
                                },
                                AssetType: {
                                    text: e.getAttribute('data-assettype'),
                                    id: parseInt(e.getAttribute('data-assettype-id')),
                                },
                                Asset: {
                                    text: e.getAttribute('data-assetname'),
                                    id: parseInt(e.getAttribute('data-asset-id')),
                                },
                                UnitType: {
                                    text: e.getAttribute('data-unittype'),
                                    id: parseInt(e.getAttribute('data-unittype-id')),
                                },
                                UnitSubType: {
                                    text: e.getAttribute('data-unitsubtype'),
                                    id: parseInt(e.getAttribute('data-unitsubtype-id')),
                                },
                                Unit: {
                                    text: e.getAttribute('data-unit'),
                                    id: parseInt(e.getAttribute('data-unit-id')),
                                },
                                refreshIndex: parseInt(e.getAttribute('refresh-index')),
                                eventKey: parseInt(e.getAttribute('data-asset-unit-key')),
                            },
                            editOnUnitCapacities: e.getAttribute('edit-event'),
                            newAddEditBt: false,
                        });
                    }
                    else {
                        this.setState({
                            eventsWindow: !this.state.eventsWindow,
                            newAddEditBt: true,
                            selectedData: {},
                        });
                    }
                }
                else {
                    this.setState({
                        eventsWindow: !this.state.eventsWindow,
                        newAddEditBt: true,
                        selectedData: {},
                    });
                }
                return [2 /*return*/];
            });
        }); };
        _this.renderGenevaLogo = function () {
            return (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent" }, { children: _jsx("div", __assign({ className: "container", style: { marginTop: '5%' } }, { children: _jsxs("div", __assign({ style: { display: _this.state.isApply ? 'block' : 'none' }, className: "text-center" }, { children: [_jsx(SvgLogo, {}), _jsx("p", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "Click on Apply to view Capacity" }))] })) })) })));
        };
        _this.capacityViewEvent = function () {
            _this.setState({ cviewisOn: !_this.state.cviewisOn }, function () {
                _this.setState({
                    isApply: true,
                });
            });
        };
        _this.collapseExpandEvent = function () {
            _this.setState({ ceviewisOn: !_this.state.ceviewisOn });
        };
        _this.filterShowEvent = function () {
            _this.setState({
                fsviewisOn: !_this.state.fsviewisOn,
                filterShow: !_this.state.filterShow,
            });
        };
        _this.expandStandardEvent = function () {
            _this.setState({ esviewisOn: !_this.state.esviewisOn });
        };
        _this.visualizationEvent = function () {
            window.open('https://app.powerbi.com/groups/me/apps/99d6e8b2-e816-4907-9de0-6efde3d3055b/reports/600296f7-1f8b-439d-a152-40527da409d2/ReportSectionda033444bb0a3361dd17?ctid=db1e96a8-a3da-442a-930b-235cac24cd5c', '_blank');
        };
        _this.filterBtnToggle = function () {
            _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
        };
        _this.customRender = function (data) {
            var value = data.dataItem[data.field];
            return (_jsx("td", { children: _jsxs("div", __assign({ className: "td-data" }, { children: [value === null ? '' : data.dataItem[data.field].toString(), " KB/D"] })) }));
        };
        _this.handleDownloadToggle = function () {
            _this.setState({ download: !_this.state.download });
        };
        _this.componentDidMount = function () {
            var currentPath = _this.props.location.pathname.split('/').pop();
            if (currentPath === 'capacityTotal') {
                _this.setState({
                    cviewisOn: false,
                });
            }
            else {
                _this.setState({
                    cviewisOn: true,
                });
            }
            var resizer = document.querySelectorAll('.k-column-resizer');
            resizer.forEach(function (e) {
                e.innerHTML += "<i className='fa fa-arrows-h' aria-hidden='true'></i>";
                e.style.display = 'none';
            });
        };
        _this.paramsForAssetFilterURLGenerate = function (startDate, endDate, dp, pt, versionDate, lastUpdateDate, path) {
            _this.setState({
                startDate: startDate,
                endDate: endDate,
                dp: dp,
                pt: pt,
                versionDate: versionDate,
                incrAssetPath: path,
                lastUpdateDate: lastUpdateDate,
            });
        };
        _this.paramsForAssetFilter = function () {
            if (_this.state.lastUpdateDate) {
                return ('startdate=' +
                    _this.state.startDate +
                    '&enddate=' +
                    _this.state.endDate +
                    '&' +
                    _this.state.dp +
                    '&lastUpdateDateSince=' +
                    _this.state.lastUpdateDate +
                    '&' +
                    _this.state.pt +
                    '&' +
                    _this.state.incrAssetPath);
            }
            else
                return ('startdate=' +
                    _this.state.startDate +
                    '&enddate=' +
                    _this.state.endDate +
                    '&' +
                    _this.state.dp +
                    '&versiondate=' +
                    _this.state.versionDate +
                    '&' +
                    _this.state.pt +
                    '&' +
                    _this.state.incrAssetPath);
        };
        _this.refreshTotalCapacity = function (bool, assetId) {
            if ('boolean' !== typeof bool && bool > -1) {
                _this.TotalCapacityDataGrid.refreshBlockOfIndex(bool, assetId);
            }
            else {
                var assetArr_1 = [];
                _this.state.incrAssetPath.split('&').map(function (x) {
                    var xSplit = x.split('=');
                    if (xSplit.length > 0) {
                        if (xSplit[0] === 'a') {
                            assetArr_1.push(xSplit[1]);
                        }
                    }
                });
                if (assetArr_1.length > 0) {
                    _this.TotalCapacityDataGrid.refreshGridFromAddEdit(assetId);
                }
            }
        };
        _this.handleDateFilterChange = _this.handleDateFilterChange.bind(_this);
        return _this;
    }
    Capacityscreen.prototype.handleDateFilterChange = function (nextFilter) {
        this.setState({ dateFilter: nextFilter });
    };
    Capacityscreen.prototype.render = function () {
        var _this = this;
        var _a = this.state, cviewisOn = _a.cviewisOn, ceviewisOn = _a.ceviewisOn, fsviewisOn = _a.fsviewisOn, esviewisOn = _a.esviewisOn, eventsWindow = _a.eventsWindow;
        return (_jsx(ApiProvider, { children: _jsxs(_Fragment, { children: [this.state.loader && _jsx(Spinner, {}), _jsx(Header, { onAddEditClick: this.handleAddEdit, onAddEditClickEnable: Object.keys(this.state.allFilters).length == 9 ? false : true, onDownloadToggle: this.handleDownloadToggle, download: this.state.download }), _jsxs("div", __assign({ className: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100", id: "capacityscreen" }, { children: [_jsx("div", { children: _jsx(Topnav, { cvisOn: cviewisOn, pathIncrCap: "/dashboard/globalAssetDatabase/capacity/capacityIncremental", pathTotCap: "/dashboard/globalAssetDatabase/capacity/capacityTotal", capacityViewEvent: this.capacityViewEvent, collapseExpandEvent: this.collapseExpandEvent, ceisOn: ceviewisOn, filterShowEvent: this.filterShowEvent, fsisOn: fsviewisOn, expandStandardEvent: this.expandStandardEvent, esisOn: esviewisOn, visualizationEvent: this.visualizationEvent }) }), _jsxs("div", __assign({ className: "filter", style: { display: this.state.filterShow ? 'block' : 'none' } }, { children: [_jsx(FilterContainer, { ref: function (ref) { return (_this.applyTrigger = ref); }, OnApply: this.OnApply, display: this.state.filterShow, currentScreen: this.state.cviewisOn, resetWMEnable: this.resetEnableWatermark, paramsForAssetFilterURLGenerate: this.paramsForAssetFilterURLGenerate, globalFilterStore: this.props.actions.addSelectedElementToGlobalArray, deleteFilterStore: this.props.actions.removeSelectedElementToGlobalArray, mainFilterSelectedItems: this.props.mainFilterSelectedItems, 
                                        //filterShowEvent={this.filterShowEvent}
                                        setLimitForHighlight: function (limitForHighlight) {
                                            return _this.setState({
                                                limitForHighlight: limitForHighlight,
                                            });
                                        }, setBaseProvider: function (baseProvider) {
                                            return _this.setState({
                                                baseProvider: baseProvider,
                                                prevProvider: baseProvider,
                                            }, function () {
                                                _this.setState({
                                                    baseProvider: null, //one time update so shouldComponentUpdate is not called again and again in totalCapacityDataGrid
                                                });
                                            });
                                        }, deltaActive: function (bool) {
                                            return _this.setState({
                                                showLegend: bool,
                                            });
                                        }, setAllFilters: this.setAllFilters, onDateFilterChange: this.handleDateFilterChange }), _jsx("div", { className: "border-top mt-0 ml-0 mr-0 mb-3 p-0 shadow" })] })), _jsx(If, __assign({ condition: cviewisOn }, { children: _jsx("div", __assign({ className: "h-100 border-0" }, { children: _jsxs("div", __assign({ style: {
                                            height: fsviewisOn ? parseInt(window.innerHeight) - parseInt(200) + 'px' : '812px',
                                            border: '0px',
                                        } }, { children: [this.state.showLegend && (_jsxs("div", __assign({ className: "row font-shell-light" }, { children: [_jsx("div", { className: "col-10" }), _jsxs("div", __assign({ className: "col-2" }, { children: [_jsxs("div", __assign({ className: "pull-right pr-4", style: { display: 'flex' } }, { children: [_jsx("div", { className: "dot-limit1" }), _jsxs("div", __assign({ className: "ml-2" }, { children: ['> ' + this.state.limitForHighlight + ' KB/D', " "] }))] })), _jsxs("div", __assign({ className: "pull-right pr-3", style: { display: 'flex' } }, { children: [_jsx("div", { className: "dot-limit2" }), _jsx("div", __assign({ className: "ml-2" }, { children: '< ' + this.state.limitForHighlight + ' KB/D' }))] }))] }))] }))), _jsx("div", __assign({ style: { display: !this.state.isApply ? 'block' : 'none' } }, { children: _jsx(IncrementalCapacityScreen, { ref: function (ref) { return (_this.IncrementalCapacityScreen = ref); }, onCapacitySelect: this.handleAddEdit, data: this.state.incrementalCapacityData, assetFilterParams: this.paramsForAssetFilter(), baseProvider: this.state.baseProvider, limitForHighlight: this.state.limitForHighlight, dateFilter: this.state.dateFilter }, this.state.randKey) })), this.renderGenevaLogo()] })) })) })), _jsx(If, __assign({ condition: !cviewisOn }, { children: _jsx("div", __assign({ className: "border-0", style: {
                                        overflowY: this.state.isApply ? 'hidden' : 'auto',
                                        overflowX: 'hidden',
                                    } }, { children: _jsxs("div", __assign({ style: {
                                            height: fsviewisOn ? window.innerHeight - 300 : window.innerHeight - 150,
                                        } }, { children: [this.state.showLegend && (_jsxs("div", __assign({ className: "row font-shell-light" }, { children: [_jsx("div", { className: "col-10" }), _jsxs("div", __assign({ className: "col-2" }, { children: [_jsxs("div", __assign({ className: "pull-right pr-4", style: { display: 'flex' } }, { children: [_jsx("div", { className: "dot-limit1" }), _jsxs("div", __assign({ className: "ml-2" }, { children: ['> ' + this.state.limitForHighlight + '%', " "] }))] })), _jsxs("div", __assign({ className: "pull-right pr-3", style: { display: 'flex' } }, { children: [_jsx("div", { className: "dot-limit2" }), _jsx("div", __assign({ className: "ml-2" }, { children: '<' + this.state.limitForHighlight + '%' }))] }))] }))] }))), _jsx("div", __assign({ className: "container-fluid m-0 p-0", style: { display: !this.state.isApply ? 'block' : 'none' } }, { children: _jsx(TotalCapacityDataGrid, { ref: function (ref) { return (_this.TotalCapacityDataGrid = ref); }, onCapacitySelect: this.handleAddEdit, data: this.state.totalCapacityAssetUnit, assetFilterParams: this.paramsForAssetFilter(), baseProvider: this.state.baseProvider, limitForHighlight: this.state.limitForHighlight, shouldRefresh: this.state.shouldRefresh }, this.state.randKey) })), this.renderGenevaLogo()] })) })) }))] })), _jsx(CapacityAddEdit, { onClose: this.handleAddEdit, selectedData: this.state.selectedData, capacity: eventsWindow, maintenance: false, onApply: function (assetId) {
                            if (_this.state.cviewisOn && _this.state.incrementalCapacityData.length > 0) {
                                _this.IncrementalCapacityScreen.getSourceOnRefresh(assetId);
                            }
                        }, currentYearData: this.state.currentYearData, newAddEditBt: this.state.newAddEditBt, allFilters: this.state.allFilters, setShouldRefresh: function (bool, assetId) {
                            _this.refreshTotalCapacity(bool, assetId);
                        }, isIncCap: cviewisOn }), this.state.eventsWindow && _jsx("div", { className: "k-overlay" })] }) }));
    };
    return Capacityscreen;
}(Component));
export { Capacityscreen };
function mapStateToProps(state) {
    return {
        mainFilterSelectedItems: state.mainFilterActionReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainFilterActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Capacityscreen);
