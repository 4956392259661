import { __assign } from "tslib";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useApiContext } from '../../../../../../globalFilterContext/apiContext';
import ErrorBoundary from '../../../../../../services/errorBoundary';
import Spin from '../../../../../../commons/spin';
import SearchBox from './search-box';
import { If, ElIf } from 'rc-if-else';
import { Checkbox } from 'antd';
import _ from 'lodash';
function Filter(props) {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useState(), searchQuery = _c[0], setSearchQuery = _c[1];
    var _d = useState([]), currentSelectedValue = _d[0], setCurrentSelectedValue = _d[1];
    var _e = useState(false), loading = _e[0], useLoading = _e[1];
    var _f = useState(), nodata = _f[0], useNodata = _f[1];
    function handleSearch(query) {
        setSearchQuery(query);
        return;
    }
    function getFilterData() {
        var filtered = list;
        if (searchQuery)
            filtered = list.filter(function (item) {
                if (item[props.code]) {
                    //special Characters need includes
                    return item[props.code].toLowerCase().includes(searchQuery.toLowerCase());
                }
                return true;
            });
        return filtered;
    }
    useEffect(function () {
        setList(props.value);
        getFilterData();
    }, [searchQuery, props.value.length]);
    function getRandom() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    function onInputSelect(e) {
        var _a, _b;
        props.setCurrentSelectInFilter(props.selectionCode);
        var currentSelectedInList = apiParams.refineryFilterSelection[props.selectionCode];
        if (apiParams.refineryFilterSelection[props.selectionCode].includes(e.target.value)) {
            var index = currentSelectedInList.indexOf(e.target.value);
            if (index > -1) {
                currentSelectedInList.splice(index, 1);
            }
            var object_1 = __assign(__assign({}, apiParams.refineryFilterSelection), (_a = {}, _a[props.selectionCode] = currentSelectedInList, _a));
            setApiParams(function (prevParams) {
                return __assign(__assign({}, prevParams), { refineryFilterSelection: object_1 });
            });
        }
        else {
            currentSelectedInList.push(e.target.value);
            var object_2 = __assign(__assign({}, apiParams.refineryFilterSelection), (_b = {}, _b[props.selectionCode] = currentSelectedInList, _b));
            setApiParams(function (prevParams) {
                return __assign(__assign({}, prevParams), { refineryFilterSelection: object_2 });
            });
        }
    }
    function renderList(item, index) {
        var dataActive;
        var currentSelectedInList = apiParams.refineryFilterSelection[props.selectionCode];
        if (_.indexOf(currentSelectedInList, item.id) > -1) {
            dataActive = true;
        }
        else {
            dataActive = false;
        }
        return (_jsx(_Fragment, { children: item[props.code] && (_jsx("div", __assign({ className: "py-2 ml-2 item-line-height" }, { children: _jsxs(Checkbox, __assign({ onChange: onInputSelect, value: item.id, defaultChecked: dataActive, name: item[props.code] }, { children: [item[props.code], props.code === 'assetUnitNm' && item.totalCapacity && item.measureUnit.measureUnitCd
                            ? '(' + item.totalCapacity + ' ' + item.measureUnit.measureUnitCd + ')'
                            : null] })) }), props.code + '-' + item.id + '-' + index + '-' + dataActive)) }));
    }
    function renderElement() {
        if (props.loading) {
            return _jsx(Spin, {});
        }
        else if (nodata) {
            return _jsx("span", { children: "No Data..." });
        }
    }
    return (_jsx("div", { children: _jsx("div", __assign({ className: "col p-0" }, { children: _jsxs(ErrorBoundary, { children: [_jsx(SearchBox, { id: props.id, onChange: handleSearch, value: searchQuery }), _jsxs("div", __assign({ style: {
                            height: '300px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        } }, { children: [_jsx(If, __assign({ condition: getFilterData().length > 0 }, { children: getFilterData().map(function (value, index) {
                                    return renderList(value, index);
                                }) })), renderElement()] }))] }) })) }));
}
export default Filter;
