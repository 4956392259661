import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { getSourceFilter } from '../../../../../services/urlService';
import { ApiProvider } from './ApiContext';
import './events.css';
import { AddEditEventWindow } from './AddEditEventWindow';
var dataAreaPath = [
    'AssetEventTypes',
    'EventCauseTypes',
    'CapacityBasisTypes',
    'PlanningProbabilityTypes',
    'PlanningStatusTypes',
];
export var AddEditEvent = function (_a) {
    var selectedData = _a.selectedData, capacity = _a.capacity, maintenance = _a.maintenance, isIncCap = _a.isIncCap, onClose = _a.onClose, allFilters = _a.allFilters, newAddEditBt = _a.newAddEditBt, onApply = _a.onApply, setShouldRefresh = _a.setShouldRefresh, currentYearData = _a.currentYearData;
    var _b = useState(false), windowOverLay = _b[0], setWindow = _b[1];
    var _c = useState([]), unit = _c[0], setUnit = _c[1];
    var _d = useState({}), dropDownDataArea = _d[0], setDropDownDataArea = _d[1];
    var _e = useState({}), innerSelectedData = _e[0], setSelectedData = _e[1];
    /*
     *component did mount using useeffect, this used to determine the selected data
     */
    useEffect(function () {
        setSelectedData(Object.keys(selectedData).length > 0 ? selectedData : {});
        if (selectedData.Unit)
            setUnit([selectedData.Unit]);
    }, [selectedData]);
    /*
     *component did mount using useeffect, this used to load options for dropdowns in data area
     */
    useEffect(function () {
        dataAreaPath.map(function (value) {
            getSourceFilter(value).then(function (response) {
                if (typeof response !== 'undefined') {
                    var dataObject_1 = {};
                    dataObject_1[value] = response === null || response === void 0 ? void 0 : response.data;
                    setDropDownDataArea(function (prevState) { return (__assign(__assign({}, prevState), dataObject_1)); });
                }
            });
        });
        var maintenanceProviders = 'DataProviders?type=2';
        getSourceFilter(maintenanceProviders).then(function (response) {
            var dataObject = {};
            dataObject['DataProviders/Maintenance'] = response === null || response === void 0 ? void 0 : response.data;
            setDropDownDataArea(function (prevState) { return (__assign(__assign({}, prevState), dataObject)); });
        });
        var capacityProviders = 'DataProviders/UserSpecificDataProvider?TypeKey=1';
        getSourceFilter(capacityProviders).then(function (response) {
            var dataObject = {};
            dataObject['DataProviders/Capacity'] = response === null || response === void 0 ? void 0 : response.data;
            setDropDownDataArea(function (prevState) { return (__assign(__assign({}, prevState), dataObject)); });
        });
    }, []);
    /*
     *component did mount using useeffect, this used to determine the which screen either capacity or maintenance
     */
    useEffect(function () {
        setWindow(!!capacity || !!maintenance);
    }, [capacity, maintenance]);
    return (_jsx(ApiProvider, { children: (capacity || maintenance) && windowOverLay && (_jsxs("div", __assign({ className: "events-window" }, { children: [_jsx(AddEditEventWindow, { allFilters: allFilters, capacity: capacity, maintenance: maintenance, currentYearData: currentYearData, dropDownDataArea: dropDownDataArea, isIncCap: isIncCap, newAddEditBt: newAddEditBt, onApply: onApply, onClose: onClose, selectedData: innerSelectedData, setSelectedData: setSelectedData, setShouldRefresh: setShouldRefresh, setUnit: setUnit, setWindow: setWindow, unit: unit }), windowOverLay && _jsx("div", { className: "k-overlay" })] }))) }));
};
