import tinyColor from 'tinycolor2';

import { Theme } from '../providers/theme/theme';
import {
  Sentiments,
  DeprecatedStandardSentiments,
  DeprecatedSentiments,
  Variants,
  FilledVariant,
  TransparentVariant,
  OutlinedVariant,
  SubtleVariant,
} from '../types/types';

type ColorSentiments =
  | Sentiments.Information
  | Sentiments.Warning
  | Sentiments.Neutral
  | Sentiments.Negative
  | Sentiments.Positive
  | Sentiments.Default
  | DeprecatedSentiments;

type ColorStandardSentiments =
  | Sentiments.Information
  | Sentiments.Warning
  | Sentiments.Neutral
  | Sentiments.Negative
  | Sentiments.Positive
  | DeprecatedStandardSentiments;

type ColorVariants = Variants | FilledVariant | TransparentVariant | OutlinedVariant | SubtleVariant;

type ColorStandardVariants =
  | Variants.Filled
  | Variants.Subtle
  | Variants.Outlined
  | FilledVariant
  | OutlinedVariant
  | SubtleVariant;

export const getColorBySentiment = (sentiment: ColorSentiments, theme: Theme): string => {
  switch (sentiment) {
    case Sentiments.Positive:
      return theme.system.strong.positive;
    case Sentiments.Negative:
      return theme.system.strong.negative;
    case Sentiments.Warning:
      return theme.system.strong.warning;
    case Sentiments.Information:
      return theme.system.strong.information;
    case Sentiments.Default:
      return theme.focus.strong;
    default:
      return theme.system.strong.neutral;
  }
};

export const getSubtleColorBySentiment = (sentiment: ColorSentiments, theme: Theme): string => {
  switch (sentiment) {
    case Sentiments.Positive:
      return theme.system.subtle.positive;
    case Sentiments.Negative:
      return theme.system.subtle.negative;
    case Sentiments.Warning:
      return theme.system.subtle.warning;
    case Sentiments.Information:
      return theme.system.subtle.information;
    case Sentiments.Default:
      return theme.focus.subtle;
    default:
      return theme.system.subtle.neutral;
  }
};

export const getColorBySentimentOnSubtleBg = (sentiment: ColorSentiments, theme: Theme): string => {
  switch (sentiment) {
    case Sentiments.Positive:
      return theme.system.strong.positive;
    case Sentiments.Negative:
      return theme.system.strong.negative;
    case Sentiments.Information:
      return theme.system.strong.information;
    case Sentiments.Default:
      return theme.focus.strong;
    default:
      return theme.text.onSurface.strong;
  }
};

export const getColorByVariant = (theme: Theme, sentiment: ColorSentiments, variant: ColorVariants) => {
  switch (variant) {
    case Variants.Outlined:
    case Variants.Transparent:
      if (sentiment === Sentiments.Neutral) {
        return theme.text.onSurface.strong;
      }

      if (sentiment === Sentiments.Default) {
        return theme.focus.strong;
      }

      return theme.text.onSurface[sentiment];
    case Variants.Subtle:
      if (sentiment === Sentiments.Default) {
        return theme.text.onSystem.subtle;
      }

      return theme.text.onSystem.subtle[sentiment];
    case Variants.Filled:
    default:
      if (sentiment === Sentiments.Default) {
        return theme.text.onSystem.strong.information;
      }

      return theme.text.onSystem.strong[sentiment];
  }
};

export const getBackgroundColorByVariant = (
  theme: Theme,
  sentiment: ColorStandardSentiments,
  variant: ColorStandardVariants,
): string => {
  switch (variant) {
    case Variants.Outlined:
      return Variants.Transparent;
    case Variants.Subtle:
      return theme.system.subtle[sentiment];
    case Variants.Filled:
    default:
      return theme.system.strong[sentiment];
  }
};

export const getBorderColorByVariant = (
  theme: Theme,
  sentiment: ColorStandardSentiments,
  variant: ColorStandardVariants,
): string => {
  switch (variant) {
    case Variants.Outlined:
      if (sentiment === Sentiments.Neutral) {
        return theme.border.medium;
      }

      if (sentiment === Sentiments.Warning) {
        return theme.system.strong[sentiment];
      }

      return theme.text.onSurface[sentiment];
    case Variants.Subtle:
      return theme.system.subtle[sentiment];
    case Variants.Filled:
    default:
      return theme.system.strong[sentiment];
  }
};

export const rgba2rgb = (rawColor: string, rawBackground: string) => {
  const color = tinyColor(rawColor).toRgb();
  const background = tinyColor(rawBackground).toRgb();
  const alpha = color.a;

  color.r = Math.floor((1 - alpha) * background.r + alpha * color.r + 0.5);
  color.g = Math.floor((1 - alpha) * background.g + alpha * color.g + 0.5);
  color.b = Math.floor((1 - alpha) * background.b + alpha * color.b + 0.5);
  color.a = 1;

  return tinyColor(color).toRgbString();
};
