var programsMenu = {
    title: 'Programs',
    caption: 'Geneva',
    menu: [
        {
            genevaMenu: {
                id: '1',
                uniqueKey: 1,
                title: 'ACM',
                component_path: '',
            },
            items: [
                {
                    genevaMenu: {
                        id: '2',
                        uniqueKey: 2,
                        title: 'Maintenance Event Summary',
                        component_path: '/dashboard/globalAssetDatabase/maintenance/maintenanceEventSummary',
                    },
                },
                {
                    genevaMenu: {
                        id: '3',
                        uniqueKey: 3,
                        title: 'Maintenance Daily',
                        component_path: '/dashboard/globalAssetDatabase/maintenance/maintenanceDaily',
                    },
                },
                {
                    genevaMenu: {
                        id: '4',
                        uniqueKey: 4,
                        title: 'Capacity - Total',
                        component_path: '/dashboard/globalAssetDatabase/capacity/capacityTotal',
                    },
                },
                {
                    genevaMenu: {
                        id: '5',
                        uniqueKey: 5,
                        title: 'Capacity - Incremental',
                        component_path: '/dashboard/globalAssetDatabase/capacity/capacityIncremental',
                    },
                },
                {
                    genevaMenu: {
                        id: '5',
                        uniqueKey: 5,
                        title: 'Refinery Intake',
                        component_path: '/dashboard/globalAssetDatabase/refineryIntake',
                    },
                },
                {
                    genevaMenu: {
                        id: '6',
                        uniqueKey: 6,
                        title: 'Data Upload',
                        component_path: '/dashboard/globalAssetDatabase/dataUpload',
                    },
                },
                {
                    genevaMenu: {
                        id: '7',
                        uniqueKey: 7,
                        title: 'Charts',
                        component_path: '/dashboard/charts',
                    },
                },
                {
                    genevaMenu: {
                        id: '8',
                        uniqueKey: 8,
                        title: 'Client Tracker',
                        component_path: '/companies',
                    },
                },
            ],
            expanded: true,
        },
        {
            genevaMenu: {
                id: '8',
                uniqueKey: 8,
                title: 'Configuration Data',
                component_path: '',
            },
            items: [
                {
                    genevaMenu: {
                        id: '9',
                        uniqueKey: 9,
                        title: 'General Configuration',
                        component_path: '/dashboard/generalConfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '10',
                                uniqueKey: 10,
                                title: 'Intelligence Source Type',
                                component_path: '/dashboard/generalConfiguration/intelligenceSourceType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '11',
                                uniqueKey: 11,
                                title: 'Version Type',
                                component_path: '/dashboard/generalConfiguration/versionType',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '12',
                        uniqueKey: 12,
                        title: 'Baseline Configuration',
                        component_path: '/dashboard/baselineConfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '13',
                                uniqueKey: 13,
                                title: 'Baseline Data Provider',
                                component_path: '/dashboard/baselineConfiguration/baselineProvider',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '14',
                                uniqueKey: 14,
                                title: 'Baseline Type',
                                component_path: '/dashboard/baselineConfiguration/baselineType',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '15',
                        uniqueKey: 15,
                        title: 'Asset Configuration',
                        component_path: '/dashboard/assetConfiguration',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '16',
                                uniqueKey: 6,
                                title: 'Asset Unit Configuration',
                                component_path: '/dashboard/globalAssetDatabase/assetUnitConfiguration/assetUnitConfiguration',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '17',
                                uniqueKey: 17,
                                title: 'Asset Configuration',
                                component_path: '/dashboard/globalAssetDatabase/assetConfiguration',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '18',
                                uniqueKey: 18,
                                title: 'Asset Class',
                                component_path: '/dashboard/assetConfiguration/assetClass',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '19',
                                uniqueKey: 19,
                                title: 'Asset Configuration Type',
                                component_path: '/dashboard/assetConfiguration/assetConfigurationType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '20',
                                uniqueKey: 20,
                                title: 'Asset Event Type',
                                component_path: '/dashboard/assetConfiguration/assetEventType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '21',
                                uniqueKey: 21,
                                title: 'Asset Relationship Type',
                                component_path: '/dashboard/assetConfiguration/assetRelationshipType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '22',
                                uniqueKey: 22,
                                title: 'Asset Ramp-Up',
                                component_path: '/dashboard/assetConfiguration/assetRampUp',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '23',
                                uniqueKey: 23,
                                title: 'Capacity Basis Type',
                                component_path: '/dashboard/assetConfiguration/capacityBasisType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '24',
                                uniqueKey: 24,
                                title: 'Consumption Range Type',
                                component_path: '/dashboard/assetConfiguration/consumptionRangeType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '25',
                                uniqueKey: 26,
                                title: 'Industry Type',
                                component_path: '/dashboard/assetConfiguration/industryType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '27',
                                uniqueKey: 27,
                                title: 'Operational Status Type',
                                component_path: '/dashboard/assetConfiguration/operationalStatusType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '28',
                                uniqueKey: 28,
                                title: 'Planning Precision Type',
                                component_path: '/dashboard/assetConfiguration/planningPrecisionType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '29',
                                uniqueKey: 29,
                                title: 'Planning Probability Type',
                                component_path: '/dashboard/assetConfiguration/planningProbabilityType',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '30',
                                uniqueKey: 30,
                                title: 'Planning Status Type',
                                component_path: '/dashboard/assetConfiguration/planningStatusType',
                            },
                        },
                    ],
                },
            ],
            expanded: false,
        },
        {
            genevaMenu: {
                id: '31',
                uniqueKey: 31,
                title: 'Reference Data',
                component_path: '',
            },
            items: [
                {
                    genevaMenu: {
                        id: '32',
                        uniqueKey: 32,
                        title: 'Asset Reference',
                        component_path: '/dashboard/assetReference',
                    },
                    items: [
                        // {
                        //   genevaMenu: {
                        //     id: "1",
                        //     uniqueKey: 22,
                        //     title: "Asset & Asset Version",
                        //     component_path: "/dashboard/assetReference/asset",
                        //   },
                        // },
                        {
                            genevaMenu: {
                                id: '33',
                                uniqueKey: 33,
                                title: 'Asset Company',
                                component_path: '/dashboard/assetReference/assetCompany',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '35',
                                uniqueKey: 35,
                                title: 'Asset Relationship',
                                component_path: '/dashboard/assetReference/assetRelationship',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '36',
                        uniqueKey: 36,
                        title: 'Asset Cross-Reference',
                        component_path: '/dashboard/assetCrossReference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '37',
                                uniqueKey: 37,
                                title: 'Asset Cross-Reference',
                                component_path: '/dashboard/assetCrossReference/assetCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '38',
                                uniqueKey: 38,
                                title: 'Industry Type Cross-Reference',
                                component_path: '/dashboard/assetCrossReference/industryTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '39',
                                uniqueKey: 39,
                                title: 'Operational Status Type Cross-Reference',
                                component_path: '/dashboard/assetCrossReference/operationalTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '40',
                                uniqueKey: 40,
                                title: 'Planning Precision Type Cross Reference',
                                component_path: '/dashboard/assetCrossReference/planningRrecisionTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '41',
                                uniqueKey: 41,
                                title: 'Planning Status Type Cross Reference',
                                component_path: '/dashboard/assetCrossReference/planningStatusTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '42',
                                uniqueKey: 42,
                                title: 'Asset Configuration Type Cross Reference',
                                component_path: '/dashboard/assetCrossReference/assetConfigurationTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '43',
                                uniqueKey: 43,
                                title: 'Planning Probability Type Cross Reference',
                                component_path: '/dashboard/assetCrossReference/planningProbabilityTypeCrossReference',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '44',
                        uniqueKey: 44,
                        title: 'Asset Unit Reference',
                        component_path: '/dashboard/assetunitreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '45',
                                uniqueKey: 45,
                                title: 'Asset Unit & Asset Unit Version',
                                component_path: '/dashboard/assetUnitReference/assetUnitAndVersion',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '47',
                                uniqueKey: 47,
                                title: 'Asset Unit Type',
                                component_path: '/dashboard/assetunitreference/assetunittype',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '48',
                                uniqueKey: 48,
                                title: 'Asset Unit Subtype',
                                component_path: '/dashboard/assetunitreference/assetunitsubtype',
                            },
                        },
                    ],
                },
                {
                    genevaMenu: {
                        id: '49',
                        uniqueKey: 49,
                        title: 'Asset Unit Cross-Reference',
                        component_path: '/dashboard/assetunitcrossreference',
                    },
                    items: [
                        {
                            genevaMenu: {
                                id: '50',
                                uniqueKey: 50,
                                title: 'Asset Unit Cross-Reference',
                                component_path: '/dashboard/assetUnitCrossReference/assetUnitCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '51',
                                uniqueKey: 51,
                                title: 'Asset Unit Subtype Cross-Reference',
                                component_path: '/dashboard/assetUnitCrossReference/assetUnitSubTypeCrossReference',
                            },
                        },
                        {
                            genevaMenu: {
                                id: '52',
                                uniqueKey: 52,
                                title: 'Asset Event Type Cross-Reference',
                                component_path: '/dashboard/assetUnitCrossReference/assetEventTypeCrossReference',
                            },
                        },
                    ],
                },
            ],
            expanded: false,
        },
    ],
};
export function getProgramsMenu() {
    return programsMenu;
}
