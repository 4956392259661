import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { StyledPanelRow, StyledPanelRowLine, StyledPanelRowLineTitle, StyledPanelRowLineWrapper, StyledUnexpectedLabel, } from './PanelRow.styles';
import { StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
var getTitle = function (type) {
    switch (type) {
        case 'on':
            return 'On';
        case 'off':
            return 'Off';
        default:
            return 'Derate';
    }
};
export var PanelRow = function (_a) {
    var lines = _a.lines, hideExpected = _a.hideExpected;
    return (_jsx(StyledPanelRow, { children: lines.map(function (_a, i) {
            var days = _a.days, type = _a.type, _b = _a.unexpected, unexpected = _b === void 0 ? false : _b;
            return (_jsx(StyledPanelRowLineWrapper, { children: _jsxs(_Fragment, { children: [_jsx(StyledPanelRowLineTitle, __assign({ unexpected: unexpected, hide: hideExpected }, { children: _jsxs(StyledLabel, __assign({ size: "small" }, { children: [getTitle(type), unexpected && _jsx(StyledUnexpectedLabel, { children: " (Unexpected)" })] })) })), _jsx(StyledPanelRowLine, { type: type, hide: hideExpected, unexpected: unexpected, days: days })] }) }, i));
        }) }));
};
