/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { ReactElement, useContext, useRef } from 'react';

import { SubMenu as RCSubMenu, SubMenuProps } from 'rc-menu';

import { MENU_CLASS } from '../../../utils/constants';
import { ChevronSmallDown } from '../../Icon/components';
import MenuContext from '../MenuContext';
import MenuLabel from './MenuLabel';

export interface ISubMenuProps extends SubMenuProps {
  level?: number;
}

const Title = ({ title, itemIcon, level }: ISubMenuProps): ReactElement => {
  const titleRef = useRef<HTMLDivElement>(null);

  // const handleOnClick: MouseEventHandler<HTMLDivElement> = () => {
  //   console.log('sub-menu press');
  // };

  return (
    <div ref={titleRef} className={`${MENU_CLASS}-submenu-title-content`}>
      <MenuLabel level={level} parentRef={titleRef} itemIcon={itemIcon}>
        {title}
      </MenuLabel>
      <ChevronSmallDown className={`${MENU_CLASS}-submenu-expand-icon`} />
    </div>
  );
};

const SubMenu = ({ title, itemIcon, level, ...rest }: ISubMenuProps): ReactElement => {
  const { mode } = useContext(MenuContext);
  const popupOffset = mode === 'horizontal' ? [0, 4] : [8, 0];

  return (
    <RCSubMenu popupOffset={popupOffset} title={<Title title={title} level={level} itemIcon={itemIcon} />} {...rest} />
  );
};

export default SubMenu;
