import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import { useApiContext } from './apiContext';
var Option = Select.Option;
var RegionalHierarchiesTab = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState([]), dataSource = _b[0], setDataSource = _b[1];
    var hierarchyData = apiParams.addEditAssetConfigData.regionHierarchyDetails;
    var assetKey = apiParams.addEditAssetConfigData.assetDetails.assetKey;
    var handleRegionChange = function (val, hKey, hElementKey) {
        var newParams = __assign({}, apiParams);
        var selectedRegion = apiParams.regions
            .find(function (reg) { return reg[0].hierarchyKey === hKey; })
            .find(function (r) { return r.hierarchyElementNum === val; });
        var indexToEdit = hierarchyData.findIndex(function (data) { return data.hierarchyKey === selectedRegion.hierarchyKey; });
        if (indexToEdit !== -1) {
            //edit
            var editedHierarchy = {
                parentElementKey: selectedRegion.id,
                hierarchyKey: selectedRegion.hierarchyKey,
                assetKey: assetKey,
                isEdited: indexToEdit < apiParams.originalAssetConfigData.regionHierarchyDetails.length,
                hierarchyElementNm: selectedRegion.hierarchyElementNm,
                hierarchyElementNum: selectedRegion.hierarchyElementNum,
                hierarchyElementKey: hElementKey,
            };
            newParams.addEditAssetConfigData.regionHierarchyDetails[indexToEdit] = editedHierarchy;
        }
        else {
            // add
            var newHierarchy = {
                parentElementKey: selectedRegion.id,
                hierarchyKey: selectedRegion.hierarchyKey,
                isEdited: false,
                hierarchyElementNm: selectedRegion.hierarchyElementNm,
                hierarchyElementNum: selectedRegion.hierarchyElementNum,
            };
            newParams.addEditAssetConfigData.regionHierarchyDetails.push(newHierarchy);
        }
        var dataSourceIndex = dataSource.findIndex(function (data) { return data.id === hKey; });
        var newDataSource = dataSource.slice();
        newDataSource[dataSourceIndex].hierarchyElementNm = selectedRegion.hierarchyElementNm;
        newDataSource[dataSourceIndex].hierarchyElementNum = selectedRegion.hierarchyElementNum;
        setDataSource(newDataSource);
        setApiParams(newParams);
    };
    var getRegionName = function (hkey, hElKey) {
        var _a;
        var regions = apiParams.regions.filter(function (region) { return region[0].hierarchyKey === hkey; })[0];
        var name = (_a = regions.find(function (region) { return region.id === hElKey; })) === null || _a === void 0 ? void 0 : _a.hierarchyElementNm;
        return name ? name : null;
    };
    useEffect(function () {
        var dataSource = [];
        apiParams.hierarchies.forEach(function (hierarchy) {
            var data = hierarchyData.filter(function (data) { return data.hierarchyKey === hierarchy.id; })[0];
            var obj = {
                hierarchyNm: hierarchy.hierarchyNm,
                id: hierarchy.id,
                hierarchyElementNm: data ? getRegionName(hierarchy.id, data.parentElementKey) : null,
                hierarchyElementNum: data ? data.hierarchyElementNum : null,
                hierarchyElementKey: data ? data.hierarchyElementKey : null,
            };
            dataSource.push(obj);
        });
        setDataSource(dataSource);
    }, [apiParams.regions.length]);
    var filterSelect = function (input, option) {
        if (option !== undefined)
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        else
            return false;
    };
    var columns = [
        { title: 'Hierarchy', dataIndex: 'hierarchyNm' },
        {
            title: 'Regions',
            dataIndex: 'hierarchyElementNm',
            render: function (name, record) {
                return (_jsx(Select, __assign({ "data-testid": "select-id-".concat(record.id), showSearch: true, optionFilterProp: "children", filterOption: filterSelect, style: { width: 300 }, onChange: function (val) { return handleRegionChange(parseInt(val), record.id, record.hierarchyElementKey); }, placeholder: "Select Region", value: name }, { children: apiParams.regions.map(function (reg) {
                        if (reg[0].hierarchyKey === record.id) {
                            return reg.map(function (r, i) { return (_jsx(Option, __assign({ value: r.hierarchyElementNum }, { children: r.hierarchyElementNm }), i)); });
                        }
                    }) })));
            },
        },
    ];
    return (_jsx("div", { children: _jsx(Table, { loading: !dataSource.length, columns: columns, dataSource: dataSource, pagination: false }) }));
};
export default RegionalHierarchiesTab;
