import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Reclassification from './reclassification';
import { useApiContext } from './ApiContext';
/*
 * This is parent component for Reclassification
 * It holds the Sitemap icon and the Reclasification
 * pop up component
 */
export var ReclassificationWindow = function (_a) {
    var setEnableReclassification = _a.setEnableReclassification, enableReclassification = _a.enableReclassification, incrementalCapacity = _a.incrementalCapacity, uomCode = _a.uomCode, capacityId = _a.capacityId;
    var apiParams = useApiContext()[0];
    return (_jsxs("div", __assign({ style: { float: 'left' } }, { children: [enableReclassification && _jsx("div", { className: "k-overlay", style: { position: 'absolute', zIndex: 9999 } }), enableReclassification && (_jsx(Reclassification, { onReallocationClose: setEnableReclassification, xcapacity: incrementalCapacity, assetUnits: apiParams.reclassificationData, uomCode: uomCode, dataProviders: apiParams['DataProviders/Capacity'], data: apiParams.reclassifiedData, capacityId: capacityId, selectedUnit: apiParams.unit[0], setEnableReclassification: setEnableReclassification }))] })));
};
export default ReclassificationWindow;
