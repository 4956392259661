import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, THead, TBody, TD, TH, TR } from '@sede-x/shell-ds-react-framework';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTheme } from 'styled-components';
import { StyledQuantity } from '../CapacityActionsModal.styles';
import { renderCapacity } from '../CapacityActions.config';
import { Loader } from 'components/loader';
import { DivWithEllipsis } from 'pages/capacity/Capacity.hooks';
import { renderDateColumn } from 'common/utils/renderDateColumn.util';
var stickyWidth = [0, 114, 242];
var getStickyStyles = function (index, stickyBackground, type) {
    if (index < 3) {
        return {
            position: 'sticky',
            zIndex: 1,
            left: stickyWidth[index],
            background: stickyBackground,
        };
    }
    if (type === 'header') {
        return {
            background: stickyBackground,
        };
    }
};
var relatedCapacityColumns = [
    {
        header: 'Start date',
        accessorKey: 'startDate',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: renderDateColumn(info.getValue()) }); },
    },
    {
        header: 'Asset',
        accessorKey: 'asset',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Asset key',
        accessorKey: 'assetKey',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Inc. capacity',
        cell: function (info) { return (_jsx(DivWithEllipsis, { children: renderCapacity(info.row.original.capacityOrigQty, info.row.original) })); },
    },
    {
        header: 'Total cap.',
        cell: function (info) {
            return info.row.original.totalCapacity !== null ? (_jsxs(DivWithEllipsis, { children: [info.row.original.totalCapacity.toFixed(1), ' ', _jsx(StyledQuantity, { children: info.row.original.measureUnitCd })] })) : ('');
        },
    },
    {
        header: 'Company',
        accessorKey: 'company',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Company key',
        accessorKey: 'companyKey',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Unit name',
        accessorKey: 'unit',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Unit key',
        accessorKey: 'unitKey',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Provider',
        accessorKey: 'source',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Prov. asset ID',
        accessorKey: 'providerAssetId',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Prov. unit ID',
        accessorKey: 'providerAssetUnitId',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Prov. capacity',
        accessorKey: 'providerAssetCapacityQty',
        cell: function (info) {
            var _a;
            return (_jsx(DivWithEllipsis, { children: info.row.original.providerAssetCapacityQty !== null ? (_jsxs(_Fragment, { children: [(_a = info.row.original.providerAssetCapacityQty) === null || _a === void 0 ? void 0 : _a.toFixed(1), ' ', _jsx(StyledQuantity, { children: info.row.original.measureUnitCd })] })) : ('') }));
        },
    },
    {
        header: 'Type',
        accessorKey: 'versionType',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Probability',
        accessorKey: 'probability',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Basis',
        accessorKey: 'capacityBasisType',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Status',
        accessorKey: 'status',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: info.getValue() }); },
    },
    {
        header: 'Last update',
        accessorKey: 'updateDate',
        cell: function (info) { return _jsx(DivWithEllipsis, { children: renderDateColumn(info.getValue()) }); },
    },
];
export var RelatedCapacityEvents = function (_a) {
    var data = _a.data, isFetching = _a.isFetching;
    var table = useReactTable({
        data: data,
        columns: relatedCapacityColumns,
        getCoreRowModel: getCoreRowModel(),
    });
    var theme = useTheme();
    var stickyBackground = theme.background.surface;
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx("div", __assign({ style: { paddingTop: '10px' } }, { children: _jsx(Loader, { message: "Fetching related events..." }) }))), !isFetching && (_jsx("div", __assign({ style: {
                    position: 'relative',
                    maxHeight: '30vh',
                    overflowY: 'auto',
                } }, { children: _jsxs(Table, { children: [_jsx(THead, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header, index) { return (_jsx(TH, __assign({ style: getStickyStyles(index, stickyBackground, 'header') }, { children: header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext()) }), header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) { return (_jsx(TR, __assign({ style: { cursor: 'pointer' } }, { children: row.getVisibleCells().map(function (cell, index) { return (_jsx(TD, __assign({ style: getStickyStyles(index, stickyBackground) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id)); }) }), row.id)); }) })] }) })))] }));
};
