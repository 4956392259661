import React, { ReactElement } from 'react';

import { Check, ChevronSmallDown, Cross } from '../../../Icon/components';

export const ArrowIcon = (): ReactElement => <ChevronSmallDown width={24} height={24} />;

export const SelectedIcon = (): ReactElement => <Check width={24} height={24} />;

export const ClearIcon = (): ReactElement => <Cross width={20} height={20} />;

export const RemoveIcon = (): ReactElement => <Cross width={18} height={18} />;
