import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';
var MySelect = function (props) {
    if (props.allowSelectAll) {
        return (_jsx(ReactSelect, __assign({}, props, { options: __spreadArray([props.allOption], props.options, true), onChange: function (selected, event) {
                if (selected !== null && selected.length > 0) {
                    if (selected[selected.length - 1].value === props.allOption.value) {
                        return props.onChange(__spreadArray([props.allOption], props.options, true));
                    }
                    var result = [];
                    if (selected.length === props.options.length) {
                        if (selected.includes(props.allOption)) {
                            result = selected.filter(function (option) { return option.value !== props.allOption.value; });
                        }
                        else if (event.action === 'select-option') {
                            result = __spreadArray([props.allOption], props.options, true);
                        }
                        return props.onChange(result);
                    }
                }
                return props.onChange(selected);
            } })));
    }
    return _jsx(ReactSelect, __assign({}, props));
};
MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
};
MySelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*',
    },
};
export default MySelect;
