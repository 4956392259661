import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RadioButton, Table, THead, TH, TR, TD, TBody } from '@sede-x/shell-ds-react-framework';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTheme } from 'styled-components';
import { useGetEventLinksQuery } from 'common/ducks/maintenanceApis';
import { useAppDispatch, useAppSelector } from 'common/hooks/store';
import { columns } from 'components/event-summary-actions-modal/EventsSummaryActionsModal.config';
import { StyledActionsContainer } from 'components/event-summary-actions-modal/EventsSummaryActionsModal.styles';
import { Loader } from 'components/loader';
import { selectActionModals, setModalSelectedItemKey } from 'pages/maintenance-event-summary/ducks/slice';
var getColumnsWithRadioBtn = function (_a) {
    var columns = _a.columns, selectedItemKey = _a.selectedItemKey;
    return __spreadArray([
        {
            header: '',
            accessorKey: 'assetUnitEventKey',
            cell: function (info) { return _jsx(RadioButton, { checked: selectedItemKey === +info.getValue(), name: "T1", value: info.getValue() }); },
        }
    ], columns, true);
};
export var LinkEventsModal = function (_a) {
    var skip = _a.skip;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var _b = useAppSelector(selectActionModals), unitKey = _b.unitKey, selectedItemKey = _b.selectedItemKey;
    var _c = useGetEventLinksQuery(unitKey, { skip: skip }), data = _c.data, isFetching = _c.isFetching;
    var filterByBaselined = (data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.autoBaselineEvent; })) || [];
    var getColumns = columns({ shortKeyType: true });
    var columnsWithRadioBtn = getColumnsWithRadioBtn({ columns: getColumns, selectedItemKey: selectedItemKey });
    var table = useReactTable({
        data: filterByBaselined || [],
        columns: columnsWithRadioBtn,
        getCoreRowModel: getCoreRowModel(),
    });
    if (isFetching) {
        return (_jsx(StyledActionsContainer, { children: _jsx(Loader, { message: "Fetching events to link..." }) }));
    }
    return (_jsx(StyledActionsContainer, { children: _jsx("div", __assign({ style: {
                position: 'relative',
                maxHeight: '30vh',
                overflowY: 'auto',
            } }, { children: _jsxs(Table, { children: [_jsx(THead, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header) { return (_jsx(TH, __assign({ style: { background: theme.background.raised } }, { children: header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext()) }), header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) { return (_jsx(TR, __assign({ style: { cursor: 'pointer' }, onClick: function () { return dispatch(setModalSelectedItemKey(row.original.assetUnitEventKey)); } }, { children: row.getVisibleCells().map(function (cell) { return (_jsx(TD, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)); }) }), row.id)); }) })] }) })) }));
};
