import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { useTheme } from 'styled-components';

import { AVATAR_CLASS } from '../../utils/constants';
import PersonSolid from '../Icon/components/PersonSolid';
import { altDefaultText, avatarConsts } from './Avatar.consts';
import * as Styled from './Avatar.styles';
import { IAvatarProps, IAvatarNoImageCircle, IAvatarAsText, IAvatarAsIcon, IAvatarAsImage } from './Avatar.types';
import Button from '../Button/Button';
import { ChevronDown } from '../Icon/components';
import Popover from '../Popover/Popover';
import { Sizes, Variants } from '../../types/types';

const NoImageCircle = ({ size, ariaLabel, children }: IAvatarNoImageCircle) => (
  <Styled.NoImageCircleWrapper size={size}>
    <Styled.NoImageCircle aria-label={ariaLabel}>{children}</Styled.NoImageCircle>
  </Styled.NoImageCircleWrapper>
);

const AsAbbreviation = ({ abbreviation, size, ariaLabel }: IAvatarAsText) => (
  <NoImageCircle size={size} ariaLabel={ariaLabel}>
    {abbreviation}
  </NoImageCircle>
);

const AsIcon = ({ size, ariaLabel }: IAvatarAsIcon) => {
  const theme = useTheme();
  const iconSize = avatarConsts[size].icon;

  return (
    <NoImageCircle size={size}>
      <PersonSolid width={iconSize} height={iconSize} aria-label={ariaLabel} fill={theme.text.onFocus.strong} />
    </NoImageCircle>
  );
};

const AsImage = ({ src, alt }: IAvatarAsImage) => <Styled.Image src={src} alt={alt} />;

const Avatar = ({
  className,
  size = Sizes.Medium,
  src,
  alt = altDefaultText,
  abbreviation,
  label,
  secondaryLabel,
  additionalText,
  dropdownOverlay,
  collapsed = false,
  ...rest
}: IAvatarProps): ReactElement => {
  const innerSecondaryLabel = secondaryLabel || additionalText;

  const content = (
    <Styled.AvatarWrapper className={classNames(AVATAR_CLASS, className)} {...rest}>
      <Styled.Avatar size={size}>
        {!!src && <AsImage size={size} src={src} alt={alt} />}
        {!src &&
          (abbreviation ? (
            <AsAbbreviation abbreviation={abbreviation} size={size} ariaLabel={rest['aria-label']} />
          ) : (
            <AsIcon size={size} ariaLabel={rest['aria-label']} />
          ))}
      </Styled.Avatar>
      {(!!label || !!innerSecondaryLabel) && (
        <Styled.TextBox collapsed={collapsed}>
          {!!label && (
            <Styled.Label size={size} secondaryLabel={innerSecondaryLabel}>
              {label}
            </Styled.Label>
          )}
          {!!innerSecondaryLabel && <Styled.AdditionalText size={size}>{innerSecondaryLabel}</Styled.AdditionalText>}
        </Styled.TextBox>
      )}
      {!collapsed && !!dropdownOverlay && (
        <Button
          size={Sizes.Small}
          variant={Variants.Transparent}
          icon={<ChevronDown width={16} />}
          iconOnly
          aria-label="View profile and more"
        />
      )}
    </Styled.AvatarWrapper>
  );

  return (
    <>
      {dropdownOverlay ? (
        <Popover popup={dropdownOverlay} popupPlacement="topLeft">
          {content}
        </Popover>
      ) : (
        <>{content}</>
      )}
    </>
  );
};

export default Avatar;
