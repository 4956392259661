import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext } from 'react';
import Moment from 'moment';
import { ApiContext } from './apiContext';
var AssetUnitHistoryTab = function () {
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    return apiParams.addEditAssetUnitConfigData.assetUnitHistory &&
        apiParams.addEditAssetUnitConfigData.assetUnitHistory.length > 0 ? (_jsx("div", __assign({ style: { height: '560px', overflowX: 'hidden', overflowY: 'auto' } }, { children: apiParams.addEditAssetUnitConfigData.assetUnitHistory.map(function (history, index) {
            return (_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsx("div", __assign({ className: "px-3 py-1 h-container bd-tb mt-2" }, { children: _jsxs("table", __assign({ className: "w-100" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Updated Date" }), _jsx("th", { children: "Start Date" }), _jsx("th", { children: "Close Date" }), _jsx("th", { children: "Unit Subtype" }), _jsx("th", { children: "Source" })] }) }), _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: history.versionEffectiveDt
                                                    ? Moment(history.versionEffectiveDt.split(' ')[0]).format('DD MMM YYYY')
                                                    : null }), _jsx("td", { children: history.startDate ? Moment(history.startDate.split(' ')[0]).format('DD MMM YYYY') : null }), _jsx("td", { children: history.endDate ? Moment(history.endDate.split(' ')[0]).format('DD MMM YYYY') : null }), _jsx("td", { children: history.assetUnitSubTypeCd }), _jsx("td", { children: history.sourceTypeCd })] }) })] })) })), _jsx("div", __assign({ className: "p-3", style: { minHeight: '50px' } }, { children: _jsx("p", __assign({ className: "m-0" }, { children: history.comments ? history.comments : 'No comments provided.' })) })), _jsxs("div", __assign({ className: "p-2 version-border text-gray" }, { children: [_jsxs("span", __assign({ className: "px-3" }, { children: [_jsx("i", { className: "fa fa-link", "aria-hidden": "true" }), _jsx("span", __assign({ className: "px-2" }, { children: history.url ? history.url : 'URL not found' }))] })), _jsx("span", __assign({ className: "px-3" }, { children: _jsxs("span", { children: [_jsx("i", { className: "fa fa-picture-o", "aria-hidden": "true" }), _jsx("span", __assign({ className: "px-2" }, { children: history.fileName ? history.fileName : 'No attachment' }))] }) })), _jsxs("span", __assign({ className: "px-3 pull-right" }, { children: [_jsx("i", { className: "fa fa-user-circle-o", "aria-hidden": "true" }), _jsx("span", __assign({ className: "px-2" }, { children: history.userName ? history.userName : '' }))] }))] }))] }), index));
        }) }))) : (_jsx("div", __assign({ style: {
            border: '1px solid #595959',
            padding: '10px',
            borderRadius: '4px',
            position: 'absolute',
            color: '#595959',
            fontFamily: 'ShellLight',
            left: '36%',
            top: '46%',
        } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No History available to display." })) })));
};
export default AssetUnitHistoryTab;
