import { __assign, __extends } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import { promoteToBaseline } from '../../../../../services/urlService';
import Spinner from '../../../../../commons/spinner';
import { LastUpdatedButton } from '../../../../../commons/LastUpdatedButton';
var PromoteToBaselineButton = /** @class */ (function (_super) {
    __extends(PromoteToBaselineButton, _super);
    function PromoteToBaselineButton(props) {
        var _this = _super.call(this, props) || this;
        _this.anchor = null;
        _this.anchorAlign = {
            horizontal: 'center',
            vertical: 'bottom',
        };
        _this.popupAlign = {
            horizontal: 'center',
            vertical: 'top',
        };
        _this.handleScroll = function () {
            if (_this.state.show)
                _this.setState({ show: false });
        };
        _this.handleClickOutside = function (event) {
            var domNode = ReactDOM.findDOMNode(_this);
            if (!domNode || !domNode.contains(event.target)) {
                _this.setState({
                    show: false,
                });
            }
        };
        _this.onProviderDataClick = function () {
            _this.setState({
                show: !_this.state.show,
            });
        };
        _this.onPromoteToBaselineClick = function () {
            _this.setState({
                show: false,
                spinner: true,
            }, function () {
                promoteToBaseline(_this.props.capacityKey, _this.props.capacityVersionKey, 'assetUnitCapacity').then(function (response) {
                    if (response !== undefined && response.status === 200) {
                        _this.setState({
                            spinner: false,
                        }, function () {
                            _this.props.loadPromotedData(3, _this.props.assetId); // 3 -> id for Unit Pivot
                        });
                    }
                    else {
                        _this.setState({
                            spinner: false,
                        });
                    }
                });
            });
        };
        _this.state = {
            show: false,
            spinner: false,
        };
        return _this;
    }
    PromoteToBaselineButton.prototype.componentDidMount = function () {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('scroll', this.handleScroll, true);
    };
    PromoteToBaselineButton.prototype.componentWillUnmount = function () {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('scroll', this.handleScroll, true);
    };
    PromoteToBaselineButton.prototype.render = function () {
        var _this = this;
        return (_jsxs(_Fragment, { children: [this.state.spinner && _jsx(Spinner, {}), _jsx(LastUpdatedButton, { className: this.props.limitClass, highlight: this.props.highlight, onClick: this.onProviderDataClick, capacityQty: this.props.capacityQty, capacityUom: this.props.capacityUom, ref: function (button) {
                        _this.anchor = button;
                    } }), _jsx(Popup, __assign({ anchor: this.anchor, anchorAlign: this.anchorAlign, popupAlign: this.popupAlign, collision: {
                        vertical: 'fit',
                    }, show: this.state.show, popupClass: "promote-popup" }, { children: _jsx(Button, __assign({ primary: true, onClick: this.onPromoteToBaselineClick }, { children: "Promote to Baseline" })) }))] }));
    };
    return PromoteToBaselineButton;
}(Component));
export default PromoteToBaselineButton;
