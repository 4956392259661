import { emptySplitApi } from './emptySplitApi';
import { resolveParamsObject } from 'common/utils/url/search-params';
export var maintenanceApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getMaintenanceDaily: builder.query({
            query: function (_a) {
                var _b = _a.unitTypeIds, unitTypeIds = _b === void 0 ? [] : _b, _c = _a.unitSubTypeIds, unitSubTypeIds = _c === void 0 ? [] : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _a.companyIds, companyIds = _e === void 0 ? [] : _e, _f = _a.countryIds, countryIds = _f === void 0 ? [] : _f, _g = _a.regionIds, regionIds = _g === void 0 ? [] : _g, _h = _a.assetUnitIds, assetUnitIds = _h === void 0 ? [] : _h, startDate = _a.startDate, endDate = _a.endDate, version = _a.version;
                return ({
                    url: '/Maintenance/MaintenanceDaily',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        assetUnitKeys: assetUnitIds,
                        startDate: [startDate],
                        endDate: [endDate],
                        version: [version],
                    }),
                });
            },
        }),
        getNewMaintenanceEventSummary: builder.query({
            providesTags: ['MaintenanceEventSummary'],
            query: function (_a) {
                var _b = _a.asset_unit_keys, asset_unit_keys = _b === void 0 ? [] : _b, _c = _a.asset_unit_subtype_keys, asset_unit_subtype_keys = _c === void 0 ? [] : _c, _d = _a.asset_unit_type_keys, asset_unit_type_keys = _d === void 0 ? [] : _d, _e = _a.region_keys, region_keys = _e === void 0 ? [] : _e, _f = _a.country_keys, country_keys = _f === void 0 ? [] : _f, _g = _a.owner_keys, owner_keys = _g === void 0 ? [] : _g, _h = _a.asset_keys, asset_keys = _h === void 0 ? [] : _h, event_start_dt = _a.event_start_dt, event_end_dt = _a.event_end_dt, _j = _a.page_number, page_number = _j === void 0 ? 1 : _j;
                return ({
                    url: process.env.REACT_APP_FAST_API_BASE_URL + '/maintenanceSummary',
                    params: resolveParamsObject({
                        asset_unit_keys: asset_unit_keys,
                        asset_unit_subtype_keys: asset_unit_subtype_keys,
                        asset_unit_type_keys: asset_unit_type_keys,
                        region_keys: region_keys,
                        country_keys: country_keys,
                        owner_keys: owner_keys,
                        asset_keys: asset_keys,
                        event_start_dt: [event_start_dt],
                        event_end_dt: [event_end_dt],
                        page_number: [page_number - 1],
                    }),
                });
            },
        }),
        getMaintenanceEventSummary: builder.query({
            providesTags: ['MaintenanceEventSummary'],
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 20 : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _a.assetUnitIds, assetUnitIds = _e === void 0 ? [] : _e, _f = _a.companyIds, companyIds = _f === void 0 ? [] : _f, _g = _a.countryIds, countryIds = _g === void 0 ? [] : _g, _h = _a.regionIds, regionIds = _h === void 0 ? [] : _h, _j = _a.unitSubTypeIds, unitSubTypeIds = _j === void 0 ? [] : _j, _k = _a.unitTypeIds, unitTypeIds = _k === void 0 ? [] : _k, version = _a.version, startDate = _a.startDate, endDate = _a.endDate, _l = _a.orderBy, orderBy = _l === void 0 ? '' : _l;
                return ({
                    url: '/v2/Maintenance/MaintenanceEventSummary',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        unit: assetUnitIds,
                        versionDate: [version],
                        pageNumber: [page],
                        pageSize: [pageSize],
                        startDate: [startDate],
                        endDate: [endDate],
                        orderBy: [orderBy],
                    }),
                });
            },
        }),
        getAllMaintenanceEvents: builder.query({
            providesTags: ['MaintenanceAllEvents'],
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 20 : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, _e = _a.assetUnitIds, assetUnitIds = _e === void 0 ? [] : _e, _f = _a.companyIds, companyIds = _f === void 0 ? [] : _f, _g = _a.countryIds, countryIds = _g === void 0 ? [] : _g, _h = _a.regionIds, regionIds = _h === void 0 ? [] : _h, _j = _a.unitSubTypeIds, unitSubTypeIds = _j === void 0 ? [] : _j, _k = _a.unitTypeIds, unitTypeIds = _k === void 0 ? [] : _k, startDate = _a.startDate, endDate = _a.endDate, _l = _a.orderBy, orderBy = _l === void 0 ? '' : _l;
                return ({
                    url: '/v2/Maintenance/MaintenanceAllEvents',
                    params: resolveParamsObject({
                        re: regionIds,
                        co: countryIds,
                        o: companyIds,
                        a: assetIds,
                        ut: unitTypeIds,
                        ust: unitSubTypeIds,
                        unit: assetUnitIds,
                        pageNumber: [page],
                        pageSize: [pageSize],
                        startDate: [startDate],
                        endDate: [endDate],
                        orderBy: [orderBy],
                    }),
                });
            },
        }),
        getMaintenanceGroupEvent: builder.query({
            providesTags: ['GroupedEvents'],
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 20 : _c, _d = _a.assetIds, assetIds = _d === void 0 ? [] : _d, version = _a.version, startDate = _a.startDate, endDate = _a.endDate, eventStartDate = _a.eventStartDate, eventEndDate = _a.eventEndDate, _e = _a.orderBy, orderBy = _e === void 0 ? '' : _e;
                return ({
                    url: '/v2/Maintenance/MaintenanceGroupEvent',
                    params: resolveParamsObject({
                        a: assetIds,
                        versionDate: [version],
                        pageNumber: [page],
                        pageSize: [pageSize],
                        startDate: [startDate],
                        endDate: [endDate],
                        eventStartDate: [eventStartDate],
                        eventEndDate: [eventEndDate],
                        orderBy: [orderBy],
                    }),
                });
            },
        }),
        getAssetClasses: builder.query({
            query: function () { return '/AssetClasses'; },
        }),
        getEventCauseTypes: builder.query({
            query: function () { return ({
                url: '/EventCauseTypes',
            }); },
        }),
        getAssetEventTypes: builder.query({
            query: function () { return ({
                url: '/AssetEventTypes',
            }); },
        }),
        getAssetOutageGroup: builder.query({
            query: function () { return ({
                url: '/AssetOutageGroup',
            }); },
        }),
        saveAssetUnitEvent: builder.mutation({
            invalidatesTags: function (_, error) {
                return error ? [] : ['MaintenanceEventSummary', 'Versions', 'GroupedEvents', 'MaintenanceAllEvents'];
            },
            query: function (body) { return ({
                url: '/AssetUnitEvents',
                method: 'post',
                body: body,
            }); },
        }),
        updateAssetUnitEvent: builder.mutation({
            invalidatesTags: function (_, error) {
                return error ? [] : ['MaintenanceEventSummary', 'Versions', 'GroupedEvents', 'MaintenanceAllEvents'];
            },
            query: function (body) { return ({
                url: '/AssetUnitEvents',
                method: 'put',
                body: body,
            }); },
        }),
        getVersions: builder.query({
            providesTags: ['Versions'],
            query: function (eventId) { return ({
                url: "/AssetUnitEvents/".concat(eventId, "/versions"),
            }); },
        }),
        getEventLinks: builder.query({
            query: function (eventId) { return ({
                url: "/AssetUnitEvents/".concat(eventId),
            }); },
        }),
        getEventOriginals: builder.query({
            query: function (eventId) { return ({
                url: "/AssetUnitEvents/".concat(eventId, "/originals"),
            }); },
        }),
        updateEventOriginals: builder.mutation({
            query: function (_a) {
                var assetUnitEventKey = _a.assetUnitEventKey, selectedItemKey = _a.selectedItemKey;
                return ({
                    url: "/AssetUnitEvents/".concat(assetUnitEventKey, "/").concat(selectedItemKey),
                    method: 'put',
                });
            },
        }),
        updateEventLinks: builder.mutation({
            query: function (_a) {
                var assetUnitEventKey = _a.assetUnitEventKey, selectedItemKey = _a.selectedItemKey;
                return ({
                    url: "/AssetUnitEvents/".concat(assetUnitEventKey, "/").concat(selectedItemKey, "/maintenanceLinking"),
                    method: 'put',
                });
            },
        }),
        getLastMaintenanceUpdates: builder.query({
            query: function () { return '/ApplicationInfo/LastMaintenanceUpdates'; },
        }),
        getBaselineEventsHavingOriginals: builder.query({
            query: function (_a) {
                var event_start_dt = _a.event_start_dt, event_end_dt = _a.event_end_dt;
                return ({
                    url: process.env.REACT_APP_FAST_API_BASE_URL + '/maintenanceSummary/baselineEventsHavingOriginals',
                    params: {
                        event_start_dt: event_start_dt,
                        event_end_dt: event_end_dt,
                    },
                });
            },
        }),
    }); },
});
export var useGetMaintenanceDailyQuery = maintenanceApis.useGetMaintenanceDailyQuery, useGetMaintenanceEventSummaryQuery = maintenanceApis.useGetMaintenanceEventSummaryQuery, useLazyGetMaintenanceEventSummaryQuery = maintenanceApis.useLazyGetMaintenanceEventSummaryQuery, useLazyGetMaintenanceGroupEventQuery = maintenanceApis.useLazyGetMaintenanceGroupEventQuery, useGetEventCauseTypesQuery = maintenanceApis.useGetEventCauseTypesQuery, useGetAssetEventTypesQuery = maintenanceApis.useGetAssetEventTypesQuery, useSaveAssetUnitEventMutation = maintenanceApis.useSaveAssetUnitEventMutation, useUpdateAssetUnitEventMutation = maintenanceApis.useUpdateAssetUnitEventMutation, useGetVersionsQuery = maintenanceApis.useGetVersionsQuery, useGetEventLinksQuery = maintenanceApis.useGetEventLinksQuery, useGetEventOriginalsQuery = maintenanceApis.useGetEventOriginalsQuery, useUpdateEventOriginalsMutation = maintenanceApis.useUpdateEventOriginalsMutation, useUpdateEventLinksMutation = maintenanceApis.useUpdateEventLinksMutation, useGetAssetClassesQuery = maintenanceApis.useGetAssetClassesQuery, useLazyGetAllMaintenanceEventsQuery = maintenanceApis.useLazyGetAllMaintenanceEventsQuery, useGetLastMaintenanceUpdatesQuery = maintenanceApis.useGetLastMaintenanceUpdatesQuery, useGetAssetOutageGroupQuery = maintenanceApis.useGetAssetOutageGroupQuery, useGetNewMaintenanceEventSummaryQuery = maintenanceApis.useGetNewMaintenanceEventSummaryQuery, useGetBaselineEventsHavingOriginalsQuery = maintenanceApis.useGetBaselineEventsHavingOriginalsQuery;
