import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import TotalCpacityBtnGroup from './totalCpacityBtnGroup';
import Toggleicon from './toggleicon';
// import _ from "lodash";
import { If } from 'rc-if-else';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import TotalCapacityAssetUnit from '../totalCapacityAssetUnit';
import TotalCapacityAssetUnitSubtype from '../totalCapacityAssetUnitSubtype';
import TotalCapacityAssetUnitType from '../totalCapacityAssetUnitType';
import totalCapacityAssetUnitSubtype from '../json/totalCapacityAssetUnitSubtype.json';
import totalCapacityAssetUnitType from '../json/totalCapacityAssetUnitType.json';
import { getSourceFilter } from '../../../../../../services/urlService';
var totalCapacityAccordion = /** @class */ (function (_super) {
    __extends(totalCapacityAccordion, _super);
    function totalCapacityAccordion(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.doUnitToggle = function (id, randomNo, assetKey) {
            _this.props.setButtonGroupState(assetKey, id);
            switch (parseInt(id)) {
                case 1:
                    _this.setState({
                        unitTpeOn: true,
                        subUnitOn: false,
                        unitOn: false,
                        currentRandKey: randomNo,
                    });
                    break;
                case 2:
                    _this.setState({
                        unitTpeOn: false,
                        subUnitOn: true,
                        unitOn: false,
                        currentRandKey: randomNo,
                    });
                    _this.fetchDataOnSwitch(parseInt(id));
                    break;
                case 3:
                    _this.setState({
                        unitTpeOn: false,
                        subUnitOn: false,
                        unitOn: true,
                        currentRandKey: randomNo,
                    });
                    _this.fetchDataOnSwitch(parseInt(id));
                    break;
                default:
                    break;
            }
        };
        _this.fetchDataOnSwitch = function (selection) {
            var path;
            switch (selection) {
                case 1:
                    path = '';
                    break;
                case 2:
                    path = '';
                    break;
                case 3:
                    path = '';
                    break;
                default:
                    break;
            }
            if (path) {
                getSourceFilter(path)
                    .then(function (filterData) {
                    if (typeof filterData.data !== 'undefined' && typeof filterData !== 'undefined') {
                        if (filterData.data.length > 0) {
                            switch (selection) {
                                case 2:
                                    _this.setState({
                                        subUnit: filterData.data,
                                    });
                                    break;
                                case 3:
                                    _this.setState({
                                        unitType: filterData.data,
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (filterData.data.length === 0) {
                            _this.setState({ nodata: true });
                        }
                    }
                })
                    .catch(function (error) { });
            }
        };
        _this.setStateRefreshOnUpdate = function () {
            _this.setState({
                refreshOnUpdate: _this.getRandom(),
            });
        };
        _this.state = {
            unitTpeOn: false,
            subUnitOn: true,
            unitOn: false,
            currentRandKey: '',
            subUnit: totalCapacityAssetUnitSubtype[0].unitTypeGroups,
            unitType: totalCapacityAssetUnitType[0].unitTypeGroups,
            refreshOnUpdate: false,
        };
        _this.currentView = 2;
        return _this;
    }
    totalCapacityAccordion.prototype.render = function () {
        var _a = this.state, unitTpeOn = _a.unitTpeOn, subUnitOn = _a.subUnitOn, unitOn = _a.unitOn;
        return (_jsx(Accordion, __assign({ defaultActiveKey: this.props.accordionKey }, { children: _jsxs(Card, { children: [_jsx(TotalCpacityBtnGroup, { unitToggle: this.doUnitToggle, assetKey: this.props.assetKey, buttonGroupState: this.props.buttonGroupState }), _jsx(Accordion.Toggle, __assign({ as: Card.Header, eventKey: this.props.accordionKey }, { children: _jsx(Toggleicon, { title: this.props.title }) })), _jsx(Accordion.Collapse, __assign({ eventKey: this.props.accordionKey }, { children: _jsx(Card.Body, { children: _jsxs("div", { children: [_jsx(If, __assign({ condition: unitOn }, { children: _jsx(TotalCapacityAssetUnit, { index: this.props.index, data: this.props.data, assetKey: this.props.assetKey, assetFilterParams: this.props.assetFilterParams, onCapacitySelect: this.props.onCapacitySelect, limitForHighlight: this.props.limitForHighlight, baseProvider: this.props.baseProvider }, this.state.refreshOnUpdate) })), _jsx(If, __assign({ condition: subUnitOn }, { children: _jsx(TotalCapacityAssetUnitSubtype, { index: this.props.index, assetKey: this.props.assetKey, 
                                            //data={this.state.subUnit}
                                            data: this.props.data, assetFilterParams: this.props.assetFilterParams, limitForHighlight: this.props.limitForHighlight, baseProvider: this.props.baseProvider }) })), _jsx(If, __assign({ condition: unitTpeOn }, { children: _jsx(TotalCapacityAssetUnitType, { index: this.props.index, assetKey: this.props.assetKey, data: this.state.subUnit, assetFilterParams: this.props.assetFilterParams, limitForHighlight: this.props.limitForHighlight, baseProvider: this.props.baseProvider }) }))] }, this.props.identifier) }) }))] }) })));
    };
    return totalCapacityAccordion;
}(Component));
export default totalCapacityAccordion;
