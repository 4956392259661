import { combineReducers } from 'redux';
import capacityScreenReducer from './capacityScreenReducer';
import masterScreenReducer from './masterScreenReducer';
import mainFilterActionReducer from './mainFilterActionReducer';
var rootReducer = combineReducers({
    capacityScreenReducer: capacityScreenReducer,
    masterScreenReducer: masterScreenReducer,
    mainFilterActionReducer: mainFilterActionReducer,
});
export default rootReducer;
