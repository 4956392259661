import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Company from './company';
import CompanyCrossReference from './companyCrossReference';
import CompanyRoleTypeCrossReference from './companyRoleTypeCrossReference';
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var companiesReferenceNavLink = /** @class */ (function (_super) {
    __extends(companiesReferenceNavLink, _super);
    function companiesReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    companiesReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsx("div", __assign({ className: "header-title mb-3", style: { display: 'flex', justifyContent: 'space-between' } }, { children: "Companies (Reference Data)" })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/companiesReference/company", className: "nav-link", activeClassName: "active" }, { children: "Company" })), _jsx(NavLink, __assign({ to: "/dashboard/companiesReference/companyRoleTypeCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Company Role Type Cross Reference" })), _jsx(NavLink, __assign({ to: "/dashboard/companiesReference/companyCrossReference", className: "nav-link", activeClassName: "active" }, { children: "Company Cross Reference" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/companiesreference", to: "/dashboard/companiesReference/company", exact: true }), _jsx(Route, { path: "/dashboard/companiesReference/company", component: Company, exact: true }), _jsx(Route, { path: "/dashboard/companiesReference/companyRoleTypeCrossReference", component: CompanyRoleTypeCrossReference, exact: true }), _jsx(Route, { path: "/dashboard/companiesReference/companyCrossReference", component: CompanyCrossReference, exact: true })] }) }))] }))] })) }));
    };
    return companiesReferenceNavLink;
}(Component));
export default companiesReferenceNavLink;
