import { Sizes } from '../../types/types';

type TextAreaWrapperConstsProps = {
  iconSize: {
    [Sizes.Small]: string;
    [Sizes.Medium]: string;
    [Sizes.Large]: string;
  };
  iconOffset: {
    [Sizes.Small]: string;
    [Sizes.Medium]: string;
    [Sizes.Large]: string;
  };
};

export const textAreaConsts = {
  paddingWithoutIcon: {
    [Sizes.Small]: '4px 10px',
    [Sizes.Medium]: '8px 12px',
    [Sizes.Large]: '12px 16px',
  },
  paddingWithIcon: {
    [Sizes.Small]: {
      left: '4px 10px 4px 32px',
      right: '4px 32px 4px 10px',
    },
    [Sizes.Medium]: {
      left: '8px 12px 8px 40px',
      right: '8px 40px 8px 12px',
    },
    [Sizes.Large]: {
      left: '12px 16px 12px 44px',
      right: '12px 44px 12px 16px',
    },
  },
};

export const textAreaWrapperConsts: TextAreaWrapperConstsProps = {
  iconSize: {
    [Sizes.Small]: '20px',
    [Sizes.Medium]: '24px',
    [Sizes.Large]: '24px',
  },
  iconOffset: {
    [Sizes.Small]: '7px',
    [Sizes.Medium]: '9px',
    [Sizes.Large]: '13px',
  },
};
