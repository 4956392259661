import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup, Sizes, Icons, Label, Button, } from '@sede-x/shell-ds-react-framework';
import { AvatarMenuItem } from './AvatarMenuItem';
var Sun = Icons.Sun, Moon = Icons.Moon, Monitor = Icons.Monitor;
var StyledThemeSwitcher = styled(ButtonGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: transparent;\n"], ["\n  background-color: transparent;\n"])));
export var AvatarMenu = function (_a) {
    var _b;
    var onMenuClick = _a.onMenuClick;
    var history = useHistory();
    var location = useLocation();
    var queryStrings = new URLSearchParams(location.search);
    var selectedTheme = (_b = localStorage.getItem('sdsTheme')) !== null && _b !== void 0 ? _b : 'System';
    var handleThemeChange = function (e) {
        localStorage.setItem('sdsTheme', e);
        queryStrings.delete('theme');
        queryStrings.append('theme', e);
        history.replace({
            search: queryStrings.toString(),
        });
    };
    return (_jsxs("div", __assign({ style: { padding: '0 32px 0 32px' } }, { children: [_jsxs(AvatarMenuItem, { children: [_jsx(Label, __assign({ color: "light" }, { children: "APPEARANCE" })), _jsx(StyledThemeSwitcher, { selectedKey: selectedTheme, size: Sizes.Small, onChange: handleThemeChange, items: [
                            { label: 'Light', icon: _jsx(Sun, {}) },
                            { label: 'Dark', icon: _jsx(Moon, {}) },
                            { label: 'System', icon: _jsx(Monitor, {}) },
                        ] })] }), _jsxs(AvatarMenuItem, { children: [_jsx(Label, __assign({ color: "light" }, { children: "MANAGE SUBSCRIPTIONS" })), _jsx(Button, __assign({ size: 'small', onClick: onMenuClick, style: { width: '70%' } }, { children: "Your subscriptions" }))] })] })));
};
var templateObject_1;
