import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import './reclassification.css';
import Reclassificationfeed from './reclassificationfeed';
import { editSourceData } from '../../../../../services/urlService';
import { toast } from 'react-toastify';
import { useApiContext } from './ApiContext';
/*
 *a generic function to remove the selected unit from the list
 *of asset units filter
 */
function removeSelectedUnit(units, selectedUnit) {
    return units.filter(function (value, index, arr) {
        return value.id !== selectedUnit.id;
    });
}
/*
 * Master screen, on add/edit/delete operation error messages jsx to toast
 */
var Msg = function (props) { return (_jsx("ul", { children: props.error.map(function (value, i) {
        return _jsx("li", { children: props.error[i] }, i);
    }) })); };
/*
 *The generic reclassification component
 */
function Reclassification(props) {
    //list of units that can be allocated
    var _a = useState(removeSelectedUnit(props.assetUnits, props.selectedUnit)), assetUnits = _a[0], setAssetUnits = _a[1];
    //apply button controll
    var _b = useState(false), applyBtn = _b[0], setApplyBtn = _b[1];
    //to show overlay withing the component
    var _c = useState(false), overlayReclassify = _c[0], setOverlayReclassify = _c[1];
    //main data to be sent to the PUT API
    var _d = useState(props.data), reclassifiedData = _d[0], setReclassifiedData = _d[1];
    //the capacity array of non-x-bucket units
    var _e = useState([]), initialCapacityList = _e[0], setInitialCapacityList = _e[1];
    //total x bucket capacity
    var _f = useState(props.xcapacity), xbucketcapacity = _f[0], setXbucketcapacity = _f[1];
    //holds the remaining capacity to be be allocated
    var _g = useState(props.xcapacity), remainingKbd = _g[0], setRemainingKbd = _g[1];
    var _h = useApiContext(), apiParams = _h[0], setApiParams = _h[1];
    // a function to formulate and array of current not X-bucket units
    var formCapacityList = function (index, newitem) {
        if (index > -1 && newitem !== NaN)
            initialCapacityList[index] = Number.isNaN(parseFloat(newitem)) ? 0 : parseFloat(newitem);
        setRemainingKbd(calculateRemainingKbds);
        if (calculateRemainingKbds() > -1) {
            setApplyBtn(false);
        }
        else {
            setApplyBtn(true);
        }
        return initialCapacityList;
    };
    var combinedUnits = [];
    /*
     *function to calculate the the remaining capacity
     *based on the user input text
     */
    var calculateRemainingKbds = function () {
        var currentSum = initialCapacityList.reduce(function (a, b) { return a + b; }, 0);
        return xbucketcapacity - currentSum;
    };
    function togglePopup() {
        props.onReallocationClose(false);
        props.setEnableReclassification(false);
    }
    //passing it as a prop to the feed component
    function remainingcapacityonchange(newValue, index) {
        setApplyBtn(false);
        setInitialCapacityList(formCapacityList(index, newValue));
        //reclassifiedData.units[index] = unit;
        //setReclassifiedData(reclassifiedData);
    }
    function setDataProvider(newValue, index) {
        reclassifiedData.units[index].dataProviderKey = newValue;
        //setReclassifiedData(reclassifiedData);
    }
    /*
     *random key generator function
     */
    function getRandom() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    function reclassify() {
        var allInput = document.querySelectorAll('input.reclass-capacity');
        allInput.forEach(function (x) {
            var unitDefaultObj = {};
            unitDefaultObj['quantity'] = parseFloat(x.getAttribute('data-value'));
            unitDefaultObj['unitName'] = x.getAttribute('data-unit-nm');
            unitDefaultObj['unitId'] = parseFloat(x.getAttribute('data-unit-id'));
            unitDefaultObj['dataProviderKey'] = x.getAttribute('data-provider');
            if (parseFloat(x.getAttribute('data-value')) > 0) {
                combinedUnits.push(unitDefaultObj);
            }
        }, 2000);
        setOverlayReclassify(true);
        reclassifiedData.units = combinedUnits;
        var editReturn = editSourceData(reclassifiedData, props.capacityId, 'aucReallocation').then(function (response) {
            setApiParams(__assign(__assign({}, apiParams), { unitRefreshOnUpdate: getRandom() }));
            setOverlayReclassify(false);
            var reclassificationErrors = response.data.map(function (responseData) {
                if (typeof responseData.isReAllocatedCapacity !== 'undefined' && responseData.message) {
                    return responseData.message;
                }
            });
            reclassificationErrors = reclassificationErrors.filter(function (element) {
                return element !== undefined;
            });
            if (response.status == 400) {
                if (!toast.isActive('reclassification-validation')) {
                    toast.error(_jsx(Msg, { error: ['Exception Could not handle'] }), {
                        autoClose: 8000,
                        toastId: 'reclassification-validation',
                    });
                }
                props.onReallocationClose(false);
            }
            if (reclassificationErrors.length > 0) {
                if (!toast.isActive('reclassification-validation')) {
                    toast.error(_jsx(Msg, { error: reclassificationErrors }), {
                        autoClose: 8000,
                        toastId: 'reclassification-validation',
                    });
                }
            }
        });
    }
    useEffect(function () {
        reclassifiedData.capacityID = props.capacityId;
        reclassifiedData.quantity = xbucketcapacity;
        reclassifiedData.units = [];
        setReclassifiedData(reclassifiedData);
    }, []);
    useEffect(function () {
        setReclassifiedData(reclassifiedData);
    }, [JSON.stringify(reclassifiedData)]);
    return (_jsx("div", __assign({ className: "reclassification-common" }, { children: _jsxs("div", __assign({ className: "reclassification-content" }, { children: [overlayReclassify && _jsx("div", { className: "k-overlay", style: { position: 'absolute' } }), _jsxs("div", __assign({ className: "reclass-head" }, { children: [_jsx("div", __assign({ className: "close-btn" }, { children: _jsx("button", __assign({ onClick: togglePopup }, { children: _jsx("span", { class: "k-icon k-i-close", style: { color: '#7f7f7f' } }) })) })), _jsx("span", __assign({ className: "events-title mb-3" }, { children: "Reclassification" }))] })), _jsxs("div", __assign({ className: "row col-12 m-0 pt-3" }, { children: [_jsx("div", __assign({ className: "col-sm-6 p-2" }, { children: props.selectedUnit.assetUnitNm + ' has ' + parseFloat(props.xcapacity).toFixed(2) + ' ' + props.uomCode })), _jsxs("div", __assign({ className: 
                            //remainingKbd > -1?
                            'col-sm-6 text-right p-2' }, { children: ["Remaining ", parseFloat(remainingKbd).toFixed(2), " ", props.uomCode] }))] })), _jsxs("div", __assign({ className: "grid" }, { children: [_jsxs("div", __assign({ className: "row col-12 m-0 grid-head" }, { children: [_jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: "Unit Name" })), _jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: "Incremental capacity quantity" })), _jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: "Data Provider" }))] })), _jsx("div", __assign({ className: "tr-height" }, { children: assetUnits.map(function (assetUnit, index) {
                                return (_jsx("div", { children: _jsx(Reclassificationfeed, { assetUnit: assetUnit, totalcapacity: assetUnit.totalCapacity, remainingcapacityonchange: remainingcapacityonchange, indexnumber: index, remainingKbd: remainingKbd, initialCapacityList: initialCapacityList, xbucketcapacity: xbucketcapacity, dataProviders: props.dataProviders, data: props.data, setDataProvider: setDataProvider }) }, index));
                            }) }))] })), _jsxs("div", __assign({ className: "reclass-actions" }, { children: [_jsx("button", __assign({ className: "k-button border-1 mr-3 action", onClick: togglePopup }, { children: "Cancel" })), _jsx("button", __assign({ disabled: applyBtn, className: "k-button k-primary", onClick: reclassify }, { children: "Apply" }))] }))] })) })));
}
export default Reclassification;
