import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
var calculateSum = function (theList, indexNmuber) {
    theList[indexNmuber] = 0;
    var summ = theList.reduce(function (a, b) { return a + b; }, 0);
    return summ;
};
function Reclassification(props) {
    var _a = useState(0), capacity = _a[0], setCapacity = _a[1];
    var _b = useState(null), dataProviderValue = _b[0], setDataProviderValue = _b[1];
    var defaultItem = { dataProviderAbbr: 'Select' };
    //the capacity array will default to empty
    var _c = useState([]), capacityArray = _c[0], setCapacityArray = _c[1];
    function onchange(event, indexNumber) {
        if (!isNaN(Number(event.target.value)) && !(event.target.value < 0)) {
            if (event.target.value) {
                setCapacity(parseFloat(event.target.value));
            }
            else {
                setCapacity();
            }
            // unit.quantity = parseInt(event.target.value);
            props.remainingcapacityonchange(parseFloat(event.target.value).toFixed(2), props.indexnumber);
        }
        else {
            event.preventDefault();
        }
    }
    function handleChange(event, index) {
        setDataProviderValue(event.target.value);
        //sunit.dataProviderKey = event.target.value.id;
        //props.setDataProvider(event.target.value.id, index);
    }
    return (_jsx("div", __assign({ className: "container-cl" }, { children: _jsxs("div", __assign({ className: "row col-12 m-0 cl" }, { children: [_jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: props.assetUnit.assetUnitNm })), _jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: _jsx("div", __assign({ className: "border bg-solid rounded position-relative p-2", style: { width: '179px' } }, { children: _jsx("input", { className: "reclass-capacity bg-transparent border-0 shadow-none incremental-capacity-input text-white", type: "number", name: "capacity", id: "capacity", "data-provider": dataProviderValue ? dataProviderValue.id : null, "data-unit-id": props.assetUnit.id, "data-unit-nm": props.assetUnit.assetUnitNm, "data-value": capacity, value: capacity, onChange: function (event) {
                                onchange(event, props.indexnumber);
                            }, min: "0" }) })) })), _jsx("div", __assign({ className: "col-sm-4 p-2" }, { children: _jsx("div", __assign({ className: "border bg-solid rounded", style: { width: '180px' } }, { children: _jsx(DropDownList, { data: props.dataProviders, defaultItem: defaultItem, filterable: false, textField: 'dataProviderAbbr', value: dataProviderValue, onChange: function (event) {
                                handleChange(event, props.indexnumber);
                            } }) })) }))] })) })));
}
export default Reclassification;
