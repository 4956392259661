import { forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { LOADING_CLASS } from '../../utils/constants';
import * as Styled from './Loading.styles';
import { ILoadingProps } from './Loading.types';
import { loadingConsts } from './Loading.consts';
import { Sizes } from '../../types/types';

const Loading = forwardRef<SVGSVGElement, ILoadingProps>(
  ({ size = Sizes.Medium, className, type = 'default', ...rest }, ref): ReactElement => {
    const { viewBoxSize, strokeWidth } = loadingConsts[size];
    const radius = (viewBoxSize - strokeWidth) / 2;
    const cx = radius + strokeWidth / 2;
    const cy = cx;
    const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;

    return (
      <Styled.Loading
        aria-label="Loading spinner"
        className={classNames(LOADING_CLASS, className)}
        size={size}
        viewBox={viewBox}
        ref={ref}
        {...rest}
      >
        <Styled.Background size={size} r={radius} cx={cx} cy={cy} />
        <Styled.Highlighted size={size} type={type} r={radius} cx={cx} cy={cy} />
      </Styled.Loading>
    );
  },
);

export default Loading;
