import { __assign, __makeTemplateObject, __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { AppHeader } from '../app-header';
import { BaseContainer } from 'components/base-container';
var AppContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
export var AppContainer = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsxs(BaseContainer, __assign({}, props, { children: [_jsx(AppHeader, {}), _jsx(AppContent, { children: children })] })));
};
var templateObject_1;
