import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../commons/crossReferenceGridTemplate';
var measureUnit = /** @class */ (function (_super) {
    __extends(measureUnit, _super);
    function measureUnit(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            measureUnitCd: {
                required: true,
                error: 'Enter Measure Unit Cd unique key.',
            },
        };
        var yesNoData = [{ ynValue: 'platformUnitInd' }, { ynValue: 'universalUnitInd' }];
        var foreginKeyParams = [
            {
                path: 'measureUnitType',
                key: 'measureUnitTypeKey',
                cdName: 'measureUnitTypeCd',
            },
        ];
        var columns = [
            {
                field: 'measureUnitCd',
                title: 'Measure Unit Cd',
            },
            {
                field: 'measureUnitDesc',
                title: 'Measure Unit Desc Title',
            },
            {
                field: 'conversionAdditiveFctr',
                title: 'Conversion Additive Factor',
            },
            {
                field: 'conversionDenominatorFctr',
                title: 'Conversion Denominator Factor',
            },
            {
                field: 'conversionNumeratorFctr',
                title: 'Conversion Numerator Factor',
            },
            {
                field: 'platformUnitInd',
                title: 'Platform Unit Ind',
                type: 'yesNo',
                ynRefer: 'platformUnitInd',
                yn: 'yn',
            },
            {
                field: 'universalUnitInd',
                title: 'Universal Unit Ind',
                type: 'yesNo',
                ynRefer: 'universalUnitInd',
                yn: 'yn',
            },
            {
                field: 'measureUnitTypeCd',
                title: 'Measure Unit Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'measureUnitTypeCd',
                referenceTitle: 'Measure Unit Type',
                urlEndPoint: 'measureUnitType',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
            yesNoData: yesNoData,
        };
        return _this;
    }
    measureUnit.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "measureUnit", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "measureUnitCd", foreginKeyParams: this.state.foreginKeyParams, yesNoData: this.state.yesNoData }));
    };
    return measureUnit;
}(Component));
export default measureUnit;
