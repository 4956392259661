import { createContext, useContext } from 'react';

import { ITableProps } from '../Table.types';

export const TableContext = createContext<ITableProps | null>(null);

export const useTable = () => {
  const tabData = useContext(TableContext);

  if (tabData === null) {
    throw Error('A table must have a Table parent');
  }

  return tabData;
};
