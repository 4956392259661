import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { getSourceData, editSourceData, saveSourceData } from '../services/urlService';
import ActionCell from './actionCell';
import GridDropDownList from './gridDropDownList';
import BooleanDropdownList from './booleanDropdownList';
import { process } from '@progress/kendo-data-query';
import PreviousStateFunctionalComponent from './previousStateFunctionalComponent';
import Spinner from './spinner';
import { toast } from 'react-toastify';
/*
 * Master screen, on add/edit/delete operation error messages jsx to toast
 */
var Msg = function (props) { return (_jsx("ul", { children: props.list.map(function (value, i) {
        return _jsx("li", { children: props.error[value] }, i);
    }) })); };
function CrRefGridTemplate(props) {
    /*
     * Hook variable to set ref to the current functional component
     */
    var totalDataInGrid = useRef(0);
    var gridDataRef = useRef(0);
    var previousStateGridData;
    /*
     * Hook constant to set state to the current functional component
     */
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState([]), gridData = _b[0], setGridData = _b[1];
    var _c = useState(true), addNewRecord = _c[0], setAddNewRecord = _c[1];
    //hook to keep showMetadata variable
    var _d = useState(true), showMetadata = _d[0], setShowMetadata = _d[1];
    //hook to keep showChecked variable
    var _e = useState(true), showChecked = _e[0], setShowChecked = _e[1];
    //hook to keep the state of minwidth
    var _f = useState('200px'), minWidth = _f[0], setMinWidth = _f[1];
    //hook to track/handle spinner
    var _g = useState(false), isLoading = _g[0], setIsLoading = _g[1];
    //hook to keep the state for process i.e datastate
    var _h = useState({
        skip: 0,
        take: 20,
        filter: {
            logic: 'and',
            filters: [],
        },
    }), dataState = _h[0], setDataState = _h[1];
    /*
     * Hook to load data from the api once the component gets mounted
     */
    useEffect(function () {
        setIsLoading(true);
        getSourceData(props.api).then(function (response) {
            if (typeof response !== 'undefined') {
                totalDataInGrid.current = response['data'].length;
                var data_1 = response['data'];
                if (data_1 !== 'empty' && response['data'].length > 0) {
                    gridDataRef.current = dateFormatter(data_1);
                    setData(gridDataRef.current);
                    setGridData(gridDataRef.current);
                    setIsLoading(false);
                }
            }
            else {
                setTimeout(function () {
                    setIsLoading(false);
                }, 200);
            }
        });
    }, []);
    /*
     * This will hold the previous state of the grid data in this functional component
     */
    previousStateGridData = PreviousStateFunctionalComponent(gridDataRef.current);
    /*
     * Function to format the date in the recieved data
     */
    function dateFormatter(items) {
        var formatedData = items.map(function (item) {
            Object.keys(item).map(function (value, keys) {
                var regex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/g;
                if (item[value] && regex.test(item[value])) {
                    var timeSplit = item[value].split('.');
                    item[value] = new Date(timeSplit[0]);
                }
            });
            return item;
        });
        return formatedData;
    }
    /*
     * Function to show the Edit and delete actions
     */
    function commandCellWithActions(commandCellWithActionsProps) {
        var searchedItem = searchForForeignKey('key', props.column);
        return (_jsx(ActionCell, __assign({}, commandCellWithActionsProps, { edit: edit, save: save, cancel: cancel, updateCountAndData: updateCountAndData, setIsLoading: setIsLoading, api: props.api, foreignkey: searchedItem })));
    }
    /*
     *This function will update the grid data and count
     *after a delete action
     */
    function updateCountAndData(dataItemKey) {
        totalDataInGrid.current = totalDataInGrid.current - 1;
        var currentGridData = gridData.filter(function (item, index) { return item.id !== dataItemKey; });
        setGridData(currentGridData);
    }
    /*
     * Search in array object
     */
    function searchForForeignKey(nameKey, myArray) {
        var foreignKey = [];
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i][nameKey]) {
                foreignKey.push(myArray[i][nameKey]);
            }
        }
        return foreignKey;
    }
    /*
     * Function to edit an record
     */
    function edit(dataItem) {
        if (!addNewRecord) {
            setAddNewRecord(true);
            gridData.shift();
        }
        var currentGridData = gridData.map(function (item, index) {
            if (item.inEdit) {
                data[index].inEdit = false;
                return data[index];
            }
            if (item.id === dataItem.id) {
                item.inEdit = true;
                item.event = 'edit';
                return item;
            }
            return item;
        });
        setGridData(currentGridData);
    }
    /*
     * Function to validate before save or edit
     */
    function validation(dataItem) {
        if (dataItem) {
            var errorToSHow = Object.keys(props.validation).filter(function (value, i) {
                if (dataItem.hasOwnProperty(value) && props.validation[value]) {
                    if (!dataItem[value]) {
                        return props.validation[value];
                    }
                }
                if (!dataItem.hasOwnProperty(value) && props.validation[value]) {
                    return props.validation[value];
                }
            });
            if (errorToSHow.length > 0) {
                if (!toast.isActive('master-screen-validation')) {
                    toast.error(_jsx(Msg, { list: errorToSHow, error: props.validation }), {
                        autoClose: 6000,
                        toastId: 'master-screen-validation',
                    });
                }
                return false;
            }
            else {
                return true;
            }
        }
    }
    /*
     * Function to save an record
     */
    function save(dataItem) {
        //if validation is failure
        if (!validation(dataItem)) {
            return false;
        }
        //if validation is success
        if (dataItem.event === 'add') {
            saveSourceData(dataItem, props.api).then(function (response) {
                if (typeof response !== 'undefined') {
                    var currentGridData = gridData.map(function (item, index) {
                        if (item.inEdit === true) {
                            var typeOfResponsedData = Array.isArray(response.data) ? response.data[0] : response.data;
                            var responseDataFormat = dateFormatter([typeOfResponsedData]);
                            return responseDataFormat[0];
                        }
                        return item;
                    });
                    setGridData(currentGridData);
                    setData(currentGridData);
                    totalDataInGrid.current = currentGridData.length;
                    setAddNewRecord(true);
                }
            });
        }
        else {
            editSourceData(dataItem, dataItem.id, props.api).then(function (response) {
                var currentGridData = gridData.map(function (item, index) {
                    if (item.inEdit === true) {
                        var typeOfResponsedData = Array.isArray(response.data) ? response.data[0] : response.data;
                        var responseDataFormat = dateFormatter([typeOfResponsedData]);
                        return responseDataFormat[0];
                    }
                    return item;
                });
                setData(currentGridData);
                setGridData(currentGridData);
            });
        }
    }
    /*
     * Function to cancel an record
     */
    function cancel(dataItem) {
        if (!addNewRecord) {
            setAddNewRecord(true);
        }
        var currentGridData = data.map(function (item, index) {
            if (item.id === dataItem.id) {
                item.inEdit = false;
            }
            return data[index];
        });
        setGridData(currentGridData);
    }
    /*
     * Function to call on itemChange
     */
    function itemChange(event) {
        var data = gridData.map(function (item) {
            var _a;
            return item.id === event.dataItem.id ? __assign(__assign({}, item), (_a = {}, _a[event.field] = event.value, _a)) : item;
        });
        setGridData(data);
    }
    /*
     * Function to enabe and disable meta data columns
     */
    function enableDisableMetadata() {
        if (showMetadata) {
            setMinWidth('');
        }
        setShowChecked(!showChecked);
        setShowMetadata(!showMetadata);
    }
    /*
     * Function to add a new item to the grid
     */
    function add() {
        setAddNewRecord(false);
        var currentGridData = gridData.map(function (item, index) {
            if (item.inEdit) {
                data[index].inEdit = false;
                return data[index];
            }
            return item;
        });
        var dataItem = {
            inEdit: true,
            event: 'add',
        };
        currentGridData.unshift(dataItem);
        setGridData(currentGridData);
    }
    /*
     *condition editing based on dropdown y/n value
     */
    function isEditable(editableColumn, field) {
        var isEditable = false;
        var index;
        var editableItem;
        var dataStateCurrent = gridData;
        dataStateCurrent.map(function (item, i) {
            if (item.event === 'edit' && item[editableColumn] == 'Y') {
                item[field] = item[field] !== null && item[field] > -1 ? item[field] : data[i][field];
                isEditable = true;
            }
            else {
                if (item.inEdit === true) {
                    index = i;
                    item[field] = null;
                    editableItem = item;
                }
            }
        });
        if (index > -1) {
            dataStateCurrent[index] = editableItem;
        }
        return isEditable;
    }
    /*
     *component jsx return
     */
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(Spinner, {}), _jsx("div", __assign({ id: "gridTemplateListings" }, { children: _jsxs(Grid, __assign({ style: { height: window.innerHeight - 140 }, rowHeight: 40, filterable: true, resizable: true, data: process(gridData, dataState) }, dataState, { reorderable: true, resizable: true, scrollable: "virtual", sortable: true, onItemChange: itemChange, editField: "inEdit", onDataStateChange: function (event) { return setDataState(event.data); } }, { children: [_jsxs(GridToolbar, { children: [_jsx("div", __assign({ className: "pull-left ml-2" }, { children: _jsx(Tooltip, __assign({ openDelay: 10, position: "bottom", anchorElement: "target" }, { children: addNewRecord && (_jsx("button", __assign({ look: "bare", onClick: add, className: "k-button bg-transparent border border-0 p-1" }, { children: _jsx("span", { className: "k-icon k-i-plus-outline", style: { fontSize: 25, color: '#7f7f7f' }, title: "Add new item to the grid" }) }))) })) })), _jsxs("div", __assign({ className: "pull-right p-2" }, { children: ["Total Data: ", totalDataInGrid.current] })), _jsxs("div", __assign({ className: "pull-left pl-3" }, { children: [_jsx("input", { id: "metaData", type: "checkbox", checked: showChecked, onChange: enableDisableMetadata, className: "k-checkbox" }), _jsx("label", __assign({ htmlFor: "metaData", className: "k-checkbox-label m-2" }, { children: "Enable Metadata" }))] }))] }), _jsx(Column, { width: "130px", cell: commandCellWithActions, filterable: false, resizable: false, reorderable: false, locked: true }), props.column.map(function (column, index) {
                            if (column.type !== 'meta' || (column.type === 'meta' && showMetadata === true)) {
                                var columnKey = Object.keys(column);
                                var cell = void 0;
                                if (typeof column.key !== 'undefined') {
                                    if (column.key === 'booleanList') {
                                        cell = BooleanDropdownList;
                                    }
                                    else {
                                        cell = GridDropDownList;
                                    }
                                }
                                var editing = columnKey.indexOf('editingBasedOn') !== -1
                                    ? isEditable(column.editingBasedOn, column.field)
                                    : column.type !== 'meta'
                                        ? true
                                        : false;
                                return (_jsx(Column, __assign({ width: minWidth, editable: editing }, column, { cell: cell, className: cell ? column.key : '' }), 'crRefGridColumn-' + index));
                            }
                        })] })) }))] }));
}
export default CrRefGridTemplate;
