import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function dataServiceSubscriber(props) {
    var validationParams = {
        serviceNm: 'Enter Service Name.',
        shellUserId: 'A subscriber is required .',
    };
    var columnParams = [
        {
            field: 'serviceNm',
            title: 'Data Service',
            key: 'dataProviderServiceKey',
        },
        {
            field: 'shellUserId',
            title: 'Subscriber',
            key: 'subscriberKey',
        },
        {
            field: 'subscriptionStartDt',
            title: 'Subscription Start',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            editor: 'date',
        },
        {
            field: 'subscriptionEndDt',
            title: 'Subscription End',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            editor: 'date',
        },
        {
            field: 'subscriptionApprovalCd',
            title: 'Approval Code',
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'dataServiceSubscriber' });
}
export default dataServiceSubscriber;
