import { __assign } from "tslib";
var getDefaultFormValues = function (assetToEdit, ownershipDetails) {
    if (ownershipDetails === void 0) { ownershipDetails = []; }
    if (assetToEdit) {
        var startDate = assetToEdit.assetStartDate === '0001-01-01T00:00:00' ? undefined : assetToEdit.assetStartDate;
        var closeDate = assetToEdit.assetCloseDate === '9999-12-31T00:00:00' ? undefined : assetToEdit.assetCloseDate;
        var latitude = !assetToEdit.latitudeNum ? undefined : +assetToEdit.latitudeNum;
        var longitude = !assetToEdit.longitudeNum ? undefined : +assetToEdit.longitudeNum;
        var subdivisionKey = assetToEdit.subdivision ? assetToEdit.locationKey : undefined;
        var cityKey = !subdivisionKey && assetToEdit.city ? assetToEdit.locationKey : undefined;
        var ownershipDetailsWithEmpty = ownershipDetails.map(function (company) { return ({
            Id: company.Id,
            assetKey: company.AssetKey,
            company: company.CompanyKey,
            equityShare: company.EquitySharePct ? String(company.EquitySharePct * 100) : '',
            equityStartDate: company.StartDate,
            equityEndDate: company.EndDate === '9999-12-31T00:00:00' ? undefined : company.EndDate,
        }); });
        ownershipDetailsWithEmpty.push({ Id: 0, equityShare: '' });
        return {
            assetName: assetToEdit.assetNm,
            assetClass: assetToEdit.assetClassKey,
            startDate: startDate,
            closeDate: closeDate,
            latitude: latitude,
            longitude: longitude,
            status: assetToEdit.operationalStatusKey ? assetToEdit.operationalStatusKey : undefined,
            rtlRegion: {
                HierarchyElementKey: assetToEdit.rtlKey,
                HierarchyElementNm: assetToEdit.rtl,
            },
            rttRegion: {
                HierarchyElementKey: assetToEdit.rttKey,
                HierarchyElementNm: assetToEdit.rtt,
            },
            acmCountry: {
                HierarchyElementKey: assetToEdit.acmCountryKey,
                HierarchyElementNm: assetToEdit.acmCountry,
            },
            country: assetToEdit.countryKey,
            subdivision: subdivisionKey,
            city: cityKey,
            comments: assetToEdit.comments,
            intelligenceSourceUrl: assetToEdit.sourceUrl,
            intelligenceSource: assetToEdit.sourceKey,
            ownershipDetails: ownershipDetailsWithEmpty,
        };
    }
    return {
        ownershipDetails: [{}],
    };
};
var createAssetObject = function (e, assetToEdit, ownershipDetails) {
    var _a;
    var hierarchies = ['acmCountry', 'rtlRegion', 'rttRegion'];
    var AssetHierarchy = [];
    hierarchies.forEach(function (hierarchy) {
        if (e[hierarchy]) {
            AssetHierarchy.push(__assign(__assign({}, e[hierarchy]), { AssetKey: assetToEdit ? assetToEdit.assetKey : 0 }));
        }
    });
    var Latitude = e.latitude ? Number(e.latitude) : undefined;
    var Longitude = e.longitude ? Number(e.longitude) : undefined;
    var assetObject = {
        Asset: {
            AssetKey: assetToEdit ? assetToEdit.assetKey : 0,
            AssetName: e.assetName,
            AssetClassKey: e.assetClass,
            DataProviderKey: e.intelligenceSource,
            StartDate: e.startDate,
            CloseDate: e.closeDate,
            StatusKey: e.status,
            AcmCountryKey: e.acmCountry.HierarchyElementKey,
            CountryKey: e.country,
            CityKey: e.city,
            SubdivisionKey: e.subdivision,
            Latitude: Latitude,
            Longitude: Longitude,
            Comments: e.comments,
            IntelligenceSourceUrl: e.intelligenceSourceUrl,
        },
        AssetCompany: (_a = e.ownershipDetails) === null || _a === void 0 ? void 0 : _a.filter(function (c) { return c.company; }).map(function (company) { return ({
            AssetKey: assetToEdit ? assetToEdit.assetKey : 0,
            CompanyKey: company.company,
            StartDate: company.equityStartDate,
            EndDate: company.equityEndDate,
            EquitySharePct: company.equityShare ? Number(company.equityShare) / 100 : undefined,
            Id: company.Id,
        }); }),
        AssetHierarchy: AssetHierarchy,
    };
    if (assetToEdit) {
        var editAssetObject = addEditedFields(assetObject, e, getDefaultFormValues(assetToEdit, ownershipDetails));
        return editAssetObject;
    }
    return assetObject;
};
//eslint-disable-next-line complexity
var addEditedFields = function (assetObject, e, initialFormValues) {
    var _a, _b, _c, _d, _e, _f;
    var editedObject = {
        Asset: __assign(__assign({}, assetObject.Asset), { EditedFlag: false }),
        AssetCompany: (_a = assetObject.AssetCompany) === null || _a === void 0 ? void 0 : _a.map(function (company) { return (__assign(__assign({}, company), { EditedFlag: false })); }),
        AssetHierarchy: (_b = assetObject.AssetHierarchy) === null || _b === void 0 ? void 0 : _b.map(function (hierarchy) { return (__assign(__assign({}, hierarchy), { EditedFlag: false })); }),
    };
    if (initialFormValues.assetName !== e.assetName ||
        initialFormValues.assetClass !== e.assetClass ||
        initialFormValues.startDate !== e.startDate ||
        initialFormValues.closeDate !== e.closeDate ||
        initialFormValues.status !== e.status ||
        initialFormValues.acmCountry.HierarchyElementNm !== e.acmCountry.HierarchyElementNm ||
        initialFormValues.country !== e.country ||
        initialFormValues.city !== e.city ||
        initialFormValues.subdivision !== e.subdivision ||
        ((_c = initialFormValues.latitude) !== null && _c !== void 0 ? _c : '') !== e.latitude ||
        ((_d = initialFormValues.longitude) !== null && _d !== void 0 ? _d : '') !== e.longitude ||
        ((_e = initialFormValues.comments) !== null && _e !== void 0 ? _e : '') !== e.comments ||
        initialFormValues.intelligenceSource !== e.intelligenceSource ||
        initialFormValues.intelligenceSourceUrl !== e.intelligenceSourceUrl) {
        editedObject.Asset.EditedFlag = true;
    }
    (_f = initialFormValues.ownershipDetails) === null || _f === void 0 ? void 0 : _f.forEach(function (initialCompany, index) {
        var _a, _b;
        var company = (_a = e.ownershipDetails) === null || _a === void 0 ? void 0 : _a[index];
        if (initialCompany.company !== (company === null || company === void 0 ? void 0 : company.company) ||
            initialCompany.equityShare !== (company === null || company === void 0 ? void 0 : company.equityShare) ||
            initialCompany.equityStartDate !== (company === null || company === void 0 ? void 0 : company.equityStartDate) ||
            initialCompany.equityEndDate !== (company === null || company === void 0 ? void 0 : company.equityEndDate)) {
            if ((_b = editedObject.AssetCompany) === null || _b === void 0 ? void 0 : _b[index]) {
                editedObject.AssetCompany[index].EditedFlag = true;
            }
        }
    });
    editedObject.AssetHierarchy.forEach(function (hierarchy) {
        var _a, _b;
        if (hierarchy.HierarchyElementKey !== initialFormValues.acmCountry.HierarchyElementKey &&
            hierarchy.HierarchyElementKey !== ((_a = initialFormValues.rtlRegion) === null || _a === void 0 ? void 0 : _a.HierarchyElementKey) &&
            hierarchy.HierarchyElementKey !== ((_b = initialFormValues.rttRegion) === null || _b === void 0 ? void 0 : _b.HierarchyElementKey)) {
            hierarchy.EditedFlag = true;
        }
    });
    return editedObject;
};
// eslint-disable-next-line complexity
var getIsFormDirty = function (watch) {
    var _a;
    var isDetailsDirty = watch('assetName') || watch('assetClass') || watch('status') || watch('startDate') || watch('closeDate');
    var isOwnershipDirty = (_a = watch('ownershipDetails')) === null || _a === void 0 ? void 0 : _a.some(function (ownership) { return ownership.company || ownership.equityShare || ownership.equityStartDate || ownership.equityEndDate; });
    var isLocationDirty = watch('acmCountry') || watch('country') || watch('subdivision') || watch('city');
    var isIntelligenceDirty = watch('intelligenceSource') || watch('intelligenceSourceUrl');
    return !!(isDetailsDirty || isOwnershipDirty || isLocationDirty || isIntelligenceDirty);
};
export { createAssetObject, getIsFormDirty, getDefaultFormValues };
