var STICKYWIDTHS = [0, 50, 150];
var HEIGHTWITHOUTTABLECONTAINER = '230px'; // Height of Navbar + Header Section + Paddings
var INDEXFORGROUPINGS = [3, 12, 18];
var DEFAULTVISIBLECAPACITYCOLUMNS = [
    'Selected events',
    'Start date',
    'Asset',
    'Unit',
    'Subtype',
    'Probability',
    'Basis',
    'Status',
    'Total cap.',
    'Updated',
    'Edited by',
    'Related events',
    'Actions',
];
export { DEFAULTVISIBLECAPACITYCOLUMNS, HEIGHTWITHOUTTABLECONTAINER, INDEXFORGROUPINGS, STICKYWIDTHS };
