import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertMaintenanceDaily } from './MaintenanceDailySummary.utils';
import { MaintenancePanel } from 'components/maintenance-panel';
import { useGetMaintenanceDailyQuery } from 'common/ducks/maintenanceApis';
import { useMaintenanceDaily } from 'common/hooks/maintenance-daily';
import { Loader } from 'components/loader';
import { SelectedFiltersTags } from 'components/selected-filters-tags';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { parse } from 'common/utils/newScreenUtils';
import { PagesHeader } from 'components/pages-header/PagesHeader';
var isValidTimeframe = function (timeframeParam) {
    return dayjs(timeframeParam[0]).isValid() && dayjs(timeframeParam[1]).isValid();
};
var shouldSkipMaintenanceRequest = function (_a) {
    var assetIds = _a.assetIds, assetUnitIds = _a.assetUnitIds, companyIds = _a.companyIds, countryIds = _a.countryIds, regionIds = _a.regionIds, unitSubTypeIds = _a.unitSubTypeIds, unitTypeIds = _a.unitTypeIds, version = _a.version, startDate = _a.startDate;
    return !startDate ||
        !version ||
        (!(assetIds === null || assetIds === void 0 ? void 0 : assetIds.length) &&
            !(assetUnitIds === null || assetUnitIds === void 0 ? void 0 : assetUnitIds.length) &&
            !(companyIds === null || companyIds === void 0 ? void 0 : companyIds.length) &&
            !(countryIds === null || countryIds === void 0 ? void 0 : countryIds.length) &&
            !(regionIds === null || regionIds === void 0 ? void 0 : regionIds.length) &&
            !(unitSubTypeIds === null || unitSubTypeIds === void 0 ? void 0 : unitSubTypeIds.length) &&
            !(unitTypeIds === null || unitTypeIds === void 0 ? void 0 : unitTypeIds.length));
};
export var MaintenanceDailySummary = function (_a) {
    var reset = _a.reset;
    var _b = useState(false), hideExpected = _b[0], setHideExpected = _b[1];
    var theme = useTheme();
    var _c = useState({
        startDate: dayjs().startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        endDate: dayjs().endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        version: dayjs().format(DATE_FORMAT_YYYY_MM_DD),
    }), maintenanceRequestParams = _c[0], setMaintenanceRequestParams = _c[1];
    var location = useLocation();
    // Fetch data for +/- 1 month of the selected month tp accomodate unexpected events
    var _d = useGetMaintenanceDailyQuery(__assign(__assign({}, maintenanceRequestParams), { startDate: dayjs(maintenanceRequestParams.startDate).subtract(1, 'month').format(DATE_FORMAT_YYYY_MM_DD), endDate: dayjs(maintenanceRequestParams.endDate).add(1, 'month').format(DATE_FORMAT_YYYY_MM_DD) }), {
        skip: shouldSkipMaintenanceRequest(maintenanceRequestParams),
    }), data = _d.data, isFetching = _d.isFetching, isUninitialized = _d.isUninitialized;
    var maintenanceDaily = useMaintenanceDaily(data !== null && data !== void 0 ? data : { assetDetails: null }, maintenanceRequestParams.startDate);
    var handlePanelMonthChange = useCallback(function (value) {
        var month = value || dayjs();
        setMaintenanceRequestParams(__assign(__assign({}, maintenanceRequestParams), { startDate: month.startOf('month').format(DATE_FORMAT_YYYY_MM_DD), endDate: month.endOf('month').format(DATE_FORMAT_YYYY_MM_DD) }));
    }, [maintenanceRequestParams]);
    var initialValues = useMemo(function () {
        var search = location.search;
        var params = new URLSearchParams(search);
        var timeframeParams = params.getAll('timeframe');
        var versionParam = params.get('version');
        var today = dayjs().format(DATE_FORMAT_YYYY_MM_DD);
        return {
            assets: params.getAll('assets').map(parse),
            location: params.getAll('location').map(parse),
            regions: params.getAll('regions').map(parse),
            owners: params.getAll('owners').map(parse),
            units: params.getAll('units').map(parse),
            unitSubTypes: params.getAll('unitSubTypes').map(parse),
            unitTypes: params.getAll('unitTypes').map(parse),
            timeframe: isValidTimeframe(timeframeParams) ? [timeframeParams[0], timeframeParams[1]] : [today, today],
            version: dayjs(versionParam).isValid() ? dayjs(versionParam).format(DATE_FORMAT_YYYY_MM_DD) : today,
            sinceLastUpdate: today,
        };
    }, [location]);
    useEffect(function () {
        setMaintenanceRequestParams({
            regionIds: initialValues.regions,
            countryIds: initialValues.location,
            assetIds: initialValues.assets,
            companyIds: initialValues.owners,
            unitSubTypeIds: initialValues.unitSubTypes,
            unitTypeIds: initialValues.unitTypes,
            assetUnitIds: initialValues.units,
            startDate: dayjs(initialValues.timeframe[0]).startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
            endDate: dayjs(initialValues.timeframe[0]).endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
            version: initialValues.version,
        });
    }, [initialValues]);
    var showData = !isFetching && (data === null || data === void 0 ? void 0 : data.assetDetails) && !reset;
    return (_jsxs(_Fragment, { children: [_jsx(SelectedFiltersTags, __assign({}, maintenanceRequestParams)), _jsxs("div", __assign({ style: { backgroundColor: theme.background.surface, padding: '20px' } }, { children: [_jsx(PagesHeader, { title: "Daily Summary" }), isFetching && (_jsx("div", __assign({ style: { height: '70vh' } }, { children: _jsx(Loader, { message: "Loading summary..." }) }))), showData && (_jsx(MaintenancePanel, { hideExpected: hideExpected, toggleExpected: setHideExpected, onMonthChange: handlePanelMonthChange, data: maintenanceDaily, monthDate: maintenanceRequestParams === null || maintenanceRequestParams === void 0 ? void 0 : maintenanceRequestParams.startDate })), _jsx(AlertMaintenanceDaily, { isFetching: isFetching, isUninitialized: isUninitialized, data: data, reset: reset })] }))] }));
};
