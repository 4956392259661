import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Alert, Button, Flexbox, Icons, Sentiments } from '@sede-x/shell-ds-react-framework';
import { useHistory, useLocation } from 'react-router-dom';
import { AssetCatalogTable } from './AssetCatalogTable';
import { AddEditAssetDrawer } from './AddEditAssetDrawer';
import { selectIsSidebarOpen, setSidebarOpenState } from 'pages/maintenance-event-summary/ducks/slice';
import { useSidebar } from 'components/sidebar-template/hooks';
import { parse } from 'common/utils/newScreenUtils';
import { SidebarTemplate } from 'components/sidebar-template';
import { PagesHeader } from 'components/pages-header/PagesHeader';
import { SelectedFiltersTags } from 'components/selected-filters-tags';
import { AssetsCatalogFilter } from 'components/assets-catalog-filter/AssetsCatalogFilter';
import { useGetV2AssetsQuery } from 'common/ducks/assetCatalogApis';
import { ROUTE_PATHS } from 'app/routes/route-paths';
import { resolveParamsObject } from 'common/utils/url/search-params';
import { Loader } from 'components/loader';
import { InfoPanel } from 'components/info-panel';
var title = 'Asset Catalog  • Asset Capacity & Maintenance';
var pageSize = 20;
var Add = Icons.Add, Gear = Icons.Gear;
var assetColumnMap = new Map([
    ['Asset key', 'assetKey'],
    ['Asset name', 'assetNm'],
    ['Company', 'companyNm'],
    ['Asset class', 'assetClassDesc'],
    ['Mapped source', 'dataProviderAbbr'],
    ['Start date', 'assetStartDate'],
    ['Close date', 'assetCloseDate'],
    ['CDU capacity today', 'assetSize'],
    ['Units', 'units'],
    ['Status', 'assetOperationalStatus'],
    ['Edited by', 'metaChangedByNm'],
    ['Edited date', 'metaChangedDttm'],
    ['ACM country', 'acmCountryNm'],
    ['Latitude', 'latitudeNum'],
    ['Longitude', 'longitudeNum'],
    ['ACM region', 'acmRegion'],
    ['ACM country', 'acmCountry'],
]);
export var AssetCatalog = function () {
    document.title = title;
    var history = useHistory();
    var location = useLocation();
    var search = location.search;
    var _a = useState(false), openAddEditDrawer = _a[0], setOpenAddEditDrawer = _a[1];
    var _b = useState(undefined), assetToEdit = _b[0], setAssetToEdit = _b[1];
    var _c = useState(false), isConfigureModalOpen = _c[0], setIsConfigureModalOpen = _c[1];
    var _d = useSidebar(selectIsSidebarOpen, setSidebarOpenState), handleSidebarClose = _d.handleSidebarClose, handleSidebarOpen = _d.handleSidebarOpen, isSidebarOpen = _d.isSidebarOpen;
    var initialValues = useMemo(function () {
        var params = new URLSearchParams(search);
        return {
            regions: params.getAll('regions').map(parse),
            location: params.getAll('location').map(parse),
            assets: params.getAll('assets').map(parse),
            assetClasses: params.getAll('assetClasses').map(parse),
            currentPage: params.getAll('currentPage').map(parse),
        };
    }, [search]);
    var _e = useState([]), sorting = _e[0], setSorting = _e[1];
    var _f = useGetV2AssetsQuery({
        regionIds: initialValues.regions,
        countryIds: initialValues.location,
        assetIds: initialValues.assets,
        assetClassIds: initialValues.assetClasses,
        page: initialValues.currentPage[0] || 1,
        pageSize: pageSize,
        orderBy: sorting.length ? "".concat(assetColumnMap.get(sorting[0].id), ":").concat(sorting[0].desc ? 'desc' : 'asc') : undefined,
    }), _g = _f.data, data = _g === void 0 ? [] : _g, isFetching = _f.isFetching;
    var handleFilterSubmit = function (values) {
        history.push({
            pathname: ROUTE_PATHS.ASSET_CATALOG,
            search: new URLSearchParams(resolveParamsObject(values)).toString(),
        });
    };
    var handlePaginationOnChange = function (currentPage) {
        var params = new URLSearchParams(search);
        params.set('currentPage', currentPage.toString());
        history.push({
            pathname: ROUTE_PATHS.ASSET_CATALOG,
            search: params.toString(),
        });
    };
    var handleDrawerVisbility = function (open, asset) {
        setAssetToEdit(asset);
        setOpenAddEditDrawer(open);
    };
    return (_jsxs(SidebarTemplate, __assign({ sidebarOpen: isSidebarOpen, onSidebarOpen: handleSidebarOpen, onSidebarClose: handleSidebarClose, sidebarTitle: "Filters", sidebar: _jsx(AssetsCatalogFilter, __assign({ onSubmit: handleFilterSubmit }, initialValues, { isSidebarOpen: isSidebarOpen })) }, { children: [_jsxs(PagesHeader, __assign({ title: "Asset catalog", dockStartItemsLength: 1 }, { children: [_jsx(InfoPanel, { type: "asset" }), _jsx(Button, __assign({ size: "small", variant: "outlined", icon: _jsx(Gear, {}), disabled: isFetching, onClick: function () { return setIsConfigureModalOpen(true); } }, { children: "Configure columns" })), _jsx(Button, __assign({ size: "small", onClick: function () { return handleDrawerVisbility(true); }, icon: _jsx(Add, { height: 100 }) }, { children: "New asset" }))] })), _jsx(SelectedFiltersTags, { regionIds: initialValues.regions, countryIds: initialValues.location, assetIds: initialValues.assets }), _jsx(AddEditAssetDrawer, { open: openAddEditDrawer, handleDrawerClose: function () { return handleDrawerVisbility(false); }, assetToEdit: assetToEdit }), isFetching && _jsx(Loader, { message: "Fetching assets..." }), !isFetching && !data.length && (_jsx(Flexbox, __assign({ alignItems: "center", justifyContent: "center", style: { height: '75%' } }, { children: _jsx(Alert, __assign({ dismissible: false, sentiment: Sentiments.Neutral, style: { maxWidth: '550px' } }, { children: "No data found. Please select other filters." })) }))), !isFetching && !!data.length && (_jsx(AssetCatalogTable, { assetsData: data, sorting: sorting, setSorting: setSorting, handlePaginationChange: handlePaginationOnChange, currentPage: initialValues.currentPage[0] || 1, isConfigureModalOpen: isConfigureModalOpen, closeConfigureModal: function () { return setIsConfigureModalOpen(false); }, handleEditAsset: handleDrawerVisbility, selectedAssetKey: assetToEdit === null || assetToEdit === void 0 ? void 0 : assetToEdit.assetKey }))] })));
};
