import styled, { css } from 'styled-components';

import Heading from '../../../Text/components/Heading/Heading';
import Label from '../../../Text/components/Label/Label';
import { modalConsts } from '../../Modal.consts';
import { ModalDescriptionPosition, ModalPadding, ModalSize } from '../../Modal.types';
import { Positions } from '../../../../types/types';

type TitleBarProps = {
  size: ModalSize;
};

const TitleBar = styled.div<TitleBarProps>`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

type TextWrapperProps = {
  descriptionPosition: ModalDescriptionPosition;
  size: ModalSize;
  padding: ModalPadding;
  haveAreaComponent: boolean;
};

const TextWrapper = styled.div<TextWrapperProps>`
  ${({ descriptionPosition, size, padding, haveAreaComponent }) => css`
    display: flex;
    flex-direction: ${descriptionPosition === Positions.Bottom ? 'column' : 'column-reverse'};
    gap: ${modalConsts[size].titleAndDescriptionGap};
    padding: ${modalConsts[size].titleBarPadding[padding]};
    padding-right: ${haveAreaComponent && '0'};
  `};
`;

const Title = styled(Heading)`
  margin: 0;
`;

const Description = styled(Label)`
  ${({ theme }) => css`
    font-weight: 400;
    color: ${theme.text.onSurface.subtle};
  `};
`;

type ComponentWrapperProps = {
  size: ModalSize;
  padding: ModalPadding;
};

const ComponentWrapper = styled.div<ComponentWrapperProps>`
  display: flex;
  flex-grow: 1;
  padding: ${({ size, padding }) => modalConsts[size].closeButton.position[padding].right};
`;

export { TitleBar, TextWrapper, Title, Description, ComponentWrapper };
