import dayjs from 'dayjs';
export var getDefaultValues = function (event) {
    return event
        ? {
            asset: event.asset_key,
            units: [event.asset_unit_key],
            offlineCapacityOriginal: event.offline_capacity_pct.toFixed(2),
            lockBaseline: event.event_locked_ind !== 'N',
            startDate: event.event_start_dt,
            endDate: event.event_end_dt,
            eventType: event.asset_event_type_key,
            eventCause: event.event_cause_type_key,
            provider: event.data_provider_key,
            comment: event.comments || '',
        }
        : {
            offlineCapacityOriginal: '100.00',
            lockBaseline: false,
            startDate: dayjs().toISOString(),
            endDate: dayjs().toISOString(),
        };
};
