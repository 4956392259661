import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
var IncrBtnGroup = /** @class */ (function (_super) {
    __extends(IncrBtnGroup, _super);
    function IncrBtnGroup(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClickUnit = function (id) {
            _this.setState({ activeLinkUnit: id });
            _this.props.unitToggle(id, _this.props.assetId);
        };
        _this.handleClickView = function (id) {
            _this.setState({ activeLinkView: id });
            _this.props.viewToggle(id, _this.props.assetId);
        };
        _this.state = {
            primary: false,
            activeIndex: 0,
            unittypes: [
                {
                    id: 1,
                    name: 'Unit Type',
                },
                {
                    id: 2,
                    name: 'Unit Subtype',
                },
                {
                    id: 3,
                    name: 'Unit',
                },
            ],
            views: [
                {
                    id: 1,
                    name: 'Daily',
                },
                {
                    id: 2,
                    name: 'Monthly',
                },
                {
                    id: 3,
                    name: 'Quarterly',
                },
            ],
            activeLinkUnit: 2,
            activeLinkView: 2,
            expOn: false,
        };
        return _this;
    }
    IncrBtnGroup.prototype.render = function () {
        var _this = this;
        var _a = this.state, unittypes = _a.unittypes, views = _a.views, activeLinkUnit = _a.activeLinkUnit, activeLinkView = _a.activeLinkView;
        return (_jsxs("div", __assign({ className: "mx-3" }, { children: [_jsx(ButtonGroup, __assign({ className: "bg-solid ml-3 pull-right" }, { children: unittypes.map(function (unit) {
                        return (_jsx(Button, __assign({ className: "btn border", onClick: function () { return _this.handleClickUnit(unit.id); }, primary: unit.id === activeLinkUnit ? true : false }, { children: unit.name }), unit.id));
                    }) })), _jsx(ButtonGroup, __assign({ className: "bg-solid mx-3 pull-right" }, { children: views.map(function (view) {
                        return (_jsx(Button, __assign({ className: "btn border", onClick: function () { return _this.handleClickView(view.id); }, primary: view.id === activeLinkView ? true : false }, { children: view.name }), view.id));
                    }) }))] })));
    };
    return IncrBtnGroup;
}(Component));
export default IncrBtnGroup;
