import { PropsWithChildren, Ref, ReactElement } from 'react';

import { BaseSelectRef } from 'rc-select';
import { BaseOptionType, DefaultOptionType, SelectProps } from 'rc-select/lib/Select';

import Option from '../../Select/components/Option/Option';
import OptionGroup from '../../Select/components/OptionGroup/OptionGroup';
import Select from '../../Select/Select';
import { ISelectProps } from '../../Select/Select.types';

const PaginationSelect = (props: ISelectProps) => <Select allowClear={false} {...props} />;

// Needed for `selectComponentClass` in rc-pagination
// To match rc-select: https://github.com/react-component/select/blob/master/src/Select.tsx#L659
PaginationSelect.Option = Option;
PaginationSelect.OptGroup = OptionGroup;

export default PaginationSelect as unknown as (<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: PropsWithChildren<SelectProps<ValueType, OptionType>> & {
    ref?: Ref<BaseSelectRef>;
  },
) => ReactElement) & {
  Option: typeof Option;
  OptGroup: typeof OptionGroup;
};
