import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function assetCrossReference(props) {
    var validationParams = {
        assetNm: 'Please Select an Asset Key',
        dataProviderAbbr: 'Please select a Data Provider Key',
    };
    var columnParams = [
        {
            field: 'providerAssetId',
            title: 'Provider Asset Id',
        },
        {
            field: 'providerAssetNm',
            title: 'Provider Asset Nm',
        },
        {
            field: 'providerCityNm',
            title: 'Provider City Nm',
        },
        {
            field: 'providerOwnerNm',
            title: 'Provider Owner Nm',
        },
        {
            field: 'assetNm',
            title: 'Asset Key',
            key: 'assetKey',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'versionEffectiveDt',
            title: 'Effective Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionTerminationDt',
            title: 'Termination Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'assetCrossRef' });
}
export default assetCrossReference;
