import styled, { css } from 'styled-components';

import { formFieldConsts } from '../../FormField.consts';
import { FormFieldSize } from '../../FormField.types';
import { Sizes, Positions } from '../../../../types/types';

type CommonStyledProps = {
  size: FormFieldSize;
};

type IconOrientation = {
  iconPosition?: string;
};

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.label.medium.normal};
    color: ${theme.text.onSurface.subtle};
  `}
`;

const LeftContainer = styled.div<CommonStyledProps>`
  ${({ size }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: ${formFieldConsts[size].margin};

    svg {
      width: ${formFieldConsts[size].icon};
      height: ${formFieldConsts[size].icon};
    }
  `}
`;

const TitleContainer = styled.label<CommonStyledProps & IconOrientation>`
  ${({ iconPosition }) =>
    iconPosition === Positions.Right &&
    css`
      flex-flow: row-reverse;
      // This is required to move items to its original position (left in ltr)
      margin-right: auto;
    `}

  ${({ size, theme }) => css`
    ${size === Sizes.Small && theme.label.small.bold};
    ${size === Sizes.Medium && theme.label.medium.bold};
    ${size === Sizes.Large && theme.label.large.bold};
    color: ${theme.text.onSurface.strong};
  `}
  
  display: flex;
  gap: 6px;
  align-items: center;
`;

const LabelText = styled.span`
  display: flex;
  gap: 6px;
`;

const Title = styled.div<CommonStyledProps>`
  ${({ size, theme }) => css`
    ${size === Sizes.Small && theme.label.small.bold};
    ${size === Sizes.Medium && theme.label.medium.bold};
    ${size === Sizes.Large && theme.label.large.bold};
    color: ${theme.text.onSurface.strong};
  `}
`;

const Mandatory = styled.span<CommonStyledProps>`
  ${({ size, theme }) => css`
    ${size === Sizes.Small && theme.label.small.bold};
    ${size === Sizes.Medium && theme.label.medium.bold};
    ${size === Sizes.Large && theme.label.large.bold};
    color: ${theme.system.strong.negative};
    margin-left: 1px;
  `}
`;

const Description = styled.span`
  ${({ theme }) => css`
    ${theme.label.small.normal};
    color: ${theme.text.onSurface.subtle};
  `}
`;

export { Container, LeftContainer, TitleContainer, LabelText, Title, Mandatory, Description };
