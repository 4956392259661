import React, { ReactElement, forwardRef, ForwardedRef } from 'react';

import classNames from 'classnames';

import { CHECKBOX_CLASS, NOOP } from '../../utils/constants';
import { Check, Minus } from '../Icon/components';
import * as Styled from './Checkbox.styles';
import { ICheckboxProps } from './Checkbox.types';
import { Sizes } from '../../types/types';
import { getLabelSize } from '../../utils/sizesUtils';

const Checkbox = forwardRef(
  (
    {
      className = '',
      size = Sizes.Medium,
      checked,
      disabled,
      label,
      onChange = NOOP,
      indeterminate = false,
      wrapperProps,
      invalid = false,
      emphasis = true,
      mirrored = false,
      ...rest
    }: ICheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): ReactElement => (
    <Styled.CheckboxWrapper
      size={size}
      className={classNames(CHECKBOX_CLASS, className)}
      $disabled={disabled}
      $checked={checked}
      $emphasis={emphasis}
      $mirrored={mirrored}
      {...wrapperProps}
    >
      <Styled.Checkbox
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        $indeterminate={indeterminate}
        $emphasis={emphasis}
        $invalid={invalid}
        tabIndex={0}
        ref={ref}
        {...rest}
      />
      <Styled.FakeCheckbox
        size={size}
        $checked={checked}
        $indeterminate={indeterminate}
        $disabled={disabled}
        $emphasis={emphasis}
        $invalid={invalid}
      >
        {indeterminate ? <Minus /> : <Check />}
      </Styled.FakeCheckbox>
      {!!label && <Styled.CheckboxLabel size={getLabelSize(size)}>{label}</Styled.CheckboxLabel>}
    </Styled.CheckboxWrapper>
  ),
);

export default Checkbox;
