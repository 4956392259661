import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Icons, Popconfirm } from '@sede-x/shell-ds-react-framework';
import { StyledPopconfirm } from './MaintenanceSaveConfirmation.styles';
var AlertCircleSolid = Icons.AlertCircleSolid;
export var MaintenanceSaveConfirmation = function (_a) {
    var saveDisabled = _a.saveDisabled, onLockEvent = _a.onLockEvent;
    var actions = [
        {
            label: 'Save without lock',
            action: function () { return onLockEvent(false); },
            props: {
                variant: 'outlined',
            },
        },
        {
            label: 'Lock and save',
            action: function () { return onLockEvent(true); },
        },
    ];
    var popConfirmDescription = (_jsxs("p", __assign({ style: { fontSize: '14px' } }, { children: ["Your edit will be overwritten by IIR updates.", _jsx("br", {}), "To protect your changes we recommend", _jsx("br", {}), "locking this event."] })));
    return (_jsx(_Fragment, { children: _jsx(StyledPopconfirm, __assign({ title: _jsx(Popconfirm.Title, { text: "Lock baseline before saving?" }), description: _jsx(Popconfirm.Description, { size: "small", text: popConfirmDescription }), sentiment: "warning", actions: actions, icon: _jsx(AlertCircleSolid, {}) }, { children: _jsx(Button, __assign({ size: "small", disabled: saveDisabled, style: { width: '58px' } }, { children: "Save" })) })) }));
};
