import { css } from 'styled-components';

import { MENU_CLASS } from '../../../utils/constants';
import { menuConsts } from '../Menu.consts';
import { StyledMenuProps } from '../Menu.types';
import { Sizes } from '../../../types/types';

export const groupMenuItemStyle = css<StyledMenuProps>`
  ${({ theme, size, mode }) => {
    const padding = `${menuConsts[size].padding}px`;
    const indent = `${menuConsts[size].indent}px`;
    const subMenuGroupIndent = `${menuConsts[size].indent * 2}px`;

    return css`
      .${MENU_CLASS}-item-group {
        .${MENU_CLASS}-item-group-title {
          ${(size === Sizes.Small || size === Sizes.Medium) && theme.label.small.normal};
          ${size === Sizes.Large && theme.label.medium.normal};
          padding: ${padding} ${indent};
          color: ${theme.text.onSurface.subtle};
          text-transform: uppercase;
        }

        .${MENU_CLASS}-group-title-content {
          padding-bottom: 4px;
          margin-bottom: -5px;
          border-bottom: 1px solid ${theme.border.medium};
        }

        /* Horizontal */
        ${mode === 'horizontal' &&
        css`
          .${MENU_CLASS}-item-group-list {
            display: flex;
            flex-direction: row;
            align-items: stretch;
          }
        `}
      }

      /* Inline */
      ${mode === 'inline' &&
      css`
        .${MENU_CLASS}-submenu .${MENU_CLASS}-item-group {
          .${MENU_CLASS}-item-group-title {
            padding-left: ${subMenuGroupIndent};
          }

          &.${MENU_CLASS}-submenu-open
            > .${MENU_CLASS}-submenu-title
            > .${MENU_CLASS}-submenu-title-content
            > .${MENU_CLASS}-submenu-expand-icon {
            transform: rotate(180deg);
          }
        }
      `}

      /* Popup */
      &.${MENU_CLASS}-submenu {
        /* Horizontal */
        ${mode === 'horizontal' &&
        css`
          .${MENU_CLASS}-item-group .${MENU_CLASS}-item-group-list {
            display: flex;
            flex-direction: column;
            align-items: stretch;
          }
          .${MENU_CLASS}-submenu-expand-icon {
            transform: rotate(-90deg);
          }
        `}
      }
    `;
  }}
`;
