import { __awaiter, __generator } from "tslib";
import { useEffect, useState } from 'react';
import { useAccessToken } from 'common/hooks/access-token';
import { getAccessTokenData } from 'common/utils/access-token/get-access-token-data';
import { SESSIONSTORAGE_ACCESS_TOKEN, SESSIONSTORAGE_PREVIOUS_ROUTE, SESSIONSTORAGE_REFRESH_TOKEN } from 'common/constants';
var delay = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
var MAX_WAIT = 60000;
var requestAccessToken = function (refreshToken, round) {
    if (round === void 0) { round = 1000; }
    return __awaiter(void 0, void 0, Promise, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(process.env.REACT_APP_SSO_TOKEN_URI, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        method: 'post',
                        body: new URLSearchParams({
                            grant_type: 'refresh_token',
                            refresh_token: refreshToken,
                        }),
                    })];
                case 1:
                    resp = _a.sent();
                    if (!resp.ok) return [3 /*break*/, 2];
                    return [2 /*return*/, resp];
                case 2:
                    if (!(round < MAX_WAIT)) return [3 /*break*/, 4];
                    return [4 /*yield*/, delay(round)];
                case 3:
                    _a.sent();
                    return [2 /*return*/, requestAccessToken(refreshToken, round * 2)];
                case 4: throw new Error('Could not request new token.');
            }
        });
    });
};
export var useScheduleRefreshToken = function () {
    var accessToken = useAccessToken();
    var _a = useState(accessToken), internalAccessToken = _a[0], setInternalToken = _a[1];
    useEffect(function () {
        var accessTokenData = getAccessTokenData();
        if (accessTokenData) {
            // request new access token 2min before expiration
            var expirationMilliseconds = accessTokenData.exp * 1000;
            var time = expirationMilliseconds - 120000 - Date.now();
            var currentPath_1 = location.href;
            if (time <= 0) {
                window.sessionStorage.clear();
                window.sessionStorage.setItem(SESSIONSTORAGE_PREVIOUS_ROUTE, JSON.stringify(currentPath_1));
                location.replace(currentPath_1);
                return;
            }
            var timerId_1 = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var refreshToken, resp, json, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            refreshToken = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_REFRESH_TOKEN));
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, , 5]);
                            return [4 /*yield*/, requestAccessToken(refreshToken)];
                        case 2:
                            resp = _a.sent();
                            return [4 /*yield*/, resp.json()];
                        case 3:
                            json = _a.sent();
                            window.sessionStorage.setItem(SESSIONSTORAGE_ACCESS_TOKEN, JSON.stringify(json.access_token));
                            window.sessionStorage.setItem(SESSIONSTORAGE_REFRESH_TOKEN, JSON.stringify(json.refresh_token));
                            setInternalToken(json.access_token);
                            return [3 /*break*/, 5];
                        case 4:
                            e_1 = _a.sent();
                            console.error('Error', e_1);
                            alert('Invalid SSO response. Refreshing page to authenticate.');
                            window.sessionStorage.clear();
                            location.replace(currentPath_1);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); }, time);
            return function () {
                clearTimeout(timerId_1);
            };
        }
    }, [accessToken, internalAccessToken]);
};
