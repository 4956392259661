import { css } from 'styled-components';

import { TextTypes } from '../../../types/types';

export const allHeadings = [
  TextTypes.H1,
  TextTypes.H2,
  TextTypes.H3,
  TextTypes.H4,
  TextTypes.H5,
  TextTypes.H6,
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

export const headingFontNormal = (l: number) => css`
  ${({ theme }) => css`
    ${l === 1 && theme.heading[1].normal}
    ${l === 2 && theme.heading[2].normal}
    ${l === 3 && theme.heading[3].normal}
    ${l === 4 && theme.heading[4].normal}
    ${(l === 5 || l === 6) && theme.heading[5].normal}
  `}
`;

export const headingFontBold = (l: number) => css`
  ${({ theme }) => css`
    ${l === 1 && theme.heading[1].demi}
    ${l === 2 && theme.heading[2].demi}
    ${l === 3 && theme.heading[3].demi}
    ${l === 4 && theme.heading[4].bold}
    ${(l === 5 || l === 6) && theme.heading[5].bold}
  `}
`;
