import { ReactElement } from 'react';

import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import { IDropdownProps } from './Dropdown.types';

const Dropdown = (
  Component: ({ className, trigger, children, arrow, placement, ...rest }: IDropdownProps) => ReactElement,
  prefixCls: string,
  overlayClassName: string,
) =>
  styled(Component)`
    ${({ theme }) => css`
      &.${prefixCls} {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: ${theme.text.onSurface.strong};

        &-hidden {
          display: none !important;
        }

        .${prefixCls}-arrow {
          width: 24px;
          height: 12px;
          margin: 0 16px;
          overflow: hidden;

          &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: ${theme.background.raised};
            box-shadow: rgb(0 0 0 / 2%) 0 0 4px 0, rgb(0 0 0 / 4%) 0 0 0 1px;
            transform: translateX(50%) translateY(50%) rotate(45deg);
          }
        }

        &-placement-topLeft,
        &-placement-bottomLeft {
          .${prefixCls}-arrow {
            margin-right: auto;
          }
        }

        &-placement-topCenter,
        &-placement-bottomCenter {
          .${prefixCls}-arrow {
            margin: auto;
          }
        }

        &-placement-topRight,
        &-placement-bottomRight {
          .${prefixCls}-arrow {
            margin-left: auto;
          }
        }

        &-placement-topLeft,
        &-placement-topCenter,
        &-placement-topRight {
          flex-direction: column-reverse;
          .${prefixCls}-arrow {
            &::before {
              transform: translateX(50%) translateY(-50%) rotate(45deg);
            }
          }
        }
      }

      &.${overlayClassName} {
        z-index: ${Z_INDEX.POPOVER};
      }
    `}
  `;

const DropdownContainer = styled.div`
  ${({ theme }) => css`
    border-radius: 2px;
    background: ${theme.background.raised};
    box-shadow: ${theme.elevation.level2};
  `}
`;

export { Dropdown, DropdownContainer };
