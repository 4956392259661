import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Checkbox, ExpanderButton, Flexbox } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { StyledLock, StyledUnlock } from './MaintenanceEventTable.styles';
import { useAppDispatch } from 'common/hooks/store';
import { setModalData, setModalOpen } from 'pages/maintenance-event-summary/ducks/slice';
import { DATE_FORMAT_DD_MMM_YYYY } from 'common/constants';
import { EventSummaryActions } from 'pages/maintenance-event-summary/components/EventSummaryActions';
export var usePageLoading = function (_a) {
    var isLoading = _a.isLoading, isFetching = _a.isFetching, dataLength = _a.dataLength, reset = _a.reset;
    var showFullLoading = isFetching && (isLoading || reset || !dataLength);
    var showTableLoading = isFetching && !!dataLength && !isLoading && !reset;
    return { showFullLoading: showFullLoading, showTableLoading: showTableLoading };
};
export var useNewTableColumns = function (setGroupedEvents, handleSelectAll, checked) {
    var dispatch = useAppDispatch();
    var handleShowModal = function (_a) {
        var e = _a.e, modalName = _a.modalName, eventKey = _a.eventKey, title = _a.title, unitKey = _a.unitKey;
        e.stopPropagation();
        dispatch(setModalOpen(modalName));
        dispatch(setModalData({ title: title, assetUnitEventKey: eventKey, unitKey: unitKey }));
    };
    return [
        {
            id: 'Updated',
            enableHiding: false,
            enableResizing: false,
            size: 200,
            header: function () { return _jsx("span", __assign({ style: { paddingLeft: '76px' } }, { children: "Updated" })); },
            accessorKey: 'version_effective_dt',
            cell: function (info) {
                return info.row.getCanExpand() ? (_jsxs(Flexbox, __assign({ alignItems: "baseline", gap: info.row.getIsExpanded() ? '16px' : '52px' }, { children: [_jsx(ExpanderButton, { onClick: function (e) {
                                var _a;
                                e.stopPropagation();
                                if (info.row.getIsExpanded()) {
                                    info.row.toggleExpanded();
                                    setGroupedEvents([]);
                                    return;
                                }
                                info.table.resetExpanded();
                                setGroupedEvents((_a = info.row.original.subrows) !== null && _a !== void 0 ? _a : []);
                                info.row.toggleExpanded();
                            }, expanded: info.row.getIsExpanded() }), info.row.getIsExpanded() && (_jsx("div", __assign({ onClick: function (e) {
                                e.stopPropagation();
                                handleSelectAll();
                            } }, { children: _jsx(Checkbox, { checked: checked === 'indeterminate' ? false : checked, indeterminate: checked === 'indeterminate', size: "small" }) }))), dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY)] }))) : (_jsx("div", __assign({ style: { paddingLeft: '76px' } }, { children: dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY) })));
            },
        },
        {
            id: 'Asset',
            header: 'Asset',
            accessorKey: 'asset_nm',
            enableHiding: false,
        },
        {
            id: 'Asset key',
            header: 'Asset key',
            accessorKey: 'asset_key',
        },
        {
            id: 'Unit',
            header: 'Unit',
            accessorKey: 'asset_unit_nm',
        },
        {
            id: 'Unit key',
            header: 'Unit key',
            accessorKey: 'asset_unit_key',
            cell: function (info) {
                var value = info.row.original.asset_unit_nm === 'Multiple' ? 'Multiple' : info.getValue();
                return _jsx("span", __assign({ style: { marginRight: '16px' } }, { children: value }));
            },
        },
        {
            id: 'Subtype',
            header: 'Subtype',
            accessorKey: 'asset_unit_subtype_cd',
            cell: function (info) {
                var value = info.row.original.asset_unit_nm === 'Multiple' ? 'Multiple' : info.getValue();
                return _jsx("span", __assign({ style: { marginRight: '16px' } }, { children: value }));
            },
        },
        {
            id: 'Offline %',
            header: 'Offline %',
            accessorKey: 'offline_capacity_pct',
            size: 130,
            cell: function (info) {
                var value = info.row.original.asset_unit_nm === 'Multiple' ? 'Multiple' : info.getValue();
                return _jsx("span", __assign({ style: { float: 'right', marginRight: '16px' } }, { children: value }));
            },
        },
        {
            id: 'Total cap.',
            header: 'Total cap.',
            accessorKey: 'total_capacity',
            size: 150,
            cell: function (info) {
                var value = info.row.original.asset_unit_nm === 'Multiple'
                    ? 'Multiple'
                    : "".concat(parseFloat(info.getValue()).toFixed(1), " ").concat(info.row.original.measure_unit_cd);
                return _jsx("span", __assign({ style: { float: 'right', marginRight: '16px' } }, { children: value }));
            },
        },
        {
            id: 'Start date',
            header: 'Start date',
            accessorKey: 'event_start_dt',
            cell: function (info) { return dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY); },
        },
        {
            id: 'End date',
            header: 'End date',
            accessorKey: 'event_end_dt',
            cell: function (info) { return dayjs(info.getValue()).format(DATE_FORMAT_DD_MMM_YYYY); },
        },
        {
            id: 'Event type',
            header: 'Event type',
            accessorKey: 'asset_event_type_desc',
        },
        {
            id: 'Event cause',
            header: 'Event cause',
            accessorKey: 'event_cause_type_desc',
            cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
        },
        {
            id: 'Source',
            header: 'Source',
            accessorKey: 'data_provider_abbr',
        },
        {
            id: 'Locked',
            header: 'Locked',
            accessorKey: 'event_locked_ind',
            enableSorting: false,
            cell: function (info) {
                return info.getValue() === 'N' ? (_jsx(StyledUnlock, { "data-testid": "unlocked", height: 15, width: 15, style: { visibility: 'visible' } })) : (_jsx(StyledLock, { "data-testid": "locked", height: 15, width: 15, style: { visibility: 'visible' } }));
            },
        },
        {
            id: 'Actions',
            header: 'Actions',
            accessorKey: 'asset_unit_event_key',
            size: 100,
            enableResizing: false,
            enableHiding: false,
            enableSorting: false,
            cell: function (info) {
                var event = info.row.original;
                return !!event.subrows ? _jsx(_Fragment, {}) : EventSummaryActions(parseInt(info.getValue(), 10), event, handleShowModal);
            },
        },
    ];
};
export var useReset = function (_a) {
    var data = _a.data, setMaintenanceRequestParams = _a.setMaintenanceRequestParams;
    var _b = useState(false), reset = _b[0], setReset = _b[1];
    var handleReset = function (reset) {
        setReset(reset);
        reset && setMaintenanceRequestParams({});
    };
    useEffect(function () {
        reset && handleReset(false);
    }, [data]);
    return { reset: reset, handleReset: handleReset };
};
