import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function assetRampUp(props) {
    var validationParams = {
        rampUpMonthCnt: 'Enter ramp up month value.',
        assetNm: 'Enter asset name unique key.',
        assetUnitSubtypeCd: 'Enter asset unit subtype unique key.',
    };
    var columnParams = [
        {
            field: 'rampUpMonthCnt',
            title: 'Ramp Up Months',
        },
        {
            field: 'assetNm',
            title: 'Asset',
            key: 'assetKey',
        },
        {
            field: 'assetUnitSubtypeCd',
            title: 'Unit Subtype',
            key: 'assetUnitSubtypeKey',
        },
        {
            field: 'recordEntryDttm',
            title: 'Entry Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Quality Code',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Action Code',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Created Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Created By',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Changed Date',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Changed By',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'assetRampUpProfile' });
}
export default assetRampUp;
