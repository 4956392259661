import { __makeTemplateObject } from "tslib";
import { Drawer, Heading, Text } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
export var StyledDrawer = styled(Drawer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"], ["\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.section;
}, function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
});
export var StyledDrawerHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 16px;\n  border-bottom: 1px solid ", ";\n"], ["\n  padding-bottom: 16px;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
});
export var StyledDrawerForm = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: calc(100% - 63px);\n"], ["\n  height: calc(100% - 63px);\n"])));
export var StyledTitle = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n"], ["\n  color: ", ";\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.subtle;
});
export var StyledHeading = styled(Heading)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  white-space: nowrap;\n  width: 400px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 28px;\n"], ["\n  white-space: nowrap;\n  width: 400px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 28px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
