import { __assign, __extends, __spreadArray } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './breadcrumb.css';
import _ from 'lodash';
var breadcrumb = /** @class */ (function (_super) {
    __extends(breadcrumb, _super);
    function breadcrumb() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.breadcrumb = function (parts) {
            parts = parts.slice(1, parts.length);
            return _jsx(React.Fragment, { children: parts.slice(2).map(_this.crumb) });
        };
        _this.crumb = function (part, partIndex, parts) {
            switch (part) {
                case 'globalAssetDatabase':
                    part = 'ACM';
                    break;
                case 'maintenanceEventSummary':
                    part = 'Event Summary';
                    break;
                case 'capacityTotal':
                    part = 'Total';
                    break;
                case 'capacityIncremental':
                    part = 'Incremental';
                    break;
                default:
                    part = _.startCase(_.camelCase(part));
            }
            var path = __spreadArray([''], parts.slice(0, partIndex + 1), true).join('/');
            return (_jsx("li", __assign({ className: "breadcrumb-item" }, { children: part !== 'Dashboard' ? _jsx(NavLink, __assign({ to: path }, { children: part })) : _jsx("span", { children: part }) }), partIndex));
        };
        return _this;
    }
    breadcrumb.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsx(Breadcrumb, { children: this.breadcrumb(window.location.href.split('/')) }) }));
    };
    return breadcrumb;
}(Component));
export default breadcrumb;
