import styled, { css } from 'styled-components';

import { IFlexboxProps } from './Flexbox.types';

const Flexbox = styled.div<IFlexboxProps>`
  ${({
    flex,
    display,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
    alignSelf,
    gap,
    rowGap,
    columnGap,
    flexBasis,
    flexGrow,
    flexShrink,
    order,
  }) => css`
    flex: ${flex};
    display: ${display ?? 'flex'};
    ${flexDirection && `flex-direction: ${flexDirection}`};
    ${flexWrap && `flex-wrap: ${flexWrap}`};
    ${flexBasis && `flex-basis: ${flexBasis}`};
    ${flexGrow && `flex-grow: ${flexGrow}`};
    ${flexShrink && `flex-shrink: ${flexShrink}`};
    ${justifyContent && `justify-content: ${justifyContent}`};
    ${alignItems && `align-items: ${alignItems}`};
    ${alignContent && `align-content: ${alignContent}`};
    ${alignSelf && `align-self: ${alignSelf}`};
    ${gap && `gap: ${gap}`};
    ${rowGap && `row-gap: ${rowGap}`};
    ${columnGap && `column-gap: ${columnGap}`};
    ${order && `order: ${order}`};
  `}
`;

export default Flexbox;
