import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Weekday from 'dayjs/plugin/weekday';
import SameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import LocaleData from 'dayjs/plugin/localeData';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Router } from './routes';
import './assets/fonts.css';
import { AppContainer } from './components/app-container';
import { store } from './ducks/store';
import { useDetectSystemTheme } from 'common/hooks/detect-system-theme/use-detect-system-theme';
dayjs.extend(Weekday);
dayjs.extend(LocaleData);
dayjs.extend(SameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
var StyledAppWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"], ["\n  height: 100vh;\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.section;
}, function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
});
var DARKTHEME = 'dark';
var LIGHTTHEME = 'light';
var SYSTEMTHEME = 'System';
export var App = function () {
    var storedTheme = localStorage.getItem('sdsTheme');
    var isSystemDark = useDetectSystemTheme();
    if (!storedTheme) {
        localStorage.setItem('sdsTheme', DARKTHEME);
        storedTheme = DARKTHEME;
    }
    else if (storedTheme === SYSTEMTHEME) {
        storedTheme = isSystemDark ? DARKTHEME : LIGHTTHEME;
    }
    var _a = useState(storedTheme), theme = _a[0], setTheme = _a[1];
    var location = useLocation();
    var search = location.search;
    var params = new URLSearchParams(search);
    var themeFromQueryParam = params.get('theme');
    useEffect(function () {
        if (themeFromQueryParam) {
            var themeToSet = themeFromQueryParam;
            localStorage.setItem('sdsTheme', themeToSet);
            if (themeToSet === SYSTEMTHEME) {
                themeToSet = isSystemDark ? DARKTHEME : LIGHTTHEME;
            }
            setTheme(themeToSet);
        }
    }, [themeFromQueryParam, setTheme, isSystemDark]);
    return (_jsx(ShellThemeProvider, __assign({ theme: theme.toLowerCase() }, { children: _jsx(StyledAppWrapper, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(AppContainer, { children: _jsx(Router, {}) }) })) }) })));
};
var templateObject_1;
