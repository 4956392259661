import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { noop } from 'lodash';
import React, { useState, createContext, useContext, useEffect, useMemo, } from 'react';
import { getSourceFilter } from '../../../../services/urlService';
import { dataContextStore } from './dataContextStore';
export var ApiContext = createContext([{}, noop]);
export var useApiContext = function () { return useContext(ApiContext); };
var apiDataStoreCall = [
    'Countries',
    'Cities',
    'Companies',
    'AssetClasses',
    'Assets/AssetFilter',
    'AssetUnitTypes',
    'AssetUnitSubtypes',
];
export var ApiProvider = function (_a) {
    var children = _a.children;
    var _b = useState(__assign({}, dataContextStore)), apiParams = _b[0], setApiParams = _b[1];
    useEffect(function () {
        apiDataStoreCall.map(function (value) {
            getSourceFilter(value).then(function (response) {
                setApiParams(function (prevParams) {
                    var _a;
                    return __assign(__assign({}, prevParams), (_a = {}, _a[value] = response !== undefined ? response.data : [], _a));
                });
            });
        });
    }, []);
    var providerValue = useMemo(function () { return [apiParams, setApiParams]; }, [apiParams]);
    return _jsx(ApiContext.Provider, __assign({ value: providerValue }, { children: children }));
};
