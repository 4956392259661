import styled from 'styled-components';

const gap = 1;

export const Key = styled.div`
  width: 17.55px;
  height: 17.55px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 0.5px;
  display: flex;
`;

export const Keys = styled.div`
  display: flex;
  column-gap: ${gap}px;
`;

export const Keypad = styled.div`
  display: flex;
  row-gap: ${gap}px;
  flex-direction: column;
  align-items: center;
`;
