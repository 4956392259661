import { Sizes } from '../../types/types';

type TextInputWrapperConstsProps = {
  iconSize: {
    [Sizes.Small]: string;
    [Sizes.Medium]: string;
    [Sizes.Large]: string;
  };
  affixPadding: {
    [Sizes.Small]: string;
    [Sizes.Medium]: string;
    [Sizes.Large]: string;
  };
};

export const textInputConsts = {
  padding: {
    [Sizes.Small]: '4px 10px',
    [Sizes.Medium]: '8px 12px',
    [Sizes.Large]: '11px 16px',
  },
  paddingWithTransparentAffix: {
    [Sizes.Small]: '8px',
    [Sizes.Medium]: '8px',
    [Sizes.Large]: '12px',
  },
};

export const textInputWrapperConsts: TextInputWrapperConstsProps = {
  iconSize: {
    [Sizes.Small]: '16px',
    [Sizes.Medium]: '20px',
    [Sizes.Large]: '24px',
  },
  affixPadding: {
    [Sizes.Small]: '8px',
    [Sizes.Medium]: '12px',
    [Sizes.Large]: '16px',
  },
};
