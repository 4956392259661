import { MenuSize } from './Menu.types';
import { Sizes } from '../../types/types';

type MenuConstsType = {
  [size in MenuSize]: {
    iconSize: number;
    indent: number;
    padding: number;
    gap: number;
    iconContainer: { width: number; height: number };
  };
};

export const menuConsts: MenuConstsType = {
  [Sizes.Small]: {
    iconSize: 20,
    indent: 14,
    padding: 8,
    gap: 8,
    iconContainer: { width: 20, height: 16 },
  },
  [Sizes.Medium]: {
    iconSize: 20,
    indent: 16,
    padding: 12,
    gap: 8,
    iconContainer: { width: 24, height: 16 },
  },
  [Sizes.Large]: {
    iconSize: 24,
    indent: 20,
    padding: 14,
    gap: 12,
    iconContainer: { width: 24, height: 20 },
  },
};
