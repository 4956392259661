import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from '@sede-x/shell-ds-react-framework';
import { useState, useEffect } from 'react';
import { StyledLoad, StyledDelayedText } from './Loader.styles';
export var Loader = function (_a) {
    var message = _a.message, delayMessage = _a.delayMessage;
    var _b = useState(false), delayed = _b[0], setDelayed = _b[1];
    useEffect(function () {
        var timeoutId = setTimeout(function () {
            setDelayed(true);
        }, 5000);
        return function () {
            clearTimeout(timeoutId);
        };
    }, []);
    return (_jsxs(StyledLoad, { children: [_jsx(Loading, { size: "medium" }), _jsx("span", { children: message }), delayed && (_jsx(StyledDelayedText, { children: delayMessage !== null && delayMessage !== void 0 ? delayMessage : 'This filter combination is not yet optimised and may take 5-20s to load' }))] }));
};
