import { __assign } from "tslib";
export var addHighlightFieldsToVersions = function (data) {
    var newData = data.map(function (item) { return (__assign(__assign({}, item), { hasBasisChanged: false, hasDataProviderChanged: false, hasIncCapChanged: false, hasProbChanged: false, hasStartDateChanged: false, hasStatusChanged: false })); });
    data.forEach(function (column, index) {
        if (index + 1 < data.length) {
            if (column.capacityBasisTypeDesc !== data[index + 1].capacityBasisTypeDesc) {
                newData[index].hasBasisChanged = true;
            }
            if (column.dataProviderAbbr !== data[index + 1].dataProviderAbbr) {
                newData[index].hasDataProviderChanged = true;
            }
            if (column.capacityUniversalQty !== data[index + 1].capacityUniversalQty) {
                newData[index].hasIncCapChanged = true;
            }
            if (column.planningProbabilityTypeDesc !== data[index + 1].planningProbabilityTypeDesc) {
                newData[index].hasProbChanged = true;
            }
            if (column.capacityStartDate !== data[index + 1].capacityStartDate) {
                newData[index].hasStartDateChanged = true;
            }
            if (column.planningStatusTypeDesc !== data[index + 1].planningStatusTypeDesc) {
                newData[index].hasStatusChanged = true;
            }
        }
    });
    return newData;
};
export var addHighlightFieldsToOriginals = function (data) {
    var newData = data.map(function (item) { return (__assign(__assign({}, item), { hasBasisChanged: false, hasDataProviderChanged: false, hasIncCapChanged: false, hasProbChanged: false, hasStartDateChanged: false, hasStatusChanged: false })); });
    data.forEach(function (column, index) {
        if (index + 1 < data.length) {
            if (column.capacityBasisType !== data[index + 1].capacityBasisType) {
                newData[index].hasBasisChanged = true;
            }
            if (column.capacityOrigQty !== data[index + 1].capacityOrigQty) {
                newData[index].hasIncCapChanged = true;
            }
            if (column.probability !== data[index + 1].probability) {
                newData[index].hasProbChanged = true;
            }
            if (column.startDate !== data[index + 1].startDate) {
                newData[index].hasStartDateChanged = true;
            }
            if (column.status !== data[index + 1].status) {
                newData[index].hasStatusChanged = true;
            }
            if (column.capacityBasisType !== data[index + 1].capacityBasisType) {
                newData[index].hasBasisChanged = true;
            }
        }
    });
    return newData;
};
