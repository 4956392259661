import { SelectSize } from './Select.types';
import { Sizes } from '../../types/types';

type SelectConstsType = {
  [size in SelectSize]: {
    iconRightOffset: number;
    iconTopOffset: number;
    topBottomOffset: number;
    leftOffset: number;
    tagTopBottomPadding: number;
    tagLeftPadding: number;
    tagRightPadding: number;
    multiplePadding: string;
    height: number;
  };
};

export const selectConsts: SelectConstsType = {
  [Sizes.Small]: {
    iconRightOffset: 6,
    iconTopOffset: 3,
    topBottomOffset: 4,
    leftOffset: 10,
    tagLeftPadding: 5,
    tagTopBottomPadding: 0,
    tagRightPadding: 2,
    multiplePadding: '0 5px',
    height: 32,
  },
  [Sizes.Medium]: {
    iconRightOffset: 8,
    iconTopOffset: 7,
    topBottomOffset: 8,
    leftOffset: 12,
    tagLeftPadding: 6,
    tagTopBottomPadding: 3,
    tagRightPadding: 5,
    multiplePadding: '0 6px',
    height: 40,
  },
  [Sizes.Large]: {
    iconRightOffset: 12,
    iconTopOffset: 11,
    topBottomOffset: 12,
    leftOffset: 16,
    tagLeftPadding: 8,
    tagTopBottomPadding: 4,
    tagRightPadding: 6,
    multiplePadding: '0 8px',
    height: 48,
  },
};
