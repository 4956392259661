import { getAccessToken } from './get-access-token';
export var getAccessTokenData = function () {
    var accessToken = getAccessToken();
    if (!accessToken) {
        return null;
    }
    try {
        var parsedAccessToken = JSON.parse(atob(accessToken.split('.')[1]));
        return parsedAccessToken;
    }
    catch (e) {
        return null;
    }
};
