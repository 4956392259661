import { __assign, __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
var Open = Icons.Open;
var StyledFormFieldInfo = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: x-small;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  color: ", ";\n  margin-bottom: 6px;\n  :hover {\n    color: ", ";\n  }\n"], ["\n  font-size: x-small;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  color: ", ";\n  margin-bottom: 6px;\n  :hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.focus.strong;
}, function (_a) {
    var theme = _a.theme;
    return theme.focus.strong;
});
var StyledOpen = styled(Open)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.focus.strong;
});
export var FormFieldInfoLink = function (_a) {
    var href = _a.href, text = _a.text;
    return (_jsxs(StyledFormFieldInfo, __assign({ href: href, target: "_blank", rel: "noreferrer" }, { children: [text, " ", _jsx(StyledOpen, {})] })));
};
var templateObject_1, templateObject_2;
