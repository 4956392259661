import styled, { css } from 'styled-components';

import { INavBarProps, INavBarPropsWithReqSize } from '../../NavBar.types';
import Pecten from '../../../Pecten/Pecten';
import { navBarSizes } from '../../NavBar.consts';

const Area = styled.div<INavBarPropsWithReqSize>(
  ({ size }) => css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 10px;
    padding: ${navBarSizes[size].pectenPadding}px;
    overflow: hidden;
  `,
);

const HeaderPecten = styled(Pecten)`
  flex-shrink: 0;
`;

const Header = styled.div<INavBarProps>(
  ({ collapsed }) => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transition: opacity 0.3s;

    ${collapsed &&
    css`
      opacity: 0;
    `}
  `,
);

export { Area, Header, HeaderPecten };
