import { __extends } from "tslib";
import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.azureBuildVersion;
var CacheBuster = /** @class */ (function (_super) {
    __extends(CacheBuster, _super);
    function CacheBuster(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: function () {
                console.log('Clearing cache and hard reloading...');
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
                            var name = names_1[_i];
                            caches.delete(name);
                        }
                    });
                }
                // delete browser cache and hard reload
                window.location.reload(true);
            },
        };
        return _this;
    }
    CacheBuster.prototype.componentDidMount = function () {
        var _this = this;
        fetch("/meta.json?".concat(new Date().getTime()), { cache: 'no-cache' })
            .then(function (response) { return response.json(); })
            .then(function (meta) {
            var latestVersion = meta.version;
            var currentVersion = global.appVersion;
            if (latestVersion !== currentVersion) {
                console.log("We have a new version. Should force refresh");
                _this.setState({ loading: false, isLatestVersion: false });
            }
            else {
                console.log("You already have the latest version. No cache refresh needed.");
                _this.setState({ loading: false, isLatestVersion: true });
            }
        });
    };
    CacheBuster.prototype.render = function () {
        var _a = this.state, loading = _a.loading, isLatestVersion = _a.isLatestVersion, refreshCacheAndReload = _a.refreshCacheAndReload;
        return this.props.children({ loading: loading, isLatestVersion: isLatestVersion, refreshCacheAndReload: refreshCacheAndReload });
    };
    return CacheBuster;
}(React.Component));
export default CacheBuster;
