import * as Styled from './FirstArea.styles';
import { INavBarFirstAreaProps, NavBarHeader } from '../../NavBar.types';
import Heading from '../../../Text/components/Heading/Heading';
import Label from '../../../Text/components/Label/Label';
import { Sizes, Prominences } from '../../../../types/types';
import { navBarSizes } from '../../NavBar.consts';

function isHeader(obj: INavBarFirstAreaProps['header']): obj is NavBarHeader {
  return !!obj && typeof obj === 'object' && ('title' in obj || 'description' in obj);
}

const FirstArea = ({ pectenArea, header, collapsed, size = Sizes.Medium }: INavBarFirstAreaProps) => (
  <Styled.Area size={size}>
    {pectenArea === 'default' ? <Styled.HeaderPecten size={navBarSizes[size].pectenSize} /> : pectenArea}
    {isHeader(header) ? (
      <Styled.Header collapsed={collapsed}>
        {!!header.title && (
          <Heading level={4} bold>
            {header.title}
          </Heading>
        )}
        {!!header.description && (
          <Label prominence={Prominences.Subtle} size={Sizes.ExtraSmall}>
            {header.description}
          </Label>
        )}
      </Styled.Header>
    ) : (
      <>{header}</>
    )}
  </Styled.Area>
);

export default FirstArea;
