import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
// import EmployeeType from "./employeeType";
import UserRoleType from './userRoleType';
import ItApplication from './itApplication';
import ItApplicationUser from './itApplicationUser';
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var accessandsecurityconfiguration = /** @class */ (function (_super) {
    __extends(accessandsecurityconfiguration, _super);
    function accessandsecurityconfiguration() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    accessandsecurityconfiguration.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Access And Security ", _jsx("span", { children: "(Configuration Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/accessAndSecurityConfiguration/userRoleType", className: "nav-link", activeClassName: "active" }, { children: "User Role Type" })), _jsx(NavLink, __assign({ to: "/dashboard/accessAndSecurityConfiguration/itApplication", className: "nav-link", activeClassName: "active" }, { children: "IT Application" })), _jsx(NavLink, __assign({ to: "/dashboard/accessAndSecurityConfiguration/itApplicationUser", className: "nav-link", activeClassName: "active" }, { children: "IT Application User" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/accessandsecurityconfiguration", to: "/dashboard/accessAndSecurityConfiguration/userRoleType", exact: true }), _jsx(Route, { path: "/dashboard/accessAndSecurityConfiguration/userRoletype", component: UserRoleType, exact: true }), _jsx(Route, { path: "/dashboard/accessAndSecurityConfiguration/itApplication", component: ItApplication, exact: true }), _jsx(Route, { path: "/dashboard/accessAndSecurityConfiguration/itApplicationUser", component: ItApplicationUser, exact: true })] }) }))] }))] })) }));
    };
    return accessandsecurityconfiguration;
}(Component));
export default accessandsecurityconfiguration;
