import { css } from 'styled-components';

import { MENU_CLASS } from '../../../utils/constants';

const commonMotion = css`
  animation-duration: 0.3s;
  animation-fill-mode: both;
`;

export const motionStyle = css`
  .${MENU_CLASS} {
    &-collapse {
      overflow: hidden;
      transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    &-collapse-enter,
    &-collapse-appear {
      opacity: 0;
    }

    &-collapse-leave {
      opacity: 1;
    }
  }

  &.${MENU_CLASS} {
    &-slide-up-enter,
    &-slide-up-appear {
      ${commonMotion};
      opacity: 0;
      transform: scaleY(0);
      animation-timing-function: ease-in-out;
      animation-play-state: paused;
    }

    &-slide-up-leave {
      ${commonMotion};
      opacity: 1;
      transform: scaleY(1);
      animation-timing-function: ease-in-out;
      animation-play-state: paused;
    }

    &-slide-up-enter-active,
    &-slide-up-appear-active {
      animation-name: slide-up-in;
      animation-play-state: running;
    }

    &-slide-up-leave-active {
      animation-name: slide-up-out;
      animation-play-state: running;
    }

    @keyframes slide-up-in {
      0% {
        opacity: 0;
        transform: scaleY(0.8);
      }

      100% {
        opacity: 1;
        transform: scaleY(1);
      }
    }

    @keyframes slide-up-out {
      0% {
        opacity: 1;
        transform: scaleY(1);
      }

      100% {
        opacity: 0;
        transform: scaleY(0.8);
      }
    }

    &-zoom-enter,
    &-zoom-appear {
      ${commonMotion};
      transform-origin: 0% 0%;
      transform: scale(0);
      opacity: 0;
      animation-timing-function: ease-in-out;
      animation-play-state: paused;
    }

    &-zoom-leave {
      ${commonMotion};
      transform-origin: 0% 0%;
      animation-timing-function: ease-in-out;
      animation-play-state: paused;
    }

    &-zoom-enter-active,
    &-zoom-appear-active {
      animation-name: open-zoom-in;
      animation-play-state: running;
    }

    &-zoom-leave-active {
      animation-name: open-zoom-out;
      animation-play-state: running;
    }

    @keyframes open-zoom-in {
      0% {
        opacity: 0;
        transform: scale(0, 0);
      }

      100% {
        opacity: 1;
        transform: scale(1, 1);
      }
    }

    @keyframes open-zoom-out {
      0% {
        opacity: 1;
        transform: scale(1, 1);
      }

      100% {
        opacity: 0;
        transform: scale(0, 0);
      }
    }
  }
`;
