import { __assign, __spreadArray } from "tslib";
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Text, Tooltip } from '@sede-x/shell-ds-react-framework';
import { useTheme } from 'styled-components';
import { loadingPlaceholder, useResolveSelectOnChange } from './MaintenanceFilter.hooks';
import { FilterFieldWrapper, StyledLabel } from './MaintenanceFilter.styles';
import { Select } from 'components/select/Select';
import { FilterRow } from 'common/styles/FilterStyles';
import { useGetAssetOutageGroupQuery, useGetEventCauseTypesQuery } from 'common/ducks/maintenanceApis';
import { mapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
export var TrendsFilters = function (_a) {
    var form = _a.form, enableApply = _a.enableApply;
    var theme = useTheme();
    var watch = form.watch;
    var resolveSelectOnChange = useResolveSelectOnChange(form);
    var watchEventCauseType = watch('eventCauseTypeKey');
    var watchAssetOutageGroupType = watch('assetOutageGroupKey');
    var watchDerateCapacityInd = watch('derateCapacityInd');
    var _b = useGetEventCauseTypesQuery(), _c = _b.data, eventCause = _c === void 0 ? [] : _c, isFetchingEventCause = _b.isFetching;
    var eventCauseOptionsWithoutBlanks = useMemo(function () { return eventCause.map(mapSelectData('eventCauseTypeDesc')); }, [eventCause]);
    var eventCauseOptions = __spreadArray([{ key: 0, children: '(Blank)' }], eventCauseOptionsWithoutBlanks, true).sort(sortSelectData);
    var _d = useGetAssetOutageGroupQuery(), _e = _d.data, assetOutageGroup = _e === void 0 ? [] : _e, isFetchingAssetOutageGroup = _d.isFetching;
    var assetOutageGroupOptions = useMemo(function () { return assetOutageGroup.map(mapSelectData('assetOutageGroupDesc')).sort(sortSelectData); }, [assetOutageGroup]);
    var derateCapacityIndOptions = [
        {
            key: 'N',
            children: 'N',
        },
        {
            key: 'Y',
            children: 'Y',
        },
    ];
    var config = [
        {
            label: 'Event cause',
            placeholder: loadingPlaceholder(isFetchingEventCause),
            id: 'maintenance-filter-event-cause',
            data: eventCauseOptions,
            value: watchEventCauseType,
            onChange: resolveSelectOnChange('eventCauseTypeKey'),
            disabled: isFetchingEventCause,
        },
        {
            label: 'Outage type',
            placeholder: loadingPlaceholder(isFetchingAssetOutageGroup),
            id: 'maintenance-filter-asset-outage',
            data: assetOutageGroupOptions.map(function (options) {
                var _a;
                var outageDescription = (_a = options.children) === null || _a === void 0 ? void 0 : _a.toString().split(' - ');
                return __assign(__assign({}, options), { children: (_jsxs(_Fragment, { children: [outageDescription === null || outageDescription === void 0 ? void 0 : outageDescription[0], (outageDescription === null || outageDescription === void 0 ? void 0 : outageDescription[1]) && (_jsxs("span", __assign({ style: { color: theme.text.onSurface.subtle } }, { children: [" - ", outageDescription === null || outageDescription === void 0 ? void 0 : outageDescription[1]] })))] })) });
            }),
            value: watchAssetOutageGroupType,
            onChange: resolveSelectOnChange('assetOutageGroupKey'),
            disabled: isFetchingAssetOutageGroup,
        },
        {
            label: 'Derated',
            placeholder: loadingPlaceholder(false),
            id: 'maintenance-filter-derate-ind',
            data: derateCapacityIndOptions,
            value: watchDerateCapacityInd,
            onChange: resolveSelectOnChange('derateCapacityInd'),
            disabled: false,
            tooltip: (_jsx(Tooltip, { overlay: _jsxs("div", __assign({ style: { padding: '8px' } }, { children: [_jsxs(Text, __assign({ prominence: "subtle" }, { children: ["When a unit's maintenance offline % is greater than 0% ", _jsx("br", {}), "(online) and less than 100 % (offline), it is said to be"] })), ' ', "derated."] })), placement: "bottom left", trigger: _jsx("span", __assign({ style: { margin: '0 0 2px 4px' } }, { children: _jsx("svg", __assign({ width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77818 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77818 12.8333 6.99984 12.8333ZM6.70817 5.24996C6.54709 5.24996 6.4165 5.11938 6.4165 4.95829V4.37496C6.4165 4.21388 6.54709 4.08329 6.70817 4.08329H7.2915C7.45259 4.08329 7.58317 4.21388 7.58317 4.37496V4.95829C7.58317 5.11938 7.45259 5.24996 7.2915 5.24996H6.70817ZM6.70817 9.91663C6.54709 9.91663 6.4165 9.78604 6.4165 9.62496V6.70829C6.4165 6.54721 6.54709 6.41663 6.70817 6.41663H7.2915C7.45259 6.41663 7.58317 6.54721 7.58317 6.70829V9.62496C7.58317 9.78604 7.45259 9.91663 7.2915 9.91663H6.70817Z", fill: "#4B78AA" }) })) })) })),
        },
    ];
    return (_jsx(_Fragment, { children: config.map(function (el) {
            return (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: el.label })), el.tooltip, _jsx(FilterFieldWrapper, { children: _jsx(Select, { size: "small", mode: "multiple", placeholder: el.placeholder, id: el.id, data: el.data, value: el.value, onChange: function (e) {
                                enableApply();
                                el.onChange(e);
                            }, disabled: el.disabled }) })] }, el.id));
        }) }));
};
