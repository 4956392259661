var START_DATE = 'Start date';
var measureUnitCd = 'Capacity unit';
var UPDATED_BY = 'Updated by';
var INC_CAP = 'Inc. capacity';
export var displayNameMapping = {
    maintenanceEventDataList: {
        startDate: START_DATE,
        endDate: 'End date',
        asset: 'Asset',
        assetKey: 'Asset key',
        unitType: 'Unit type',
        unitSubType: 'Unit subtype',
        unit: 'Unit name',
        unitKey: 'Unit key',
        offlinePCT: 'Offline %',
        totalCapacity: 'Total capacity',
        measureUnitCd: measureUnitCd,
        eventType: 'Event type',
        eventCauseType: 'Event cause',
        updateDate: 'Updated date',
        editedBy: UPDATED_BY,
        eventLockInd: 'Locked',
        dataProviderName: 'Source',
        comment: 'Comment',
    },
    capacityEventDataList: {
        startDate: START_DATE,
        asset: 'Asset',
        assetKey: 'Asset key',
        allAssetNames: 'Other asset names',
        company: 'Company',
        country: 'Country',
        city: 'City',
        rtl: 'RTL',
        rtt: 'RTT',
        unitSubType: 'Unit subtype',
        unit: 'Unit name',
        unitKey: 'Unit key',
        capacityOrigQty: INC_CAP,
        measureUnitCd: measureUnitCd,
        capacityBasisType: 'Basis type',
        probability: 'Probability',
        status: 'Status',
        updateDate: 'Updated date',
        editedBy: UPDATED_BY,
        source: 'Source',
        intelligenceSourceTxt: 'Comment',
    },
    maintenanceOriginals: {
        id: 'Asset ID',
        assetUnitEventKey: 'Asset unit event key',
        versionEffectiveDate: 'Update date',
        offlineCapacityPct: 'Offline %',
        eventStartDate: START_DATE,
        eventEndDate: 'End date',
        eventType: 'Event type',
        eventTypeKey: 'Event type key',
        eventCauseType: 'Event cause',
        eventCauseTypeKey: 'Event cause key',
        dataProvider: 'Source',
        dataProviderKey: 'Source key',
        comment: 'Comment',
        eventLockInd: 'Locked',
        updatedBy: UPDATED_BY,
        createdBy: 'Created by',
    },
    capacityVersions: {
        versionEffectiveDate: 'Version date',
        updatedBy: 'Edited by',
        capacityStartDate: START_DATE,
        capacityUniversalQty: INC_CAP,
        measureUnitCd: measureUnitCd,
        planningProbabilityTypeDesc: 'Probability',
        capacityBasisTypeDesc: 'Basis',
        planningStatusTypeDesc: 'Status',
        dataProviderAbbr: 'Source',
    },
    capacityOriginals: {
        source: 'Source',
        startDate: START_DATE,
        capacityOrigQty: INC_CAP,
        measureUnitCd: measureUnitCd,
        probability: 'Probability',
        capacityBasisType: 'Basis',
        status: 'Status',
        updateDate: 'Last update',
    },
    relatedEvents: {
        startDate: START_DATE,
        asset: 'Asset',
        assetKey: 'Asset key',
        capacityOrigQty: INC_CAP,
        totalCapacity: 'Total capacity',
        measureUnitCd: measureUnitCd,
        company: 'Company',
        companyKey: 'Company key',
        unit: 'Unit name',
        unitKey: 'Unit key',
        source: 'Provider',
        providerAssetId: 'Provider asset ID',
        providerAssetUnitId: 'Provider unit ID',
        providerAssetCapacityQty: 'Provider capacity',
        versionType: 'Version type',
        probability: 'Probability',
        capacityBasisType: 'Basis',
        status: 'Status',
        updateDate: 'Last date',
    },
};
