import { ToggleSize } from './Toggle.types';
import { Sizes } from '../../types/types';

type ToggleConstsProps = {
  [size in ToggleSize]: {
    width: number;
    height: number;
    labelSpacing: number;
    circle: {
      size: number;
      pressedSize: number;
      onPosition: number;
      onBackPosition: number;
      offset: number;
    };
  };
};

export const toggleConsts: ToggleConstsProps = {
  [Sizes.ExtraSmall]: {
    width: 20,
    height: 12,
    labelSpacing: 8,
    circle: {
      size: 8,
      pressedSize: 10,
      onPosition: 8,
      onBackPosition: 20,
      offset: 2,
    },
  },
  [Sizes.Small]: {
    width: 28,
    height: 16,
    labelSpacing: 8,
    circle: {
      size: 12,
      pressedSize: 16,
      onPosition: 12,
      onBackPosition: 20,
      offset: 2,
    },
  },
  [Sizes.Medium]: {
    width: 44,
    height: 24,
    labelSpacing: 10,
    circle: {
      size: 20,
      pressedSize: 26,
      onPosition: 20,
      onBackPosition: 20,
      offset: 2,
    },
  },
  [Sizes.Large]: {
    width: 56,
    height: 28,
    labelSpacing: 12,
    circle: {
      size: 24,
      pressedSize: 32,
      onPosition: 28,
      onBackPosition: 20,
      offset: 2,
    },
  },
};
