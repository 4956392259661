import { Sizes } from '../../types/types';
import { NavBarSize } from './NavBar.types';

type NavBarSizesType = {
  [size in NavBarSize]: {
    height: number;
    pectenSize: number;
    pectenPadding: number;
  };
};

export const navBarSizes: NavBarSizesType = {
  [Sizes.Small]: {
    height: 48,
    pectenSize: 24,
    pectenPadding: 12,
  },
  [Sizes.Medium]: {
    height: 56,
    pectenSize: 28,
    pectenPadding: 14,
  },
  [Sizes.Large]: {
    height: 64,
    pectenSize: 32,
    pectenPadding: 16,
  },
};
