import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { noop } from 'lodash';
import Filter from './ProgressiveFilter';
import Capacity from './Capacity';
import Maintenance from './Maintenance';
var setCapacityMaintenanceDisplay = function (capacityDisplay, maintenanceDisplay) {
    var maintenance = document.querySelector('.maintenance-event');
    var capacity = document.querySelector('.capacity-event');
    if (maintenance)
        maintenance.style.display = maintenanceDisplay;
    if (capacity)
        capacity.style.display = capacityDisplay;
};
/*
 *function random code generator
 */
function getRandom() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
export var AddEditEventWindow = function (_a) {
    var onClose = _a.onClose, setUnit = _a.setUnit, selectedData = _a.selectedData, setSelectedData = _a.setSelectedData, dropDownDataArea = _a.dropDownDataArea, allFilters = _a.allFilters, capacity = _a.capacity, newAddEditBt = _a.newAddEditBt, onApply = _a.onApply, setShouldRefresh = _a.setShouldRefresh, isIncCap = _a.isIncCap, maintenance = _a.maintenance, currentYearData = _a.currentYearData, setWindow = _a.setWindow, unit = _a.unit;
    var _b = useState(!!maintenance), unitOnly = _b[0], setUnitOnly = _b[1];
    var maintenanceRefs = useRef(null);
    var capacityRefs = useRef(null);
    var progressiveFilterRefs = useRef(null);
    var _c = useState(false), switched = _c[0], setSwitched = _c[1];
    var filterRefreshRandomKey = useState(getRandom())[0];
    /*
     *function to close the pop up, works on canceling too
     */
    function handleOnClose() {
        setWindow(false);
        // @ts-expect-error: TODO: ignoring typescript check here while ProgressiveFilter isn't coverted to Typescript
        progressiveFilterRefs.current.onClose();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }
    /*
     *function to show the data area
     */
    function showEvents() {
        if (unitOnly) {
            setCapacityMaintenanceDisplay('none', 'block');
        }
        else {
            setCapacityMaintenanceDisplay('block', 'none');
        }
    }
    /*
     *function to enable capacity
     */
    function handleCapacityBtnClick() {
        setCapacityMaintenanceDisplay('block', 'none');
        setUnitOnly(false);
        setSwitched(true);
    }
    /*
     *function to enable maintenance
     */
    function handleMaintenanceBtnClick() {
        setCapacityMaintenanceDisplay('none', 'block');
        setUnitOnly(true);
        setSwitched(true);
    }
    /*
     *component did mount using useeffect, this used to determine the which screen either capacity or maintenance
     */
    useEffect(function () {
        setUnitOnly(!!maintenance);
    }, [setWindow, capacity, maintenance]);
    /*
     *component did mount using useeffect, this used to determine the which data area needs to be enabled either capacity or maintenance
     */
    useEffect(function () {
        if (unit.length === 0) {
            setCapacityMaintenanceDisplay('none', 'none');
        }
    }, [unit]);
    return (_jsxs(Window, __assign({ title: _jsxs("div", { children: [_jsx("div", __assign({ className: 'events-title mb-4' }, { children: _jsx("div", { children: "Add/Edit an Event" }) })), _jsxs("ul", __assign({ className: "d-flex align-items-end mt-1 events-process" }, { children: [_jsx("li", __assign({ className: !unitOnly ? 'capacity-event-tab-btn mr-3 pb-3 active' : 'capacity-event-tab-btn mr-3 pb-3', onClick: handleCapacityBtnClick }, { children: "Capacity Event" })), _jsx("li", __assign({ className: unitOnly ? 'maintenance-event-tab-btn pb-3 active' : 'maintenance-event-tab-btn pb-3', onClick: handleMaintenanceBtnClick }, { children: "Maintenance Event" }))] }))] }), style: { left: '2%', top: '8px' }, onClose: handleOnClose, 
        // @ts-expect-error: there's a problem in Window type definition, it can accept string for initialWidth as well
        initialWidth: '95%', initialHeight: 900 }, { children: [_jsx("div", __assign({ className: "event-filter filter background-filter-fill p-3 border-bottom", style: { position: 'relative' } }, { children: _jsx("div", __assign({ className: "row pt-1 pb-1 mr-2" }, { children: _jsx(Filter, { ref: progressiveFilterRefs, 
                        // @ts-expect-error: TODO remove this when Filter becomes Typescript
                        unitOnly: unitOnly, setUnit: setUnit, showEvents: showEvents, random: filterRefreshRandomKey, selectedData: selectedData, selectedDataReset: setSelectedData, setEnableSaveButton: noop, dropDownDataArea: dropDownDataArea, allFilters: allFilters, switched: switched, setSwitched: setSwitched }) })) })), _jsx("div", __assign({ className: "capacity-event", style: { display: 'none', position: 'relative', height: '83%' } }, { children: _jsx(Capacity, { ref: capacityRefs, 
                    // @ts-expect-error: TODO remove this when Capacity becomes Typescript
                    unitOnly: unitOnly, setEnableSaveButton: noop, eventKey: Object.keys(selectedData).length > 0 && capacity ? selectedData.eventKey : false, onSaveRefreshIndex: Object.keys(selectedData).length > 0 && capacity ? selectedData.refreshIndex : false, newEdit: newAddEditBt, onApply: onApply, onClickCancel: handleOnClose, setOverlay: noop, setShouldRefresh: setShouldRefresh, isIncCap: isIncCap }) })), _jsx("div", __assign({ className: "maintenance-event", style: { display: 'none', position: 'relative', height: '83%' } }, { children: _jsx(Maintenance, { ref: maintenanceRefs, 
                    // @ts-expect-error: TODO remove this when Maintenance becomes Typescript
                    historyYearRange: 2, unitOnly: unitOnly, setEnableSaveButton: noop, eventKey: Object.keys(selectedData).length > 0 && maintenance ? selectedData.eventKey : false, onClickCancel: handleOnClose, newEdit: newAddEditBt, onApply: onApply, setOverlay: noop, currentYearData: currentYearData }) }))] })));
};
