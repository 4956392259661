import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Flexbox } from '@sede-x/shell-ds-react-framework';
import { useCallback, useEffect, useState } from 'react';
import { StyledColumnContainer, StyledConfigurationModal } from './ConfigureColumns.styles';
import { ConfigureColumnsFooter } from './ConfigureColumnsFooter';
var ConfigureColumns = function (_a) {
    var open = _a.open, onClose = _a.onClose, table = _a.table, defaultSelectedColumns = _a.defaultSelectedColumns, groupIndex = _a.groupIndex, description = _a.description, localStorageId = _a.localStorageId;
    var _b = useState(__spreadArray([], defaultSelectedColumns, true)), selectedColumns = _b[0], setSelectedColumns = _b[1];
    var readSavedColumnsFromStorageAndApply = function () {
        var allColumns = table.getAllColumns().map(function (column) { return column.id; });
        var savedColumnIds = JSON.parse(localStorage.getItem(localStorageId) || '[]');
        setSelectedColumns(savedColumnIds);
        var initialColumnVisibility = allColumns.reduce(function (visibilityState, columnId) {
            visibilityState[columnId] = savedColumnIds.includes(columnId);
            return visibilityState;
        }, {});
        if (!initialColumnVisibility['Actions']) {
            initialColumnVisibility['Actions'] = true;
        }
        table.setState(function (state) { return (__assign(__assign({}, state), { columnVisibility: initialColumnVisibility })); });
    };
    useEffect(function () {
        if (localStorage.getItem(localStorageId) === null) {
            var columIds = table
                .getAllColumns()
                .map(function (column) { return column.id; })
                .filter(function (id) { return selectedColumns.includes(id); });
            localStorage.setItem(localStorageId, JSON.stringify(columIds));
        }
        readSavedColumnsFromStorageAndApply();
    }, [table]);
    var handleSelectedColumnChange = useCallback(function (checked, columnId) {
        if (checked) {
            setSelectedColumns(function (prev) { return __spreadArray(__spreadArray([], prev, true), [columnId], false); });
        }
        else {
            setSelectedColumns(function (prev) { return prev.filter(function (id) { return id !== columnId; }); });
        }
    }, []);
    var handleApply = function () {
        localStorage.setItem(localStorageId, JSON.stringify(selectedColumns));
        readSavedColumnsFromStorageAndApply();
        onClose();
    };
    var handleClose = function () {
        var savedColumnIds = JSON.parse(localStorage.getItem(localStorageId) || '[]');
        setSelectedColumns(savedColumnIds);
        onClose();
    };
    var handleReset = function () {
        setSelectedColumns(__spreadArray([], defaultSelectedColumns, true));
    };
    return (_jsx(StyledConfigurationModal, __assign({ open: open, onClose: handleClose, title: "Configure columns", description: description, footer: _jsx(ConfigureColumnsFooter, { onCancel: handleClose, onReset: handleReset, onApply: handleApply }) }, { children: _jsx(StyledColumnContainer, { children: table.getAllLeafColumns().map(function (column, index) {
                return (_jsxs("div", { children: [_jsx(Flexbox, __assign({ style: { paddingBottom: 0 } }, { children: _jsx(Checkbox, { checked: selectedColumns.includes(column.id), disabled: !column.getCanHide(), onChange: function (e) { return handleSelectedColumnChange(e.target.checked, column.id); }, label: column.id }) })), groupIndex.includes(index) && _jsx("hr", { style: { marginTop: 8 } })] }, column.id));
            }) }) })));
};
export default ConfigureColumns;
