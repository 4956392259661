import { __assign, __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import round from 'lodash/round';
export var LastUpdatedButton = forwardRef(function (_a, ref) {
    var _b = _a.highlight, highlight = _b === void 0 ? false : _b, capacityQty = _a.capacityQty, capacityUom = _a.capacityUom, className = _a.className, rest = __rest(_a, ["highlight", "capacityQty", "capacityUom", "className"]);
    return (_jsx("button", __assign({ className: classnames([
            'last-updated-btn',
            'k-link btnlink-height',
            className || 'tdbgc',
            { 'since-last-update': highlight },
        ]), ref: ref }, rest, { children: capacityQty ? "".concat(round(capacityQty, 1), " ").concat(capacityUom) : '' })));
});
LastUpdatedButton.displayName = 'LastUpdatedButton';
