import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import { createAssetObject, getDefaultFormValues, getIsFormDirty } from './AddEditAssetDrawer.utils';
import { AssetDetailsSection } from './components/AssetDetailsSection';
import { OwnershipDetailsSection } from './components/OwnershipDetailsSection';
import { AssetLocationSection } from './components/AssetLocationSection';
import { IntelligenceSection } from './components/IntelligenceSection';
import { BaseDrawer } from 'components/base-drawer';
import { BaseDrawerForm } from 'components/base-drawer-form';
import { BaseDrawerFormFooter } from 'components/base-drawer-form-footer';
import { useAddAssetMutation, useEditAssetMutation, useGetAssetCompanyQuery } from 'common/ducks/assetCatalogApis';
export var AddEditAssetDrawer = function (_a) {
    var _b;
    var open = _a.open, handleDrawerClose = _a.handleDrawerClose, assetToEdit = _a.assetToEdit;
    var _c = useAddAssetMutation(), addAsset = _c[0], isAddingAsset = _c[1].isLoading;
    var _d = useEditAssetMutation(), editAsset = _d[0], isEditingAsset = _d[1].isLoading;
    var _e = useGetAssetCompanyQuery((_b = assetToEdit === null || assetToEdit === void 0 ? void 0 : assetToEdit.assetKey) !== null && _b !== void 0 ? _b : 0, { skip: !assetToEdit }), _f = _e.data, ownershipDetails = _f === void 0 ? [] : _f, isFetchingAssetCompany = _e.isFetching;
    var _g = useState(), error = _g[0], setError = _g[1];
    var errorRef = useRef(null);
    var form = useForm({
        defaultValues: getDefaultFormValues(),
    });
    var handleSubmit = form.handleSubmit, reset = form.reset, watch = form.watch;
    useEffect(function () {
        if (assetToEdit) {
            var formValues = getDefaultFormValues(assetToEdit, ownershipDetails);
            reset(formValues);
        }
    }, [assetToEdit, reset, ownershipDetails]);
    var onClose = function () {
        reset({ ownershipDetails: [{ equityShare: '' }], assetName: '' });
        setError(undefined);
        handleDrawerClose();
    };
    var onFormSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var submitObject, error_1, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    submitObject = createAssetObject(e, assetToEdit, ownershipDetails);
                    if (!assetToEdit) return [3 /*break*/, 5];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, editAsset(submitObject).unwrap()];
                case 2:
                    _c.sent();
                    onClose();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    if (typeof error_1.data.detail === 'string') {
                        setError([error_1.data.detail]);
                    }
                    else {
                        setError(error_1.data.detail.map(function (err) { return err.loc.join('-') + ' - ' + err.msg; }));
                    }
                    return [3 /*break*/, 4];
                case 4:
                    (_a = errorRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
                    return [2 /*return*/];
                case 5:
                    _c.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, addAsset(submitObject).unwrap()];
                case 6:
                    _c.sent();
                    onClose();
                    return [3 /*break*/, 8];
                case 7:
                    error_2 = _c.sent();
                    if (typeof error_2.data.detail === 'string') {
                        setError([error_2.data.detail]);
                    }
                    else {
                        setError(error_2.data.detail.map(function (err) { return err.loc.join('-') + ' - ' + err.msg; }));
                    }
                    (_b = errorRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
                    return [2 /*return*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var isFormDirty = getIsFormDirty(watch);
    var title = assetToEdit ? 'Edit asset' : '';
    var subtitle = assetToEdit ? assetToEdit.assetNm : 'New asset';
    return (_jsxs(BaseDrawer, __assign({ title: title, subtitle: subtitle, open: open, onClose: onClose }, { children: [error === null || error === void 0 ? void 0 : error.map(function (err, index) { return (_jsxs(_Fragment, { children: [_jsx(Alert, __assign({ ref: errorRef, dismissible: false, sentiment: Sentiments.Negative }, { children: err }), err + index), _jsx("hr", {})] })); }), _jsxs(BaseDrawerForm, __assign({ onSubmit: handleSubmit(onFormSubmit), footer: _jsx(BaseDrawerFormFooter, { cancelDisabled: isAddingAsset || isEditingAsset, onCancelClick: onClose, saveDisabled: isAddingAsset || isEditingAsset || !isFormDirty, showSavingLoader: isAddingAsset || isEditingAsset }) }, { children: [_jsx(AssetDetailsSection, { open: open, form: form }), _jsx(OwnershipDetailsSection, { open: open, form: form, isLoading: isFetchingAssetCompany }), _jsx(AssetLocationSection, { open: open, form: form }), _jsx(IntelligenceSection, { open: open, form: form, assetToEdit: assetToEdit })] }))] })));
};
