import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../../commons/crossReferenceGridTemplate';
var assetUnit = /** @class */ (function (_super) {
    __extends(assetUnit, _super);
    function assetUnit(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            intelligenceSourceTypeCd: {},
        };
        var yesNoData = [{ ynValue: 'offlineLockedInd' }, { ynValue: 'officiallyAnnouncedInd' }];
        var foreginKeyParams = [
            {
                path: 'intelligentSourceType',
                key: 'intelligenceSourceTypeKey',
                cdName: 'intelligenceSourceTypeCd',
            },
            {
                path: 'operationalStatusType',
                key: 'operationalStatusTypeKey',
                cdName: 'operationalStatusTypeCd',
            },
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
            },
            {
                path: 'person',
                key: 'userKey',
                cdName: 'shellUserId',
            },
            {
                path: 'assetUnitSubtype',
                key: 'assetUnitSubTypeKey',
                cdName: 'assetUnitSubtypeCd',
            },
            {
                path: 'assets',
                key: 'assetKey',
                cdName: 'assetNm',
            },
            {
                path: 'dataSet',
                key: 'datasetKey',
                cdName: 'datasetNm',
            },
        ];
        var columns = [
            {
                field: 'id',
                title: 'Key',
                type: 'meta',
                editable: false,
            },
            {
                field: 'assetUnitNm',
                title: 'Name',
            },
            {
                field: 'assetUnitSubtypeCd',
                title: 'Subtype',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetUnitSubtypeCd',
                referenceTitle: 'Asset Unit Subtype',
                urlEndPoint: 'assetUnitSubtype',
            },
            {
                field: 'assetNm',
                title: 'Asset Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'assetNm',
                referenceTitle: 'Name',
                urlEndPoint: 'assets',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider Name',
                urlEndPoint: 'dataProvider',
                loadDependent: 'loadSource',
            },
            {
                field: 'datasetNm',
                title: 'Data set Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'datasetNm',
                referenceTitle: 'Dataset Nm',
                urlEndPoint: 'dataSet',
                loadDependecy: 'dataProviderKey',
                loadField: 'dataProviderAbbr',
            },
            {
                field: 'reportDt',
                title: 'Report Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'intelligenceSourceURL',
                title: 'Intelligence Source URL',
            },
            {
                field: 'intelligenceSourceTxt',
                title: 'Intelligence Source Text',
            },
            {
                field: 'intelligenceSourceTypeCd',
                title: 'Intelligence Source Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'intelligenceSourceTypeCd',
                referenceTitle: 'Intelligence Source Type Cd',
                urlEndPoint: 'intelligentSourceType',
            },
            {
                field: 'shellUserId',
                title: 'User',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
            },
            ,
            {
                field: 'officiallyAnnouncedInd',
                title: 'Offically Announced Ind',
                type: 'yesNo',
                ynRefer: 'officiallyAnnouncedInd',
                yn: 'yn',
            },
            {
                field: 'assetUnitStartDt',
                title: 'Start Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'assetUnitCloseDt',
                title: 'Close Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                editor: 'date',
            },
            {
                field: 'latitudeNum',
                title: 'Latitude',
            },
            {
                field: 'longitudeNum',
                title: 'Longitude',
            },
            {
                field: 'operationalStatusTypeCd',
                title: 'Operational Status',
                type: 'foreignKeyConstraint',
                referenceCd: 'operationalStatusTypeCd',
                referenceTitle: 'Operational Status Type Cd',
                urlEndPoint: 'operationalStatusType',
            },
            {
                field: 'petchemProcessNm',
                title: 'Petchem Process Name',
            },
            {
                field: 'sulphurAverageNum',
                title: 'Sulphur Average',
            },
            {
                field: 'apiGravityAverageNum',
                title: 'API Gravity Average',
            },
            {
                field: 'offlineLockedInd',
                title: 'Offline Locked ?',
                type: 'yesNo',
                ynRefer: 'offlineLockedInd',
                yn: 'yn',
            },
            {
                field: 'versionEffectiveDt',
                title: 'Effective Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'versionTerminationDt',
                title: 'Termination Date',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            yesNoData: yesNoData,
            foreginKeyParams: foreginKeyParams,
        };
        return _this;
    }
    assetUnit.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "assetUnit", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "id", yesNoData: this.state.yesNoData, foreginKeyParams: this.state.foreginKeyParams }));
    };
    return assetUnit;
}(Component));
export default assetUnit;
