import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Button, Tabs, Flexbox, Icons, Table, THead, TR, TH, TBody, TD } from '@sede-x/shell-ds-react-framework';
import { flexRender, getCoreRowModel, useReactTable, getExpandedRowModel } from '@tanstack/react-table';
import { useTheme } from 'styled-components';
import { StyledCapacityModal, StyledText } from './CapacityActionsModal.styles';
import { baselineHistoryColumns, originalsColumns } from './CapacityActions.config';
import { RelatedCapacityEvents } from './components/RelatedCapacityEvents';
import { addHighlightFieldsToVersions, addHighlightFieldsToOriginals } from './CapacityActions.utils';
import { capacityApis } from 'common/ducks/capacityApis';
import { Loader } from 'components/loader';
import { ModalExportButton } from 'components/modal-export-button/ModalExportButton';
var Time = Icons.Time, DatabaseAlt = Icons.DatabaseAlt, RigLandSolid = Icons.RigLandSolid;
var stickyWidth = [0, 114];
var getStickyStyles = function (index, stickyBackground, type) {
    if (index < 2) {
        return {
            position: 'sticky',
            zIndex: 1,
            left: stickyWidth[index],
            background: stickyBackground,
        };
    }
    if (type === 'header') {
        return {
            background: stickyBackground,
        };
    }
};
// eslint-disable-next-line complexity
export var CapacityActionsModal = function (_a) {
    var open = _a.open, closeModal = _a.closeModal, eventId = _a.eventId, assetKey = _a.assetKey, subtypeKey = _a.subtypeKey, activeTab = _a.activeTab;
    var tabKey = activeTab === '' ? 'history' : activeTab;
    var _b = useState(tabKey), activeTabKey = _b[0], setActiveTabKey = _b[1];
    var skip = !open;
    var _c = capacityApis.useGetCapacityVersionsQuery(eventId, {
        skip: skip,
    }), versionData = _c.data, isFetchingVersions = _c.isFetching;
    var _d = capacityApis.useGetCapacityOriginalsQuery(eventId, {
        skip: skip,
    }), originalsData = _d.data, isFetchingOriginals = _d.isFetching;
    var _e = capacityApis.useGetRelatedEventsQuery({
        assetKey: assetKey,
        unitSubtypeKey: subtypeKey,
    }, {
        skip: skip,
    }), relatedEventsData = _e.data, isFetchingRelatedEvents = _e.isFetching;
    var dataToExport, type, propertyName;
    var originalWithHighlights = [];
    if (originalsData) {
        originalWithHighlights = addHighlightFieldsToOriginals(originalsData);
    }
    var tanstackData = [];
    tanstackData[0] = __assign(__assign({}, originalWithHighlights[0]), { subRows: __spreadArray([], originalWithHighlights
            .slice(1, originalWithHighlights.length)
            .map(function (original) { return (__assign(__assign({}, original), { source: '' })); }), true) });
    var _f = useState({}), expanded = _f[0], setExpanded = _f[1];
    var table = useReactTable({
        data: tanstackData,
        columns: originalsColumns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            expanded: expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: function (row) { return row.subRows; },
        getExpandedRowModel: getExpandedRowModel(),
    });
    var versionDataWithHighlights = useMemo(function () { return addHighlightFieldsToVersions(versionData || []); }, [versionData]);
    var versionTable = useReactTable({
        data: versionDataWithHighlights,
        columns: baselineHistoryColumns,
        getCoreRowModel: getCoreRowModel(),
    });
    var theme = useTheme();
    var stickyBackground = theme.background.surface;
    var handleClose = function () {
        setActiveTabKey('history');
        closeModal();
    };
    useEffect(function () {
        if (activeTab !== '') {
            setActiveTabKey(activeTab);
        }
    }, [activeTab]);
    if (activeTabKey === 'originals') {
        dataToExport = originalsData;
        type = 'capacity-original-events';
        propertyName = 'capacityOriginals';
    }
    else if (activeTabKey === 'history') {
        dataToExport = versionData;
        type = 'capacity-baseline-history-events';
        propertyName = 'capacityVersions';
    }
    else {
        dataToExport = relatedEventsData;
        type = 'capacity-related-events';
        propertyName = 'relatedEvents';
    }
    return (_jsxs(StyledCapacityModal, __assign({ destroyOnClose: true, width: "80vw", open: open, onClose: handleClose, showHeader: false, footer: _jsx(Button, __assign({ variant: "outlined", size: "small", onClick: handleClose, style: { margin: '15px 25px 15px 0px' } }, { children: "Close" })) }, { children: [_jsx(ModalExportButton, { dataToExport: dataToExport !== null && dataToExport !== void 0 ? dataToExport : [], propertyName: propertyName, type: type, right: 60, top: 23 }, activeTabKey), _jsx(Tabs, { tabBarStyle: {
                    justifyContent: 'left',
                }, activeKey: activeTabKey, onTabClick: function (key) { return setActiveTabKey(key); }, items: [
                    {
                        label: (_jsxs(Flexbox, __assign({ gap: "5px", alignItems: "center", style: { fontSize: '16px' } }, { children: [_jsx(Time, { height: 20, width: 20 }), "Baseline history"] }))),
                        key: 'history',
                        children: (_jsxs(_Fragment, { children: [isFetchingVersions && _jsx(Loader, { message: "Fetching baseline history" }), !isFetchingVersions && (_jsx("div", __assign({ style: {
                                        position: 'relative',
                                        maxHeight: '60vh',
                                        maxWidth: '80vw',
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                    } }, { children: _jsxs(Table, { children: [_jsx(THead, { children: versionTable.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header, index) { return (_jsx(TH, __assign({ style: getStickyStyles(index, stickyBackground, 'header') }, { children: header.isPlaceholder
                                                            ? null
                                                            : flexRender(header.column.columnDef.header, header.getContext()) }), header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: versionTable.getRowModel().rows.map(function (row, index) { return (_jsxs(_Fragment, { children: [index === 0 && _jsx(StyledText, { children: "CURRENT BASELINE" }), index === 1 && _jsx(StyledText, { children: "BASELINE HISTORY" }), _jsx(TR, { children: row.getVisibleCells().map(function (cell, index) { return (_jsx(TD, __assign({ style: getStickyStyles(index, stickyBackground) }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id)); }) }, row.id)] })); }) })] }) })))] })),
                    },
                    {
                        label: (_jsxs(Flexbox, __assign({ gap: "5px", alignItems: "center", style: { fontSize: '16px' } }, { children: [_jsx(DatabaseAlt, { height: 20, width: 20 }), "Originals"] }))),
                        key: 'originals',
                        children: (_jsxs(_Fragment, { children: [isFetchingOriginals && _jsx(Loader, { message: "Fetching originals" }), !isFetchingOriginals && (_jsxs("div", __assign({ style: {
                                        position: 'relative',
                                        maxHeight: '60vh',
                                        maxWidth: '80vw',
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                    } }, { children: [_jsxs(Table, { children: [_jsx(THead, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header) { return (_jsx(TH, { children: header.isPlaceholder
                                                                ? null
                                                                : flexRender(header.column.columnDef.header, header.getContext()) }, header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: originalsData && originalsData.length > 0
                                                        ? table.getRowModel().rows.map(function (row) { return (_jsx(TR, { children: row.getVisibleCells().map(function (cell) { return (_jsx(TD, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)); }) }, row.id)); })
                                                        : null })] }), (originalsData === null || originalsData === void 0 ? void 0 : originalsData.length) === 0 && 'No originals exist for this event.'] })))] })),
                    },
                    {
                        label: (_jsxs(Flexbox, __assign({ gap: "5px", alignItems: "center", style: { fontSize: '16px' } }, { children: [_jsx(RigLandSolid, { height: 20, width: 20 }), "Related events"] }))),
                        key: 'related',
                        children: _jsx(RelatedCapacityEvents, { data: relatedEventsData !== null && relatedEventsData !== void 0 ? relatedEventsData : [], isFetching: isFetchingRelatedEvents }),
                    },
                ] })] })));
};
