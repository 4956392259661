import { PectenPadding, PectenSize } from './Pecten.types';
import { Sizes, Spacings } from '../../types/types';

type PectenSizesProps = {
  [size in PectenSize]: number;
};
export const pectenSizes: PectenSizesProps = {
  [Sizes.Small]: 16,
  [Sizes.Medium]: 40,
  [Sizes.Large]: 72,
  [Sizes.ExtraLarge]: 100,
};

type PectenPaddingsProps = {
  [size in PectenSize]: {
    [padding in PectenPadding]: number;
  };
};
export const pectenPaddings: PectenPaddingsProps = {
  [Sizes.Small]: {
    none: 0,
    [Spacings.Tight]: 4,
    minimum: 4,
    [Spacings.Generous]: 8,
    optimal: 8,
  },
  [Sizes.Medium]: {
    none: 0,
    [Spacings.Tight]: 12,
    minimum: 12,
    [Spacings.Generous]: 20,
    optimal: 20,
  },
  [Sizes.Large]: {
    none: 0,
    [Spacings.Tight]: 21,
    minimum: 21,
    [Spacings.Generous]: 36,
    optimal: 36,
  },
  [Sizes.ExtraLarge]: {
    none: 0,
    [Spacings.Tight]: 30,
    minimum: 30,
    [Spacings.Generous]: 50,
    optimal: 50,
  },
};
