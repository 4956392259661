import styled from 'styled-components';

import Avatar from '../../../Avatar/Avatar';

const Area = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 10px;
  overflow: hidden;
`;

const StyledAvatar = styled(Avatar)`
  flex: 1 1 0;
  min-width: 0;
`;

export { Area, StyledAvatar };
