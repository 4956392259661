var _a;
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
export var capacitySlice = createSlice({
    name: 'capacity',
    initialState: initialState,
    reducers: {
        setSorting: function (state, action) {
            state.sort = action.payload;
        },
        setCapacityLoaded: function (state, action) {
            state.capacityLoaded = action.payload;
        },
    },
});
export var setSorting = (_a = capacitySlice.actions, _a.setSorting), setCapacityLoaded = _a.setCapacityLoaded;
export var selectSorting = function (state) { return state.capacity.sort; };
export var selectCapacityLoaded = function (state) { return state.capacity.capacityLoaded; };
