import { css } from 'styled-components';

import { TextProminence, TextColor } from '../Text.types';
import { Prominences, TextTypes, DeprecatedTypes, Sizes } from '../../../types/types';
import { TextSize } from '../components/Text/Text.types';

export const getProminence = (color?: TextColor, prominence?: TextProminence) => {
  if (prominence) return prominence;

  if (color && !prominence) {
    return color === 'normal' ? Prominences.Strong : Prominences.Subtle;
  }

  return Prominences.Strong;
};

export const getTextType = (type: TextTypes | DeprecatedTypes | 'label' | 'p'): TextTypes => {
  switch (type) {
    case 'p':
      return TextTypes.Paragraph;
    case 'span':
      return TextTypes.Span;
    case 'label':
      return TextTypes.Label;
    case 'h1':
      return TextTypes.H1;
    case 'h2':
      return TextTypes.H2;
    case 'h3':
      return TextTypes.H3;
    case 'h4':
      return TextTypes.H4;
    case 'h5':
      return TextTypes.H5;
    case 'h6':
      return TextTypes.H6;
    default:
      return type;
  }
};

export const textFont = (size: TextSize, weight: 'normal' | 'bold') => css`
  ${({ theme }) => css`
    ${size === Sizes.Small && theme.paragraph.small[weight]}
    ${size === Sizes.Medium && theme.paragraph.medium[weight]}
    ${size === Sizes.Large && theme.paragraph.large[weight]}
    ${size === Sizes.ExtraLarge && theme.paragraph.extraLarge[weight]}
  `}
`;
