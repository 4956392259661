import { __makeTemplateObject } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Label } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { FilterGroup } from './filter-group';
import { filtersApis, useGetAcmHierarchyElementQuery } from 'common/ducks/filtersApis';
export var StyledSelectedFiltersWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 10px;\n  & > span {\n    margin-right: 20px;\n  }\n"], ["\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 10px;\n  & > span {\n    margin-right: 20px;\n  }\n"])));
export var SelectedFiltersTags = function (_a) {
    var regionIds = _a.regionIds, countryIds = _a.countryIds, companyIds = _a.companyIds, assetIds = _a.assetIds, unitTypeIds = _a.unitTypeIds, unitSubTypeIds = _a.unitSubTypeIds, assetUnitIds = _a.assetUnitIds, probabilityType = _a.probabilityType;
    var isFilterSelected = !!(regionIds === null || regionIds === void 0 ? void 0 : regionIds.length) ||
        !!(countryIds === null || countryIds === void 0 ? void 0 : countryIds.length) ||
        !!(companyIds === null || companyIds === void 0 ? void 0 : companyIds.length) ||
        !!(assetIds === null || assetIds === void 0 ? void 0 : assetIds.length) ||
        !!(unitTypeIds === null || unitTypeIds === void 0 ? void 0 : unitTypeIds.length) ||
        !!(unitSubTypeIds === null || unitSubTypeIds === void 0 ? void 0 : unitSubTypeIds.length) ||
        !!(assetUnitIds === null || assetUnitIds === void 0 ? void 0 : assetUnitIds.length);
    var _b = useGetAcmHierarchyElementQuery(undefined, { skip: !isFilterSelected }).data, allRegions = _b === void 0 ? [] : _b;
    var allCountries = useMemo(function () { return allRegions.map(function (region) { return region.children; }).flat(); }, [allRegions]);
    var allOwners = useMemo(function () { return allCountries.map(function (country) { return country.children; }).flat(); }, [allCountries]);
    var allAssets = useMemo(function () { return allOwners.map(function (owner) { return owner.children; }).flat(); }, [allOwners]);
    var _c = filtersApis.useGetAssetUnitTypesQuery({
        companyIds: companyIds,
        countryIds: countryIds,
        assetIds: assetIds,
    }, {
        skip: !(unitTypeIds === null || unitTypeIds === void 0 ? void 0 : unitTypeIds.length),
    }).data, unitTypes = _c === void 0 ? [] : _c;
    var _d = filtersApis.useGetAssetUnitSubTypesQuery({
        companyIds: companyIds,
        countryIds: countryIds,
        assetIds: assetIds,
        unitTypeIds: unitTypeIds,
    }, {
        skip: !(unitSubTypeIds === null || unitSubTypeIds === void 0 ? void 0 : unitSubTypeIds.length),
    }).data, unitSubTypes = _d === void 0 ? [] : _d;
    var _e = filtersApis.useGetAssetUnitsQuery({ assetIds: assetIds, unitTypeIds: unitTypeIds, unitSubTypeIds: unitSubTypeIds, placeholder: true }, { skip: !(assetIds === null || assetIds === void 0 ? void 0 : assetIds.length) || !(assetUnitIds === null || assetUnitIds === void 0 ? void 0 : assetUnitIds.length) }).data, units = _e === void 0 ? [] : _e;
    var _f = filtersApis.useGetPlanningProbabilityTypesQuery(undefined, {
        skip: !(probabilityType === null || probabilityType === void 0 ? void 0 : probabilityType.length),
    }).data, probabilityTypes = _f === void 0 ? [] : _f;
    var getSelection = function (selectedData, data, field) {
        if (selectedData) {
            return selectedData === null || selectedData === void 0 ? void 0 : selectedData.map(function (selection) {
                var region = data.find(function (r) { return r.id === selection; });
                if (region) {
                    return { id: selection, name: region[field] };
                }
                else {
                    return { id: 0, name: '' };
                }
            });
        }
        return [];
    };
    var geSelectionFromNewHierarchy = function (selectedKeys, hierarchyData, key, value) {
        if (selectedKeys) {
            return selectedKeys.map(function (selectedKey) {
                var hierarchy = hierarchyData.find(function (h) { return h[key] === selectedKey; });
                if (hierarchy) {
                    return { id: hierarchy[key], name: hierarchy[value] };
                }
                return { id: 0, name: '' };
            });
        }
        return [];
    };
    return (_jsxs(StyledSelectedFiltersWrapper, { children: [isFilterSelected && _jsx(Label, { children: "Active Filters" }), _jsx(FilterGroup, { groupName: "Regions", param: "regions", selectedData: geSelectionFromNewHierarchy(regionIds, allRegions, 'regionKey', 'regionName') }), _jsx(FilterGroup, { groupName: "Countries", param: "location", selectedData: geSelectionFromNewHierarchy(countryIds, allCountries, 'countryKey', 'countryName') }), _jsx(FilterGroup, { groupName: "Owners", param: "owners", selectedData: geSelectionFromNewHierarchy(companyIds, allOwners, 'companyKey', 'companyName') }), _jsx(FilterGroup, { groupName: "Assets", param: "assets", selectedData: geSelectionFromNewHierarchy(assetIds, allAssets, 'assetKey', 'assetName') }), _jsx(FilterGroup, { param: "unitTypes", groupName: "Unit Types", selectedData: getSelection(unitTypeIds, unitTypes, 'assetUnitTypeCd') }), _jsx(FilterGroup, { param: "unitSubTypes", groupName: "Unit Subtypes", selectedData: getSelection(unitSubTypeIds, unitSubTypes, 'assetUnitSubtypeCd') }), _jsx(FilterGroup, { param: "units", groupName: "Units", selectedData: getSelection(assetUnitIds, units, 'assetUnitNm') }), _jsx(FilterGroup, { param: "probabilityTypes", groupName: "Probability", selectedData: getSelection(probabilityType, probabilityTypes, 'planningProbabilityTypeCd') })] }));
};
var templateObject_1;
