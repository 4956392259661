import React from 'react';

import { popconfirmConsts } from '../../Popconfirm.consts';
import * as Styled from '../../Popconfirm.styles';
import { IPopconfirmTitleProps } from './PopconfirmTitle.types';
import { Sizes } from '../../../../types/types';

const PopconfirmTitle = ({ size = Sizes.Medium, text, ...rest }: IPopconfirmTitleProps) => (
  <Styled.PopconfirmTitle {...rest} level={popconfirmConsts[size].titleLevel} bold>
    {text}
  </Styled.PopconfirmTitle>
);

export default PopconfirmTitle;
