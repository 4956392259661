import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { selectCapacityLoaded, selectSorting, setCapacityLoaded } from './ducks/slice';
import { SidebarTemplate } from 'components/sidebar-template';
import { useSidebar } from 'components/sidebar-template/hooks';
import { selectIsSidebarOpen, setSidebarOpenState } from 'pages/maintenance-daily-summary/ducks/slice';
import { CapacityFilter } from 'components/capacity-filter/CapacityFilter';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { isValidTimeframe } from 'pages/maintenance-event-summary/MaintenanceEventSummary';
import { ROUTE_PATHS } from 'app/routes';
import { resolveParamsObject } from 'common/utils/url/search-params';
import { parse } from 'common/utils/newScreenUtils';
import { useGetIncrementalCapacitiesQuery } from 'common/ducks/capacityApis';
import { CapacityEventsTable } from 'components/capacity-events-table/CapacityEventsTable';
import { useAppDispatch, useAppSelector } from 'common/hooks/store';
import { getSortingString } from 'common/utils/getSortingString/getSortingString';
import { PagesHeader } from 'components/pages-header/PagesHeader';
import { SelectedFiltersTags } from 'components/selected-filters-tags/SelectedFiltersTags';
import { AddEditCapacityEventDrawer } from 'components/add-edit-capacity-event-drawer/AddEditCapacityEventDrawer';
import { ExportData } from 'components/export-data';
import { InfoPanel } from 'components/info-panel';
var Add = Icons.Add, Gear = Icons.Gear;
export var Capacity = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b;
    document.title = title;
    var history = useHistory();
    var location = useLocation();
    var _c = useSidebar(selectIsSidebarOpen, setSidebarOpenState), handleSidebarClose = _c.handleSidebarClose, handleSidebarOpen = _c.handleSidebarOpen, isSidebarOpen = _c.isSidebarOpen;
    var _d = useState({
        currentPage: 1,
        total: 0,
        pageSize: 20,
    }), paginationMeta = _d[0], setPaginationMeta = _d[1];
    var _e = useState(false), isConfigureModalOpen = _e[0], setIsConfigureModalOpen = _e[1];
    var _f = useState([]), eventsToEdit = _f[0], setEventsToEdit = _f[1];
    var _g = useState(false), openDrawer = _g[0], setOpenDrawer = _g[1];
    var dispatch = useAppDispatch();
    var sorting = useAppSelector(selectSorting);
    var loaded = useAppSelector(selectCapacityLoaded);
    var _h = useState({}), capacityRequestParams = _h[0], setCapacityRequestParams = _h[1];
    var _j = useState(false), reset = _j[0], setReset = _j[1];
    var _k = useGetIncrementalCapacitiesQuery(__assign(__assign({}, capacityRequestParams), { page: paginationMeta.currentPage, orderBy: getSortingString(sorting) }), {
        skip: !capacityRequestParams.startDate,
    }), incrdata = _k.data, isFetching = _k.isFetching, isError = _k.isError;
    var handlePaginationOnChange = function (currentPage) {
        setPaginationMeta(function (prev) { return (__assign(__assign({}, prev), { currentPage: currentPage })); });
    };
    var initialValues = useMemo(function () {
        var search = location.search;
        var params = new URLSearchParams(search);
        var timeframeParams = params.getAll('timeframe');
        return {
            assets: params.getAll('assets').map(parse),
            regions: params.getAll('regions').map(parse),
            location: params.getAll('location').map(parse),
            owners: params.getAll('owners').map(parse),
            units: params.getAll('units').map(parse),
            unitSubTypes: params.getAll('unitSubTypes').map(parse),
            unitTypes: params.getAll('unitTypes').map(parse),
            probabilityTypes: params.getAll('probabilityTypes').length
                ? params.getAll('probabilityTypes').map(parse)
                : loaded
                    ? []
                    : [1],
            timeframe: isValidTimeframe(timeframeParams)
                ? [timeframeParams[0], timeframeParams[1]]
                : ['2006-01-01', dayjs().add(3, 'years').endOf('month').format(DATE_FORMAT_YYYY_MM_DD)],
        };
    }, [location, loaded]);
    useEffect(function () {
        setCapacityRequestParams({
            regionIds: initialValues.regions,
            countryIds: initialValues.location,
            assetIds: initialValues.assets,
            companyIds: initialValues.owners,
            unitSubTypeIds: initialValues.unitSubTypes,
            unitTypeIds: initialValues.unitTypes,
            assetUnitIds: initialValues.units,
            probabilityType: initialValues.probabilityTypes,
            startDate: initialValues.timeframe[0],
            endDate: initialValues.timeframe[1],
        });
        setPaginationMeta({
            currentPage: 1,
            pageSize: 0,
            total: 0,
        });
        setReset(false);
    }, [initialValues]);
    var handleFilterSubmit = function (values) {
        if (!values.probabilityTypes.length) {
            dispatch(setCapacityLoaded(true));
        }
        history.push({
            pathname: ROUTE_PATHS.CAPACITY,
            search: new URLSearchParams(resolveParamsObject(values)).toString(),
        });
    };
    var handleOpenDrawer = useCallback(function (events) {
        setEventsToEdit(events);
        setOpenDrawer(true);
    }, []);
    var handleCloseDrawer = useCallback(function () {
        setOpenDrawer(false);
    }, []);
    return (_jsxs(SidebarTemplate, __assign({ sidebarOpen: isSidebarOpen, onSidebarOpen: handleSidebarOpen, onSidebarClose: handleSidebarClose, sidebarTitle: "Filters", sidebar: _jsx(CapacityFilter, __assign({}, initialValues, { handleReset: setReset, onSubmit: handleFilterSubmit, sidebarOpen: isSidebarOpen })) }, { children: [_jsxs(PagesHeader, __assign({ title: "Capacity events", dockStartItemsLength: 1 }, { children: [_jsx(InfoPanel, { type: "capacity" }), _jsx(ExportData, { requestParams: capacityRequestParams, type: "capacity", propertyName: "capacityEventDataList", orderBy: getSortingString(sorting), refetchingData: isFetching, totalRows: incrdata === null || incrdata === void 0 ? void 0 : incrdata.totalRows }), _jsx(Button, __assign({ size: "small", variant: "outlined", icon: _jsx(Gear, {}), disabled: isFetching, onClick: function () { return setIsConfigureModalOpen(true); } }, { children: "Configure columns" })), _jsx(Button, __assign({ size: "small", icon: _jsx(Add, {}), onClick: function () { return handleOpenDrawer([]); } }, { children: "Capacity event" }))] })), !reset && _jsx(SelectedFiltersTags, __assign({}, capacityRequestParams)), _jsx(AddEditCapacityEventDrawer, { open: openDrawer, onClose: handleCloseDrawer, eventsToEdit: eventsToEdit }), _jsx(CapacityEventsTable, { isFetching: isFetching, isError: isError, reset: reset, data: incrdata, handlePaginationChange: handlePaginationOnChange, openEditDrawer: handleOpenDrawer, isConfigureModalOpen: isConfigureModalOpen, closeConfigureModal: function () { return setIsConfigureModalOpen(false); } })] })));
};
