import { __assign, __awaiter, __generator } from "tslib";
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
export var useHandleSave = function (_a) {
    var saveAssetUnitEvent = _a.saveAssetUnitEvent, updateAssetUnitEvent = _a.updateAssetUnitEvent, setIsSaving = _a.setIsSaving, onSave = _a.onSave, event = _a.event, bulkEvents = _a.bulkEvents;
    var _b = useState(null), saveErrors = _b[0], setError = _b[1];
    var handleSave = useCallback(function (data, e) { return __awaiter(void 0, void 0, void 0, function () {
        var baseAssetUnitEvent, assetUnitEvent, e_1, data_1, values;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e === null || e === void 0 ? void 0 : e.preventDefault();
                    setIsSaving(true);
                    setError(null);
                    baseAssetUnitEvent = {
                        assetEventTypeKey: data.eventType,
                        comment: data.comment,
                        dataProviderKey: data.provider,
                        eventCauseTypeKey: data.eventCause,
                        eventEndDt: dayjs(data.endDate).format(DATE_FORMAT_YYYY_MM_DD),
                        eventStartDt: dayjs(data.startDate).format(DATE_FORMAT_YYYY_MM_DD),
                        eventLockInd: data.lockBaseline,
                        offlineCapacityOriginal: +data.offlineCapacityOriginal,
                        offlineCapacityOriginalUomKey: 2,
                        offlineDataType: 'PCT',
                        url: '',
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!!event) return [3 /*break*/, 3];
                    assetUnitEvent = __assign(__assign({}, baseAssetUnitEvent), { assetUnitKey: data.units });
                    return [4 /*yield*/, saveAssetUnitEvent(assetUnitEvent).unwrap()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    assetUnitEvent = __assign(__assign({}, baseAssetUnitEvent), { assetUnitEventKey: bulkEvents.length
                            ? bulkEvents.map(function (event) { return event.asset_unit_event_key; })
                            : [event.asset_unit_event_key] });
                    return [4 /*yield*/, updateAssetUnitEvent(assetUnitEvent).unwrap()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    setIsSaving(false);
                    onSave(assetUnitEvent);
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _a.sent();
                    setIsSaving(false);
                    if (e_1.data) {
                        data_1 = e_1.data;
                        values = Object.values(data_1 === null || data_1 === void 0 ? void 0 : data_1.errors);
                        setError(values === null || values === void 0 ? void 0 : values.map(function (x) { return x[0]; }));
                    }
                    console.error(e_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [saveAssetUnitEvent, updateAssetUnitEvent, onSave, event, setIsSaving, bulkEvents]);
    return {
        handleSave: handleSave,
        saveErrors: saveErrors,
        setError: setError,
    };
};
