import { css } from 'styled-components';

import { MENU_CLASS, Z_INDEX } from '../../../utils/constants';
import { menuConsts } from '../Menu.consts';
import { StyledMenuProps } from '../Menu.types';
import { indicatorBottomStyle, indicatorPositionStyle } from './indicator.styles';

export const subMenuStyle = css<StyledMenuProps>`
  ${({ theme, size, inlineCollapsed }) => {
    const offset = 4;
    const padding = `${menuConsts[size].padding - offset}px`;
    const paddingOffset = `${offset}px`;
    const subMenuIndent = `${menuConsts[size].indent}px`;
    const iconSize = `${menuConsts[size].iconSize}px`;
    const iconPadding = `${menuConsts[size].padding - offset - 2}px`;
    const gap = `${menuConsts[size].gap}px`;

    return css`
      .${MENU_CLASS}-submenu {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        .${MENU_CLASS}-submenu-title {
          position: relative;
          padding: ${paddingOffset};
          display: flex;
          align-items: stretch;
          flex: 1 1 0;
          min-width: 0;

          &:focus-visible {
            outline: none;
            position: relative;

            .${MENU_CLASS}-submenu-title-content {
              outline: 3px solid ${theme.focus.medium};
            }
          }
        }

        .${MENU_CLASS}-submenu-title-content {
          padding: ${padding};
          margin: 0 -${padding};
          display: flex;
          flex: 1 1 0;
          min-width: 0;
          justify-content: space-between;
          align-items: center;
          gap: ${gap};
          border-radius: 2px;
          transition: background-color 0.3s ease-out;
        }

        .${MENU_CLASS}-submenu-expand-icon {
          width: ${iconSize};
          height: ${iconSize};
          flex-shrink: 0;
          transition: transform 0.2s ease-in-out;
          margin: -10px 0;

          ${inlineCollapsed &&
          css`
            display: none;
          `}
        }

        &.${MENU_CLASS}-submenu-active:not(.${MENU_CLASS}-submenu-selected) > .${MENU_CLASS}-submenu-title {
          &:focus-visible {
            > .${MENU_CLASS}-submenu-title-content {
              background-color: transparent;
            }
          }

          > .${MENU_CLASS}-submenu-title-content {
            background-color: ${theme.focus.subtle};
          }
        }
      }

      /* Inline and vertical */
      &.${MENU_CLASS}-inline, &.${MENU_CLASS}-vertical .${MENU_CLASS}-submenu {
        .${MENU_CLASS}-submenu-title {
          padding-left: ${subMenuIndent};
          padding-right: ${subMenuIndent};
        }
      }

      /* Inline */
      &.${MENU_CLASS}-inline {
        .${MENU_CLASS}-submenu.${MENU_CLASS}-submenu-open
          > .${MENU_CLASS}-submenu-title
          > .${MENU_CLASS}-submenu-title-content
          > .${MENU_CLASS}-submenu-expand-icon {
          transform: rotate(180deg);
        }

        /* Lowest level */
        .${MENU_CLASS}-submenu-selected:not(.${MENU_CLASS}-submenu-open) .${MENU_CLASS}-submenu-title {
          background-color: transparent;

          &:after {
            ${indicatorPositionStyle}
          }
        }
      }

      /* Vertical */
      &.${MENU_CLASS}-vertical .${MENU_CLASS}-submenu {
        .${MENU_CLASS}-submenu-expand-icon {
          transform: rotate(-90deg);
        }

        &.${MENU_CLASS}-submenu-selected > .${MENU_CLASS}-submenu-title {
          background-color: transparent;

          &:after {
            ${indicatorPositionStyle}
          }
        }

        .${MENU_CLASS}-item-group .${MENU_CLASS}-item-group-list {
          display: flex;
          flex-direction: row;
        }
      }

      /* Horizontal */
      &.${MENU_CLASS}-horizontal .${MENU_CLASS}-submenu {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .${MENU_CLASS}-submenu-title {
          padding-left: ${subMenuIndent};
          padding-right: ${subMenuIndent};
        }

        &.${MENU_CLASS}-submenu-selected > .${MENU_CLASS}-submenu-title {
          background-color: ${theme.background.section};

          &:after {
            ${indicatorBottomStyle}
          }
        }

        &.${MENU_CLASS}-overflow-item-rest {
          .${MENU_CLASS}-submenu-title {
            padding-left: 8px;
            padding-right: 8px;
          }

          .${MENU_CLASS}-submenu-title-content {
            padding: ${iconPadding};
          }

          .${MENU_CLASS}-submenu-rest-icon {
            width: ${iconSize};
            height: ${iconSize};
          }
        }
      }

      /* Popup */
      &.${MENU_CLASS}-submenu {
        &.${MENU_CLASS}-submenu-popup {
          z-index: ${Z_INDEX.MENU};
          min-width: 300px;
          position: absolute;
          transform-origin: 0 0;
          background-color: ${theme.background.raised};
          box-shadow: ${theme.elevation.level2};
          border-radius: 4px;
        }

        &.${MENU_CLASS}-submenu-hidden {
          display: none;
        }

        /* Vertical and horizontal */
        .${MENU_CLASS}-vertical {
          width: 100%;
          max-width: 300px;

          .${MENU_CLASS}-submenu-title {
            padding-left: ${subMenuIndent};
            padding-right: ${subMenuIndent};
          }

          .${MENU_CLASS}-submenu-selected > .${MENU_CLASS}-submenu-title:after {
            ${indicatorPositionStyle}
          }

          .${MENU_CLASS}-submenu-expand-icon {
            transform: rotate(-90deg);
          }
        }
      }
    `;
  }}
`;
