import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table, Flexbox, Heading, TR, TBody, TD } from '@sede-x/shell-ds-react-framework';
import { StyledTrendsDataContainer } from './CapacityTrends.styles';
import { BREAKDOWN_BY_MAP, DATE_FORMAT_BY_MODE } from './capacity-trends.consts';
import { createDefaultTableData } from './trends.utils';
import { Loader } from 'components/loader';
import { ModalExportButton } from 'components/modal-export-button/ModalExportButton';
export var CapacityTrendsTable = function (_a) {
    var breakdownBy = _a.breakdownBy, datesForColumns = _a.datesForColumns, columnDataProps = _a.columnDataProps, colorSeries = _a.colorSeries, dateFormat = _a.dateFormat, isFetching = _a.isFetching, columnIndex = _a.columnIndex;
    var data = useMemo(function () {
        if (columnIndex !== undefined) {
            return createDefaultTableData(columnDataProps.map(function (columnData) { return ({
                type: columnData.type,
                name: columnData.name,
                data: [columnData.data[columnIndex]],
            }); }), BREAKDOWN_BY_MAP.get(breakdownBy)).reverse();
        }
        return createDefaultTableData(columnDataProps, BREAKDOWN_BY_MAP.get(breakdownBy)).reverse();
    }, [columnDataProps, breakdownBy, columnIndex]);
    var columns = useMemo(function () {
        var memoizedColumn = [
            {
                id: 'color',
                cell: function (info) {
                    var rowIndex = info.row.index;
                    return (_jsx("span", __assign({ style: { color: colorSeries[rowIndex] ? colorSeries[rowIndex] : colorSeries[colorSeries.length - 1] } }, { children: '\u25A0' })));
                },
            },
        ];
        if (data.length > 0)
            Object.keys(data[0]).forEach(function (key, index) {
                memoizedColumn.push({
                    header: function () {
                        return (_jsx("div", __assign({ style: { textAlign: index < 1 ? 'left' : 'right', width: '100px' } }, { children: dayjs(parseInt(key, 10)).isValid()
                                ? dayjs(parseInt(key, 10)).format(DATE_FORMAT_BY_MODE.get(dateFormat))
                                : key })));
                    },
                    accessorKey: key,
                    cell: function (info) {
                        return (_jsx("div", __assign({ style: {
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: index < 1 ? 'left' : 'right',
                            }, title: info.getValue() }, { children: info.getValue() })));
                    },
                });
            });
        return memoizedColumn;
    }, [colorSeries, data, dateFormat]);
    var table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });
    return (_jsxs(StyledTrendsDataContainer, { children: [_jsxs(Flexbox, __assign({ style: { marginBottom: '24px' } }, { children: [_jsxs(Flexbox, __assign({ alignItems: "center", gap: "10px" }, { children: [_jsxs(Heading, __assign({ level: 2 }, { children: ["Breakdown by ", BREAKDOWN_BY_MAP.get(breakdownBy)] })), columnIndex !== undefined ? (_jsxs(Heading, __assign({ color: "light", level: 3 }, { children: ["(", dayjs(columnDataProps[0].data[columnIndex][0]).format('DD MMM YYYY'), ")"] }))) : (_jsxs(Heading, __assign({ color: "light", level: 3 }, { children: ["(", dayjs(datesForColumns[0]).format('DD MMM YYYY'), " -", ' ', dayjs(datesForColumns[datesForColumns.length - 1]).format('DD MMM YYYY'), ")"] })))] })), _jsx(ModalExportButton, { dataToExport: data, type: "offline-capacity", propertyName: "", right: 28 })] })), _jsx("div", __assign({ style: { height: '23vh', overflow: 'auto' } }, { children: isFetching ? (_jsx(Loader, { message: "", delayMessage: "" })) : (_jsxs(Table, __assign({ size: "small" }, { children: [_jsx("thead", { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, { children: headerGroup.headers.map(function (header) { return (_jsx("th", { children: header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext()) }, header.id)); }) }, headerGroup.id)); }) }), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) { return (_jsx(TR, { children: row.getVisibleCells().map(function (cell) { return (_jsx(TD, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)); }) }, row.id)); }) })] }))) }))] }));
};
