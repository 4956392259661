var _a;
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
export var maintenanceEventSummarySlice = createSlice({
    name: 'maintenanceEventSummary',
    initialState: initialState,
    reducers: {
        setSidebarOpenState: function (state, action) {
            state.isSidebarOpen = action.payload;
        },
        setModalOpen: function (state, action) {
            state.actionModals.isOpen = true;
            state.actionModals.current = action.payload;
        },
        setModalClose: function (state) {
            state.actionModals.isOpen = false;
            state.actionModals.current = 'none';
        },
        setModalData: function (state, action) {
            state.actionModals.assetUnitEventKey = action.payload.assetUnitEventKey;
            state.actionModals.unitKey = action.payload.unitKey;
            state.actionModals.title = action.payload.title;
        },
        setModalSelectedItemKey: function (state, action) {
            state.actionModals.selectedItemKey = action.payload;
        },
        setModalAssetUnitEventKey: function (state, action) {
            state.actionModals.assetUnitEventKey = action.payload;
        },
        setSorting: function (state, action) {
            state.sort = action.payload;
        },
    },
});
export var setSidebarOpenState = (_a = maintenanceEventSummarySlice.actions, _a.setSidebarOpenState), setModalOpen = _a.setModalOpen, setModalClose = _a.setModalClose, setModalData = _a.setModalData, setModalSelectedItemKey = _a.setModalSelectedItemKey, setModalAssetUnitEventKey = _a.setModalAssetUnitEventKey, setSorting = _a.setSorting;
export var selectIsSidebarOpen = function (state) {
    return state.maintenanceEventSummary.isSidebarOpen;
};
export var selectActionModals = function (state) {
    return state.maintenanceEventSummary.actionModals;
};
export var selectSorting = function (state) { return state.maintenanceEventSummary.sort; };
