export var MAINTENANCE_NOTIFICATION_UNEXPECTED_OFF = 101132;
export var MAINTENANCE_NOTIFICATION_UNEXPECTED_ON = 101133;
export var SESSIONSTORAGE_ACCESS_TOKEN = 'access_token';
export var SESSIONSTORAGE_REFRESH_TOKEN = 'refresh_token';
export var SESSIONSTORAGE_PREVIOUS_ROUTE = 'previous_route';
export var SESSIONSTORAGE_CODE_VERIFIER = 'code_verifier';
export var DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export var BASE_DRAWER_PADDING = 32;
export var DATE_FORMAT_DD_MMM_YYYY = 'DD MMM YYYY';
export var DATE_FORMAT_DD_MMM_YYYY_HH_MM_UTC = 'DD MMM YYYY, HH:mm UTC';
export var NB_HOURS_WINDOW_TO_CHECK = 24;
export var SESSIONSTORAGE_POWER_BI_ACCESS_TOKEN = 'power_bi_access_token';
