import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var moment = require('moment');
export var ProviderRow = function (_a) {
    var countrydata = _a.countrydata, editCurrentRow = _a.editCurrentRow, dateColumn = _a.dateColumn, countryInLoop = _a.countryInLoop;
    return (_jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("table", __assign({ id: "name" }, { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsx("td", { children: _jsx("div", __assign({ className: "header", style: { border: 'none' } }, { children: "\u00A0" })) }) }), countrydata.dataProviderLsts.map(function (row, index) {
                            return (_jsx("tr", __assign({ className: "tbodycontent" }, { children: _jsx("td", { children: _jsxs("div", __assign({ className: "header pos" }, { children: [row.dataProviderName, " ", row.dataProviderName === 'ESAI' && _jsx("i", { children: "(KBD)" }), row.dataProviderName === 'Baseline' && (_jsx("span", __assign({ className: "editIcon", onClick: function () {
                                                    editCurrentRow(countryInLoop, row.dataProviderName, countrydata.countryName.toLowerCase());
                                                } }, { children: _jsx("i", { className: "fa fa-pencil iconfa", style: { cursor: 'pointer' } }) })))] })) }) }), row + index));
                        })] }) })), _jsx("div", __assign({ id: "wrap" }, { children: _jsx("table", __assign({ id: "data" }, { children: _jsxs("tbody", { children: [_jsx("tr", { children: dateColumn.map(function (date) {
                                    return (_jsx("th", __assign({ className: "text-centre" }, { children: _jsx("div", __assign({ className: "header" }, { children: moment(new Date(date)).format('MMM YYYY') })) }), date));
                                }) }), countrydata.dataProviderLsts.map(function (row, index) {
                                return (_jsx("tr", __assign({ className: "tbodycontent" }, { children: dateColumn.map(function (data) {
                                        var coData = row.dataLst.filter(function (dl, i) {
                                            return dl.monthAndYear === moment(new Date(data)).format('MMYYYY');
                                        });
                                        return (_jsx("td", { children: _jsxs("div", __assign({ className: row.dataProviderName === 'Baseline' ? 'td-data py-2 bgc' : 'td-data py-2' }, { children: [coData.length > 0 ? coData[0].quantity : 0, coData.length > 0 && coData[0].isLockedInd && (_jsx("i", { className: "fa fa-lock ml-2", style: { color: '#fbce07', fontSize: '14px' } }))] })) }, data));
                                    }) }), row + index));
                            })] }) })) }))] })));
};
