import { CheckboxSizes } from './Checkbox.types';
import { Sizes } from '../../types/types';

type CheckboxConstsType = {
  [size in CheckboxSizes]: {
    width: number;
    height: number;
    labelSpacing: number;
  };
};

export const checkboxConsts: CheckboxConstsType = {
  [Sizes.ExtraSmall]: {
    width: 16,
    height: 16,
    labelSpacing: 8,
  },
  [Sizes.Small]: {
    width: 20,
    height: 20,
    labelSpacing: 8,
  },
  [Sizes.Medium]: {
    width: 24,
    height: 24,
    labelSpacing: 10,
  },
  [Sizes.Large]: {
    width: 28,
    height: 28,
    labelSpacing: 12,
  },
};
