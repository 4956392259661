import RcDrawer from 'rc-drawer';
import styled, { css } from 'styled-components';

import { RequiredProperties, Sizes, Transient } from '../../types/types';
import { Z_INDEX } from '../../utils/constants';
import { drawerConsts } from './Drawer.consts';
import { IDrawerProps } from './Drawer.types';
import { getActionsAlignment } from './utils/styleUtils';

const panelMotion = css`
  &-enter,
  &-appear,
  &-leave {
    &-start {
      transition: none;
    }

    &-active {
      transition: all 0.3s ease-out;
    }
  }
`;

const Drawer = styled(RcDrawer)<Transient<IDrawerProps, 'elevation'>>`
  ${({ prefixCls, theme, sticky, $elevation }) => css`
    z-index: ${Z_INDEX.DRAWER};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: ${theme.text.onSurface.strong};
    pointer-events: none;

    &.${prefixCls}-inline {
      position: absolute;
    }

    .${prefixCls} {
      &-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${Z_INDEX.DRAWER};
        pointer-events: auto;
        background-color: ${theme.background.mask};

        &.mask-motion {
          &-enter,
          &-appear,
          &-leave {
            &-active {
              transition: all 0.3s;
            }
          }

          &-enter,
          &-appear {
            opacity: 0;

            &-active {
              opacity: 1;
            }
          }

          &-leave {
            opacity: 1;

            &-active {
              opacity: 0;
            }
          }
        }
      }

      &-content-wrapper {
        position: absolute;
        z-index: ${Z_INDEX.DRAWER};
        transition: transform 0.3s ease-out;

        &-hidden {
          display: none;
        }

        .${prefixCls}-left& {
          top: 0;
          bottom: 0;
          left: 0;
        }

        .${prefixCls}-right& {
          top: 0;
          right: 0;
          bottom: 0;
        }

        .${prefixCls}-top& {
          top: 0;
          right: 0;
          left: 0;
        }

        .${prefixCls}-bottom& {
          right: 0;
          bottom: 0;
          left: 0;
        }

        &.panel-motion {
          // Left
          &-left {
            ${panelMotion};

            &-enter,
            &-appear {
              &-start {
                transform: translateX(-100%) !important;
              }

              &-active {
                transform: translateX(0);
              }
            }

            &-leave {
              transform: translateX(0);

              &-active {
                transform: translateX(-100%);
              }
            }
          }

          // Right
          &-right {
            ${panelMotion};

            &-enter,
            &-appear {
              &-start {
                transform: translateX(100%) !important;
              }

              &-active {
                transform: translateX(0);
              }
            }

            &-leave {
              transform: translateX(0);

              &-active {
                transform: translateX(100%);
              }
            }
          }

          // Top
          &-top {
            ${panelMotion};

            &-enter,
            &-appear {
              &-start {
                transform: translateY(-100%) !important;
              }

              &-active {
                transform: translateY(0);
              }
            }

            &-leave {
              transform: translateY(0);

              &-active {
                transform: translateY(-100%);
              }
            }
          }

          // Bottom
          &-bottom {
            ${panelMotion};

            &-enter,
            &-appear {
              &-start {
                transform: translateY(100%) !important;
              }

              &-active {
                transform: translateY(0);
              }
            }

            &-leave {
              transform: translateY(0);

              &-active {
                transform: translateY(100%);
              }
            }
          }
        }
      }

      &-content {
        height: 100%;
        width: 100%;
        pointer-events: auto;
        display: flex;
        flex-flow: column;
        background: ${theme.background.surface};

        ${!sticky &&
        css`
          overflow-y: auto;
        `}

        ${$elevation &&
        css`
          box-shadow: ${theme.elevation.level2};
        `}
      }
    }
  `}
`;

const DrawerContent = styled.div<
  RequiredProperties<IDrawerProps, 'size' | 'padding' | 'bodyPadding' | 'bodyBgColor' | 'sticky'>
>`
  ${({ size, padding, bodyPadding, bodyBgColor, sticky, theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;

    ${sticky &&
    css`
      overflow-y: auto;
    `}

    ${bodyPadding &&
    css`
      padding: ${drawerConsts[size][padding].padding};
    `}

    ${bodyBgColor &&
    css`
      background-color: ${theme.background[bodyBgColor]};
    `}
  `}
`;

const DrawerBar = styled.div<RequiredProperties<IDrawerProps, 'size' | 'padding'>>`
  ${({ size, padding, theme }) => css`
    z-index: ${Z_INDEX.ELEVATED};
    display: flex;
    align-items: center;
    min-height: ${drawerConsts[size][padding].height};
    padding-right: ${drawerConsts[size][padding].padding};
    padding-left: ${drawerConsts[size][padding].padding};
    background: ${theme.background.surface};
  `}
`;

const DrawerHeaderContent = styled.div`
  flex: 1 0 0;
`;

const DrawerHeader = styled(DrawerBar)<RequiredProperties<IDrawerProps, 'size' | 'padding'>>`
  ${({ size, borders, theme }) => css`
    ${size === Sizes.Small ? theme.heading[3].demi : theme.heading[2].demi};
    gap: 12px;

    ${borders &&
    css`
      border-bottom: 1px solid ${theme.border.medium};
    `}
  `}
`;

const DrawerFooter = styled(DrawerBar)<RequiredProperties<IDrawerProps, 'actionsAlignment'>>`
  ${({ borders, actionsAlignment, theme }) => css`
    justify-content: ${getActionsAlignment(actionsAlignment)};
    gap: 12px;

    ${borders &&
    css`
      border-top: 1px solid ${theme.border.medium};
    `}
  `}
`;

export { Drawer, DrawerContent, DrawerHeader, DrawerHeaderContent, DrawerFooter };
