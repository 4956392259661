import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import './dropDown.css';
var Dropdown = function (_a) {
    var value = _a.value, options = _a.options, _b = _a.placeholder, placeholder = _b === void 0 ? 'Select' : _b, onChange = _a.onChange, textName = _a.textName, returnValue = _a.returnValue, label = _a.label;
    var node = useRef();
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(null), selectedItem = _d[0], setSelectedItem = _d[1];
    var handleClick = function (e) {
        if (node.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };
    var handleChange = function (selectedValue) {
        setSelectedItem(selectedValue[textName]);
        onChange(selectedValue[returnValue]);
        setOpen(false);
    };
    useEffect(function () {
        document.addEventListener('mousedown', handleClick);
        return function () {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    useEffect(function () {
        setSelectedItem(null);
    }, [options]);
    return (_jsx("div", __assign({ ref: node }, { children: _jsxs("div", __assign({ className: "wrapper-dropdown" }, { children: [_jsx("span", __assign({ className: "label-for" }, { children: label })), _jsxs("div", __assign({ className: "p-2", onClick: function (e) { return setOpen(!open); } }, { children: [_jsxs("span", __assign({ className: "px-1" }, { children: [" ", selectedItem || placeholder] })), _jsx("i", { className: "fa fa-caret-down dropdown-icon" })] })), open && (_jsx("div", __assign({ className: "dropdown-list" }, { children: options.map(function (opt, i) { return (_jsx("div", __assign({ className: "py-2 list-option", onClick: function (e) { return handleChange(opt); } }, { children: opt[textName] }), opt + '-' + i)); }) })))] })) })));
};
export default Dropdown;
