import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import '../../../../../../node_modules/simplebar/dist/simplebar.min.css';
import { getSourceData } from './../../../../../services/urlService';
import _ from 'lodash';
import Spinner from '../../../../../commons/spinner';
import SvgLogo from '../../../../../commons/tools/svgLogo';
var DailyView = /** @class */ (function (_super) {
    __extends(DailyView, _super);
    function DailyView(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.editClick = function (e) {
            _this.props.onBaselineEdit(e.target);
        };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.state = {
            loader: false,
            dailyview: {
                days: [
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23',
                    '24',
                    '25',
                    '26',
                    '27',
                    '28',
                    '29',
                    '30',
                    '31',
                ],
            },
        };
        _this.editClick = _this.editClick.bind(_this);
        return _this;
    }
    DailyView.prototype.render = function () {
        var _this = this;
        return (_jsx(_Fragment, { children: typeof this.props.data.maintenanceCapacityAssetUnitList !== 'undefined' &&
                this.props.data.maintenanceCapacityAssetUnitList.length > 0 ? (_jsxs("div", __assign({ className: "maintenance" }, { children: [_jsx("div", __assign({ className: "col-6 pull-right text-right font-shell-light p-2" }, { children: this.props.deltaView ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "pull-right" }, { children: [_jsx("div", __assign({ className: "pull-left p-2" }, { children: _jsx("span", { className: "dot-limit1" }) })), _jsx("div", __assign({ className: "pull-left py-2" }, { children: _jsxs("span", { children: ['> ' + this.props.limitForHighlight + '%', " "] }) }))] })), _jsxs("div", __assign({ className: "pull-right px-3" }, { children: [_jsx("div", __assign({ className: "pull-left p-2" }, { children: _jsx("span", { className: "dot-limit2" }) })), _jsx("div", __assign({ className: "pull-left py-2" }, { children: _jsx("span", { children: '<' + this.props.limitForHighlight + '%' }) }))] }))] })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "pull-right" }, { children: [_jsx("div", __assign({ className: "pull-left p-2" }, { children: _jsx("span", { className: "dot-planned" }) })), _jsx("div", __assign({ className: "pull-left py-2" }, { children: _jsx("span", { children: "Unplanned Events" }) }))] })), _jsxs("div", __assign({ className: "pull-right px-3" }, { children: [_jsx("div", __assign({ className: "pull-left p-2" }, { children: _jsx("span", { className: "dot-unplanned" }) })), _jsx("div", __assign({ className: "pull-left py-2" }, { children: _jsx("span", { children: "Planned Events" }) }))] }))] })) })), _jsx("div", __assign({ className: "pr-0" }, { children: _jsx("div", __assign({ className: "daily-view" }, { children: _jsx("div", __assign({ className: "col-12", style: {
                                    overflowY: 'scroll',
                                    height: window.innerHeight - 383 + 'px',
                                } }, { children: _jsxs("table", __assign({ className: "table no-bgc" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "sticky-headers" }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: "\u00A0" })) })), _jsx("th", __assign({ className: "sticky-headers" }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: "\u00A0" })) })), this.state.dailyview.days.map(function (day, index) { return (_jsx("th", __assign({ className: "p-1 text-center sticky-headers" }, { children: _jsx("div", __assign({ className: "minwidth pb-2" }, { children: day })) }), index)); })] }) }), _jsx("tbody", { children: this.props.data.maintenanceCapacityAssetUnitList.map(function (months, i) {
                                                var date = months.groupMonth;
                                                var deltaArray = null;
                                                if (_this.props.baseProvider) {
                                                    for (i = 0; i < months.maintenanceCapacityDataLayerList.length; i++) {
                                                        if (months.maintenanceCapacityDataLayerList[i].dpName === _this.props.baseProvider.label) {
                                                            deltaArray = months.maintenanceCapacityDataLayerList[i].maintenanceCapacityDataList;
                                                            break;
                                                        }
                                                    }
                                                }
                                                var dproviders = months.maintenanceCapacityDataLayerList.map(function (o) { return o.dpName; });
                                                if (dproviders.includes('GENSCAPE') && !dproviders.includes('Baseline')) {
                                                    months.maintenanceCapacityDataLayerList.unshift({
                                                        dpName: 'Baseline',
                                                    });
                                                }
                                                return (_jsxs(React.Fragment, { children: [months.maintenanceCapacityDataLayerList.map(function (providerName, j) {
                                                            if (j === 0) {
                                                                return (_jsx(React.Fragment, { children: _jsxs("tr", { children: [_jsx("td", __assign({ style: {
                                                                                    textAlign: 'right',
                                                                                    paddingTop: '4px',
                                                                                    paddingRight: '10px',
                                                                                    fontWeight: 'bold',
                                                                                    width: '145px',
                                                                                } }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: date })) })), _jsx("td", __assign({ className: "border rounded text-right p-1 nobgcbd provider-name", style: { width: '50px' } }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: providerName.dpName })) })), _this.state.dailyview.days.map(function (day, index) {
                                                                                var dayValue = _.result(_.find(providerName.maintenanceCapacityDataList, function (obj) {
                                                                                    return parseInt(obj.day) === parseInt(day);
                                                                                }), 'maintenanceQty');
                                                                                var dayValueForBaseProvider;
                                                                                if (_this.props.baseProvider) {
                                                                                    dayValueForBaseProvider = _.result(_.find(deltaArray, function (obj) {
                                                                                        return parseInt(obj.day) === parseInt(day);
                                                                                    }), 'maintenanceQty');
                                                                                }
                                                                                var eventKey = _.result(_.find(providerName.maintenanceCapacityDataList, function (obj) {
                                                                                    return parseInt(obj.day) === parseInt(day);
                                                                                }), 'assetUnitEventKey');
                                                                                var planUnplan = _this.props.deltaView
                                                                                    ? ''
                                                                                    : _.result(_.find(providerName.maintenanceCapacityDataList, function (obj) {
                                                                                        return parseInt(obj.day) === parseInt(day);
                                                                                    }), 'eventTypeName');
                                                                                var limitClass;
                                                                                if (_this.props.baseProvider) {
                                                                                    if (dayValueForBaseProvider &&
                                                                                        dayValueForBaseProvider - dayValue > _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-more';
                                                                                    else if (dayValueForBaseProvider &&
                                                                                        dayValueForBaseProvider - dayValue < -1 * _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-less';
                                                                                    else
                                                                                        limitClass = '';
                                                                                }
                                                                                else if (_this.props.deltaView && dayValue !== undefined) {
                                                                                    if (dayValue > _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-more';
                                                                                    else if (dayValue < -1 * _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-less';
                                                                                    else
                                                                                        limitClass = '';
                                                                                }
                                                                                else
                                                                                    limitClass = '';
                                                                                return (_jsx(React.Fragment, { children: _jsx("td", __assign({ className: 'border rounded data data-td p-1 text-center ' +
                                                                                            planUnplan +
                                                                                            ' ' +
                                                                                            limitClass }, { children: _jsx("div", __assign({ className: "minwidth", "data-country-id": _this.props.data.countryKey, "data-country": _this.props.data.countryNm, "data-city-id": _this.props.data.cityKey, "data-city": _this.props.data.cityNm, "data-owner-id": _this.props.data.ownerKey, "data-owner": _this.props.data.ownerNm, "data-asset-id": _this.props.data.assetKey, "data-assetname": _this.props.data.assetNm, "data-assettype-id": _this.props.data.assetClassKey, "data-assettype": _this.props.data.assetClassNm, "data-unit-id": _this.props.data.unitId, "data-unit": _this.props.data.unitCd, "data-event-key": eventKey, onClick: _this.editClick, style: {
                                                                                                cursor: 'pointer',
                                                                                            } }, { children: dayValue < 100 && dayValue > -100
                                                                                                ? dayValue < 10 && dayValue > -10
                                                                                                    ? parseFloat(dayValue).toFixed(2)
                                                                                                    : parseFloat(dayValue).toFixed(1)
                                                                                                : dayValue })) })) }, day));
                                                                            })] }) }, providerName.dpName));
                                                            }
                                                            else {
                                                                return (_jsx(React.Fragment, { children: _jsxs("tr", { children: [_jsx("td", { children: _jsx("div", __assign({ className: "minwidth" }, { children: "\u00A0" })) }), _jsx("td", __assign({ className: "border rounded text-right p-1 nobgcbd provider-name", style: { width: '50px' } }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: providerName.dpName })) })), _this.state.dailyview.days.map(function (day, index) {
                                                                                var dayValue = _.result(_.find(providerName.maintenanceCapacityDataList, function (obj) {
                                                                                    return parseInt(obj.day) === parseInt(day);
                                                                                }), 'maintenanceQty');
                                                                                var dayValueForBaseProvider = null;
                                                                                if (_this.props.baseProvider) {
                                                                                    dayValueForBaseProvider = _.result(_.find(deltaArray, function (obj) {
                                                                                        return parseInt(obj.day) === parseInt(day);
                                                                                    }), 'maintenanceQty');
                                                                                    if (dayValueForBaseProvider === null)
                                                                                        dayValueForBaseProvider = 0;
                                                                                }
                                                                                var planUnplan = _this.props.deltaView
                                                                                    ? ''
                                                                                    : _.result(_.find(providerName.maintenanceCapacityDataList, function (obj) {
                                                                                        return parseInt(obj.day) === parseInt(day);
                                                                                    }), 'eventTypeName');
                                                                                var limitClass;
                                                                                if (_this.props.baseProvider) {
                                                                                    if (dayValueForBaseProvider - dayValue > _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-more';
                                                                                    else if (dayValueForBaseProvider - dayValue <
                                                                                        -1 * _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-less';
                                                                                    else
                                                                                        limitClass = '';
                                                                                }
                                                                                else if (_this.props.deltaView && dayValue !== undefined) {
                                                                                    if (dayValue > _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-more';
                                                                                    else if (dayValue < -1 * _this.props.limitForHighlight)
                                                                                        limitClass = 'limit-less';
                                                                                    else
                                                                                        limitClass = '';
                                                                                }
                                                                                else
                                                                                    limitClass = '';
                                                                                return (_jsx(React.Fragment, { children: _jsx("td", __assign({ className: 'border rounded data data-td p-1 text-center ' +
                                                                                            planUnplan +
                                                                                            ' ' +
                                                                                            limitClass }, { children: _jsx("div", __assign({ className: "minwidth" }, { children: dayValue < 100 && dayValue > -100
                                                                                                ? dayValue < 10 && dayValue > -10
                                                                                                    ? parseFloat(dayValue).toFixed(2)
                                                                                                    : parseFloat(dayValue).toFixed(1)
                                                                                                : dayValue })) })) }, day));
                                                                            })] }) }, providerName.dpName));
                                                            }
                                                        }), _jsx("tr", { children: _jsx("td", { children: "\u00A0" }) })] }, months.groupMonth));
                                            }) })] })) })) })) }))] }))) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No data available, Please reselect filters." }))] })) }))) }));
    };
    return DailyView;
}(Component));
export default DailyView;
