import { __assign } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, Flexbox, Icons } from '@sede-x/shell-ds-react-framework';
import { selectSorting, setSorting } from './ducks/slice';
import { CapacityCheckbox } from './CapacityCheckbox';
import { useAppDispatch, useAppSelector } from 'common/hooks/store';
import { renderDateColumn } from 'common/utils/renderDateColumn.util';
import { renderSortingTitle } from 'common/utils/renderSortingTitle.util';
import { userFriendlyNameMappings } from 'common/utils/userFriendlyNameMappings';
import { StyledQuantity } from 'components/capacity-actions-modal/CapacityActionsModal.styles';
export var renderCellWithEllipsis = function (name) { return (_jsx("div", __assign({ title: name, style: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '100px' } }, { children: name }))); };
export var DivWithEllipsis = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ title: children, style: {
            whiteSpace: 'nowrap',
            width: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        } }, { children: children })));
};
var Edit = Icons.Edit, EyeSolid = Icons.EyeSolid;
var MAX_EVENTS = 20;
export var useTableColumns = function (checkedEvents, handleSelectEvent, handleHistoryClick) {
    var dispatch = useAppDispatch();
    var sorting = useAppSelector(selectSorting);
    var handleSorting = function (sortingProp) { return dispatch(setSorting(sortingProp)); };
    return [
        {
            id: 'Selected events',
            header: function () { return (_jsx("div", __assign({ style: { paddingTop: 4 } }, { children: _jsx(Checkbox, { size: "xsmall", checked: checkedEvents.length === MAX_EVENTS, indeterminate: checkedEvents.length > 0 && checkedEvents.length < MAX_EVENTS, onClick: function () { return handleSelectEvent(); } }) }))); },
            enableHiding: false,
            enableResizing: false,
            size: 50,
            cell: function (info) {
                var record = info.row.original;
                return (_jsx("div", __assign({ style: { marginTop: 6 }, onClick: function (e) {
                        e.stopPropagation();
                        handleSelectEvent(record);
                    } }, { children: _jsx(CapacityCheckbox, { checkedEvents: checkedEvents.map(function (e) { return e.assetUnitCapacityKey; }), eventKey: record.assetUnitCapacityKey }) })));
            },
        },
        {
            id: 'Start date',
            header: function () { return renderSortingTitle(handleSorting, 'Start date', 'startDate', sorting); },
            accessorKey: 'startDate',
            cell: function (info) { return renderDateColumn(info.getValue()); },
            size: 150,
            enableResizing: false,
            enableHiding: false,
        },
        {
            id: 'Asset',
            header: function () { return renderSortingTitle(handleSorting, 'Asset', 'asset', sorting); },
            accessorKey: 'asset',
            enableHiding: false,
        },
        {
            id: 'Asset key',
            header: function () { return renderSortingTitle(handleSorting, 'Asset key', 'assetKey', sorting); },
            accessorKey: 'assetKey',
        },
        {
            id: 'Company',
            header: function () { return renderSortingTitle(handleSorting, 'Company', 'company', sorting); },
            accessorKey: 'company',
        },
        {
            id: 'Country',
            header: function () { return renderSortingTitle(handleSorting, 'Country', 'country', sorting); },
            accessorKey: 'country',
        },
        {
            id: 'City',
            header: function () { return renderSortingTitle(handleSorting, 'City', 'city', sorting); },
            accessorKey: 'city',
        },
        {
            id: 'RTL',
            header: function () { return renderSortingTitle(handleSorting, 'RTL Region', 'rtl', sorting); },
            accessorKey: 'rtl',
        },
        {
            id: 'RTT',
            header: function () { return renderSortingTitle(handleSorting, 'RTT Region', 'rtt', sorting); },
            accessorKey: 'rtt',
        },
        {
            id: 'Other asset names',
            header: function () { return renderSortingTitle(handleSorting, 'Other asset names', 'allAssetNames', sorting); },
            accessorKey: 'allAssetNames',
        },
        {
            id: 'Unit',
            header: function () { return renderSortingTitle(handleSorting, 'Unit', 'unit', sorting); },
            accessorKey: 'unit',
        },
        {
            id: 'Unit key',
            header: function () { return renderSortingTitle(handleSorting, 'Unit key', 'unitKey', sorting); },
            accessorKey: 'unitKey',
        },
        {
            id: 'Subtype',
            header: function () { return renderSortingTitle(handleSorting, 'Subtype', 'unitSubtype', sorting); },
            accessorKey: 'unitSubType',
        },
        {
            id: 'Probability',
            header: function () { return renderSortingTitle(handleSorting, 'Probability', 'probability', sorting); },
            accessorKey: 'probability',
        },
        {
            id: 'Basis',
            header: function () { return renderSortingTitle(handleSorting, 'Basis', 'capacityBasisType', sorting); },
            accessorKey: 'capacityBasisType',
        },
        {
            id: 'Total cap.',
            header: function () { return renderSortingTitle(handleSorting, 'Total cap.', 'capacityOrigQty', sorting); },
            accessorKey: 'capacityOrigQty',
            cell: function (info) { return (_jsxs(_Fragment, { children: [parseFloat(info.getValue()).toFixed(2), " ", info.row.original.measureUnitCd] })); },
        },
        {
            id: 'Source',
            header: function () { return renderSortingTitle(handleSorting, 'Source', 'source', sorting); },
            accessorKey: 'source',
        },
        {
            id: 'Status',
            header: function () { return renderSortingTitle(handleSorting, 'Status', 'status', sorting); },
            accessorKey: 'status',
            cell: function (info) { var _a; return (_a = info.getValue()) !== null && _a !== void 0 ? _a : '—'; },
        },
        {
            id: 'Updated',
            header: function () { return renderSortingTitle(handleSorting, 'Updated', 'updateDate', sorting); },
            accessorKey: 'updateDate',
            cell: function (info) { return renderDateColumn(info.getValue()); },
        },
        {
            id: 'Edited by',
            header: function () { return renderSortingTitle(handleSorting, 'Edited by', 'editedBy', sorting); },
            accessorKey: 'editedBy',
            cell: function (info) { return _jsx(_Fragment, { children: userFriendlyNameMappings.get(info.getValue()) || info.getValue() }); },
        },
        {
            id: 'Related events',
            header: function () { return renderSortingTitle(handleSorting, 'Related events', 'relatedEventLastUpdateDate', sorting); },
            accessorKey: 'relatedEventLastUpdateDate',
            cell: function (_a) {
                var row = _a.row;
                var record = row.original;
                return (_jsx(_Fragment, { children: _jsxs(Button, __assign({ variant: "transparent", size: "small", onClick: function (e) {
                            e.stopPropagation();
                            handleHistoryClick(record.assetUnitCapacityKey, record.assetKey, record.unitSubTypeKey, 'related');
                        } }, { children: [_jsx("span", __assign({ style: { marginRight: '4px' } }, { children: record.relatedEventCount })), record.relatedEventLastUpdateDate && (_jsxs(StyledQuantity, { children: ["(", renderDateColumn(record.relatedEventLastUpdateDate), ")"] }))] })) }));
            },
        },
        {
            id: 'Actions',
            header: 'Actions',
            enableHiding: false,
            enableResizing: false,
            size: 100,
            cell: function (_a) {
                var row = _a.row;
                var record = row.original;
                return (_jsxs(Flexbox, { children: [_jsx(Button, { title: "history", onClick: function (e) {
                                e.stopPropagation();
                                handleHistoryClick(record.assetUnitCapacityKey, record.assetKey, record.unitSubTypeKey);
                            }, iconOnly: true, size: "small", icon: _jsx(EyeSolid, {}), variant: "transparent" }), _jsx(Button, { size: "small", icon: _jsx(Edit, {}), variant: "transparent", title: "edit", iconOnly: true })] }));
            },
        },
    ];
};
