import { __makeTemplateObject } from "tslib";
import { Label } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { listWidth, dayHeight, unitHeight, listItemMarginBottom } from './../../MaintenancePanel.styles';
export var StyledList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", "px;\n  margin-top: ", "px;\n"], ["\n  width: ", "px;\n  margin-top: ", "px;\n"])), listWidth, dayHeight);
export var StyledListItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", "px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: ", "px;\n"])), listItemMarginBottom);
export var StyledListItemAsset = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 12px;\n  height: 32px;\n\n  label {\n    margin-bottom: 0;\n    text-align: right;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 12px;\n  height: 32px;\n\n  label {\n    margin-bottom: 0;\n    text-align: right;\n  }\n"])));
export var StyledListItemUnit = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: ", "px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  label {\n    margin-bottom: 0;\n    text-align: right;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  height: ", "px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  label {\n    margin-bottom: 0;\n    text-align: right;\n  }\n"])), unitHeight);
export var StyledListItemUnitName = styled(Label)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  height: ", "px;\n  padding-right: 8px;\n  font-family: 'Shell Font';\n"], ["\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  height: ", "px;\n  padding-right: 8px;\n  font-family: 'Shell Font';\n"])), unitHeight);
export var StyledListItemUnitNameWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  justify-content: end;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"], ["\n  justify-content: end;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"])));
export var StyledTooltipOverlay = styled(Label)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 8px;\n  margin-bottom: 0;\n"], ["\n  padding: 8px;\n  margin-bottom: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
