import { __assign, __spreadArray } from "tslib";
import { useCallback } from 'react';
export var defaultColumns = [
    'Updated',
    'Asset',
    'Unit',
    'Subtype',
    'Offline %',
    'Total cap.',
    'Start date',
    'End date',
    'Event type',
    'Event cause',
    'Source',
    'Locked',
    'Actions',
];
export var getStickyStyles = function (index, type) {
    var defaultZIndex = 10;
    if (index === 0) {
        return {
            left: 0,
            zIndex: type === 'row' ? defaultZIndex : 0,
        };
    }
    if (index === 1) {
        return {
            left: 200,
            zIndex: type === 'row' ? defaultZIndex : 0,
        };
    }
};
export var useHandleSelectAll = function (setSelectedEvents, setChecked, groupEvents, checked) {
    return useCallback(function () {
        if (checked === true) {
            setSelectedEvents([]);
            setChecked(false);
        }
        else {
            setSelectedEvents(__spreadArray([], groupEvents, true));
            setChecked(true);
        }
    }, [checked, groupEvents, setChecked, setSelectedEvents]);
};
export var useExpandRowCheckboxChange = function (selectedEvents, setSelectedEvents) {
    return useCallback(function (event) {
        var newSelection = __spreadArray([], selectedEvents, true);
        var index = newSelection.findIndex(function (selection) { return selection.asset_unit_event_key === event.asset_unit_event_key; });
        if (index === -1) {
            newSelection.push(event);
        }
        else {
            newSelection.splice(index, 1);
        }
        setSelectedEvents(newSelection);
    }, [selectedEvents]);
};
export var convertToTableData = function (events) {
    return events.map(function (event) {
        if (event.length === 1) {
            return event[0];
        }
        var groupedEvent = __assign(__assign({}, event[0]), { asset_unit_nm: 'Multiple', asset_unit_subtype_cd: 'Multiple', subrows: event });
        return groupedEvent;
    });
};
