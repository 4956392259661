import React, { ReactElement } from 'react';

import { ILabelProps } from '../../FormField.types';
import * as Styled from './Label.styles';

const LabelMessage = ({ label, size, mandatory }: Pick<ILabelProps, 'label' | 'size' | 'mandatory'>) => (
  <Styled.LabelText>
    {label && <Styled.Title size={size}>{label}</Styled.Title>}
    {mandatory && <Styled.Mandatory size={size}>*</Styled.Mandatory>}
  </Styled.LabelText>
);

const Label = ({ size, icon, iconPosition, id, label, description, mandatory, helper }: ILabelProps): ReactElement => (
  <Styled.Container>
    <Styled.LeftContainer size={size}>
      <Styled.TitleContainer iconPosition={iconPosition} size={size} htmlFor={id}>
        {icon}
        <LabelMessage label={label} size={size} mandatory={mandatory} />
      </Styled.TitleContainer>
      {description && <Styled.Description>{description}</Styled.Description>}
    </Styled.LeftContainer>
    {helper}
  </Styled.Container>
);

export default Label;
