import { TableSize } from '../Table.types';
import { Sizes } from '../../../types/types';

export const getSize = (size: TableSize) => {
  switch (size) {
    case Sizes.Medium:
      return Sizes.Small;
    case Sizes.Large:
      return Sizes.Medium;
    default:
      return Sizes.ExtraSmall;
  }
};
