import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import BaselineProviders from './baselineProviders';
import BaselineType from './baselineType';
import './../generalConfiguration/generalConfiguration.css';
var baselineConfigurationNavLink = /** @class */ (function (_super) {
    __extends(baselineConfigurationNavLink, _super);
    function baselineConfigurationNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    baselineConfigurationNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration  mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Baseline Configuration ", _jsx("span", { children: "(Configuration Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/baselineConfiguration/baselineType", className: "nav-link", activeClassName: "active" }, { children: "Baseline Type" })), _jsx(NavLink, __assign({ to: "/dashboard/baselineConfiguration/baselineProvider", className: "nav-link", activeClassName: "active" }, { children: "Baseline Data Provider" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/baselineconfiguration", to: "/dashboard/baselineConfiguration/baselineType", exact: true }), _jsx(Route, { path: "/dashboard/baselineConfiguration/baselineProvider", component: BaselineProviders, exact: true }), _jsx(Route, { path: "/dashboard/baselineConfiguration/baselineType", component: BaselineType, exact: true })] }) }))] }))] })) }));
    };
    return baselineConfigurationNavLink;
}(Component));
export default baselineConfigurationNavLink;
