import React, { ReactElement } from 'react';

import { MenuItemGroup as RCMenuItemGroup } from 'rc-menu';
import { MenuItemGroupProps as RCMenuItemGroupProps } from 'rc-menu/lib/MenuItemGroup';

import { MENU_CLASS } from '../../../utils/constants';
import MenuLabel from './MenuLabel';

export interface IMenuItemGroupProps extends RCMenuItemGroupProps {}

const Title = ({ title }: IMenuItemGroupProps): ReactElement => (
  <div className={`${MENU_CLASS}-group-title-content`}>
    <MenuLabel>{title}</MenuLabel>
  </div>
);

const MenuItemGroup = ({ children, title, ...rest }: IMenuItemGroupProps): ReactElement => (
  <RCMenuItemGroup title={<Title title={title} />} {...rest}>
    {children}
  </RCMenuItemGroup>
);

export default MenuItemGroup;
