import { __spreadArray } from "tslib";
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { updateUnitRow, updateSortedEvents, valueOrEmptyString } from './use-maintenance-daily.utils';
export var useMaintenanceDaily = function (data, monthDate) {
    return useMemo(function () {
        var rows = [];
        var assets = [];
        if (!monthDate) {
            return { rows: rows, assets: assets };
        }
        (data.assetDetails || []).forEach(function (asset) {
            var tempAsset = {
                name: asset.assetNm,
                owner: '',
                units: [],
            };
            rows.push({
                isDivider: true,
                lines: [],
            });
            asset.units.forEach(function (unit) {
                var firstEvent = unit.maintenanceDailyDataLayerLsts[0];
                var capacityScreen = "".concat(firstEvent === null || firstEvent === void 0 ? void 0 : firstEvent.totalCapacity).concat(firstEvent === null || firstEvent === void 0 ? void 0 : firstEvent.measureUnitCd);
                tempAsset.owner = valueOrEmptyString(firstEvent, firstEvent === null || firstEvent === void 0 ? void 0 : firstEvent.ownerNm);
                tempAsset.units.push({
                    name: "".concat(unit.unitNm),
                    capacity: valueOrEmptyString(firstEvent, capacityScreen),
                });
                var unitRow = {
                    isDivider: false,
                    lines: [],
                };
                var sortedEvents = __spreadArray([], unit.maintenanceDailyDataLayerLsts, true).sort(function (a, b) { return new Date(a.startDate).getTime() - new Date(b.startDate).getTime(); });
                sortedEvents.forEach(function (event) { return updateSortedEvents(event, monthDate, unitRow); });
                if (!unitRow.lines.length) {
                    unitRow.lines.push({
                        startDate: monthDate,
                        type: 'on',
                        days: dayjs(monthDate).daysInMonth(),
                    });
                }
                var finalLines = [];
                var sortedUnitRowLines = __spreadArray([], unitRow.lines, true).sort(function (a, b) { var _a, _b; return new Date((_a = a.startDate) !== null && _a !== void 0 ? _a : '').getTime() - new Date((_b = b.startDate) !== null && _b !== void 0 ? _b : '').getTime(); });
                unitRow.lines = sortedUnitRowLines;
                unitRow.lines.forEach(function (line, i, arr) { return updateUnitRow(line, i, arr, monthDate, finalLines); });
                unitRow.lines = finalLines;
                rows.push(unitRow);
            });
            assets.push(tempAsset);
        });
        return { rows: rows, assets: assets };
    }, [data, monthDate]);
};
