import React, { ChangeEvent, ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { NOOP, TEXT_AREA_CLASS } from '../../utils/constants';
import * as Styled from './TextArea.styles';
import { ITextAreaProps } from './TextArea.types';
import { Sizes, Positions } from '../../types/types';

const TextArea = forwardRef(
  (
    {
      className,
      size = Sizes.Medium,
      icon = null,
      iconPosition = Positions.Left,
      value = '',
      invalid = false,
      onChange = NOOP,
      disabled = false,
      resize = 'both',
      wrapperProps,
      ...rest
    }: ITextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const [innerValue, setInnerValue] = useState(value);

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e);
        setInnerValue(e.target.value);
      },
      [onChange],
    );

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    return (
      <Styled.TextAreaWrapper
        className={classNames(TEXT_AREA_CLASS, className)}
        size={size}
        value={innerValue}
        iconPosition={iconPosition}
        disabled={disabled}
        {...wrapperProps}
      >
        {iconPosition === Positions.Left && icon}
        <Styled.TextArea
          ref={ref}
          size={size}
          value={innerValue}
          onChange={handleOnChange}
          invalid={invalid}
          disabled={disabled}
          resize={resize}
          icon={icon}
          iconPosition={iconPosition}
          {...rest}
        />
        {iconPosition === Positions.Right && icon}
      </Styled.TextAreaWrapper>
    );
  },
);

export default TextArea;
