import styled, { css } from 'styled-components';

import { textConsts } from '../../Text.consts';
import { ITextProps } from './Text.types';
import { textFont } from '../../utils/textUtils';

const Text = styled.p<ITextProps & Required<Pick<ITextProps, 'size'>>>`
  ${({ size, gutter, bold, prominence, theme }) => css`
    ${textFont(size, bold ? 'bold' : 'normal')}
    margin-top: 0;
    margin-bottom: 0;

    ${prominence &&
    css`
      color: ${theme.text.onSurface[prominence]};
    `}

    ${gutter &&
    css`
      margin-bottom: ${textConsts.gutter.p};
    `}

    strong {
      ${textFont(size, 'bold')}
    }
  `}
`;

export { Text };
