import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './pagination.css';
/*
 *A generic utility function to formulate the array of
 *page numbers in the component. Takes the following parameters
 * from : the current page number
 * to : total page numbers
 * maxPages : a constant; which is always 5, makes sure there are
 *only atmost 5 items in the array
 */
var calculatePaginationPages = function (from, to, maxPages, step) {
    if (step === void 0) { step = 1; }
    var i = from;
    var pageCount = 1;
    var pageRange = [];
    while (i <= to && pageCount <= maxPages) {
        pageCount = pageCount + 1;
        pageRange.push(i);
        i += step;
    }
    return pageRange;
};
// an arry for page limits/size
var pageSizeList = [20, 50, 60, 70, 80, 90, 100];
/*
 * A generic pagination component
 */
function Pagination(props) {
    //holds the current page number
    var _a = useState(1), currentPageNumber = _a[0], setCurrentPageNumber = _a[1];
    //calculate the total pages using the total incoming records
    var _b = useState((props.totalRecords + (props.pageLimit - 1)) / props.pageLimit), totalPages = _b[0], setTotalPages = _b[1];
    //holds the array of page numbers in the component
    var _c = useState([]), paginationPages = _c[0], setPaginationPages = _c[1];
    //max number of page numbers in the array
    var maxPages = 5;
    //holds the reference of page 1
    var pageStartFrom = 1;
    //holds the current page size / page limit
    var _d = useState(props.pageLimit), pageSize = _d[0], setPageSize = _d[1];
    //move one page to the right
    function moveRight() {
        currentPageNumber = currentPageNumber + 1;
        if (currentPageNumber > totalPages)
            currentPageNumber = Math.trunc(totalPages);
        setCurrentPageNumber(currentPageNumber);
    }
    //move one page to the left
    function moveLeft() {
        if (currentPageNumber != 1) {
            currentPageNumber = currentPageNumber - 1;
        }
        setCurrentPageNumber(currentPageNumber);
    }
    //move to the last page
    function moveForward() {
        /*  setCurrentPageNumber(
          Math.trunc(totalPages) >= maxPages
            ? Math.trunc(totalPages) - maxPages + 1
            : currentPageNumber
        ); */
        setCurrentPageNumber(totalPages);
    }
    //move to the first page
    function moveBackward() {
        setCurrentPageNumber(pageStartFrom);
    }
    //update the current page number on every click
    useEffect(function () {
        // setCurrentPageNumber(currentPageNumber);
        if (currentPageNumber + maxPages - 1 <= totalPages) {
            setPaginationPages(calculatePaginationPages(currentPageNumber, totalPages, maxPages));
            props.onPageChange(currentPageNumber, pageSize);
        }
        else {
            setPaginationPages(calculatePaginationPages(Math.trunc(totalPages) - maxPages + 1 < 0 ? 1 : Math.trunc(totalPages) - maxPages + 1, totalPages, maxPages));
            props.onPageChange(currentPageNumber, pageSize);
        }
    }, [currentPageNumber]);
    useEffect(function () {
        //  setPageSize(pageSize);
        setTotalPages(Math.ceil(props.totalRecords / pageSize));
        props.onPageChange(currentPageNumber, pageSize);
        // setCurrentPageNumber(currentPageNumber);
    }, [pageSize]);
    useEffect(function () {
        setPaginationPages(calculatePaginationPages(pageStartFrom, totalPages, maxPages));
    }, [totalPages]);
    function handlePageLimitChange(event) {
        setPageSize(event.target.value);
        setCurrentPageNumber(pageStartFrom);
    }
    return (_jsxs("div", __assign({ className: "pagination-common row", style: { paddingTop: 5 } }, { children: [_jsx("div", __assign({ className: "col-10" }, { children: _jsxs("ul", __assign({ className: "pagination" }, { children: [_jsx("li", __assign({ className: "page-item-icons", style: { width: 31 } }, { children: _jsx("button", __assign({ className: "paginaton-button", onClick: moveBackward }, { children: _jsx("i", { title: "First", className: "fa fa-backward", style: { fontSize: 15 } }) })) })), _jsx("li", __assign({ className: "page-item-icons", style: { width: 31 } }, { children: _jsx("button", __assign({ className: "paginaton-button", onClick: moveLeft }, { children: _jsx("i", { title: "Previous", className: "fa fa-caret-left", style: { fontSize: 15 } }) })) })), paginationPages.map(function (page, index) { return (_jsx("li", __assign({ className: currentPageNumber === page ? 'active-page' : 'page-item' }, { children: _jsx("a", __assign({ className: "page-link", onClick: function () {
                                    setCurrentPageNumber(page);
                                } }, { children: page })) }), index)); }), _jsx("li", __assign({ className: "page-item-icons", style: { width: 31 } }, { children: _jsx("button", __assign({ className: "paginaton-button", onClick: moveRight }, { children: _jsx("i", { title: "Next", className: "fa fa-caret-right", style: { fontSize: 15 } }) })) })), _jsx("li", __assign({ className: "page-item-icons", style: { width: 31 } }, { children: _jsx("button", __assign({ className: "paginaton-button", onClick: moveForward }, { children: _jsx("i", { title: "Last", className: "fa fa-forward", style: { fontSize: 15 } }) })) }))] })) })), _jsx("div", __assign({ className: "col-2" }, { children: _jsxs("div", __assign({ className: "pagelimit-item" }, { children: ["Page limit", _jsx("select", __assign({ className: "pagelimit-dropdown", onChange: handlePageLimitChange }, { children: pageSizeList.map(function (pagesize) {
                                if (parseInt(props.pageLimit) === pagesize) {
                                    return (_jsx("option", __assign({ className: "pagelimit-dropdown-item", value: pagesize, selected: true }, { children: pagesize })));
                                }
                                else {
                                    return (_jsx("option", __assign({ className: "pagelimit-dropdown-item", value: pagesize }, { children: pagesize })));
                                }
                            }) }))] })) }))] })));
}
Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number.isRequired,
};
export default Pagination;
