import { forwardRef, KeyboardEventHandler, MouseEventHandler, ReactElement, useState } from 'react';

import classNames from 'classnames';

import { NAV_BAR_CLASS } from '../../utils/constants';
import LastArea from './components/LastArea/LastArea';
import FirstArea from './components/FirstArea/FirstArea';
import { Wrapper, Expand, ExpandIcon, ExpandNotch, MiddleArea, Nav, StyledMenu } from './NavBar.styles';
import { INavBarProps, NavBarDirection } from './NavBar.types';
import { ChevronSmallRight } from '../Icon/components';
import { IAvatarProps } from '../Avatar/Avatar.types';
import { IMenuProps } from '../Menu/Menu.types';
import { Directions, Sizes } from '../../types/types';

const NavBar = forwardRef<HTMLElement, INavBarProps>(
  (
    {
      elevation,
      float,
      collapsed = false,
      pectenArea = 'default',
      preventExpand = false,
      expandedWidth,
      className,
      orientation,
      mode,
      direction,
      children,
      avatarArea,
      avatarProps,
      items,
      header,
      selectedPath,
      itemRender,
      size = Sizes.Medium,
      ...rest
    },
    ref,
  ): ReactElement => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);

    const innerDirection = direction || mode || orientation || Directions.Horizontal;
    const innerElevation = elevation || float || true;

    const menuModeMap: { [Property in NavBarDirection]: IMenuProps['mode'] } = {
      vertical: 'inline',
      horizontal: 'horizontal',
    };

    const handleOnExpandClick: MouseEventHandler = () => {
      setIsCollapsed(!isCollapsed);
    };

    const handleOnExpandKeyPress: KeyboardEventHandler = (e) => {
      if (e.code === 'Enter') {
        setIsCollapsed(!isCollapsed);
      }
    };

    const mergedAvatarProps: IAvatarProps = {
      ...avatarProps,
      collapsed: isCollapsed,
      size,
    };

    return (
      <Wrapper $direction={innerDirection} size={size}>
        <Nav
          as={innerDirection === Directions.Vertical ? 'nav' : 'header'}
          className={classNames(NAV_BAR_CLASS, className)}
          $direction={innerDirection}
          elevation={innerElevation}
          collapsed={isCollapsed}
          expandedWidth={expandedWidth}
          size={size}
          ref={ref}
          {...rest}
        >
          {pectenArea !== 'none' && (
            <FirstArea header={header} pectenArea={pectenArea} collapsed={isCollapsed} size={size} />
          )}
          {direction === Directions.Vertical && !preventExpand && (
            <Expand
              tabIndex={0}
              role="button"
              aria-roledescription="Expand navigation bar"
              onClick={handleOnExpandClick}
              onKeyDown={handleOnExpandKeyPress}
            >
              <ExpandNotch />
              <ExpandIcon>
                <ChevronSmallRight className={`${NAV_BAR_CLASS}-icon`} width={20} height={20} />
              </ExpandIcon>
            </Expand>
          )}
          <MiddleArea $direction={innerDirection} collapsed={isCollapsed}>
            {!!children && !items ? (
              children
            ) : (
              <StyledMenu
                triggerSubMenuAction="click"
                inlineCollapsed={isCollapsed}
                items={items}
                itemRender={itemRender}
                mode={menuModeMap[innerDirection]}
                selectedPath={selectedPath}
                size={size}
              />
            )}
          </MiddleArea>
          {(!!avatarArea || !!avatarProps) && (
            <LastArea avatarArea={avatarArea} avatarProps={avatarProps && mergedAvatarProps} />
          )}
        </Nav>
      </Wrapper>
    );
  },
);

export default NavBar;
