import styled, { css } from 'styled-components';

import { StyledButtonProps } from '../Button/Button.types';
import { CommonStyles } from '../Button/Button.styles';
import * as Styled from '../Button/components/ButtonContent/ButtonContent.styles';

const ButtonLink = styled.a<StyledButtonProps>`
  ${CommonStyles};

  ${({ theme }) => css`
    color: ${theme.text.onSurface.strong};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: ${theme.text.onSurface.visited};

      &:hover {
        color: ${theme.text.onSurface.visited};
      }

      ${Styled.ButtonContent} > svg {
        fill: ${theme.text.onSurface.visited};
      }
    }
  `}
`;

export { ButtonLink };
