import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Text, Tabs } from '@sede-x/shell-ds-react-framework';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { CapacityTrends } from 'components/capacity-trends';
import { MaintenanceDailySummary } from 'pages/maintenance-daily-summary';
import { EmbeddedReport } from 'components/embedded-report';
import { SidebarTemplate } from 'components/sidebar-template';
import { setSidebarOpenState, selectIsSidebarOpen } from 'pages/maintenance-daily-summary/ducks/slice';
import { useSidebar } from 'components/sidebar-template/hooks';
import { MaintenanceFilter } from 'components/maintenance-filter';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { isValidTimeframe } from 'pages/maintenance-event-summary/MaintenanceEventSummary';
import { parse } from 'common/utils/newScreenUtils';
import { ROUTE_PATHS } from 'app/routes';
import { resolveParamsObject } from 'common/utils/url/search-params';
export var Trends = function () {
    var _a;
    var _b = useSidebar(selectIsSidebarOpen, setSidebarOpenState), handleSidebarClose = _b.handleSidebarClose, handleSidebarOpen = _b.handleSidebarOpen, isSidebarOpen = _b.isSidebarOpen;
    var location = useLocation();
    var search = location.search;
    var params = new URLSearchParams(search);
    var history = useHistory();
    var handleTabClick = useCallback(function (tabKey) { return history.push("/trends?tab=".concat(tabKey)); }, [history]);
    var trendType = (_a = params.get('tab')) !== null && _a !== void 0 ? _a : 'capacity';
    document.title =
        trendType === 'daily' ? 'Daily summary • Asset Capacity & Maintenance' : 'Trends • Asset Capacity & Maintenance';
    var initialValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var search = location.search;
        var params = new URLSearchParams(search);
        var today = dayjs().format(DATE_FORMAT_YYYY_MM_DD);
        var versionParam = params.get('version');
        var timeframeParams = params.getAll('timeframe');
        return {
            assets: (_a = params.getAll('assets')) === null || _a === void 0 ? void 0 : _a.map(parse),
            regions: (_b = params.getAll('regions')) === null || _b === void 0 ? void 0 : _b.map(parse),
            location: (_c = params.getAll('location')) === null || _c === void 0 ? void 0 : _c.map(parse),
            owners: (_d = params.getAll('owners')) === null || _d === void 0 ? void 0 : _d.map(parse),
            units: (_e = params.getAll('units')) === null || _e === void 0 ? void 0 : _e.map(parse),
            unitSubTypes: (_f = params.getAll('unitSubTypes')) === null || _f === void 0 ? void 0 : _f.map(parse),
            unitTypes: (_g = params.getAll('unitTypes')) === null || _g === void 0 ? void 0 : _g.map(parse),
            version: dayjs(versionParam).isValid() ? dayjs(versionParam).format(DATE_FORMAT_YYYY_MM_DD) : today,
            timeframe: isValidTimeframe(timeframeParams)
                ? [timeframeParams[0], timeframeParams[1]]
                : [
                    dayjs().startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
                    dayjs().add(3, 'month').endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
                ],
            assetOutageGroupKey: (_h = params.getAll('assetOutageGroupKey')) === null || _h === void 0 ? void 0 : _h.map(parse),
            derateCapacityInd: params.getAll('derateCapacityInd'),
            eventCauseTypeKey: (_j = params.getAll('eventCauseTypeKey')) === null || _j === void 0 ? void 0 : _j.map(parse),
        };
    }, [location]);
    var _c = useState(false), reset = _c[0], setReset = _c[1];
    var handleReset = function (reset) { return setReset(reset); };
    var handleFilterSubmit = function (values) {
        var convertedValues = __assign(__assign({}, values), { tab: [trendType], version: [values.version], chartMode: params.getAll('chartMode'), splitByType: params.getAll('splitByType'), splitBySubType: params.getAll('splitBySubType') });
        history.push({
            pathname: ROUTE_PATHS.TRENDS,
            search: new URLSearchParams(resolveParamsObject(convertedValues)).toString(),
        });
    };
    var isEventSummaryOrTrends = trendType === 'capacity';
    return (_jsx(SidebarTemplate, __assign({ sidebarOpen: isSidebarOpen, onSidebarClose: handleSidebarClose, onSidebarOpen: handleSidebarOpen, sidebar: _jsx(MaintenanceFilter, __assign({}, initialValues, { onSubmit: handleFilterSubmit, isEventSummary: isEventSummaryOrTrends, sidebarOpen: isSidebarOpen, handleReset: handleReset, isTrends: isEventSummaryOrTrends })), sidebarTitle: "Filters" }, { children: _jsx("div", __assign({ style: { maxHeight: '95%', overflow: 'auto' } }, { children: _jsx(Tabs, { activeKey: trendType, tabBarStyle: {
                    justifyContent: 'flex-start',
                    paddingBottom: '20px',
                }, destroyInactiveTabPane: true, onChange: handleTabClick, items: [
                    {
                        label: _jsx(Text, __assign({ size: "small" }, { children: "Outage trends" })),
                        key: 'capacity',
                        children: (_jsx(CapacityTrends, { props: {
                                regionIds: initialValues.regions,
                                countryIds: initialValues.location,
                                assetIds: initialValues.assets,
                                companyIds: initialValues.owners,
                                unitSubTypeIds: initialValues.unitSubTypes,
                                unitTypeIds: initialValues.unitTypes,
                                assetUnitIds: initialValues.units,
                                version: initialValues.version,
                                startDate: initialValues.timeframe[0],
                                endDate: initialValues.timeframe[1],
                                assetOutageGroupKey: initialValues.assetOutageGroupKey,
                                derateCapacityInd: initialValues.derateCapacityInd,
                                eventCauseTypeKey: initialValues.eventCauseTypeKey,
                            }, open: isSidebarOpen })),
                    },
                    {
                        label: _jsx(Text, __assign({ size: "small" }, { children: "Upcoming capacities" })),
                        key: 'upcoming_capacity',
                        children: (_jsx(EmbeddedReport, { reportId: "3bad1cfa-bc43-4b3d-beea-bf3cb2b90da4", pageName: "ReportSection808bdbba9768a38d548c", format: "Tabbed" })),
                    },
                    {
                        label: _jsx(Text, __assign({ size: "small" }, { children: "Daily summary" })),
                        key: 'daily',
                        children: _jsx(MaintenanceDailySummary, { reset: reset }),
                    },
                ] }) })) })));
};
