import { TagShape } from '../Tag.types';
import { Shapes } from '../../../types/types';

export const getInnerShape = (shape?: TagShape): Shapes => {
  if (shape === 'round' || shape === Shapes.Rounded) {
    return Shapes.Rounded;
  }

  return Shapes.Square;
};
