import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, RangeDatePicker } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { FilterFieldWrapper, StyledLabel } from './MaintenanceFilter.styles';
import { useResolveSelectOnChange, useSelectsConfigData, useTimeHandlers } from './MaintenanceFilter.hooks';
import { TrendsFilters } from './TrendsFilters';
import { filterResetObject } from './MaintenanceFilter.utils';
import { Select } from 'components/select';
import { MonthPicker } from 'components/month-picker';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { FilterRow } from 'common/styles/FilterStyles';
// eslint-disable-next-line complexity
export var MaintenanceFilter = function (_a) {
    var _b, _c, _d;
    var _e = _a.assets, initialAssets = _e === void 0 ? [] : _e, _f = _a.regions, initialRegions = _f === void 0 ? [] : _f, _g = _a.location, initialLocation = _g === void 0 ? [] : _g, _h = _a.owners, initialOwners = _h === void 0 ? [] : _h, _j = _a.units, initialUnits = _j === void 0 ? [] : _j, _k = _a.unitSubTypes, initialUnitSubTypes = _k === void 0 ? [] : _k, _l = _a.unitTypes, initialUnitTypes = _l === void 0 ? [] : _l, _m = _a.assetOutageGroupKey, initialAssetOutageGroupKey = _m === void 0 ? [] : _m, _o = _a.derateCapacityInd, initialDerateCapacityInd = _o === void 0 ? [] : _o, _p = _a.eventCauseTypeKey, initialEventCauseTypeKey = _p === void 0 ? [] : _p, _q = _a.timeframe, initialTimeframe = _q === void 0 ? [
        dayjs().startOf('month').toISOString(),
        dayjs().add(3, 'month').endOf('month').toISOString(),
    ] : _q, _r = _a.version, initialVersion = _r === void 0 ? dayjs().toISOString() : _r, onSubmit = _a.onSubmit, isEventSummary = _a.isEventSummary, sidebarOpen = _a.sidebarOpen, handleReset = _a.handleReset, isTrends = _a.isTrends;
    var form = useForm({
        defaultValues: {
            assets: initialAssets,
            location: initialLocation,
            regions: initialRegions,
            owners: initialOwners,
            units: initialUnits,
            unitSubTypes: initialUnitSubTypes,
            unitTypes: initialUnitTypes,
            timeframe: initialTimeframe,
            version: dayjs(initialVersion).isValid()
                ? dayjs(initialVersion).format(DATE_FORMAT_YYYY_MM_DD)
                : dayjs().format(DATE_FORMAT_YYYY_MM_DD),
            assetOutageGroupKey: initialAssetOutageGroupKey,
            derateCapacityInd: initialDerateCapacityInd,
            eventCauseTypeKey: initialEventCauseTypeKey,
        },
    });
    var setValue = form.setValue, watch = form.watch, reset = form.reset, handleSubmit = form.handleSubmit;
    var _s = useState(true), disableApply = _s[0], setDisableApply = _s[1];
    var watchTimeframe = watch('timeframe');
    var resolveSelectOnChange = useResolveSelectOnChange(form);
    var config = useSelectsConfigData(form, resolveSelectOnChange, !sidebarOpen);
    var _t = useTimeHandlers(form, initialTimeframe), handleTimeFrameChange = _t.handleTimeFrameChange, handleRangePickerChange = _t.handleRangePickerChange;
    var hasDirtyField = !!config.map(function (el) { var _a; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.length; }).reduce(function (acc, curr) { return acc || curr; });
    var isDirtyTimeframe = (filterResetObject === null || filterResetObject === void 0 ? void 0 : filterResetObject.timeframe) !== undefined &&
        (watchTimeframe[0] !== (filterResetObject === null || filterResetObject === void 0 ? void 0 : filterResetObject.timeframe[0]) || watchTimeframe[1] !== (filterResetObject === null || filterResetObject === void 0 ? void 0 : filterResetObject.timeframe[1]));
    var isDirtyTrends = !!((_b = watch('assetOutageGroupKey')) === null || _b === void 0 ? void 0 : _b.length) ||
        !!((_c = watch('derateCapacityInd')) === null || _c === void 0 ? void 0 : _c.length) ||
        !!((_d = watch('eventCauseTypeKey')) === null || _d === void 0 ? void 0 : _d.length);
    var isDirtyForm = hasDirtyField || isDirtyTimeframe;
    useEffect(function () {
        handleReset === null || handleReset === void 0 ? void 0 : handleReset(!hasDirtyField);
        if (hasDirtyField) {
            setDisableApply(false);
        }
    }, [hasDirtyField]);
    // TODO: create separate custom hook for this useEffect
    useEffect(function () {
        setValue('regions', initialRegions);
        setValue('location', initialLocation);
        setValue('assets', initialAssets);
        setValue('owners', initialOwners);
        setValue('unitTypes', initialUnitTypes);
        setValue('unitSubTypes', initialUnitSubTypes);
        setValue('units', initialUnits);
    }, [
        initialLocation,
        initialOwners,
        initialAssets,
        initialUnitSubTypes,
        initialUnits,
        initialUnitTypes,
        initialRegions,
        setValue,
    ]);
    return (_jsxs("div", { children: [config.map(function (el) {
                var placeholder = 'Select';
                if (el.label === 'Unit') {
                    placeholder = watch('assets').length ? el.placeholder : 'Select an asset first to see units';
                }
                var optionFilterProp = el.label === 'Assets' ? 'optionFilterProp' : 'children';
                return (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: el.label })), _jsx(FilterFieldWrapper, { children: _jsx(Select, { size: "small", mode: "multiple", placeholder: placeholder, id: el.id, data: el.data, value: el.value, onChange: function (e) {
                                    setDisableApply(false);
                                    el.onChange(e);
                                }, disabled: el.disabled, optionFilterProp: optionFilterProp }) })] }, el.id));
            }), !isEventSummary && (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: "Month" })), _jsx(FilterFieldWrapper, { children: _jsx(MonthPicker, { value: dayjs(watchTimeframe[0]), onMonthChange: function (e) {
                                setDisableApply(false);
                                handleRangePickerChange(e);
                            } }) })] })), isEventSummary && (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: "Date range" })), _jsx(FilterFieldWrapper, { children: _jsx(RangeDatePicker, { size: "small", value: [dayjs(watchTimeframe[0]), dayjs(watchTimeframe[1])], format: "MMM YYYY", picker: "month", onChange: function (e) {
                                setDisableApply(false);
                                handleTimeFrameChange(e);
                            }, dropdownAlign: {
                                points: ['tc', 'bc'],
                            }, allowClear: false }) })] })), isTrends && _jsx(TrendsFilters, { enableApply: function () { return setDisableApply(false); }, form: form }), _jsxs(FilterRow, { children: [_jsx(Button, __assign({ size: "small", variant: "outlined", disabled: !isDirtyForm && !isDirtyTrends, onClick: function () {
                            setDisableApply(false);
                            return reset(filterResetObject);
                        } }, { children: "Reset" })), _jsx(Button, __assign({ size: "small", disabled: disableApply, onClick: function (e) {
                            setDisableApply(true);
                            handleSubmit(onSubmit)(e);
                        } }, { children: "Apply" }))] })] }));
};
