import React, { ReactElement, useRef } from 'react';

import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { RangePicker } from 'rc-picker';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import enGB from 'rc-picker/lib/locale/en_GB';

import { datePickerConsts } from '../../DatePicker.consts';
import { DATE_PICKER_CLASS } from '../../../../utils/constants';
import Button from '../../../Button/Button';
import * as Styled from '../../DatePicker.styles';
import { ClearIcon, NextIcon, PrevIcon, Separator, SuffixIcon, SuperNextIcon, SuperPrevIcon } from '../Icons/Icons';
import { IRangePickerProps } from './RangeDatePicker.types';
import { Sizes, Variants } from '../../../../types/types';

function RangeDatePicker<T>({
  className,
  size = Sizes.Medium,
  invalid = false,
  elevation,
  float,
  dropdownAlign,
  ...rest
}: IRangePickerProps<T>): ReactElement {
  const container = useRef<HTMLDivElement>(null);

  const innerElevation = elevation || float || true;

  const pickerProps = {
    dropdownAlign: {
      ...dropdownAlign,
      offset: [0, innerElevation ? 20 : 0],
    },
    prefixCls: DATE_PICKER_CLASS,
    getPopupContainer: () => container.current as HTMLElement,
    separator: <Separator />,
    showToday: false,
    nextIcon: <NextIcon />,
    superNextIcon: <SuperNextIcon />,
    prevIcon: <PrevIcon />,
    superPrevIcon: <SuperPrevIcon />,
    suffixIcon: <SuffixIcon />,
    allowClear: true,
    clearIcon: (
      <Button
        size={datePickerConsts[size].clearSize}
        variant={Variants.Transparent}
        icon={<ClearIcon />}
        iconOnly
        aria-label="Clear entry"
      />
    ),
    locale: enGB,
    format: 'DD MMM YYYY',
  };

  const datePickerWrapperProps = {
    className: classNames(DATE_PICKER_CLASS, className),
    prefixCls: DATE_PICKER_CLASS,
    ref: container,
    size,
    $invalid: invalid,
    $elevation: innerElevation,
  };

  return (
    <Styled.DatePickerWrapper {...datePickerWrapperProps}>
      {rest.config === 'custom' ? (
        <RangePicker<T> {...pickerProps} {...rest} />
      ) : (
        <RangePicker<Dayjs> {...pickerProps} generateConfig={dayJsGenerateConfig} {...rest} />
      )}
    </Styled.DatePickerWrapper>
  );
}

export default RangeDatePicker;
