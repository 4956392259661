import dayjs from 'dayjs';
export var getDefaultValues = function (event) {
    var _a;
    return event.length === 1
        ? {
            startDate: event[0].startDate,
            incrementalCapacity: (_a = event[0].capacityOrigQty) === null || _a === void 0 ? void 0 : _a.toFixed(2),
            basisType: event[0].capacityBasisTypeKey,
            probabilityType: event[0].planningProbTypeKey,
            sourceOfIntelligence: event[0].dataProviderKey,
            statusType: event[0].statusKey,
            intelligenceSourceTxt: event[0].intelligenceSourceTxt || '',
        }
        : {
            startDate: dayjs().toISOString(),
            incrementalCapacity: '100.00',
            intelligenceSourceTxt: '',
            offsetStartDateBy: '-1',
        };
};
