import { __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import GeopoliticalEntityNavLink from './geoPoliticalEntity/geoPoliticalEntityNavLink';
var locationAndHierarchiesReferenceNavLink = /** @class */ (function (_super) {
    __extends(locationAndHierarchiesReferenceNavLink, _super);
    function locationAndHierarchiesReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    locationAndHierarchiesReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/locationandhierarchyreference", to: "/dashboard/locationAndHierarchyReference/geopoliticalEntity", exact: true }), _jsx(Route, { path: "/dashboard/locationAndHierarchyReference/geopoliticalEntityCrossReference", component: GeopoliticalEntityNavLink, exact: true }), _jsx(Route, { path: "/dashboard/locationAndHierarchyReference/geopoliticalEntity", component: GeopoliticalEntityNavLink, exact: true })] }) }));
    };
    return locationAndHierarchiesReferenceNavLink;
}(Component));
export default locationAndHierarchiesReferenceNavLink;
