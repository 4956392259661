import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component, useState } from 'react';
import './chart.css';
var NavPanel = function (props) {
    var _a = useState(false), bool = _a[0], setBool = _a[1];
    var _b = useState('slc'), active = _b[0], setActive = _b[1];
    var optionschart = [
        { id: 1, name: 'Simple Line Chart' },
        { id: 2, name: 'Dashed Line Chart' },
        { id: 3, name: 'Label Line Chart' },
        { id: 4, name: 'Stacked Area Chart' },
        { id: 5, name: 'Simple Bar Chart' },
        { id: 6, name: 'Stacked Bar Chart' },
        { id: 7, name: 'Mix Bar Chart' },
        { id: 8, name: 'Bar Chart Background' },
        // { id: 9, name: "Bar Chart Multi XAxis" },
    ];
    return (_jsxs("div", __assign({ className: "chart-navcontainer" }, { children: [bool && (_jsx("div", __assign({ className: "pull-left aside-panel" }, { children: _jsx("ul", { children: optionschart.map(function (data) {
                        return (_jsx("li", __assign({ className: active === data.id ? 'text-left px-2 my-2 item-active' : 'text-left px-2 my-2', onClick: function (e) {
                                setActive(data.id);
                                props.switchChart(data.id);
                            } }, { children: data.name }), data.id));
                    }) }) }))), _jsx("div", __assign({ className: "togleBtn pull-left", onClick: function () { return setBool(!bool); } }, { children: bool ? _jsx("i", { className: "fa fa-chevron-left" }) : _jsx("i", { className: "fa fa-chevron-right" }) }))] })));
};
export default NavPanel;
