import { __spreadArray } from "tslib";
import { useMemo } from 'react';
import { useGetPlanningProbabilityTypesQuery, useGetCapacityBasisTypesQuery, useGetPlanningStatusTypesQuery, useGetAssetUnitsQuery, useGetAcmHierarchyElementQuery, } from 'common/ducks/filtersApis';
import { useResolveSelectData } from 'common/hooks/resolve-select-data';
import { getAssetsAndOptions } from 'common/utils/get-filters-from-hierarchy';
import { sortSelectData } from 'common/utils/newScreenUtils';
export var useCapacityRequests = function (form, skip) {
    var watch = form.watch;
    var _a = useGetAcmHierarchyElementQuery(undefined, {
        skip: skip,
    }), _b = _a.data, regions = _b === void 0 ? [] : _b, isFetchingAcmHierarchy = _a.isFetching;
    var assets = useMemo(function () {
        return getAssetsAndOptions({
            regions: regions,
            countries: [],
            companies: [],
        });
    }, [regions]).assetsOptions;
    var _c = useGetPlanningProbabilityTypesQuery({}, { skip: skip }), pTypesData = _c.data, isFetchingProbabilityTypes = _c.isFetching;
    var _d = useGetCapacityBasisTypesQuery({}, { skip: skip }), basistypesData = _d.data, isFetchingBasisTypes = _d.isFetching;
    var _e = useGetPlanningStatusTypesQuery({}, { skip: skip }), statusTypesData = _e.data, isFetchingStatusTypes = _e.isFetching;
    var _f = useGetAssetUnitsQuery({ assetIds: [watch('asset')], placeholder: true }, { skip: !watch('asset') || skip }), unitsData = _f.data, isFetchingUnits = _f.isFetching;
    var probabilityTypes = useResolveSelectData(pTypesData, 'id', 'planningProbabilityTypeCd').sort(sortSelectData);
    var basistypes = useResolveSelectData(basistypesData, 'id', 'capacityBasisTypeDesc').sort(sortSelectData);
    var statusTypes = useResolveSelectData(statusTypesData, 'id', 'planningStatusTypeDesc').sort(sortSelectData);
    var units = useResolveSelectData(unitsData, 'id', 'assetUnitNm').sort(sortSelectData);
    if (!!(units === null || units === void 0 ? void 0 : units.length)) {
        units = __spreadArray([{ key: 0, children: 'Select All', value: 0 }], units, true);
    }
    return {
        assets: assets,
        units: units,
        unitsData: unitsData,
        isFetching: {
            isFetchingAcmHierarchy: isFetchingAcmHierarchy,
            isFetchingProbabilityTypes: isFetchingProbabilityTypes,
            isFetchingBasisTypes: isFetchingBasisTypes,
            isFetchingStatusTypes: isFetchingStatusTypes,
            isFetchingUnits: isFetchingUnits,
        },
        probabilityTypes: probabilityTypes,
        basistypes: basistypes,
        statusTypes: statusTypes,
    };
};
export var capacityRequestsHook = {
    useCapacityRequests: useCapacityRequests,
};
