import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CrRefGridTemplate from '../../../../../commons/crRefGridTemplate';
function geoPoliticalEntityCrossReference(props) {
    var validationParams = {
        geopoliticalEntityCd: 'Please Select a Geopolitical Entity',
        dataProviderAbbr: 'Please select a Data Provider',
    };
    var columnParams = [
        {
            field: 'providerCityNm',
            title: 'Provider City Name',
        },
        {
            field: 'providerCountrySubSubdivisionNm',
            title: 'Provider Country Sub Subdivision Name',
        },
        {
            field: 'providerCountrySubdivisionNm',
            title: 'Provider Country Subdivision Name',
        },
        {
            field: 'providerCountryNm',
            title: 'Provider Country Name',
        },
        {
            field: 'providerGeopoliticalEntityId',
            title: 'Provider Geopolitical Entity Id',
        },
        {
            field: 'dataProviderAbbr',
            title: 'Data Provider',
            key: 'dataProviderKey',
        },
        {
            field: 'geopoliticalEntityCd',
            title: 'Geopolitical Entity',
            key: 'geopoliticalEntityKey',
        },
        {
            field: 'versionTerminationDt',
            title: 'Version Termination Dt',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'versionEffectiveDt',
            title: 'Version Effective Dt',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'recordEntryDttm',
            title: 'Record Entry Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaQualityCd',
            title: 'Meta Quality Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaActionCd',
            title: 'Meta Action Cd',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatedDttm',
            title: 'Meta Created Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaCreatorNm',
            title: 'Meta Creator Nm',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedDttm',
            title: 'Meta Changed Dttm',
            filter: 'date',
            format: '{0:dd MMM yyyy hh:mm a}',
            type: 'meta',
            editable: false,
        },
        {
            field: 'metaChangedByNm',
            title: 'Meta Changed By Nm',
            type: 'meta',
            editable: false,
        },
    ];
    return _jsx(CrRefGridTemplate, { column: columnParams, validation: validationParams, api: 'geoPoliticalEntityCrossRef' });
}
export default geoPoliticalEntityCrossReference;
