import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
var Header = function (_a) {
    var title = _a.title, onAddEditClick = _a.onAddEditClick, onAddEditClickEnable = _a.onAddEditClickEnable;
    return (_jsx("div", __assign({ className: "row ml-0 mr-0 mt-3 mb-3" }, { children: _jsx("div", __assign({ className: "container-fluid" }, { children: _jsx("div", __assign({ className: "pull-left page-title" }, { children: _jsx("h3", { children: title }) })) })) })));
};
Header.propTypes = {
    onAddEditClick: PropTypes.func.isRequired,
};
export default Header;
