import http from './httpService';
import { baseUrl, urlArray } from './urls';
import { getAccessToken } from 'common/utils/access-token/get-access-token';
var getAxiosConfig = function () {
    var axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
    };
    var accessToken = getAccessToken();
    if (accessToken) {
        // @ts-ignore: authorization header added only with access token available
        axiosConfig.headers['Authorization'] = "Bearer ".concat(accessToken);
    }
    return axiosConfig;
};
var resolveResponse = function (response) {
    if (!response)
        return undefined;
    if ((Array.isArray(response.data) && response.data.length > 0) || Object.keys(response.data).length > 0)
        return response;
    return undefined;
};
export function getSourceData(screenType) {
    return http
        .get(urlArray[screenType], getAxiosConfig())
        .then(function (result) {
        if (result.data.length > 0) {
            return result;
        }
        else {
            return undefined;
        }
    })
        .catch(function () {
        return undefined;
    });
}
export function getSourceFilter(path, differentPath) {
    if (differentPath === void 0) { differentPath = false; }
    if (differentPath) {
        return http
            .get(path, getAxiosConfig())
            .then(resolveResponse)
            .catch(function () {
            return undefined;
        });
    }
    else {
        return http
            .get(baseUrl + '/' + path, getAxiosConfig())
            .then(resolveResponse)
            .catch(function () {
            return undefined;
        });
    }
}
export function getSourceEventListing(path) {
    return http
        .get(baseUrl + '/' + path, getAxiosConfig())
        .then(resolveResponse)
        .catch(function () {
        return;
    });
}
export function slackApi(url, data) {
    return http
        .post(url, JSON.stringify(data))
        .then(resolveResponse)
        .catch(function () {
        return;
    });
}
export function getSourceDataByID(screenType, id) {
    return http
        .get(urlArray[screenType] + '/' + id, getAxiosConfig())
        .then(function (result) {
        if (result.data.length > 0) {
            return result;
        }
        else {
            return { data: 'empty' };
        }
    })
        .catch(function () {
        return;
    });
}
export function linkMaintenanceEvents(path) {
    return http
        .put(baseUrl + path, null, getAxiosConfig())
        .then(function (result) { return result; })
        .catch(function (error) { return error; });
}
export function editSourceData(data, id, screenType) {
    if (id === void 0) { id = null; }
    if (id === null) {
        var url = urlArray[screenType];
        return http
            .put(url, data, getAxiosConfig())
            .then(function (result) {
            return result;
        })
            .catch(function (error) {
            return error;
        });
    }
    else {
        var url = urlArray[screenType] + '/' + id;
        return http
            .put(url, data, getAxiosConfig())
            .then(function (result) {
            return result;
        })
            .catch(function (error) {
            return error;
        });
    }
}
export function promoteToBaseline(capacityKey, capacityVersionKey, screenType) {
    var url = urlArray[screenType] + '/' + capacityKey + '/' + capacityVersionKey;
    return http
        .put(url, 0, getAxiosConfig())
        .then(function (result) {
        return result;
    })
        .catch(function () {
        return;
    });
}
export function deleteSourceData(id, screenType) {
    var url = urlArray[screenType] + '/' + id;
    return http
        .delete(url, getAxiosConfig())
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        console.error('Ouch ' + error);
    });
}
export function saveSourceData(data, screenType) {
    var url = urlArray[screenType];
    return http
        .post(url, data, getAxiosConfig())
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        return error;
    });
}
export function fileUpload(url, formData) {
    var pathUrl = baseUrl + '/' + url;
    var options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: pathUrl,
    };
    var accessToken = getAccessToken();
    if (accessToken) {
        options.headers['Authorization'] = "Bearer ".concat(accessToken);
    }
    return http
        .request(options)
        .then(function (result) {
        return result;
    })
        .catch(function () {
        return;
    });
}
