import styled, { css } from 'styled-components';

import { tableConsts } from './Table.consts';
import { ITableProps } from './Table.types';
import { Sizes } from '../../types/types';

const Table = styled.table<ITableProps>(
  ({ theme, size = Sizes.Medium, alignment, $empty }) => css`
    border-collapse: collapse;
    background-color: ${theme.background.raised};

    th {
      text-align: ${alignment};
    }

    th,
    td {
      ${theme.paragraph.small.normal};

      height: ${tableConsts[size].height};

      ${size === Sizes.Large &&
      css`
        ${theme.paragraph.medium.normal};
      `}

      ${size === Sizes.ExtraSmall &&
      css`
        padding: 0 14px;
      `};

      ${size === Sizes.Small &&
      css`
        padding: 3px 14px;
      `};

      ${(size === Sizes.Medium || size === Sizes.Large) &&
      css`
        padding: 4px 14px;
      `};
    }

    th {
      ${(size === Sizes.ExtraSmall || size === Sizes.Small || size === Sizes.Medium) && theme.label.medium.bold};
      ${size === Sizes.Large && theme.label.large.bold};
    }

    tbody td {
      ${$empty &&
      css`
        padding: unset;
      `};
    }

    tfoot th {
      text-align: left;
    }
  `,
);

export { Table };
