import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  ${({ theme }) => css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, Roboto, 'Helvetica Neue', sans-serif;
      ${theme.paragraph.medium.normal};
      color: ${theme.text.onSurface.strong};
      -webkit-font-smoothing: antialiased;
    }
  `}
`;
