import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { filterSearch } from './tools/filterSearch';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { useApiContext } from './apiContext';
var AssetCompanies = function () {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(apiParams.dataProviders), dataProvidersDropdownData = _b[0], setDataProvidersDropdownData = _b[1];
    var _c = useState(apiParams.companiesFilterData), companies = _c[0], setCompanies = _c[1];
    var _d = useState(apiParams.companyRoleTypeFilterData), companiesRoleType = _d[0], setCompaniesRoleType = _d[1];
    var _e = useState(apiParams.intelligenceSourceTypes), intelligenceSourceType = _e[0], setIntelligenceSourceType = _e[1];
    var _f = useState(null), indexForEdit = _f[0], setIndexForEdit = _f[1];
    var _g = useState(''), addEditPopupTitle = _g[0], setAddEditPopupTitle = _g[1];
    var _h = useState([]), sort = _h[0], setSort = _h[1];
    var _j = useState(false), visibleAddEdit = _j[0], setVisibleAddEdit = _j[1];
    var _k = useState(false), deletePopup = _k[0], setDeletePopup = _k[1];
    useEffect(function () {
        setDataProvidersDropdownData(apiParams.dataProviders);
        setCompanies(apiParams.companiesFilterData);
        setCompaniesRoleType(apiParams.companyRoleTypeFilterData);
        setIntelligenceSourceType(apiParams.intelligenceSourceTypes);
    }, [
        apiParams.dataProviders,
        apiParams.companiesFilterData,
        apiParams.companyRoleTypeFilterData,
        apiParams.intelligenceSourceTypes,
        visibleAddEdit,
    ]);
    var addEditAssetCompany = function (dataItem) {
        var index;
        if (!dataItem) {
            setAddEditPopupTitle('Add Asset Company');
            index = apiParams.addEditAssetConfigData.assetCompanies.length;
            var newParams = __assign({}, apiParams);
            newParams.addEditAssetConfigData.assetCompanies[index] = {};
            newParams.fieldsMissing = false;
            setIndexForEdit(index);
            setApiParams(newParams);
        }
        else {
            setAddEditPopupTitle('Edit Asset Company');
            index = apiParams.addEditAssetConfigData.assetCompanies.findIndex(function (item) { return item.assetCompanyId === dataItem.assetCompanyId; });
            setIndexForEdit(index);
        }
        setVisibleAddEdit(true);
    };
    var deleteAssetCompany = function (dataItem) {
        var index = apiParams.addEditAssetConfigData.assetCompanies.findIndex(function (item) { return item.assetCompanyId === dataItem.assetCompanyId; });
        setIndexForEdit(index);
        setDeletePopup(true);
    };
    var EditButtonsCell = function (dataItemProps) {
        return (_jsxs("td", { children: [_jsx("button", __assign({ onClick: function () { return addEditAssetCompany(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-pencil" }) })), _jsx("button", __assign({ className: "btn-delete", onClick: function () { return deleteAssetCompany(dataItemProps.dataItem); } }, { children: _jsx("i", { className: "fa fa-trash " }) }))] }));
    };
    /////////////Functions for handling edits/////////////////////////
    var isEdited = function () { return indexForEdit < apiParams.originalAssetCompaniesLength; };
    var onDelete = function () {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies.splice(indexForEdit, 1);
        newParams.originalAssetConfigData.assetCompanies.splice(indexForEdit, 1);
        newParams.originalAssetCompaniesLength = newParams.originalAssetCompaniesLength - 1; //important for setting isEdited field
        setApiParams(newParams);
        setDeletePopup(false);
    };
    var onCancel = function () {
        var newParams = __assign({}, apiParams);
        if (!apiParams.originalAssetConfigData.assetCompanies[indexForEdit]) {
            newParams.addEditAssetConfigData.assetCompanies.splice(indexForEdit, 1);
        }
        else {
            newParams.addEditAssetConfigData.assetCompanies[indexForEdit] = JSON.parse(JSON.stringify(apiParams.originalAssetConfigData.assetCompanies[indexForEdit]));
        }
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    var onCompanyChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyNm = e.target.value.companyNm;
        setApiParams(newParams);
    };
    var onRoleTypeChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyRoleTypeKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyRoleTypeNm = e.target.value.companyRoleTypeCd;
        setApiParams(newParams);
    };
    var onEquityChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].equitySharePct = e.target.value;
        setApiParams(newParams);
    };
    var onProviderChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].dataProviderKey = e.target.value.id;
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].dataProviderNm = e.target.value.dataProviderAbbr;
        setApiParams(newParams);
    };
    var onStartDtChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].startDt =
            e.target.value == null ? null : Moment(e.target.value).format('YYYY-MM-DDT00:00:00');
        setApiParams(newParams);
    };
    var onEndDtChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].endDt =
            e.target.value == null ? null : Moment(e.target.value).format('YYYY-MM-DDT00:00:00');
        setApiParams(newParams);
    };
    var onIntelligenceSrcChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceTypeCd =
            e.target.value.intelligenceSourceTypeCd;
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceTypeKey = e.target.value.id;
        setApiParams(newParams);
    };
    var onUrlChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceUrl = e.target.value;
        setApiParams(newParams);
    };
    var onIntelligenceSrcTxtChange = function (e) {
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceText = e.target.value;
        setApiParams(newParams);
    };
    var onUpdate = function () {
        if (!apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyNm) {
            toast.error('Fields marked in red are required', { autoClose: 5000 });
            setApiParams(__assign(__assign({}, apiParams), { fieldsMissing: true }));
            return;
        }
        var newParams = __assign({}, apiParams);
        newParams.addEditAssetConfigData.assetCompanies[indexForEdit].isEdited = isEdited();
        newParams.originalAssetConfigData.assetCompanies[indexForEdit] = JSON.parse(JSON.stringify(newParams.addEditAssetConfigData.assetCompanies[indexForEdit]));
        newParams.fieldsMissing = false;
        setApiParams(newParams);
        setVisibleAddEdit(false);
    };
    var itemRender = function (li, itemProps) {
        var index = itemProps.index;
        var itemChildren = _jsx("span", __assign({ style: { textAlign: 'left' } }, { children: li.props.children }));
        return React.cloneElement(li, li.props, itemChildren);
    };
    return (_jsxs("div", { children: [visibleAddEdit && (_jsx("div", __assign({ id: "unit-cross-reference" }, { children: _jsxs(Dialog, __assign({ width: '50%', title: addEditPopupTitle, onClose: onCancel }, { children: [_jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col-4 m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsxs("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: [_jsx("i", { className: "fa fa-asterisk red-text", "aria-hidden": "true" }), "Company"] })), _jsx(DropDownList, { data: companies, textField: "companyCd", name: "companyCd", itemRender: itemRender, filterable: true, onFilterChange: function (e) {
                                                    setCompanies(filterSearch(apiParams.companiesFilterData, e.filter));
                                                }, className: !apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyNm &&
                                                    apiParams.fieldsMissing
                                                    ? 'k-state-invalid'
                                                    : null, value: {
                                                    companyCd: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyNm || 'Select'
                                                        : 'Select',
                                                }, onChange: onCompanyChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 p-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Company Role Type" })), _jsx(DropDownList, { data: companiesRoleType, textField: "companyRoleTypeDesc", itemRender: itemRender, filterable: true, onFilterChange: function (e) {
                                                    setCompaniesRoleType(filterSearch(apiParams.companyRoleTypeFilterData, e.filter));
                                                }, value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyRoleTypeKey
                                                    ? companiesRoleType.filter(function (x) {
                                                        return x.id === apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].companyRoleTypeKey;
                                                    })[0]
                                                    : { companyRoleTypeDesc: 'Select' }, onChange: onRoleTypeChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Equity Share" })), _jsxs("div", __assign({ className: "input-group mr-sm-2" }, { children: [_jsx(Input, { name: "equityShare", style: { width: '88%' }, value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit]
                                                            ? apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].equitySharePct || ''
                                                            : '', onChange: onEquityChange, placeholder: "Enter a value", autoComplete: "off" }), _jsx("div", __assign({ className: "input-group-prepend" }, { children: _jsx("div", __assign({ className: "input-group-text", style: { border: 'none', background: 'none' } }, { children: "%" })) }))] }))] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col-4 m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Data Provider" })), _jsx(DropDownList, { data: dataProvidersDropdownData, textField: "dataProviderAbbr", name: "dataProvider", filterable: true, onFilterChange: function (e) {
                                                    setDataProvidersDropdownData(filterSearch(apiParams.dataProviders, e.filter));
                                                }, value: {
                                                    dataProviderAbbr: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit]
                                                        ? apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].dataProviderNm || 'Select'
                                                        : 'Select',
                                                }, onChange: onProviderChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 p-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Start Date" })), _jsx(DatePicker, { name: "startDate", value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit] &&
                                                    apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].startDt
                                                    ? new Date(apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].startDt)
                                                    : null, format: 'dd MMM yyyy', onChange: onStartDtChange })] })) })), _jsx("div", __assign({ className: "col-4 m-0 pr-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "End Date" })), _jsx(DatePicker, { name: "endDate", value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit] &&
                                                    apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].endDt
                                                    ? new Date(apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].endDt)
                                                    : null, format: 'dd MMM yyyy', onChange: onEndDtChange })] })) }))] })), _jsxs("div", __assign({ className: "row m-0 px-2 py-4" }, { children: [_jsx("div", __assign({ className: "col-4 m-0 pl-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source Type" })), _jsx(DropDownList, { data: intelligenceSourceType, textField: "intelligenceSourceTypeCd", name: "intelligenceSourceTypeCd", filterable: true, onFilterChange: function (e) {
                                                    setIntelligenceSourceType(filterSearch(apiParams.intelligenceSourceTypes, e.filter));
                                                }, value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceTypeKey
                                                    ? intelligenceSourceType.filter(function (x) {
                                                        return x.id ===
                                                            apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceTypeKey;
                                                    })[0]
                                                    : { intelligenceSourceTypeCd: 'Select' }, onChange: onIntelligenceSrcChange })] })) })), _jsx("div", __assign({ className: "col-8 m-0 p-0" }, { children: _jsxs("div", __assign({ className: "position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source URl" })), _jsx(Input, { name: "IntelligenceSrcURL", style: { width: '100%' }, value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit]
                                                    ? apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceUrl || ''
                                                    : '', onChange: onUrlChange, placeholder: "Enter URL", autoComplete: "off" })] })) }))] })), _jsx("div", __assign({ className: "row m-0 px-2 py-4" }, { children: _jsxs("div", __assign({ className: "col-12 p-0" }, { children: [_jsx("div", __assign({ className: "title position-absolute background-filter-fill" }, { children: "Intelligence Source Text" })), _jsx("textarea", { name: "intelligentSourceText", className: "textareaInput-ist p-2", placeholder: "Enter text", value: apiParams.addEditAssetConfigData.assetCompanies[indexForEdit]
                                            ? apiParams.addEditAssetConfigData.assetCompanies[indexForEdit].intelligenceSourceText || ''
                                            : '', onChange: onIntelligenceSrcTxtChange })] })) })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("button", __assign({ className: "btn btn-default mx-3 border btn-cancel", onClick: onCancel }, { children: "Cancel" })), _jsx("button", __assign({ className: "btn btn-primary btn-update", onClick: onUpdate }, { children: "Update" }))] }))] })) }))), _jsxs("button", __assign({ className: "p-3 new-asset-company", onClick: function () { return addEditAssetCompany(); } }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '25px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Asset Company" }))] })), apiParams.addEditAssetConfigData && apiParams.addEditAssetConfigData.assetCompanies.length > 0 ? (_jsx("div", __assign({ id: "dailyeventListing", className: "p-2" }, { children: _jsxs(Grid, __assign({ data: orderBy(apiParams.addEditAssetConfigData.assetCompanies, sort), sortable: true, sort: sort, onSortChange: function (e) {
                        setSort(e.sort);
                    }, style: { height: '575px' } }, { children: [_jsx(Column, { width: "90px", cell: EditButtonsCell, locked: true }), _jsx(Column, { field: "companyNm", title: "Company", width: 150 }), _jsx(Column, { field: "companyRoleTypeNm", title: "Company Role Type", width: 200 }), _jsx(Column, { field: "equitySharePct", title: "Equity Share %", width: 150 }), _jsx(Column, { field: "dataProviderNm", title: "Data Provider", width: 150 }), _jsx(Column, { field: "startDt", title: "Start Date", filter: "date", format: "{0:dd MMM yyyy}", width: 150 }), _jsx(Column, { field: "endDt", title: "End Date", filter: "date", format: "{0:dd MMM yyyy}", width: 150 }), _jsx(Column, { field: "intelligenceSourceTypeCd", title: "Intelligence Source Type", width: 200 }), _jsx(Column, { field: "intelligenceSourceUrl", title: "Intelligence Source URL", width: 200 }), _jsx(Column, { field: "intelligenceSourceText", title: "Intelligence Source Text", width: 200 })] })) }))) : (_jsx("div", __assign({ style: {
                    border: '1px solid #595959',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    color: '#595959',
                    fontFamily: 'ShellLight',
                    left: '36%',
                    top: '46%',
                } }, { children: _jsx("h3", __assign({ style: { color: '#595959' } }, { children: "No records available to display." })) }))), deletePopup && (_jsx("div", { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setDeletePopup(false); } }, { children: [_jsx("div", __assign({ className: "p-4" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setDeletePopup(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onDelete }, { children: "Yes" }))] })] })) }))] }));
};
export default AssetCompanies;
