import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Flexbox, Label, Tooltip } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { StyledOverlay, StyleFlexbox } from './InfoPanel.styles';
import { useGetLastMaintenanceUpdatesQuery } from 'common/ducks/maintenanceApis';
import { useGetLastCapacityUpdatesQuery } from 'common/ducks/capacityApis';
import { NB_HOURS_WINDOW_TO_CHECK, DATE_FORMAT_DD_MMM_YYYY_HH_MM_UTC } from 'common/constants';
import { useGetModuleLastUpdateQuery } from 'common/ducks/filtersApis';
export var InfoPanel = function (_a) {
    var type = _a.type;
    var capacityUpdates = useGetLastCapacityUpdatesQuery(undefined, { skip: type !== 'capacity' }).data;
    var maintenanceUpdates = useGetLastMaintenanceUpdatesQuery(undefined, { skip: type !== 'maintenance' }).data;
    var assetUpdates = useGetModuleLastUpdateQuery('asset', { skip: type !== 'asset' }).data;
    var data = null;
    switch (type) {
        case 'capacity':
            data = capacityUpdates;
            break;
        case 'maintenance':
            data = maintenanceUpdates;
            break;
        default:
            data = assetUpdates
                ? assetUpdates.map(function (update) { return ({ lastCreatedDate: update.LastCreatedDate, dataProvider: update.DataProvider }); })
                : undefined;
            break;
    }
    var sortedData = data ? __spreadArray([], data, true) : [];
    sortedData.sort(function (a, b) {
        return +dayjs(b.lastCreatedDate) - +dayjs(a.lastCreatedDate);
    });
    return (_jsx(Tooltip, { overlay: _jsxs(StyledOverlay, __assign({ gap: "16px", flexDirection: "column" }, { children: [_jsx(Label, __assign({ size: "small", bold: true }, { children: "Updates" })), sortedData.map(function (value) { return (_jsxs(Flexbox, __assign({ gap: "8px" }, { children: [_jsx(Badge, { style: { alignSelf: 'center' }, sentiment: dayjs.utc().diff(dayjs.utc(value.lastCreatedDate), 'hour') < NB_HOURS_WINDOW_TO_CHECK
                                ? 'positive'
                                : 'neutral', variant: "filled", size: "micro" }), _jsxs(Label, __assign({ size: "small" }, { children: [value.dataProvider, " - ", dayjs.utc(value.lastCreatedDate).format(DATE_FORMAT_DD_MMM_YYYY_HH_MM_UTC)] }))] }), value.dataProvider)); })] })), trigger: !!sortedData.length ? (_jsxs(StyleFlexbox, __assign({ gap: "8px" }, { children: [_jsx(Badge, { style: { alignSelf: 'center', marginTop: '1px' }, sentiment: dayjs.utc().diff(dayjs.utc(sortedData[0].lastCreatedDate), 'hour') < NB_HOURS_WINDOW_TO_CHECK
                        ? 'positive'
                        : 'neutral', variant: "filled", size: "micro" }), _jsxs(Label, __assign({ size: "small", bold: true }, { children: ["Update: ", dayjs.utc(sortedData[0].lastCreatedDate).format(DATE_FORMAT_DD_MMM_YYYY_HH_MM_UTC)] }))] }))) : (_jsx("div", {})) }));
};
