import { createContext } from 'react';

import { IMenuContext } from './Menu.types';

const MenuContext = createContext<IMenuContext>({
  mode: 'inline',
  inlineCollapsed: false,
});

export default MenuContext;
