export var baseUrl = process.env.REACT_APP_BASE_URL;
var endpointIntelligentSourceType = baseUrl + '/IntelligenceSourceTypes';
var endpointBaselineDataProviders = baseUrl + '/BaselineDataProviders';
var endpointBaselineTypes = baseUrl + '/BaselineTypes';
var endpointAssetClasses = baseUrl + '/AssetClasses';
var endpointCompanyRoleType = baseUrl + '/CompanyRoleTypes';
var endpointIndustryType = baseUrl + '/IndustryTypes';
var endpointOwnershipClass = baseUrl + '/OwnershipClasses';
var endpointGeopoliticalEntityType = baseUrl + '/GeopoliticalEntityTypes';
var endpointDataProviderType = baseUrl + '/DataProviderTypes';
var endpointAssetEventType = baseUrl + '/AssetEventTypes';
var endpointCapacityBasisType = baseUrl + '/CapacityBasisTypes';
var endpointConsumptionRangeType = baseUrl + '/ConsumptionRangeTypes';
var endpointDatasetType = baseUrl + '/DatasetTypes';
var endpointDatasetIngestionMethodType = baseUrl + '/DatasetIngestionMethodTypes';
var endpointDatasetIngestionTechnologyType = baseUrl + '/DatasetIngestionTechnologyTypes';
var endpointDirectChargebackType = baseUrl + '/DirectChargebackTypes';
var endpointAssetConfigurationType = baseUrl + '/AssetConfigurationTypes';
var endpointDataServiceTerminalType = baseUrl + '/DataServiceTerminalTypes';
var endpointDataServiceReportType = baseUrl + '/DataServiceReportTypes';
var endpointDataServiceSubscriber = baseUrl + '/DataServiceSubscribers';
var endpointAssetRelationshipType = baseUrl + '/AssetRelationshipTypes';
var endpointPeriodType = baseUrl + '/PeriodTypes';
var endpointSplitCostMethodType = baseUrl + '/SplitCostMethodTypes';
var endpointTimeZone = baseUrl + '/TimeZones';
var endpointVersionType = baseUrl + '/VersionTypes';
var endpointSubscriptionStatusType = baseUrl + '/SubscriptionStatusTypes';
var endpointLicenseType = baseUrl + '/LicenseTypes';
var endpointGeopoliticalEntity = baseUrl + '/GeopoliticalEntities';
var endpointAssetUnitType = baseUrl + '/AssetUnitTypes';
var endpointAssetUnitSubtype = baseUrl + '/AssetUnitSubtypes';
var endpointBaselineDataProvider = baseUrl + '/BaselineDataProviders';
var endpointOperationalStatusType = baseUrl + '/OperationalStatusTypes';
var endpointEmployeeType = baseUrl + '/EmployeeTypes';
var endpointUserRoleType = baseUrl + '/UserRoleTypes';
var endpointAssetCrossRef = baseUrl + '/AssetCrossRefs';
var endpointCompany = baseUrl + '/Companies';
var endpointAllCompanies = endpointCompany + '/AllCompany';
var endpointMeasureUnit = baseUrl + '/MeasureUnits';
var endpointPlanningPrecisionType = baseUrl + '/PlanningPrecisionTypes';
var endpointPlanningProbabilityType = baseUrl + '/PlanningProbabilityTypes';
var endpointPlanningStatusType = baseUrl + '/PlanningStatusTypes';
var endpointMeasureUnitType = baseUrl + '/MeasureUnitTypes';
var endpointIndustryTypeCrossRefKey = baseUrl + '/IndustryTypeCrossRefs';
var endpointPlanningStatusTypeCrossRef = baseUrl + '/PlanningStatusTypeCrossRefs';
var endpointPlanningPrecisionTypeCrossRef = baseUrl + '/PlanningPrecisionTypeCrossRefs';
var endpointMeasureUnitCrossRef = baseUrl + '/MeasureUnitCrossRefs';
var endpointOperationalStatusTypeCrossRef = baseUrl + '/OperationalStatusTypeCrossRefs';
var endpointAssetUnitSubTypeCrossRef = baseUrl + '/AssetUnitSubtypeCrossRefs';
var endpointCompanyRoleTypeCrossRef = baseUrl + '/CompanyRoleTypeCrossRefs';
var endpointAssetUnitCrossRef = baseUrl + '/AssetUnitCrossRefs';
var endpointDataProvider = baseUrl + '/DataProviders';
var endpointAssetCompany = baseUrl + '/AssetCompanies';
var endpointGeoPoliticalEntityCrossRef = baseUrl + '/GeopoliticalEntityCrossRefs';
var endpointAsset = baseUrl + '/Assets';
var endpointDataSet = baseUrl + '/Datasets';
var endpointDataProviderService = baseUrl + '/DataProviderServices';
var endpointOrganizationalUnit = baseUrl + '/OrganizationalUnit';
var endpointPerson = baseUrl + '/Person';
var endpointAssetExternalIds = baseUrl + '/AssetExternalIds';
var endpointAssetUnitExternalIds = baseUrl + '/AssetUnitExternalIds';
var endpointAssetUnit = baseUrl + '/AssetUnits';
var endpointAssetRelationship = baseUrl + '/AssetRelationships';
var endpointCountry = baseUrl + '/Countries';
var endpointAssetConfigurationTypeCrossRef = baseUrl + '/AssetConfigurationTypeCrossRefs';
var endpointCities = baseUrl + '/Cities';
var endpointCompanyCrossRef = baseUrl + '/CompanyCrossRefs';
var endpointAssetUnitCapacity = baseUrl + '/AssetUnitCapacities';
var endpointPlanningProbabilityTypeCrossRef = baseUrl + '/PlanningProbabilityTypeCrossRefs';
var endpointAssetUnitCapacities = baseUrl + '/AssetUnitCapacities';
var endpointAssetUnitEvents = baseUrl + '/AssetUnitEvents';
var endpointEventCauseTypes = baseUrl + '/EventCauseTypes';
var endpointAssetEventTypes = baseUrl + '/AssetEventTypes';
var endpointNotificationClass = baseUrl + '/NotificationClasses';
var endpointNotificationTypes = baseUrl + '/NotificationTypes';
var endpointNotificationActionTypes = baseUrl + '/NotificationActionTypes';
var endpointAccessibilityTypes = baseUrl + '/AccessibilityTypes';
var endpointAssetRampUpProfile = baseUrl + '/AssetRampUpProfile';
var endpointUser = baseUrl + '/User';
var endpointNotificationPriorityTypes = baseUrl + '/NotificationPriorityTypes';
var endpointAssetEventTypeCrossRefs = baseUrl + '/AssetEventTypeCrossRefs';
var endpointAssetUnitList = baseUrl + '/AssetUnits/AssetUnitFilter';
var endPointAssetUnits = baseUrl + '/AssetUnits';
var endPointAucReallocation = baseUrl + '/AssetUnitCapacities/ReAllocation';
var endPointBulkClosure = baseUrl + '/AssetUnitCapacities/BulkClosure';
var endPointRefineryIntake = baseUrl + '/RefineryIntake/RefineryIntakeByCountry';
var endPointRefineryIntakeBulk = baseUrl + '/RefineryIntake/bulkupdate';
export var urlArray = {
    intelligentSourceType: endpointIntelligentSourceType,
    assetClass: endpointAssetClasses,
    companyRoleType: endpointCompanyRoleType,
    industryType: endpointIndustryType,
    baselineProvider: endpointBaselineDataProviders,
    baselineType: endpointBaselineTypes,
    ownershipClass: endpointOwnershipClass,
    geopoliticalEntityType: endpointGeopoliticalEntityType,
    dataProviderType: endpointDataProviderType,
    assetEventType: endpointAssetEventType,
    capacityBasisType: endpointCapacityBasisType,
    consumptionRangeType: endpointConsumptionRangeType,
    datasetType: endpointDatasetType,
    datasetIngestionMethodType: endpointDatasetIngestionMethodType,
    datasetIngestionTechnologyType: endpointDatasetIngestionTechnologyType,
    directChargebackType: endpointDirectChargebackType,
    assetConfigurationType: endpointAssetConfigurationType,
    dataServiceTerminalType: endpointDataServiceTerminalType,
    dataServiceReportType: endpointDataServiceReportType,
    dataServiceSubscriber: endpointDataServiceSubscriber,
    assetRelationshipType: endpointAssetRelationshipType,
    periodType: endpointPeriodType,
    splitCostMethodType: endpointSplitCostMethodType,
    timeZone: endpointTimeZone,
    versionType: endpointVersionType,
    subscriptionStatusType: endpointSubscriptionStatusType,
    licenseType: endpointLicenseType,
    geopoliticalEntity: endpointGeopoliticalEntity,
    assetUnitType: endpointAssetUnitType,
    assetUnitSubtype: endpointAssetUnitSubtype,
    baselineDataProvider: endpointBaselineDataProvider,
    operationalStatusType: endpointOperationalStatusType,
    employeeType: endpointEmployeeType,
    userRoleType: endpointUserRoleType,
    assetCrossRef: endpointAssetCrossRef,
    company: endpointCompany,
    allCompanies: endpointAllCompanies,
    measureUnit: endpointMeasureUnit,
    planningPrecisionType: endpointPlanningPrecisionType,
    planningProbabilityType: endpointPlanningProbabilityType,
    planningStatusType: endpointPlanningStatusType,
    measureUnitType: endpointMeasureUnitType,
    industryTypeCrossRef: endpointIndustryTypeCrossRefKey,
    planningStatusTypeCrossRef: endpointPlanningStatusTypeCrossRef,
    planningPrecisionTypeCrossRef: endpointPlanningPrecisionTypeCrossRef,
    measureUnitCrossRef: endpointMeasureUnitCrossRef,
    operationalStatusTypeCrossRef: endpointOperationalStatusTypeCrossRef,
    assetUnitSubTypeCrossRef: endpointAssetUnitSubTypeCrossRef,
    companyRoleTypeCrossRef: endpointCompanyRoleTypeCrossRef,
    assetUnitCrossRef: endpointAssetUnitCrossRef,
    dataProvider: endpointDataProvider,
    assetCompanies: endpointAssetCompany,
    geoPoliticalEntityCrossRef: endpointGeoPoliticalEntityCrossRef,
    assets: endpointAsset,
    dataSet: endpointDataSet,
    dataProviderService: endpointDataProviderService,
    organizationalUnit: endpointOrganizationalUnit,
    person: endpointPerson,
    assetExternalId: endpointAssetExternalIds,
    assetUnitExternalIds: endpointAssetUnitExternalIds,
    assetUnit: endpointAssetUnit,
    assetRelationship: endpointAssetRelationship,
    country: endpointCountry,
    assetConfigurationTypeCrossRef: endpointAssetConfigurationTypeCrossRef,
    cities: endpointCities,
    companyCrossRef: endpointCompanyCrossRef,
    assetUnitCapacity: endpointAssetUnitCapacity,
    planningProbabilityTypeCrossRef: endpointPlanningProbabilityTypeCrossRef,
    assetUnitCapacities: endpointAssetUnitCapacities,
    assetUnitEvents: endpointAssetUnitEvents,
    eventCauseTypes: endpointEventCauseTypes,
    assetEventTypes: endpointAssetEventTypes,
    notificationClass: endpointNotificationClass,
    notificationTypes: endpointNotificationTypes,
    notificationActionTypes: endpointNotificationActionTypes,
    accessibilityTypes: endpointAccessibilityTypes,
    assetRampUpProfile: endpointAssetRampUpProfile,
    user: endpointUser,
    notificationPriorityTypes: endpointNotificationPriorityTypes,
    assetEventTypeCrossRefs: endpointAssetEventTypeCrossRefs,
    //on passing key getting api endpoint
    assetEventTypeDesc: endpointAssetEventTypes,
    dataProviderAbbr: endpointDataProvider,
    assetUnitSubtypeCd: endpointAssetUnitSubtype,
    assetNm: endpointAsset,
    assetUnitNm: endpointAssetUnitList,
    assetUnits: endPointAssetUnits,
    assetClassCd: endpointAssetClasses,
    datasetNm: endpointDataSet,
    geopoliticalEntityNm: endpointGeopoliticalEntity,
    operationalStatusTypeCd: endpointOperationalStatusType,
    industryTypeCd: endpointIndustryType,
    assetConfigurationTypeCd: endpointAssetConfigurationType,
    intelligenceSourceTypeCd: endpointIntelligentSourceType,
    shellUserId: endpointPerson,
    geopoliticalEntityCd: endpointGeopoliticalEntity,
    planningPrecisionTypeCd: endpointPlanningPrecisionType,
    planningStatusTypeCd: endpointPlanningStatusType,
    planningProbabilityTypeCd: endpointPlanningProbabilityType,
    companyRoleTypeCd: endpointCompanyRoleType,
    companyCd: endpointCompany,
    serviceNm: endpointDataProviderService,
    aucReallocation: endPointAucReallocation,
    baselineTypeCd: endpointBaselineTypes,
    ownershipClassCd: endpointOwnershipClass,
    bulkClosure: endPointBulkClosure,
    refineryIntake: endPointRefineryIntake,
    refineryIntakeBulk: endPointRefineryIntakeBulk,
};
