import React, { ForwardedRef, forwardRef, ReactElement } from 'react';

import classNames from 'classnames';

import { TOGGLE_CLASS, TOGGLE_SWITCH_CLASS } from '../../utils/constants';
import * as Styled from './Toggle.styles';
import { IToggleProps } from './Toggle.types';
import { Sizes, Positions } from '../../types/types';
import { getLabelSize } from '../../utils/sizesUtils';

const Toggle = forwardRef(
  (
    {
      className,
      size = Sizes.Medium,
      label,
      mirrored,
      labelPosition,
      disabled = false,
      wrapperProps,
      title,
      ...props
    }: IToggleProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ): ReactElement => {
    const innerMirrored = mirrored || labelPosition === Positions.Right;

    return (
      <Styled.ToggleWrapper
        className={classNames(TOGGLE_CLASS, className)}
        size={size}
        $mirrored={innerMirrored}
        $disabled={disabled}
        {...wrapperProps}
      >
        <Styled.Toggle
          ref={ref}
          prefixCls={TOGGLE_SWITCH_CLASS}
          disabled={disabled}
          size={size}
          title={title || (typeof label === 'string' && label)}
          {...props}
        />
        {!!label && <Styled.ToggleLabel size={getLabelSize(size)}>{label}</Styled.ToggleLabel>}
      </Styled.ToggleWrapper>
    );
  },
);

export default Toggle;
