import { Alignments } from '../../../types/types';

export const getJustifyContentValue: {
  [alignment in Alignments.Left | Alignments.Center | Alignments.Right | Alignments.Fill]: string | null;
} = {
  [Alignments.Left]: 'start',
  [Alignments.Center]: 'center',
  [Alignments.Fill]: null,
  [Alignments.Right]: 'end',
};
