import { __assign } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
import Spin from '../../../../../../commons/spin';
var Header = function (_a) {
    var onAddEditClick = _a.onAddEditClick, onDownloadToggle = _a.onDownloadToggle, download = _a.download, onAddEditClickEnable = _a.onAddEditClickEnable;
    return (_jsx("div", __assign({ className: "row ml-0 mr-0 mt-3 mb-3" }, { children: _jsxs("div", __assign({ className: "container-fluid" }, { children: [_jsx("div", __assign({ className: "pull-left page-title" }, { children: _jsx("h3", { children: "Asset Capacity" }) })), _jsx("div", __assign({ className: "pull-right" }, { children: _jsx("button", __assign({ type: "button", className: "add-edit-btn", title: "add or edit the current record", onClick: function () { return onAddEditClick(); }, disabled: onAddEditClickEnable }, { children: onAddEditClickEnable ? (_jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-spinner fa-spin mr-1" }), " Add / Edit an Event"] })) : (_jsxs(_Fragment, { children: [_jsx("i", { className: "fa fa-edit mr-1" }), " Add / Edit an Event"] })) })) }))] })) })));
};
Header.propTypes = {
    onAddEditClick: PropTypes.func.isRequired,
};
export default Header;
