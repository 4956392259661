import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { useAccessToken } from 'common/hooks/access-token';
import { useRequestAccessToken } from 'common/hooks/request-access-token';
import { Loader } from 'components/loader';
import { BaseContainer } from 'components/base-container';
import { useScheduleRefreshToken } from 'common/hooks/schedule-refresh-token';
var AuthContext = createContext('');
export var AuthGuardProvider = function (_a) {
    var children = _a.children;
    var accessToken = useAccessToken();
    var requestAccessToken = useRequestAccessToken();
    useScheduleRefreshToken();
    if (!accessToken) {
        requestAccessToken();
        return (_jsx(BaseContainer, { children: _jsx(Loader, { message: "Authenticating user...", delayMessage: "" }) }));
    }
    return _jsx(AuthContext.Provider, __assign({ value: accessToken }, { children: children }));
};
