import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Redirect, Switch, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import CompanyRoleType from './companyRoleType';
import OwnershipClass from './ownershipClass';
import '../../../programs/geneva/configurationData/generalConfiguration/generalConfiguration.css';
var companiesConfigurationNavLink = /** @class */ (function (_super) {
    __extends(companiesConfigurationNavLink, _super);
    function companiesConfigurationNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    companiesConfigurationNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Companies ", _jsx("span", { children: "(Configuration Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsxs(Nav, __assign({ className: "mr-auto" }, { children: [_jsx(NavLink, __assign({ to: "/dashboard/companiesConfiguration/companyRoleType", className: "nav-link", activeClassName: "active" }, { children: "Company Role Type" })), _jsx(NavLink, __assign({ to: "/dashboard/companiesConfiguration/ownershipClass", className: "nav-link", activeClassName: "active" }, { children: "Ownership Class" }))] })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/companiesconfiguration", to: "/dashboard/companiesConfiguration/companyRoleType", exact: true }), _jsx(Route, { path: "/dashboard/companiesConfiguration/companyRoleType", component: CompanyRoleType, exact: true }), _jsx(Route, { path: "/dashboard/companiesConfiguration/ownershipClass", component: OwnershipClass, exact: true })] }) }))] }))] })) }));
    };
    return companiesConfigurationNavLink;
}(Component));
export default companiesConfigurationNavLink;
