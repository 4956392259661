import { ButtonSize, ButtonVariant } from './Button.types';
import { Sizes, Variants } from '../../types/types';

type ButtonConstsType = {
  padding: {
    [size in ButtonSize]: {
      [variant in ButtonVariant]: string;
    };
  };
  icons: {
    [size in ButtonSize]: string;
  };
  sizes: {
    [size in ButtonSize]: string;
  };
};

export const buttonConsts: ButtonConstsType = {
  padding: {
    [Sizes.ExtraSmall]: {
      [Variants.Filled]: '8px',
      [Variants.Transparent]: '4px',
      [Variants.Outlined]: '7px',
    },
    [Sizes.Small]: {
      [Variants.Filled]: '12px',
      [Variants.Transparent]: '6px',
      [Variants.Outlined]: '10px',
    },
    [Sizes.Medium]: {
      [Variants.Filled]: '16px',
      [Variants.Transparent]: '8px',
      [Variants.Outlined]: '15px',
    },
    [Sizes.Large]: {
      [Variants.Filled]: '24px',
      [Variants.Transparent]: '12px',
      [Variants.Outlined]: '23px',
    },
  },
  icons: {
    [Sizes.ExtraSmall]: '16px',
    [Sizes.Small]: '16px',
    [Sizes.Medium]: '20px',
    [Sizes.Large]: '24px',
  },
  sizes: {
    [Sizes.ExtraSmall]: '24px',
    [Sizes.Small]: '32px',
    [Sizes.Medium]: '40px',
    [Sizes.Large]: '48px',
  },
};
