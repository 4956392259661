import React, { ReactElement } from 'react';

import classNames from 'classnames';
import RcDropdown from 'rc-dropdown';

import { DROPDOWN_CLASS, DROPDOWN_CONTAINER_OVERLAY_CLASS } from '../../utils/constants';
import * as Styled from './Dropdown.styles';
import { IDropdownProps } from './Dropdown.types';

const DropdownComponent = ({
  className,
  overlayClassName,
  trigger = 'click',
  children,
  arrow = false,
  placement = 'bottomLeft',
  overlay,
  ...rest
}: IDropdownProps): ReactElement => (
  <RcDropdown
    // @ts-ignore
    className={className}
    placement={placement}
    arrow={arrow}
    trigger={trigger}
    getPopupContainer={(element) => element}
    {...rest}
    prefixCls={DROPDOWN_CLASS}
    overlayClassName={classNames(DROPDOWN_CONTAINER_OVERLAY_CLASS, overlayClassName, className)}
    overlay={<Styled.DropdownContainer>{overlay}</Styled.DropdownContainer>}
  >
    {children}
  </RcDropdown>
);

/**
 *
 * @deprecated
 * Use Popover instead of Dropdown
 */
const Dropdown = Styled.Dropdown(DropdownComponent, DROPDOWN_CLASS, DROPDOWN_CONTAINER_OVERLAY_CLASS);

export default Dropdown;
