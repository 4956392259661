import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Grid, GridColumn, GridToolbar, GridFilterCell } from '@progress/kendo-react-grid';
import { getSourceData, editSourceData, saveSourceData, deleteSourceData } from '../services/urlService';
//import { ExcelExport } from "@progress/kendo-react-excel-export";
import Spinner from './spinner';
//import ScrollbarWithSimpleBar from "simplebar";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { process } from '@progress/kendo-data-query';
import _ from 'lodash';
import CommandCell from './commandCell';
import { withWindowSizeListener } from 'react-window-size-listener';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as masterScreenActions from '../redux/actions/masterScreenActions';
import { bindActionCreators } from 'redux';
import './gridTemplate.css';
var options = { autoClose: 2000 };
var Msg = function (props) { return (_jsx(React.Fragment, { children: _jsx("ul", { children: props.list.map(function (value, i) {
            return _jsx("li", { children: props.error[value].error }, i);
        }) }) })); };
var gridTemplate = /** @class */ (function (_super) {
    __extends(gridTemplate, _super);
    function gridTemplate(props) {
        var _this = _super.call(this, props) || this;
        _this.pageSize = 20;
        _this.getRandomInt = function (max) {
            return Math.floor(Math.random() * Math.floor(max));
        };
        _this.CustomFilterCell = function (props) {
            return (_jsx(GridFilterCell, __assign({ filterType: "date" }, props, { operators: [
                    { operator: 'gt', text: 'From Date' },
                    { operator: 'lt', text: 'To Date' },
                ] })));
        };
        _this.loadForeginKeyData = function (value, key) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    this.props.actions.loadMasterScreenDataOnAction(value, value);
                    return [2 /*return*/, true];
                }
                catch (error) {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/];
            });
        }); };
        _this.pageChange = function (event) {
            _this.requestIfNeeded(event.page.skip);
            _this.setState({
                skip: event.page.skip,
            });
        };
        _this.loadingCell = function (tdElement, props) {
            if (props.dataItem[props.field] === undefined) {
                return (_jsx("td", { children: _jsx("span", { className: "k-icon k-i-loading" }) }));
            }
            return tdElement;
        };
        _this.dateFormatter = function (item) {
            if (item.recordEntryDttm !== null && item.recordEntryDttm) {
                var timeSplit = item.recordEntryDttm.split('.');
                item.recordEntryDttm = new Date(timeSplit[0]);
            }
            if (item.metaChangedDttm !== null && item.metaChangedDttm) {
                var timeSplit = item.metaChangedDttm.split('.');
                item.metaChangedDttm = new Date(timeSplit[0]);
            }
            if (item.metaCreatedDttm !== null && item.metaCreatedDttm) {
                var timeSplit = item.metaCreatedDttm.split('.');
                item.metaCreatedDttm = new Date(timeSplit[0]);
            }
            return item;
        };
        _this.dataFormatter = function (item) {
            _this.dateFormatter(item);
            return item;
        };
        _this.dataload = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.setState({ loader: !this.state.loader });
                setTimeout(function () {
                    _this.requestData(0, _this.state.path);
                    setTimeout(function () {
                        _this.setState({ loader: !_this.state.loader });
                    }, 3000);
                }, 2000);
                return [2 /*return*/];
            });
        }); };
        _this.componentDidMount = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    this.dataload();
                }
                catch (error) {
                    this.setState({ loader: !this.state.loader });
                }
                return [2 /*return*/];
            });
        }); };
        _this.commandCellWithProps = function (props) {
            return (_jsx(CommandCell, __assign({}, props, { result: _this.state.result, onSave: _this.save, onCancel: _this.cancel, onRemove: _this.remove, onUpdate: _this.update, onEnterEdit: _this.enterEdit, uniqueid: _this.state.uniqueid, uniquecode: _this.props.uniquecode })));
        };
        _this.enterInsert = function () {
            var dataItem = {
                inEdit: true,
                Discontinued: false,
                events: 'add',
                randomEditInd: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            };
            var newproducts = _this.state.virtualScrollData.slice();
            newproducts.unshift(dataItem);
            _this.setState({
                virtualScrollData: newproducts,
            });
            var currentTable = document.querySelector('.k-grid-table');
            currentTable.style.transform = 'translate(0px)';
            document.querySelector('.k-grid-table').scrollIntoView({ block: 'start' });
        };
        _this.enterEdit = function (dataItem) {
            dataItem.randomEditInd = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            _this.update(_this.state.virtualScrollData, dataItem).inEdit = true;
            _this.setState({
                data: _this.state.virtualScrollData.slice(),
                dataItemCustom: '',
            });
        };
        _this.save = function (dataItem) { return __awaiter(_this, void 0, void 0, function () {
            var errorToSHow, edit, errorToSHow, add;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(typeof dataItem.events === 'undefined')) return [3 /*break*/, 4];
                        if (!dataItem) return [3 /*break*/, 3];
                        errorToSHow = Object.keys(this.props.validation).filter(function (value, i) {
                            if (dataItem.hasOwnProperty(value) && _this.props.validation[value].required) {
                                if (!dataItem[value]) {
                                    return _this.props.validation[value].error;
                                }
                            }
                            return false;
                        });
                        if (!(errorToSHow.length > 0)) return [3 /*break*/, 1];
                        toast.error(_jsx(Msg, { list: errorToSHow, error: this.props.validation }), options);
                        return [2 /*return*/, false];
                    case 1: return [4 /*yield*/, this.getEdit(dataItem, dataItem[this.props.uniqueid], this.state.path)];
                    case 2:
                        edit = _a.sent();
                        if (edit) {
                            this.setState({
                                virtualScrollData: this.state.virtualScrollData.slice(),
                                dataBackup: this.state.virtualScrollData.slice(),
                            });
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 7];
                    case 4:
                        if (!dataItem) return [3 /*break*/, 7];
                        errorToSHow = Object.keys(this.props.validation).filter(function (value, i) {
                            if (!dataItem.hasOwnProperty(value) && _this.props.validation[value].required) {
                                return _this.props.validation[value].error;
                            }
                            return false;
                        });
                        if (!(errorToSHow.length > 0)) return [3 /*break*/, 5];
                        toast.error(_jsx(Msg, { list: errorToSHow, error: this.props.validation }), options);
                        return [2 /*return*/, false];
                    case 5: return [4 /*yield*/, this.add(dataItem, this.state.path)];
                    case 6:
                        add = _a.sent();
                        if (add) {
                            delete dataItem.inEdit;
                            delete dataItem.Discontinued;
                            delete dataItem.events;
                            this.setState({
                                virtualScrollData: this.state.virtualScrollData.slice(),
                                dataBackup: this.state.virtualScrollData.slice(),
                            });
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.cancel = function (dataItem) {
            dataItem.inEdit = undefined;
            if (dataItem[_this.props.uniqueid]) {
                var originalItem = _this.state.dataBackup.find(function (p) { return p[_this.props.uniqueid] === dataItem[_this.props.uniqueid]; });
                delete originalItem.inEdit;
                delete originalItem.randomEditInd;
                _this.update(_this.state.virtualScrollData, originalItem);
            }
            else {
                _this.update(_this.state.virtualScrollData, dataItem, !dataItem[_this.props.uniqueid]);
            }
            _this.setState({
                data: _this.state.virtualScrollData.slice(),
            });
        };
        _this.remove = function (dataItem) {
            dataItem.inEdit = undefined;
            _this.update(_this.state.virtualScrollData, dataItem, true);
            _this.removeRow(dataItem[_this.props.uniqueid], _this.state.path);
            _this.setState({
                data: _this.state.virtualScrollData.slice(),
            });
        };
        _this.itemChange = function (event) {
            var value = event.value;
            var name = event.field;
            if (!name) {
                return;
            }
            var updatedData = _this.state.virtualScrollData.slice();
            var item = _this.update(updatedData, event.dataItem);
            item[name] = value;
            _this.setState({
                virtualScrollData: updatedData,
            });
        };
        _this.update = function (data, item, remove) {
            var _a;
            var updated;
            var index = data.findIndex(function (p) { return p === item || (item[_this.props.uniqueid] && p[_this.props.uniqueid] === item[_this.props.uniqueid]); });
            if (index >= 0) {
                updated = Object.assign({}, item);
                data[index] = updated;
            }
            else {
                var id_1 = 1;
                data.forEach(function (p) {
                    id_1 = Math.max(p[_this.props.uniqueid] + 1, id_1);
                });
                updated = Object.assign({}, item, (_a = {}, _a[_this.props.uniqueid] = id_1, _a));
                data.unshift(updated);
                index = 0;
            }
            if (remove) {
                return data.splice(index, 1)[0];
            }
            return data[index];
        };
        _this.removeRow = function (uniqueID, path) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, deleteSourceData(uniqueID, this.state.path)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        return [2 /*return*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.getEdit = function (dt, uniqueID, path) { return __awaiter(_this, void 0, void 0, function () {
            var returnData_1, stateData, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 9]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        if (!this.props.foreginKeyParams) return [3 /*break*/, 3];
                        this.props.foreginKeyParams.map(function (value, i) {
                            var foreignKey = value.key;
                            var foreignCd = value.cdName;
                            var foreignPath = value.path;
                            var currentChangedId = dt[value.cdName];
                            dt[foreignKey] = currentChangedId;
                            if (typeof currentChangedId === 'string') {
                                dt[foreignKey] = dt[foreignPath].id;
                            }
                            delete dt[foreignCd];
                            delete dt[foreignPath];
                            return value;
                        });
                        return [4 /*yield*/, editSourceData(dt, uniqueID, path)];
                    case 2:
                        returnData_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, editSourceData(dt, uniqueID, path)];
                    case 4:
                        returnData_1 = _a.sent();
                        _a.label = 5;
                    case 5:
                        stateData = this.state.virtualScrollData;
                        stateData.map(function (item) {
                            if (item.inEdit === true && item.id === uniqueID) {
                                var mergedData = _.merge(item, returnData_1.data);
                                delete mergedData.inEdit;
                                if (_this.props.foreginKeyParams) {
                                    _this.props.foreginKeyParams.forEach(function (value, i) {
                                        if (typeof _this.props.masterData !== 'undefined') {
                                            if (typeof _this.props.masterData.get(value.path) !== 'undefined') {
                                                var index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.key]; });
                                                if (index !== -1) {
                                                    item[value.cdName] = _this.props.masterData.get(value.path)[index][value.cdName];
                                                    item[value.path] = _this.props.masterData.get(value.path)[index];
                                                }
                                                else {
                                                    item[value.path] = {};
                                                }
                                            }
                                        }
                                    });
                                }
                                _this.dateFormatter(mergedData);
                                return mergedData;
                            }
                            else {
                                return item;
                            }
                        });
                        this.setState({
                            virtualScrollData: stateData,
                            dataBackup: stateData,
                        });
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 7:
                        error_2 = _a.sent();
                        if (this.props.foreginKeyParams) {
                            this.props.foreginKeyParams.map(function (value) {
                                var foreignKey = value.key;
                                var foreignCd = value.cdName;
                                // let foreignPath = value.path;
                                var currentChangedId = dt[foreignKey];
                                delete dt[foreignKey];
                                dt[foreignCd] = currentChangedId;
                                return value;
                            });
                        }
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 8:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 9: return [2 /*return*/];
                }
            });
        }); };
        _this.add = function (dt, path) { return __awaiter(_this, void 0, void 0, function () {
            var returnData_2, stateData, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 9]);
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 1:
                        _a.sent();
                        if (!this.props.foreginKeyParams) return [3 /*break*/, 3];
                        this.props.foreginKeyParams.map(function (value, i) {
                            var foreignKey = value.key;
                            var foreignCd = value.cdName;
                            var foreignPath = value.path;
                            var currentChangedId = dt[value.cdName];
                            delete dt[foreignCd];
                            delete dt[foreignPath];
                            dt[foreignKey] = currentChangedId;
                            return value;
                        });
                        return [4 /*yield*/, saveSourceData(dt, path)];
                    case 2:
                        returnData_2 = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, saveSourceData(dt, path)];
                    case 4:
                        returnData_2 = _a.sent();
                        _a.label = 5;
                    case 5:
                        stateData = this.state.virtualScrollData;
                        stateData.map(function (item) {
                            if (item.events === 'add' && item[_this.props.uniquecode] === returnData_2.data[_this.props.uniquecode]) {
                                var mergedData = _.merge(item, returnData_2.data);
                                if (_this.props.foreginKeyParams) {
                                    _this.props.foreginKeyParams.forEach(function (value, i) {
                                        if (typeof _this.props.masterData !== 'undefined') {
                                            if (typeof _this.props.masterData.get(value.path) !== 'undefined') {
                                                var index = _this.props.masterData.get(value.path).findIndex(function (x) { return x.id === item[value.key]; });
                                                if (index !== -1) {
                                                    item[value.cdName] = _this.props.masterData.get(value.path)[index][value.cdName];
                                                    item[value.path] = _this.props.masterData.get(value.path)[index];
                                                }
                                                else {
                                                    item[value.path] = {};
                                                }
                                            }
                                        }
                                    });
                                }
                                _this.dateFormatter(mergedData);
                                return mergedData;
                            }
                            else {
                                return item;
                            }
                        });
                        this.setState({
                            virtualScrollData: stateData,
                            dataBackup: stateData,
                        });
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 7:
                        error_3 = _a.sent();
                        if (this.props.foreginKeyParams) {
                            this.props.foreginKeyParams.map(function (value) {
                                var foreignKey = value.key;
                                var foreignCd = value.cdName;
                                //let foreignPath = value.path;
                                var currentChangedId = dt[foreignKey];
                                delete dt[foreignKey];
                                dt[foreignCd] = currentChangedId;
                                return value;
                            });
                        }
                        return [4 /*yield*/, this.setState({ loader: !this.state.loader })];
                    case 8:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 9: return [2 /*return*/];
                }
            });
        }); };
        //metabox enable and disable
        _this.checkboxChange = function (_a) {
            var checked = _a.target.checked;
            if (_this.state.showMetaColumn) {
                _this.setState({
                    showMetaColumn: !_this.state.showMetaColumn,
                    singleMode: !_this.state.singleMode,
                    minWidth: '',
                    // scroll: 'none'
                });
            }
            else {
                _this.setState({
                    showMetaColumn: !_this.state.showMetaColumn,
                    singleMode: !_this.state.singleMode,
                    minWidth: '240px',
                    // scroll: 'virtual'
                });
            }
            return;
        };
        _this.cancelCurrentChanges = function (e) {
            var cancelChangesData = _this.state.virtualScrollData;
            cancelChangesData = cancelChangesData.filter(function (item, key) {
                if (item.inEdit) {
                    item.inEdit = undefined;
                    if (typeof item.events !== 'undefined') {
                        return false;
                    }
                    else {
                        return item;
                    }
                }
                else {
                    return item;
                }
            });
            _this.setState({ virtualScrollData: cancelChangesData });
            return;
        };
        _this.dataStateChange = function (event) {
            _this.setState(_this.createAppState(event));
        };
        _this.export = function () {
            _this._export.save();
        };
        _this.state = {
            data: [],
            dataBackup: [],
            loader: false,
            events: undefined,
            showMetaColumn: true,
            singleMode: true,
            dataState: {
                skip: 0,
                take: 20,
                filter: {
                    logic: 'and',
                    filters: [],
                },
            },
            minWidth: '240px',
            width: '',
            height: '',
            path: _this.props.path,
            uniqueid: _this.props.uniqueid,
            column: _this.props.column,
            foreginKeyData: new Map(),
            dataItemCustom: '',
            virtualScrollData: [],
            total: 0,
            skip: 0,
            scroll: 'virtual',
        };
        //initializing virtual scroll
        _this.createAppState({
            data: {},
        });
        //React.renderComponent(GridDropDownCell);
        _this.checkboxChange = _this.checkboxChange.bind(_this);
        _this.cancelCurrentChanges = _this.cancelCurrentChanges.bind(_this);
        _this.enterInsert = _this.enterInsert.bind(_this);
        _this.itemChange = _this.itemChange.bind(_this);
        _this.enterEdit = _this.enterEdit.bind(_this);
        _this.save = _this.save.bind(_this);
        _this.cancel = _this.cancel.bind(_this);
        _this.remove = _this.remove.bind(_this);
        return _this;
    }
    gridTemplate.prototype.requestIfNeeded = function (skip) {
        for (var i = skip; i < skip + this.pageSize && i < this.state.virtualScrollData.length; i++) {
            if (this.state.virtualScrollData[i].id === undefined) {
                // request data only if not already fetched
                this.requestData(skip);
                return;
            }
        }
    };
    gridTemplate.prototype.requestData = function (skipParameter, path) {
        var _this = this;
        if (this.requestInProgress) {
            return;
        }
        // this.requestInProgress = true;
        var skip = Math.max(skipParameter - this.pageSize, 0); //request the prev page as well
        getSourceData(path)
            .then(function (response) { return response; })
            .then(function (response) {
            //  this.requestInProgress = false;
            try {
                if (typeof response !== 'undefined') {
                    var total = response['data'].length;
                    var data = response['data'];
                    if (data !== 'empty') {
                        var virtualScrollData_1 = _this.state.virtualScrollData.length === total
                            ? _this.state.virtualScrollData
                            : new Array(total).fill().map(function (e, i) { return ({ Index: i }); });
                        data.forEach(function (order, i) {
                            virtualScrollData_1[i + skip] = __assign({ Index: i + skip }, order);
                        });
                        var mapData = virtualScrollData_1.map(_this.dataFormatter);
                        _this.requestIfNeeded(_this.state.skip);
                        _this.setState({
                            virtualScrollData: mapData,
                            dataBackup: mapData,
                            total: total,
                        });
                    }
                    else {
                        _this.setState({
                            virtualScrollData: [],
                            dataBackup: [],
                            total: 0,
                        });
                    }
                }
                else {
                    _this.setState({
                        virtualScrollData: [],
                        dataBackup: [],
                        total: 0,
                    });
                }
            }
            catch (error) {
                // this.requestInProgress = false;
                return false;
            }
        });
    };
    gridTemplate.prototype.createAppState = function (event) {
        return {
            result: process(this.state.virtualScrollData, event.data),
            dataState: event.data,
        };
    };
    gridTemplate.prototype.render = function () {
        var _this = this;
        return (_jsxs(_Fragment, { children: [this.state.loader && _jsx(Spinner, {}), _jsx("div", __assign({ id: "gridTemplateListings" }, { children: _jsxs(Grid, __assign({ style: { height: this.props.windowSize.windowHeight - 140 }, rowHeight: 40, onItemChange: this.itemChange, editField: "inEdit", reorderable: true, resizable: true, sortable: true, filterable: true, scrollable: this.state.scroll, data: process(this.state.virtualScrollData, this.state.dataState) }, this.state.dataState, { onDataStateChange: function (event) { return _this.setState({ dataState: event.data }); } }, { children: [_jsxs(GridToolbar, { children: [_jsx("div", __assign({ className: "pull-left ml-2" }, { children: _jsx(Tooltip, __assign({ openDelay: 10, position: "bottom", anchorElement: "target" }, { children: _jsx("button", __assign({ look: "bare", onClick: this.enterInsert, className: "k-button bg-transparent border border-0 p-1" }, { children: _jsx("a", __assign({ href: "javascript:void(0);", role: "button", className: "k-icon k-i-plus-outline", style: { fontSize: 25, color: '#7f7f7f' }, title: "Add new item to the grid" }, { children: "\u00A0" })) })) })) })), _jsx("div", __assign({ className: "pull-right" }, { children: _jsx(Tooltip, { openDelay: 10, position: "bottom", anchorElement: "target" }) })), _jsxs("div", __assign({ className: "pull-right p-2" }, { children: ["Total Data: ", this.state.virtualScrollData.length] })), _jsxs("div", __assign({ className: "pull-left pl-3" }, { children: [_jsx("input", { id: "metaData", type: "checkbox", checked: this.state.singleMode, onChange: this.checkboxChange, className: "k-checkbox" }), _jsx("label", __assign({ htmlFor: "metaData", className: "k-checkbox-label m-2" }, { children: "Enable Metadata" }))] }))] }), _jsx(GridColumn, { width: "130px", cell: this.commandCellWithProps, filterable: false, resizable: false, reorderable: false, locked: true }), this.props.column.map(function (column, index) {
                                if (column.type !== 'meta' || (column.type === 'meta' && _this.state.showMetaColumn)) {
                                    // let columnKey = Object.keys(column);
                                    var filterCell = column.filter === 'date' ? _this.CustomFilterCell : '';
                                    return _jsx(GridColumn, __assign({}, column, { width: _this.state.minWidth, filterCell: filterCell }), index);
                                }
                                return true;
                            })] })) }))] }));
    };
    return gridTemplate;
}(Component));
function mapStateToProps(state) {
    return {
        masterData: state.masterScreenReducer.masterData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(masterScreenActions, dispatch),
    };
}
export default withWindowSizeListener(connect(mapStateToProps, mapDispatchToProps)(gridTemplate));
