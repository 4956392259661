import { __assign, __spreadArray } from "tslib";
import { newMapSelectData } from './newScreenUtils';
var getCountriesAndOptions = function (regions, selectedRegions) {
    var countries = [];
    if (selectedRegions && selectedRegions.length) {
        countries = regions
            .filter(function (region) { return selectedRegions.some(function (selectedRegion) { return selectedRegion === region.regionKey; }); })
            .map(function (region) { return region.children; })
            .flat()
            .sort(function (a, b) { return (a.countryName.toLowerCase() < b.countryName.toLowerCase() ? -1 : 1); });
    }
    else {
        countries = regions
            .map(function (region) { return region.children; })
            .flat()
            .sort(function (a, b) { return (a.countryName.toLowerCase() < b.countryName.toLowerCase() ? -1 : 1); });
    }
    var countriesOptions = countries.map(newMapSelectData('countryName', 'countryKey'));
    return { countries: countries, countriesOptions: countriesOptions };
};
var getCompaniesAndOptions = function (regions, countries, selectedRegions, selectedCountries) {
    var companies = [];
    if (selectedCountries && selectedCountries.length) {
        companies = countries
            .filter(function (country) { return selectedCountries.some(function (selectedCountry) { return selectedCountry === country.countryKey; }); })
            .map(function (region) { return region.children; })
            .flat()
            .filter(function (company, index, array) {
            return company.companyKey !== null && array.findIndex(function (t) { return t.companyKey == company.companyKey; }) == index;
        })
            .sort(function (a, b) { return ((a.companyName || '').toLowerCase() < (b.companyName || '').toLowerCase() ? -1 : 1); });
    }
    else if (selectedRegions && selectedRegions.length) {
        companies = regions
            .filter(function (region) { return selectedRegions.some(function (selectedRegion) { return selectedRegion === region.regionKey; }); })
            .map(function (region) {
            return region.children.map(function (country) {
                return country.children;
            });
        })
            .flat(2)
            .filter(function (company, index, array) {
            return company.companyKey !== null && array.findIndex(function (t) { return t.companyKey == company.companyKey; }) == index;
        })
            .sort(function (a, b) { return ((a.companyName || '').toLowerCase() < (b.companyName || '').toLowerCase() ? -1 : 1); });
    }
    else {
        var companiesWithDuplicates = regions
            .map(function (region) { return region.children; })
            .flat()
            .map(function (country) { return country.children; })
            .flat()
            .filter(function (company) { return company.companyKey !== null; })
            .sort(function (a, b) { return ((a.companyName || '').toLowerCase() < (b.companyName || '').toLowerCase() ? -1 : 1); });
        companiesWithDuplicates.forEach(function (company) {
            var companyIndex = companies.findIndex(function (distinctCompany) { return distinctCompany.companyKey === company.companyKey; });
            if (companyIndex === -1) {
                companies.push(company);
            }
            else {
                companies[companyIndex] = __assign(__assign({}, companies[companyIndex]), { children: __spreadArray(__spreadArray([], companies[companyIndex].children, true), company.children, true) });
            }
        });
    }
    var companiesOptions = companies.map(newMapSelectData('companyName', 'companyKey'));
    return { companies: companies, companiesOptions: companiesOptions };
};
var getAssetsAndOptions = function (_a) {
    var regions = _a.regions, countries = _a.countries, companies = _a.companies, selectedRegions = _a.selectedRegions, selectedCountries = _a.selectedCountries, selectedCompanies = _a.selectedCompanies;
    var assets = [];
    if (selectedCompanies && selectedCompanies.length) {
        assets = companies
            .filter(function (company) { return selectedCompanies.some(function (selectedCompany) { return selectedCompany === company.companyKey; }); })
            .map(function (company) { return company.children; })
            .flat()
            .filter(function (asset, index, array) { return array.findIndex(function (t) { return t.assetKey == asset.assetKey; }) == index; })
            .sort(function (a, b) { return (a.assetName.toLowerCase() < b.assetName.toLowerCase() ? -1 : 1); });
    }
    else if (selectedCountries && selectedCountries.length) {
        assets = countries
            .filter(function (country) { return selectedCountries.some(function (selectedCountry) { return selectedCountry === country.countryKey; }); })
            .map(function (country) {
            return country.children.map(function (company) {
                return company.children;
            });
        })
            .flat(2)
            .filter(function (asset, index, array) { return array.findIndex(function (t) { return t.assetKey == asset.assetKey; }) == index; })
            .sort(function (a, b) { return (a.assetName.toLowerCase() < b.assetName.toLowerCase() ? -1 : 1); });
    }
    else if (selectedRegions && selectedRegions.length) {
        assets = regions
            .filter(function (region) { return selectedRegions.some(function (selectedRegion) { return selectedRegion === region.regionKey; }); })
            .map(function (region) {
            return region.children.map(function (country) {
                return country.children.map(function (company) { return company.children.map(function (assets) { return assets; }); }).flat();
            });
        })
            .flat(2)
            .filter(function (asset, index, array) { return array.findIndex(function (t) { return t.assetKey == asset.assetKey; }) == index; })
            .sort(function (a, b) { return (a.assetName.toLowerCase() < b.assetName.toLowerCase() ? -1 : 1); });
    }
    else {
        assets = regions
            .map(function (region) { return region.children; })
            .flat()
            .map(function (country) { return country.children; })
            .flat()
            .map(function (company) { return company.children; })
            .flat()
            .filter(function (asset, index, array) { return array.findIndex(function (t) { return t.assetKey == asset.assetKey; }) == index; })
            .sort(function (a, b) { return (a.assetName.toLowerCase() < b.assetName.toLowerCase() ? -1 : 1); });
    }
    var assetsOptions = assets.map(newMapSelectData('assetName', 'assetKey', 'allAssetNms'));
    return { assets: assets, assetsOptions: assetsOptions };
};
export { getCountriesAndOptions, getCompaniesAndOptions, getAssetsAndOptions };
