import { ReactElement } from 'react';

import Button from '../../../Button/Button';
import { FlexboxJustifyContent } from '../../../Flexbox/Flexbox.types';
import Styled from './ActionBar.styles';
import { IActionBarProps } from './ActionBar.types';
import { Alignments } from '../../../../types/types';
import { getJustifyContentValue } from '../../utils/actionBarUtils';

const ActionBar = ({
  size,
  padding,
  actions,
  alignment = Alignments.Right,
  footerDirection,
}: IActionBarProps): ReactElement => (
  <>
    {actions?.length && (
      <Styled.Container
        gap="12px"
        justifyContent={getJustifyContentValue[alignment] as FlexboxJustifyContent}
        flexDirection={footerDirection}
        actionBarAlignment={alignment}
        size={size}
        padding={padding}
      >
        {actions.map(({ label, action, props }) => (
          <Button key={label} size={size} onClick={action} {...props}>
            {label}
          </Button>
        ))}
      </Styled.Container>
    )}
  </>
);

export default ActionBar;
