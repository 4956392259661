import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
var _ = require('lodash');
//Functional component to show  Yes/No in a dropdown
export default function BooleanDropdownList(props) {
    var dataItem = props.dataItem, field = props.field;
    var booleanListItems = ['Yes', 'No'];
    var dataValue = dataItem[field] === null ? '' : dataItem[field];
    var keyValue;
    var defaultItem = ['Select...'];
    function onTextChange(event) {
        var data = props.dataItem;
        if (event.target.value === 'Yes') {
            keyValue = 'Y';
        }
        else if (event.target.value === 'No') {
            keyValue = 'N';
        }
        else {
            keyValue = '';
        }
        props.onChange({
            dataItem: data,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: keyValue,
        });
    }
    return (_jsx("td", { children: dataItem.inEdit ? (_jsx(DropDownList, { style: { width: '100%' }, data: booleanListItems, filterable: false, onChange: onTextChange, value: dataValue === 'Y' ? 'Yes' : dataValue === 'N' ? 'No' : 'Select...', dataItemKey: keyValue, textField: dataItem.field, defaultItem: "Select..." })) : (dataValue.toString()) }));
}
