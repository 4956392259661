import { __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Maintenance from './maintenance/maintenance';
import RefineryIntake from './RefineryIntake/RefineryIntake';
import DataUpload from './dataUpload/dataUpload';
import Capacityscreen from './capacityscreen/capacityscreen';
import AssetUnitConfiguration from './assetUnitConfiguration/assetUnitConfiguration';
import AssetConfiguration from './assetConfiguration/AssetConfiguration';
var globalAssetDb = /** @class */ (function (_super) {
    __extends(globalAssetDb, _super);
    function globalAssetDb() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        return _this;
    }
    globalAssetDb.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/globalAssetDatabase", to: "/dashboard/globalAssetDatabase/maintenance", exact: true }), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/maintenance/maintenance" }, "maintenance"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/maintenance/maintenanceDaily", component: Maintenance }, "maintenance/daily"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/maintenance/maintenanceEventSummary", component: Maintenance }, "maintenance/eventSummary"), _jsx(Route, { path: "/dashboard/globalAssetDatabase/dataUpload", component: DataUpload }), _jsx(Redirect, { from: "/dashboard/globalAssetDatabase/capacity", to: "/dashboard/globalAssetDatabase/capacity/capacityTotal", exact: true }), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/capacity", component: Capacityscreen }, "capacity"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/capacity/capacityTotal", component: Capacityscreen }, "capacity/total"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/capacity/capacityIncremental", component: Capacityscreen }, "capacity/incremental"), _jsx(Redirect, { from: "/dashboard/globalAssetDatabase/assetUnitConfiguration", to: "/dashboard/globalAssetDatabase/assetUnitConfiguration/assetUnitConfiguration", exact: true }), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/assetUnitConfiguration/assetUnitConfiguration", component: AssetUnitConfiguration }, "assetUnitConfig"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/assetConfiguration", component: AssetConfiguration }, "assetConfig"), _jsx(Route, { exact: true, path: "/dashboard/globalAssetDatabase/refineryIntake", component: RefineryIntake }, "refineryIntake")] }) }));
    };
    return globalAssetDb;
}(Component));
export default globalAssetDb;
