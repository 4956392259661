import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table, THead, TBody, TH, TR, TD } from '@sede-x/shell-ds-react-framework';
import { useAppDispatch } from 'common/hooks/store';
import { StyledActionsContainer } from 'components/event-summary-actions-modal/EventsSummaryActionsModal.styles';
import { Loader } from 'components/loader';
import { columns } from 'components/event-summary-actions-modal/EventsSummaryActionsModal.config';
import { setModalAssetUnitEventKey, setModalSelectedItemKey } from 'pages/maintenance-event-summary/ducks/slice';
export var OriginalEventsModal = function (_a) {
    var _b, _c;
    var data = _a.data, isFetching = _a.isFetching;
    var dispatch = useAppDispatch();
    (data === null || data === void 0 ? void 0 : data[0]) && dispatch(setModalSelectedItemKey((_b = data[0]) === null || _b === void 0 ? void 0 : _b.id));
    (data === null || data === void 0 ? void 0 : data[0]) && dispatch(setModalAssetUnitEventKey((_c = data[0]) === null || _c === void 0 ? void 0 : _c.assetUnitEventKey));
    var getColumns = columns({ shortKeyType: false });
    var table = useReactTable({
        data: data || [],
        columns: getColumns,
        getCoreRowModel: getCoreRowModel(),
    });
    if (isFetching) {
        return (_jsx(StyledActionsContainer, { children: _jsx(Loader, { message: "Fetching originals..." }) }));
    }
    return (_jsx("div", __assign({ style: {
            position: 'relative',
            maxHeight: '30vh',
            overflowY: 'auto',
        } }, { children: _jsxs(Table, { children: [_jsx(THead, { children: table.getHeaderGroups().map(function (headerGroup) { return (_jsx(TR, __assign({ style: { position: 'sticky', top: 0, zIndex: 2 } }, { children: headerGroup.headers.map(function (header) { return (_jsx(TH, { children: header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext()) }, header.id)); }) }), headerGroup.id)); }) }), _jsx(TBody, { children: table.getRowModel().rows.map(function (row) { return (_jsx(TR, __assign({ style: { cursor: 'pointer' } }, { children: row.getVisibleCells().map(function (cell) { return (_jsx(TD, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)); }) }), row.id)); }) })] }) })));
};
