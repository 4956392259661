import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import Breadcrumb from '../breadcrumb/breadcrumb';
import { Navbar } from 'react-bootstrap';
import './topBarMenu.css';
var topBarMenu = /** @class */ (function (_super) {
    __extends(topBarMenu, _super);
    function topBarMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    topBarMenu.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "topbar-menu", style: {
                    position: 'fixed',
                    width: '100%',
                    height: '45px',
                    width: '97.2%',
                } }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "topbar-menu-breadcrumbs col-sm-8" }, { children: _jsx(Breadcrumb, {}) })), _jsx("div", __assign({ className: "topbar-menu-user-tray col-sm-4" }, { children: _jsx(Navbar, { children: _jsx(Navbar.Collapse, __assign({ className: "justify-content-end" }, { children: _jsx("a", __assign({ className: "mt-2", href: "mailto:GX-TR-GENEVA-PENGUINS-FEEDBACK@shell.com" }, { children: _jsx("i", __assign({ className: "fa fa-comments", style: { fontSize: '24px', paddingRight: '10px' } }, { children: _jsxs("span", __assign({ style: {
                                                    fontFamily: 'ShellMedium',
                                                    fontSize: '14px',
                                                    padding: '0 10px 0 5px',
                                                    color: '#d9d9d9',
                                                } }, { children: [' ', "Send Feedback", ' '] })) })) })) })) }) }))] })) })) }));
    };
    return topBarMenu;
}(Component));
export default topBarMenu;
