import { __makeTemplateObject } from "tslib";
import { Modal, Heading, Text, Tree, Loading, Tabs } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
export var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .shell-modal-container-body {\n    justify-content: flex-start;\n  }\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"], ["\n  .shell-modal-container-body {\n    justify-content: flex-start;\n  }\n  *::-webkit-scrollbar {\n    background: ", ";\n    width: 10px;\n    height: 10px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background: ", ";\n    border: 1px solid ", ";\n    border-radius: 8px;\n  }\n\n  *::-webkit-scrollbar-thumb:hover {\n    background: ", ";\n  }\n\n  *::-webkit-scrollbar-corner {\n    background: rgba(0, 0, 0, 0);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.section;
}, function (_a) {
    var theme = _a.theme;
    return theme.border.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
});
export var StyledText = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.subtle;
});
export var StyledLoading = styled(Loading)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 8px;\n"], ["\n  margin-right: 8px;\n"])));
export var StyledFooter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  align-items: center;\n  margin: 15px 20px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  align-items: center;\n  margin: 15px 20px;\n"])));
// FIXME: remove this component when master-dark.css file is deleted!
export var StyledH3 = styled(Heading)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
// FIXME: remove this component when master-dark.css file is deleted!
export var StyledTree = styled(Tree)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  svg {\n    vertical-align: baseline;\n  }\n  label {\n    margin-bottom: 0;\n  }\n  path {\n    margin-right: 0;\n  }\n  padding: 24px 0 24px 0;\n"], ["\n  svg {\n    vertical-align: baseline;\n  }\n  label {\n    margin-bottom: 0;\n  }\n  path {\n    margin-right: 0;\n  }\n  padding: 24px 0 24px 0;\n"])));
export var StyledPlaceholder = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-style: normal;\n"], ["\n  color: ", ";\n  font-style: normal;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.strong;
});
export var StyledTabs = styled(Tabs)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  .shell-tabs-tab p {\n    color: ", ";\n  }\n  .shell-tabs-tab-active p {\n    color: ", " !important;\n  }\n"], ["\n  .shell-tabs-tab p {\n    color: ", ";\n  }\n  .shell-tabs-tab-active p {\n    color: ", " !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.subtle;
}, function (_a) {
    var theme = _a.theme;
    return theme.text.onSurface.strong;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
