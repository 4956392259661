import { ButtonGroupSize } from './ButtonGroup.types';
import { Sizes } from '../../types/types';

type ButtonGroupConstsProps = {
  [size in ButtonGroupSize]: {
    height: number;
    paddings: number;
    borderRadius: number;
    icon: string;
  };
};

export const buttonGroupConsts: ButtonGroupConstsProps = {
  [Sizes.ExtraSmall]: {
    height: 24,
    paddings: 4,
    borderRadius: 2,
    icon: '14px',
  },
  [Sizes.Small]: {
    height: 32,
    paddings: 8,
    borderRadius: 2,
    icon: '16px',
  },
  [Sizes.Medium]: {
    height: 40,
    paddings: 12,
    borderRadius: 2,
    icon: '20px',
  },
  [Sizes.Large]: {
    height: 48,
    paddings: 16,
    borderRadius: 2,
    icon: '24px',
  },
};
