import { __assign, __spreadArray } from "tslib";
import dayjs from 'dayjs';
import { MAX_SERIES_LENGTH } from './capacity-trends.consts';
var getDates = function (offset, start, end, type) {
    var dates = [];
    var dateToPush;
    if (type === 'week') {
        dateToPush = dayjs(start).startOf('week').subtract(offset, 'day');
        var endOfWeekLastDate = dayjs(end).endOf('week').subtract(offset, 'day');
        while (dateToPush.isSameOrBefore(endOfWeekLastDate)) {
            dates.push(dateToPush.valueOf());
            dateToPush = dateToPush.add(1, type);
        }
        return dates;
    }
    if (type === 'year') {
        dateToPush = dayjs(start).startOf('year');
        var endYear = dayjs(end).endOf('year');
        while (dateToPush.isSameOrBefore(endYear)) {
            dates.push(dateToPush.valueOf());
            dateToPush = dateToPush.add(1, type);
        }
        return dates;
    }
    dateToPush = dayjs(start);
    while (dateToPush.isSameOrBefore(end)) {
        dates.push(dateToPush.valueOf());
        dateToPush = dateToPush.add(1, type);
    }
    return dates;
};
var getSeries = function (dateRange, colorSeries, data) {
    var series = [];
    var allSeries = [];
    if (data) {
        var _loop_1 = function (i) {
            var dateIndex = dateRange.findIndex(function (d) { return dayjs(d).isSame(data[i].eventDate); });
            var index = series.findIndex(function (seriesData) { return seriesData.name === data[i].entityName; });
            if (index !== -1) {
                series[index].data[dateIndex] = [dateRange[dateIndex], data[i].calculatedOfflineCapacity];
            }
            else {
                var seriesArray = dateRange.map(function (date) { return [date, 0]; });
                seriesArray[dateIndex] = [dateRange[dateIndex], data[i].calculatedOfflineCapacity];
                series.push({
                    type: 'column',
                    name: data[i].entityName,
                    data: seriesArray,
                });
            }
        };
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        series.sort(function (a, b) {
            return a.data.reduce(function (partialSum, a) { return partialSum + a[1]; }, 0) - b.data.reduce(function (partialSum, a) { return partialSum + a[1]; }, 0);
        });
        allSeries = __spreadArray([], series, true);
        if (series.length > MAX_SERIES_LENGTH) {
            var sumOfLastSeven = dateRange.map(function (date) { return [date, 0]; });
            for (var i = 0; i < sumOfLastSeven.length; i++) {
                sumOfLastSeven[i] = [dateRange[i], 0];
                for (var j = 0; j < series.length - MAX_SERIES_LENGTH; j++) {
                    sumOfLastSeven[i][1] += series[j].data[i][1];
                }
            }
            var seriesWithOthers = __spreadArray([
                { type: 'column', name: 'Others', data: sumOfLastSeven }
            ], series.slice(series.length - MAX_SERIES_LENGTH), true);
            return {
                series: seriesWithOthers.map(function (bar, i) { return (__assign(__assign({}, bar), { color: colorSeries[MAX_SERIES_LENGTH - i] })); }),
                allSeries: allSeries,
            };
        }
    }
    return {
        allSeries: allSeries,
        series: series.map(function (bar, i) { return (__assign(__assign({}, bar), { color: colorSeries[series.length - i - 1] })); }),
    };
};
var getChartLabelDetails = function (chartMode) {
    switch (chartMode.slice(-1)) {
        case 'D':
            return { headingText: 'Daily', type: 'day' };
        case 'W':
            return { headingText: 'Weekly', type: 'week' };
        case 'A':
            return { headingText: 'Weekly EiA', type: 'week' };
        case 'M':
            return { headingText: 'Monthly', type: 'month' };
        case 'Y':
        default:
            return { headingText: 'Yearly', type: 'year' };
    }
};
var getChartOptions = function (_a, columnClickHandler, columnIndex) {
    var background = _a.background, text = _a.text, border = _a.border;
    return ({
        time: {
            useUTC: false,
        },
        loading: {
            style: {
                backgroundColor: background.raised,
            },
            labelStyle: {
                color: text.onSurface.strong,
                top: '50px',
            },
        },
        chart: {
            height: '18%',
            style: {
                fontFamily: 'Shell Font',
                cursor: columnIndex !== undefined ? 'pointer' : 'default',
            },
            animation: false,
            backgroundColor: background.surface,
            type: 'column',
            events: {
                click: function () {
                    columnClickHandler();
                    this.series.forEach(function (series) {
                        series.data.forEach(function (point) {
                            var _a;
                            (_a = point.graphic) === null || _a === void 0 ? void 0 : _a.css({
                                opacity: 1,
                            });
                        });
                    });
                },
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'datetime',
            lineColor: border.medium,
            tickColor: border.medium,
            plotLines: [
                {
                    color: border.medium,
                    width: 1,
                    value: dayjs().valueOf(),
                    label: {
                        text: 'today',
                        style: {
                            color: text.onSurface.subtle,
                        },
                    },
                },
            ],
            dateTimeLabelFormats: {
                day: '%e %b',
                week: '%e %b',
                month: '%b %y',
                year: '%Y',
            },
            labels: {
                align: 'center',
                style: {
                    color: text.onSurface.subtle,
                },
            },
        },
        yAxis: {
            title: {
                style: {
                    color: text.onSurface.subtle,
                    fontWeight: '600',
                },
            },
            events: {
                afterSetExtremes: function (_a) {
                    var dataMax = _a.dataMax;
                    var unit = dataMax > 1000 ? 'M' : 'k';
                    var title = "<span style=\"color: ".concat(text.onSurface.strong, "\">Offline Capacity</span> ").concat(unit, "B/d");
                    this.setTitle({ text: title });
                },
            },
            labels: {
                style: {
                    color: text.onSurface.subtle,
                },
                formatter: function () {
                    var _a;
                    var max = (_a = this.axis.max) !== null && _a !== void 0 ? _a : 0;
                    var value = this.value.toString();
                    return max > 1000 ? (parseFloat(value) / 1000).toString() : value.toString();
                },
            },
            gridLineWidth: 0.2,
            gridLineColor: border.strong,
        },
        legend: {
            reversed: true,
            align: 'center',
            x: 0,
            verticalAlign: 'bottom',
            y: 0,
            floating: false,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 1,
            shadow: false,
            maxHeight: 100,
            itemHiddenStyle: {
                color: text.onSurface.inactive,
            },
            itemHoverStyle: {
                color: text.onSurface.strong,
            },
            itemStyle: {
                color: text.onSurface.subtle,
            },
        },
        tooltip: {
            xDateFormat: '%d %B %Y',
            backgroundColor: background.raised,
            borderColor: background.raised,
            useHTML: true,
            outside: true,
            padding: 0,
            shape: 'square',
            positioner: function (boxWidth, boxHeight, point) {
                var chart = this.chart, plotLeft = chart.plotLeft, chartWidth = chart.chartWidth, x = point.plotX + plotLeft + boxWidth > chartWidth
                    ? point.plotX + plotLeft - boxWidth - 10
                    : point.plotX + plotLeft + 10, y = chart.plotHeight + chart.plotTop - boxHeight - 10;
                return { x: x, y: y };
            },
            style: {
                color: text.onSurface.subtle,
            },
            formatter: function () {
                var _this = this;
                var _a;
                var series = __spreadArray([], this.series.chart.series, true).reverse();
                var result = "<div style=\"width: 300px;font-size: 14px; color: ".concat(text.onSurface.strong, "; font-weight: 600; padding: 16px 0 8px 16px\">").concat(dayjs(this.x).format('DD MMM YYYY'), "</div>");
                series.forEach(function (s) {
                    var _a, _b;
                    if (s !== _this.series) {
                        result +=
                            '<div style="padding: 4px 16px 0px 16px"><span style="color:' +
                                s.points[_this.point.index].color +
                                '">\u25A0</span><span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;width:150px;display:inline-block;vertical-align:middle;margin-left:5px">' +
                                s.name +
                                '</span><span style="float:right">' +
                                ((_a = s.points[_this.point.index].y) === null || _a === void 0 ? void 0 : _a.toFixed(2)) +
                                ' kB/d</span></div>';
                    }
                    else {
                        result +=
                            '<div style="padding: 4px 16px 0px 16px"><span style="color:' +
                                s.points[_this.point.index].color +
                                '">\u25A0</span><b><span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;width:150px;display:inline-block;vertical-align:middle;margin-left:5px;color:' +
                                text.onSurface.strong +
                                '"> ' +
                                s.name +
                                '</span><span style="color:' +
                                text.onSurface.strong +
                                ';float:right">' +
                                ((_b = s.points[_this.point.index].y) === null || _b === void 0 ? void 0 : _b.toFixed(2)) +
                                ' kB/d</span></b></div>';
                    }
                }, this);
                result += "<div style=\"color: ".concat(text.onSurface.strong, ";background: ").concat(background.surface, "; padding: 16px; margin-top: 16px; font-size: 14px; font-weight: 400\">Total offline <span style=\"float: right\">").concat((_a = this.point.total) === null || _a === void 0 ? void 0 : _a.toFixed(2), " kB/d</span></div>");
                return result;
            },
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                animation: false,
                cursor: 'pointer',
                states: {
                    inactive: {
                        opacity: 1,
                    },
                },
                point: {
                    events: {
                        click: function () {
                            columnClickHandler(this.index);
                            var hoveredPointIndex = this.index;
                            var chart = this.series.chart;
                            chart.series.forEach(function (series) {
                                series.data.forEach(function (point) {
                                    var _a, _b;
                                    if (point.index !== hoveredPointIndex) {
                                        (_a = point.graphic) === null || _a === void 0 ? void 0 : _a.css({
                                            opacity: 0.2,
                                        });
                                    }
                                    else {
                                        (_b = point.graphic) === null || _b === void 0 ? void 0 : _b.css({
                                            opacity: 1,
                                        });
                                    }
                                });
                            });
                        },
                    },
                },
            },
            column: {
                groupPadding: 0.1,
                pointPadding: 0.1,
                borderWidth: 0,
            },
        },
    });
};
var createDefaultTableData = function (data, breakdownBy) {
    if (breakdownBy === void 0) { breakdownBy = 'Region'; }
    var defaultData = [];
    var firstColumnName = breakdownBy;
    for (var i = 0; i < data.length; i++) {
        var entity = data[i];
        var columnObject = {};
        columnObject[firstColumnName] = entity.name;
        columnObject['Avg kBD'] = '';
        var total = 0;
        var entityColumnsWithData = entity.data;
        for (var j = 0; j < entityColumnsWithData.length; j++) {
            var columnName = entityColumnsWithData[j][0].toString();
            var columnValue = entityColumnsWithData[j][1];
            columnObject[columnName] = columnValue.toFixed(2);
            total += columnValue;
        }
        columnObject['Avg kBD'] = (total / entity.data.length).toFixed(2);
        defaultData.push(columnObject);
    }
    return defaultData;
};
export { getChartLabelDetails, getChartOptions, getDates, getSeries, createDefaultTableData };
