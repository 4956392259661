import { RadioButtonSize } from './RadioButton.types';
import { Sizes } from '../../types/types';

type RadioButtonConstsType = {
  [size in RadioButtonSize]: {
    size: number;
    labelSpacing: number;
    innerCircleSize: number;
  };
};

export const radioButtonConsts: RadioButtonConstsType = {
  [Sizes.ExtraSmall]: {
    size: 16,
    labelSpacing: 8,
    innerCircleSize: 6,
  },
  [Sizes.Small]: {
    size: 20,
    labelSpacing: 8,
    innerCircleSize: 6,
  },
  [Sizes.Medium]: {
    size: 24,
    labelSpacing: 10,
    innerCircleSize: 8,
  },
  [Sizes.Large]: {
    size: 28,
    labelSpacing: 12,
    innerCircleSize: 10,
  },
};
