import styled, { css } from 'styled-components';

import PectenSVG from './components/PectenIcon';
import { pectenSizes, pectenPaddings } from './Pecten.consts';
import { IPectenProps } from './Pecten.types';
import { Transient } from '../../types/types';

const Pecten = styled(PectenSVG)<
  Required<Pick<IPectenProps, 'size'>> & Transient<Pick<IPectenProps, 'emphasis' | 'padding'>, 'emphasis' | 'padding'>
>(({ prefixCls, size, $emphasis, $padding, theme }) => {
  const width = typeof size === 'number' ? size : pectenSizes[size];
  const margin = typeof size === 'number' || !$padding ? 0 : `${pectenPaddings[size][$padding]}`;

  return css`
    height: ${width}px;
    width: ${width}px;
    margin: ${margin}px;

    .${prefixCls}-border {
      fill: ${$emphasis ? 'white' : theme.text.onSurface.strong};
    }

    .${prefixCls}-outer-shell {
      fill: ${$emphasis ? '#DD1D20' : theme.text.onSurface.strong};
    }

    .${prefixCls}-inner-shell {
      fill: ${$emphasis ? '#FBCF09' : 'transparent'};
    }
  `;
});

export { Pecten };
