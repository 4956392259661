import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FilterContainer from './filterContainter';
import AssetListGrid from './assetListGrid';
import Spinner from '../../../../../commons/spinner';
import { ApiProvider } from './apiContext';
import AssetConfigTabContainer from './assetConfigTabContainer';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { deleteSourceData } from '../../../../../services/urlService';
import './assetconfig.css';
var AssetConfiguration = function (props) {
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState(false), showOriginal = _b[0], setShowOriginal = _b[1];
    var _c = useState(false), visible = _c[0], visiblePopup = _c[1];
    var _d = useState(false), isNewUnit = _d[0], setIsNewUnit = _d[1];
    var _e = useState(false), deleteConfirmation = _e[0], setDeleteConfirmation = _e[1];
    var _f = useState(null), idToDelete = _f[0], setIdToDelete = _f[1];
    var _g = useState(false), shouldGridRefresh = _g[0], setShouldGridRefresh = _g[1];
    var _h = useState(-1), deleteAsset = _h[0], setDeleteAsset = _h[1];
    var onAddNewUnit = function () {
        visiblePopup(true);
        setIsNewUnit(true);
    };
    var onAddEditClose = function () {
        visiblePopup(false);
        setIsNewUnit(false);
    };
    var onDelete = function (id) {
        setDeleteConfirmation(true);
        setIdToDelete(id);
    };
    var onConfirmDelete = function () {
        setDeleteConfirmation(false);
        setLoader(true);
        deleteSourceData(idToDelete, 'assets')
            .then(function (res) {
            setLoader(false);
            setDeleteAsset(idToDelete);
        })
            .catch(function (err) {
            setLoader(false);
        });
    };
    return (_jsxs(ApiProvider, { children: [loader && _jsx(Spinner, {}), _jsx("div", __assign({ className: "row ml-0 mr-0 mt-3 mb-3" }, { children: _jsx("div", __assign({ className: "container-fluid" }, { children: _jsx("div", __assign({ className: "pull-left page-title" }, { children: _jsx("h3", { children: "Asset Configuration" }) })) })) })), _jsxs("div", __assign({ className: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100", id: "assetUnitConfig" }, { children: [_jsx("div", __assign({ className: "filter" }, { children: _jsx(FilterContainer, { setLoader: setLoader, shouldGridRefresh: shouldGridRefresh, deleteAsset: deleteAsset }) })), _jsx("div", __assign({ style: {
                            backgroundColor: '#282828',
                            borderTop: '1px solid #595959',
                            height: '55px',
                        } }, { children: _jsxs("button", __assign({ className: "pull-left p-3 on-add-new-unit", onClick: onAddNewUnit }, { children: [_jsx("i", { className: "k-icon k-i-plus-outline", style: { color: '#7f7f7f', fontSize: '20px' } }), _jsx("span", __assign({ className: "pl-2", style: { color: '#A6A6A6', fontSize: '14px' } }, { children: "New Asset" }))] })) })), _jsx(AssetListGrid, { showOriginal: showOriginal, showAddEditPopup: visiblePopup, isAddEditPopupVisible: visible, onDelete: onDelete })] })), visible && (_jsx("div", __assign({ id: "assetconfigwindow" }, { children: _jsx(Dialog, __assign({ width: '65%', title: 'Add/Edit Asset Configuration', onClose: onAddEditClose }, { children: _jsx(AssetConfigTabContainer, { isNewUnit: isNewUnit, onClose: onAddEditClose, refreshGrid: setShouldGridRefresh, setDeleteAsset: setDeleteAsset }) })) }))), deleteConfirmation && (_jsx("div", __assign({ id: "delete-confirmation" }, { children: _jsxs(Dialog, __assign({ title: 'Confirmation', onClose: function () { return setDeleteConfirmation(false); } }, { children: [_jsx("div", __assign({ className: "my-4 mx-2" }, { children: "Are you sure you want to continue?" })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: function () { return setDeleteConfirmation(false); } }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: onConfirmDelete }, { children: "Yes" }))] })] })) })))] }));
};
export default AssetConfiguration;
