import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { connect } from 'react-redux';
import * as masterScreenActions from '../redux/actions/masterScreenActions';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
var initialState = null;
var gridDropDownCell = /** @class */ (function (_super) {
    __extends(gridDropDownCell, _super);
    function gridDropDownCell(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.loadSubSetData = function (props) {
            if (typeof props.masterData !== 'undefined') {
                if (typeof props.masterData.get(props.className.split('-')[0]) !== 'undefined') {
                    var data = _this.props.masterData.get(_this.props.className.split('-')[0]).slice(0, 12);
                    return data;
                }
                else {
                    return [];
                }
            }
            else {
                return '';
            }
        };
        //Function to load conditional value for dropdown depending on respective indicator (Y/N)
        _this.loadDataOnCondition = function (data, className, filterInd) {
            if (typeof data !== 'undefined') {
                var filterDropDown = _.filter(data.get(className.split('-')[0]), function (item) {
                    return item[filterInd] === 'Y';
                });
                return filterDropDown;
            }
        };
        _this.handleChange = function (e) {
            //IF Block - To clear DataSet dropdown value when user select Data Provider dropdown values in the same record
            if (_this.props.className.split('-')[7].replace(/\s+/g, '') !== 'noDependent') {
                if (typeof _this.props.dataItem.id !== 'undefined') {
                    var element = document.getElementById(_this.props.dataItem.id.toString());
                    var spanElm = element.getElementsByClassName('k-input');
                    spanElm[0].innerHTML = '';
                }
                else {
                    var element = document.getElementById('noID');
                    var spanElm = element.getElementsByClassName('k-input');
                    spanElm[0].innerHTML = '';
                }
            }
            _this.props.onChange({
                dataItem: _this.props.dataItem,
                field: _this.props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.id,
            });
            _this.setState({
                currentValue: e.target.value[_this.props.className.split('-')[1]],
            });
        };
        _this.handleYesNoChange = function (e) {
            _this.ynFlag = true;
            _this.props.onChange({
                dataItem: _this.props.dataItem,
                field: _this.props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.id,
            });
            _this.setState({
                yesNoCurrentValue: e.target.value,
            });
        };
        _this.componentDidMount = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                try {
                    if (this.props.className.split('-')[2].replace(/\s+/g, '') === 'yn') {
                        data = [
                            { id: 'Y', title: 'Yes' },
                            { id: 'N', title: 'No' },
                        ];
                        this.setState({
                            data: data,
                        });
                    }
                }
                catch (error) {
                    return [2 /*return*/];
                }
                return [2 /*return*/];
            });
        }); };
        _this.foreginKeyCd = function (dataItem) {
            //loop for normal screen which doesn't have two forigen key with same data
            if (_this.props.foreignReplicaScreen !== 'replica' &&
                _this.props.className.split('-')[9].replace(/\s+/g, '') === 'noUseId') {
                try {
                    if (_this.props.foreginKeyParams.length > 0 && dataItem.events !== 'add') {
                        var Cd = _this.props.foreginKeyParams.map(function (value) {
                            if (_this.props.className.split('-')[0] === value.path) {
                                var dataItemFrKyVal = dataItem[value.path];
                                var cdReturn = dataItemFrKyVal[value.cdName];
                                return cdReturn;
                            }
                            else {
                                return null;
                            }
                        });
                        return Cd;
                    }
                    else {
                        return null;
                    }
                }
                catch (error) {
                    return null;
                }
            }
            else if (_this.props.foreignReplicaScreen !== 'replica' &&
                _this.props.className.split('-')[9].replace(/\s+/g, '') === 'useId') {
                try {
                    if (_this.props.foreginKeyParams.length > 0 && dataItem.events !== 'add') {
                        var Cd = _this.props.foreginKeyParams.map(function (value) {
                            if (_this.props.className.split('-')[0] === value.path) {
                                var dataItemFrKyVal = dataItem[value.path];
                                var cdReturn = dataItemFrKyVal.id;
                                return cdReturn;
                            }
                            else {
                                return null;
                            }
                        });
                        return Cd;
                    }
                    else {
                        return null;
                    }
                }
                catch (error) {
                    return null;
                }
            }
            else {
                //loop for replica screen which do have two forigen key with same data
                try {
                    if (_this.props.foreginKeyParams.length > 0 && dataItem.events !== 'add') {
                        var Cd = _this.props.foreginKeyParams.map(function (value) {
                            if (_this.props.className.split('-')[4].replace(/\s+/g, '') === value.replicaPath) {
                                if (typeof value.foreignReplica !== 'undefined' && value.foreignReplica) {
                                    var index = _this.props.masterData
                                        .get(value.path)
                                        .findIndex(function (x) { return x.id === dataItem[value.foreignReplicaKey]; });
                                    var cd = _this.props.masterData.get(value.path)[index][value.cdName];
                                    return cd;
                                }
                                else {
                                    var dataItemFrKyVal = dataItem[value.path];
                                    var cdReturn = dataItemFrKyVal[value.cdName];
                                    return cdReturn;
                                }
                            }
                            else {
                                return null;
                            }
                        });
                        return Cd;
                    }
                    else {
                        return null;
                    }
                }
                catch (error) {
                    return null;
                }
            }
        };
        //common function to return current value(Ref.by :currentMatchedValue)
        _this.currentValueCommonCheck = function (value) {
            if (typeof _this.props.masterData.get(_this.props.className.split('-')[0]) !== 'undefined' &&
                _this.props.className.split('-')[9].replace(/\s+/g, '') === 'noUseId') {
                var currentValue = _this.props.masterData
                    .get(_this.props.className.split('-')[0])
                    .find(function (c) { return c[_this.props.className.split('-')[1]] === value; });
                return currentValue;
            }
            else {
                if (typeof _this.props.masterData.get(_this.props.className.split('-')[0]) !== 'undefined') {
                    var currentValue = _this.props.masterData.get(_this.props.className.split('-')[0]).find(function (c) {
                        return c.id.toString() === value;
                    });
                    return currentValue;
                }
            }
        };
        _this.currentMatchedValue = function (dataValue, events) {
            if (_this.props.masterData !== 'undefined') {
                if (events !== 'add') {
                    if (_this.state.currentValue) {
                        var currentValue = _this.currentValueCommonCheck(_this.state.currentValue);
                        return currentValue;
                    }
                    else {
                        var currentValue = _this.currentValueCommonCheck(dataValue);
                        return currentValue;
                    }
                }
                else {
                    var currentValue = _this.currentValueCommonCheck(_this.state.currentValue);
                    return currentValue;
                }
            }
            else {
                var currentValue = _this.currentValueCommonCheck(_this.state.currentValue);
                return currentValue;
            }
        };
        _this.currentMatchedValueYesNO = function (dataValue, events) {
            if (_this.props.masterData !== 'undefined') {
                if (events !== 'add') {
                    if (_this.state.yesNoCurrentValue) {
                        var currentValue = _this.state.yesNoCurrentValue;
                        return currentValue;
                    }
                    else {
                        var currentValue = { title: dataValue };
                        return currentValue;
                    }
                }
                else {
                    var currentValue = _this.state.yesNoCurrentValue;
                    return currentValue;
                }
            }
            else {
                var currentValue = { title: dataValue };
                return currentValue;
            }
        };
        //Function to return existing Y (Yes) / N (No) value eg: ShelllegalEntity column from company table
        _this.yesNoDataValue = function (dataItem) {
            try {
                if (_this.props.yesNoData.length > 0 && dataItem.events !== 'add') {
                    var yesNO = _this.props.yesNoData.map(function (value) {
                        if (_this.state.className.split('-')[3].replace(/\s+/g, '') === value.ynValue) {
                            var dataItemYn = dataItem[value.ynValue];
                            return dataItemYn;
                        }
                        else {
                            return null;
                        }
                    });
                    return yesNO;
                }
                else {
                    return null;
                }
            }
            catch (error) {
                return null;
            }
        };
        _this.loadingCell = function () {
            return (_jsx("td", { children: _jsx("span", { className: "k-icon k-i-loading" }) }));
        };
        _this.pageChange = function (event) {
            var skip = event.page.skip;
            var take = event.page.take;
            var newSubsetData = _this.props.masterData.get(_this.props.className.split('-')[0]).slice(skip, skip + take);
            //Below check is to handle if requested dropdown is conditional by indicator (Y/N)
            if (_this.props.className.split('-')[5].replace(/\s+/g, '') !== 'noFilterInd') {
                var indType_1 = _this.props.className.split('-')[5].replace(/\s+/g, '');
                var filterDropDown = _.filter(_this.props.masterData.get(_this.props.className.split('-')[0]), function (item) {
                    return item[indType_1] === 'Y';
                });
                _this.setState({
                    subsetData: filterDropDown,
                    skip: skip,
                });
            }
            else {
                _this.setState({
                    subsetData: newSubsetData,
                    skip: skip,
                });
            }
        };
        _this.itemRender = function (li, itemProps) {
            /*If -- (IF Column resize happens ) else -- (If Column resize doesn't happen)
            Here assummed Grid column default size would be 200-250*/
            if (_this.props.resizeParam !== undefined && _this.props.newColumnWidth > 250) {
                return React.cloneElement(li, li.props);
            }
            else {
                var itemChildren = (_jsx("span", __assign({ style: { textAlign: 'left' } }, { children: _this.truncateWithEllipses(li.props.children, '17') })));
                return React.cloneElement(li, li.props, itemChildren);
            }
        };
        //Function to give filtered data based on key passed
        _this.fetchLoadData = function (Key) {
            if (typeof _this.props.masterData !== 'undefined') {
                if (typeof _this.props.masterData.get(_this.props.className.split('-')[0].replace(/\s+/g, '')) !== 'undefined') {
                    var keyAttribute_1 = _this.props.className.split('-')[6].replace(/\s+/g, '');
                    var filterDropDown = _.filter(_this.props.masterData.get(_this.props.className.split('-')[0].replace(/\s+/g, '')), function (item) {
                        return item[keyAttribute_1] === Key;
                    });
                    _this.setState({
                        subsetData: filterDropDown,
                    });
                }
                else {
                    return [];
                }
            }
            else {
                return '';
            }
        };
        //This callback to filter dropdwon values based on other dropdown values
        //Eg. Fetch Datasets based on selected Dataprovider
        _this.onOpen = function (event) {
            if (_this.props.className.split('-')[6].replace(/\s+/g, '') !== 'noDependency') {
                if (typeof _this.props.dataItem[_this.props.className.split('-')[8].replace(/\s+/g, '')] === 'string') {
                    _this.fetchLoadData(_this.props.dataItem[_this.props.className.split('-')[6].replace(/\s+/g, '')]);
                }
                else {
                    _this.fetchLoadData(_this.props.dataItem[_this.props.className.split('-')[8].replace(/\s+/g, '')]);
                }
            }
        };
        _this.state = {
            data: [],
            currentValue: initialState,
            yesNoCurrentValue: null,
            className: _this.props.className,
            masterDataLength: typeof _this.props.masterData !== 'undefined'
                ? typeof _this.props.masterData.get(_this.props.className.split('-')[0]) !== 'undefined'
                    ? _this.props.masterData.get(_this.props.className.split('-')[0]).length
                    : []
                : '',
            pageSize: 12,
            subsetData: _this.props.className.split('-')[5].replace(/\s+/g, '') !== 'noFilterInd'
                ? _this.loadDataOnCondition(_this.props.masterData, _this.props.className, _this.props.className.split('-')[5].replace(/\s+/g, ''))
                : _this.loadSubSetData(_this.props),
            skip: 0,
        };
        return _this;
    }
    gridDropDownCell.prototype.reset = function () {
        this.setState({ currentValue: initialState });
    };
    gridDropDownCell.prototype.truncateWithEllipses = function (text, max) {
        return text.substr(0, max - 1) + (text.length > max ? '...' : '');
    };
    // filterChange = (event) => {
    //   this.setState({
    //     subsetData: this.filterData(event.filter),
    //   });
    // };
    // filterData(filter) {
    //   const data = allData.slice();
    //   return filterBy(data, filter);
    // }
    gridDropDownCell.prototype.render = function () {
        var _a;
        var dataValue = this.foreginKeyCd(this.props.dataItem) === null
            ? ''
            : this.foreginKeyCd(this.props.dataItem).toString().replace(/,/g, '');
        var datavalueYn = this.yesNoDataValue(this.props.dataItem) === null
            ? ''
            : this.yesNoDataValue(this.props.dataItem).toString().replace(/,/g, '');
        var defaultItemKey = this.props.className.split('-')[1];
        var defaultItemObject = (_a = {},
            _a[defaultItemKey] = 'Select...',
            _a.id = -1,
            _a);
        return (_jsx("td", __assign({ id: this.props.className.split('-')[6].replace(/\s+/g, '') !== 'noDependency'
                ? typeof this.props.dataItem.id !== 'undefined'
                    ? this.props.dataItem.id.toString()
                    : 'noID'
                : this.props.dataItem.randomEditInd }, { children: this.props.className.split('-')[2].replace(/\s+/g, '') == 'yn' ? (this.props.dataItem.inEdit ? (_jsx(DropDownList, { style: { width: '100%' }, onChange: this.handleYesNoChange, value: this.currentMatchedValueYesNO(datavalueYn, this.props.dataItem.events), data: this.state.data, textField: "title" })) : (datavalueYn)) : this.props.dataItem.inEdit ? (typeof this.props.masterData !== 'undefined' ? ((this.props.className.split('-')[5].replace(/\s+/g, '') !== '' && this.state.subsetData.length < 12) ||
                (this.props.className.split('-')[6].replace(/\s+/g, '') !== 'noDependency' &&
                    this.props.className.split('-')[9].replace(/\s+/g, '') === 'noUseId') ? (_jsx(DropDownList, { style: { width: '100%' }, onChange: this.handleChange, value: this.currentMatchedValue(dataValue, this.props.dataItem.events), data: this.state.subsetData, itemRender: this.itemRender, onOpen: this.onOpen, defaultItem: defaultItemObject, textField: this.props.className.split('-')[1] })) : this.props.className.split('-')[9].replace(/\s+/g, '') === 'noUseId' ? (_jsx(DropDownList, { style: { width: '100%' }, onChange: this.handleChange, value: this.currentMatchedValue(dataValue, this.props.dataItem.events), virtual: {
                    total: this.state.masterDataLength,
                    pageSize: this.state.pageSize,
                    skip: this.state.skip,
                }, data: this.state.subsetData, onPageChange: this.pageChange, itemRender: this.itemRender, defaultItem: defaultItemObject, textField: this.props.className.split('-')[1] })) : (_jsx(DropDownList, { style: { width: '100%' }, onChange: this.handleChange, value: this.currentMatchedValue(dataValue, this.props.dataItem.events), virtual: {
                    total: this.state.masterDataLength,
                    pageSize: this.state.pageSize,
                    skip: this.state.skip,
                }, data: this.state.subsetData, onPageChange: this.pageChange, itemRender: this.itemRender, 
                //filterable={true}
                //onFilterChange={this.filterChange}
                //defaultItem={defaultItemObject}
                textField: "id" }))) : (this.loadingCell)) : (dataValue) }), this.props.dataItem.randomEditInd));
    };
    gridDropDownCell.flagDrop = 0;
    gridDropDownCell.getDerivedStateFromProps = function (props, state) {
        if (!props.dataItem.inEdit) {
            return {
                currentValue: null,
                yesNoCurrentValue: null,
            };
        }
        return null;
    };
    return gridDropDownCell;
}(Component));
function mapStateToProps(state) {
    return {
        masterData: state.masterScreenReducer.masterData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(masterScreenActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(gridDropDownCell);
