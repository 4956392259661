import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
export var maintenanceDailySummarySlice = createSlice({
    name: 'maintenanceDailySummary',
    initialState: initialState,
    reducers: {
        setSidebarOpenState: function (state, action) {
            state.isSidebarOpen = action.payload;
        },
    },
});
export var setSidebarOpenState = maintenanceDailySummarySlice.actions.setSidebarOpenState;
export var selectIsSidebarOpen = function (state) {
    return state.maintenanceDailySummary.isSidebarOpen;
};
