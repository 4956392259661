import { ChangeEventHandler, KeyboardEventHandler, forwardRef, useImperativeHandle, useRef } from 'react';

import * as Styled from '../../ButtonGroup.styles';
import { IRadioButtonGroupButtonProps } from './RadioButton.types';
import { useGetProps } from '../../hooks/useGetProps';
import { Sizes, Positions } from '../../../../types/types';

const RadioButton = forwardRef<HTMLInputElement, IRadioButtonGroupButtonProps>(
  (
    {
      size = Sizes.Medium,
      selectedKey,
      disabled = false,
      onChange,
      value,
      label,
      icon,
      iconPosition = Positions.Left,
      iconOnly = false,
      emphasis,
      buttonProps,
      ...rest
    },
    ref,
  ) => {
    const { children, tabIndex } = useGetProps(label, icon, iconPosition, iconOnly, disabled);

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef?.current as HTMLInputElement);

    const handleOnKeyDown: KeyboardEventHandler = (e) => {
      if (e.code === 'Enter' && inputRef?.current) {
        inputRef.current.click();
      }
    };

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(e.target.id, e);
    };

    return (
      <Styled.ButtonGroupLabel
        size={size}
        type="label"
        htmlFor={value}
        onKeyDown={handleOnKeyDown}
        checked={selectedKey === value}
        disabled={disabled}
        iconOnly={iconOnly}
        iconPosition={iconPosition}
        emphasis={emphasis}
        tabIndex={tabIndex}
        {...buttonProps}
      >
        {children}
        <Styled.ButtonGroupRadio
          type="radio"
          id={value}
          value={value}
          checked={selectedKey === value}
          disabled={disabled}
          onChange={handleOnChange}
          ref={inputRef}
          {...rest}
        />
      </Styled.ButtonGroupLabel>
    );
  },
);

export default RadioButton;
