import { __assign, __extends } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
var deleteConfirmBox = /** @class */ (function (_super) {
    __extends(deleteConfirmBox, _super);
    function deleteConfirmBox(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleDialog = function () {
            _this.setState({
                visible: !_this.state.visible,
            }, function () {
                if (!_this.state.visible) {
                    document.querySelector('.k-state-selected').classList.remove('k-state-selected');
                }
            });
        };
        _this.onConfirmAction = function () {
            _this.props.onRemove(_this.props.data);
            _this.setState({
                visible: !_this.state.visible,
            });
            document.querySelector('.k-state-selected').classList.remove('k-state-selected');
        };
        _this.removeSelected = function () {
            _this.props.removeSelected();
            _this.setState({
                visible: !_this.state.visible,
            });
        };
        _this.state = {
            visible: false,
        };
        _this.toggleDialog = _this.toggleDialog.bind(_this);
        return _this;
    }
    deleteConfirmBox.prototype.render = function () {
        var gridContent = document.body;
        return (this.state.visible &&
            ReactDOM.createPortal(_jsxs(Dialog, __assign({ title: 'Please confirm', onClose: this.toggleDialog }, { children: [_jsxs("p", __assign({ style: { margin: '25px', textAlign: 'center' } }, { children: ["Are you sure want to delete", '  ' + this.props.data[this.props.uniquecode], " ?"] })), _jsxs(DialogActionsBar, { children: [_jsx("button", __assign({ className: "k-button", onClick: this.removeSelected }, { children: "No" })), _jsx("button", __assign({ className: "k-button", onClick: this.onConfirmAction }, { children: "Yes" }))] })] })), gridContent));
    };
    return deleteConfirmBox;
}(Component));
export default deleteConfirmBox;
