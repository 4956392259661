import { ReactNode, ComponentPropsWithoutRef } from 'react';

import { Table, Row } from '@tanstack/react-table';

import { ButtonSize } from '../../../Button/Button.types';
import { Sentiments, DeprecatedSentiments } from '../../../../types/types';
import { TableSize } from '../../Table.types';

export interface ITableComponentsProps<T> {
  table: Table<T>;
}

export interface ITableWrapperProps extends ComponentPropsWithoutRef<'div'> {
  maxHeight?: number;
  maxWidth?: number;
  stickyColumns?: boolean;
  stickyRows?: boolean;
  virtualized?: boolean;
}

export interface ITableRowProps {
  size?: TableSize;
  isSelected?: boolean;
  sentiment?:
    | Sentiments.Warning
    | Sentiments.Information
    | Sentiments.Neutral
    | Sentiments.Positive
    | Sentiments.Negative
    | Sentiments.Default
    | DeprecatedSentiments;
  sticky?: boolean;
  stickyType?: StickyTypeCell;
  depth?: number;
}

export interface IDraggableRowProps<T> {
  row: Row<T>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
}

export interface IExpanderProps<T> {
  row: Row<T>;
  children?: ReactNode | ReactNode[];
}

export interface ITableCellExpanderContainerProps {
  size?: ButtonSize;
  depth: number;
  lastChild?: boolean;
}

export interface IFilterProps {
  filters?: ReactNode;
}

export enum StickyTypeCell {
  Header = 'header',
  Footer = 'footer',
  Column = 'column',
}

export interface ITableCellProps extends ComponentPropsWithoutRef<'td'> {
  colSpan?: number;
  sticky?: boolean;
  stickyType?: StickyTypeCell;
  startingPoint?: number;
}
