import { __makeTemplateObject } from "tslib";
import styled from 'styled-components';
import { StyledModal } from 'components/notifications-modal/NotificationsModal.styles';
var StyledConfigurationModal = styled(StyledModal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .shell-modal-container-body {\n    background-color: ", ";\n  }\n  .shell-modal-container-wrap {\n    overflow: hidden;\n  }\n"], ["\n  .shell-modal-container-body {\n    background-color: ", ";\n  }\n  .shell-modal-container-wrap {\n    overflow: hidden;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.raised;
});
var StyledColumnContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 16px;\n  max-height: 40vh;\n  overflow: auto;\n"], ["\n  background-color: ", ";\n  padding: 16px;\n  max-height: 40vh;\n  overflow: auto;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
export { StyledColumnContainer, StyledConfigurationModal };
var templateObject_1, templateObject_2;
