import { __makeTemplateObject } from "tslib";
import styled, { css } from 'styled-components';
export var StyledSidebarContentWrapper = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 0;\n  overflow: hidden;\n  transition: all 0.1s ease;\n  border-right: 1px solid ", ";\n  height: 90%;\n"], ["\n  width: 0;\n  overflow: hidden;\n  transition: all 0.1s ease;\n  border-right: 1px solid ", ";\n  height: 90%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.subtle;
});
export var StyledSidebarBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 20px 0;\n  width: ", "px;\n  height: 100%;\n"], ["\n  padding: 20px 0;\n  width: ", "px;\n  height: 100%;\n"])), function (_a) {
    var size = _a.size;
    return size;
});
// FIXME: REMOVE THE H3 MARGIN-BOTTOM WHEN global.less GETS DELETED
export var StyledSidebarHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 28px;\n  height: 40px;\n  padding: 0 32px;\n\n  h3 {\n    margin-bottom: 0;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 28px;\n  height: 40px;\n  padding: 0 32px;\n\n  h3 {\n    margin-bottom: 0;\n  }\n"])));
export var StyledSidebarContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: calc(100% - 50px);\n  overflow-y: scroll;\n  padding: 5px 28px 0px 28px;\n"], ["\n  width: 100%;\n  height: calc(100% - 50px);\n  overflow-y: scroll;\n  padding: 5px 28px 0px 28px;\n"])));
export var StyledSidebarOpener = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 64px;\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding-top: 12px;\n"], ["\n  width: 64px;\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding-top: 12px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
});
export var StyledSidebar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  ", " {\n    ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  ", " {\n    ", ";\n  }\n"])), StyledSidebarContentWrapper, function (_a) {
    var open = _a.open, size = _a.size;
    return open && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        width: ", "px;\n      "], ["\n        width: ", "px;\n      "])), size);
});
export var StyledContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 20px 32px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n"], ["\n  padding: 20px 32px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n"])));
export var StyledWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n"], ["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
