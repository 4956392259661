import { ReactElement } from 'react';

import { modalConsts } from '../../Modal.consts';
import * as Styled from './TitleBar.styles';
import { ITitleBarProps } from './TitleBar.types';

const TitleBar = ({
  title,
  description,
  headerAreaComponent,
  size,
  padding,
  descriptionPosition,
}: ITitleBarProps): ReactElement => (
  <Styled.TitleBar size={size}>
    <Styled.TextWrapper
      descriptionPosition={descriptionPosition}
      size={size}
      padding={padding}
      haveAreaComponent={!!headerAreaComponent}
    >
      {title && (
        <Styled.Title level={modalConsts[size].titleLevel}>
          <strong>{title}</strong>
        </Styled.Title>
      )}
      {description && <Styled.Description size={modalConsts[size].descriptionSize}>{description}</Styled.Description>}
    </Styled.TextWrapper>
    {headerAreaComponent && (
      <Styled.ComponentWrapper size={size} padding={padding}>
        {headerAreaComponent}
      </Styled.ComponentWrapper>
    )}
  </Styled.TitleBar>
);

export default TitleBar;
