import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../commons/crossReferenceGridTemplate';
var dataSet = /** @class */ (function (_super) {
    __extends(dataSet, _super);
    function dataSet(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            datasetNm: {
                required: true,
                error: 'Enter Dataset Name .',
            },
        };
        var yesNoData = [
            { ynValue: 'sensitivityClassificationInd' },
            { ynValue: 'csiClassificationInd' },
            { ynValue: 'localizationClassificationInd' },
            { ynValue: 'soxClassificationInd' },
            { ynValue: 'gdprClassificationInd' },
            { ynValue: 'shellInternalSourceInd' },
        ];
        var foreginKeyParams = [
            {
                path: 'dataProvider',
                key: 'dataProviderKey',
                cdName: 'dataProviderAbbr',
            },
            {
                path: 'datasetType',
                key: 'datasetTypeKey',
                cdName: 'datasetTypeCd',
            },
            {
                path: 'dataProviderService',
                key: 'dataProviderServiceKey',
                cdName: 'serviceNm',
            },
            {
                path: 'periodType',
                key: 'periodTypeKey',
                cdName: 'periodTypeCd',
            },
            {
                path: 'timeZone',
                key: 'timeZoneKey',
                cdName: 'timeZoneCd',
            },
            {
                path: 'measureUnit',
                key: 'defaultMeasureUnitKey',
                cdName: 'measureUnitCd',
            },
            {
                path: 'person',
                key: 'itOwnerKey',
                cdName: 'shellUserId',
            },
            {
                path: 'person',
                key: 'dataDefinitionOwnerKey',
                cdName: 'shellUserId',
            },
            {
                path: 'person',
                key: 'dataValueOwnerKey',
                cdName: 'shellUserId',
            },
        ];
        var columns = [
            {
                field: 'contactAdGroupNm',
                title: 'Contact Ad Group Nm',
            },
            {
                field: 'maximumRetentionMos',
                title: 'Maximum Retention Mos',
            },
            {
                field: 'minmumRetentionMos',
                title: 'Minmum Retention Mos',
            },
            {
                field: 'sensitivityClassificationInd',
                title: 'Sensitivity Classification Ind',
                type: 'yesNo',
                ynRefer: 'sensitivityClassificationInd',
                yn: 'yn',
            },
            {
                field: 'csiClassificationInd',
                title: 'Csi Classification Ind',
                type: 'yesNo',
                ynRefer: 'csiClassificationInd',
                yn: 'yn',
            },
            {
                field: 'localizationClassificationInd',
                title: 'Localization Classification Ind',
                type: 'yesNo',
                ynRefer: 'localizationClassificationInd',
                yn: 'yn',
            },
            {
                field: 'soxClassificationInd',
                title: 'Sox Classification Ind',
                type: 'yesNo',
                ynRefer: 'soxClassificationInd',
                yn: 'yn',
            },
            {
                field: 'gdprClassificationInd',
                title: 'Gdpr Classification Ind',
                type: 'yesNo',
                ynRefer: 'gdprClassificationInd',
                yn: 'yn',
            },
            {
                field: 'securityClassificationExpirationDt',
                title: 'Security Classification Expiration Dt',
            },
            {
                field: 'securityClassificationTypeKey',
                title: 'Security Classification Type Key',
            },
            {
                field: 'restrictionReasonTypeKey',
                title: 'RestrictionReason Type Key',
            },
            {
                field: 'shellInternalSourceInd',
                title: 'Shell Internal Source Ind',
                type: 'yesNo',
                ynRefer: 'shellInternalSourceInd',
                yn: 'yn',
            },
            {
                field: 'frequencyCnt',
                title: 'Frequency Cnt',
            },
            {
                field: 'periodTypeCd',
                title: 'Period Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'periodTypeCd',
                referenceTitle: 'Period Type',
                urlEndPoint: 'periodType',
            },
            {
                field: 'timeZoneCd',
                title: 'Time Zone Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'timeZoneCd',
                referenceTitle: 'Time Zone',
                urlEndPoint: 'timeZone',
            },
            {
                field: 'datasetTypeCd',
                title: 'Dataset Type Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'datasetTypeCd',
                referenceTitle: 'Dataset Type',
                urlEndPoint: 'datasetType',
            },
            {
                field: 'measureUnitCd',
                title: 'Default Measure Unit Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'measureUnitCd',
                referenceTitle: 'Measure Unit',
                urlEndPoint: 'measureUnit',
            },
            {
                field: 'datasetUsageDesc',
                title: 'Dataset Usage Desc',
            },
            {
                field: 'shellUserId',
                title: 'IT Owner Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
            },
            {
                field: 'shellUserId',
                title: 'Data Value Owner Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
            },
            {
                field: 'shellUserId',
                title: 'Data Definition Owner Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'shellUserId',
                referenceTitle: 'Shell User Id',
                urlEndPoint: 'person',
            },
            {
                field: 'datasetNm',
                title: 'Dataset Name',
            },
            {
                field: 'datasetAbbr',
                title: 'Dataset Abbrevation',
            },
            {
                field: 'serviceNm',
                title: 'Data Provider Service Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'serviceNm',
                referenceTitle: 'Service Name',
                urlEndPoint: 'dataProviderService',
            },
            {
                field: 'dataProviderAbbr',
                title: 'Data Provider',
                type: 'foreignKeyConstraint',
                referenceCd: 'dataProviderAbbr',
                referenceTitle: 'Data Provider',
                urlEndPoint: 'dataProvider',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
            yesNoData: yesNoData,
        };
        return _this;
    }
    dataSet.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "dataSet", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "datasetNm", foreginKeyParams: this.state.foreginKeyParams, yesNoData: this.state.yesNoData }));
    };
    return dataSet;
}(Component));
export default dataSet;
