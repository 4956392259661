var moment = require('moment');
export var filterContext = {
    filter: {},
    refineryFilterSelection: {
        countries: [],
        regions: [],
        startDate: moment(new Date('JAN').setFullYear(new Date().getFullYear() - 1)).format('MMM YYYY'),
        endDate: moment(new Date(new Date().getFullYear(), 11, 31)).format('MMM YYYY'),
        matType: null,
    },
};
