import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Toggle } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { AssetsList } from './components/assets-list';
import { PanelRow } from './components/panel-row';
import { StyledActions, StyledDay, StyledDays, StyledDivider, StyledHeader, StyledPanel, StyledPanelWrapper, } from './MaintenancePanel.styles';
import { StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
import { MonthPicker } from 'components/month-picker';
export var MaintenancePanel = function (_a) {
    var data = _a.data, _b = _a.monthDate, monthDate = _b === void 0 ? dayjs().format('YYYY-MM-DD') : _b, _c = _a.onMonthChange, onMonthChange = _c === void 0 ? noop : _c, hideExpected = _a.hideExpected, toggleExpected = _a.toggleExpected;
    return (_jsxs("div", __assign({ style: { width: '100%', maxHeight: '70vh', overflow: 'auto' } }, { children: [_jsxs(StyledHeader, { children: [_jsx(StyledActions, { children: _jsx(MonthPicker, { monthlySkip: true, value: monthDate, onMonthChange: onMonthChange }) }), _jsxs(StyledActions, { children: [_jsx(StyledLabel, __assign({ size: "medium" }, { children: "Hide expected" })), ' ', _jsx(Toggle, { defaultChecked: hideExpected, onClick: function () { return toggleExpected(!hideExpected); } })] })] }), _jsxs("div", __assign({ style: { display: 'flex' } }, { children: [_jsx(AssetsList, { assets: (data === null || data === void 0 ? void 0 : data.assets) || [] }), _jsxs(StyledPanelWrapper, { children: [_jsx(StyledDays, { children: new Array(dayjs(monthDate).daysInMonth()).fill(0).map(function (_, i) { return (_jsx(StyledDay, { children: _jsx(StyledLabel, __assign({ size: "medium" }, { children: i + 1 })) }, "day-".concat(i))); }) }), _jsx(StyledPanel, { children: ((data === null || data === void 0 ? void 0 : data.rows) || []).map(function (row, i) {
                                    var _a;
                                    return row.isDivider ? (_jsx(StyledDivider, {}, i)) : (_jsx(PanelRow, { hideExpected: hideExpected, lines: (_a = row === null || row === void 0 ? void 0 : row.lines) !== null && _a !== void 0 ? _a : [] }, i));
                                }) })] })] }))] })));
};
