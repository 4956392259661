import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, PureComponent } from 'react';
import { getSourceFilter } from '../../../services/urlService';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, AreaChart, Area, } from 'recharts';
import NavPanel from './navPannel.jsx';
import Spinner from '../../../commons/spinner';
import SvgLogo from '../../tools/svgLogo';
var _ = require('lodash');
var ChartList = function (props) {
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState({}), data = _b[0], setData = _b[1];
    var _c = useState(false), noData = _c[0], setNoData = _c[1];
    function colorCode(abr) {
        switch (abr) {
            case 'BaseLine':
                return '#008000';
                break;
            case 'DAD':
                return '#F7B80B';
                break;
            case 'GRAD':
                return '#e0d91a';
                break;
            case 'CITAC':
                return '#faebd7';
                break;
            case 'EA':
                return '#6897bb';
                break;
            case 'EA':
                return '#0066cc';
                break;
            case 'FGE':
                return '#ef4135';
                break;
            case 'GENSCAPE':
                return '#ff3200';
                break;
            case 'ICIS':
                return '#e2a8fd';
                break;
            case 'IHS':
                return '#f57280';
                break;
            case 'IIR':
                return '#003c88';
                break;
            case 'JLC':
                return '#32ecab';
                break;
            case 'OGJ':
                return '#93ffff';
                break;
            case 'PM':
                return '#cbbeb5';
                break;
            case 'PIRA':
                return '#f768a1';
                break;
            case 'WM':
                return '#6403ff';
                break;
            case 'EIA':
                return '#cc99ff';
                break;
            case 'SCI':
                return '#e562ba';
                break;
            case 'RYSTAD':
                return '#7e78dc';
                break;
            case 'STMA':
                return '#03c0ff';
                break;
            case 'GENEVA':
                return '#c1eaea';
                break;
            case 'Internal':
                return '#7a9eff';
                break;
            case 'PETROLOGISTICS':
                return '#fff8b7';
                break;
            case 'CLARKSONS':
                return '';
                break;
            case 'KPLER':
                return '#ff00ff';
                break;
            case 'VIENNA':
                return '#e3ae1d';
                break;
        }
    }
    useEffect(function () {
        if (props.path) {
            setLoader(true);
            var chartPath = 'CapacitiesOverTime' + props.path;
            getSourceFilter(chartPath).then(function (returnData) {
                //api data
                if (typeof returnData != 'undefined' && Object.keys(returnData.data).length > 0) {
                    setData(returnData.data);
                    setLoader(false);
                }
                else {
                    setNoData(true);
                    setLoader(false);
                }
            });
        }
    }, []);
    var switchChart = function (e) {
        for (var i = 0; i < 9; i++) {
            document.getElementById('chart' + (i + 1)).classList.remove('show');
            document.getElementById('chart' + (i + 1)).classList.add('hide');
            if (e == i + 1) {
                document.getElementById('chart' + e).classList.remove('hide');
                document.getElementById('chart' + e).classList.add('show');
            }
        }
    };
    var monthTickFormatter = function (tick) {
        var date = new Date(tick);
        return date.getMonth() + 1;
    };
    var renderQuarterTick = function (tickProps) {
        var x = tickProps.x, y = tickProps.y, payload = tickProps.payload;
        var value = payload.value, offset = payload.offset;
        var date = new Date(value);
        var month = date.getMonth();
        var quarterNo = Math.floor(month / 3) + 1;
        var isMidMonth = month % 3 === 1;
        if (month % 3 === 1) {
            return _jsx("text", __assign({ x: x + offset, y: y - 4, textAnchor: "middle" }, { children: "Q".concat(quarterNo) }));
        }
        var isLast = month === 11;
        if (month % 3 === 0 || isLast) {
            var pathX = Math.floor(isLast ? x + offset * 2 : x) + 0.5;
            return _jsx("path", { d: "M".concat(pathX, ",").concat(y - 4, "v").concat(-35), stroke: "red" });
        }
        return null;
    };
    var CustomizedLabel = /** @class */ (function (_super) {
        __extends(CustomizedLabel, _super);
        function CustomizedLabel() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedLabel.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, value = _a.value;
            return (_jsx("text", __assign({ x: x, y: y, dy: -4, fill: stroke, fontSize: 10, textAnchor: "middle" }, { children: value })));
        };
        return CustomizedLabel;
    }(PureComponent));
    var CustomizedAxisTick = /** @class */ (function (_super) {
        __extends(CustomizedAxisTick, _super);
        function CustomizedAxisTick() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedAxisTick.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, payload = _a.payload;
            return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: _jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "end", fill: "#666", transform: "rotate(-35)" }, { children: payload.value })) })));
        };
        return CustomizedAxisTick;
    }(PureComponent));
    var chartData = [];
    _.forEach(data.labels, function (value) {
        var chartObj = {};
        chartObj['name'] = value;
        _.forEach(data.data, function (provider) {
            _.forEach(provider.capacityOverTimeGroup, function (qty) {
                if (qty.capacityMonth === value) {
                    chartObj[provider.dataProviderName] = qty.quantity;
                }
            });
        });
        chartData.push(chartObj);
    });
    return (_jsxs("div", __assign({ style: { width: '100%', height: 500, position: 'absolute' } }, { children: [loader && _jsx(Spinner, {}), !noData && (_jsxs("div", __assign({ className: "row chart-continer" }, { children: [_jsx(NavPanel, { switchChart: switchChart }), _jsx("div", __assign({ id: "chart1", className: "col-12 p-0 show" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(LineChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Line, { type: "monotone", 
                                                // //strokeDasharray="5 5"
                                                dataKey: pname.dataProviderName, stroke: fillColor, strokeWidth: 3 }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart2", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(LineChart, __assign({ data: chartData, height: 300, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", tick: _jsx(CustomizedAxisTick, {}) }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Line, { type: "monotone", 
                                                //strokeDasharray="5 5"
                                                dataKey: pname.dataProviderName, label: _jsx(CustomizedLabel, {}), stroke: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart3", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(AreaChart, __assign({ data: chartData, height: 300, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Area, { 
                                                //strokeDasharray="5 5"
                                                dataKey: pname.dataProviderName, stackId: "1", stroke: colorCode(pname.dataProviderName), fill: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart4", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, 
                                                // background={{ fill: colorCode(pname.dataProviderName) }}
                                                fill: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart5", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, stackId: "a", 
                                                // background={{ fill: colorCode(pname.dataProviderName) }}
                                                fill: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart6", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, stackId: "a", 
                                                // background={{ fill: colorCode(pname.dataProviderName) }}
                                                fill: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart7", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Tooltip, { contentStyle: { backgroundColor: '#202020', borderColor: '#595959' } }), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, 
                                                // background={{ fill: colorCode(pname.dataProviderName) }}
                                                fill: fillColor }, 'chart' + index));
                                        })] })) }) })), _jsx("div", __assign({ id: "chart8", className: "col-12 p-0 hide" }, { children: _jsx(ResponsiveContainer, { children: _jsxs(BarChart, __assign({ data: chartData, margin: {
                                    top: 20,
                                    right: 30,
                                    left: 10,
                                    bottom: 5,
                                } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", tickFormatter: monthTickFormatter }), _jsx(XAxis, { dataKey: "name", axisLine: false, tickLine: false, interval: 0, tick: renderQuarterTick, height: 1, scale: "band", xAxisId: "quarter" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Legend, {}), Object.keys(data).length > 0 &&
                                        data.data.map(function (pname, index) {
                                            var fillColor = colorCode(pname.dataProviderName);
                                            return (_jsx(Bar, { dataKey: pname.dataProviderName, background: { fill: '#595959' }, 
                                                // background={{ fill: colorCode(pname.dataProviderName) }}
                                                fill: fillColor }, 'chart' + index));
                                        })] })) }) }))] }))), noData && (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center", style: { marginTop: '5%' } }, { children: [_jsx(SvgLogo, {}), _jsx("h3", __assign({ className: "mt-2", style: { color: '#595959' } }, { children: "No data available, Please reselect filters." }))] })) })))] })));
};
export default ChartList;
