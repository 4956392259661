import { useCallback, useEffect } from 'react';
export function useOnClickOutside(ref, onClickOutside) {
    var handleClickOutside = useCallback(function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside(event);
        }
    }, [ref, onClickOutside]);
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, handleClickOutside]);
}
