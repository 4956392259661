import { DrawerPaddings, DrawerSize } from './Drawer.types';
import { Sizes, Spacings } from '../../types/types';

type DrawerConstsType = {
  [sizes in DrawerSize]: {
    [paddings in DrawerPaddings]: {
      padding: string;
      height: string;
    };
  };
};

export const drawerConsts: DrawerConstsType = {
  [Sizes.Small]: {
    [Spacings.Tight]: {
      padding: '16px',
      height: '64px',
    },
    [Spacings.Generous]: {
      padding: '24px',
      height: '80px',
    },
  },
  [Sizes.Medium]: {
    [Spacings.Tight]: {
      padding: '20px',
      height: '80px',
    },
    [Spacings.Generous]: {
      padding: '32px',
      height: '104px',
    },
  },
  [Sizes.Large]: {
    [Spacings.Tight]: {
      padding: '24px',
      height: '96px',
    },
    [Spacings.Generous]: {
      padding: '40px',
      height: '128px',
    },
  },
};
