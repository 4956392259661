import { emptySplitApi } from './emptySplitApi';
export var emailSubscriptionApis = emptySplitApi.injectEndpoints({
    endpoints: function (builder) { return ({
        saveEmailSubscription: builder.mutation({
            query: function (subscriptionObject) { return ({
                url: '/EmailSubscription',
                method: 'put',
                body: subscriptionObject,
            }); },
        }),
        getEmailSubscription: builder.query({
            query: function () { return '/EmailSubscription'; },
        }),
        getAcmHierarchy: builder.query({
            query: function () { return '/AcmHierarchy'; },
        }),
    }); },
});
export var useSaveEmailSubscriptionMutation = emailSubscriptionApis.useSaveEmailSubscriptionMutation, useGetEmailSubscriptionQuery = emailSubscriptionApis.useGetEmailSubscriptionQuery, useLazyGetEmailSubscriptionQuery = emailSubscriptionApis.useLazyGetEmailSubscriptionQuery, useGetAcmHierarchyQuery = emailSubscriptionApis.useGetAcmHierarchyQuery;
