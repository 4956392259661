import { __spreadArray } from "tslib";
import { useEffect, useMemo } from 'react';
import { useGetAcmHierarchyElementQuery, useGetAssetUnitsQuery } from 'common/ducks/filtersApis';
import { useGetAssetEventTypesQuery, useGetEventCauseTypesQuery, useSaveAssetUnitEventMutation, useUpdateAssetUnitEventMutation, } from 'common/ducks/maintenanceApis';
import { useResolveSelectData } from 'common/hooks/resolve-select-data';
import { getAssetsAndOptions } from 'common/utils/get-filters-from-hierarchy';
import { sortSelectData } from 'common/utils/newScreenUtils';
export var useSetDefaultEventDrawer = function (_a) {
    var setValue = _a.setValue, event = _a.event;
    useEffect(function () {
        var _a;
        if (event) {
            setValue('asset', event.asset_key);
            setValue('units', [event.asset_unit_key]);
            setValue('offlineCapacityOriginal', event.offline_capacity_pct.toFixed(2));
            setValue('lockBaseline', event.event_locked_ind !== 'N');
            setValue('startDate', event.event_start_dt);
            setValue('endDate', event.event_end_dt);
            setValue('eventCause', event.event_cause_type_key);
            setValue('eventType', event.asset_event_type_key);
            setValue('provider', event.data_provider_key);
            setValue('comment', (_a = event.comments) !== null && _a !== void 0 ? _a : '');
        }
    }, [event, setValue]);
};
export var useReset = function (form, setError, open) {
    var reset = form.reset;
    useEffect(function () {
        if (!open) {
            reset();
            setError(null);
        }
    }, [open, reset, setError]);
};
export var useRequests = function (form, skip) {
    var watch = form.watch;
    var _a = useGetAcmHierarchyElementQuery(undefined, {
        skip: skip,
    }), _b = _a.data, regions = _b === void 0 ? [] : _b, isFetchingAcmHierarchy = _a.isFetching;
    var assets = useMemo(function () {
        return getAssetsAndOptions({
            regions: regions,
            countries: [],
            companies: [],
        });
    }, [regions]).assetsOptions;
    var _c = useGetAssetUnitsQuery({ assetIds: [watch('asset')] }, { skip: !watch('asset') }), unitsData = _c.data, isFetchingUnit = _c.isFetching;
    var units = useResolveSelectData(unitsData, 'id', 'assetUnitNm').sort(sortSelectData);
    if (!!(units === null || units === void 0 ? void 0 : units.length)) {
        units = __spreadArray([{ key: 0, children: 'Select All', value: 0 }], units, true);
    }
    var _d = useGetEventCauseTypesQuery({}, { skip: skip }), eventCauseTypesData = _d.data, isFetchingEventCauseTypes = _d.isFetching;
    var _e = useGetAssetEventTypesQuery({}, { skip: skip }), assetEventTypesData = _e.data, isFetchingAssetEventTypes = _e.isFetching;
    var saveAssetUnitEvent = useSaveAssetUnitEventMutation()[0];
    var updateAssetUnitEvent = useUpdateAssetUnitEventMutation()[0];
    var eventCauseTypes = useResolveSelectData(eventCauseTypesData, 'id', 'eventCauseTypeDesc').sort(sortSelectData);
    var assetEventTypes = useResolveSelectData(assetEventTypesData, 'id', 'assetEventTypeDesc');
    return {
        assets: assets,
        units: units,
        isFetching: {
            isFetchingAcmHierarchy: isFetchingAcmHierarchy,
            isFetchingUnit: isFetchingUnit,
            isFetchingEventCauseTypes: isFetchingEventCauseTypes,
            isFetchingAssetEventTypes: isFetchingAssetEventTypes,
        },
        saveAssetUnitEvent: saveAssetUnitEvent,
        updateAssetUnitEvent: updateAssetUnitEvent,
        eventCauseTypes: eventCauseTypes,
        assetEventTypes: assetEventTypes,
    };
};
export var AddEventMaintenanceEventDrawerHook = {
    useSetDefaultEventDrawer: useSetDefaultEventDrawer,
    useReset: useReset,
    useRequests: useRequests,
};
