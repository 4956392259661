import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, RangeDatePicker } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useResolveSelectOnChange, useSelectsConfigData } from './CapacityFilter.hooks';
import { FilterRow } from 'common/styles/FilterStyles';
import { FilterFieldWrapper, StyledLabel } from 'components/maintenance-filter/MaintenanceFilter.styles';
import { Select } from 'components/select';
import { useGetPlanningProbabilityTypesQuery } from 'common/ducks/filtersApis';
import { DATE_FORMAT_YYYY_MM_DD } from 'common/constants';
import { mapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
export var CapacityFilter = function (_a) {
    var _b = _a.assets, initialAssets = _b === void 0 ? [] : _b, _c = _a.regions, initialRegions = _c === void 0 ? [] : _c, _d = _a.location, initialLocation = _d === void 0 ? [] : _d, _e = _a.owners, initialOwners = _e === void 0 ? [] : _e, _f = _a.units, initialUnits = _f === void 0 ? [] : _f, _g = _a.unitSubTypes, initialUnitSubTypes = _g === void 0 ? [] : _g, _h = _a.unitTypes, initialUnitTypes = _h === void 0 ? [] : _h, _j = _a.probabilityTypes, initialProbabilityTypes = _j === void 0 ? [] : _j, _k = _a.timeframe, initialTimeframe = _k === void 0 ? ['2006-01-01', dayjs().add(3, 'years').endOf('month').format(DATE_FORMAT_YYYY_MM_DD)] : _k, onSubmit = _a.onSubmit, sidebarOpen = _a.sidebarOpen;
    var form = useForm({
        defaultValues: {
            assets: initialAssets,
            location: initialLocation,
            regions: initialRegions,
            owners: initialOwners,
            probabilityTypes: initialProbabilityTypes,
            units: initialUnits,
            unitSubTypes: initialUnitSubTypes,
            unitTypes: initialUnitTypes,
            timeframe: initialTimeframe,
        },
    });
    var setValue = form.setValue, watch = form.watch, reset = form.reset, handleSubmit = form.handleSubmit;
    var _l = useState(true), disableApply = _l[0], setDisableApply = _l[1];
    var watchTimeframe = watch('timeframe');
    var watchProbabilityTypes = watch('probabilityTypes');
    var _m = useGetPlanningProbabilityTypesQuery({}, { skip: !sidebarOpen }), _o = _m.data, planningProbabilityTypes = _o === void 0 ? [] : _o, isFetchingProbabilityTypes = _m.isFetching;
    var probabilityTypesOptions = useMemo(function () {
        return planningProbabilityTypes
            .map(mapSelectData('planningProbabilityTypeCd'))
            .sort(sortSelectData);
    }, [planningProbabilityTypes]);
    var resolveSelectOnChange = useResolveSelectOnChange(form);
    // TODO: create common hook with `maintenance filter`
    var config = useSelectsConfigData(form, resolveSelectOnChange, !sidebarOpen);
    var handleTimeFrameChange = useCallback(function (value) {
        var _a, _b;
        if (value) {
            setDisableApply(false);
            setValue('timeframe', [
                ((_a = value[0]) === null || _a === void 0 ? void 0 : _a.format(DATE_FORMAT_YYYY_MM_DD)) || initialTimeframe[0],
                ((_b = value[1]) === null || _b === void 0 ? void 0 : _b.endOf('month').format(DATE_FORMAT_YYYY_MM_DD)) || initialTimeframe[1],
            ]);
        }
    }, [setValue, initialTimeframe]);
    var hasDirtyField = config.map(function (el) { var _a; return (_a = el.value) === null || _a === void 0 ? void 0 : _a.length; }).reduce(function (acc, curr) { return acc || curr; });
    var IsDirtyProbabilityTypes = (watchProbabilityTypes === null || watchProbabilityTypes === void 0 ? void 0 : watchProbabilityTypes.length) !== 1 && watchProbabilityTypes[0] !== 1;
    var IsDirtyTimeframe = watchTimeframe[0] !== '2006-01-01' ||
        watchTimeframe[1] !== dayjs().add(3, 'years').endOf('month').format(DATE_FORMAT_YYYY_MM_DD);
    var isDirtyForm = hasDirtyField || IsDirtyProbabilityTypes || IsDirtyTimeframe;
    // TODO: create separate custom hook for this useEffect
    useEffect(function () {
        setValue('regions', initialRegions);
        setValue('location', initialLocation);
        setValue('assets', initialAssets);
        setValue('owners', initialOwners);
        setValue('unitTypes', initialUnitTypes);
        setValue('unitSubTypes', initialUnitSubTypes);
        setValue('units', initialUnits);
        setValue('probabilityTypes', initialProbabilityTypes);
    }, [
        initialLocation,
        initialOwners,
        initialAssets,
        initialUnitSubTypes,
        initialUnits,
        initialUnitTypes,
        initialRegions,
        initialProbabilityTypes,
        setValue,
    ]);
    return (_jsxs(_Fragment, { children: [config.map(function (el) {
                var placeholder = el.placeholder;
                if (el.label === 'Unit') {
                    placeholder = watch('assets').length ? el.placeholder : 'Select an asset first to see units';
                }
                var optionFilterProp = el.label === 'Assets' ? 'optionFilterProp' : 'children';
                return (_jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: el.label })), _jsx(FilterFieldWrapper, { children: _jsx(Select, { size: "small", mode: "multiple", placeholder: placeholder, id: el.id, data: el.data, value: el.value, onChange: function (e) {
                                    setDisableApply(false);
                                    el.onChange(e);
                                }, disabled: el.disabled, optionFilterProp: optionFilterProp }) })] }, el.id));
            }), _jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: "Probability" })), _jsx(FilterFieldWrapper, { children: _jsx(Select, { size: "small", mode: "multiple", placeholder: isFetchingProbabilityTypes ? 'Loading...' : 'Select', disabled: isFetchingProbabilityTypes, id: "capacity-filter-prob", data: probabilityTypesOptions, value: watchProbabilityTypes, onChange: function (e) {
                                setDisableApply(false);
                                resolveSelectOnChange('probabilityTypes')(e);
                            } }) })] }), _jsxs(FilterRow, { children: [_jsx(StyledLabel, __assign({ size: "small" }, { children: "Start date" })), _jsx(FilterFieldWrapper, { children: _jsx(RangeDatePicker, { size: "small", value: [dayjs(watchTimeframe[0]), dayjs(watchTimeframe[1])], format: "MMM YYYY", picker: "month", onChange: handleTimeFrameChange, dropdownAlign: {
                                points: ['tc', 'bc'],
                            }, allowClear: false }) })] }), _jsxs(FilterRow, { children: [_jsx(Button, __assign({ size: "small", variant: "outlined", disabled: !isDirtyForm, onClick: function () {
                            setDisableApply(false);
                            return reset({
                                assets: [],
                                location: [],
                                owners: [],
                                units: [],
                                unitSubTypes: [],
                                unitTypes: [],
                                probabilityTypes: [1],
                                timeframe: ['2006-01-01', dayjs().add(3, 'years').endOf('month').format(DATE_FORMAT_YYYY_MM_DD)],
                            });
                        } }, { children: "Reset" })), _jsx(Button, __assign({ size: "small", onClick: function (e) {
                            setDisableApply(true);
                            handleSubmit(onSubmit)(e);
                        }, disabled: disableApply }, { children: "Apply" }))] })] }));
};
