import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, createContext, useContext, useEffect } from 'react';
import { filterContext } from './filterContext';
export var useApiContext = function () { return useContext(ApiContext); };
export var ApiContext = createContext();
export var ApiProvider = function (props) {
    //function state set
    var _a = useState(__assign({}, filterContext)), apiParams = _a[0], setApiParams = _a[1];
    /*
     *return of jsx
     */
    return _jsx(ApiContext.Provider, __assign({ value: [apiParams, setApiParams] }, { children: props.children }));
};
