import { __assign } from "tslib";
import { useCallback, useMemo } from 'react';
import { useGetAcmHierarchyElementQuery, useGetAssetUnitsQuery, useGetAssetUnitSubTypesQuery, useGetAssetUnitTypesQuery, } from 'common/ducks/filtersApis';
import { mapSelectData, newMapSelectData, sortSelectData } from 'common/utils/newScreenUtils';
import { loadingPlaceholder } from 'components/maintenance-filter/MaintenanceFilter.hooks';
import { getAssetsAndOptions, getCompaniesAndOptions, getCountriesAndOptions, } from 'common/utils/get-filters-from-hierarchy';
import { sortUnitSubtypes, sortUnitTypes } from 'components/maintenance-filter/MaintenanceFilter.utils';
export var useResolveSelectOnChange = function (_a) {
    var setValue = _a.setValue, reset = _a.reset, getValues = _a.getValues;
    return useCallback(function (target) { return function (value) {
        switch (target) {
            case 'regions':
                reset(__assign(__assign({}, getValues()), { location: [], owners: [], assets: [], unitTypes: [], unitSubTypes: [], units: [] }));
                break;
            case 'location':
                reset(__assign(__assign({}, getValues()), { owners: [], assets: [], unitTypes: [], unitSubTypes: [], units: [] }));
                break;
            case 'owners':
                reset(__assign(__assign({}, getValues()), { assets: [], unitTypes: [], unitSubTypes: [], units: [] }));
                break;
            case 'assets':
                reset(__assign(__assign({}, getValues()), { unitTypes: [], unitSubTypes: [], units: [] }));
                break;
            case 'unitTypes':
                reset(__assign(__assign({}, getValues()), { unitSubTypes: [], units: [] }));
                break;
            case 'unitSubTypes':
                reset(__assign(__assign({}, getValues()), { units: [] }));
                break;
        }
        setValue(target, value);
    }; }, [setValue, reset, getValues]);
};
export var useSelectsConfigData = function (
// TODO: `useSelectsConfigDataReturnType` move type to common folder
form, resolveSelectOnChange, skip) {
    var watch = form.watch;
    var watchRegions = watch('regions');
    var watchCountries = watch('location');
    var watchAssets = watch('assets');
    var watchCompanies = watch('owners');
    var watchUnitTypes = watch('unitTypes');
    var watchUnitSubTypes = watch('unitSubTypes');
    var watchUnits = watch('units');
    var _a = useGetAcmHierarchyElementQuery(undefined, {
        skip: skip,
    }), _b = _a.data, regions = _b === void 0 ? [] : _b, isFetchingAcmHierarchy = _a.isFetching;
    var regionsOptions = useMemo(function () { return regions.map(newMapSelectData('regionName', 'regionKey')); }, [regions]);
    var _c = useMemo(function () { return getCountriesAndOptions(regions, watchRegions); }, [regions, watchRegions]), countries = _c.countries, countriesOptions = _c.countriesOptions;
    var _d = useMemo(function () { return getCompaniesAndOptions(regions, countries, watchRegions, watchCountries); }, [regions, countries, watchRegions, watchCountries]), companies = _d.companies, companiesOptions = _d.companiesOptions;
    var assetsOptions = useMemo(function () {
        return getAssetsAndOptions({
            regions: regions,
            countries: countries,
            companies: companies,
            selectedRegions: watchRegions,
            selectedCountries: watchCountries,
            selectedCompanies: watchCompanies,
        });
    }, [regions, countries, companies, watchRegions, watchCountries, watchCompanies]).assetsOptions;
    var _e = useGetAssetUnitTypesQuery({
        regionIds: watchRegions,
        companyIds: watchCompanies,
        countryIds: watchCountries,
        assetIds: watchAssets,
    }, {
        skip: skip,
    }), _f = _e.data, unitTypes = _f === void 0 ? [] : _f, isFetchingUnitTypes = _e.isFetching;
    var _g = useGetAssetUnitSubTypesQuery({
        regionIds: watchRegions,
        companyIds: watchCompanies,
        countryIds: watchCountries,
        assetIds: watchAssets,
        unitTypeIds: watchUnitTypes,
    }, {
        skip: skip,
    }), _h = _g.data, unitSubTypes = _h === void 0 ? [] : _h, isFetchingUnitSubTypes = _g.isFetching;
    var _j = useGetAssetUnitsQuery({ assetIds: watchAssets, unitTypeIds: watchUnitTypes, unitSubTypeIds: watchUnitSubTypes, placeholder: true }, { skip: !(watchAssets === null || watchAssets === void 0 ? void 0 : watchAssets.length) || skip }), _k = _j.data, units = _k === void 0 ? [] : _k, isFetchingUnits = _j.isFetching;
    var unitTypesOptions = useMemo(function () { return sortUnitTypes(unitTypes); }, [unitTypes]);
    var unitSubTypesOptions = useMemo(function () { return sortUnitSubtypes(unitSubTypes); }, [unitSubTypes]);
    var unitsOptions = useMemo(function () {
        return watchAssets.length && Array.isArray(units)
            ? units.map(mapSelectData('assetUnitNm')).sort(sortSelectData)
            : [];
    }, [watchAssets, units]);
    return [
        {
            label: 'Regions',
            placeholder: loadingPlaceholder(isFetchingAcmHierarchy),
            id: 'maintenance-filter-regions',
            data: regionsOptions,
            value: watchRegions,
            onChange: resolveSelectOnChange('regions'),
            disabled: isFetchingAcmHierarchy,
        },
        {
            label: 'Countries',
            placeholder: loadingPlaceholder(isFetchingAcmHierarchy),
            id: 'maintenance-filter-locations',
            data: countriesOptions,
            value: watchCountries,
            onChange: resolveSelectOnChange('location'),
            disabled: isFetchingAcmHierarchy,
        },
        {
            label: 'Owners',
            placeholder: loadingPlaceholder(isFetchingAcmHierarchy),
            id: 'maintenance-filter-owners',
            data: companiesOptions,
            value: watchCompanies,
            onChange: resolveSelectOnChange('owners'),
            disabled: isFetchingAcmHierarchy,
        },
        {
            label: 'Assets',
            placeholder: loadingPlaceholder(isFetchingAcmHierarchy),
            id: 'maintenance-filter-assets',
            data: assetsOptions,
            value: watchAssets,
            onChange: resolveSelectOnChange('assets'),
            disabled: isFetchingAcmHierarchy,
        },
        {
            label: 'Unit types',
            placeholder: loadingPlaceholder(isFetchingUnitTypes),
            id: 'maintenance-filter-unit-types',
            data: unitTypesOptions,
            value: watchUnitTypes,
            onChange: resolveSelectOnChange('unitTypes'),
            disabled: isFetchingUnitTypes,
        },
        {
            label: 'Unit subtypes',
            placeholder: loadingPlaceholder(isFetchingUnitSubTypes),
            id: 'maintenance-filter-unit-subtypes',
            data: unitSubTypesOptions,
            value: watchUnitSubTypes,
            onChange: resolveSelectOnChange('unitSubTypes'),
            disabled: isFetchingUnitSubTypes,
        },
        {
            label: 'Unit',
            placeholder: loadingPlaceholder(isFetchingUnits),
            id: 'maintenance-filter-units',
            data: unitsOptions,
            value: watchUnits,
            onChange: resolveSelectOnChange('units'),
            disabled: isFetchingUnits,
        },
    ];
};
