import React, { ReactElement, useRef } from 'react';

import classNames from 'classnames';
import Picker from 'rc-picker';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import enGB from 'rc-picker/lib/locale/en_GB';

import { datePickerConsts } from '../../DatePicker.consts';
import { DATE_PICKER_CLASS } from '../../../../utils/constants';
import ArrowKeys from '../../../_internalComponents/ArrowKeys/ArrowKeys';
import Button from '../../../Button/Button';
import * as Styled from '../../DatePicker.styles';
import { ClearIcon, NextIcon, PrevIcon, SuffixIcon, SuperNextIcon, SuperPrevIcon } from '../Icons/Icons';
import { ISingleDatePickerProps } from './SingleDatePicker.types';
import { Sizes, Variants } from '../../../../types/types';
import { getElevation } from '../../utils/datePickerUtils';

const renderExtraFooter = () => <ArrowKeys />;

function SingleDatePicker<T>({
  className = '',
  size = Sizes.Medium,
  invalid = false,
  elevation,
  float,
  dropdownAlign,
  ...rest
}: ISingleDatePickerProps<T>): ReactElement {
  const container = useRef<HTMLDivElement>(null);

  const innerElevation = getElevation(elevation, float);

  const pickerProps = {
    dropdownAlign: {
      ...dropdownAlign,
      offset: [0, innerElevation ? 20 : 0],
    },
    prefixCls: DATE_PICKER_CLASS,
    getPopupContainer: () => container.current as HTMLElement,
    showToday: true,
    nextIcon: <NextIcon />,
    superNextIcon: <SuperNextIcon />,
    prevIcon: <PrevIcon />,
    superPrevIcon: <SuperPrevIcon />,
    suffixIcon: <SuffixIcon />,
    allowClear: true,
    clearIcon: (
      <Button
        size={datePickerConsts[size].clearSize}
        variant={Variants.Transparent}
        icon={<ClearIcon />}
        iconOnly
        aria-label="Clear entry"
      />
    ),
    locale: enGB,
    format: 'DD MMM YYYY',
  };

  const datePickerWrapperProps = {
    className: classNames(DATE_PICKER_CLASS, className),
    prefixCls: DATE_PICKER_CLASS,
    ref: container,
    size,
    $invalid: invalid,
    $elevation: innerElevation,
  };

  if (rest.config === 'custom') {
    return (
      <Styled.DatePickerWrapper {...datePickerWrapperProps}>
        <Picker {...pickerProps} {...rest} renderExtraFooter={renderExtraFooter} />
      </Styled.DatePickerWrapper>
    );
  }

  return (
    <Styled.DatePickerWrapper {...datePickerWrapperProps}>
      <Picker {...pickerProps} generateConfig={dayJsGenerateConfig} {...rest} renderExtraFooter={renderExtraFooter} />
    </Styled.DatePickerWrapper>
  );
}

export default SingleDatePicker;
