import { __makeTemplateObject } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var StyledSection = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border-top: 1px solid ", ";\n  padding: 28px 0 28px 0;\n\n  gap: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  border-top: 1px solid ", ";\n  padding: 28px 0 28px 0;\n\n  gap: 16px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.border.subtle;
});
export var AvatarMenuItem = function (_a) {
    var children = _a.children;
    return (_jsx(StyledSection, { children: children }));
};
var templateObject_1;
