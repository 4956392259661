import { __makeTemplateObject } from "tslib";
import { TD, TH } from '@sede-x/shell-ds-react-framework';
import styled, { css } from 'styled-components';
var StyledTH = styled(TH)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  ", "\n"], ["\n  background-color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.background.surface;
}, function (_a) {
    var fixedIndex = _a.fixedIndex, isActions = _a.isActions, _b = _a.fixedWidth, fixedWidth = _b === void 0 ? 0 : _b;
    return fixedIndex
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          position: sticky;\n          z-index: 1;\n          left: ", "px;\n          filter: brightness(0.95);\n        "], ["\n          position: sticky;\n          z-index: 1;\n          left: ", "px;\n          filter: brightness(0.95);\n        "])), fixedWidth) : isActions
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          position: sticky;\n          z-index: 1;\n          right: 0;\n          filter: brightness(0.95);\n        "], ["\n          position: sticky;\n          z-index: 1;\n          right: 0;\n          filter: brightness(0.95);\n        "]))) : null;
});
var StyledTD = styled(TD)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  cursor: pointer;\n  background-color: ", ";\n  ", "\n"], ["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  cursor: pointer;\n  background-color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme, isSelected = _a.isSelected;
    return isSelected && theme.focus.medium;
}, function (_a) {
    var fixedIndex = _a.fixedIndex, isActions = _a.isActions, isSelected = _a.isSelected, _b = _a.fixedWidth, fixedWidth = _b === void 0 ? 0 : _b;
    return fixedIndex
        ? css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          position: sticky;\n          z-index: 1;\n          left: ", "px;\n          filter: brightness(0.95);\n          background-color: ", ";\n        "], ["\n          position: sticky;\n          z-index: 1;\n          left: ", "px;\n          filter: brightness(0.95);\n          background-color: ", ";\n        "])), fixedWidth, function (_a) {
            var theme = _a.theme;
            return (isSelected ? theme.focus.medium : theme.background.surface);
        }) : isActions
        ? css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          position: sticky;\n          z-index: 1;\n          right: 0;\n          filter: brightness(0.95);\n          background-color: ", ";\n        "], ["\n          position: sticky;\n          z-index: 1;\n          right: 0;\n          filter: brightness(0.95);\n          background-color: ", ";\n        "])), function (_a) {
            var theme = _a.theme;
            return theme.background.surface;
        }) : null;
});
export { StyledTH, StyledTD };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
