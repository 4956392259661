import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { getSourceFilter } from '../../../../../services/urlService';
import Spinner from '../../../../../commons/spinner';
var totalCapacityAssetUnitSubtype = /** @class */ (function (_super) {
    __extends(totalCapacityAssetUnitSubtype, _super);
    function totalCapacityAssetUnitSubtype(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.removeParam = function (key, sourceURL) {
            var rtn = sourceURL.split('?')[0], param, params_arr = [], queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
            if (queryString !== '') {
                params_arr = queryString.split('&');
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split('=')[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + '?' + params_arr.join('&');
            }
            return rtn;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.props.baseProvider) {
                    this.setState({
                        limitForHighlight: this.props.limitForHighlight,
                        baseProvider: this.props.baseProvider,
                        deltaByVersion: false,
                        deltaByProvider: true,
                    });
                }
                else if (this.props.assetFilterParams.includes('isDeltaByProvider=false')) {
                    this.setState({
                        limitForHighlight: this.props.limitForHighlight,
                        deltaByProvider: false,
                        deltaByVersion: true,
                    });
                }
                else {
                    this.setState({
                        limitForHighlight: 0,
                        deltaByProvider: false,
                        deltaByVersion: false,
                    });
                }
                return [2 /*return*/];
            });
        }); };
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.state = {
            data: [],
            limitForHighlight: null,
            deltaByProvider: false,
            deltaByVersion: false,
            baseProvider: null,
        };
        return _this;
    }
    totalCapacityAssetUnitSubtype.prototype.render = function () {
        var _this = this;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "capacitycontainer" }, { children: _jsx("div", __assign({ className: "col-12 k-widget k-grid capacity-grid unit-sub-type", id: "capacityscreen" }, { children: _jsx("div", __assign({ style: { overflowX: 'auto' } }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "k-header text-centre", style: { width: '200px' } }, { children: "\u00A0" })), this.props.data.totalCapacityUnitTypeGroupList.map(function (uSubType, uSubTypeIndex) {
                                                return (_jsxs("th", __assign({ className: "k-header text-centre" }, { children: [_jsx("div", __assign({ className: "pr-1", style: {
                                                                margin: '0 1px 4px 0',
                                                            } }, { children: _jsx("button", __assign({ className: "k-link no-pointer text-centre", style: {
                                                                    padding: '6px',
                                                                } }, { children: uSubType.assetUnitTypeCd })) })), _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uSubType.totalCapacityUnitSubTypeGroupList.map(function (assetUnit, index) {
                                                                        return (_jsx("th", __assign({ className: "k-header text-centre" }, { children: _jsx("div", { children: _jsx("div", __assign({ className: "td-data ellipsis mr-1", style: {
                                                                                        margin: '0 1px 0px 0',
                                                                                    } }, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-centre" }, { children: assetUnit.unitSubTypeCd })) })) }) }), 'key-1-' + _this.getRandom()));
                                                                    }) }) }) })] }), 'key-0-' + _this.getRandom()));
                                            })] }) }), _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: this.props.data.totalCapacityUnitTypeGroupList[0].totalCapacityUnitSubTypeGroupList[0].totalCapacityDataList.map(function (uType, uTypeIndex) {
                                                            return (_jsx("tr", { children: _jsx("td", __assign({ style: {
                                                                        paddingBottom: '4px',
                                                                        paddingRight: '10px',
                                                                        textAlign: 'right',
                                                                    } }, { children: _jsx("div", __assign({ style: {
                                                                            padding: '6px',
                                                                        } }, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-right" }, { children: uType.dataProviderName !== 'Multiple' ? uType.dataProviderName : 'Baseline' })) })) })) }, 'key-2-' + _this.getRandom()));
                                                        }) }) }) }), this.props.data.totalCapacityUnitTypeGroupList.map(function (uSubType, uSubTypeIndex) {
                                                return (_jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uSubType.totalCapacityUnitSubTypeGroupList.map(function (assetUnit, index) {
                                                                    var providerValue = 0;
                                                                    if (_this.state.deltaByProvider) {
                                                                        for (var i = 0; i < assetUnit.totalCapacityDataList.length; i++) {
                                                                            if (assetUnit.totalCapacityDataList[i].dataProviderId ===
                                                                                _this.state.baseProvider.value) {
                                                                                providerValue = assetUnit.totalCapacityDataList[i].capacityQty;
                                                                                break;
                                                                            }
                                                                            if (i === assetUnit.totalCapacityDataList.length - 1)
                                                                                providerValue = null;
                                                                        }
                                                                    }
                                                                    return (_jsx("td", __assign({ className: "k-header text-centre" }, { children: _jsx("table", { children: _jsx("tbody", { children: assetUnit.totalCapacityDataList.map(function (totalCapacityDataList, index) {
                                                                                    var limitClass = '';
                                                                                    if (_this.state.deltaByVersion) {
                                                                                        if (totalCapacityDataList.capacityQty > 0 &&
                                                                                            totalCapacityDataList.capacityQty > _this.state.limitForHighlight)
                                                                                            limitClass = 'limit-more';
                                                                                        else if (totalCapacityDataList.capacityQty < 0 &&
                                                                                            totalCapacityDataList.capacityQty < -1 * _this.state.limitForHighlight)
                                                                                            limitClass = 'limit-less';
                                                                                        else
                                                                                            limitClass = '';
                                                                                    }
                                                                                    if (_this.state.deltaByProvider) {
                                                                                        if (
                                                                                        // totalCapacityDataList.capacityQty &&
                                                                                        providerValue !== null &&
                                                                                            providerValue - totalCapacityDataList.capacityQty > 0 &&
                                                                                            providerValue - totalCapacityDataList.capacityQty >
                                                                                                _this.state.limitForHighlight)
                                                                                            limitClass = 'limit-more';
                                                                                        else if (
                                                                                        // totalCapacityDataList.capacityQty &&
                                                                                        providerValue !== null &&
                                                                                            providerValue - totalCapacityDataList.capacityQty < 0 &&
                                                                                            providerValue - totalCapacityDataList.capacityQty <
                                                                                                -1 * _this.state.limitForHighlight)
                                                                                            limitClass = 'limit-less';
                                                                                        else
                                                                                            limitClass = '';
                                                                                    }
                                                                                    return (_jsx("tr", { children: _jsx("td", __assign({ className: 'k-header' }, { children: _jsx("div", __assign({ className: 'td-data ellipsis unit-sub-type ' + limitClass }, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-centre" }, { children: totalCapacityDataList.capacityQty
                                                                                                        ? parseFloat(totalCapacityDataList.capacityQty).toFixed(1) +
                                                                                                            ' ' +
                                                                                                            totalCapacityDataList.capacityUom
                                                                                                        : totalCapacityDataList.capacityQty +
                                                                                                            ' ' +
                                                                                                            totalCapacityDataList.capacityUom }), 'key-5-' + _this.getRandom()) })) }), 'key-4-' + _this.getRandom()) }));
                                                                                }) }) }) })));
                                                                }) }) }) }) }, 'key-3-' + _this.getRandom()));
                                            })] }) })] }) })) })) })) }));
    };
    return totalCapacityAssetUnitSubtype;
}(Component));
export default totalCapacityAssetUnitSubtype;
