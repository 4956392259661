import React, { ReactElement } from 'react';

import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import BaseTable from 'rc-table';
import { DefaultRecordType } from 'rc-table/lib/interface';

import { TABLE_CLASS } from '../../utils/constants';
import * as Styled from './Table.styles';
import { ITableProps } from './Table.types';
import { Sizes } from '../../types/types';

/**
 *
 * @deprecated
 * Use Table instead of DeprecatedTable
 */
const Table = <T extends DefaultRecordType>({
  className,
  size = Sizes.Medium,
  wrapperStyle,
  data,
  columns = [],
  onResize,
  ...rest
}: ITableProps<T>): ReactElement => (
  <Styled.BaseTableStyles prefixCls={TABLE_CLASS} size={size} style={wrapperStyle}>
    {onResize ? (
      <ResizeObserver onResize={onResize}>
        <BaseTable
          className={classNames(className, TABLE_CLASS)}
          prefixCls={TABLE_CLASS}
          columns={columns}
          data={data}
          {...rest}
        />
      </ResizeObserver>
    ) : (
      <BaseTable
        className={classNames(className, TABLE_CLASS)}
        prefixCls={TABLE_CLASS}
        columns={columns}
        data={data}
        {...rest}
      />
    )}
  </Styled.BaseTableStyles>
);

export default Table;
