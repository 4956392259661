import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import CrossReferenceGridTemplate from '../../../../commons/crossReferenceGridTemplate';
var company = /** @class */ (function (_super) {
    __extends(company, _super);
    function company(props, context) {
        var _this = _super.call(this, props, context) || this;
        var fieldRequired = {
            companyCd: {
                required: true,
                error: 'Enter Company Code.',
            },
            companyNm: {
                required: true,
                error: 'Enter Company Name.',
            },
            shellLegalEntityInd: {
                required: true,
                error: 'Select Shell Legal Entity Indicator.',
            },
            ownershipClassCd: {
                required: true,
                error: 'Select ownership class.',
            },
        };
        var yesNoData = [{ ynValue: 'shellLegalEntityInd' }];
        var foreginKeyParams = [
            {
                path: 'ownershipClass',
                key: 'ownershipClassKey',
                cdName: 'ownershipClassCd',
            },
        ];
        var columns = [
            {
                field: 'companyCd',
                title: 'company Code',
            },
            {
                field: 'companyNm',
                title: 'Company Name',
            },
            {
                field: 'shellLegalEntityInd',
                title: 'ShellLegalEntity Ind',
                type: 'yesNo',
                ynRefer: 'shellLegalEntityInd',
                yn: 'yn',
            },
            // {
            //   field: "ownershipClassKey",
            //   title: "Ownership Class Key"
            // },
            {
                field: 'ownershipClassCd',
                title: 'Ownership Class Key',
                type: 'foreignKeyConstraint',
                referenceCd: 'ownershipClassCd',
                referenceTitle: 'Ownership Class Key',
                urlEndPoint: 'ownershipClass',
            },
            {
                field: 'recordEntryDttm',
                title: 'Record Entry Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaQualityCd',
                title: 'Meta Quality Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaActionCd',
                title: 'Meta Action Cd',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatedDttm',
                title: 'Meta Created Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaCreatorNm',
                title: 'Meta Creator Nm',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedDttm',
                title: 'Meta Changed Dttm',
                filter: 'date',
                format: '{0:dd MMM yyyy hh:mm a}',
                type: 'meta',
                editable: false,
            },
            {
                field: 'metaChangedByNm',
                title: 'Meta Changed By Nm',
                type: 'meta',
                editable: false,
            },
        ];
        _this.state = {
            dataColumns: columns,
            fieldRequired: fieldRequired,
            foreginKeyParams: foreginKeyParams,
            yesNoData: yesNoData,
        };
        return _this;
    }
    company.prototype.render = function () {
        return (_jsx(CrossReferenceGridTemplate, { path: "company", loadPath: "allCompanies", uniqueid: "id", column: this.state.dataColumns, validation: this.state.fieldRequired, uniquecode: "companyCd", foreginKeyParams: this.state.foreginKeyParams, yesNoData: this.state.yesNoData }));
    };
    return company;
}(Component));
export default company;
