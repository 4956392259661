import { DrawerFooterAlignment } from '../Drawer.types';
import { Alignments } from '../../../types/types';

export const getActionsAlignment = (alignment?: DrawerFooterAlignment) => {
  switch (alignment) {
    case Alignments.Left:
      return 'flex-start';
    case Alignments.Center:
      return 'center';
    case Alignments.Right:
      return 'flex-end';
    default:
      return 'space-between';
  }
};
