import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import FilterContainer from './filterContainer';
import { useApiContext } from '../../components/programs/geneva/dataStore/apiDataStore';
function ChartFilterContainer(props) {
    var _a = useApiContext(), apiParams = _a[0], setApiParams = _a[1];
    useEffect(function () { }, [apiParams]);
    return (_jsx(FilterContainer, { currentScreen: "dashboard", OnApply: props.OnApply, resetChartlistFilter: props.resetChartlistFilter, defaultData: typeof apiParams !== 'undefined' ? apiParams : {} }));
}
export default ChartFilterContainer;
