import { ChangeEventHandler, KeyboardEventHandler, forwardRef, useImperativeHandle, useRef } from 'react';

import * as Styled from '../../ButtonGroup.styles';
import { ICheckboxButtonGroupButtonProps } from './CheckboxButton.types';
import { useGetProps } from '../../hooks/useGetProps';
import { Sizes, Positions } from '../../../../types/types';

const CheckboxButton = forwardRef<HTMLInputElement, ICheckboxButtonGroupButtonProps>(
  (
    {
      size = Sizes.Medium,
      selectedKey,
      disabled = false,
      onChange,
      value,
      label,
      icon,
      iconPosition = Positions.Left,
      iconOnly = false,
      emphasis,
      buttonProps,
      ...rest
    },
    ref,
  ) => {
    const { children, tabIndex } = useGetProps(label, icon, iconPosition, iconOnly, disabled);

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef?.current as HTMLInputElement);

    const handleOnKeyDown: KeyboardEventHandler = (e) => {
      if (e.code === 'Enter' && inputRef?.current) {
        inputRef.current.click();
      }
    };

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if (selectedKey && selectedKey.length > 0 && selectedKey.includes(e.target.id)) {
        const newCheckedCheckboxes: string[] = Array.isArray(selectedKey)
          ? selectedKey.filter((checkbox) => checkbox !== e.target.id)
          : [];
        onChange?.(newCheckedCheckboxes, e);

        return;
      }

      const newValue = Array.isArray(selectedKey) ? [...selectedKey] : [];

      const updatedCheckedCheckboxes = [...newValue, e.target.id];
      onChange?.(updatedCheckedCheckboxes, e);
    };

    return (
      <Styled.ButtonGroupLabel
        size={size}
        type="label"
        htmlFor={value}
        onKeyDown={handleOnKeyDown}
        checked={value ? selectedKey?.includes(value) : false}
        disabled={disabled}
        iconPosition={iconPosition}
        iconOnly={iconOnly}
        emphasis={emphasis}
        tabIndex={tabIndex}
        {...buttonProps}
      >
        {children}
        <Styled.ButtonGroupCheckbox
          type="checkbox"
          id={value}
          value={value || ''}
          checked={value ? selectedKey?.includes(value) : false}
          disabled={disabled}
          onChange={handleOnChange}
          ref={inputRef}
          {...rest}
        />
      </Styled.ButtonGroupLabel>
    );
  },
);

export default CheckboxButton;
