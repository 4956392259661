import { __assign, __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
var TotalCpacityBtnGroup = /** @class */ (function (_super) {
    __extends(TotalCpacityBtnGroup, _super);
    function TotalCpacityBtnGroup(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };
        _this.componentDidMount = function () {
            if (_this.props.buttonGroupState) {
                switch (_this.props.buttonGroupState) {
                    case 1:
                        _this.handleClickUnit({
                            target: {
                                getAttribute: function (e) {
                                    return 1;
                                },
                            },
                        });
                        break;
                    case 2:
                        _this.handleClickUnit({
                            target: {
                                getAttribute: function (e) {
                                    return 2;
                                },
                            },
                        });
                        break;
                    case 3:
                        _this.handleClickUnit({
                            target: {
                                getAttribute: function (e) {
                                    return 3;
                                },
                            },
                        });
                }
            }
        };
        _this.handleClickUnit = function (e) {
            _this.setState({
                activeLinkUnit: parseInt(e.target.getAttribute('unit-id')),
            });
            var randomNo = _this.getRandom();
            _this.props.unitToggle(parseInt(e.target.getAttribute('unit-id')), randomNo, e.target.getAttribute('assetKey'));
        };
        _this.state = {
            primary: false,
            activeIndex: 0,
            //Button Groups
            unittypes: [
                {
                    id: 1,
                    name: 'Unit Type',
                },
                {
                    id: 2,
                    name: 'Unit Subtype',
                },
                {
                    id: 3,
                    name: 'Unit',
                },
            ],
            activeLinkUnit: 2,
            expOn: false,
        };
        _this.handleClickUnit = _this.handleClickUnit.bind(_this);
        return _this;
    }
    TotalCpacityBtnGroup.prototype.render = function () {
        var _this = this;
        var _a = this.state, unittypes = _a.unittypes, activeLinkUnit = _a.activeLinkUnit;
        return (_jsx("div", __assign({ className: "py-3 position-absolute", style: { zIndex: 99, right: 0 } }, { children: _jsx("div", { children: _jsx(ButtonGroup, __assign({ className: "bg-solid mx-3 pull-right" }, { children: unittypes.map(function (unit) {
                        return (_jsx(Button, __assign({ className: "btn border", onClick: _this.handleClickUnit, "unit-id": unit.id, assetKey: _this.props.assetKey, primary: unit.id === activeLinkUnit ? true : false }, { children: unit.name }), unit.id));
                    }) })) }) })));
    };
    return TotalCpacityBtnGroup;
}(Component));
export default TotalCpacityBtnGroup;
