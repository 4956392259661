import React, { cloneElement, forwardRef, useImperativeHandle, useRef } from 'react';

import { useOverlayPosition } from '@react-aria/overlays';
import { useTooltipTrigger } from '@react-aria/tooltip';
import { useTooltipTriggerState } from '@react-stately/tooltip';
import { createPortal } from 'react-dom';
import { mergeProps } from 'react-aria';
import classNames from 'classnames';

import { ITooltipProps } from './Tooltip.types';
import { Overlay } from './Overlay';
import { TOOLTIP_CLASS } from '../../utils/constants';
import { Positions } from '../../types/types';

const Tooltip = forwardRef<HTMLElement, ITooltipProps>(
  (
    {
      className,
      isDisabled,
      delay = 500,
      closeDelay = 500,
      trigger: triggerElement,
      overlay,
      triggerAction,
      placement: overlayPlacement,
      shouldFlip,
      offset = 12,
      crossOffset = 0,
      isOpen,
      arrow = true,
      onOpenChange,
    },
    ref,
  ) => {
    const state = useTooltipTriggerState({
      delay,
      isOpen,
      closeDelay,
      trigger: triggerAction,
      isDisabled,
      onOpenChange,
    });

    const targetRef = useRef<HTMLElement>(null);
    const overlayRef = useRef<HTMLElement>(null);
    const arrowRef = useRef<HTMLElement>(null);

    useImperativeHandle(ref, () => overlayRef.current as HTMLElement);

    const { triggerProps, tooltipProps } = useTooltipTrigger(
      {
        isDisabled,
        trigger: triggerAction,
      },
      state,
      targetRef,
    );

    const overlayProps = useOverlayPosition({
      placement: overlayPlacement || Positions.Top,
      targetRef,
      overlayRef,
      offset,
      crossOffset,
      shouldFlip,
      arrowSize: 20,
      containerPadding: 0,
      arrowBoundaryOffset: 0,
      isOpen: state.isOpen,
    });

    const mergedProps = mergeProps(triggerElement.props, triggerProps);

    const trigger = cloneElement(triggerElement, { ref: targetRef, ...mergedProps });

    const portal = createPortal(
      <Overlay
        {...overlayProps}
        {...tooltipProps}
        triggerState={state}
        className={classNames(TOOLTIP_CLASS, className)}
        arrowRef={arrowRef}
        arrow={arrow}
        ref={overlayRef}
      >
        {overlay}
      </Overlay>,
      document.body,
    );

    return (
      <>
        {trigger}
        {state.isOpen && portal}
      </>
    );
  },
);

export default Tooltip;
