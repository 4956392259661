import { ReactElement } from 'react';

import styled, { css } from 'styled-components';

import { Z_INDEX } from '../../utils/constants';
import { IPopoverProps } from './Popover.types';

const PopoverOverlayArrow = styled.div`
  ${({ theme }) => css`
    z-index: ${Z_INDEX.ARROW};
    width: 24px;
    height: 12px;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: ${theme.background.raised};
      box-shadow: rgb(0 0 0 / 2%) 0 0 4px 0, rgb(0 0 0 / 4%) 0 0 0 1px;
      transform: translateX(50%) translateY(50%) rotate(45deg);
    }
  `}
`;

const Popover = (Component: (props: IPopoverProps) => ReactElement, prefixCls: string) =>
  styled(Component)`
    ${({ theme }) => css`
      &.${prefixCls} {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: ${theme.text.onSurface.strong};

        &.${prefixCls}-hidden {
          display: none !important;
        }

        &-placement-topLeft,
        &-placement-bottomLeft {
          .${prefixCls}-arrow {
            margin-left: 16px;
            margin-right: auto;
          }
        }

        &-placement-top,
        &-placement-bottom {
          .${prefixCls}-arrow {
            margin: auto;
          }
        }

        &-placement-topRight,
        &-placement-bottomRight {
          .${prefixCls}-arrow {
            margin-left: auto;
            margin-right: 16px;
          }
        }

        &-placement-topLeft,
        &-placement-top,
        &-placement-topRight {
          flex-direction: column-reverse;

          .${prefixCls}-arrow {
            &::before {
              transform: translateX(50%) translateY(-50%) rotate(45deg);
            }
          }
        }
      }
    `}
  `;

const PopoverOverlay = styled.div<{ padded?: boolean }>`
  ${({ padded, theme }) => css`
    z-index: ${Z_INDEX.OVERLAY};
    border-radius: 2px;
    background: ${theme.background.raised};
    box-shadow: ${theme.elevation.level2};

    ${padded &&
    css`
      padding: 4px 8px;
    `}
  `}
`;

export { Popover, PopoverOverlay, PopoverOverlayArrow };
